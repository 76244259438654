import { ContextProperty } from './context'
import { PropertyBase, PropertyStringBase, PropertyBooleanBase } from './property'
import { BooleanType } from './types/boolean'
import { StringType } from './types/string'
import { AbstractType, TypeWithValue } from './types/type'
import { AggregationBase } from './aggregation'

export type OperatorTypes = 'comparison' | 'logical' | 'arithmetic'
export type LogicalOperatorName = 'some' | 'every'
export type ComparisonOperatorName =
  | 'equals'
  | 'notEquals'
  | 'like'
  | 'greater'
  | 'less'
  | 'greaterOrEqual'
  | 'lessOrEqual'
  | 'isNull'
  | 'notNull'
  | 'isTrue'
  | 'isFalse'
  | 'likeAny'
  | 'tsQuery'
  | 'tsRank'
  | 'between'
  | 'inOperator'
  | 'notInOperator'
export type ArithmeticOperatorName = 'addition' | 'subtraction' | 'multiplication' | 'division'

export type OperatorValueItem = TypeWithValue | PropertyBase | ContextProperty | AggregationBase

interface AbstractOperator {
  readonly kind: OperatorTypes
  readonly mode: LogicalOperatorName | ComparisonOperatorName | ArithmeticOperatorName
  readonly content: (Operator | OperatorValueItem)[]
}

export interface LogicalOperator extends AbstractOperator {
  readonly kind: 'logical'
  readonly mode: LogicalOperatorName
  readonly content: Operator[]
}

export interface ArithmeticOperator extends AbstractOperator {
  readonly kind: 'arithmetic'
  readonly mode: ArithmeticOperatorName
  readonly content: (Operator | OperatorValueItem)[]
}

export interface ComparisonOperator extends AbstractOperator {
  readonly kind: 'comparison'
  readonly mode: ComparisonOperatorName
  readonly content: OperatorValueItem[]
}

export interface StringComparisonOperator extends ComparisonOperator {
  readonly content: [
    PropertyStringBase | ((StringType | AbstractType<string>) & { value: string | null }),
    StringType & { value: string | null },
  ]
  sensitive: boolean
}

export type Operator = LogicalOperator | ComparisonOperator | ArithmeticOperator

export function some(...content: Operator[]) {
  return {
    kind: 'logical',
    mode: 'some',
    content,
  } as LogicalOperator
}

export function every(...content: Operator[]) {
  return {
    kind: 'logical',
    mode: 'every',
    content,
  } as LogicalOperator
}

export function equals(a: OperatorValueItem, b: OperatorValueItem, c?: OperatorValueItem) {
  return {
    kind: 'comparison',
    mode: 'equals',
    content: [a, b, c],
  } as ComparisonOperator
}

export function notEquals(a: OperatorValueItem, b: OperatorValueItem) {
  return {
    kind: 'comparison',
    mode: 'notEquals',
    content: [a, b],
  } as ComparisonOperator
}

export function like(
  a: PropertyStringBase | ((StringType | AbstractType<string>) & { value: string | null }),
  b: PropertyStringBase | ((StringType | AbstractType<string>) & { value: string | null }),
  { sensitive = false }: { sensitive?: boolean } = {},
) {
  return {
    kind: 'comparison',
    mode: 'like',
    content: [a, b],
    sensitive,
  } as StringComparisonOperator
}

export function likeAny(
  a: PropertyStringBase | ((StringType | AbstractType<string>) & { value: string | null }),
  b:
    | StringType
    | AbstractType<string>
    | StringType[]
    | (AbstractType<string>[] &
        {
          value: string | null
        }[]),
  { sensitive = false }: { sensitive?: boolean } = {},
) {
  return {
    kind: 'comparison',
    mode: 'likeAny',
    content: [a, b],
    sensitive,
  } as StringComparisonOperator
}

export function greater(a: OperatorValueItem, b: OperatorValueItem) {
  return {
    kind: 'comparison',
    mode: 'greater',
    content: [a, b],
  } as ComparisonOperator
}

export function less(a: OperatorValueItem, b: OperatorValueItem) {
  return {
    kind: 'comparison',
    mode: 'less',
    content: [a, b],
  } as ComparisonOperator
}

export function greaterOrEqual(a: OperatorValueItem, b: OperatorValueItem) {
  return {
    kind: 'comparison',
    mode: 'greaterOrEqual',
    content: [a, b],
  } as ComparisonOperator
}

export function lessOrEqual(a: OperatorValueItem, b: OperatorValueItem) {
  return {
    kind: 'comparison',
    mode: 'lessOrEqual',
    content: [a, b],
  } as ComparisonOperator
}

export function isNull(a: OperatorValueItem) {
  return {
    kind: 'comparison',
    mode: 'isNull',
    content: [a],
  } as ComparisonOperator
}

export function notNull(a: OperatorValueItem) {
  return {
    kind: 'comparison',
    mode: 'notNull',
    content: [a],
  } as ComparisonOperator
}

export function isTrue(
  a: PropertyBooleanBase | ((BooleanType | AbstractType<boolean>) & { value: boolean | null }),
) {
  return {
    kind: 'comparison',
    mode: 'isTrue',
    content: [a],
  } as ComparisonOperator
}

export function isFalse(
  a: PropertyBooleanBase | ((BooleanType | AbstractType<boolean>) & { value: boolean | null }),
) {
  return {
    kind: 'comparison',
    mode: 'isFalse',
    content: [a],
  } as ComparisonOperator
}

export function addition(a: Operator | OperatorValueItem, b: Operator | OperatorValueItem) {
  return {
    kind: 'arithmetic',
    mode: 'addition',
    content: [a, b],
  } as ArithmeticOperator
}

export function subtraction(a: Operator | OperatorValueItem, b: Operator | OperatorValueItem) {
  return {
    kind: 'arithmetic',
    mode: 'subtraction',
    content: [a, b],
  } as ArithmeticOperator
}

export function multiplication(a: Operator | OperatorValueItem, b: Operator | OperatorValueItem) {
  return {
    kind: 'arithmetic',
    mode: 'multiplication',
    content: [a, b],
  } as ArithmeticOperator
}

export function division(a: Operator | OperatorValueItem, b: Operator | OperatorValueItem) {
  return {
    kind: 'arithmetic',
    mode: 'division',
    content: [a, b],
  } as ArithmeticOperator
}

export function TSQuery(
  a: Operator[] | OperatorValueItem[],
  b:
    | Operator
    | OperatorValueItem
    | ((StringType | AbstractType<string>) & { value: string | null }),
) {
  return {
    kind: 'comparison',
    mode: 'tsQuery',
    content: [a, b],
  } as ComparisonOperator
}

export function TSRank(a: Operator[] | OperatorValueItem[], b: Operator | OperatorValueItem) {
  return {
    kind: 'comparison',
    mode: 'tsRank',
    content: [a, b],
  } as ComparisonOperator
}

export function inOperator(a: Operator | OperatorValueItem, b: Operator[] | OperatorValueItem[]) {
  return {
    kind: 'comparison',
    mode: 'inOperator',
    content: [a, b],
  } as ComparisonOperator
}

export function notInOperator(
  a: Operator | OperatorValueItem,
  b: Operator[] | OperatorValueItem[],
) {
  return {
    kind: 'comparison',
    mode: 'notInOperator',
    content: [a, b],
  } as ComparisonOperator
}

/**
 * Conversão da função BETWEEN do postgres
 * @param target Coluna que deve ser avaliada
 * @param start Argumento de início da comparação
 * @param end Argumento de fim da comparação
 * @returns Objeto operator compatível com a prixApi
 */
export function between(
  target: Operator | OperatorValueItem,
  start: Operator | OperatorValueItem,
  end: Operator | OperatorValueItem,
) {
  return {
    kind: 'comparison',
    mode: 'between',
    content: [target, start, end],
  } as ComparisonOperator
}
