import React, { memo, useMemo } from 'react'
import styled from 'styled-components'
import { formatAsBrNumber } from '~/prix'
import LegalEntityGeoprocessingMapDemarcationReportChartComponent from './legalEntityGeoprocessingMapDemarcationReportChart.component'
import LegalEntityGeoprocessingMapDemarcationReportTableComponent from './legalEntityGeoprocessingMapDemarcationReportTable.component'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 20px;

  .header-card {
    display: flex;
    height: 75px;
    background-color: transparent;
    padding: 10px;
    margin-bottom: 3px;

    .title-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #0f438a;
        font-size: 22px;
        font-weight: 600;
      }

      .subtitle {
        color: #0f438a;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .legend-wrapper {
      display: flex;
      flex: 1;
      gap: 5px;
      flex-direction: row;

      align-items: center;
      justify-content: right;

      span {
        font-size: 13px;
        padding-right: 12px;

        @media (max-width: 570px) {
          padding-right: 2px;
        }
      }
    }
  }

  .list {
    display: flex;
    flex: 1;
    border: 1px solid #b6bfc8;
    border-radius: 20px;
    padding: 20px;

    .item-wrapper {
      display: flex;
      width: 50%;
      flex-direction: column;
      padding: 0px 20px 0px 20px;
    }

    .item-wrapper-full {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: 0px 20px 0px 20px;
    }
  }
`

const Item = styled.div`
  display: flex;
  height: 40px;
  padding: 10px 0px 10px 0px;
  align-items: center;

  .description {
    width: 75%;
    align-items: center;

    span {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;

      color: #121921;
      font-size: 16px;

      @media (max-width: 670px) {
        font-size: 14px;
      }
    }
  }

  .value {
    width: 20%;
    text-align: center;

    @media (max-width: 670px) {
      width: 25%;
    }

    span {
      color: #121921;
      font-size: 16px;
      padding-left: 10px;

      @media (max-width: 670px) {
        font-size: 14px;
      }
    }
  }
`

const MultipleItem = styled(Item)`
  height: 60px;

  .description {
    span {
      -webkit-line-clamp: 3;
    }
  }
`

const Line = styled.hr`
  display: block;
  height: 1px;
  background: transparent;
  width: 100%;
  border: none;
  border-top: solid 1px #e0e5eb;
`

const Circle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
`

const HeaderContainer = styled.div`
  display: flex;
  height: 50px;
  flex-direction: column;
  padding: 0px 0px 5px 0px;

  .title {
    color: #0f438a;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 5px;
  }

  .subtitle {
    font-size: 14px;
    color: #0f438a;
    font-weight: 500;
    line-height: 1.35;
  }
`

interface LegalEntityGeoprocessingMapDemarcationReportListProps {
  title: string
  subtitle?: string
  values?: any
  isCnae?: boolean
  isSegment?: boolean
  isCity?: boolean
  hasIdhm?: boolean
  setImageUrl?: any
}

// criar mode for booleans
function legalEntityGeoprocessingMapDemarcationReportList({
  title,
  subtitle,
  values,
  isCnae = false,
  isCity = false,
  hasIdhm = false,
  setImageUrl,
}: LegalEntityGeoprocessingMapDemarcationReportListProps) {
  const wrapperValues = useMemo(() => {
    const firstColumnValueCnaes = values.result.cnae
      ?.map((item: any, index: number) => {
        if (index < 6) {
          return item
        }
      })
      .filter((item: any) => item !== undefined)

    const secondColumnValueCnaes = values.result.cnae
      ?.map((item: any, index: number) => {
        if (index > 5 && index < 12) {
          return item
        }
      })
      .filter((item: any) => item !== undefined)

    return {
      firstColumnValueCnaes,
      secondColumnValueCnaes,
    }
  }, [values.result])

  return (
    <Wrapper>
      <div className='header-card'>
        <div
          className='title-wrapper'
          style={
            isCnae === false && isCity === false
              ? { height: '100px', paddingBottom: '40px' }
              : undefined
          }
        >
          <span className='title'>{title}</span>
          {subtitle !== undefined ? <span className='subtitle'>{subtitle}</span> : null}
        </div>
        {isCity && hasIdhm ? (
          <div className='legend-wrapper'>
            <Circle style={{ backgroundColor: '#8ccdff' }} />
            <span>Baixo</span>
            <Circle style={{ backgroundColor: '#42abff' }} />
            <span>Médio</span>
            <Circle style={{ backgroundColor: '#1975bf' }} />
            <span>Alto</span>
            <Circle style={{ backgroundColor: '#0F438A' }} />
            <span>Muito Alto</span>
          </div>
        ) : null}
      </div>
      <div className='list'>
        {isCnae ? (
          <div className='item-wrapper'>
            {wrapperValues.firstColumnValueCnaes.map((item: any, index: React.Key) => (
              <React.Fragment key={index}>
                <MultipleItem>
                  <div className='description'>
                    <span>{item.description}</span>
                  </div>
                  <div className='value'>
                    <span>{formatAsBrNumber(item.count)}</span>
                  </div>
                </MultipleItem>
                <Line />
              </React.Fragment>
            ))}
          </div>
        ) : null}
        {isCnae ? (
          <div className='item-wrapper'>
            {wrapperValues.secondColumnValueCnaes.map((item: any, index: React.Key) => (
              <React.Fragment key={index}>
                <MultipleItem>
                  <div className='description'>
                    <span>{item.description}</span>
                  </div>
                  <div className='value'>
                    <span>{formatAsBrNumber(item.count)}</span>
                  </div>
                </MultipleItem>
                <Line />
              </React.Fragment>
            ))}
          </div>
        ) : null}
        {isCnae === false && isCity === false ? (
          <div className='item-wrapper-full'>
            {values.result.segment.map((item: any, index: React.Key) => (
              <React.Fragment key={index}>
                <Item>
                  <div className='description'>
                    <span>{item.description}</span>
                  </div>
                  <div className='value'>
                    <span>{formatAsBrNumber(item.count)}</span>
                  </div>
                </Item>
                <Line />
              </React.Fragment>
            ))}
          </div>
        ) : null}

        {isCity === true ? (
          <div className='item-wrapper-full'>
            <HeaderContainer>
              <span className='title'>Censo</span>
              <span className='subtitle'>Dados extraídos do último Censo encontrado.</span>
            </HeaderContainer>
            {values.result.city.map((item: any, index: React.Key) => (
              <React.Fragment key={index}>
                <Item>
                  <div className='description'>
                    <span>
                      {item.description} <small>(Censo: {item.year})</small>
                    </span>
                  </div>
                  <div className='value'>
                    {item.value < 1 ? (
                      <Circle
                        style={
                          hasIdhm && item.value < 0.55
                            ? {
                                backgroundColor: '#8ccdff',
                              }
                            : hasIdhm && item.value >= 0.55 && item.value <= 0.699
                            ? {
                                backgroundColor: '#42abff',
                              }
                            : hasIdhm && item.value >= 0.7 && item.value <= 0.799
                            ? {
                                backgroundColor: '#1975bf',
                              }
                            : hasIdhm && item.value >= 0.8
                            ? {
                                backgroundColor: '#0F438A',
                              }
                            : {}
                        }
                      ></Circle>
                    ) : null}
                    <span>
                      {item.key === 'grossDomesticProduct' || item.key === 'incomePerCapita'
                        ? 'R$ '
                        : null}
                      {formatAsBrNumber(item.value)}
                      {item.key === 'area' ? ' km²' : null}
                      {item.key === 'populationDensity' ? ' habitantes/km²' : null}
                    </span>
                  </div>
                </Item>
                <Line />
              </React.Fragment>
            ))}

            <LegalEntityGeoprocessingMapDemarcationReportTableComponent
              values={values?.result.governmentTransparencyData}
            />

            {/* {values.result.cagedData?.length > 0 ? (
              <LegalEntityGeoprocessingMapDemarcationReportChartComponent
                values={values.result?.cagedData}
                setImageUrl={setImageUrl}
              />
            ) : null} */}
          </div>
        ) : null}
      </div>
    </Wrapper>
  )
}

export default memo(legalEntityGeoprocessingMapDemarcationReportList)
