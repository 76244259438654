import add from 'date-fns/add'
import formatISO from 'date-fns/formatISO'
import parseISO from 'date-fns/parseISO'
import createType, { AbstractType } from './type'

export interface DateTimeType<NativeGeneric extends Date | null = Date>
  extends AbstractType<NativeGeneric> {
  readonly type: 'dateTime'
  readonly currentValue?: NativeGeneric
  isRequired(): DateTimeType<Exclude<NativeGeneric, null>>
  isOptional(): DateTimeType<NativeGeneric | null>
}

export default function dateTime(): DateTimeType<Date> {
  return {
    ...createType<DateTimeType<Date>>(),
    type: 'dateTime',
  }
}

export const timestamps = {
  createdAt: dateTime().label('Data de inclusão'),
  updatedAt: dateTime().label('Data de atualização'),
  deletedAt: dateTime().isOptional().label('Data de exclusão'),
}

export const baseDate = new Date(1970, 0, 1, 0, 0, 0)

export function parseDateTime(isoDateTime: string): Date {
  return parseISO(isoDateTime)
}

export const floatFormatBaseDate = new Date(1899, 11, 30, 0, 0, 0)

export function parseDateTimeFromFloat(floatDateTime: number): Date {
  if (
    (floatDateTime as unknown as string) == '' ||
    floatDateTime == null ||
    Number.isNaN(floatDateTime)
  ) {
    throw new Error(`Invalid date time: ${floatDateTime}`)
  }
  const days = Math.floor(floatDateTime)
  const floatPart = floatDateTime - days
  const seconds = floatPart * 24 * 60 * 60
  const date = add(floatFormatBaseDate, { days, seconds })
  return date
}

export function stringifyDateTime(jsDateTime: Date): string {
  return formatISO(jsDateTime)
}
