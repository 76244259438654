import { boolean, defineEntity, number, object, string } from '~/prix'

const courseEvasionEntity = defineEntity({
  key: 'courseEvasion',
  title: 'Evasão de cursos EaD',
  sources: ['oltp', 'olap'],
  schema: object({
    cpf: string(),
    uf: string(),
    productCode: number(),
    coursePercentualConclusion: number(),
    isActivePortfolio: boolean(),
    registerDate: string(),
  }),
})

export default courseEvasionEntity
