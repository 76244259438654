import React from 'react'
import styled from 'styled-components'

const Menu = styled.div<{ withPadding?: boolean }>`
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
  padding: ${({ withPadding = true }) => (withPadding ? '1rem' : 0)};
`

export default Menu
