import { array, defineAction, enumerated, number, object, string } from '~/prix'
import mapDemarcationReport from './mapDemarcationReport.action.json'
import { point, polygon } from '~/prix/types/geoJson'

const mapDemarcationReportDefinition = defineAction({
  ...mapDemarcationReport,
  input: object({
    point: point().label('Coordenada'),
    radius: number(),
    boundary: polygon().label('Polígono'),
    geoItems: array(
      object({
        level: enumerated({
          country: 'country',
          macroRegion: 'macroRegion',
          mesoRegion: 'mesoRegion',
          microRegion: 'microRegion',
          state: 'state',
          city: 'city',
          street: 'street',
          neighborhood: 'neighborhood',
          suburb: 'suburb',
        }),
        id: string().label('Id do nível selecionado'),
      }),
    ),
  }),
  output: object({
    areaInfo: object({
      areaMeters: number().label('Área em metros do perímetro demarcado.'),
    }),
    legalEntityCount: number().label('Quantidade de empresas'),
    segment: object({
      description: string().label('Descrição do segmento'),
      count: number().label('Quantidade de segmentos'),
    }),
    cnae: object({
      description: string().label('Descrição da atividade econômica'),
      count: number().label('Quantidade de cnaes'),
    }),
    sector: object({
      type: string().label('Tipo do setor'),
      count: number().label('Quantidade de setores'),
    }),
    size: object({
      size: string().label('Descrição do porte'),
      count: number().label('Quantidade de empresas nessa categoria'),
    }),
    legalNature: object({
      description: string().label('Descrição da natureza jurídica'),
      count: number().label('Quantidade de empresas nessa categoria'),
    }),
    mei: number().label('Quantidade de empresas mei'),
    indicators: object({
      revenue: number().label('Faturamento'),
      averageCost: number().label('Custo médio'),
      scoreOperationsManagement: number().label('Score de operação gerencial'),
      scoreTransformation: number().label('Score transformação'),
      scoreIndicatorsManagement: number().label('Score de indicadores da gerência'),
      scoreInnovation: number().label('Score de inovação'),
      scoreMarketing: number().label('Score de Marketing'),
      scoreSustainablePractices: number().label('Score de sustentável'),
    }),
    attendances: object({
      count: number().label('Quantidade de empresas atendidas no ano corrente'),
    }),
    citiesCensus: array(
      object({
        name: string().label('Nome da cidade'),
        intersectionArea: number().label('Tamanho da área interseccionada em metros quadrados'),
        data: array(
          object({
            key: string().label('Identificador do dado.'),
            value: number().label('Valor do dado.'),
            year: string().label('Ano do qual o dado foi coletado.'),
            description: string().label('Descrição do dado.'),
          }),
        ),
      }),
    ),
    citiesGovernmentTransparency: object({
      name: string().label('Nome da cidade'),
      intersectionArea: number().label('Tamanho da área interseccionada em metros quadrados'),
      data: object({
        year: number().label('Ano do dado'),
        transferredResources: number().label('Valor dos recursos transferidos'),
        populationCount: number().label('Contagem de população'),
        totalBenefits: object({
          beneficiaryCount: number().label(
            'Quantidade total de cidadãos cadastrados em benefícios do governo',
          ),
          total: number().label('Valor total pago aos cidadãos em benefícios'),
          beneficiaryPercentage: number().label(
            'Percentual da população cadastrado em benefícios do governo',
          ),
          meanByBeneficiary: number().label('Média do valor pago aos cidadãos em benefícios'),
        }),
        bolsaFamilia: object({
          bolsaFamiliaCount: number().label(
            'Quantidade total de cidadãos cadastrados no programa Bolsa Familia',
          ),
          bolsaFamiliaTotal: number().label(
            'Valor total pago aos cidadãos cadastrados no programa Bolsa Familia',
          ),
          bolsaFamiliaPercentage: number().label(
            'Percentual da população cadastrados no programa Bolsa Familia',
          ),
          bolsaFamiliaMean: number().label(
            'Média do valor pago aos cidadãos cadastrados no programa Bolsa Familia',
          ),
        }),
        peti: object({
          petiCount: number().label('Quantidade total de cidadãos no programa PETI'),
          petiTotal: number().label('Valor total pago aos cidadãos no programa PETI'),
          petiPercentage: number().label('Percentual da população no programa PETI'),
          petiMean: number().label('Média do valor pago aos cidadãos no programa PETI'),
        }),
        safraBeneficiary: object({
          safraCount: number().label('Quantidade total de cidadãos cadastrados no programa SAFRA'),
          safraTotal: number().label('Valor total pago aos cidadãos cadastrados no programa SAFRA'),
          safraPercentage: number().label('Percentual da população cadastrados no programa SAFRA'),
          safraMean: number().label(
            'Média do valor pago aos cidadãos cadastrados no programa SAFRA',
          ),
        }),
        seguroDefesoBeneficiary: object({
          seguroDefesoCount: number().label(
            'Quantidade total de cidadãos cadastrados no programa Seguro Defeso',
          ),
          seguroDefesoTotal: number().label(
            'Valor total pago aos cidadãos cadastrados no programa Seguro Defeso',
          ),
          seguroDefesoPercentage: number().label(
            'Percentual da população cadastrados no programa Seguro Defeso',
          ),
          seguroDefesoMean: number().label(
            'Média do valor pago aos cidadãos cadastrados no programa Seguro Defeso',
          ),
        }),
        bpcBeneficiary: object({
          bpcCount: number().label('Quantidade total de cidadãos cadastrados no programa BPC'),
          bpcTotal: number().label('Valor total pago aos cidadãos cadastrados no programa BPC'),
          bpcPercentage: number().label('Percentual da população cadastrados no programa BPC'),
          bpcMean: number().label('Média do valor pago aos cidadãos cadastrados no programa BPC'),
        }),
        auxilioEmergencialBeneficiary: object({
          auxilioEmergencialCount: number().label(
            'Quantidade total de cidadãos cadastrados no programa Auxílio Emergencial',
          ),
          auxilioEmergencialTotal: number().label(
            'Valor total pago aos cidadãos cadastrados no programa Auxílio Emergencial',
          ),
          auxilioEmergencialPercentage: number().label(
            'Percentual da população cadastrados no programa Auxílio Emergencial',
          ),
          auxilioEmergencialMean: number().label(
            'Média do valor pago aos cidadãos cadastrados no programa Auxílio Emergencial',
          ),
        }),
        auxilioBrasilBeneficiary: object({
          auxilioBrasilCount: number().label(
            'Quantidade total de cidadãos no programa Auxílio Brasil',
          ),
          auxilioBrasilTotal: number().label(
            'Valor total pago aos cidadãos no programa Auxílio Brasil',
          ),
          auxilioBrasilPercentage: number().label(
            'Percentual da população no programa Auxílio Brasil',
          ),
          auxilioBrasilMean: number().label(
            'Média do valor pago aos cidadãos no programa Auxílio Brasil',
          ),
        }),
        novoBolsaFamiliaBeneficiary: object({
          novoBolsaFamiliaCount: number().label(
            'Quantidade total de cidadãos no programa Novo Bolsa Familia',
          ),
          novoBolsaFamiliaTotal: number().label(
            'Valor total pago aos cidadãos no programa Novo Bolsa Familia',
          ),
          novoBolsaFamiliaPercentage: number().label(
            'Percentual da população no programa Novo Bolsa Familia',
          ),
          novoBolsaFamiliaMean: number().label(
            'Média do valor pago aos cidadãos no programa Novo Bolsa Familia',
          ),
        }),
      }),
    }),
    cityCagedData: array(
      object({
        name: string().label('Nome da cidade'),
        intersectionArea: number().label('Tamanho da área interseccionada em metros quadrados'),
        data: array(
          object({
            year: number().label('Ano competência do caged'),
            month: number().label('Mês competência do caged'),
            balance: number().label('Saldo mensal de admissões e demissões'),
            admissions: number().label('Quantidade de admissões no mẽs'),
            demissions: number().label('Quantidade de demissões no mês'),
          }),
        ),
      }),
    ),
  }),
})

export default mapDemarcationReportDefinition
