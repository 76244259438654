import React from 'react'
import styled from 'styled-components'
import SearchIcon from '~/components/icons/ui/16px_zoom-2.svg'

const SearchInput = styled.input`
  border-radius: ${({ theme }) => theme.borderRadius};
  height: 100%;
  border: none;
  width: 100%;
  padding: ${({ theme }) => theme.padding(0.5)} ${({ theme }) => theme.padding(0.4)};
  outline: none;
  color: #363636;
`

const SearchWrapper = styled.div`
  display: flex;
  margin: 10px;
  outline: ${({ theme }) => `1px solid ${theme.colors.mediumGrey}`};
  border-radius: ${({ theme }) => theme.borderRadius};
  text-decoration: none;

  &:focus-within {
    border: none;
    outline: ${({ theme }) => `2px solid ${theme.colors.inputBorder}`};
  }
`

const SearchIconWrapper = styled.div`
  display: flex;
  border-left: 1px #ccc solid;
  align-self: center;
  padding: 4px 7px;
  fill: #ccc;

  ${SearchInput}:focus ~ & {
    fill: #666;
  }
`

interface searchFieldProps {
  value: string
  onChange: Function
}

const searchField = ({ value, onChange }: searchFieldProps) => {
  return (
    <SearchWrapper>
      <SearchInput
        type='text'
        id='searchInput'
        value={value}
        onChange={event => onChange(event.target.value)}
      />
      <SearchIconWrapper>
        <SearchIcon width={16} height={14} />
      </SearchIconWrapper>
    </SearchWrapper>
  )
}

export default searchField
