import { defineAction, object, string } from '~/prix'
import streetFullGeocoding from './streetFullGeocoding.action.json' assert { type: 'json' }

const streetFullGeocodingDefinition = defineAction({
  ...streetFullGeocoding,
  input: object({
    publicPlace: string().label('Public place'),
    number: string().label('Number'),
    postalCode: string().label('Postal code'),
    city: string().label('City'),
    state: string().label('State'),
    country: string().label('Country'),
    neighborhood: string().label('Neighborhood'),
    hash: string().label('Hash'),
  }),
  output: object({}),
})

export default streetFullGeocodingDefinition
