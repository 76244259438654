import { array, dateTime, defineAction, enumerated, object, string } from '~/prix'
import systemCheckImportsAction from './systemCheckImports.action.json' assert { type: 'json' }

const systemCheckImportsDefinition = defineAction({
  ...systemCheckImportsAction,
  input: object({}),
  output: array(
    object({
      wasLastRunSuccessful: enumerated({ YES: 'YES', NO: 'NO' }),
      actionKey: string(),
      dataset: string(),
      lastSuccessfulData: object({
        startedAt: dateTime(),
        payload: object({}),
      }),
      lastUnsucessfullData: object({
        startedAt: dateTime(),
        payload: object({}),
        messageError: string(),
        stackTrace: string(),
      }),
    }),
  ) as any,
})

export default systemCheckImportsDefinition
