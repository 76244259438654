import React from 'react'
import styled from 'styled-components'
import { colors } from '~/design'
import MenuIcon from '~/components/icons/ui/24px_menu-34.svg'
import RemoveIcon from '~/components/icons/ui/24px_simple-remove.svg'
import BackIcon from '~/components/icons/arrows/24px_minimal-left.svg'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useViewMode } from '~/prix/react/hooks/viewMode'

const Wrapper = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    order: 1;
    overflow: auto;
    overflow: hidden;
    height: 60px;
    background-color: white;
    z-index: 1;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  nav {
    display: none;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;

      span {
        display: none;
      }
      .flex {
        padding: 0px;
        display: flex;
        width: 100%;
      }
      .item {
        display: flex;
        padding: 10px 5px;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 20%;
      }
      .center {
        width: 60%;
      }
      .menu {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        background-color: rgba(95, 39, 205, 0.5);
        backdrop-filter: blur(6px);
        flex: 1;

        border: 2px solid red;

        ul {
          align-items: center;
          justify-content: flex-start;

          li {
            display: flex;
          }
        }
      }
    }
  }
`

const Button = styled.button`
  display: none;

  background: none;
  border: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: flex;
    flex-shrink: 1;
    text-align: center;
    flex-direction: column;
    align-items: center;

    .link {
      width: 70%;
      height: 70%;
      align-items: center;
    }
    .icon {
      width: 70%;
      height: 70%;
    }
    svg {
      margin-top: 0px;
    }
  }
`

export default function Head({
  title,
  isVisibleMenu,
  onToggleMenu,
  menu,
}: {
  title?: string
  isVisibleMenu: boolean
  onToggleMenu: any
  menu: boolean
}) {
  const navigate = useNavigate()
  const viewMode = useViewMode()

  const [searchParams] = useSearchParams()
  const definedOptionParam = searchParams.get('definedOption')

  return (
    <Wrapper>
      <nav className='menu'>
        <ul className='flex'>
          <li className='item'>
            {viewMode === 'normal' && definedOptionParam === null ? (
              <Button className='link icon' onClick={() => navigate(-1)}>
                <BackIcon width={24} height={24} fill={colors.sebraeBlue} />
              </Button>
            ) : null}

            {viewMode === 'normal' && definedOptionParam !== null ? (
              <Button className='link icon' onClick={() => navigate('../map', { replace: true })}>
                <BackIcon width={24} height={24} fill={colors.sebraeBlue} />
              </Button>
            ) : null}
          </li>

          {title ? (
            <li className='item center'>
              <h3> {title} </h3>
            </li>
          ) : null}
          <li className='item'>
            {menu ? (
              <Button className='link icon' onClick={onToggleMenu}>
                {isVisibleMenu ? (
                  <RemoveIcon width={24} height={24} fill={colors.sebraeBlue} />
                ) : (
                  <MenuIcon width={24} height={24} fill={colors.sebraeBlue} />
                )}
              </Button>
            ) : null}
          </li>
        </ul>
      </nav>
    </Wrapper>
  )
}
