import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
`

export default function Loader() {
  return <Wrapper>Carregando...</Wrapper>
}
