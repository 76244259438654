import { date, defineEntity, number, object, string } from '~/prix'
import any from '~/prix/types/any'

const timeSeries = defineEntity({
  key: 'timeSeries',
  title: 'Série temporal',
  sources: ['oltp'],
  schema: object({
    id: string().label('ID'),
    key: string().label('Chave da série temporal'),
    value: number().label('Valor'),
    predictedValue: number().label('Predição de valor'),
    date: date().label('Data'),
    components: any().label('Componentes'),
    alertLevel: string().label('Nível de alerta'),
    relevance: number().label('Relevância'),
  }),
})

export default timeSeries

export interface AbstractComponent {
  name: string
  amplitude: number
  type: string
}

export interface Outbreak {
  end: string
  count: number
  start: string
  pValues: number[]
  endAngle: number
  amplitude: number
  coefficients: number[]
}

export interface TTestOutbreaksComponent extends AbstractComponent {
  type: 'tTestOutbreaks'
  detections: Outbreak[]
  isCurrentlyOutbreak: boolean
}

export interface DefaultDetection {
  amplitude: number
  coefficients: number[]
  count: number
  endAngle: number
  start: string
  end: string
}

export interface FixedThresholdComponent extends AbstractComponent {
  type: 'fixedThreshold'
  threshold: number
  detections: DefaultDetection[]
  isCurrentlyAbove: boolean
}

export interface MovingAbove extends DefaultDetection {
  ratio: number
}

export interface MovingThresholdComponent extends AbstractComponent {
  type: 'movingThreshold'
  threshold: number
  detections: MovingAbove[]
  multiplierThreshold: number
  method: string
  isCurrentlyAbove: boolean
}

export interface AbovePredictionComponent extends AbstractComponent {
  type: 'abovePrediction'
  detections: DefaultDetection[]
  isCurrentlyAbove: boolean
}

export interface BelowPredictionComponent extends AbstractComponent {
  type: 'belowPrediction'
  detections: DefaultDetection[]
  isCurrentlyAbove: boolean
}

export interface RiseComparisonDetectionComponent extends AbstractComponent {
  type: 'riseComparisonDetection'
  detections: DefaultDetection[]
  isCurrentlyAbove: boolean
}

export interface DropComparisonDetectionComponent extends AbstractComponent {
  type: 'dropComparisonDetection'
  detections: DefaultDetection[]
  isCurrentlyAbove: boolean
}

export interface PolynomialComponent extends AbstractComponent {
  type: 'polynomial'
  count: number
  start: string
  endAngle: number
  amplitude: number
  coefficients: number[]
}

export interface ConditionalPolynomialComponent extends AbstractComponent {
  type: 'conditionalPolynomial'
  start: Record<string, string>
  end: Record<string, string>
  counts: Record<string, number>
  amplitude: number
  endAngles: Record<string, number>
  coefficients: Record<string, number[]>
}

export interface HarmonicComponent extends AbstractComponent {
  type: 'harmonic'
  peaks: number
  start: string
  end: string
  length: number
  amplitude: number
  frequency: number
  fftDefinition: Array<{
    amplitudeReal: number
    frequencyIndex: number
    amplitudeImaginary: number
  }>
}

export type Component =
  | TTestOutbreaksComponent
  | FixedThresholdComponent
  | MovingThresholdComponent
  | AbovePredictionComponent
  | BelowPredictionComponent
  | RiseComparisonDetectionComponent
  | DropComparisonDetectionComponent
  | PolynomialComponent
  | ConditionalPolynomialComponent
  | HarmonicComponent

export interface TimeSeriesItem {
  id: string
  key: string
  alertLevel: string | null
  date: string
  components: Component[] | null
  value: number | null
  predictedValue: number | null
}
