import { defineEntity, number, object, string } from '~/prix'

const legalEntityAttendanceDataSource = defineEntity({
  key: 'legalEntityAttendanceDataSource',
  title: 'Fontes de dados dos atendimentos',
  sources: ['olap', 'oltp'],
  schema: object({
    id: string().label('Identificador'),
    legalEntityAttendanceId: number().label('Identificador da empresa'),
    dataSourceId: number().label('Identificador do data source'),
    createdAt: string().label('Criado em'),
  }),
})

export default legalEntityAttendanceDataSource
