import { array, defineAction, enumerated, number, object, string } from '~/prix'
import searchLegalEntityAction from './searchLegalEntity.action.json'

const searchLegalEntityDefinition = defineAction({
  ...searchLegalEntityAction,
  input: object({
    query: string(),
    sizeAbbreviature: enumerated({
      EPP: 'EPP',
      MEI: 'MEI',
      ME: 'ME',
    }),
    mainCnae: string(),
    filter: object({
      by: enumerated({
        state: 'state',
        city: 'city',
        street: 'street',
        neighborhood: 'neighborhood',
      }).isOptional(),
      query: string(),
    }),
  }),
  output: object({
    warnings: array(
      object({
        message: string(),
      }),
    ).isOptional(),
    legalEntitiesFound: array(
      object({
        url: string(),
        id: number(),
        corporateName: string(),
        tradeName: string(),
        state: string(),
        city: string(),
      }),
    ),
  }),
})

export default searchLegalEntityDefinition
