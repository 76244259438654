import React from 'react'
import styled from 'styled-components'
import Link, { LinkProps } from '../link'

const xPadding = '1rem'
const yPadding = '1.2rem'

const Wrapper = styled(Link)`
  display: flex;
  flex-direction: row;
  background-color: ${({ isActive }) => (isActive ? 'rgba(0,0,0,0.15)' : 'transparent')};
  color: ${({ theme }) => theme.colors.darkText};
  padding: ${yPadding} ${xPadding};
  position: relative;

  &:not(:last-of-type)::before {
    content: '';
    display: block;
    width: calc(100% - ${xPadding});
    position: absolute;
    bottom: 0;
    right: 0;
    border-bottom: 1px solid ${({ isActive }) => (!isActive ? 'rgba(0,0,0,0.1)' : 'transparent')};
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);

    &:not(:last-of-type)::before {
      border-bottom-color: transparent;
    }
  }

  .left {
    display: flex;
    flex: 1;
    flex-direction: column;

    p {
      margin: 0;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-left: ${xPadding};
    align-self: center;
  }
`

export interface MenuItemCardProps extends LinkProps {
  title: string
  description?: string
  label?: string
  childrenOnRight?: React.ReactNode
  menuVisibility: (visibility: boolean) => void
}

export default function MenuItemCard({
  title,
  description,
  label,
  childrenOnRight,
  children,
  menuVisibility,
  ...linkProps
}: MenuItemCardProps) {
  return (
    <Wrapper {...linkProps} onClick={() => menuVisibility(false)}>
      <div className='left'>
        {label && <small className='label'>{label}</small>}
        <strong className='title'>{title}</strong>
        {description && <p className='description'>{description}</p>}
      </div>
      {childrenOnRight ? <div className='right'>{childrenOnRight}</div> : null}
    </Wrapper>
  )
}
