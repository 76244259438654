import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: 'flex';
  flex: 1;

  img {
    width: 100%;
    height: 165px;
    object-fit: cover;
  }

  .text {
    padding: 14px 10px 10px 10px;
  }

  .bottom {
    display: flex;
    justify-content: center;
    padding: 5px 0px 10px 0px;

    a {
      color: #005EB8;

      &:hover {
        color: #0F438A;
      }
    }
  }
`

export default function DashboardGridItemCourseDropout() {
  return (
    <Wrapper>
      <img
        src="https://images.unsplash.com/photo-1502465771179-51f3535da42c?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      />
      <div className='text'>
        <span>
          Um estudo sobre evasão de cursos a nível nacional e estadual.
        </span>
      </div>
      <div className='bottom'>
        <a href={'/app/report/course-dropout'}>Ver mais</a>
      </div>
    </Wrapper>
  )
}
