import { defineAction, object } from '~/prix'
import seedCityGovernmentTransparencyAction from './seedCityGovernmentTransparency.action.json' assert { type: 'json' }

const seedCityGovernmentTransparencyDefinition = defineAction({
  ...seedCityGovernmentTransparencyAction,
  input: object({}),
  output: object({}),
})

export default seedCityGovernmentTransparencyDefinition
