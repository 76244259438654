import React, { useRef } from 'react'
import { format } from 'date-fns'
import styled from 'styled-components'
import { parseDateTime } from '~/prix/types/dateTime'
import { maskNumbers, masks } from '~/prix/utils/types/mask'
import CopyIcon from '~/meta/copy.svg'
import { NativeBaseOptional } from '~/prix/types/type'
import { institutionalColors } from '~/design'

const DataFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  padding: 12px;
  align-content: flex-start;

  div:has(input, textarea) {
    width: 100%;
    height: fit-content;
  }

  span {
    color: var(--p-default, #005EB8);
    font-size: 1.10345rem;
    font-weight: 400;
    line-height: 1.6069rem;
  }

  input, textarea {
    width: 100%;
    border-radius: 4px;
    border: 1px solid ${institutionalColors.graySebrae75};
    background: #FFF;
    padding: 5px;

    color: ${institutionalColors.graySebrae60};
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.6rem;

    min-width: 150px;

    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:focus {
      outline: none;
    }
  }

  .half {
    width: calc(50% - 8px);
  }

  .two-third {
    width: 66%;
  }
  
  .third {
    width: calc(33% - 9px);
  }

  @media (max-width: 1366px) {
    .two-third {
      width: 100%;
    }

    .third {
      width: 48%;
    }
  }

  @media (max-width: 1194px) {
    .third {
      width: calc(50% - 8px);
    }
  }
  
  @media (max-width: 1160px) {
    .half {
      width: 100%;
    }
    
    .two-third {
      width: 100%;
    }
    
    .third {
      width: 100%;
    }
  }

  @media (max-width: 1024px) {
    order: -1;
    margin-top: 75px;
  }
`

const WrapperInput = styled.div`
  display: inline-flex;
  
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
    margin-left: -22px;
    margin-top: 2px;
    width: fit-content;
    height: fit-content;
  }
`

interface Items {
  city: string
  cnpj: string
  complement: string
  corporateName: string
  email: string
  neighborhood: string
  number: number
  openingDate: string
  phoneNumberOne: string
  phoneNumberOneAreaCode: string
  phoneNumberTwo: string
  phoneNumberTwoAreaCode: string
  pointOnStreet: [longitude: number, latitude: number] | null
  pointOnStreetDirectionAngle: number | null
  postalCode: string
  publicPlace: string
  sector: string
  size: string
  state: string
  tradeName: string
}[]

interface CnaeProps {
  cnae_id: NativeBaseOptional
  description: NativeBaseOptional
  is_main: NativeBaseOptional
  segmentDescription: any
}

// export const sectorAux = {
//   agribusiness: 'Agronegócios',
//   commerce: 'Comércio',
//   industry: 'Indústria',
//   service: 'Serviços',
// }

export const sizeAux = {
  unknown: 'Não informado',
  micro: 'Microempresa (ME)',
  small: 'Empresa de Pequeno Porte (EPP)',
  other: 'Demais',
}

export default function DataForm({ item, cnae, readOnly = false }:
  {
    item: Items | any,
    cnae?: CnaeProps[],
    readOnly?: boolean
  }) {

  const mainCnae = cnae?.[0]?.cnae_id ? `${cnae[0]?.cnae_id} - ${cnae[0]?.description}` : 'Desconhecido'
  const cnpj = item?.cnpj ? maskNumbers(item.cnpj.toString(), masks.cnpj) : 'Desconhecido'
  const openingDate = item?.openingDate
    ? format(parseDateTime(item.openingDate), 'dd/MM/yyyy')
    : 'Desconhecido'

  // Não sendo usado por enquanto
  // const sector = item?.sector ? sectorAux[item?.sector as unknown as keyof typeof sectorAux] : 'Desconhecido' 
  const size = item?.size ? sizeAux[item?.size as unknown as keyof typeof sizeAux] : 'Desconhecido'
  const mainTelephone = item?.phoneNumberOneAreaCode && item?.phoneNumberOne ?
    (maskNumbers(item?.phoneNumberOneAreaCode.toString() + item?.phoneNumberOne.toString(), masks.phone)) : 'Desconhecido'
  const secondaryTelephone = item?.phoneNumberTwoAreaCode && item?.phoneNumberTwo ? (maskNumbers(item?.phoneNumberTwoAreaCode.toString() + item?.phoneNumberTwo.toString(), masks.phone)) : 'Desconhecido'
  const formattedAddressParts = [
    item?.publicPlace,
    item?.number,
    item?.complement
  ]
  const formattedAddress = formattedAddressParts.filter(Boolean).join(', ')
  const segment = cnae?.[0]?.segmentDescription ?? 'Desconhecido'

  const inputRefs = useRef<(HTMLInputElement | any)[]>([])

  const handleCopy = (index: number) => {
    const value = inputRefs.current[index]?.value || ''
    navigator.clipboard.writeText(value)
      .then(() => {
        inputRefs.current[index]!.value = 'Copiado!'
        setTimeout(() => {
          if (inputRefs.current[index]) {
            inputRefs!.current[index]!.value = value
          }
        }, 2000)
      })
      .catch((error) => {
        console.error('Failed to copy text: ', error)
      })
  }

  return (
    <DataFormContainer>
      <div>
        <span>Razão social</span>
        <WrapperInput>
          <input type="text" readOnly={readOnly} ref={(el) => inputRefs.current[0] = el} value={item?.corporateName || 'Desconhecido'} />
          <button onClick={() => handleCopy(0)}>
            <CopyIcon width={13} height={13} />
          </button>
        </WrapperInput>
      </div>
      <div className='third'>
        <span>CNPJ</span>
        <WrapperInput>
          <input type="text" readOnly={readOnly} ref={(el) => inputRefs.current[1] = el} value={cnpj} />
          <button onClick={() => handleCopy(1)}>
            <CopyIcon width={13} height={13} />
          </button>
        </WrapperInput>
      </div>
      <div className='third'>
        <span>Telefone principal</span>
        <WrapperInput>
          <input type="text" readOnly={readOnly} ref={(el) => inputRefs.current[2] = el} value={mainTelephone} />
          <button onClick={() => handleCopy(2)}>
            <CopyIcon width={13} height={13} />
          </button>
        </WrapperInput>
      </div>
      <div className='third'>
        <span>Telefone secundário</span>
        <WrapperInput>
          <input type="text" readOnly={readOnly} ref={(el) => inputRefs.current[3] = el}
            value={secondaryTelephone} />
          <button onClick={() => handleCopy(3)}>
            <CopyIcon width={13} height={13} />
          </button>
        </WrapperInput>
      </div>
      <div className='two-third'>
        <span>Email</span>
        <WrapperInput>
          <input type="text" readOnly={readOnly} ref={(el) => inputRefs.current[4] = el}
            value={item?.email || 'Desconhecido'} />
          <button onClick={() => handleCopy(4)}>
            <CopyIcon width={13} height={13} />
          </button>
        </WrapperInput>
      </div>
      <div>
        <span>Endereço</span>
        <WrapperInput>
          <textarea rows={2} readOnly={readOnly} ref={(el) => inputRefs.current[5] = el}
            value={formattedAddress} />
          <button onClick={() => handleCopy(5)}>
            <CopyIcon width={13} height={13} />
          </button>
        </WrapperInput>
      </div>
      <div>
        <span>CNAE principal</span>
        <WrapperInput>
          <input type="text" readOnly={readOnly} ref={(el) => inputRefs.current[7] = el}
            value={mainCnae} />
          <button onClick={() => handleCopy(7)}>
            <CopyIcon width={13} height={13} />
          </button>
        </WrapperInput>
      </div>
      <div className='third'>
        <span>Data de abertura</span>
        <WrapperInput>
          <input type="text" readOnly={readOnly} ref={(el) => inputRefs.current[6] = el}
            value={openingDate} />
          <button onClick={() => handleCopy(6)}>
            <CopyIcon width={13} height={13} />
          </button>
        </WrapperInput>
      </div>
      <div className='third'>
        <span>Segmento</span>
        <WrapperInput>
          <input type="text" readOnly={readOnly} ref={(el) => inputRefs.current[8] = el}
            value={segment} />
          <button onClick={() => handleCopy(8)}>
            <CopyIcon width={13} height={13} />
          </button>
        </WrapperInput>
      </div>
      {/* <div>
        <span>Setor</span>
        <WrapperInput>
          <input type="text" readOnly={readOnly} ref={(el) => inputRefs.current[9] = el}
            value={sector} />
          <button onClick={() => handleCopy(9)}>
            <CopyIcon width={13} height={13} />
          </button>
        </WrapperInput>
      </div> */}
      <div className='third'>
        <span>Porte</span>
        <WrapperInput>
          <input type="text" readOnly={readOnly} ref={(el) => inputRefs.current[10] = el}
            value={size} />
          <button onClick={() => handleCopy(10)}>
            <CopyIcon width={13} height={13} />
          </button>
        </WrapperInput>
      </div>
    </DataFormContainer>
  )
}