import errors from '../error'

export default async function canvasDrawToURL(
  draw: (context: CanvasRenderingContext2D) => void,
  { width, height }: { width: number; height: number },
) {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')

  canvas.width = width
  canvas.height = height

  if (!context) {
    throw errors.incompatible()
  }

  draw(context)

  const blob = await new Promise<Blob | null>((resolve, reject) => {
    canvas.toBlob(resolve, 'image/png')
  })

  if (!blob) {
    throw errors.incompatible()
  }

  return URL.createObjectURL(blob)
}
