import React from 'react'
import styled from 'styled-components'
import theme, { colors } from '~/design'
import { formatAsPercentage } from '~/prix'

const ChartTitleContainer = styled.div`
  display: flex;
  max-height: 45px;
  padding-top: 5px;
  line-height: 1;
`

const BarChartTitle = styled.h1`
  font-size: 16px;
  font-weight: bolder;
  color: #576370;
  line-height: 1;
`

const BarChartContainer = styled.div`
  width: 100%;
  padding-bottom: 5px;

  p {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 14px;
    text-overflow: ellipsis;
  }
`
const BarChartBar = styled.div`
  width: 100%;
  padding-top: 12px;
  position: relative;
  background: lightgray;
  border-radius: 15px;
  overflow: hidden;


  div {
    top: 0;
    left: 0;
    position: absolute;
    height: 15px;
    width: ${props => props.theme.custom?.percentage}%;
    background: ${colors.sebraeBlue};

    span {
      top: 25%;
      left: 0;
      padding-left: 5px;
      color: white;
      font-weight: bold;
      position: absolute;
      text-shadow: 0 0 2px black;
      
    }
  }
`

interface BarChartProps {
  title: string
  items: {
    title: string
    percentage: number
  }[]
}

export default function DashboardBarChart({ title, items }: BarChartProps) {
  return (
    <>
      <ChartTitleContainer>
        <BarChartTitle>{title}</BarChartTitle>
      </ChartTitleContainer>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <BarChartContainer>
            <p>{item.title} - {formatAsPercentage(item.percentage)}%</p>
            <BarChartBar theme={{ ...theme, custom: { percentage: item.percentage * 100 } }}>
              <div></div>
            </BarChartBar>
          </BarChartContainer>
        </React.Fragment>
      ))}
    </>
  )
}
