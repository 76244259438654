import { EntityKey } from '../entity'
import { InferPropertyNative, Property } from '../property'
import createType, { AbstractType, NativeBaseOptional } from './type'

export interface ForeignType<NativeGeneric extends number | string | null = number | string | null>
  extends AbstractType<NativeGeneric> {
  readonly type: 'foreign'
  readonly currentValue?: NativeGeneric
  readonly property: Property<EntityKey, string, string, number | string | null>
  isRequired(): ForeignType<Exclude<NativeGeneric, null>>
  isOptional(): ForeignType<NativeGeneric | null>
}

export default function foreign<NativeGeneric extends number | null = number | null>(
  property: Property<EntityKey, string, string, NativeGeneric>,
): ForeignType<number>

export default function foreign<NativeGeneric extends string | null = string | null>(
  property: Property<EntityKey, string, string, NativeGeneric>,
): ForeignType<string>

export default function foreign<
  NativeGeneric extends number | string | null = number | string | null
>(property: Property<EntityKey, string, string, NativeGeneric>): ForeignType<NativeGeneric> {
  return {
    ...createType(),
    type: 'foreign',
    property,
  }
}
