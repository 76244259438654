import React, { EntityKey } from '../../entity'
import errors from '../../error'
import { Selectables, SerializableQuery } from '../../query'
import useItems from './items'

export default function useItem<
  MainEntityKey extends EntityKey,
  MainEntityKeyAlias extends string,
  Selection extends Selectables,
>(
  queryFactory: () => SerializableQuery<MainEntityKey, MainEntityKeyAlias, Selection>,
  deps: React.DependencyList,
  { autoLoad = true, cache }: { autoLoad?: boolean; cache?: number } = {},
) {
  const { items, ...result } = useItems<MainEntityKey, MainEntityKeyAlias, Selection>(
    queryFactory,
    deps,
    { autoLoad, cache },
  )

  if (result.query.currentLimit !== 1) {
    throw errors.incompatible(
      `Para buscar apenas um resultado, é importante que a limitação seja configurada`,
    )
  }

  if (items && !items[0]) {
    return {
      ...result,
      isLoading: false,
      error: errors.notFound(),
      item: undefined,
    }
  }

  return {
    ...result,
    item: items ? items[0] : undefined,
  }
}
