import { defineAction, object } from '~/prix'
import importFtpPostgresDumpsAction from './importFtpPostgresDumps.action.json'

const importFtpPostgresDumpsDefinition = defineAction({
  ...importFtpPostgresDumpsAction,
  input: object({}),
  output: object({}),
})

export default importFtpPostgresDumpsDefinition
