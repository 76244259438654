import { defineAction, object } from '~/prix'
import cnpjValidatorAction from './cnpjValidator.action.json' assert { type: 'json' }

const cnpjValidatorDefinition = defineAction({
  ...cnpjValidatorAction,
  input: object({}),
  output: object({}),
})

export default cnpjValidatorDefinition
