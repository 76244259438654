import add from 'date-fns/add'
import * as math from 'mathjs'
import React, { useCallback, useMemo } from 'react'
import { colors } from '~/design'
import { BottomAxis, BottomAxisProps } from '~/prix/react/components/lineChart/bottomAxis'
import { Series } from '~/prix/react/components/lineChart/lineChart'
import LineChartWithScroll from '~/prix/react/components/lineChart/lineChartWithScroll'
import { formatDate, parseDate } from '~/prix/types/date'
import { HarmonicComponent, TimeSeriesItem } from './timeSeries.entity'

const series: Series[] = [
  {
    label: 'Ocorrências estimadas',
    marker: {
      color: colors.nausPurple,
      radius: 5,
    },
    line: {
      color: colors.nausPurple,
      width: 3,
      curve: 'curveMonotoneX',
    },
  },
]

export interface TimeSeriesHarmonicBottomChartProps {
  component: HarmonicComponent
  height: number
  items: TimeSeriesItem[]
}

function generateFromFFTDefinition(
  fftDefinition: Array<{
    frequencyIndex: number
    amplitudeReal: number
    amplitudeImaginary: number
  }>,
  length: number,
  x = Array.from(Array(length).keys()),
) {
  const baseFrequency = 1 / length
  const generated = Array.from(Array(length)).map(() => 0)

  for (const item of fftDefinition) {
    const amplitude = math.complex(item.amplitudeReal, item.amplitudeImaginary)
    const currentAmplitude = math.divide(amplitude, length) as math.Complex
    for (let i = 0; i < length; i++) {
      generated[i] +=
        (math.abs(currentAmplitude) as unknown as number) *
        math.cos(
          2 * math.pi * item.frequencyIndex * baseFrequency * x[i] + math.arg(currentAmplitude),
        )
    }
  }

  return generated
}

function TimeSeriesHarmonicBottomChart({ component, height }: TimeSeriesHarmonicBottomChartProps) {
  const chartItems = useMemo(
    () =>
      generateFromFFTDefinition(component.fftDefinition, component.length).map(value => ({
        values: [value],
      })),
    [component.fftDefinition, component.length],
  )

  const start = useMemo(() => parseDate(component.start), [component.start])
  const renderBottomAxis = useCallback(
    (props: BottomAxisProps) => (
      <BottomAxis
        {...props}
        getLabel={index =>
          formatDate(
            add(start, {
              days: index,
            }),
            true,
          )
        }
      />
    ),
    [start],
  )

  return (
    <LineChartWithScroll
      height={height}
      items={chartItems}
      series={series}
      itemWidth={100}
      BottomAxis={renderBottomAxis}
      topSpacingForFixedAxis={30}
    />
  )
}

export default React.memo(TimeSeriesHarmonicBottomChart)
