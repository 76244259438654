import { defineAction, object } from '~/prix'
import createLegalEntitySearchIndexAction from './createLegalEntitySearchIndex.action.json' assert { type: 'json' }

const createLegalEntitySearchIndexDefinition = defineAction({
  ...createLegalEntitySearchIndexAction,
  input: object({}),
  output: object({}),
})

export default createLegalEntitySearchIndexDefinition
