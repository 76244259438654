import type { ObjectType } from './object'
import type { StringType } from './string'
import { EnumeratedType } from './enumerated'
import { NumberType } from './number'
import { IntervalType } from './interval'
import { BooleanType } from './boolean'
import { GeographicPointType } from './geographicPoint'
import { DateTimeType } from './dateTime'
import { ForeignType } from './foreign'
import { DateType } from './date'
import { TimeType } from './time'
import { WeekType } from './week'
import { MonthType } from './month'
import { YearType } from './year'
import { ArrayBufferType } from './arrayBuffer'
import { ArrayType } from './array'
import { GeoJsonType } from './geoJson'
import { AnyType } from './any'

export interface GenericObject {
  [key: string]: NativeBaseOptional
}

export type NativeBase = string | number | boolean | GenericObject | Date | object | ArrayBuffer
export type NativeBaseOrMultiple = NativeBase | NativeBase[]

export type NativeBaseOptional = NativeBase | null
export type NativeBaseOptionalOrMultiple = NativeBaseOptional | NativeBase[]

export type ComparableNativeBase = string | number | boolean | Date

export type IdentifierNativeBase = number | string | null

export interface TypeRules {
  readonly isRequired: boolean
}

export interface TypeMeta<NativeGeneric = NativeBase> {
  label?: string
  alternativeLabels?: string[]
  isUnique?: boolean
  placeholder?: string
  isIndex?: boolean
  isDisabled?: boolean
  inputType?: 'text' | 'password'
  mock?: () => NativeGeneric
  default?: () => NativeGeneric
}

export interface AbstractType<NativeGeneric = NativeBase | null> {
  readonly kind: 'type'
  readonly type: string
  readonly subType?: string
  readonly currentRules: TypeRules
  readonly currentMeta: TypeMeta<NativeGeneric> & { [key: string]: unknown }
  readonly currentValue?: NativeGeneric

  value<NewValue extends NativeGeneric>(
    newValue: NewValue,
  ): AbstractType<NewValue> & { value: NewValue }
  isRequired(): AbstractType<Exclude<NativeGeneric, null>>
  isOptional(): AbstractType<NativeGeneric | null>
  meta(metaPatch: TypeMeta<NativeGeneric>): AbstractType<NativeGeneric>
  default(getDefault: () => NativeGeneric): AbstractType<NativeGeneric>
  label(label: string, ...alternativeLabels: string[]): AbstractType<NativeGeneric>
}

export type Type =
  | StringType
  | NumberType
  | BooleanType
  | EnumeratedType
  | ObjectType
  | IntervalType
  | GeographicPointType
  | DateTimeType
  | DateType
  | WeekType
  | MonthType
  | YearType
  | TimeType
  | ForeignType
  | ArrayBufferType
  | GeoJsonType
  | AnyType

export type TypeWithValue<
  Native extends NativeBaseOptionalOrMultiple = NativeBaseOptionalOrMultiple,
> = AbstractType & { value: Native }

export type InferNative<TypeGeneric> = TypeGeneric extends EnumeratedType<infer Native>
  ? Native
  : TypeGeneric extends IntervalType<infer Shape>
  ? {
      [key in keyof Shape]: InferNative<Shape[key]>
    }
  : TypeGeneric extends GeographicPointType<infer Shape>
  ? {
      [key in keyof Shape]: InferNative<Shape[key]>
    }
  : TypeGeneric extends ObjectType<infer Shape>
  ? {
      [key in keyof Shape]: InferNative<Shape[key]>
    }
  : TypeGeneric extends ArrayType<infer Native>
  ? Native
  : TypeGeneric extends ForeignType<infer Native>
  ? Native
  : TypeGeneric extends StringType<infer Native>
  ? Native
  : TypeGeneric extends NumberType<infer Native>
  ? Native
  : TypeGeneric extends BooleanType<infer Native>
  ? Native
  : TypeGeneric extends DateTimeType<infer Native>
  ? Native
  : TypeGeneric extends DateType<infer Native>
  ? Native
  : TypeGeneric extends WeekType<infer Native>
  ? Native
  : TypeGeneric extends MonthType<infer Native>
  ? Native
  : TypeGeneric extends YearType<infer Native>
  ? Native
  : TypeGeneric extends TimeType<infer Native>
  ? Native
  : TypeGeneric extends AnyType<infer Native>
  ? Native
  : TypeGeneric extends ArrayBufferType<infer Native>
  ? Native
  : never

export default function type<TypeGeneric extends AbstractType<unknown>>(): TypeGeneric {
  return {
    kind: 'type',
    type: 'unknown',
    currentMeta: {
      isUnique: false,
      isIndex: true,
      isDisabled: false,
      placeholder: undefined,
      default: () => null,
    },
    currentRules: {
      isRequired: true,
    },
    isRequired() {
      return {
        ...this,
        currentRules: {
          ...this.currentRules,
          isRequired: true,
        },
      }
    },
    isOptional() {
      return {
        ...this,
        currentRules: {
          ...this.currentRules,
          isRequired: false,
        },
      }
    },
    value(newValue) {
      return {
        ...this,
        currentValue: newValue,
      }
    },
    meta(metaPatch) {
      return {
        ...this,
        currentMeta: {
          ...this.currentMeta,
          ...metaPatch,
        },
      }
    },
    default(getDefault) {
      return {
        ...this,
        currentMeta: {
          ...this.currentMeta,
          default: getDefault,
        },
      }
    },
    label(label, ...alternativeLabels) {
      return {
        ...this,
        currentMeta: {
          ...this.currentMeta,
          label,
          alternativeLabels,
        },
      }
    },
  } as TypeGeneric
}
