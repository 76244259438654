import React, { useEffect, useMemo, useState } from 'react'
import Handle from '~/prix/react/components/handle'
import styled from 'styled-components'
import useItems from '~/prix/react/hooks/items'
import DashboardBarChart from '../components/dashboardBarChart.component'
import useDisproportionateAttention from '~/packages/legalEntityAttendance/disproportionateAttention/query/disproportionateAttention.hook'
import { add, format, subYears } from 'date-fns'

import eachSourceAttendanceDatesQuery from '~/packages/legalEntityAttendance/attendanceDates/eachSourceAttendanceDates.query'
import { formatDateUTC } from '~/packages/legalEntityGeoprocessing/map/menu/legalEntityGeoprocessingDefinedOptionMapMenu.component'

const AttentionList = styled.div`
  padding: 0 17px 30px 17px;
`

export default function DashboardGridItemDisproportionateComponent({
  userGeoStateAbbreviation,
  dataSourceId,
  isEnabled = true,
}: {
  userGeoStateAbbreviation?: string | null
  dataSourceId?: number
  isEnabled?: boolean
}) {
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')

  const eachSourceAttendanceDates = useItems(() => eachSourceAttendanceDatesQuery(), [isEnabled], {
    cache: 60 * 60 * 8,
    autoLoad: isEnabled === true,
  })

  const eachSourceAttendanceDatesFormatted: any = eachSourceAttendanceDates.items?.map(
    (item: any) => {
      const maxAttendanceDate = formatDateUTC(item.maxAttendanceDate, 'yyyy-MM-dd')
      const minAttendanceDate = formatDateUTC(item.minAttendanceDate, 'yyyy-MM-dd')

      return {
        value: item.dataSourceId,
        label: item.dataSourceName,
        minAttendanceDate,
        maxAttendanceDate,
        startDate: formatDateUTC(subYears(new Date(maxAttendanceDate), 1), 'yyyy-MM-dd'),
        period: `Dados de ${formatDateUTC(
          new Date(minAttendanceDate),
          'dd/MM/yyyy',
        )} à ${formatDateUTC(new Date(maxAttendanceDate), 'dd/MM/yyyy')}`,
      }
    },
  )

  useEffect(() => {
    if (dataSourceId && eachSourceAttendanceDatesFormatted) {
      let treatDataSourceId = dataSourceId
      if (dataSourceId === 1) {
        treatDataSourceId = 5
      }
      const dataSourceDates = eachSourceAttendanceDatesFormatted.filter(
        (item: { value: number }) => Number(item.value) == Number(treatDataSourceId),
      )
      if (dataSourceDates.length > 0) {
        const { maxAttendanceDate, startDate } = dataSourceDates[0]
        if (startDate !== startDate || endDate !== maxAttendanceDate) {
          setEndDate(maxAttendanceDate)
          setStartDate(startDate)
        }
      }
    }
  }, [eachSourceAttendanceDatesFormatted, dataSourceId, endDate, startDate])

  const legalEntitiesResult = useDisproportionateAttention({
    filters: {
      dataSourceId: Number(dataSourceId) || null,
      attendancePeriod: `${startDate}, ${endDate}`,
      economicActivity: null,
      size: null,
      eadOption: Number(dataSourceId) === 1 ? 'portfolio' : null,
      courseProductCode: null,
    },
    geoFilter: {
      geoAggregate: 'countries',
      geoLevel: 'country',
      geoLevelId: '30',
    },
    isEnabled: isEnabled,
  })

  const mergedData = useMemo(() => {
    if (!legalEntitiesResult.publicResult.items || !legalEntitiesResult.attendanceResult.items) {
      return []
    }

    const publicSum = legalEntitiesResult.publicResult.items
      .map(item => item.count)
      .reduce((a, b) => parseInt(a as unknown as string) + parseInt(b as unknown as string), 0)

    return legalEntitiesResult.publicResult.items
      .map(publicItem => {
        const attendanceItem = legalEntitiesResult.attendanceResult.items?.find(
          attendance => attendance.cnaeId === publicItem.cnaeId,
        )

        if (attendanceItem) {
          const publicRate = publicItem.count / publicSum
          const attendanceRate = attendanceItem.count / publicSum

          const difference = publicRate - attendanceRate

          return {
            cnaeId: publicItem.cnaeId,
            cnaeDescription: publicItem.cnaeDescription,
            geoId: publicItem.geoId,
            publicCount: publicItem.count,
            attendanceCount: attendanceItem.count,
            publicRate,
            attendanceRate,
            difference,
          }
        }

        return null
      })
      .filter(Boolean)
  }, [legalEntitiesResult.publicResult.items, legalEntitiesResult.attendanceResult.items])

  const limitedData = mergedData
    ? mergedData.filter(item => item.attendanceRate)?.slice(0, 3)
    : undefined

  return (
    <Handle
      isLoading={legalEntitiesResult.isLoading}
      error={legalEntitiesResult.attendanceResult.error}
      style={{ minHeight: '100px' }}
    >
      <AttentionList>
        {limitedData?.map((item, index) => (
          <DashboardBarChart
            title={`${item?.cnaeDescription} (${item?.cnaeId})`}
            key={index}
            items={[
              {
                title: 'Dentre todas as empresas',
                percentage: Number(item.publicRate),
              },
              {
                title: 'Dentre as empresas atendidas pelo SEBRAE',
                percentage: Number(item.attendanceRate),
              },
            ]}
          />
        ))}
      </AttentionList>
    </Handle>
  )
}
