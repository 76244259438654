import { defineAction, object, string } from '~/prix'
import legalEntityInsightAction from './legalEntityInsight.action.json'

const legalEntityInsightDefinition = defineAction({
  ...legalEntityInsightAction,
  input: object({
    cnpj: string(),
  }),
  output: object({
    description: string(),
    currentLegalEntity: object({
      cnpj: string(),
      cnaeDescription: string(),
      segmentDescription: string(),
      ageGroup: string(),
      size: string(),
    }),
    legalEntitiesSameCNAE: object({
      area: string(),
      count: string(),
    }),
    legalEntitiesSameSegment: object({
      area: string(),
      count: string(),
    }),
    legalEntitiesSameCNAESizes: object({
      area: string(),
      smallCount: string(),
      microCount: string(),
      meiCount: string(),
      otherCount: string(),
      unknownCount: string(),
    }),
    legalEntitiesSameCNAEAgeGroup: object({
      area: string(),
      startupCount: string(),
      youngCount: string(),
      growthCount: string(),
      establishedCount: string(),
    }),
    mortalitySameCNAE: object({
      area: string(),
      mortalityRange: string(),
      mortalityPercentage: string(),
    }),
  }),
})

export default legalEntityInsightDefinition
