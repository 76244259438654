import { defineEntity, number, object, string, boolean } from '~/prix'

const dataSource = defineEntity({
  key: 'dataSource',
  title: 'Fontes de dados',
  sources: ['olap', 'oltp'],
  schema: object({
    id: number().label('Identificador'),
    name: string().label('Nome da fonte de dados'),
    description: string().label('Descrição'),
    createdAt: string().label('Criado em'),
    isActive: boolean().label('Ativo'),
  }),
})

export default dataSource
