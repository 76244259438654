import createType, { AbstractType } from './type'

export interface TimeType<NativeGeneric extends string | null = string>
  extends AbstractType<NativeGeneric> {
  readonly type: 'time'
  readonly currentValue?: NativeGeneric
  isRequired(): TimeType<Exclude<NativeGeneric, null>>
  isOptional(): TimeType<NativeGeneric | null>
}

export default function time(): TimeType<string> {
  return {
    ...createType<TimeType<string>>(),
    type: 'time',
  }
}
