import 'normalize.css'
import 'react-toastify/dist/ReactToastify.css'
import { render } from 'react-dom'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { ThemeProvider } from 'styled-components'
import theme from './design'
import './design/index.scss'
import entities from './packages/entities'
import actionDefinitions from './packages/actionDefinitions'
import Router from './packages/routes'
import Handle from './prix/react/components/handle'
import createServerlessSource from './prix/sources/serverless'
import keycloak from './utils/client/keycloak'
import ApiWithContext from './components/apiWithContext'
import { KeycloakProvider } from './components/keycloak'
import { apiBasePath } from './utils/shared/platform'
import { NotificationsProvider } from './packages/notifications/notificationContext'
import { ToastContainer } from 'react-toastify'

const serverlessSource = createServerlessSource({
  baseUrl: apiBasePath,
  entities,
  actionDefinitions,
})

const sources = {
  oltp: serverlessSource,
  olap: serverlessSource,
}

const appElement = document.getElementById('app')
render(
  <ThemeProvider theme={theme}>
    <ErrorBoundary
      FallbackComponent={({ error, resetErrorBoundary }) => (
        <Handle error={error} onTryAgain={resetErrorBoundary} />
      )}
      onReset={window.location.reload}
    >
      <KeycloakProvider keycloak={keycloak}>
        <ApiWithContext entities={entities} sources={sources}>
          <NotificationsProvider>
            <Router />
          </NotificationsProvider>
        </ApiWithContext>
      </KeycloakProvider>
    </ErrorBoundary>
    <ToastContainer />
  </ThemeProvider>,
  appElement,
)

const isDevelopment = process.env.NODE_ENV === 'development'

async function prepareServiceWorker() {
  if ('serviceWorker' in navigator) {
    if (isDevelopment) {
      const registration = await navigator.serviceWorker.ready
      await registration.unregister()
      return
    }

    const { Workbox } = await import('workbox-window')
    const workbox = new Workbox('/sw.js')
    workbox.register()
  }
}
prepareServiceWorker().catch(error => console.error(error))
