import { useMemo } from 'react'
import { FilterHighlight } from '~/packages/legalEntityGeoprocessing/map/legalEntityGeoprocessingMapLevels.data'
import useLegalEntityGeoprocessingPolygonsMap from '~/packages/legalEntityGeoprocessing/map/legalEntityGeoprocessingPolygonsMap.hook'
import { isNullOrUndefined } from '~/prix/utils/empty'
import { DefinedOption } from '../legalEntityGeoprocessing/map/menu/legalEntityGeoprocessingMapMenu.data'
import countCityCensusByPopulation from '../cityIbgeCensus/cityCensusByPopulation/countCityCensusByPopulation.query'
import countCityCensusEntitiesByArea from '../cityIbgeCensus/cityCensusByArea/countCityCensusByArea.query'
import useItems from '../../prix/react/hooks/items'
import { QueryBase } from '../../prix/query'
import legalEntityProfitsQuery from '../legalEntityProfit/legalEntityProfits.query'
import {
  profits,
  scores,
} from '../legalEntityGeoprocessing/map/menu/legalEntityGeoprocessingCustomizedMapMenu.component'

interface MesoRegionLegalEntitiesOptions {
  id: string
  filter: FilterHighlight | null
  highlight: FilterHighlight | null
  childrenGeoLevel?: 'cities' | 'microRegions'
  isEnabled?: boolean
  definedOption: DefinedOption | null
}

const childrenGeoLevelOptions = {
  cities: 'Cidades',
  microRegions: 'Microrregiões',
}

export default function useMesoRegionLegalEntities({
  id,
  filter,
  highlight,
  childrenGeoLevel = 'microRegions',
  isEnabled = true,
  definedOption,
}: MesoRegionLegalEntitiesOptions) {
  const polygonsResult = useLegalEntityGeoprocessingPolygonsMap({
    filter,
    highlight,
    isEnabled,
    childrenGeoLevel,
    id,
    by: 'mesoRegionId',
    definedOption,
  })

  const isHighlightProfits =
    highlight && profits.includes(highlight?.type) && highlight?.value === 'profitsData'

  const isHighlightScores =
    highlight && scores.includes(highlight?.type) && highlight?.value === 'profitsData'

  const isHighlightIndicators = isHighlightProfits || isHighlightScores

  const countResultArea = useItems(
    () =>
      isEnabled
        ? (countCityCensusEntitiesByArea({
            groupColumn: 'mesoRegionId',
            level: 'mesoRegion',
            id,
          }) as QueryBase)
        : (null as never),
    [id, isEnabled],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: isEnabled,
    },
  )

  const countResultPopulation = useItems(
    () =>
      isEnabled
        ? (countCityCensusByPopulation({
            groupColumn: 'mesoRegionId',
            level: 'mesoRegion',
            id,
          }) as QueryBase)
        : (null as never),
    [id, isEnabled],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: isEnabled,
    },
  )

  const countResultProfits = useItems(
    () =>
      isEnabled
        ? (legalEntityProfitsQuery({
            groupColumn: 'mesoRegionId',
            level: 'mesoRegion',
            id,
            highlight,
          }) as QueryBase)
        : (null as never),
    [highlight, isEnabled],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: isEnabled,
    },
  )

  const { countSum, highlightSum } = useMemo(() => {
    if (
      definedOption &&
      definedOption.type === 'legalEntityAttendanceIndex' &&
      polygonsResult.isLoading === false
    ) {
      const legalEntityCount = polygonsResult.items
        ? polygonsResult.items
            .filter(item => !item.path)
            .reduce((acc, item) => acc + (Number(item.count) ?? 0), 0)
        : null

      const attendanceCount = polygonsResult.items
        ? polygonsResult.items
            .filter(item => !item.path)
            .reduce((acc, item) => acc + (Number(item.highlight) ?? 0), 0)
        : null

      return {
        countSum: legalEntityCount,
        highlightSum: attendanceCount,
      }
    }

    const countSum =
      polygonsResult.items && highlight?.type !== 'area' && highlight?.type !== 'perCapta'
        ? polygonsResult.items.reduce((acc, item) => acc + (item.count ?? 0), 0)
        : polygonsResult.items && (highlight?.type === 'area' || highlight?.type === 'perCapta')
        ? polygonsResult.items.reduce((acc, item) => acc + (item.highlight ?? 0), 0)
        : null

    const highlightPolygons =
      polygonsResult.items &&
      highlight &&
      !isNullOrUndefined(highlight.value) &&
      highlight?.type !== 'area' &&
      highlight?.type !== 'perCapta' &&
      !isHighlightIndicators
        ? polygonsResult.items.reduce((acc, item) => acc + (item.highlight ?? 0), 0)
        : null

    const highlightCourseProduct =
      polygonsResult.items &&
      highlight &&
      isNullOrUndefined(highlight.value) &&
      highlight?.type !== 'area' &&
      highlight?.type !== 'perCapta' &&
      !isHighlightIndicators
        ? polygonsResult.items.reduce((acc, item) => acc + (item.highlight ?? 0), 0)
        : null

    const highlightAreaCondition =
      highlight?.type === 'area' && !isNullOrUndefined(highlight.value) && countResultArea.items
        ? countResultArea.items[0]?.highlight
        : null
    const highlightPerCaptaCondition =
      highlight?.type === 'perCapta' &&
      !isNullOrUndefined(highlight.value) &&
      countResultPopulation.items
        ? countResultPopulation.items[0]?.highlight
        : null
    const highlightProfits =
      isHighlightIndicators && !isNullOrUndefined(highlight.value) && countResultProfits.items
        ? countResultProfits.items[0]?.highlight
        : null

    const highlightSum = highlightCourseProduct
      ? highlightCourseProduct
      : highlightPolygons
      ? highlightPolygons
      : highlightAreaCondition
      ? highlightAreaCondition
      : highlightPerCaptaCondition
      ? highlightPerCaptaCondition
      : highlightProfits
      ? highlightProfits
      : null

    return {
      countSum,
      highlightSum,
    }
  }, [
    highlight,
    polygonsResult.items,
    countResultArea.items,
    countResultPopulation.items,
    countResultProfits.items,
    isHighlightIndicators,
  ])

  return {
    ...polygonsResult,
    countResultArea,
    countResultPopulation,
    countResultProfits,
    childrenGeoLevelOptions,
    childrenGeoLevel,
    countSum,
    highlightSum,
  }
}
