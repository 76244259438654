type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T // from lodash
export function truthy<T>(value: T): value is Truthy<T> {
  return !!value
}

type NotNullNaNOrUndefined<T> = T extends null | undefined ? never : T // from lodash
export function notNullNaNOrUndefined<T>(value: T): value is NotNullNaNOrUndefined<T> {
  return value !== null && value !== undefined && !Number.isNaN(value)
}

export type AffirmativeVerificationValue =
  | boolean
  | 'true'
  | 'false'
  | 1
  | 0
  | '1'
  | '0'
  | 'on'
  | 'off'
  | 'yes'
  | 'no'
  | 'high'
  | 'low'
  | ''
  | 'null'
  | null
  | 'undefined'
  | undefined

export type AffirmativeVerificationValueString = AffirmativeVerificationValue & string

const affirmativeVerificationValueStrings: AffirmativeVerificationValueString[] = [
  'true',
  '1',
  'on',
  'yes',
  'high',
]

const negativeVerificationValueStrings: AffirmativeVerificationValueString[] = [
  'false',
  '0',
  'off',
  'no',
  'low',
  '',
  'null',
  'undefined',
]

export function isAffirmative(value: AffirmativeVerificationValue | string) {
  const stringValue = String(value).toLowerCase() as AffirmativeVerificationValueString
  if (affirmativeVerificationValueStrings.includes(stringValue)) {
    return true
  }
  if (negativeVerificationValueStrings.includes(stringValue)) {
    return false
  }
  return null
}
