import React, { memo, useMemo } from 'react'
import { Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer'
import { formatAsBrNumber } from '~/prix'
import LegalEntityGeoprocessingMapDemarcationReportTablePdfComponent from './legalEntityGeoprocessingMapDemarcationReportTablePdf.component'

Font.register({
  family: 'Lato-700',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVew-FGC_p9dw.ttf',
    },
  ],
})

const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    height: '45%',
    paddingBottom: 15,
  },

  wrapperWithPadding: {
    display: 'flex',
    height: '46.5%',
    marginBottom: '1px',
    paddingTop: '10px',
    paddingBottom: '15px',
  },

  cityContainer: {
    display: 'flex',
    height: '150%',
    paddingTop: 15,
  },

  titleContainer: {
    display: 'flex',
    minHeight: '50px',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingLeft: 20,
  },

  titleCityContainer: {
    display: 'flex',
    minHeight: '40px',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingLeft: '5px',
    paddingBottom: '5px',
  },

  cityListContainerColumn: {
    display: 'flex',
    height: '350px',
  },

  titleCenterContainer: {
    display: 'flex',
    height: 50,
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: 20,
  },

  title: {
    fontSize: '15px',
    color: '#0F438A',
    fontFamily: 'Lato-700',
  },

  demographicAndEconomicSubtitle: {
    fontSize: '10px',
    color: '#0F438A',
  },

  listContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    border: '1px solid #B6BFC8',
    borderRadius: '20px',
  },

  listContainerColumn: {
    display: 'flex',
    flex: 1,
    width: '50%',
  },

  cityCenterContainerMap: {
    display: 'flex',
  },

  cityHeaderContainer: {
    display: 'flex',
    height: 40,
    paddingLeft: 5,
    justifyContent: 'center',
  },

  cityHeaderChartText: {
    fontSize: '10px',
    color: '#0F438A',
  },

  cityHeaderChartTextTitle: {
    fontSize: '11px',
    color: '#0F438A',
    fontFamily: 'Lato-700',
  },

  listItem: {
    display: 'flex',
    height: 45,
    flexDirection: 'row',
  },

  cityListItem: {
    display: 'flex',
    height: '21px',
    flexDirection: 'row',
    borderBottom: '1px solid #dfdfdf',
    marginLeft: 5,
    marginRight: 5,
  },

  listItemDescription: {
    display: 'flex',
    width: '80%',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 10,
  },

  listItemDescriptionCenso: {
    display: 'flex',
    width: '80%',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 5,
    paddingRight: 10,
  },

  listItemValue: {
    display: 'flex',
    width: '20%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  listText: {
    fontSize: '10px',
    paddingTop: '3.25px',
    paddingBottom: '3.25px',
  },

  listSmallText: {
    fontSize: '9px',
    paddingLeft: '3px',
  },

  listNoDataText: {
    fontSize: '10px',
    paddingLeft: '3px',
  },

  imageView: {
    width: '100%',
    height: '100%',
    aspectRatio: '3/3',
    paddingLeft: 15,
    paddingRight: 15,
  },
})

export interface CityProps {
  key: string
  value: number
  year: number
  description: string
}

export interface DataTransparencyProps {
  auxilioBrasil: {
    count: number
    total: number
    percentage: number
    average: number
  }
  auxilioEmergencial: {
    count: number
    total: number
    percentage: number
    average: number
  }
  bolsaFamilia: {
    count: number
    total: number
    percentage: number
    average: number
  }
  bpc: {
    count: number
    total: number
    percentage: number
    average: number
  }
  novoBolsaFamilia: {
    count: number
    total: number
    percentage: number
    average: number
  }
  peti: {
    count: number
    total: number
    percentage: number
    average: number
  }
  populationCount: number
  safra: {
    count: number
    total: number
    percentage: number
    average: number
  }
  seguroDefeso: {
    count: number
    total: number
    percentage: number
    average: number
  }
  totalBenefits: {
    count: number
    total: number
    percentage: number
    average: number
  }
  transferredResources: number
  year: number
}

interface CnaeLegalNatureSegmentProps {
  description: string
  count: number
}

interface ReportListProps {
  values: {
    city?: CityProps[]
    cnae?: CnaeLegalNatureSegmentProps[]
    dataTransparency?: DataTransparencyProps
    legalNature?: CnaeLegalNatureSegmentProps[]
    segment?: CnaeLegalNatureSegmentProps[]
  }
  title: string
  subtitle?: string
  type: string
  chartImage?: string
  selectedYearChart: string
}

function legalEntityGeoprocessingMapDemarcationReportListPdf({
  values,
  type,
  title,
  subtitle,
  chartImage,
  selectedYearChart,
}: ReportListProps) {
  const wrapperValues = useMemo(() => {
    if (type === 'cnae') {
      const firstColumnValue = values?.cnae
        ?.map((item: CnaeLegalNatureSegmentProps, index: number) => {
          if (index < 6) {
            return item
          }
        })
        .filter(item => item !== undefined)

      const secondColumnValue = values?.cnae
        ?.map((item: CnaeLegalNatureSegmentProps, index: number) => {
          if (index > 5 && index < 12) {
            return item
          }
        })
        .filter(item => item !== undefined)

      return {
        firstColumnValue,
        secondColumnValue,
      }
    }

    if (type === 'segment') {
      const firstColumnValue = values?.segment
        ?.map((item: CnaeLegalNatureSegmentProps, index: number) => {
          if (index < 6) {
            return item
          }
        })
        .filter(item => item !== undefined)

      const secondColumnValue = values?.segment
        ?.map((item: CnaeLegalNatureSegmentProps, index: number) => {
          if (index > 5 && index < 12) {
            return item
          }
        })
        .filter(item => item !== undefined)

      return {
        firstColumnValue,
        secondColumnValue,
      }
    }

    if (type === 'legalNature') {
      const firstColumnValue = values?.legalNature
        ?.map((item: CnaeLegalNatureSegmentProps, index: number) => {
          if (index < 6) {
            return item
          }
        })
        .filter(item => item !== undefined)

      const secondColumnValue = values?.legalNature
        ?.map((item: CnaeLegalNatureSegmentProps, index: number) => {
          if (index > 5 && index < 12) {
            return item
          }
        })
        .filter(item => item !== undefined)

      return {
        firstColumnValue,
        secondColumnValue,
      }
    }
  }, [values, type])

  return (
    <>
      {type === 'cnae' ? (
        <View style={styles.wrapper}>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.demographicAndEconomicSubtitle}>{subtitle}</Text>
          </View>
          <View style={styles.listContainer}>
            <View style={styles.listContainerColumn}>
              {wrapperValues?.firstColumnValue?.map((item, index: number) => (
                <View style={styles.listItem} key={index}>
                  <View style={styles.listItemDescription}>
                    <Text style={styles.listText}>{item?.description}</Text>
                  </View>
                  <View style={styles.listItemValue}>
                    <Text style={styles.listText}>
                      {item?.count ? formatAsBrNumber(item.count) : 0}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
            <View style={styles.listContainerColumn}>
              {wrapperValues?.secondColumnValue?.map((item, index: number) => (
                <View style={styles.listItem} key={index}>
                  <View style={styles.listItemDescription}>
                    <Text style={styles.listText}>{item?.description}</Text>
                  </View>
                  <View style={styles.listItemValue}>
                    <Text style={styles.listText}>
                      {item?.count ? formatAsBrNumber(item.count) : 0}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
        </View>
      ) : null}

      {type !== 'cnae' && type !== 'city' ? (
        <View style={styles.wrapperWithPadding}>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.demographicAndEconomicSubtitle}>{subtitle}</Text>
          </View>
          <View style={styles.listContainer}>
            <View style={styles.listContainerColumn}>
              {wrapperValues?.firstColumnValue?.map((item, index: number) => (
                <View style={styles.listItem} key={index}>
                  <View style={styles.listItemDescription}>
                    <Text style={styles.listText}>{item?.description}</Text>
                  </View>
                  <View style={styles.listItemValue}>
                    <Text style={styles.listText}>
                      {item?.count ? formatAsBrNumber(item.count) : 0}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
            <View style={styles.listContainerColumn}>
              {wrapperValues?.secondColumnValue?.map((item, index: number) => (
                <View style={styles.listItem} key={index}>
                  <View style={styles.listItemDescription}>
                    <Text style={styles.listText}>{item?.description}</Text>
                  </View>
                  <View style={styles.listItemValue}>
                    <Text style={styles.listText}>
                      {item?.count ? formatAsBrNumber(item.count) : 0}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
        </View>
      ) : null}

      {type === 'city' ? (
        <>
          {subtitle ? (
            <View style={styles.titleCityContainer}>
              <Text style={styles.title}>{title}</Text>
              <Text style={styles.demographicAndEconomicSubtitle}>{subtitle}</Text>
            </View>
          ) : null}

          {subtitle === undefined ? (
            <View style={styles.titleCenterContainer}>
              <Text style={styles.title}>{title}</Text>
            </View>
          ) : null}

          <View style={styles.cityListContainerColumn}>
            <View style={styles.cityHeaderContainer}>
              <Text style={styles.cityHeaderChartTextTitle}>Censo</Text>
              <Text style={styles.cityHeaderChartText}>
                Dados extraídos do último Censo encontrado.
              </Text>
            </View>

            {values?.city?.map((item, index: number) => (
              <View style={styles.cityListItem} key={index}>
                <View style={styles.listItemDescriptionCenso}>
                  <Text style={styles.listText}>{item.description}</Text>
                  <Text style={styles.listSmallText}>
                    {item.year ? `(Censo: ${item.year})` : ''}
                  </Text>
                </View>
                <View style={styles.listItemValue}>
                  {item.year ? (
                    <Text style={styles.listText}>
                      {item.key === 'grossDomesticProduct' || item.key === 'incomePerCapita'
                        ? 'R$ '
                        : null}
                      {formatAsBrNumber(item.value)}
                      {item.key === 'area' ? ' km²' : null}
                      {item.key === 'populationDensity' ? ' hab/km²' : null}
                    </Text>
                  ) : (
                    <Text style={styles.listNoDataText}>{item.value}</Text>
                  )}
                </View>
              </View>
            ))}
          </View>

          <View style={styles.cityListContainerColumn}>
            <View style={[styles.cityHeaderContainer, { marginBottom: '10px' }]}>
              <Text style={styles.cityHeaderChartTextTitle}>Portal da transparência</Text>
              <Text style={styles.cityHeaderChartText}>
                A partir do ano base {values?.dataTransparency?.year}, a população reportada foi{' '}
                {formatAsBrNumber(values?.dataTransparency?.populationCount)} e os recursos
                transferidos foram
              </Text>
              <Text style={styles.cityHeaderChartText}>
                R${' '}
                {values?.dataTransparency?.transferredResources
                  ? formatAsBrNumber(values?.dataTransparency?.transferredResources)
                  : 0}
                .
              </Text>
            </View>
            <View style={{ paddingLeft: '5px' }}>
              <LegalEntityGeoprocessingMapDemarcationReportTablePdfComponent
                values={values.dataTransparency}
              />
            </View>
          </View>

          <View style={styles.cityListContainerColumn}>
            <View style={[styles.cityHeaderContainer, { marginBottom: '10px' }]}>
              <Text style={styles.cityHeaderChartTextTitle}>CAGED</Text>
              <Text style={styles.cityHeaderChartText}>
                Gráfico de admissões e demissões dos últimos 12 meses com dados.
              </Text>
            </View>
            <View style={styles.cityCenterContainerMap}>
              <View style={styles.imageView}>
                <Image src={`${chartImage}`} />
              </View>
            </View>
          </View>

          <View style={styles.cityListContainerColumn} />
        </>
      ) : null}
    </>
  )
}

export default memo(legalEntityGeoprocessingMapDemarcationReportListPdf)
