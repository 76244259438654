import {
  array,
  defineAction,
  object,
  string,
} from '~/prix'
import registerNewDevice from './registerNewDevice.action.json'

const registerNewDeviceDefinition = defineAction({
  ...registerNewDevice,
  input: object({
    device: string(),
    email: string(),
    userToken: string(),
    roles: array(string()),
    uf: string(),
  }),
  output: object({}),
})

export default registerNewDeviceDefinition
