import { array, defineAction, enumerated, number, object } from '~/prix'
import migrationMigrateOltpAction from './migrationMigrateOltp.action.json' assert { type: 'json' }

const migrationMigrateOltpDefinition = defineAction({
  ...migrationMigrateOltpAction,
  input: object({}),
  output: object({
    results: array(
      object({
        timestamp: number(),
        result: enumerated({ success: 'Sucesso', skip: 'Já executada' }),
      }),
    ),
  }),
})

export default migrationMigrateOltpDefinition
