import {
  array,
  boolean,
  date,
  defineAction,
  entity,
  enumerated,
  foreign,
  number,
  object,
  string,
} from '~/prix'
import fetchLegalEntityDetailsAction from './fetchLegalEntityDetails.action.json'
import { point } from '~/prix/types/geoJson'

const fetchLegalEntityDetailsDefinition = defineAction({
  ...fetchLegalEntityDetailsAction,
  input: object({
    cnpj: string(),
    id: string(),
  }),
  output: object({
    warnings: array(
      object({
        message: string(),
      }),
    ).isOptional(),
    legalEntityDetails: object({
      legalEntityId: foreign(entity('legalEntity').property('id')),
      cnpj: string(),
      corporateName: string(),
      tradeName: string(),
      country: string(),
      state: string(),
      city: string(),
      neighborhood: string(),
      street: string(),
      complement: string(),
      postalCode: string(),
      filter: enumerated({
        radius: 'Está no raio do usuario.',
        sameStreet: 'Está na mesma rua que o usuário.',
        sameNeighborhood: 'Está no mesmo bairro que o usuário.',
      }),
      economicActivity: string(),
      pointOnStreet: point(),
      distanceMeters: number(),
      withinProximities: boolean(),
    }),
    contact: object({
      email: string(),
      firstContactNumber: object({
        areaCode: string(),
        number: string(),
      }),
      secondContactNumber: object({
        areaCode: string(),
        number: string(),
      }),
    }),
    address: object({
      country: string(),
      state: string(),
      city: string(),
      neighborhood: string(),
      street: string(),
      number: string(),
      complement: string(),
      postalCode: string(),
    }),
    attendances: array(
      object({
        originId: string(),
        agentCpf: string(),
        startDate: date(),
        endDate: date(),
        instrument: enumerated({
          remoteConsultancy: 'Consultoria a distância',
          presentialConsultancy: 'Consultoria presencial',
          remoteReport: 'Informação a distância',
          presentialReport: 'Informação presencial',
          remoteTechnicalGuidance: 'Orientação técnica a distância',
          presentialTechnicalGuidance: 'Orientação técnica presencial',
        }),
        state: string(),
        dataSource: string(),
      }),
    ),
    cnaes: array(
      object({
        code: string(),
        activities: string(),
        description: string(),
        observations: string(),
      }),
    ),
    coordinates: point(),
  }),
})

export default fetchLegalEntityDetailsDefinition
