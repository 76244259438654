export const masks = {
  cpf: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
  rg: [/\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/],
  cep: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
  cnpj: [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
  ],
  phone: [
    '(',
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  cns: [
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
}

export function unmaskNumbers(input: string) {
  if (typeof input !== 'string') {
    throw new Error('Input to mask must be string')
  }

  return input.replace(/[^0-9]/gi, '')
}

export function maskNumbers(input: string, mask: Array<string | RegExp>) {
  if (typeof input !== 'string') {
    throw new Error('Input to mask must be string')
  }

  const cleanInput = unmaskNumbers(input)

  let result = ''
  for (let index = 0, position = 0; index < mask.length; index++) {
    if (mask[index] instanceof RegExp) {
      if (!cleanInput[position]) {
        return result
      }

      result += cleanInput[position]
      position++
    } else {
      result += mask[index]
    }
  }

  return result
}
