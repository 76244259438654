import React, { useMemo } from 'react'
import Select from 'react-select'
import Wrapper from '../../prix/react/components/form/field/wrapper'
import { colors } from '~/design'
import { EnumeratedType } from '~/prix/types/enumerated'
import { FieldProps } from '~/prix/react/hooks/form'
import styled from 'styled-components'

const OptionWrapper = styled.div`
  line-height: 1.1;

  span {
    display: block;
    line-height: 1.2;
    font-size: 16px;
  }
  small {
    display: block;
    font-size: 12px;
  }
`

interface OptionGroupBaseSelect {
  isDisabled: boolean
  isFocused: boolean
  isSelected: boolean
}

export default function AttendanceField<Schema extends EnumeratedType>({
  meta: { isDisabled = false, placeholder = 'Selecione...', label } = {},
  id,
  value,
  onDirectChange,
  fieldSchema: { keysAndLabels, currentMeta, currentRules },
  onBlur,
}: FieldProps<Schema> & { children?: React.ReactNode }) {
  const inputOptions = useMemo(
    () =>
      keysAndLabels?.map((key: { value: any; label: any; period: any }) => ({
        value: String(key.value),
        label: key.label,
        period: key?.period,
      })),
    [keysAndLabels],
  )

  const inputSelected = inputOptions?.find((item: any) => item.value === value)

  return (
    <Wrapper isRequired={currentRules.isRequired} label={currentMeta.label} id={id}>
      <Select
        isClearable={!currentRules.isRequired}
        escapeClearsValue
        value={
          value && inputSelected
            ? {
              value: value as string,
              label: inputSelected.label as string,
              period: inputSelected.period as string,
            }
            : null
        }
        onBlur={onBlur}
        styles={{
          container: base => ({
            ...base,
            flex: 1,
            minWidth: '100%',
            maxWidth: '200px',
          }),

          option: (styles: any, { isDisabled, isFocused, isSelected }: OptionGroupBaseSelect) => {
            return {
              ...styles,
              backgroundColor: isDisabled
                ? null
                : isSelected
                  ? colors.sebraeBlue
                  : isFocused
                    ? colors.lightBlue
                    : null,
              color: isDisabled
                ? '#ccc'
                : isSelected
                  ? '#fff'
                  : isFocused
                    ? 'rgba(0, 0, 0, 0.75)'
                    : styles.color,
              cursor: isDisabled ? 'not-allowed' : 'default',

              ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled && isSelected ? colors.sebraeBlue : colors.sebraeBlue,
                color: isDisabled && isSelected ? '#fff' : '#fff',
              },
            }
          },

          placeholder: base => ({
            ...base,
            display: '-webkit-box',
            'WebkitLineClamp': '2',
            'WebkitBoxOrient': 'vertical',
            textOverflow: 'ellipsis',
            overflowY: 'hidden',
          }),
        }}
        onChange={result => {
          {
            if (!onDirectChange) {
              return
            }
            onDirectChange(result ? result.value : null)
          }
        }}
        options={inputOptions}
        placeholder={placeholder}
        noOptionsMessage={() => 'Sem opções.'}
        loadingMessage={() => 'Carregando...'}
        inputId={id}
        isDisabled={isDisabled}
        formatOptionLabel={item => (
          <OptionWrapper>
            <span>{item.label}</span>
            {item?.period ? <small>{item.period}</small> : null}
          </OptionWrapper>
        )}
      />
    </Wrapper>
  )
}
