import { NumberType } from '../types/number'
import type { AbstractValueFunction, ValueFunctionContent } from './function'

export interface RoundFunction<
  TypeGeneric extends NumberType = NumberType,
  NativeGeneric extends number | null = number | null,
> extends AbstractValueFunction<NativeGeneric> {
  name: 'round'
  decimalPlaces: number
}

export default function round(
  content: ValueFunctionContent,
  decimalPlaces: number = 0,
): RoundFunction<NumberType, number | null> {
  return {
    kind: 'function',
    name: 'round',
    decimalPlaces,
    content,
  }
}
