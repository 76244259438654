import { array, dateTime, defineAction, number, object, string } from '~/prix'
import { lineString, point } from '~/prix/types/geoJson'
import fetchItineraryAction from './fetchItinerary.action.json'

const itineraryLegalEntityObject = object({
  id: number(),
  corporateName: string(),
  tradeName: string(),
  cnpj: string(),
  attendedAt: dateTime(),
  legalEntityAttendanceId: number(),
  address: object({
    country: string(),
    state: string(),
    city: string(),
    neighborhood: string(),
    street: string(),
    number: string(),
    complement: string(),
    postalCode: string(),
  }),
  coordinates: point(),
  contact: object({
    email: string().isOptional(),
    firstContactNumber: object({
      areaCode: string(),
      number: string(),
    }),
    secondContactNumber: object({
      areaCode: string().isOptional(),
      number: string().isOptional(),
    }),
  }),
})

export const itineraryObject = object({
  id: number(),
  createdAt: dateTime(),
  expiresAt: dateTime(),
  visited: array(itineraryLegalEntityObject),
  remaining: array(itineraryLegalEntityObject),
  streets: array(
    object({
      id: number(),
      name: string(),
      ways: array(
        object({
          fullLineString: lineString(),
          partialLineString: lineString(),
        }),
      ),
    }),
  ),
})

const fetchItineraryDefinition = defineAction({
  ...fetchItineraryAction,
  input: object({
    id: number().isOptional(),
    cpf: string().isOptional(),
  }),
  output: object({
    itinerary: itineraryObject,
  }),
})

export default fetchItineraryDefinition
