import React, { useMemo } from 'react'
import { QueryBase } from '~/prix/query'
import { truthy } from '~/prix/utils/types/boolean'
import useAPI from '../hooks/api'
import LoadingMessage from './loadingMessage'

export interface LoadingQueriesMessageProps {
  queries: QueryBase[]
}

export default function LoadingQueriesMessage({ queries }: LoadingQueriesMessageProps) {
  const { entities } = useAPI()

  const messages = useMemo(() => {
    return queries
      .map(query => {
        const entityKeys = [
          query.entityKey,
          ...query.currentJoins.map(join => join.target.entityKey),
        ]
        const queryEntities = entityKeys.map(entityKey =>
          entities.find(entity => entity.key === entityKey),
        )
        const entityNames = queryEntities.filter(truthy).map(entity => entity.title)

        const queryMessages = [
          ...entityNames.map(entityName => `Pesquisando por ${entityName.toLocaleLowerCase()}`),
          ...entityNames.map(entityName => `Resgatando dados de ${entityName.toLocaleLowerCase()}`),
          ...entityNames.map(entityName => `Relacionando ${entityName.toLocaleLowerCase()}`),
          query.currentOrder ? `Ordenando busca por ${entityNames[0].toLocaleLowerCase()}` : null,
        ].filter(truthy)

        return queryMessages
      })
      .flat()
  }, [queries, entities])

  return <LoadingMessage messages={messages} />
}
