import { Role } from '~/utils/shared/roles'
import { Type } from '.'

export type HttpTrigger = {
  trigger: 'http'
  method: 'GET' | 'POST'
  route: `/${string}`
  validateOrigin: boolean
}

export type WebsocketTrigger = {
  trigger: 'websocket'
  route: string
}

export type ChangeTrigger = {
  trigger: 'change'
}

export type CronTrigger = {
  trigger: 'cron'
  expression: string
}

export type Trigger = HttpTrigger | WebsocketTrigger | ChangeTrigger | CronTrigger

export type Copy = { from: string; to: string }

export type BaseTrigger =
  | {
      trigger: string
    }
  | Trigger
export interface BaseActionDefinition {
  type: string
  key: string
  description: string
  runtime: {
    platform: string
    memory: number
    long?: {
      machineType?: string
      storage?: number
      fileShareServers?: {
        serverName: string
      }[]
      attachedDisks?: string[]
      linuxDependencies?: string[]
      extraCommands?: string[]
    }
    extraLibraries?: string[]
    copy?: Copy[]
    timeout?: number
  }
  triggers: BaseTrigger[]
  access?: string
  restrictedToRoles?: Role[] | string[]
}

export interface ActionDefinition<
  PlatformRuntime extends 'python39' | 'nodejs16' | 'nodejs18' | 'nodejs20' =
    | 'python39'
    | 'nodejs16'
    | 'nodejs18'
    | 'nodejs20',
> extends BaseActionDefinition {
  type: 'action'
  key: string
  description: string
  triggers: Trigger[]
  runtime: {
    extraLibraries?: string[]
    copy?: Copy[]
    timeout?: number
    platform: PlatformRuntime
    memory: number
    long?: {
      machineType?: string
      storage?: number
      fileShareServers?: {
        serverName: string
      }[]
      attachedDisks?: string[]
      linuxDependencies?: string[]
      extraCommands?: string[]
      customDockerfile?: boolean
    }
  }
  access?: 'public' | 'private' | 'internal'
  restrictedToRoles?: Role[]
}

export interface BaseActionDefinitionWithInputOutput<
  InputType extends Type,
  OutputType extends Type,
> extends BaseActionDefinition {
  input: InputType
  output: OutputType
  operation?: 'create' | 'read' | 'update' | 'delete'
  targetEntity?: string
}

export interface ActionDefinitionWithInputOutput<InputType extends Type, OutputType extends Type>
  extends ActionDefinition {
  input: InputType
  output: OutputType
  operation?: 'create' | 'read' | 'update' | 'delete'
  targetEntity?: string
}

export default function defineAction<
  GenericBaseActionDefinitionWithInputOutput extends BaseActionDefinitionWithInputOutput<
    Type,
    Type
  >,
>(
  actionDefinition: GenericBaseActionDefinitionWithInputOutput,
): GenericBaseActionDefinitionWithInputOutput {
  return actionDefinition
}
