import React, { useCallback, useMemo } from 'react'
import { useTheme } from 'styled-components'
import * as math from 'mathjs'
import {
  ascending,
  entity,
  formatAsBrNumber,
  like,
  notNullNaNOrUndefined,
  query,
  string,
} from '~/prix'
import asGeoJson from '~/prix/functions/asGeoJson'
import Handle from '~/prix/react/components/handle'
import GoogleMaps from '~/prix/react/components/map/googleMaps'
import useItems from '~/prix/react/hooks/items'
import GeoJsonMultiPolygon from '~/prix/react/components/map/geoJsonMultiPolygon'
import { TimeSeriesDataProps } from './timeSeries.data'
import { contrastGradientColor } from '~/design'
import { InfoWindow } from '@react-google-maps/api'
import { InfoWindowWrapper } from '../legalEntityGeoprocessing/map/legalEntityGeoItem.component'
import type { TimeSeriesItem } from './timeSeries.entity'
import useKey from 'react-use/lib/useKey'
import { GeoJsonPoint } from '~/prix/types/geoJson'
import useAPI from '~/prix/react/hooks/api'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

export interface SelectedGeoItem {
  id: string
  abbreviation: string | undefined
  geoLevel: string
  items: TimeSeriesItem[]
}

export interface TimeSeriesMapProps {
  timeSeries: TimeSeriesDataProps
  geoLevel: 'state'
  aggregation?: 'sum' | 'mean' | 'median' | 'min' | 'max'
  selectedGeoItem?: SelectedGeoItem | null
  onClickGeoItem?: (geoItem: SelectedGeoItem | null) => void
  startString?: string | null
  endString?: string | null
  optionSelected?: string
}

export default function TimeSeriesMap({
  timeSeries,
  geoLevel,
  aggregation = 'sum',
  selectedGeoItem = null,
  onClickGeoItem,
  startString = null,
  endString = null,
  optionSelected,
}: TimeSeriesMapProps) {
  const [overGeoId, setOverGeoId] = React.useState<string | null>(null)
  const { context } = useAPI()
  const { pathname: currentPathname } = useLocation()
  const navigate = useNavigate()
  const {
    timeSeriesKey: timeSeriesKeyParam,
    state: stateParam,
    eventDate: eventDateParam,
  } = useParams()

  const hasUserStatePermission = context.user
    ? context.user.roles.some(item => item === 'agent' || item === 'stateManager')
    : false

  const theme = useTheme()
  const geoResult = useItems(
    () =>
      query(geoLevel)
        .select({
          id: entity(geoLevel).property('id'),
          name: entity(geoLevel).property('name'),
          boundary: asGeoJson(entity(geoLevel).property('lowerQualityBoundary')),
          center: asGeoJson(entity(geoLevel).property('center')),
          abbreviation:
            geoLevel === 'state' ? entity('state').property('abbreviation') : string().value(''),
        })
        .limit(10000),
    [geoLevel],
    {
      cache: 8 * 60 * 60 * 1000,
    },
  )

  const timeSeriesGeoResult = useItems(
    () =>
      query('timeSeries')
        .select({
          id: entity('timeSeries').property('id'),
          key: entity('timeSeries').property('key'),
          alertLevel: entity('timeSeries').property('alertLevel'),
          date: entity('timeSeries').property('date'),
          value: entity('timeSeries').property('value'),
          predictedValue: entity('timeSeries').property('predictedValue'),
          components: entity('timeSeries').property('components'),
        })
        .where(
          like(
            entity('timeSeries').property('key'),
            string().value(`${timeSeries.key}.${geoLevel}.%`),
          ),
        )
        .order(ascending('date'))
        .limit(10000000),
    [timeSeries.key, geoLevel],
    {
      cache: 8 * 60 * 60 * 1000,
    },
  )

  const aggregatedByGeoLevel = useMemo(() => {
    const timeSeriesItems = timeSeriesGeoResult.items
    const geoItems =
      hasUserStatePermission && context.user?.stateAbbreviation
        ? geoResult.items?.filter(geo => geo.abbreviation === context.user?.stateAbbreviation)
        : geoResult.items

    if (!timeSeriesItems || !geoItems) return null

    return geoItems.map(geo => {
      const identifier = `${timeSeries.key}.${geoLevel}.${geo.id}`
      const days = timeSeriesItems.filter(day => {
        if (day.key !== identifier) {
          return false
        }
        if (startString && (day.date as string) < startString) {
          return false
        }
        if (endString && (day.date as string) > endString) {
          return false
        }
        return true
      })
      const hasPredictedValue = days.some(day => day.predictedValue !== null)
      const values = days
        .map(day => day.value ?? day.predictedValue)
        .filter(notNullNaNOrUndefined) as number[]
      const value = math[aggregation](values)

      return {
        ...geo,
        value,
        hasPredictedValue,
        items: days,
      }
    })
  }, [
    timeSeriesGeoResult.items,
    geoResult.items,
    timeSeries.key,
    geoLevel,
    aggregation,
    startString,
    endString,
  ])

  const maxValue = useMemo(() => {
    if (!aggregatedByGeoLevel) return null
    return math.max(aggregatedByGeoLevel.map(geo => geo.value))
  }, [aggregatedByGeoLevel])

  const geoItems = useMemo(() => {
    if (!aggregatedByGeoLevel) return null
    return aggregatedByGeoLevel.map(geo => {
      const percentage = maxValue !== 0 ? geo.value / maxValue : 0
      const color = contrastGradientColor(percentage)
      const borderColor = color.darken(0.8).saturate(0.2)

      return {
        ...geo,
        color: color.hex('rgb'),
        borderColor: borderColor.hex('rgb'),
      }
    })
  }, [aggregatedByGeoLevel, maxValue, theme.colors.primary])

  const handleItemClick = useCallback(
    (id: string) => {
      setOverGeoId(id)
      if (!aggregatedByGeoLevel) {
        return
      }

      if (onClickGeoItem) {
        if (selectedGeoItem?.id === id && !hasUserStatePermission) {
          onClickGeoItem(null)
          return
        }

        const geoItem = aggregatedByGeoLevel.find(geo => String(geo.id) === id)
        if (!geoItem) return

        onClickGeoItem({
          id,
          abbreviation: String(geoItem.abbreviation),
          geoLevel,
          items: (geoItem?.items ?? []) as TimeSeriesItem[],
        })

        navigate(`/app/time-series/${timeSeriesKeyParam}/${id}`)
      }
    },
    [aggregatedByGeoLevel, selectedGeoItem, onClickGeoItem, setOverGeoId, hasUserStatePermission],
  )

  useMemo(() => {
    if (stateParam && aggregatedByGeoLevel && onClickGeoItem) {
      const geoItem = aggregatedByGeoLevel.find(geo => String(geo.id) === stateParam)
      if (!geoItem) return

      onClickGeoItem(
        !hasUserStatePermission && stateParam == '0'
          ? null
          : {
              id: !hasUserStatePermission ? stateParam : String(aggregatedByGeoLevel[0].id),
              geoLevel,
              items: (!hasUserStatePermission
                ? geoItem?.items
                : aggregatedByGeoLevel[0]?.items ?? []) as TimeSeriesItem[],
              abbreviation: String(geoItem.abbreviation) || undefined,
            },
      )

      if (hasUserStatePermission) {
        navigate(
          `/app/time-series/${timeSeriesKeyParam}/${String(aggregatedByGeoLevel[0].id)}${
            eventDateParam ? `/${eventDateParam}` : ''
          }`,
        )
      }
    }
  }, [
    stateParam,
    aggregatedByGeoLevel,
    hasUserStatePermission,
    context.user?.stateAbbreviation,
    onClickGeoItem,
  ])

  const handleMouseOver = useCallback((id: string) => {
    setOverGeoId(id)
  }, [])

  const handleMouseOut = useCallback(() => {
    setOverGeoId(null)
  }, [])

  const handleDispose = useCallback(() => {
    if (hasUserStatePermission) return

    setOverGeoId(null)
    if (onClickGeoItem) {
      onClickGeoItem(null)
    }
    navigate(`/app/time-series/${timeSeriesKeyParam}`)
  }, [onClickGeoItem, currentPathname])
  useKey('Escape', handleDispose, {}, [])

  if (optionSelected && optionSelected !== 'states') {
    return null
  }

  return (
    <Handle
      error={geoResult.error || timeSeriesGeoResult.error}
      isLoading={geoResult.isLoading || timeSeriesGeoResult.isLoading}
    >
      <GoogleMaps mapTypeId='satellite' zoom={hasUserStatePermission ? 6 : 4}>
        {geoItems?.map((geo, index) => (
          <React.Fragment key={index}>
            <GeoJsonMultiPolygon
              key={String(geo.id)}
              geoJson={geo.boundary}
              options={{
                fillColor: geo.color,
                fillOpacity: selectedGeoItem ? (String(geo.id) === selectedGeoItem?.id ? 1 : 0) : 1,
                strokeColor: geo.borderColor,
                strokeWeight: 1.5,
              }}
              onClick={() => handleItemClick(String(geo.id))}
              onMouseOver={() => handleMouseOver(String(geo.id))}
              onMouseOut={handleMouseOut}
            />
            {String(geo.id) === overGeoId ? (
              <InfoWindow
                position={{
                  lat: (geo.center as GeoJsonPoint).coordinates[1],
                  lng: (geo.center as GeoJsonPoint).coordinates[0],
                }}
                onCloseClick={handleDispose}
                options={{ disableAutoPan: true, pixelOffset: new google.maps.Size(10, -5) }}
              >
                <InfoWindowWrapper style={{ marginTop: '20px', marginBottom: '10px' }}>
                  <h1>{geo.name}</h1>
                  <span>{formatAsBrNumber(Math.round(geo.value))}</span>
                </InfoWindowWrapper>
              </InfoWindow>
            ) : null}
          </React.Fragment>
        ))}
      </GoogleMaps>
    </Handle>
  )
}
