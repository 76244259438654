import { array, boolean, defineAction, enumerated, number, object, string } from '~/prix'
import attendanceRadar from './attendanceRadar.action.json'
import { multiPolygon } from '~/prix/types/geoJson'

const attendanceRadarDefinition = defineAction({
  ...attendanceRadar,
  input: object({
    latitude: number(),
    longitude: number(),
    radius: number().isOptional(),
    limit: number().isOptional(),
    myAttendances: boolean().isOptional(),
    year: number().isOptional(),
    agentCpf: number().isOptional(),
    cpf: string(),
    sizeAbbreviature: enumerated({
      unknown: 'Desconhecido',
      mei: 'Micro Empreendedor Individual',
      me: 'Micro Empresa',
      epp: 'Empresa de Pequeno Porte',
    }).isOptional(),
  }),
  output: object({
    warnings: array(
      object({
        message: string(),
      }),
    ).isOptional(),
    bounds: multiPolygon().label('Perímetros do chunk carregado'),
    getStateAbbreviationByCoordinates: object({ stateAbbreviationByCoordinates: string() }),
    entities: array(
      object({
        legalEntityId: string(),
        cnpj: string(),
        corporateName: string(),
        tradeName: string(),
        economicActivity: string(),
        sizeAbbreviature: string(),
        legalEntityAttendanceId: string(),
        agentCpf: string(),
        attendanceDate: string(),
        country: string(),
        state: string(),
        city: string(),
        neighborhood: string(),
        street: string(),
        complement: string(),
        postalCode: string(),
        pointOnStreet: string(),
      }),
    ),
    neighborhoodId: string(),
    streetIds: array(string()),
  }),
})

export default attendanceRadarDefinition
