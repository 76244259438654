import number, { NumberType } from './number'
import { ObjectType, ShapeBase } from './object'
import createType from './type'

export interface GeographicPointShape extends ShapeBase {
  x: NumberType<number> // Longitude
  y: NumberType<number> // Latitude
}

const defaultGeographicPointShape = {
  x: number(), // Longitude
  y: number(), // Latitude
}

export interface GeographicPointType<
  NativeShapeGeneric extends GeographicPointShape | null = GeographicPointShape
> extends ObjectType<NativeShapeGeneric> {
  readonly type: 'object'
  readonly subType: 'geographicPoint'
  readonly shape: NativeShapeGeneric
  readonly currentValue?: NativeShapeGeneric
  isRequired(): GeographicPointType<Exclude<NativeShapeGeneric, null>>
  isOptional(): GeographicPointType<NativeShapeGeneric | null>
}

export default function geographicPoint<NativeShapeGeneric extends GeographicPointShape>(
  shape: NativeShapeGeneric = defaultGeographicPointShape as NativeShapeGeneric,
): GeographicPointType<NativeShapeGeneric> {
  return {
    ...createType<GeographicPointType<NativeShapeGeneric>>(),
    type: 'object',
    subType: 'geographicPoint',
    shape,
  }
}
