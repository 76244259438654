import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import SelectFromQuery from '~/prix/react/components/form/inputs/selectFromQuery'
import {
  count,
  entity,
  equals,
  like,
  query,
  string,
  truthy,
  enumerated,
  isNull,
  some,
  likeAny,
  boolean,
} from '~/prix'
import AppLayout from '~/components/appLayout'
import AgentReportProductionChart from './agentReportProductionChart.component'
import AgentReportAttendancesMap from './agentReportAttendancesMap.screen'
import AgentReportHistogram from './agentReportHistogram.screen'
import AgentReportCnaesList from './agentReportCnaesList.screen'
import { primaryColors } from '~/design'
import unaccent from '~/prix/functions/unaccent'
import AgentReportMap from './agentReportMap'
import useGoogleMaps from '~/prix/react/hooks/googleMaps'
import useConfig from '~/prix/react/hooks/config'
import { useNavigate, useParams } from 'react-router'
import useItems from '~/prix/react/hooks/items'
import EnumeratedField from '~/prix/react/components/form/field/enumeratedField'
import AgentReportSegmentList from './agentReportSegmentList.screen'
import AlertIcon from '~/components/icons/ui/16px_alert.svg'
import useAPI from '~/prix/react/hooks/api'
import DateField from '~/prix/react/components/form/inputs/dateTimeField'
import { format, subYears } from 'date-fns'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 36px;
  margin-bottom: 36px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border: 1px solid rgb(182, 191, 200);
  border-radius: 20px;

  &.filter {
    box-shadow: 0px 32px 30px 0px rgba(20, 46, 82, 0.2);
  }
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 73px;
  min-height: 73px;
  padding: 0 1.7rem;

  p {
    margin: 0;
    color: ${primaryColors.action};
    text-align: center;
    font-size: 19px;
    font-style: italic;
    font-weight: 700;
  }

  &.filter {
    border-radius: 8px 8px 0px 0px;
    background: ${primaryColors.action};

    p {
      color: #fff;
    }
  }
`

const SubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .warning {
    display: flex;
    margin-top: 8px;
    padding: 3px;

    .icon {
      flex-direction: column;
      padding-right: 5px;
    }

    .text {
      span {
        text-align: center;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .info {
    padding: 0 1.7rem;
    margin: 0;
    color: ${primaryColors.action};
    text-align: center;
    font-size: 17px;
    font-weight: 700;
  }
`

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 300px;
  width: 100%;
  padding: 26px 20px;
`

const Banner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${primaryColors.default};
  padding: 0px 32px 24px 32px;

  h1 {
    color: #fff;
  }

  > div {
    width: 100%;

    > p {
      margin: 0;
      font-size: 19px;
      font-style: italic;
      font-weight: 700;
      color: #fff;
      margin-bottom: 12px;
    }

    > div {
      display: flex;
      flex-direction: column;
      width: 45%;

      @media (max-width: 768px) {
        width: 100%;
      }

      label {
        margin: 0;
        font-size: 14px;
        color: #fff;
      }
    }

    .date-wrapper {
      flex-direction: row;
      padding-top: 12px;
      width: 45%;
      gap: 12px;

      @media (max-width: 515px) {
        flex-direction: column;
        width: 100%;
      }
    }
  }
`

interface DataSourceProps {
  dataSourceId: number | string
  dataSourceName: string
  count?: number
}

export default function AgentReportScreen() {
  const { context } = useAPI()
  const { google } = useGoogleMaps()
  const config = useConfig()
  const apiKey = config.services.googleConfig.apiKey

  const [attendantSelected, setAttendantSelected] = useState<{
    id: string
    name: string
    hash: string
  }>()
  const [dataSourceSelected, setDataSourceSelected] = useState<
    { id: string; name: string } | undefined
  >(undefined)
  const [isChartLoaded, setIsChartLoaded] = useState<boolean>(false)
  const [isAreaLoaded, setIsAreaLoaded] = useState<boolean>(false)
  const [paramDataSource, setParamDataSource] = useState<string | undefined>()
  const [histogramCount, setHistogramCount] = useState<number>(0)
  const [isDataSourceSelected, setIsDataSourceSelected] = useState<boolean>(false)
  const [currentHash, setCurrentHash] = useState<string | undefined>(undefined)
  const [startDate, setStartDate] = useState<string | null>(
    format(subYears(new Date(), 1), 'yyyy-MM-dd'),
  )
  const [endDate, setEndDate] = useState<string | null>(format(new Date(), 'yyyy-MM-dd'))

  const navigate = useNavigate()
  const { agentHash: paramsAgentHash, dataSourceId: paramsDataSourceId } = useParams()

  useMemo(() => {
    if (attendantSelected && currentHash !== attendantSelected?.hash) {
      setIsDataSourceSelected(false)
      setDataSourceSelected(prevDataSourceSelected => {
        if (isDataSourceSelected === true) {
          return { id: '0', name: '' }
        }
        return prevDataSourceSelected
      })
    }
  }, [attendantSelected, currentHash])

  useEffect(() => {
    if (attendantSelected && attendantSelected.hash && isDataSourceSelected === false) {
      const agentHash = attendantSelected.hash
      navigate(`/app/agent-report/${agentHash}/0`)
      setDataSourceSelected({ id: '0', name: '' })
    }
  }, [attendantSelected, isDataSourceSelected])

  useEffect(() => {
    if (
      attendantSelected &&
      attendantSelected.hash &&
      dataSourceSelected === undefined &&
      isDataSourceSelected === true
    ) {
      const agentHash = attendantSelected.hash
      navigate(`/app/agent-report/${agentHash}`)
    }
  }, [attendantSelected, dataSourceSelected, isDataSourceSelected])

  useEffect(() => {
    if (attendantSelected === undefined && currentHash !== undefined) {
      navigate(`/app/agent-report`)
    }
  }, [attendantSelected, currentHash])

  useEffect(() => {
    if (attendantSelected && dataSourceSelected && dataSourceSelected.id) {
      const agentHash = attendantSelected.hash
      const dataSourceId = dataSourceSelected.id
      navigate(`/app/agent-report/${agentHash}/${dataSourceId}`)
      setIsDataSourceSelected(true)
      setCurrentHash(agentHash)
    }
  }, [attendantSelected, dataSourceSelected])

  const agentInformations = useItems(
    () =>
      paramsAgentHash !== undefined
        ? query('legalEntityAttendanceAttendant')
            .select({
              hash: entity('legalEntityAttendanceAttendant').property('hash'),
              agentCpf: entity('legalEntityAttendanceAttendant').property('cpf'),
              name: entity('legalEntityAttendanceAttendant').property('coalesce'),
            })
            .where(
              ...[
                paramsAgentHash !== undefined
                  ? equals(
                      entity('legalEntityAttendanceAttendant').property('hash'),
                      string().value(paramsAgentHash),
                    )
                  : null,
              ].filter(truthy),
            )
            .limit(1)
        : (null as never),
    [paramsAgentHash],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: paramsAgentHash !== undefined,
    },
  )

  useMemo(() => {
    if (paramsDataSourceId) {
      setParamDataSource(paramsDataSourceId)
    }
  }, [paramsDataSourceId])

  const attendantListQueryFactory = useCallback(
    (input: string) =>
      query('legalEntityAttendanceAttendant')
        .select({
          hash: entity('legalEntityAttendanceAttendant').property('hash'),
          agentCpf: entity('legalEntityAttendanceAttendant').property('cpf'),
          name: entity('legalEntityAttendanceAttendant').property('coalesce'),
          order: entity('legalEntityAttendanceAttendant').property('name'),
          stateAbbreviation: entity('legalEntityAttendanceAttendant').property('stateAbbreviation'),
        })
        .where(
          ...[
            some(
              like(
                unaccent(entity('legalEntityAttendanceAttendant').property('name')),
                unaccent(
                  string().value(
                    `%${
                      context.user?.roles.includes('agent')
                        ? context.user.name
                        : input.replace(/\s/g, '%')
                    }%`,
                  ),
                ),
              ),
              like(
                entity('legalEntityAttendanceAttendant').property('cpf'),
                string().value(
                  `%${
                    context.user?.roles.includes('agent')
                      ? context.user.cpf
                      : input.replace(/\s/g, '%')
                  }%`,
                ),
              ),
            ),
            context.user?.roles.includes('stateManager') && context.user.stateAbbreviation
              ? likeAny(string().value(context.user.stateAbbreviation), [
                  entity('legalEntityAttendanceAttendant').property('stateAbbreviation') as any,
                ])
              : null,
          ].filter(truthy),
        )
        .limit(20),
    [context],
  )

  const databaseInformations = useItems(
    () =>
      attendantSelected !== undefined
        ? query('legalEntityAttendance')
            .select({
              dataSourceId: entity('legalEntityAttendanceDataSource').property('dataSourceId'),
              dataSourceName: entity('dataSource').property('name'),
              count: count(entity('legalEntityAttendance').property('id')),
            })
            .join({
              current: entity('legalEntityAttendance').property('id'),
              target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
              join: 'inner',
            })
            .join({
              current: entity('legalEntityAttendance').property('legalEntityId'),
              target: entity('legalEntity').property('id'),
              join: 'inner',
            })
            .join({
              current: entity('legalEntityAttendanceDataSource').property('dataSourceId'),
              target: entity('dataSource').property('id'),
              join: 'inner',
            })
            .join({
              current: entity('legalEntityAttendance').property('agentCpf'),
              target: entity('legalEntityAttendanceAttendant').property('cpf'),
              join: 'inner',
            })
            .where(
              ...[
                isNull(entity('legalEntity').property('deletedAt')),
                equals(entity('dataSource').property('isActive'), boolean().value(true)),
                attendantSelected.hash !== undefined
                  ? equals(
                      entity('legalEntityAttendanceAttendant').property('hash'),
                      string().value(attendantSelected.hash),
                    )
                  : null,
              ].filter(truthy),
            )
            .limit(10)
        : (null as never),
    [attendantSelected],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: attendantSelected !== undefined,
    },
  )

  const totalCount = databaseInformations.items?.reduce((acc, item) => acc + item.count, 0)

  const predefinedDataSources: DataSourceProps[] = [
    { dataSourceId: 0, dataSourceName: `Todos` },
    { dataSourceId: 3, dataSourceName: 'Radar ALI' },
    { dataSourceId: 4, dataSourceName: 'Brasil Mais' },
    { dataSourceId: 6, dataSourceName: 'SEBRAE Na Sua Empresa (App)' },
  ]

  const formattedData: Record<number, string> = {}

  databaseInformations.items?.map((item: any) => {
    if (item.count !== undefined) {
      formattedData[
        item.dataSourceId
      ] = `${item.dataSourceName}: Atendimentos registrados ao longo do tempo (${item.count})`
    }
  })

  predefinedDataSources.forEach((predefinedItem: any) => {
    if (formattedData[predefinedItem.dataSourceId] === undefined) {
      formattedData[predefinedItem.dataSourceId] = `${
        predefinedItem.dataSourceName
      }: Atendimentos registrados ao longo do tempo ${
        predefinedItem.dataSourceId === 0 ? `(${totalCount})` : '(0)'
      }`
    }
  })

  const handleAttendant = useCallback((type, valueId, valueName, hash) => {
    if (type === 'attendant' && valueId && valueName) {
      setAttendantSelected({ id: valueId, name: valueName, hash })
    } else if (type === 'attendant' && !valueId) {
      setAttendantSelected(undefined)
      setParamDataSource(undefined)
    }

    if (type === 'dataSource' && valueId) {
      setDataSourceSelected({ id: valueId, name: valueName })
    } else if (type === 'dataSource' && !valueId) {
      setDataSourceSelected(undefined)
    }
  }, [])

  useMemo(() => {
    if (agentInformations.isLoading === false) {
      if (
        attendantSelected === undefined &&
        agentInformations.items &&
        agentInformations.items?.length > 0
      ) {
        const valueId = agentInformations.items[0].agentCpf
        const valueName = agentInformations.items[0].name
        const valueHash = agentInformations.items[0].hash

        setAttendantSelected({
          id: String(valueId),
          name: String(valueName),
          hash: String(valueHash),
        })
      } else if (agentInformations.query === null) {
        setAttendantSelected(undefined)
      }
    }
  }, [agentInformations.items])

  useMemo(() => {
    if (paramDataSource) {
      setDataSourceSelected({ id: paramDataSource, name: '' })
    }

    if (paramDataSource === undefined) {
      setDataSourceSelected(undefined)
    }
  }, [paramDataSource])

  const fetchSelf = useItems(
    () =>
      context && context.user?.roles.includes('agent')
        ? query('legalEntityAttendanceAttendant')
            .select({
              hash: entity('legalEntityAttendanceAttendant').property('hash'),
              agentCpf: entity('legalEntityAttendanceAttendant').property('cpf'),
              name: entity('legalEntityAttendanceAttendant').property('coalesce'),
              order: entity('legalEntityAttendanceAttendant').property('name'),
              stateAbbreviation: entity('legalEntityAttendanceAttendant').property(
                'stateAbbreviation',
              ),
            })
            .where(
              like(
                entity('legalEntityAttendanceAttendant').property('cpf'),
                string().value(`${context.user.cpf}`),
              ),
            )
            .limit(1)
        : (null as never),
    [context.user?.roles],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: context.user && context.user.roles.includes('agent') ? true : false,
    },
  )

  useMemo(() => {
    if (
      context.user?.roles.includes('agent') &&
      fetchSelf.isLoading === false &&
      fetchSelf.items &&
      fetchSelf.items?.length > 0
    ) {
      handleAttendant(
        'attendant',
        fetchSelf.items[0].agentCpf,
        fetchSelf.items[0].name,
        fetchSelf.items[0].hash,
      )
    }
  }, [fetchSelf.isLoading, fetchSelf.items])

  return (
    <AppLayout title='Relatório interativo de agente' dockActive='start'>
      <Banner>
        <h1>Relatório interativo de agente</h1>
        <div>
          <div>
            <label style={{ paddingBottom: '6px' }}>Agente</label>
            <SelectFromQuery
              placeholder='Buscar...'
              idProperty='id'
              isSearchInput={true}
              labelProperty='name'
              isDisabled={context.user?.roles.includes('agent') ? true : false}
              queryFactory={attendantListQueryFactory}
              value={
                attendantSelected?.id
                  ? { id: attendantSelected.id, name: attendantSelected.name }
                  : undefined
              }
              onDirectChange={value =>
                handleAttendant('attendant', value?.agentCpf, value?.name, value?.hash)
              }
            />
          </div>
          <div style={{ paddingTop: '12px' }}>
            <EnumeratedField
              fieldSchema={
                databaseInformations.isLoading === false || databaseInformations.items === undefined
                  ? enumerated(formattedData).isOptional()
                  : enumerated({ 1: 'Carregando...' }).isOptional()
              }
              value={
                databaseInformations.isLoading === true || databaseInformations.items === undefined
                  ? 0
                  : dataSourceSelected && dataSourceSelected.id
                  ? dataSourceSelected.id
                  : undefined
              }
              isLoading={databaseInformations.isLoading}
              meta={{
                isDisabled:
                  !attendantSelected?.id || databaseInformations.isLoading === true ? true : false,
                placeholder:
                  databaseInformations.isLoading === true ||
                  (attendantSelected?.id && databaseInformations.items === undefined)
                    ? 'Carregando...'
                    : 'Selecione...',
                label: 'Fonte de dados',
              }}
              onDirectChange={value => handleAttendant('dataSource', value, '', undefined)}
            />
          </div>
          <div className='date-wrapper'>
            <DateField
              id={`start-${startDate}`}
              value={startDate}
              label='Data de início'
              onDirectChange={date => setStartDate(date ? format(date, 'yyyy-MM-dd') : null)}
              placeholder='Início'
              meta={{
                maxDate: new Date(),
                minDate: subYears(new Date(), 3),
              }}
              isRequired
            />
            <DateField
              id={`end-${startDate}`}
              value={endDate}
              label='Data final'
              onDirectChange={date => setEndDate(format(date, 'yyyy-MM-dd'))}
              placeholder='Final'
              meta={{
                maxDate: new Date(),
                minDate: subYears(new Date(), 3),
              }}
              isRequired
            />
          </div>
        </div>
      </Banner>
      <Wrapper>
        {attendantSelected && dataSourceSelected && startDate && endDate ? (
          <>
            <Container>
              <TitleContainer style={{ height: '15px', minHeight: '15px', paddingTop: '35px' }}>
                <p>Mapa do Agente</p>
              </TitleContainer>
              <ComponentWrapper style={{ minHeight: '500px' }}>
                <AgentReportMap
                  agent={attendantSelected}
                  dataSource={
                    dataSourceSelected && dataSourceSelected?.id === '0'
                      ? undefined
                      : dataSourceSelected
                  }
                  google={google}
                  startDate={startDate}
                  endDate={endDate}
                />
                <SubtitleContainer>
                  <div className='warning'>
                    <div className='icon'>
                      <AlertIcon fill={'#f1c40f'} width={14} height={14} />
                    </div>
                    <div className='text'>
                      <span>
                        Alguns agentes não possuem atendimentos suficientes para gerar uma área de
                        atendimento.
                      </span>
                    </div>
                  </div>
                </SubtitleContainer>
              </ComponentWrapper>
            </Container>

            {isAreaLoaded || (attendantSelected && dataSourceSelected) ? (
              <Container>
                <TitleContainer>
                  <p>Mapa de Atendimentos</p>
                </TitleContainer>
                <ComponentWrapper style={{ minHeight: '700px' }}>
                  <AgentReportAttendancesMap
                    agent={attendantSelected}
                    dataSource={
                      dataSourceSelected && dataSourceSelected?.id === '0'
                        ? undefined
                        : dataSourceSelected
                    }
                    setIsMapLoaded={setIsAreaLoaded}
                    apiKey={apiKey}
                    startDate={startDate}
                    endDate={endDate}
                  />
                  <SubtitleContainer>
                    <div className='warning'>
                      <div className='icon'>
                        <AlertIcon fill={'#f1c40f'} width={14} height={14} />
                      </div>
                      <div className='text'>
                        <span>
                          Alguns pontos de partida (unidade de serviço) não possuem geolocalização
                          cadastrada. Nesses casos, foi utilizado um ponto central como referência.
                        </span>
                      </div>
                    </div>
                  </SubtitleContainer>
                </ComponentWrapper>
              </Container>
            ) : null}

            <Container>
              <TitleContainer>
                <p>Gráfico de Produtividade</p>
              </TitleContainer>
              <ComponentWrapper style={{ paddingLeft: 0 }}>
                <AgentReportProductionChart
                  attendant={attendantSelected}
                  dataSource={
                    dataSourceSelected && dataSourceSelected?.id === '0'
                      ? undefined
                      : dataSourceSelected
                  }
                  setIsChartLoaded={setIsChartLoaded}
                  startDate={startDate}
                  endDate={endDate}
                />
              </ComponentWrapper>
            </Container>

            {isChartLoaded && (
              <>
                <Container>
                  <TitleContainer>
                    <p>Histograma de Idade das Empresas Atendidas</p>
                  </TitleContainer>
                  {histogramCount > 0 ? (
                    <SubtitleContainer>
                      <span className='info'>Análise de {histogramCount} empresas</span>
                    </SubtitleContainer>
                  ) : null}
                  <ComponentWrapper>
                    <AgentReportHistogram
                      agent={attendantSelected}
                      dataSource={
                        dataSourceSelected && dataSourceSelected?.id === '0'
                          ? undefined
                          : dataSourceSelected
                      }
                      setHistogramCount={setHistogramCount}
                      startDate={startDate}
                      endDate={endDate}
                    />
                    <SubtitleContainer>
                      <div className='warning'>
                        <div className='icon'>
                          <AlertIcon fill={'#f1c40f'} width={14} height={14} />
                        </div>
                        <div className='text'>
                          <span>
                            Algumas empresas foram desconsideradas por não possuírem data de
                            abertura registrada.
                          </span>
                        </div>
                      </div>
                    </SubtitleContainer>
                  </ComponentWrapper>
                </Container>

                <AgentReportCnaesList
                  agent={attendantSelected}
                  dataSource={
                    dataSourceSelected && dataSourceSelected?.id === '0'
                      ? undefined
                      : dataSourceSelected
                  }
                  startDate={startDate}
                  endDate={endDate}
                />
                <AgentReportSegmentList
                  agent={attendantSelected}
                  dataSource={
                    dataSourceSelected && dataSourceSelected?.id === '0'
                      ? undefined
                      : dataSourceSelected
                  }
                  startDate={startDate}
                  endDate={endDate}
                />
              </>
            )}
          </>
        ) : !startDate || !endDate ? (
          <ComponentWrapper>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <div className='icon'>
                <AlertIcon fill={'#f1c40f'} width={24} height={24} />
              </div>
              <h3>Selecione uma data de início e uma data final para visualizar as informações.</h3>
            </div>
          </ComponentWrapper>
        ) : (
          <ComponentWrapper>
            {context.user?.roles.includes('agent') &&
            fetchSelf.isLoading === false &&
            fetchSelf.items &&
            fetchSelf.items?.length === 0 ? (
              <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <div className='icon'>
                  <AlertIcon fill={'#f1c40f'} width={24} height={24} />
                </div>
                <h3>Não foi possível localizar dados do agente.</h3>
              </div>
            ) : (
              <p>Escolha um agente e uma fonte de dados para visualizar as informações.</p>
            )}
          </ComponentWrapper>
        )}
      </Wrapper>
    </AppLayout>
  )
}
