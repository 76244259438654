import { defineEntity, number, string, object, boolean } from '~/prix'

const courseProductEntity = defineEntity({
  key: 'courseProduct',
  title: 'Cursos EaD',
  sources: ['olap', 'oltp'],
  schema: object({
    id: number().label('ID', 'Identificador', 'Código'),
    productCode: string().label('Código do produto'),
    portfolioSasCode: string().label('Código SAS portfólio'),
    productType: number().label('Tipo do produto'),
    productName: number().label('Nome do produto'),
    isActivePortfolio: boolean().label('Ativo no portfolio atual do SEBRAE'),
    cnpjCount: number().label('Quantidade de CNPJs'),
  }),
})

export default courseProductEntity
