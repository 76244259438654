import {
  defineAction,
  object,
  string,
} from '~/prix'
import readNotification from './readNotification.action.json'

const readNotificationDefinition = defineAction({
  ...readNotification,
  input: object({
    userEmail: string(),
  }),
  output: object({}),
})

export default readNotificationDefinition
