import { boolean, defineAction, object, string } from '~/prix'
import sincronizeAppSebraeAction from './sincronizeAppSebrae.action.json'

const sincronizeAppSebraeDefinition = defineAction({
  ...sincronizeAppSebraeAction,
  input: object({
    id: string(),
    force: boolean(),
    verbose: boolean(),
    onlyMissing: boolean(),
    skipInternalGeocoding: boolean(),
  }),
  output: object({
    ok: boolean(),
  }),
})

export default sincronizeAppSebraeDefinition
