export function camelCaseToSnakeCase(input: string) {
  return input.replace(/\.?([A-Z]+)/g, (x, y) => `_${y.toLowerCase()}`)
}

export function camelCaseToDashCase(input: string) {
  return input.replace(/\.?([A-Z]+)/g, (x, y) => `-${y.toLowerCase()}`)
}

export function snakeCaseToCamelCase(input: string) {
  return input.replace(/(_\w)/g, m => m[1].toUpperCase())
}

export function dashCaseToCamelCase(input: string) {
  return input.replace(/(-\w)/g, m => m[1].toUpperCase())
}

export function charLettersOnly(input: string) {
  return input.replace(/[^a-zA-Z]/gi, '')
}

export function charLettersAndNumbersOnly(input: string) {
  return input.replace(/[^0-9a-zA-Z]/gi, '')
}

export function charNumbersOnly(input: string) {
  return input.replace(/[^0-9]/gi, '')
}

export function sqlCharSafeOnly(input: string) {
  return input.replace(/[^0-9a-zA-Z_]/gi, '')
}

export function capitalizeFirstLetter(input: string) {
  return input.charAt(0).toUpperCase() + input.slice(1)
}

export function capitalizeWords(input: string) {
  return input.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase())
}

export function stringToNumberHash(input: string) {
  return Math.abs(
    Array.from(input).reduce(
      (result, character) => result ^ (character.charCodeAt(0) * (result || 1)),
      input.charCodeAt(0),
    ),
  )
}

export function removeSpecialCharacter(input: string) {
  input = input.toLowerCase()
  input = input.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a')
  input = input.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e')
  input = input.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i')
  input = input.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o')
  input = input.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u')
  input = input.replace(new RegExp('[Ç]', 'gi'), 'c')
  return input
}

export function removeUselessWords(input: string) {
  const uselessWordsArray = ['av', 'avenida', 'rua', 'gov', 'beco', 'linha', 'travessa', 'da']
  const expStr = uselessWordsArray.join('|')
  return input.replace(new RegExp('\\b(' + expStr + ')\\b', 'gi'), ' ').replace(/\s{2,}/g, '')
}

export function stringNumberToLiteral(input: string): string {
  if (input == '1') {
    return 'um'
  }
  if (input == '2') {
    return 'dois'
  }
  if (input == '3') {
    return 'três'
  }
  if (input == '4') {
    return 'quatro'
  }
  if (input == '5') {
    return 'cinco'
  }
  if (input == '6') {
    return 'seis'
  }
  if (input == '7') {
    return 'sete'
  }
  if (input == '8') {
    return 'oito'
  }
  if (input == '9') {
    return 'nove'
  }
  if (input == '10') {
    return 'dez'
  }
  if (input == '11') {
    return 'onze'
  }
  if (input == '12') {
    return 'doze'
  }
  if (input == '13') {
    return 'treze'
  }
  if (input == '14') {
    return 'quatorze'
  }
  if (input == '15') {
    return 'quinze'
  }
  if (input == '16') {
    return 'dezesseis'
  }
  if (input == '17') {
    return 'dezessete'
  }
  if (input == '18') {
    return 'dezoito'
  }
  if (input == '19') {
    return 'dezenove'
  }
  if (input == '20') {
    return 'vinte'
  }
  if (input.length == 2 && input[0] === '2') {
    return `vinte e ${stringNumberToLiteral(input[1])}`
  }
  if (input == '30') {
    return 'trinta'
  }
  if (input.length == 2 && input[0] === '3') {
    return `trinta e ${stringNumberToLiteral(input[1])}`
  }
  if (input == '40') {
    return 'quarenta'
  }
  if (input.length == 2 && input[0] === '4') {
    return `quarenta e ${stringNumberToLiteral(input[1])}`
  }
  if (input == '50') {
    return 'cinquenta'
  }
  if (input.length == 2 && input[0] === '5') {
    return `cinquenta e ${stringNumberToLiteral(input[1])}`
  }
  if (input == '60') {
    return 'sessenta'
  }
  if (input.length == 2 && input[0] === '6') {
    return `sessenta e ${stringNumberToLiteral(input[1])}`
  }
  if (input == '70') {
    return 'setenta'
  }
  if (input.length == 2 && input[0] === '7') {
    return `setenta e ${stringNumberToLiteral(input[1])}`
  }
  if (input == '80') {
    return 'oitenta'
  }
  if (input.length == 2 && input[0] === '8') {
    return `oitenta e ${stringNumberToLiteral(input[1])}`
  }
  if (input == '90') {
    return 'noventa'
  }
  if (input.length == 2 && input[0] === '9') {
    return `noventa e ${stringNumberToLiteral(input[1])}`
  }
  if (input >= '100') {
    return ''
  }
  return ''
}
