import { StringType } from './string'
import createType from './type'

export type KeysAndLabels<Keys extends string> = {
  [Key in Keys]: string
}

export interface EnumeratedType<NativeGeneric extends string | null = string | null>
  extends StringType<NativeGeneric> {
  readonly type: 'string'
  readonly subType: 'enumerated'
  readonly keysAndLabels: KeysAndLabels<string>
  readonly currentValue?: NativeGeneric
  isRequired(): EnumeratedType<Exclude<NativeGeneric, null>>
  isOptional(): EnumeratedType<NativeGeneric | null>
}

export default function enumerated<NativeGeneric extends string>(
  keysAndLabels: KeysAndLabels<NativeGeneric>,
): EnumeratedType<NativeGeneric> {
  return {
    ...createType<EnumeratedType<NativeGeneric>>(),
    type: 'string',
    subType: 'enumerated',
    keysAndLabels,
  }
}
