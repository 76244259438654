import { Context } from './context'
import { Entity, EntityKey } from './entity'
import errors, { AppError } from './error'
import { QueryBase, Selectables, SelectablesToItem, SerializableQuery } from './query'
import type {
  Source,
  SourceReadResolution,
  ReadResponse,
  RunOptions,
  Sources,
} from './sources/source'
import { intersections } from './utils/types/set'

interface Resolve<
  MainEntityKey extends EntityKey,
  MainEntityKeyAlias extends string,
  Selection extends Selectables = {},
> {
  sources: Sources
  entities: Array<Entity<string, any>>
  query: SerializableQuery<MainEntityKey, MainEntityKeyAlias, Selection>
  context: Context
  onAbort?: () => void
}

export default function resolve<
  MainEntityKey extends EntityKey,
  MainEntityKeyAlias extends string,
  Selection extends Selectables = {},
>({
  sources,
  entities,
  query,
  context,
  onAbort,
}: Resolve<MainEntityKey, MainEntityKeyAlias, Selection>) {
  let isAborted = false
  let hasFinished = false
  let sourceResolution:
    | SourceReadResolution<MainEntityKey, MainEntityKeyAlias, Selection>
    | undefined

  const abort = async () => {
    isAborted = true
    if (!sourceResolution || hasFinished) {
      return
    }
    await sourceResolution.abort()
  }
  const run = async ({
    onResponse = () => undefined,
  }: Partial<RunOptions<ReadResponse<MainEntityKey, MainEntityKeyAlias, Selection>>> = {}) => {
    if (isAborted) {
      throw errors.abort()
    }

    const entityKeys = new Set<string>()
    const addQueryEntities = (currentQuery: QueryBase) => {
      entityKeys.add(currentQuery.entityKey)
      currentQuery.currentJoins.forEach(join => entityKeys.add(join.current.entityKey))
      currentQuery.currentJoins.forEach(join => entityKeys.add(join.target.entityKey))
      currentQuery.currentUnions?.forEach(addQueryEntities)
    }
    addQueryEntities(query)

    const entityKeysArray = Array.from(entityKeys)
    const candidateEntities = entityKeysArray.map(
      key => entities.find(entity => entity.key === key) || null,
    )
    const entitiesToVerify = candidateEntities as Array<Entity<string, any, string[]>>
    if (candidateEntities.includes(null)) {
      throw errors.incompatible(`Uma entidade "${entityKeysArray.join(', ')}" está indisponível`)
    }

    const targetEntity = entitiesToVerify[0]
    if (!context.bypassAuthorization) {
      // const incompatibleEntity = entitiesToVerify.find(
      //   entity => !checkPermission(entity.readPermission, context),
      // )
      // if (incompatibleEntity) {
      //   throw errors.permission(undefined, {
      //     name: incompatibleEntity.key,
      //     title: incompatibleEntity.title,
      //   })
      // }
    }

    const entitySources = entitiesToVerify.map(
      entity => entity.sources.map(sourceKey => sources[sourceKey]).filter(Boolean) as Source[],
    )
    const sourceCandidates = intersections(...entitySources)
    if (!sourceCandidates.length) {
      throw errors.incompatible(`Não há suporte para sources cruzadas no momento`)
    }

    let source: Source | undefined

    const sourceEntries = Object.entries(sources)

    async function queryTargetSource(name: string, targetSource: Source) {
      source = targetSource

      try {
        sourceResolution = await source.read({
          query,
          context,
          onAbort: () => {
            if (hasFinished || !onAbort) {
              return
            }
            onAbort()
          },
        })
        if (isAborted) {
          throw errors.abort()
        }

        let finishPromise: Promise<any> | null = null
        const currentResponse: {
          items: SelectablesToItem<Selection>[]
        } = await new Promise((resolve, reject) => {
          if (!sourceResolution) {
            return
          }

          finishPromise = sourceResolution
            .run({
              onResponse: response => {
                onResponse(response)
                // Clickhouse não suporta JSONs nativamente (ainda), então os jsons são convertidos
                // no momento da requisição para que as respostas sejam as mesmas caso fossem no postgres.
                if (name === 'olap') {
                  response.items = response.items.map(item => {
                    const newItem: Record<string, unknown> = {}
                    for (const [key, value] of Object.entries(item)) {
                      try {
                        newItem[key] = JSON.parse(value as string)
                      } catch (err) {
                        newItem[key] = value
                      }
                    }
                    return newItem as SelectablesToItem<Selection>
                  })
                }
                resolve(response)
              },
            })
            .catch(reject)
        })

        await finishPromise

        hasFinished = true
        return {
          firstResponse: currentResponse,
        }
      } catch (err) {
        console.error(err)
        throw err
      }
    }

    //Checar custo da query em OLTP
    const [_, targetSource] = sourceEntries.find(item => item[0] === 'oltp') ?? []

    let oltpCost

    if (
      targetSource &&
      (await targetSource.checkAvailability({ context })) &&
      targetSource.cost !== undefined
    ) {
      source = targetSource as Source
      try {
        sourceResolution = await source.cost({
          query,
          context,
        })
        let finishPromiseCost: Promise<any> | null = null
        const currentResponseCost: number = await new Promise((resolve, reject) => {
          if (sourceResolution) {
            finishPromiseCost = sourceResolution
              .run({
                onResponse: (
                  response: any /* {items: [{ 'QUERY PLAN': [{ Plan: { 'Total Cost': number } }] }]} */,
                ) => {
                  resolve(response.items[0]['QUERY PLAN'][0]['Plan']['Total Cost'])
                },
              })
              .catch(reject)
          }
        })
        await finishPromiseCost
        oltpCost = currentResponseCost

        const minCost = 15 * 1000 // Se o custo for baixo (abaixo de 15k) usar o postgresql.
        if (oltpCost && oltpCost < minCost) {
          if (targetSource && (await targetSource.checkAvailability({ context }))) {
            source = targetSource as Source

            try {
              return await queryTargetSource('oltp', targetSource)
            } catch (err) {
              console.error(err)
            }
          }
        }
      } catch (err) {
        console.error(err)
      }
    }

    for (const targetSourceEntry of sourceEntries) {
      const [name, targetSource] = targetSourceEntry

      if (targetSource && (await targetSource.checkAvailability({ context }))) {
        try {
          return await queryTargetSource(name, targetSource)
        } catch (err) {
          continue
        }
      }
    }
    if (!source) {
      throw errors.incompatible(`Fontes de dados "${targetEntity.sources}" indisponíveis`)
    }
  }

  return { abort, run }
}
