import { boolean, defineAction, object } from '~/prix'
import importCagedDataAction from './importCagedData.action.json' assert { type: 'json' }

const importCagedDataDefinition = defineAction({
  ...importCagedDataAction,
  input: object({
    forceRecreation: boolean(),
  }),
  output: object({}),
})

export default importCagedDataDefinition
