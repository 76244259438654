import { HeatmapLayer, Marker, Polygon } from '@react-google-maps/api'
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import useItems from '~/prix/react/hooks/items'
import AgentAreaDemarcationQuery from './queries/agentAreaDemarcationQuery.query'
import * as turf from '@turf/helpers'
import bbox from '@turf/bbox'
import { colors } from '~/design'
import { PointFeatureWithRadius } from '~/prix/react/components/map/radiusEditor'
import GeoJsonMultiPolygon, {
  PolygonOptionsProps,
} from '~/prix/react/components/map/geoJsonMultiPolygon'
import GeoJsonLineString from '~/prix/react/components/map/geoJsonLineString'
import GeoJsonMultiLineString from '~/prix/react/components/map/geoJsonMultiLineString'
import {
  GeoJsonMultiPolygon as GeoJsonMultiPolygonType,
  GeoJsonLineString as GeoJsonLineStringType,
  GeoJsonMultiLineString as GeoJsonMultiLineStringType,
} from '~/prix/types/geoJson'
import center from '@turf/center'
import useActionFunction from '~/prix/react/hooks/actionFunction'
import searchAgentIntersectionsDefinition from './intersections/searchAgentIntersections.definition'
import attendantVisitLocationsListQuery from '../attendant/visitLocationsList/visitLocationsList.query'
import { QueryBase } from '~/prix/query'
import { Features } from '../attendant/map/attendantMap.component'
import { AttendantAndDataSourceProps } from './agentReportProductionChart.component'
import useAsync from 'react-use/lib/useAsync'
import canvasDrawToURL from '~/prix/utils/canvasDrawToURL'
import seedAgentReportHeatmapDefinition from './heatmap/generateAgentReportHeatmapData.definition'
import GoogleMaps from '~/prix/react/components/map/googleMaps'
import Modal from '~/prix/react/components/modal'
import AgentIntersectionModal from './agentIntersectionModal.component'

const TotalizersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 16px;
`

const ResultContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 4px 4px;
  gap: 4px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-height: 650px;
  min-width: 400px;
  border-radius: 12px;

  @media (max-width: 500px) {
    min-width: 250px;
  }
`

const OverlayLoading = styled.div`
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 94, 184, 0.6);
  backdrop-filter: blur(5px);
  align-items: center;
  text-align: center;

  .center {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .message {
      margin-top: 10px;
      color: #fff;
      font-weight: bold;
      text-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
    }
  }

  .center-error {
    border-radius: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 30px 30px 20px 30px;
    background-color: #fff;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    cursor: default;

    .row {
      display: flex;
      flex-direction: row;
    }

    .column {
      display: flex;
      flex-direction: column;
      margin-right: 5px;
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 15px;
    }
  }
`

const Subtitles = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 16px 4px;
`

const SubtitleItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 6px 18px 6px 0;

  span {
    padding-top: 2px;
  }
`

const Square = styled.div`
  width: 12px;
  height: 12px;
  padding: 6px;
  border: 1px solid lightgray;
`

function PlotFeature({
  feature,
  polygonOptions,
  currentFeature,
  setCurrentFeature,
}: {
  feature: PointFeatureWithRadius | turf.Feature
  polygonOptions?: PolygonOptionsProps
  currentFeature?: number
  setCurrentFeature?: Dispatch<SetStateAction<number | undefined>>
}) {
  const isPolygon = feature.geometry?.type === 'Polygon'
  const polygonPoints = isPolygon
    ? (feature as turf.Feature<turf.Polygon>).geometry?.coordinates[0].map(coordinate => ({
        lat: coordinate[1],
        lng: coordinate[0],
      }))
    : []

  const isPoint = feature.geometry?.type === 'Point'
  const point = isPoint
    ? {
        lat: (feature as turf.Feature<turf.Point>).geometry?.coordinates[1],
        lng: (feature as turf.Feature<turf.Point>).geometry?.coordinates[0],
      }
    : null

  const isMultiPolygon = feature.geometry?.type === 'MultiPolygon'
  const isLineString = feature.geometry?.type === 'LineString'
  const isMultiLineString = feature.geometry?.type === 'MultiLineString'

  const options = {
    options: {
      fillColor: colors.sebraeBlue,
      fillOpacity: 0.2,
      strokeColor: colors.sebraeBlue,
      strokeOpacity: 1,
      strokeWeight: 2,
    },
  }

  const polygonsPaths = useMemo(() => {
    if (isMultiPolygon) {
      return feature.geometry.coordinates.map((polygonCoordinates: [any, any][][]) =>
        polygonCoordinates[0].map(([lng, lat]) => ({ lat, lng })),
      )
    }
  }, [feature])

  return (
    <>
      {isPoint ? (
        <Marker
          position={point as google.maps.LatLngLiteral}
          icon={{
            path: google.maps.SymbolPath.CIRCLE,
            scale: 12,
            fillColor: '#696969',
            fillOpacity: 1,
            strokeWeight: 3,
            strokeColor: '#fff',
          }}
        />
      ) : null}

      {isPolygon ? (
        <Polygon
          paths={polygonPoints}
          options={polygonOptions?.options ?? options.options}
          onClick={() => setCurrentFeature && setCurrentFeature(currentFeature)}
        />
      ) : null}

      {isMultiPolygon && !setCurrentFeature ? (
        <GeoJsonMultiPolygon
          geoJson={feature.geometry as GeoJsonMultiPolygonType}
          polygonOptions={polygonOptions ?? options}
        />
      ) : null}

      {isMultiPolygon && setCurrentFeature
        ? polygonsPaths.map((polygonPaths: any, index: number) => (
            <Polygon
              key={index}
              paths={polygonPaths}
              options={polygonOptions?.options ?? options.options}
              onClick={() => setCurrentFeature && setCurrentFeature(currentFeature)}
            />
          ))
        : null}

      {isLineString ? (
        <GeoJsonLineString geoJson={feature.geometry as GeoJsonLineStringType} />
      ) : null}

      {isMultiLineString ? (
        <GeoJsonMultiLineString geoJson={feature.geometry as GeoJsonMultiLineStringType} />
      ) : null}
    </>
  )
}

const containerStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
}

export default function AgentReportMap({
  agent,
  dataSource,
  google,
  startDate,
  endDate,
}: {
  agent: AttendantAndDataSourceProps
  dataSource?: AttendantAndDataSourceProps
  google: any
  startDate: string
  endDate: string
}) {
  const [agentIntersection, setAgentIntersection] = useState<any>({})
  const [values, setValues] = useState<any>()
  const [formattedPointsHeatmap, setFormattedPointsHeatmap] = useState()
  const [currentFeature, setCurrentFeature] = useState<number | undefined>(undefined)

  const { callAction: callActionHeatMap } = useActionFunction(seedAgentReportHeatmapDefinition)
  const [heatmapPoints, setHeatmapPoints] = useState<any>([])
  const [isLoadingHeatmap, setIsLoadingHeatmap] = useState<boolean | null>(null)

  const agentAreaDemarcation = useItems(
    () => (agent !== undefined ? (AgentAreaDemarcationQuery(agent.id) as any) : (null as never)),
    [agent],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: agent !== undefined,
    },
  )

  useEffect(() => {
    setValues({
      attendant: { id: agent.id, name: agent.name },
      dataSource: { id: dataSource?.id, name: dataSource?.name },
    })
    setIsLoadingHeatmap(null)

    setHeatmapPoints([])
  }, [agent, dataSource])

  const listLegalEntitiesAttendances = useItems(
    () =>
      values !== undefined
        ? (attendantVisitLocationsListQuery(values) as QueryBase)
        : (null as never),
    [values],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: values !== undefined,
    },
  )

  const listLegalEntitiesAttendancesFormatted = useMemo(() => {
    const startDateParsed = new Date(startDate)
    const endDateParsed = new Date(endDate)
    const list = listLegalEntitiesAttendances.items
      ?.map((item: any) => {
        const attendanceDate = new Date(item.attendanceStartDate)

        if (
          item.legalEntityCoordinates?.coordinates !== undefined &&
          attendanceDate >= startDateParsed &&
          attendanceDate <= endDateParsed
        ) {
          return {
            type: 'Feature',
            properties: {
              scalerank: 2,
              name: item.legalEntityName,
              id: item.legalEntityId,
            },
            geometry: {
              type: 'Point',
              coordinates: item.legalEntityCoordinates?.coordinates,
            },
          }
        }
      })
      .filter((item: any) => item !== undefined && item.legalEntityDataSource !== 5) as Features[]

    const removerDuplicates = list?.filter(
      (element, index, array) =>
        array.findIndex(find => find.properties.id === element.properties.id) === index,
    )
    return removerDuplicates
  }, [listLegalEntitiesAttendances, startDate, endDate])

  const polygonCoverageAreaFeature = useMemo(() => {
    if (
      !agentAreaDemarcation.isLoading &&
      agentAreaDemarcation.items &&
      agentAreaDemarcation.items[0].coverageArea !== null
    ) {
      const rawCoordinates = agentAreaDemarcation.items[0].coverageArea

      const multiPolygon = turf.multiPolygon(rawCoordinates.coordinates)
      multiPolygon.bbox = bbox(multiPolygon)

      const polygonCenter = center(multiPolygon)

      const formattedCenter = new google.maps.LatLng(
        polygonCenter?.geometry.coordinates[1],
        polygonCenter?.geometry.coordinates[0],
      )

      return { multiPolygon, center: formattedCenter }
    }
  }, [agentAreaDemarcation])

  const focusAreaFeature = useMemo(() => {
    if (
      !agentAreaDemarcation.isLoading &&
      agentAreaDemarcation.items &&
      agentAreaDemarcation.items[0].focusArea !== null
    ) {
      const rawCoordinates = agentAreaDemarcation.items[0].focusArea

      const multiPolygon = turf.multiPolygon(rawCoordinates.coordinates)

      multiPolygon.bbox = bbox(multiPolygon)
      return multiPolygon
    }
  }, [agentAreaDemarcation])

  const { callAction: callActionSearchAgentIntersections } = useActionFunction(
    searchAgentIntersectionsDefinition,
  )

  useMemo(() => {
    const { resultPromise } = callActionSearchAgentIntersections({
      agentCpf: agent.id,
    })

    resultPromise.then(result => {
      setAgentIntersection(result)
    })

    return resultPromise
  }, [agent])

  const formattedAgentIntersection = useMemo(() => {
    if (agentIntersection.length > 0) {
      const intersectionsFeature = agentIntersection.map((item: any) => {
        const geoJson = JSON.parse(item.area)

        if (geoJson === null) {
          return {}
        }

        const multiPolygon =
          geoJson?.type === 'MultiPolygon'
            ? turf.multiPolygon(geoJson.coordinates)
            : turf.polygon(geoJson.coordinates)

        multiPolygon.bbox = bbox(multiPolygon)

        return {
          focusArea: multiPolygon,
          intersectionArea: item.intersectionArea,
          agentObject: item.agentObject,
        }
      })
      return intersectionsFeature
    }
  }, [agentIntersection])

  const clusterMarker = useAsync(() =>
    canvasDrawToURL(
      context => {
        context.fillStyle = colors.darkGray
        context.arc(15, 15, 5, 0, 2 * Math.PI)
        context.globalAlpha = 0.8
        context.fill()
        context.strokeStyle = '#fff'
        context.lineWidth = 1.5
        context.stroke()
      },
      { width: 20, height: 20 },
    ),
  )

  const callHeatmapPoints = useCallback(
    (agentId: string, startDate: string, endDate: string) => {
      if (agentId) {
        setIsLoadingHeatmap(true)
        const { resultPromise } = callActionHeatMap({
          agentCpf: agentId,
          startDate,
          endDate,
        })

        resultPromise
          .then(result => {
            setHeatmapPoints(result)
            setIsLoadingHeatmap(false)
          })
          .catch(error => {
            console.log(error)
          })

        return resultPromise
      }
    },
    [callActionHeatMap],
  )

  useEffect(() => {
    if (
      agent.id &&
      heatmapPoints.length === 0 &&
      listLegalEntitiesAttendancesFormatted?.length > 0 &&
      isLoadingHeatmap === null
    ) {
      callHeatmapPoints(agent.id, startDate, endDate)
    }

    if (heatmapPoints.length === 0 && listLegalEntitiesAttendancesFormatted?.length === 0) {
      setIsLoadingHeatmap(false)
    }
  }, [agent.id, heatmapPoints.length, listLegalEntitiesAttendancesFormatted])

  useMemo(() => {
    if (isLoadingHeatmap === false) {
      const googlePoints = heatmapPoints?.map((item: any) => {
        return {
          weight: item.weight,
          location: new google.maps.LatLng(item.location[1], item.location[0]),
        }
      })

      if (googlePoints) {
        setFormattedPointsHeatmap(googlePoints)
      }

      return () => {
        setIsLoadingHeatmap(true)
      }
    }
  }, [google, isLoadingHeatmap, heatmapPoints])

  return (
    <Wrapper>
      {formattedPointsHeatmap &&
      polygonCoverageAreaFeature &&
      listLegalEntitiesAttendancesFormatted &&
      listLegalEntitiesAttendancesFormatted.length > 0 ? (
        <>
          <TotalizersWrapper>
            {agentAreaDemarcation.items && agentAreaDemarcation.items[0].area ? (
              <ResultContainer>
                <span>Área total de foco do agente selecionado:</span>
                <span style={{ fontWeight: 'bold' }}>
                  {(Number(agentAreaDemarcation.items[0].area) / 1000000)
                    .toFixed(2)
                    .replace('.', ',')}{' '}
                  km²
                </span>
              </ResultContainer>
            ) : null}
            {!!formattedAgentIntersection && formattedAgentIntersection.length > 0 && (
              <ResultContainer>
                <span>Área total das interseções dos agentes vizinhos:</span>
                <span style={{ fontWeight: 'bold' }}>
                  {formattedAgentIntersection
                    .map((item: any) => item.intersectionArea)
                    .reduce((a: number, b: number) => a + b, 0)
                    .toFixed(2)
                    .replace('.', ',')}{' '}
                  km²
                </span>
              </ResultContainer>
            )}
            {listLegalEntitiesAttendancesFormatted &&
              listLegalEntitiesAttendancesFormatted.length > 0 && (
                <ResultContainer>
                  <span>Quantidade de empresas atendidas geolocalizadas:</span>
                  <span style={{ fontWeight: 'bold' }}>
                    {listLegalEntitiesAttendancesFormatted.length} empresas
                  </span>
                </ResultContainer>
              )}
          </TotalizersWrapper>
          <GoogleMaps
            mapContainerStyle={containerStyle}
            center={polygonCoverageAreaFeature?.center}
            zoom={12}
          >
            <>
              {formattedPointsHeatmap &&
              focusAreaFeature &&
              listLegalEntitiesAttendancesFormatted.length > 0 ? (
                <HeatmapLayer
                  data={formattedPointsHeatmap}
                  options={{
                    radius: 25,
                    maxIntensity: 7,
                    gradient: [
                      'rgba(0, 255, 255, 0)',
                      'rgba(255, 255, 0, 1)',
                      'rgba(255, 255, 0, 0.8)',
                      'rgba(255, 255, 0, 0.6)',
                      'rgba(255, 255, 0, 0.5)',
                      'rgba(255, 255, 0, 0.4)',
                    ],
                  }}
                />
              ) : null}

              {listLegalEntitiesAttendancesFormatted
                ? listLegalEntitiesAttendancesFormatted.map((item: any, index: number) => (
                    <Marker
                      icon={{
                        url: `${clusterMarker.value}`,
                        anchor: new google.maps.Point(15, 15),
                      }}
                      position={{
                        lng: item.geometry.coordinates[0],
                        lat: item.geometry.coordinates[1],
                      }}
                      zIndex={2}
                      key={index}
                    />
                  ))
                : null}

              {!!formattedAgentIntersection &&
                formattedAgentIntersection.length > 0 &&
                formattedAgentIntersection.map((item: any, index: number) => (
                  <PlotFeature
                    key={index}
                    feature={item.focusArea}
                    polygonOptions={{
                      options: {
                        fillColor: colors.sebraeBlue,
                        fillOpacity: 0.2,
                        strokeColor: colors.sebraeBlue,
                        strokeOpacity: 0.9,
                        strokeWeight: 1,
                      },
                    }}
                    currentFeature={index}
                    setCurrentFeature={setCurrentFeature}
                  />
                ))}

              {focusAreaFeature && (
                <PlotFeature
                  feature={focusAreaFeature}
                  polygonOptions={{
                    options: {
                      fillColor: '#ff8000',
                      fillOpacity: 0.3,
                      strokeColor: '#ff8000',
                      strokeOpacity: 1,
                      strokeWeight: 2,
                    },
                  }}
                />
              )}
            </>
          </GoogleMaps>
          <Subtitles>
            <div
              style={{ display: 'flex', minWidth: '250px', width: '30%', flexDirection: 'column' }}
            >
              <SubtitleItem>
                <Square style={{ backgroundColor: '#ff8000' }} />
                <span>Áreas de foco de atendimentos do agente no ano corrente</span>
              </SubtitleItem>
              <SubtitleItem>
                <Square style={{ backgroundColor: colors.darkGray }} />
                <span>Atendimentos do agente no ano corrente</span>
              </SubtitleItem>
            </div>
            <div
              style={{ display: 'flex', minWidth: '250px', width: '30%', flexDirection: 'column' }}
            >
              <SubtitleItem>
                <Square style={{ backgroundColor: colors.sebraeBlue }} />
                <span>Cobertura de agentes vizinhos no ano corrente</span>
              </SubtitleItem>
              <SubtitleItem>
                <Square style={{ backgroundColor: '#FFFF00' }} />
                <span>Micro e pequena empresas atendidas</span>
              </SubtitleItem>
            </div>
          </Subtitles>
          <Modal
            isOpen={currentFeature !== undefined}
            onClose={() => {
              setCurrentFeature(undefined)
            }}
            hideCloseButton={true}
          >
            {currentFeature !== undefined && (
              <AgentIntersectionModal
                agents={formattedAgentIntersection[currentFeature].agentObject}
                onClose={() => {
                  setCurrentFeature(undefined)
                }}
              />
            )}
          </Modal>
        </>
      ) : listLegalEntitiesAttendances.isLoading === true || isLoadingHeatmap === true ? (
        <OverlayLoading>
          <div className='center'>
            <span className='message'>Carregando...</span>
          </div>
        </OverlayLoading>
      ) : (
        <OverlayLoading>
          <div className='center'>
            <span className='message'>
              Não há informações de atuação do agente no período de tempo selecionado.
            </span>
          </div>
        </OverlayLoading>
      )}
    </Wrapper>
  )
}
