import {
  attendancePeriodLastMonthFormatted,
  attendancePeriodLastYearFormatted,
} from '~/packages/legalEntityGeoprocessing/map/definedQueries/utils'
import { between, boolean, entity, equals, isNull, number, query, string, truthy } from '~/prix'
import asGeoJson from '~/prix/functions/asGeoJson'

interface AttendantVisitLocationsList {
  attendant: {
    id: string
    name?: string
  }
  dataSource: {
    id?: number
    name?: string
  }
  period: {
    value?: 'lastMonth' | 'lastYear'
  }
}

export default function attendantVisitLocationsListQuery(values: AttendantVisitLocationsList) {
  const attendantCpf = values?.attendant?.id ?? undefined
  const dataSourceId = values?.dataSource?.id ?? undefined
  const periodValue = values?.period?.value ?? undefined

  return query('legalEntityAttendance')
    .select({
      legalEntityId: entity('legalEntityAttendance').property('legalEntityId'),
      legalEntityName: entity('legalEntity').property('tradeName'),
      legalEntityCoordinates: asGeoJson(
        entity('legalEntityGeoprocessing').property('pointOnStreet'),
      ),
      legalEntityDataSource: entity('legalEntityAttendanceDataSource').property('dataSourceId'),
      attendanceStartDate: entity('legalEntityAttendance').property('startDate'),
      serviceUnitCoordinates: asGeoJson(
        entity('serviceUnitGeoprocessing').property('pointOnStreet'),
      ),
    })
    .join({
      current: entity('legalEntityAttendance').property('id'),
      target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityAttendanceDataSource').property('dataSourceId'),
      target: entity('dataSource').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityAttendance').property('legalEntityId'),
      target: entity('legalEntity').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntity').property('id'),
      target: entity('legalEntityGeoprocessing').property('legalEntityId'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntity').property('id'),
      target: entity(`courseRegisterIndividual`).property('legalEntityId'),
      join: 'left',
    })
    .join({
      current: entity('legalEntityAttendance').property('agentCpf'),
      target: entity('attendant').property('cpf'),
      join: 'left',
    })
    .join({
      current: entity('attendant').property('id'),
      target: entity('attendantServiceUnit').property('attendantId'),
      join: 'left',
    })
    .join({
      current: entity('attendantServiceUnit').property('serviceUnitId'),
      target: entity('serviceUnitGeoprocessing').property('serviceUnitId'),
      join: 'left',
    })
    [periodValue !== undefined && periodValue === 'lastMonth' ? 'join' : 'dummy']({
      current: entity('legalEntityGeoprocessing').property('legalEntityId'),
      target: entity(`legalEntityAttendanceLastMonth`).property('legalEntityId'),
      join: 'left',
    })
    [periodValue !== undefined && periodValue === 'lastYear' ? 'join' : 'dummy']({
      current: entity('legalEntityGeoprocessing').property('legalEntityId'),
      target: entity(`legalEntityAttendanceLastYear`).property('legalEntityId'),
      join: 'left',
    })
    .where(
      ...[
        isNull(entity('legalEntity').property('deletedAt')),
        equals(entity('legalEntityAttendance').property('agentCpf'), string().value(attendantCpf)),
        equals(entity('dataSource').property('isActive'), boolean().value(true)),

        dataSourceId !== undefined
          ? equals(
              entity('legalEntityAttendanceDataSource').property('dataSourceId'),
              number().value(Number(dataSourceId)),
            )
          : null,

        periodValue !== undefined && periodValue === 'lastMonth'
          ? between(
              entity('courseRegisterIndividual').property('registerDate'),
              string().value(attendancePeriodLastMonthFormatted.startDate),
              string().value(attendancePeriodLastMonthFormatted.endDate),
            )
          : null,

        periodValue !== undefined && periodValue === 'lastYear'
          ? between(
              entity('courseRegisterIndividual').property('registerDate'),
              string().value(attendancePeriodLastYearFormatted.startDate),
              string().value(attendancePeriodLastYearFormatted.endDate),
            )
          : null,
      ].filter(truthy),
    )
}
