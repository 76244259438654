import React from 'react'
import styled from 'styled-components'
import { colors } from '~/design'
import LogoIcon from '~/meta/sebraeLogo.svg'
import Link from '~/prix/react/components/link'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  height: 60px;
  background: ${colors.headerBlue};

  svg {
    fill: #fff;
    min-width: 64px;
    min-height: 64px;
  }

  .nav-menu {
    display: flex;
    text-align: center;
    list-style: none;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .nav-links {
    text-decoration: none;
    padding: 0.5rem 1rem;
    color: #fff;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 48px 12px 36px;
  color: #000;
  text-align: justify;
  line-height: 1.5;

  h1 {
    text-align: left;
  }

  ol {
    padding: 12px 0px 12px 16px;
    margin: 0;

    li::marker {
      font-weight: bold;
    }

    li {
      padding: 12px 0;
    }
  }

  ul {
    li {
      list-style: none;
    }
  }
`

const Table = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0 30px 0;
  text-align: left;
  width: 100%;
`

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #0000002b;

  @media (max-width: 768px) {
    align-items: flex-start;
  }

  strong {
    width: 35%;
    word-break: keep-all;
    hyphens: auto;
  }

  p {
    width: 65%;
    word-break: keep-all;
    hyphens: auto;
    margin: 0;
    padding-left: 12px;

    a {
      word-break: break-all;
    }
  }
`

export default function TermsScreen() {
  return (
    <Wrapper>
      <Header>
        <Link href='/' remote>
          <LogoIcon width={32} height={32} />
        </Link>
        <ul className='nav-menu'>
          <li>
            <Link href='/' className='nav-links'>
              Sobre a plataforma
            </Link>
          </li>
          <li>
            <Link href='https://prix.tech/#Contact' className='nav-links' remote newTab>
              Fale conosco
            </Link>
          </li>
        </ul>
      </Header>
      <ContentWrapper>
        <h1>Termos de uso – PrixSEBRAE</h1>

        <p>
          O Site PrixSEBRAE é oferecido pelo SERVIÇO BRASILEIRO DE APOIO ÀS MICRO E PEQUENAS
          EMPRESAS-SEBRAE, entidade associativa de Direito Privado sem fins lucrativos, transformado
          em serviço social autônomo pelo Decreto nº 99.570, de 9 de outubro de 1990, com sede no
          SGAS 605 – Conjunto A, Asa Sul, Brasília – DF, inscrito no CNPJ/MF sob o nº.
          00.330.845/0001-45, sendo desenvolvido e mantido pela PRIX TECH SOFTWARE, iniciativa
          privada, com sede na Rua Conrado Neth, 106, bairro Vila Nova, Luzerna – SC, Inscrito no
          CNPJ sob o nº. 28.568.537/0001-71.
        </p>

        <p>
          Estes Termos de Uso estabelecem as condições que regem a utilização do Site pelo Usuário.
        </p>

        <p>
          Para poder usar o Site PrixSEBRAE, você deverá ler, compreender e aceitar estes Termos de
          Uso. Caso tenha qualquer dúvida sobre os Termos de Uso, recomendamos que entre em contato
          com o PrixSEBRAE por meio do e-mail
          <a href='mailto:contato@prix.tech'> contato@prix.tech </a>
          <strong>ANTES </strong>
          de aceitá-los.
        </p>

        <ol>
          <li>
            <strong>DEFINIÇÕES</strong>
            <p>
              Para facilitar a leitura, usamos certas palavras/termos com a primeira letra em
              maiúsculo. Sempre que isso ocorrer nos Termos de Uso, você deve entender que essa
              palavra/termo tem o significado indicado abaixo:
            </p>
          </li>
          <Table>
            <TableRow>
              <strong>AMEI</strong>
              <p>Significa a conta de acesso do Usuário aos aplicativos e sites do Sebrae.</p>
            </TableRow>
            <TableRow>
              <strong>Conteúdo</strong>
              <p>
                Significa toda e qualquer informação disponibilizada pelo ou por meio do Site, tais
                como textos, dados, software, imagens, vídeos, áudios, recursos interativos,
                incluindo os códigos fonte empregados para exibição desses conteúdos, como aqueles
                em linguagem HTML, CSS, JSON, entre outros.
              </p>
            </TableRow>
            <TableRow>
              <strong>Dados Pessoais</strong>
              <p>
                Significa qualquer dado disponibilizado pelo Usuário que, de alguma forma, o
                identifique, tais como, mas não se limitando a, nome completo, CPF, endereço, número
                de telefone e endereço de e-mail.
              </p>
            </TableRow>
            <TableRow>
              <strong>Dados Empresariais</strong>
              <p>
                Significa qualquer dado disponibilizado que, de alguma forma, identifique a empresa
                a qual pertence como sócio ou representante, tais como, mas não se limitando a,
                CNPJ, nome fantasia, razão social, endereço empresarial, número de telefone
                empresarial, endereço de e-mail empresarial, quantidade de sócios, quantidade de
                funcionários, faturamento anual e faturamento mensal.
              </p>
            </TableRow>
            <TableRow>
              <strong>Lei Geral de Proteção de Dados</strong>
              <p>
                Significa a legislação brasileira que determina como os dados dos cidadãos podem ser
                coletados e tratados, e que prevê punições para transgressões. Pode ser consultada
                na íntegra em:
                <Link
                  href='http://www.planalto.gov.br/ccivil_03/_Ato2015-2018/2018/Lei/L13709.htm'
                  className='nav-links'
                  remote
                  newTab
                >
                  {' '}
                  http://www.planalto.gov.br/ccivil_03/_Ato2015-2018/2018/Lei/L13709.htm
                </Link>
              </p>
            </TableRow>
            <TableRow>
              <strong>Site</strong>
              <p>
                Significa o endereço eletrônico{' '}
                <Link href='https://prixsebrae.com/' remote newTab>
                  https://prixsebrae.com/
                </Link>{' '}
                ou qualquer outro que vier a substituí-lo.
              </p>
            </TableRow>
            <TableRow>
              <strong>API</strong>
              <p>
                Significa application programming interface e é por meio do qual poderão ser
                disponibilizados dados dos clientes à outras aplicações do Sebrae ou de terceiros
                (com autorização do usuário) ou de outras aplicações do Sebrae ou de terceiros para
                o Site, também com autorização do usuário.
              </p>
            </TableRow>
            <TableRow>
              <strong>Usuário</strong>
              <p>
                Significa uma pessoa física, com plena capacidade de registrar, alterar ou excluir
                dados, aceitando expressamente os presentes Termos de Uso.
              </p>
            </TableRow>
            <TableRow>
              <strong>Geolocalização</strong>
              <p>
                Significa o ato de registrar, alterar ou excluir a posição geográfica de objetos e
                pessoas com base em coordenadas via satélite emitidas por sinais de internet (WiFi),
                radiofrequência, GPS e AGPS.
              </p>
            </TableRow>
            <TableRow>
              <strong>Aplicativo ou App</strong>
              <p>Aplicativo PrixSebrae, utilizado para a coleta de dados operacionais.</p>
            </TableRow>
          </Table>
          <li>
            <strong>USO DO SITE PELO USUÁRIO</strong>
            <p>
              Para utilizar o Site ou App PrixSEBRAE, o Usuário deverá realizar o seu cadastro no
              AMEI, caso ainda não possua, ato no qual fornecerá ao Sebrae os seus Dados Pessoais,
              nome de usuário (“Login”) e senha que deverão ser utilizados para acesso à Conta
              Sebrae somente pelo Usuário.
            </p>

            <p>
              O Usuário será responsável pela veracidade, validade e precisão dos dados por ele
              fornecidos no seu cadastro, devendo mantê-los atualizados.
            </p>

            <p>
              O AMEI usará mecanismos de segurança para validar que o usuário é ele mesmo a partir
              do CPF informado. Não será possível realizar o cadastro sem CPF e email.
            </p>

            <p>
              A senha e o Login criados pelo Usuário para acessar o AMEI são confidenciais e de
              responsabilidade exclusiva do Usuário, que deverá entrar em contato com o PrixSEBRAE
              ou com o Sebrae, imediatamente na hipótese de comprometimento do seu sigilo.
            </p>

            <p>
              Com esse mesmo Login e senha criados pelo Usuário será possível acessar outros sites
              e/ou aplicativos vinculados ao Sebrae que utilizem o AMEI.
            </p>
          </li>

          <li>
            <strong>NÃO SOMOS RESPONSÁVEIS PELA VERACIDADE DOS DADOS INSERIDOS</strong>
            <p>
              O PrixSEBRAE é um sistema de análise de dados, geoprocessamento e captação de dados
              operacionais a serem realizados pelos usuários. O PrixSEBRAE não controla nenhum dos
              dados que são inseridos, de modo que não é responsável pela veracidade desses dados,
              sendo o usuário e as fontes de dados os únicos responsáveis por isso.
            </p>

            <p>
              O usuário poderá ter a oportunidade de inserir dados de geolocalização e dados
              operacionais. Nesse caso, o Sebrae também não é responsável pelos dados inseridos ou
              por lançamentos realizados de forma errada.
            </p>

            <p>
              O site PrixSEBRAE permitirá que o usuário realize impressão de relatórios pré
              definidos compostos pelos dados inseridos, não sendo o Sebrae responsável por nenhuma
              informação ali constante.
            </p>

            <p>
              O PrixSEBRAE não é responsável ou imputável pela exatidão, qualidade, completitude,
              confiabilidade, tempestividade ou fidedignidade dos dados inseridos pelos usuários ou
              outras fontes de dados.
            </p>
          </li>

          <li>
            <strong>FUNCIONALIDADES DO SITE</strong>
            <p>O Site do PrixSEBRAE oferece como funcionalidades:</p>
            <p>Início</p>
            <ul>
              <li>
                Visualização das quantidades de dados integrados e captados (cadastros únicos) e
                geocodificados;
              </li>
              <li>Visualização das quantidades de empresas focando o público de atendimento;</li>
              <li>Visualização das quantidades de atendimentos realizados;</li>
              <li>Visualização de gráficos e detalhamentos de indicadores relevantes.</li>
            </ul>

            <p>Mapa</p>
            <ul>
              <li>Coleta de geolocalização de empresas;</li>
              <li>Visualização de geolocalização e geocodificação em mapa;</li>
              <li>Visualização em imagem de satélite para a seleção de geolocalização;</li>
              <li>
                Aplicação de filtros (por porte, por CNAE, por natureza jurídica, por atendimento,
                MEI e SIMPLES);
              </li>
              <li>
                Aplicação de filtro de destaque (Porcentagem em um porte, porcentagem em CNAE,
                porcentagem de uma natureza jurídica, porcentagem de atendimento, porcentagem de
                MEIs, porcentagem no sistema SIMPLES).
              </li>
            </ul>

            <p>Aplicativo</p>
            <ul>
              <li>Coleta de geolocalização de empresas;</li>
              <li>Visualização de geolocalização e geocodificação em mapa;</li>
              <li>Visualização em imagem de satélite para a seleção de geolocalização;</li>
              <li>Indicador de atendimentos realizados;</li>
              <li>Coleta de informações de empresas atendidas.</li>
            </ul>

            <p>
              As funcionalidades estão em constante evolução e, em caso de alterações, novas versões
              desses termos de uso poderão ser disponibilizadas, ficando o usuário sujeito à
              aceitação desses termos para ter acesso às funcionalidades disponibilizadas.
            </p>
          </li>

          <li>
            <strong>LINKS PARA E A PARTIR DO SITE</strong>
            <p>
              O PrixSEBRAE poderá conter hyperlinks para websites operados por outras partes que não
              o Sebrae. Esses hyperlinks são fornecidos apenas para sua referência. Não controlamos
              esses websites e não somos responsáveis por seus conteúdos ou pela privacidade ou
              outras práticas desses websites. Estes Termos não serão aplicados ao seu uso de
              qualquer website de terceiro que você venha a acessar por meio de nosso Site. O seu
              uso de qualquer website de terceiro poderá estar sujeito a termos e condições
              adicionais, que sugerimos que leia cuidadosamente antes de visitar tais websites.
            </p>
            <p>
              Além disso, compete a você tomar precauções para assegurar que quaisquer links que
              você selecione ou software que faça download (seja a partir do nosso Site ou de outros
              websites) estejam livres de vírus, worms, cavalos de Tróia, defeitos e outros itens de
              natureza destrutiva. Nossa inclusão de hyperlinks que levam a esses websites não
              significa que exista qualquer endosso do material existente nesses websites ou
              qualquer associação com seus operadores.
            </p>
          </li>

          <li>
            <strong>RESTRIÇÕES AO USO DO SITE OU API PELO USUÁRIO</strong>
            <p>O Usuário não poderá:</p>
            <ul>
              <li>
                Utilizar o Site, API ou o Aplicativo para divulgar informações de qualquer forma que
                possa implicar em violação de normas aplicáveis no Brasil, de direitos de
                propriedade do PrixSEBRAE e/ou de terceiros ou dos bons costumes, incluindo, sem
                limitação, a violação de direitos intelectuais, autorais e de privacidade, ou a
                produção e divulgação de conteúdo ilegal, imoral, inapropriado ou ofensivo;
              </li>
              <li>
                Reproduzir, copiar, ceder, modificar, realizar engenharia reversa, compilar,
                descompilar, transmitir, publicar, sublicenciar, alugar, vender, comercializar,
                distribuir, ou, de qualquer outra forma, utilizar qualquer funcionalidade, conteúdo,
                software ou material disponibilizado por meio do Site ou API de forma não permitida
                nestes Termos de Uso;
              </li>
              <li>
                Empregar softwares, técnicas e/ou artifícios com o intuito de utilizar indevidamente
                o Site para práticas nocivas ao PrixSEBRAE, ao Sebrae ou a terceiros, tais como
                exploits, spamming, flooding, spoofing, crashing, root kits, etc.;
              </li>
              <li>
                Publicar ou transmitir qualquer arquivo que contenha vírus, worms, cavalos de troia
                ou qualquer outro programa contaminante ou destrutivo, ou que de outra forma possa
                interferir no bom funcionamento do Site;
              </li>
              <li>
                Utilizar indevidamente o Software para finalidade diversa daquela para a qual foi
                disponibilizado pelo PrixSEBRAE e presente nesses termos de uso;
              </li>
              <li>Divulgar, utilizar ou modificar indevidamente os dados.</li>
            </ul>

            <p>
              O Usuário concorda em utilizar os dados geocodificados fornecidos pela plataforma
              exclusivamente em uso orgânico através da nossa ferramenta web. Qualquer coleta,
              replicação ou utilização dos dados fora das funcionalidades fornecidas na plataforma é
              expressamente proibida sem a permissão prévia e escrita da empresa.
            </p>
            <p>
              A extração de dados geocodificados é permitida apenas através da API especificamente
              designada para esse propósito, e sujeita à solicitação e aprovação expressa da
              empresa. A extração deve ser limitada aos registros acordados em contrato.
            </p>
            <p>
              Os dados geocodificados podem ser utilizados para estudos, análises e ferramentas ou
              plataformas, sujeitos à permissão prévia em acordos por escrito devidamente assinados.
              Sob nenhuma circunstância, o usuário pode utilizar os dados como apoio ou conjunto de
              dados para criar, promover, ou apoiar concorrência, seja externa no mercado ou interna
              com o próprio SEBRAE.
            </p>
          </li>

          <li>
            <strong>PROPRIEDADE INTELECTUAL</strong>

            <p>
              O Usuário não adquire, por meio dos presentes Termos de Uso, nenhum direito de
              propriedade intelectual ou outros direitos exclusivos, incluindo patentes, desenhos,
              bases de dados, marcas, dados geocodificados, direitos autorais ou direitos sobre
              informações confidenciais ou segredos de negócio, sobre ou relacionados ao Site, os
              quais são de propriedade exclusiva do PrixSEBRAE .
            </p>
            <p>
              Todo e qualquer Conteúdo disponibilizado no Site, tais como, mas não se limitando a,
              textos, gráficos, imagens, logos, ícones, conteúdo editorial, notificações, softwares
              e qualquer outro material, pertencem exclusivamente ao PrixSEBRAE e são protegidos
              pela legislação brasileira no que se refere à propriedade intelectual e aos direitos
              autorais.
            </p>
            <p>
              Estes Termos de Uso concedem ao Usuário uma licença pessoal, mundial, revogável, não
              exclusiva e intransferível de uso do Software sem cobrança de royalties. A utilização
              do Software pelo Usuário possui caráter pessoal e intransferível, unicamente para fins
              lícitos relacionados ao propósito a que o Site se destina, conforme estes Termos de
              Uso. Em nenhuma hipótese, o Usuário terá acesso ao código fonte do site ora
              licenciado, já que este é de propriedade do PrixSEBRAE.
            </p>
            <p>
              Os dados geocodificados extraídos devem ser mantidos por não mais de 30 dias, ou
              conforme acordos por escrito devidamente assinados. Após esse período, todos os dados
              devem ser destruídos de maneira segura e através de meios aprovados pela empresa. A
              empresa reserva-se o direito de auditar a conformidade com esta cláusula a seu
              critério.
            </p>
          </li>

          <li>
            <strong>RESPONSABILIDADES</strong>

            <p>O PrixSEBRAE se responsabiliza e garante que:</p>
            <ul>
              <li>
                Não compartilha nenhum Dado Pessoal ou Lançamentos do Usuário com terceiros sem o
                consentimento do Usuário;
              </li>
              <li>Mantém a transparência sobre como os dados são utilizados; e</li>
              <li>Coleta, usa, armazena e trata os dados de forma segura.</li>
            </ul>

            <p>O PrixSEBRAE não se responsabiliza:</p>
            <ul>
              <li>
                Por eventuais prejuízos sofridos pelos Usuários em razão da tomada de decisões com
                base nas informações ou relatórios emitidos pela plataforma, uma vez que não é
                obrigação do Site deliberar sobre as escolhas do Usuário;
              </li>
              <li>
                Por eventuais prejuízos sofridos pelos Usuários em razão de falhas no sistema de
                informática ou nos servidores que independem de culpa do PrixSEBRAE ou em sua
                conectividade com a internet de modo geral, devendo o Usuário manter, às suas
                expensas, linha de telecomunicação, modem, software de comunicação, endereço de
                correio eletrônico e outros recursos necessários à comunicação com o PrixSEBRAE;
              </li>
              <li>
                Por danos causados por hackers e/ou programas/códigos nocivos ao Software, tais
                como, mas sem se limitar a vírus, worms, ataques de negação; e
              </li>
              <li>
                Pela autenticidade, validade e precisão dos dados fornecidos pelos Usuários e/ou
                importadas de outros sistemas.
              </li>
            </ul>

            <p>
              Em nenhum caso, o PrixSEBRAE será responsável por danos diretos ou indiretos sofridos
              pelo Usuário, incluindo, sem limitação, falha de transmissão ou recepção de dados, não
              continuidade do negócio ou qualquer outra perda, decorrentes ou relacionados ao uso do
              Site.
            </p>
          </li>

          <li>
            <strong>ISENÇÃO DE GARANTIAS</strong>
            <p>
              O PrixSEBRAE não se responsabiliza por qualquer decisão tomada pelo Usuário com base
              nas informações obtidas a partir da utilização do Site.
            </p>
            <p>O PrixSEBRAE não garante que:</p>

            <ul>
              <li>as funções contidas no Site atendem às necessidades do Usuário;</li>
              <li>a operação do Site será ininterrupta ou livre de erros;</li>
              <li>qualquer funcionalidade continuará disponível;</li>
              <li>os defeitos no Site serão corrigidos; ou</li>
              <li>
                o Site será compatível ou funcionará com qualquer software, aplicações ou serviços
                de terceiros.
              </li>
            </ul>

            <p>
              O PrixSEBRAE fará todo o possível para manter os dados coletados sempre seguros,
              inclusive, irá adotar medidas de segurança e de proteção compatíveis com a natureza
              dos dados coletados, usados e armazenados, conforme previsto na Lei Geral de Proteção
              de Dados.
            </p>
            <p>
              No entanto, o PrixSEBRAE não garante que tais medidas de segurança sejam isentas de
              erros ou que não estejam sujeitas a interferência de terceiros, como hackers, entre
              outros.
            </p>
          </li>

          <li>
            <strong>INDENIZAÇÃO</strong>
            <p>
              O Usuário concorda em defender, indenizar e manter indene o PrixSEBRAE, o Sebrae e
              suas afiliadas, diretores, empregados e agentes, de e contra quaisquer encargos, ações
              ou demandas, incluindo, mas não limitado a honorários advocatícios razoáveis,
              resultantes:
            </p>

            <ul>
              <li>da sua eventual utilização indevida do Site; ou</li>
              <li>da violação das condições ora pactuadas.</li>
            </ul>
          </li>

          <li>
            <strong>SUSPENSÃO OU ENCERRAMENTO DO ACESSO</strong>
            <p>
              O PrixSEBRAE poderá, a seu exclusivo critério, a qualquer tempo, e sem a necessidade
              de comunicação prévia ao Usuário:
            </p>

            <ul>
              <li>
                Encerrar, modificar ou suspender, total ou parcialmente, o acesso do Usuário ao
                Site, quando referido acesso ou cadastro violar as condições estabelecidas nestes
                Termos de Uso;
              </li>
              <li>
                Excluir, total ou parcialmente, as informações cadastradas pelo Usuário que não
                estejam em consonância com as disposições destes Termos de Uso; e
              </li>
              <li>
                Acrescentar, excluir ou modificar as funcionalidades e o conteúdo oferecidos no
                PrixSEBRAE.
              </li>
            </ul>

            <p>
              Poderá o PrixSEBRAE, ainda, a seu exclusivo critério, suspender, modificar ou encerrar
              as atividades do Site, mediante comunicação prévia ao Usuário, salvo nas hipóteses de
              caso fortuito ou força maior.
            </p>
          </li>

          <li>
            <strong>EXCLUSÃO DA CONTA</strong>
            <p>O Usuário poderá, a qualquer momento, solicitar a exclusão da sua Conta AMEI.</p>
            <p>
              Para solicitar a exclusão da sua Conta AMEI, o Usuário poderá fazer a solicitação pelo
              link: {''}
              <Link href='https://amei.sebrae.com.br/auth/realms/externo/account/' remote newTab>
                https://amei.sebrae.com.br/auth/realms/externo/account/
              </Link>
              .
            </p>
          </li>

          <li>
            <strong>ALTERAÇÕES</strong>
            <p>
              O PrixSEBRAE poderá alterar estes Termos de Uso para, por exemplo, refletir alterações
              nas funcionalidades oferecidas.
            </p>
            <p>
              Quaisquer alterações nos Termos de Uso serão comunicadas ao Usuário por meio do email
              informado por ele no momento do cadastro e publicadas no Site,
              <strong> sendo o Usuário responsável por analisar tais alterações.</strong>
            </p>
            <p>
              As alterações não serão aplicadas retroativamente e entrarão em vigor 5 (cinco) dias
              após sua publicação. O Usuário entende e concorda que, após esse período, o uso do
              Site passará a ser submetido à versão atualizada dos Termos de Uso. Se você não
              concordar com as modificações, recomendamos que solicite a exclusão de sua conta AMEI
              e descontinue o uso do Site.
            </p>
          </li>

          <li>
            <strong>CONTATO</strong>
            <p>
              O PrixSEBRAE e o Sebrae poderão contatar o Usuário por meio do número de telefone e/ou
              do e-mail fornecidos, sempre que necessário, para tratar de assuntos relacionados às
              funcionalidades do Site como, por exemplo, para esclarecer dúvidas em relação à
              utilização, comunicar alterações nos Termos de Uso, e realizar pesquisas em relação a
              novas funcionalidades e à experiência do Usuário ao utilizar o PrixSEBRAE.
            </p>
            <p>
              Para qualquer assunto relacionado às condições destes Termos de Uso, bem como ao Site,
              o Usuário deverá entrar em contato com o PrixSEBRAE por meio do e-mail
              <a href='mailto:contato@prix.tech'> contato@prix.tech</a>.
            </p>
          </li>

          <li>
            <strong>DISPOSIÇÕES GERAIS</strong>
            <p>
              Caso qualquer disposição destes Termos de Uso seja considerada ilegal, nula ou
              inexequível por qualquer razão, as disposições restantes não serão afetadas e se
              manterão válidas e aplicáveis na máxima extensão possível.
            </p>
            <p>
              Qualquer falha do PrixSEBRAE para impor ou exercer qualquer disposição destes Termos
              de Uso ou direitos conexos, não constitui uma renúncia a esse direito ou disposição.
            </p>
          </li>

          <li>
            <strong>LEI E FORO</strong>
            <p>
              Estes Termos de Uso serão interpretados de acordo com as leis do Brasil, sendo que
              quaisquer conflitos relacionados ao Site, Termos de Uso deverão ser dirimidos no foro
              de Brasília – Distrito Federal.
            </p>
          </li>
        </ol>

        <p>Última revisão: 30 de agosto de 2023.</p>
      </ContentWrapper>
    </Wrapper>
  )
}
