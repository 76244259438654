import { entity, query, count, distinct, equals, truthy, string, isNull } from '~/prix'

export default function individualQuery(stateAbbreviation?: string) {
  return query('individual')
    .select({
      total: count(distinct(entity('individual').property('cpf'))),
      totalLegalEntity: count(distinct(entity('legalEntityIndividual').property('id'))),
    })
    .join({
      current: entity('individual').property('id'),
      target: entity('legalEntityIndividual').property('individualId'),
      join: 'left',
    })
    .join({
      current: entity('legalEntityIndividual').property('legalEntityId'),
      target: entity('legalEntity').property('id'),
      join: 'left',
    })
  [stateAbbreviation !== undefined ? 'join' : 'dummy']({
    current: entity('legalEntity').property('id'),
    target: entity('legalEntityGeoprocessing').property('legalEntityId'),
    join: 'inner',
  })
  [stateAbbreviation !== undefined ? 'join' : 'dummy']({
    current: entity('legalEntityGeoprocessing').property('stateId'),
    target: entity('state').property('id'),
    join: 'inner',
  })
    .where(
      ...[
        stateAbbreviation && stateAbbreviation !== undefined
          ? equals(entity('state').property('abbreviation'), string().value(stateAbbreviation))
          : null,
      ].filter(truthy),
    )
    .limit(1)
}