import format from 'date-fns/format'
import parse from 'date-fns/parse'
import { baseDate } from './dateTime'
import createType, { AbstractType } from './type'

export interface DateType<NativeGeneric extends string | null = string>
  extends AbstractType<NativeGeneric> {
  readonly type: 'date'
  readonly currentValue?: NativeGeneric
  isRequired(): DateType<Exclude<NativeGeneric, null>>
  isOptional(): DateType<NativeGeneric | null>
}

export default function date(): DateType<string> {
  return {
    ...createType<DateType<string>>(),
    type: 'date',
  }
}

export function parseDate(date: string): Date {
  return parse(date, 'yyyy-MM-dd', baseDate)
}

export function stringifyDate(jsDate: Date): string {
  return format(jsDate, 'yyyy-MM-dd')
}

export function formatDate(jsDate: Date, short = false): string {
  return format(jsDate, short ? 'dd/MM/yy' : 'dd/MM/yyyy')
}
