import { array, defineAction, enumerated, number, object, string } from '~/prix'
import mapDemarcationExportEntities from './mapDemarcationExportEntities.action.json'
import { point, polygon } from '~/prix/types/geoJson'

const mapDemarcationExportEntitiesDefinition = defineAction({
  ...mapDemarcationExportEntities,
  input: object({
    point: point().label('Coordenada'),
    radius: number(),
    boundary: polygon().label('Polígono'),
    geoItems: array(
      object({
        level: enumerated({
          country: 'country',
          macroRegion: 'macroRegion',
          mesoRegion: 'mesoRegion',
          microRegion: 'microRegion',
          state: 'state',
          city: 'city',
          street: 'street',
          neighborhood: 'neighborhood',
          suburb: 'suburb',
        }),
        id: string().label('ID do nível selecionado'),
      }),
    ),
    format: enumerated({
      csv: 'CSV',
      xlsx: 'Excel',
    }),
  }),
  output: object({
    downloadUrl: string().label('URL para download do arquivo gerado.'),
  }),
})

export default mapDemarcationExportEntitiesDefinition
