import { defineEntity, entity, foreign, object, string, number, boolean } from '~/prix'
import { multiPolygon, point } from '~/prix/types/geoJson'

const cityEntity = defineEntity({
  key: 'city',
  title: 'Cidade',
  alternativeTitles: ['Município'],
  sources: ['olap', 'oltp'],
  schema: object({
    id: string().label('ID', 'Identificador', 'Código'),
    ibgeCode: number().label('Código do IBGE'),
    name: string().label('Nome', 'Cidade', 'Município'),
    isCapital: boolean().label('É capital estadual'),
    dialingCode: number().label('Código telefónico'),
    timezone: string().label('Fuso horário'),
    siafiId: string().label('Código SIAFI da cidade'),
    openStreetMapId: number().label('Código ID do Open Street Map'),
    center: point().label('Centro'),
    boundary: multiPolygon().label('Polígono'),
    lowerQualityBoundary: multiPolygon().label('Polígono de baixa qualidade.'),
    microRegionId: foreign(entity('microRegion').property('id')),
  }),
})

export default cityEntity
