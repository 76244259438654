import React, { FC, useMemo } from 'react'
import styled from 'styled-components'
import microQuery from '~/packages/dashboard/queries/micro.query'
import smallQuery from '~/packages/dashboard/queries/small.query'
import Handle from '~/prix/react/components/handle'
import useItem from '~/prix/react/hooks/item'
import { formatAsBrIntegerNumber, formatAsBrNumber, formatAsPercentage } from '~/prix/utils/types/number'
import { PieChart, Pie, Tooltip } from 'recharts'
import useMedia from 'react-use/lib/useMedia'

const Wrapper = styled.div`
  @media (min-width: 1500px) {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    box-shadow : 0px 32px 30px rgba(20, 46, 82, 0.20);
    padding: 10px;
    border-radius: 0 0 12px 12px;
    justify-content: center;
    flex-grow: 1;
    min-height: 119px;
  }
`

const Items = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 12px 10px 21px;
  border-radius: 0 0 12px 12px;
  justify-content: center;
  flex: 1;

  @media (max-width: 1024px) {
    background-color: #fff;
    box-shadow : 0px 32px 30px rgba(20, 46, 82, 0.20);
  }
`

const Item = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 0 11px;

  .percentage-wrapper {
    display: flex;
    flex-direction: row;
    width: 50%;
    gap: 5px;
  }

  .data-wrapper {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  .first {
    color: var(--p-action, #0F438A);
    font-weight: 700;
    font-size: 23px;
  }

  .second {
    font-size: 13px;
    color: #000;
    font-weight: 400;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .main-color {
    background-color: #005EB8;
  }

  .secondary-color {
    background-color: #2ECC71;
  }
`

const Ball = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  align-self: center;
`
interface PieData {
  name: string
  value: number
  fill: string
}

interface PieChartProps {
  pieData: PieData[]
}

const PieGraphic: FC<PieChartProps> = ({ pieData }) => {
  const isDesktop = useMedia('(min-width: 1180px)')

  const data = pieData ? pieData : [
    { name: 'Microempresas (ME)', value: 1 },
    { name: 'Empresas de Pequeno Porte (EPP)', value: 1 },
  ]

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px 0' }}>
      {isDesktop ? (
        <PieChart width={100} height={100} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
          <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={50} />
          <Tooltip formatter={(value, name) => [`${name}: ${formatAsBrNumber(Number(value))}`]} />
        </PieChart>
      )
        : (
          <PieChart width={300} height={300} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
            <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={100} />
            <Tooltip formatter={(value, name) => [`${name}: ${formatAsBrNumber(Number(value))}`]} />
          </PieChart>
        )}
    </div>
  )
}

export default function DashboardGridItemPublic(props?: any) {
  const { onChangeValues, userGeoStateAbbreviation } = props

  const micro = useItem(
    () => microQuery(userGeoStateAbbreviation ? userGeoStateAbbreviation : undefined),
    [userGeoStateAbbreviation],
    { cache: 60 * 60 * 24 },
  )
  const small = useItem(
    () => smallQuery(userGeoStateAbbreviation ? userGeoStateAbbreviation : undefined),
    [userGeoStateAbbreviation],
    { cache: 60 * 60 * 24 },
  )

  onChangeValues(micro.item?.count! + small.item?.count!)

  const pieData = useMemo(() => {
    const data = [
      { name: 'Microempresas (ME)', value: micro.item?.count!, fill: '#005EB8' },
      { name: 'Empresas de Pequeno Porte (EPP)', value: small.item?.count!, fill: '#2ECC71' },
    ]

    return data
  }, [micro.item?.count, small.item?.count])

  return (
    <Handle isLoading={micro.isLoading || small.isLoading} error={micro.error || small.error} style={{ minHeight: '100px' }}>
      <Wrapper>
        {pieData && (
          <PieGraphic pieData={pieData} />
        )}
        <Items>
          <Item>
            <div className="percentage-wrapper">
              <Ball className='main-color' />
              <span className='first'>
                {formatAsPercentage(micro.item?.count! / (micro.item?.count! + small.item?.count!))}%
              </span>
            </div>
            <div className="data-wrapper">
              <span className='second'>
                Microempresas (ME)
              </span>
              <span className="third">
                {formatAsBrIntegerNumber(micro.item?.count!)}
              </span>
            </div>
          </Item>

          <Item>
            <div className="percentage-wrapper">
              <Ball className='secondary-color' />
              <span className='first'>
                {formatAsPercentage(small.item?.count! / (micro.item?.count! + small.item?.count!))}%
              </span>
            </div>
            <div className="data-wrapper">
              <span className='second'>
                Empresas de Pequeno Porte (EPP)
              </span>
              <span className="third">
                {formatAsBrIntegerNumber(small.item?.count!)}
              </span>
            </div>
          </Item>
        </Items>
      </Wrapper>
    </Handle>
  )
}
