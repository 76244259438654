import { useEffect } from 'react'
import { InferNative, ObjectType, Sources, Type } from '~/prix'
import { BaseActionDefinitionWithInputOutput } from '~/prix/actionDefinition'
import useActionFunction from './actionFunction'

export default function useAction<
  InputType extends Type,
  OutputType extends ObjectType,
  ActionDefinition extends BaseActionDefinitionWithInputOutput<InputType, OutputType>,
  Input = InferNative<ActionDefinition['input']>,
  Output = InferNative<ActionDefinition['output']>,
>(
  actionDefinition: ActionDefinition,
  input: Input,
  deps: React.DependencyList = input && typeof input === 'object' ? Object.values(input) : [input],
  { autoLoad = true }: { autoLoad?: boolean } = {},
  sourceKey: keyof Sources = 'oltp',
) {
  const { callAction, result, error, isLoading } = useActionFunction<
    InputType,
    OutputType,
    ActionDefinition,
    Input,
    Output
  >(actionDefinition, sourceKey)

  useEffect(() => {
    if (!autoLoad) {
      return
    }
    const { abort } = callAction(input)
    return () => {
      abort()
    }
  }, [callAction, autoLoad, ...deps])

  return {
    result,
    error,
    isLoading,
    callAction,
  }
}
