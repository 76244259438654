import { boolean, descending, entity, equals, query, string } from '~/prix'

export default function attendanceListQuery(id: string) {
  return query('legalEntityAttendance')
    .select({
      id: entity('legalEntityAttendance').property('id'),
      dataSourceId: entity('dataSource').property('id'),
      dataSourceName: entity('dataSource').property('name'),
      instrument: entity('legalEntityAttendance').property('instrument'),
      startDate: entity('legalEntityAttendance').property('startDate'),
      endDate: entity('legalEntityAttendance').property('endDate'),
    })
    .join({
      current: entity('legalEntityAttendance').property('id'),
      target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityAttendanceDataSource').property('dataSourceId'),
      target: entity('dataSource').property('id'),
      join: 'inner',
    })
    .where(
      ...[
        equals(entity('legalEntityAttendance').property('legalEntityId'), string().value(id)),
        equals(entity('dataSource').property('isActive'), boolean().value(true)),
      ],
    )
    .order(descending('startDate'))
    .limit(5000)
}
