import { Polygon, PolygonProps } from '@react-google-maps/api'
import React, { useMemo } from 'react'
import { colors } from '~/design/index'
import { GeoJsonMultiPolygon } from '~/prix/types/geoJson'

export type PolygonOptionsProps = {
  options: {
    fillColor?: string
    fillOpacity?: number
    strokeColor?: string
    strokeOpacity?: number
    strokeWeight?: number
    zIndex?: number
  }
}

export interface GeoJsonMultiPolygonProps extends PolygonProps {
  geoJson: GeoJsonMultiPolygon
  polygonOptions?: PolygonOptionsProps
}

export default function GeoJsonMultiPolygon({
  geoJson,
  polygonOptions,
  ...props
}: GeoJsonMultiPolygonProps) {
  const polygonsPaths = useMemo(() => {
    return geoJson.coordinates.map(polygonCoordinates =>
      polygonCoordinates[0].map(([lng, lat]) => ({ lat, lng })),
    )
  }, [geoJson])

  const options = polygonOptions?.options ?? {
    fillColor: colors.oceanBlue,
    fillOpacity: 0.4,
    strokeColor: colors.oceanBlue,
    strokeWeight: 1,
    zIndex: 1,
  }

  return (
    <>
      {polygonsPaths.map((polygonPaths, index) => (
        <Polygon key={index} paths={polygonPaths} options={options} {...props} />
      ))}
    </>
  )
}
