import React from 'react'
import Fingerprint2 from 'fingerprintjs2'
import useAsync from 'react-use/lib/useAsync'
import useDevice from '~/prix/react/hooks/device'

export default function DeviceInformation() {
  const { loading: deviceLoading, error: deviceError, value: deviceInfo } = useDevice()

  const realIdentifierResult = useAsync(
    () =>
      new Promise<string>(resolve => {
        Fingerprint2.getV18((result: string) => {
          resolve(result)
        })
      }),
  )

  const header = (
    <thead>
      <tr>
        <th colSpan={2}>Dispositivo</th>
      </tr>
    </thead>
  )

  if (deviceLoading) {
    return (
      <>
        {header}
        <tbody>
          <tr>
            <td colSpan={2}>Carregando...</td>{' '}
          </tr>
        </tbody>
      </>
    )
  }

  if (deviceError || realIdentifierResult.error || !deviceInfo) {
    return (
      <>
        {header}
        <tbody>
          <tr>
            <td colSpan={2}>
              Ocorreu um erro!
              <br />
              {deviceError?.message ?? realIdentifierResult.error?.message ?? 'erro desconhecido'}
            </td>{' '}
          </tr>
        </tbody>
      </>
    )
  }

  return (
    <>
      {header}
      <tbody>
        <tr>
          <td>Nome</td>
          <td>{deviceInfo.name}</td>
        </tr>
        <tr>
          <td>Identificador</td>
          <td>{deviceInfo.identifier}</td>
        </tr>
        <tr>
          <td>Identificador real</td>
          <td>{realIdentifierResult.value}</td>
        </tr>
        <tr>
          <td>Sistema Operacional</td>
          <td>{deviceInfo.platformName}</td>
        </tr>
        <tr>
          <td>Versão do Sistema Operacional</td>
          <td>{deviceInfo.osVersion === undefined ? 'Não suportado' : deviceInfo.osVersion}</td>
        </tr>
        <tr>
          <td>Navegador</td>
          <td>{deviceInfo.browserName}</td>
        </tr>
        <tr>
          <td>Versão do navegador</td>
          <td>{deviceInfo.browserVersion}</td>
        </tr>
        <tr>
          <td>Quantidade de núcleos do processador</td>
          <td>
            {navigator.hardwareConcurrency === undefined
              ? 'Não suportado'
              : navigator.hardwareConcurrency}
          </td>
        </tr>
      </tbody>
    </>
  )
}
