import { time } from '~/prix'
import { date, defineEntity, number, object } from '~/prix'

const summaryEntity = defineEntity({
  key: 'summary',
  title: 'Sumário',
  sources: ['olap', 'oltp'],
  schema: object({
    id: number().label('ID', 'Identificador', 'Código'),
    partialAddress: number().label('Endereços parcial'),
    withCoordinatesAddress: number().label('Endereços com coordenada'),
    legalEntityMicro: number().label('Microempresas'),
    legalEntitySmall: number().label('Empresas de pequeno porte'),
    attendanceAccomplished: number().label('Atendimentos realizado'),
    legalEntityAttendance: number().label('Empresas atendidas'),
    individualRegistered: number().label('Pessoas físicas'),
    legalEntityIndividual: number().label('Pessoas físicas vinculadas à empresas'),
    createdDate: date().label('Data de criação'),
    createdTime: time().label('Horário da criação'),
    updatedTime: time().label('Horário da atualização'),
  }),
})

export default summaryEntity
