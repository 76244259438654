import { boolean, defineAction, object } from '~/prix'
import systemImportEadAction from './systemImportEad.action.json' assert { type: 'json' }

const systemImportEadDefinition = defineAction({
  ...systemImportEadAction,
  input: object({
    force: boolean().isOptional(),
  }),
  output: object({}),
})

export default systemImportEadDefinition
