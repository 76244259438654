import styled from 'styled-components'

const Badge = styled.span<{ background?: string }>`
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 0.85em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.25rem;
  background-color: ${({ theme, background = theme.colors.primary }) => background};
  color: #fff;
  margin: 0;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
`

export default Badge
