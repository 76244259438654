import { defineEntity, object, string, dateTime, timestamps } from '~/prix'

const attendantEntity = defineEntity({
  key: 'attendant',
  title: 'Atendente',
  sources: ['olap', 'oltp'],
  schema: object({
    id: string().label('ID', 'Identificador', 'Código'),
    cpf: string().label('CPF'),
    name: string().label('Nome'),
    email: string().label('Email'),
    ...timestamps,
  }),
})

export default attendantEntity
