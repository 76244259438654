import { defineAction, object } from '~/prix'
import seedNeighborhoodNullableCenterAction from './seedNeighborhoodNullableCenter.action.json' assert { type: 'json' }

const seedNeighborhoodNullableCenterDefinition = defineAction({
  ...seedNeighborhoodNullableCenterAction,
  input: object({}),
  output: object({}),
})

export default seedNeighborhoodNullableCenterDefinition
