import { boolean, defineAction, object, string } from '~/prix'
import generateHeatmapDataAction from './generateHeatmapData.action.json' assert { type: 'json' }

const seedLegalEntityHeatmapDefinition = defineAction({
  ...generateHeatmapDataAction,
  input: object({
    level: string()
      .isOptional()
      .label('Nível específico de geração de heatmap ser country | state | city.'),
    isVerbose: boolean().isOptional().label('Modo verboso.'),
  }),
  output: object({}),
})

export default seedLegalEntityHeatmapDefinition
