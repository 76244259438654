import {
  array,
  defineAction,
  enumerated,
  object,
  string,
} from '~/prix'
import sendNotification from './sendNotification.action.json'
import { KeysAndLabels } from '~/prix/types/enumerated'

const statesEnum: KeysAndLabels<string> = {
  AC: 'AC',
  AL: 'AL',
  AP: 'AP',
  AM: 'AM',
  BA: 'BA',
  CE: 'CE',
  DF: 'DF',
  ES: 'ES',
  GO: 'GO',
  MA: 'MA',
  MG: 'MG',
  MS: 'MS',
  MT: 'MT',
  PA: 'PA',
  PB: 'PB',
  PE: 'PE',
  PI: 'PI',
  PR: 'PR',
  RJ: 'RJ',
  RN: 'RN',
  RO: 'RO',
  RR: 'RR',
  RS: 'RS',
  SC: 'SC',
  SE: 'SE',
  SP: 'SP',
  TO: 'TO',
}

const sendNotificationDefinition = defineAction({
  ...sendNotification,
  input: object({
    title: string(),
    body: string(),
    url: string().isOptional(),
    email: array(string()).isOptional(),
    roles: array(string()).isOptional(),
    abbreviationState: array(enumerated(statesEnum)).isOptional(),
  }),
  output: object({}),
})

export default sendNotificationDefinition
