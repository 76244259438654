import {
  array,
  boolean,
  defineAction,
  entity,
  enumerated,
  foreign,
  number,
  object,
  string,
} from '~/prix'
import radar from './radar.action.json'
import { multiPolygon, point } from '~/prix/types/geoJson'

const radarDefinition = defineAction({
  ...radar,
  input: object({
    latitude: number(),
    longitude: number(),
  }),
  output: object({
    bounds: multiPolygon().label('Perímetros do chunk carregado'),
    getStateAbbreviationByCoordinates: object({ stateAbbreviationByCoordinates: string() }),
    entities: array(
      object({
        legalEntityId: foreign(entity('legalEntity').property('id')),
        cnpj: string(),
        corporateName: string(),
        tradeName: string(),
        country: string(),
        state: string(),
        city: string(),
        neighborhood: string(),
        street: string(),
        complement: string(),
        postalCode: string(),
        filter: enumerated({
          radius: 'Está no raio do usuario.',
          sameStreet: 'Está na mesma rua que o usuário.',
          sameNeighborhood: 'Está no mesmo bairro que o usuário.',
        }),
        economicActivity: string(),
        pointOnStreet: point(),
        distanceMeters: number(),
        withinProximities: boolean(),
      }),
    ),
    neighborhoodId: string(),
    streetIds: array(string()),
  }),
})

export default radarDefinition
