import { defineEntity, entity, foreign, object, string, number } from '~/prix'
import { multiPolygon, point } from '~/prix/types/geoJson'

const stateEntity = defineEntity({
  key: 'state',
  title: 'Estado',
  sources: ['olap', 'oltp'],
  schema: object({
    id: string().label('ID', 'Identificador', 'Código'),
    name: string().label('Nome', 'Estado'),
    abbreviation: string().label('Sigla', 'Estado', 'Abreviação'),
    ibgeCode: number().label('Código do IBGE'),
    center: point().label('Centro'),
    boundary: multiPolygon().label('Polígono'),
    lowerQualityBoundary: multiPolygon().label('Polígono de baixa qualidade'),
    macroRegionId: foreign(entity('macroRegion').property('id')),
  }),
})

export default stateEntity
