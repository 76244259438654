import { defineAction, number, object, string } from '~/prix'
import abortPendingUserQueriesAction from './abortPendingUserQueries.action.json' assert { type: 'json' }

const abortPendingUserQueriesDefinition = defineAction({
  ...abortPendingUserQueriesAction,
  input: object({
    userId: string().label('Id do usuário que se deseja abortar requests pendentes...'),
    requestId: string().label('Id da query que se deseja abortar...'),
    requestedTimestamp: number().label('Timestamp de quando os abortos foi requisitado.'),
  }),
  output: object({}),
})

export default abortPendingUserQueriesDefinition
