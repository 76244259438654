import { defineEntity, number, object, foreign, entity, dateTime } from '~/prix'

const courseRegisterIndividualEntity = defineEntity({
  key: 'courseRegisterIndividual',
  title: 'Registro de matriculas',
  sources: ['olap', 'oltp'],
  schema: object({
    id: number().label('ID', 'Identificador', 'Código'),
    courseProductId: foreign(entity('courseProduct').property('id')).label('Código do produto'),
    individualId: foreign(entity('individual').property('id')).label('Identificador pessoa física'),
    legalEntityId: foreign(entity('legalEntity').property('id')).label(
      'Identificador pessoa jurídica',
    ),
    registerDate: dateTime().label('Data do registro'),
    startDate: dateTime().label('Data de inicio'),
    endDate: dateTime().label('Data do fim'),
    courseCompletionPercentage: number().label('Porcentagem de conclusão do curso'),
    createdAt: dateTime().label('Data de criação'),
  }),
})

export default courseRegisterIndividualEntity
