import { defineAction, object } from '~/prix'
import legalEntityGeoprocessingAfterProcessAction from './legalEntityGeoprocessingAfterProcess.action.json' assert { type: 'json' }

const legalEntityGeoprocessingAfterProcessDefinition = defineAction({
  ...legalEntityGeoprocessingAfterProcessAction,
  input: object({}),
  output: object({}),
})

export default legalEntityGeoprocessingAfterProcessDefinition
