import { defineAction, number, object } from '~/prix'
import neighborhoodNewShapes from './neighborhoodNewShapes.action.json' assert { type: 'json' }

const neighborhoodNewShapesDefinition = defineAction({
  ...neighborhoodNewShapes,
  input: object({ ibgeCode: number().label('Código IBGE da cidade:') }),
  output: object({}),
})

export default neighborhoodNewShapesDefinition
