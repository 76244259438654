import { boolean, defineEntity, entity, foreign, object, string } from '~/prix'

const legalEntityCnae = defineEntity({
  key: 'legalEntityCnae',
  title: 'Relacionamento das entidades com os cnaes',
  sources: ['olap', 'oltp'],
  schema: object({
    legalEntityId: foreign(entity('legalEntity').property('id')),
    cnaeSectionId: foreign(entity('cnaeSection').property('id')),
    cnaeDivisionId: foreign(entity('cnaeDivision').property('id')),
    cnaeGroupId: foreign(entity('cnaeGroup').property('id')),
    cnaeClassId: foreign(entity('cnaeClass').property('id')),
    cnaeSubclassId: foreign(entity('cnaeSubclass').property('id')),
    isMain: boolean(),
  }),
})

export default legalEntityCnae
