import { boolean, defineAction, object } from '~/prix'
import individualFetchAction from './individualFetch.action.json'

const individualFetchActionDefinition = defineAction({
  ...individualFetchAction,
  input: object({}),
  output: object({
    ok: boolean(),
  }),
})

export default individualFetchActionDefinition
