import type { InferNative, NativeBaseOptional, Type, AbstractType } from '../types/type'
import type { AbstractValueFunction } from './function'
import { PropertyStringBase } from '../property'
import { NumberType, StringType } from '~/prix'

export interface LeftFunction<NativeGeneric extends NativeBaseOptional>
  extends AbstractValueFunction<NativeGeneric> {
  name: 'left'
  length: NumberType
}

/**
 * Conversão de função `LEFT` do postgres, retorna os primeiros _n_ caracteres de uma propriedade.
 * @remarks [Documentação PostgreSLQ](https://www.postgresql.org/docs/current/functions-string.html)
 * @param content Coluna ou propriedade que deverá ser processada pela função.
 * @param length Valor _n_ de caracteres que deverá ser retornado.
 * @returns Propriedade nativa compatível com a prixApi.
 */
export default function left<
  TypeGeneric extends Type,
  NativeGeneric extends NativeBaseOptional = InferNative<TypeGeneric>,
>(
  content: NativeGeneric | ((StringType | AbstractType<string>) & { value: string | null }),
  length: NumberType | AbstractType<number>,
): PropertyStringBase {
  return {
    kind: 'function',
    name: 'left',
    length,
    content,
  }
}
