import React from 'react'
import styled from 'styled-components'
import AppLayout from '~/components/appLayout'
import OptionsMenu from './menu.component'
import Logo from '~/meta/logo.svg'
import { colors } from '~/design'
import useMedia from 'react-use/lib/useMedia'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`

export default function OptionsScreen() {
  const isMobileLarge = useMedia('(max-width: 1024px)')
  const isMobileSmall = useMedia('(max-width: 768px)')

  return (
    <AppLayout
      title='Opções'
      initialMenuVisibility={true}
      dockActive='options'
      menu={<OptionsMenu />}
      card={{
        title: 'Opções',
        customStyles: {
          content: isMobileLarge
            ? {
                height: isMobileSmall ? '100px' : '70px',
              }
            : {},
        },
      }}
    >
      <Wrapper>
        <Logo width={250} height={250} fill={colors.sebraeBlue} />
      </Wrapper>
    </AppLayout>
  )
}
