import { Polyline, PolylineProps } from '@react-google-maps/api'
import React, { useMemo } from 'react'
import { colors } from '~/design/index'
import { GeoJsonMultiLineString } from '~/prix/types/geoJson'

export interface GeoJsonMultiLineStringProps extends PolylineProps {
  geoJson: GeoJsonMultiLineString
}

export default function GeoJsonMultiLineString({ geoJson, ...props }: GeoJsonMultiLineStringProps) {
  const polylinePaths = useMemo(() => {
    return geoJson.coordinates.map(polylineCoordinates =>
      polylineCoordinates.map(([lng, lat]) => ({ lat, lng })),
    )
  }, [geoJson])

  const options = {
    fillColor: colors.oceanBlue,
    fillOpacity: 0.4,
    strokeColor: colors.oceanBlue,
    strokeWeight: 1,
    zIndex: 1,
  }

  return (
    <>
      {polylinePaths.map((polylinePath, index) => (
        <Polyline key={index} path={polylinePath} options={options} {...props} />
      ))}
    </>
  )
}
