import { PropertyStringBase } from '../property'
import type { InferNative, NativeBaseOptional, Type } from '../types/type'
import type { AbstractValueFunction } from './function'

export interface setWeightFunction<NativeGeneric extends NativeBaseOptional>
  extends AbstractValueFunction<NativeGeneric> {
  name: 'setWeight'
  weight?: 'A' | 'B' | 'C' | 'D' | ''
}

/**
 * Conversão de função SETWEIGHT do postgres, define um peso para a propriedade, sendo esse peso usado para a pontuação de uma pesquisa.
 * @remarks [Documentação PostgreSLQ](https://www.postgresql.org/docs/14/textsearch-controls.html#TEXTSEARCH-RANKING)
 * @param content Argumentos da função, a propriedade que será adicionada o peso.
 * @param weight Peso da propriedade, podendo ser A, B, C ou D
 * @returns Propriedade nativa compatível com a prixApi
 */
export default function setWeight<
  TypeGeneric extends Type,
  NativeGeneric extends NativeBaseOptional = InferNative<TypeGeneric>,
>(content: NativeGeneric, weight?: 'A' | 'B' | 'C' | 'D' | ''): PropertyStringBase {
  return {
    kind: 'function',
    name: 'setWeight',
    weight,
    content,
  }
}
