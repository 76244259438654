import filesize from 'filesize'
import React from 'react'
import useStorageInformation from '~/prix/react/hooks/storage'
import { NavigatorStorageSupported } from '~/utils/shared/platform'

export default function StorageInformation() {
  const header = (
    <thead>
      <tr>
        <th colSpan={2}>Armazenamento</th>
      </tr>
    </thead>
  )

  if (NavigatorStorageSupported) {
    const storageInformation = useStorageInformation()
    return (
      <>
        <thead>
          <tr>
            <th colSpan={2}>Armazenamento</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Armazenamento usado</td>
            <td>{filesize(storageInformation.usage)}</td>
          </tr>
          <tr>
            <td>Armazenamento total</td>
            <td>{filesize(storageInformation.quota)}</td>
          </tr>
          <tr>
            <td>Porcentagem de armazenamento</td>
            <td>{((storageInformation.usage / storageInformation.quota) * 100).toPrecision(2)}%</td>
          </tr>
        </tbody>
      </>
    )
  }

  return (
    <>
      {header}
      <tbody>
        <tr>
          <td colSpan={2}>Armazenamento não suportado</td>
        </tr>
      </tbody>
    </>
  )
}
