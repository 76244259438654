import React from 'react'
import useItems from '~/prix/react/hooks/items'
import AgentReportCnaesListQuery from './queries/cnaesRankingListQuery.query'
import { format } from 'date-fns'
import List from '~/components/list'
import { AttendantAndDataSourceProps } from './agentReportProductionChart.component'
import styled from 'styled-components'
import useMedia from 'react-use/lib/useMedia'

const MessageWrapper = styled.div`
  display: flex;
  flex: 1;
  align-self: center;
  justify-content: center;
  height: 300px;

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .loader {
      display: flex;
      height: 50px;
      flex-direction: row;
      align-items: center;
    }

    .message {
      display: flex;
      height: 50px;
      flex-direction: row;
      font-size: 16px;
      text-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
    }

    span {
      font-size: 17px;
    }
  }
`

export default function AgentReportCnaesList({
  agent,
  dataSource,
  startDate,
  endDate,
}: {
  agent: AttendantAndDataSourceProps
  dataSource?: AttendantAndDataSourceProps
  startDate: string
  endDate: string
}) {
  const isMobile = useMedia('(max-width: 768px)')
  const listCnaes = useItems(
    () =>
      agent !== undefined || dataSource !== undefined
        ? (AgentReportCnaesListQuery({
            agentCpf: agent.id,
            dataSourceId: dataSource?.id,
            startDate: startDate,
            endDate: endDate,
          }) as any)
        : (null as never),
    [agent, dataSource, startDate, endDate],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: agent !== undefined || dataSource !== undefined,
    },
  )

  const cnaesListFormatted = listCnaes.items
    ? listCnaes.items.map((item: any) => {
        return {
          cnae: item.cnae,
          description: item.description,
          registrationStatusDate: format(new Date(item.registrationStatusDate), 'yyyy-MM-dd'),
          updatedAt: format(new Date(item.updatedAt), 'yyyy-MM-dd'),
        }
      })
    : []

  const cnaeInfo: Record<string, { description: string; count: number }> = {}

  cnaesListFormatted.forEach((item: any) => {
    const { cnae, description } = item
    if (cnaeInfo[cnae]) {
      cnaeInfo[cnae].count++
    } else {
      cnaeInfo[cnae] = { description, count: 1 }
    }
  })

  const cnaeArray = Object.entries(cnaeInfo).map(([cnae, info]) => ({
    cnae,
    description: info.description,
    count: info.count,
  }))

  cnaeArray.sort((a, b) => b.count - a.count)

  return cnaeArray ? (
    <List
      title={'Classificação de Cnaes'}
      items={cnaeArray}
      isLoading={listCnaes.isLoading}
      labels={{
        count: 'Quantidade',
        cnae: 'Cnae',
        description: 'Descrição',
      }}
      customStyles={{
        width: '100%',
        boxShadow: '0px 32px 30px 0px rgba(20, 46, 82, 0.20)',
      }}
      fractions={{
        count: 1.5 / 7,
        cnae: 1.5 / 7,
        description: 4 / 7,
      }}
      hideColumn={isMobile ? ['cnae'] : undefined}
    />
  ) : (
    <MessageWrapper>
      <div className='center'>
        <span>Carregando dados...</span>
      </div>
    </MessageWrapper>
  )
}
