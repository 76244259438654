import { Property, PropertyBase } from './property'
import { NativeBaseOptional } from './types/type'
import { Operator } from './operator'
import { ValueFunction } from './functions/function'

export type Aggregatable = Operator | PropertyBase | ValueFunction<NativeBaseOptional>

export interface AbstractAggregation<
  ResultType extends NativeBaseOptional,
  Aggregated extends Aggregatable,
> {
  readonly kind: 'aggregation'
  readonly mode:
    | 'count'
    | 'sum'
    | 'max'
    | 'min'
    | 'average'
    | 'variance'
    | 'standardDeviation'
    | 'distinct'
    | 'distinctOn'
  // | 'mostRecent'
  readonly content: Aggregated
}

export type AggregationBase = AbstractAggregation<NativeBaseOptional, Aggregatable>

export type InferAggregationProperty<CurrentAggregation> =
  CurrentAggregation extends AbstractAggregation<NativeBaseOptional, infer AggregationProperty>
    ? AggregationProperty
    : never

export type InferAggregationResultType<CurrentAggregation> =
  CurrentAggregation extends AbstractAggregation<infer ResultType, Aggregatable>
    ? ResultType
    : never

export function count<AggregationProperty extends Aggregatable>(
  content: AggregationProperty | AbstractAggregation<NativeBaseOptional, Aggregatable>,
): AbstractAggregation<number, AggregationProperty | any> {
  // TODO any for now
  return {
    kind: 'aggregation',
    mode: 'count',
    content,
  }
}

export function sum<AggregationProperty extends Aggregatable>(
  content: AggregationProperty,
): AbstractAggregation<number, AggregationProperty> {
  return {
    kind: 'aggregation',
    mode: 'sum',
    content,
  }
}

export function max<AggregationProperty extends Aggregatable>(
  content: AggregationProperty,
): AbstractAggregation<number, AggregationProperty> {
  return {
    kind: 'aggregation',
    mode: 'max',
    content,
  }
}

export function min<AggregationProperty extends Aggregatable>(
  content: AggregationProperty,
): AbstractAggregation<number, AggregationProperty> {
  return {
    kind: 'aggregation',
    mode: 'min',
    content,
  }
}

export function average<AggregationProperty extends Aggregatable>(
  content: AggregationProperty,
): AbstractAggregation<number, AggregationProperty> {
  return {
    kind: 'aggregation',
    mode: 'average',
    content,
  }
}

export function variance<AggregationProperty extends Aggregatable>(
  content: AggregationProperty,
): AbstractAggregation<number, AggregationProperty> {
  return {
    kind: 'aggregation',
    mode: 'variance',
    content,
  }
}

export function standardDeviation<AggregationProperty extends Aggregatable>(
  content: AggregationProperty,
): AbstractAggregation<number, AggregationProperty> {
  return {
    kind: 'aggregation',
    mode: 'standardDeviation',
    content,
  }
}

// export function mostRecent<
//   ResultType extends NativeBaseOptional,
//   AggregationProperty extends
//     | Property<string, string, string, ResultType>
//     | ValueFunction<ResultType>
// >(
//   content: AggregationProperty,
//   timeReferenceProperty: PropertyBase,
// ): AbstractAggregation<ResultType, AggregationProperty> & {
//   timeReferenceProperty: PropertyBase
// } {
//   return {
//     kind: 'aggregation',
//     mode: 'mostRecent',
//     content,
//     timeReferenceProperty,
//   }
// }

export function distinct<
  ResultType extends NativeBaseOptional,
  AggregationProperty extends
    | Property<string, string, string, ResultType>
    | ValueFunction<ResultType>,
>(content: AggregationProperty): AbstractAggregation<ResultType, AggregationProperty> {
  return {
    kind: 'aggregation',
    mode: 'distinct',
    content,
  }
}

export function distinctOn<
  ResultType extends NativeBaseOptional,
  AggregationProperty extends
    | Property<string, string, string, ResultType>
    | ValueFunction<ResultType>,
>(content: AggregationProperty): AbstractAggregation<ResultType, AggregationProperty> {
  return {
    kind: 'aggregation',
    mode: 'distinctOn',
    content,
  }
}

export type Aggregation<ResultType extends NativeBaseOptional, Aggregated extends Aggregatable> =
  | AbstractAggregation<ResultType, Aggregated>
  | (AbstractAggregation<ResultType, Aggregated> & {
      timeReferenceProperty: PropertyBase
    })
