import { defineEntity, entity, foreign, object, string } from '~/prix'

const neighborhoodStreetIntersectionEntity = defineEntity({
  key: 'neighborhoodStreetIntersection',
  title: 'Intersecção de rua e bairro',
  sources: ['olap', 'oltp'],
  schema: object({
    id: string().label('ID', 'Identificador', 'Código'),
    streetId: foreign(entity('street').property('id')),
    neighborhoodId: foreign(entity('neighborhood').property('id')),
  }),
})

export default neighborhoodStreetIntersectionEntity
