
import { FilterHighlight } from '~/packages/legalEntityGeoprocessing/map/legalEntityGeoprocessingMapLevels.data'
import {
  entity,
  equals,
  query,
  string,
  average,
  count,
  truthy,
  isNull,
  number,
  notEquals,
  boolean,
} from '~/prix'

interface legalEntityProfitsProps {
  groupColumn: string
  id: string
  level: string
  highlight: FilterHighlight | null
}

export default function legalEntityProfitsQuery({
  groupColumn,
  id,
  level,
  highlight
}: legalEntityProfitsProps) {
  return query('legalEntityIndicators')
    .select({
      geoId: entity('legalEntityGeoprocessing').property(groupColumn),
      count: count(entity('legalEntityIndicators').property('legalEntityId')),
      highlight:
        //averageRevenue
        highlight?.type === 'profits' && highlight?.value ?
          average(entity('legalEntityIndicators').property('averageRevenue')) :
          //averageCost
          highlight?.type === 'cost' && highlight?.value ?
            average(entity('legalEntityIndicators').property('averageCost')) :
            //scoreOperationsManagement
            highlight?.type === 'scoreOperationsManagement' && highlight?.value ?
              average(entity('legalEntityIndicators').property('scoreOperationsManagement'))
              // scoreTransformation
              : highlight?.type === 'scoreTransformation' && highlight?.value ?
                average(entity('legalEntityIndicators').property('scoreTransformation'))
                //scoreIndicatorsManagement
                : highlight?.type === 'scoreIndicatorsManagement' && highlight?.value ?
                  average(entity('legalEntityIndicators').property('scoreIndicatorsManagement'))
                  //scoreInnovation
                  : highlight?.type === 'scoreInnovation' && highlight?.value ?
                    average(entity('legalEntityIndicators').property('scoreInnovation'))
                    //scoreMarketing
                    : highlight?.type === 'scoreMarketing' && highlight?.value ?
                      average(entity('legalEntityIndicators').property('scoreMarketing'))
                      //scoreSustainablePractices
                      : highlight?.type === 'scoreSustainablePractices' && highlight?.value ?
                        average(entity('legalEntityIndicators').property('scoreSustainablePractices'))
                        : number().value(null as unknown as number),
    })
    .join({
      current: entity('legalEntityIndicators').property('legalEntityId'),
      target: entity('legalEntityGeoprocessing').property('legalEntityId'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityIndicators').property('legalEntityId'),
      target: entity('legalEntity').property('id'),
      join: 'inner',
    })
    .where(
      ...[
        isNull(entity('legalEntity').property('deletedAt')),
        equals(entity('legalEntityGeoprocessing').property(`${level}Id`), string().value(id)),
        highlight && highlight?.type === 'profits' ?
          notEquals(entity('legalEntityIndicators').property('isPossibleOutlierRevenue'), boolean().value(true))
          : null,
        highlight && highlight?.type === 'cost' ?
          notEquals(entity('legalEntityIndicators').property('isPossibleOutlierCost'), boolean().value(true))
          : null
      ].filter(truthy),
    )

}