import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { entity, equals, number, query, string, truthy } from '~/prix'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router'
import Select from 'react-select'
import AppLayout from '~/components/appLayout'
import useItem from '~/prix/react/hooks/item'
import md5 from 'md5'
import Handle from '~/prix/react/components/handle'
import { isEmpty } from '~/prix/utils/empty'
import useMedia from 'react-use/lib/useMedia'
import { format } from 'date-fns'
import { parseDateTime } from '~/prix/types/dateTime'
import { useKeycloak } from '~/components/keycloak'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  aside {
    flex: 1;
    max-width: 325px;

    @media (max-width: 1100px) {
      max-width: 265px;
    }

    @media (max-width: 768px) {
      width: 100%;
      max-width: none;
    }

    img {
      width: 100%;
      height: 200px;
      object-fit: cover;

      @media (max-width: 768px) {
        width: 100%;
        max-height: 250px;
      }
    }

    h1 {
      font-size: 24px;
      margin-bottom: 10px;
      padding: 0 10px;
    }

    p {
      font-size: 14px;
      margin-bottom: 10px;
      padding: 0 10px;
    }

    label {
      display: flex;
      flex-direction: column;
      padding: 0 10px;
      margin-bottom: 10px;
    }
  }

  main {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;

    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
`

const Aside = ({ reportResult, combinations, reportOutputResult, handleVariableChange }: any) => {
  return (
    <aside>
      {reportResult.item?.imageUrl !== '' ? (
        <img src={reportResult.item?.imageUrl as string} />
      ) : null}
      <div>
        <h1>{reportResult.item?.title}</h1>
        <p>{reportResult.item?.description}</p>
      </div>

      {combinations ? (
        <div style={{ padding: '12px 0px 5px 0px' }}>
          {combinations.map(combination => {
            const allOptions = [combination.empty, ...combination.options].filter(truthy)
            const mapOption = (option: { id: number | null; name: string; default: boolean }) => ({
              value: option.id === null ? '' : String(option.id),
              label: option.name,
            })
            const options = allOptions.map(mapOption)
            const defaultValue =
              allOptions.filter(option => option.default).map(mapOption)[0] || null
            const value = reportOutputResult.item?.variables
              ? allOptions
                  .filter(
                    option =>
                      (reportOutputResult.item?.variables as any)[combination.variable] ===
                      option.id,
                  )
                  .map(mapOption)[0]
              : undefined

            return (
              <label key={combination.variable}>
                {reportResult.item?.key !== 'subnormal-clusters' ? (
                  <>
                    {combination.name}
                    <Select
                      options={options}
                      defaultValue={defaultValue}
                      value={value}
                      onChange={option =>
                        handleVariableChange(
                          combination.variable,
                          option?.value as string,
                          option?.value === defaultValue?.value,
                        )
                      }
                      isLoading={reportOutputResult.isLoading}
                      isClearable={Boolean(combination.empty)}
                    />
                  </>
                ) : null}
              </label>
            )
          })}
        </div>
      ) : null}

      {reportOutputResult.item?.updatedAt ? (
        <p>
          {' '}
          Data de atualização:{' '}
          {format(parseDateTime(reportOutputResult.item?.updatedAt), 'dd/MM/yyyy')}
        </p>
      ) : null}
    </aside>
  )
}

export default function ReportReadScreen() {
  const params = useParams()
  const navigate = useNavigate()
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const key = params.key as string
  const hash = params.hash
  const isMobile = useMedia('(max-width: 768px)')

  const auth = useKeycloak()

  const reportResult = useItem(
    () =>
      query('report')
        .select({
          id: entity('report').property('id'),
          key: entity('report').property('key'),
          title: entity('report').property('title'),
          description: entity('report').property('description'),
          imageUrl: entity('report').property('imageUrl'),
          combinations: entity('report').property('combinations'),
          isPublic: entity('report').property('isPublic'),
        })
        .where(equals(entity('report').property('key'), string().value(key)))
        .limit(1),
    [key],
    {
      cache: 60 * 60 * 4,
      autoLoad: true,
    },
  )
  const reportId = reportResult.item?.id

  // Combinations
  const combinations = reportResult.item?.combinations as
    | {
        name: string
        variable: string
        options: { id: number; name: string; default: boolean }[]
        empty: { id: number | null; name: string; default: boolean }
      }[]
    | undefined

  const reportOutputResult = useItem(
    () =>
      query('reportOutput')
        .select({
          id: entity('reportOutput').property('id'),
          reportId: entity('reportOutput').property('reportId'),
          hash: entity('reportOutput').property('hash'),
          html: entity('reportOutput').property('html'),
          variables: entity('reportOutput').property('variables'),
          createdAt: entity('reportOutput').property('createdAt'),
          updatedAt: entity('reportOutput').property('updatedAt'),
        })
        .where(
          hash
            ? equals(entity('reportOutput').property('hash'), string().value(hash))
            : equals(entity('reportOutput').property('hash'), string().value('-')),
          equals(entity('reportOutput').property('reportId'), number().value(reportId as number)),
        )
        .limit(1),
    [reportId, hash, reportResult.item],
    {
      cache: 60 * 60 * 4,
      autoLoad: Boolean(reportResult.item),
    },
  )

  const iframeUrl = useMemo(() => {
    const html = reportOutputResult.item?.html as string | undefined
    if (!html) {
      return null
    }
    const blob = new Blob([html], { type: 'text/html' })
    const url = URL.createObjectURL(blob)
    return url
  }, [reportOutputResult.item?.html])

  const handleVariableChange = (variable: string, value: string, isDefault?: boolean) => {
    setIsLoadingReportStyle(true)
    const variables = reportOutputResult.item?.variables as any
    if (!combinations) {
      return
    }
    const newVariables = combinations
      .sort((a, b) => a.variable.localeCompare(b.variable))
      .reduce((obj: any, combination) => {
        if (combination.variable === variable) {
          obj[variable] = isEmpty(value) ? null : String(value)
          return obj
        }
        if (variables && variables[combination.variable]) {
          obj[combination.variable] = isEmpty(variables[combination.variable])
            ? null
            : String(variables[combination.variable])
          return obj
        }
        const defaultOption = combination.options
          .filter(option => option.default)
          .map(option => option.id)
        if (defaultOption.length) {
          obj[combination.variable] = defaultOption[0]
          return obj
        }
        obj[combination.variable] = null
        return obj
      }, {})

    const newHash = !isDefault ? md5(JSON.stringify(newVariables)) : undefined
    navigate(`/app/report/${key}/${newHash ? newHash : ''}`)
  }

  const [isLoadingReportStyle, setIsLoadingReportStyle] = useState<boolean>(true)
  // Styling iframe content
  useEffect(() => {
    function handleLoad() {
      const iframe = iframeRef.current
      if (!iframe || !iframe.contentWindow) {
        return
      }

      const doc = iframe.contentWindow.document

      if (doc) {
        setIsLoadingReportStyle(false)
      }

      let styleElement = doc.getElementById('iframe-style') as HTMLStyleElement

      if (!styleElement) {
        styleElement = doc.createElement('style')
        styleElement.id = 'iframe-style'
        doc.head.appendChild(styleElement)
      }

      styleElement.textContent = `
        main {
          margin-left: 20px;
        }

        .jp-MarkdownCell {
          max-width: 100%;
        }

        .jp-InputPrompt {
          display: none;
        }

        .jp-Cell {
          padding: 0;
        }

        .jp-OutputPrompt {
          display: none;
        }

        .jp-RenderedText pre {
          font-family: system-ui, -apple-system;
          font-size: 14px;
          margin: 0px 0px 7px;
          line-height: 23px;
          word-break: break-word;
          white-space: normal
          min-width: 0;
          // max-width: 900px;
          max-width: 100%;
        }

        .jp-RenderedHTMLCommon h5 {
          font-size: 16px;
        }
      `
    }

    const iframe = iframeRef.current

    if (iframe) {
      iframe.addEventListener('load', handleLoad)
    }

    // Removendo o evento ao desmontar o componente.
    return () => iframe?.removeEventListener('load', handleLoad)
  })

  return (
    <AppLayout
      title='Relatório'
      dockActive='start'
      mustBeAuthenticated={
        reportResult.item?.isPublic === true && auth.isAuthenticated === false ? false : true
      }
      error={reportResult.error}
      isLoading={reportResult.isLoading}
      menu={
        isMobile ? (
          <Wrapper>
            <Aside
              reportResult={reportResult}
              combinations={combinations}
              reportOutputResult={reportOutputResult}
              handleVariableChange={handleVariableChange}
            />
          </Wrapper>
        ) : null
      }
    >
      <Wrapper>
        {!isMobile ? (
          <Aside
            reportResult={reportResult}
            combinations={combinations}
            reportOutputResult={reportOutputResult}
            handleVariableChange={handleVariableChange}
          />
        ) : null}
        <main>
          <Handle error={reportOutputResult.error} isLoading={reportOutputResult.isLoading}>
            {iframeUrl ? (
              <iframe
                src={iframeUrl}
                ref={iframeRef}
                style={isLoadingReportStyle === false ? { display: 'block' } : { display: 'none' }}
              />
            ) : null}
            {isLoadingReportStyle === true ? <span>Carregando...</span> : null}
          </Handle>
        </main>
      </Wrapper>
    </AppLayout>
  )
}
