import { useEffect, useState } from 'react'

export interface UserGeolocation {
  accuracy: number | null
  altitude: number | null
  altitudeAccuracy: number | null
  heading: string | null
  latitude: number | null
  longitude: number | null
  speed: number | null
  timestamp: string | null
  error: Error | null
  isLoading: boolean
}

const useGeolocation = (
  {
    enableHighAccuracy = true,
    maximumAge = 7 * 60 * 1000, // 7 minutes
    timeout = 40000,
  } = {},
  callback?: any,
) => {
  const [coordinates, setCoordinates] = useState<UserGeolocation>({
    accuracy: null,
    altitude: null,
    altitudeAccuracy: null,
    heading: null,
    latitude: null,
    longitude: null,
    speed: null,
    timestamp: null,
    error: null,
    isLoading: true,
  })

  useEffect(() => {
    let cancel: any
    const updateCoordinates = ({ coords = {}, timestamp }: any) => {
      const { accuracy, altitude, altitudeAccuracy, heading, latitude, longitude, speed } = coords
      if (!latitude || !longitude) {
        return
      }

      const data = {
        accuracy,
        altitude,
        altitudeAccuracy,
        heading,
        latitude,
        longitude,
        speed,
        timestamp: timestamp || +new Date(),
        error: null,
      }

      if (!cancel) {
        setCoordinates({ ...data, isLoading: false })
        if (callback) {
          callback(data)
        }
      }

      if (window.localStorage) {
        window.localStorage.setItem('lastGeolocation', JSON.stringify(data))
      }
    }

    const setError: any = (error: Error) => {
      if (!cancel) {
        updateCoordinates({
          accuracy: null,
          altitude: null,
          altitudeAccuracy: null,
          heading: null,
          latitude: null,
          longitude: null,
          speed: null,
          timestamp: null,
          error,
          isLoading: false,
        })
      }
    }

    if (window.localStorage) {
      const currentStoredRaw = window.localStorage.getItem('lastGeolocation')
      const currentStored: any = currentStoredRaw ? JSON.stringify(currentStoredRaw) : null

      if (currentStored && currentStored.timestamp >= +new Date() - maximumAge) {
        const { timestamp, ...coords } = currentStored
        updateCoordinates({
          coords,
          timestamp,
        })
      }
    }

    let watchId: any
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(updateCoordinates, setError)
      watchId = navigator.geolocation.watchPosition(updateCoordinates, setError, {
        enableHighAccuracy,
        maximumAge,
        timeout,
      })
    }
    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId)
      }
      cancel = true
    }
  }, [])

  return coordinates
}

export default useGeolocation
