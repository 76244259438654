import React, { useEffect, useMemo, useState } from 'react'
import AppLayout from '~/components/appLayout'
import AttendantMapMenu from './attendantMapMenu.component'
import attendantVisitLocationsListQuery from '../visitLocationsList/visitLocationsList.query'
import useItems from '~/prix/react/hooks/items'
import { QueryBase } from '~/prix/query'
import { Wrapper } from '@googlemaps/react-wrapper'
import * as turf from '@turf/helpers'
import center from '@turf/center'
import useConfig from '~/prix/react/hooks/config'
import AttendantMap, { Features } from './attendantMap.component'
import useMedia from 'react-use/lib/useMedia'

export default function AttendantSelectableMap() {
  const [values, setValues] = useState<any>()
  const [zoom, setZoom] = useState<number>(4)
  const [menuVisibility, setMenuVisibility] = useState(false)
  const toggleMenuVisibility = () => setMenuVisibility(!menuVisibility)
  const [totalEntities, setTotalEntities] = useState<number | string | undefined>(undefined)
  const [legalEntityName, setLegalEntityName] = useState<string | undefined>(undefined)
  const [selectedLegalEntityId, setSelectedLegalEntityId] = useState<string | undefined>(undefined)
  const closeMenu = () => setSelectedLegalEntityId(undefined)
  const isMobile = useMedia('(max-width: 768px)')

  const config = useConfig()
  const apiKey = config.services.googleConfig.apiKey

  const listLegalEntitiesAttendances = useItems(
    () =>
      values !== undefined
        ? (attendantVisitLocationsListQuery(values) as QueryBase)
        : (null as never),
    [values],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: values !== undefined,
    },
  )

  const listLegalEntitiesAttendancesFormatted = useMemo(() => {
    const list = listLegalEntitiesAttendances.items
      ?.map((item: any) => {
        if (item.legalEntityCoordinates?.coordinates !== undefined) {
          return {
            type: 'Feature',
            properties: {
              scalerank: 2,
              name: item.legalEntityName,
              id: item.legalEntityId,
            },
            geometry: {
              type: 'Point',
              coordinates: item.legalEntityCoordinates?.coordinates,
            },
          }
        }
      })
      .filter((item: any) => item !== undefined && item.legalEntityDataSource !== 5) as Features[]

    // Query duplicando ao usar ultimo ano ou mes, solução momentânea
    const removerDuplicates = list?.filter(
      (element, index, array) =>
        array.findIndex(find => find.properties.id === element.properties.id) === index,
    )
    return removerDuplicates
  }, [listLegalEntitiesAttendances])

  const attendancesCoordinates = useMemo(
    () =>
      listLegalEntitiesAttendancesFormatted
        ?.map(item => item?.geometry?.coordinates ?? item?.geometry?.coordinates)
        .filter(item => item !== undefined),
    [listLegalEntitiesAttendancesFormatted],
  )

  const hasServiceUnitCoordinates =
    listLegalEntitiesAttendances?.items?.[0]?.serviceUnitCoordinates !== null &&
    listLegalEntitiesAttendances?.items?.[0]?.serviceUnitCoordinates !== undefined

  const serviceUnitCoordinates = listLegalEntitiesAttendances?.items?.[0]
    ?.serviceUnitCoordinates as turf.AllGeoJSON

  const data = {
    type: 'FeatureCollection',
    features: listLegalEntitiesAttendancesFormatted,
  }

  const turfCenter = useMemo(() => {
    const getLegalEntityCoordinates = hasServiceUnitCoordinates
      ? serviceUnitCoordinates
      : attendancesCoordinates && attendancesCoordinates?.length > 0
      ? turf.points(attendancesCoordinates)
      : null

    const legalEntityCoordinates = getLegalEntityCoordinates
      ? center(getLegalEntityCoordinates)
      : null

    return legalEntityCoordinates
  }, [attendancesCoordinates])

  useEffect(() => {
    setZoom(10)
  }, [turfCenter])

  return (
    <AppLayout
      title={'Mapa de atendimentos'}
      initialMenuVisibility={menuVisibility}
      dockActive='map'
      menu={() => (
        <AttendantMapMenu
          title={'Mapa de atendimentos'}
          onChangeValues={setValues}
          value={attendancesCoordinates ? attendancesCoordinates?.length : 0}
          isLoading={listLegalEntitiesAttendances.isLoading}
          toggleMenuVisibility={toggleMenuVisibility}
          handleTotalEntities={setTotalEntities}
        />
      )}
      card={
        !isMobile
          ? {
              title: selectedLegalEntityId ? `${legalEntityName}` : 'Mapa de atendimentos',
              selectedLegalEntity: selectedLegalEntityId
                ? { selectedLegalEntityId, selectedLegalEntityName: String(legalEntityName) }
                : undefined,
              totalEntities: totalEntities,
              onToggleMenu: closeMenu,
            }
          : {}
      }
    >
      <Wrapper apiKey={apiKey}>
        <AttendantMap
          center={turfCenter}
          zoom={zoom}
          data={data}
          attendant={values?.attendant}
          isLoading={listLegalEntitiesAttendances.isLoading}
          setLegalEntityName={setLegalEntityName}
          setSelectedLegalEntityId={setSelectedLegalEntityId}
        />
      </Wrapper>
    </AppLayout>
  )
}
