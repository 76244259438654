import { defineAction, object } from '~/prix'
import seedBrasiliaAbbreviaturesAction from './seedBrasiliaAbbreviatures.action.json'

const seedBrasiliaAbbreviaturesDefinition = defineAction({
  ...seedBrasiliaAbbreviaturesAction,
  input: object({}),
  output: object({}),
})

export default seedBrasiliaAbbreviaturesDefinition
