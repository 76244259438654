import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import AppLayout from '~/components/appLayout'
import OptionsMenu from '~/packages/option/menu.component'
import TimeSeriesSettings from './timeSeriesSettings.component'
import useAPI from '~/prix/react/hooks/api'
import useActionFunction from '~/prix/react/hooks/actionFunction'
import getNotificationSettingsDefinition from '../getNotificationSettings/getNotificationSettings.definition'
import sendTimeSeriesNotificationDefinition from '../sendTimeSeriesNotification/sendTimeSeriesNotification.definition'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 22px;
`
export type NotificationSettingsProps = {
  userEmail: string
  tsSebraeApp: string
  tsSebraeAppAlerts: string | null
  tsAllCourses: string
  tsAllCoursesAlerts: string | null
  tsPortfolioCourses: string
  tsPortfolioCoursesAlerts: string | null
  tsSse: string
  tsSseAlerts: string | null
  tsAli: string
  tsAliAlerts: string | null
  tsBusinessOpening: string
  tsBusinessOpeningAlerts: string | null
  tsBusinessClosing: string
  tsBusinessClosingAlerts: string | null
}

export default function NotificationSettingsScreen() {
  const [notificationSettings, setNotificationSettings] = useState<NotificationSettingsProps>()
  const { context } = useAPI()
  const roles = context?.user?.roles
  const { callAction: getNotificationSettings, isLoading: isLoadingNotificationSettings } = useActionFunction(getNotificationSettingsDefinition)

  useMemo(() => {
    if (roles && isLoadingNotificationSettings) {
      getNotificationSettings({})
        .resultPromise.then((result: any) => setNotificationSettings(result[0]))

    }
  }, [roles, isLoadingNotificationSettings])

  return (
    <AppLayout
      title='Configurações de Notificações'
      dockActive='notifications'
      menu={<OptionsMenu menuActive='notifications' />}
      card={{
        title: 'Configurações de Notificações',
      }}
    >
      <Wrapper>
        <TimeSeriesSettings roles={roles} notificationSettings={notificationSettings} isLoading={isLoadingNotificationSettings} />
      </Wrapper>
    </AppLayout>
  )
}