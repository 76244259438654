import React, { useMemo } from 'react'
import styled from 'styled-components'
import { fromUnknownError, AppError } from '~/prix'
import AlertIcon from '~/components/icons/ui/16px_alert.svg'
import { Button } from '~/prix/react/components/button'
import { colors } from '~/design'

export interface IssueProps extends React.HTMLAttributes<HTMLDivElement> {
  error: Error | AppError | null | undefined
  tryAgain?: boolean
}

const OverlayLoading = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 94, 184, 0.3);
  z-index: 1;
  align-items: center;

  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }

  .center-error {
    border-radius: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 15px;
    background-color: #fff;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    cursor: default;

    .content {
      display: flex;
      flex: 1;
      padding: 10px;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;

      .button {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`

const AlertWrapper = styled.div`
  padding: 15px;
`

export default function GeoprocessingMapError({ error, tryAgain }: IssueProps) {
  if (!error) {
    return null
  }

  const finalError = useMemo(() => {
    if (!error) {
      return null
    }
    return fromUnknownError(error)
  }, [error])

  const handleReload = () => {
    location.reload()
  }

  return (
    <OverlayLoading>
      <div className='center-error'>
        <div className='content'>
          <AlertWrapper>
            <AlertIcon fill={colors.darkGray} width={36} height={36} />
          </AlertWrapper>
          Ocorreu um erro
          <br />
          <b>{finalError?.message}</b>
          <br />
          {tryAgain === true ? (
            <div className='button'>
              <Button type='button' onClick={() => handleReload()}>
                Tentar novamente
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </OverlayLoading>
  )
}
