import type { InferNative, NativeBaseOptional, Type } from '../types/type'
import type { AbstractValueFunction, ValueFunctionContent } from './function'

export interface TernaryFunction<NativeGeneric extends NativeBaseOptional>
  extends AbstractValueFunction<NativeGeneric> {
  name: 'ternary'
  ifThis: ValueFunctionContent
  trueValue: ValueFunctionContent
  falseValue: ValueFunctionContent
}

export default function ternary<NativeGeneric extends NativeBaseOptional = NativeBaseOptional>(
  ifThis: ValueFunctionContent,
  trueValue: ValueFunctionContent,
  falseValue: ValueFunctionContent,
): TernaryFunction<NativeGeneric> {
  return {
    kind: 'function',
    name: 'ternary',
    content: ifThis,
    ifThis,
    trueValue,
    falseValue,
  }
}
