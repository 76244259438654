import React, { useEffect, useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import {
  FilterHighlight,
  GeoChildrenLegalEntitiesPossibilities,
  GeoLegalEntitiesPossibilities,
  Level,
} from '../legalEntityGeoprocessingMapLevels.data'
import styled from 'styled-components'
import useItems from '~/prix/react/hooks/items'
import additionalInformationQuery from './additionalInformation.query'
import { formatAsBrNumber } from '~/prix/utils/types/number'
import { QueryBase } from '~/prix/query'
import countCityCensusByPopulation from '~/packages/cityIbgeCensus/cityCensusByPopulation/countCityCensusByPopulation.query'
import countCityCensusEntitiesByArea from '~/packages/cityIbgeCensus/cityCensusByArea/countCityCensusByArea.query'
import useMedia from 'react-use/lib/useMedia'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 4px;

  .item-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    &.results-container {
      margin-top: 5;
      output {
        margin-top: 1px;
      }
      small {
        margin-top: 5px;
      }
    }
  }
`

export default function AdditionalInformation({
  isLoadingMainTotal,
  handleAdditionalInfo,
}: {
  isLoadingMainTotal: boolean
  handleAdditionalInfo: (value: string | null) => void
}) {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const [level, setLevel] = useState<Level | null>(null)
  const [isEnabled, setIsEnabled] = useState(false)
  const temporaryCountryId = '30'
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const isMobile = useMedia('(max-width: 768px)')

  const isAreaCensus =
    searchParams.get('highlightType') === 'area' &&
    searchParams.get('highlightValue') === 'censusUpdated'

  const isPopulationCensus =
    searchParams.get('highlightType') === 'perCapta' &&
    searchParams.get('highlightValue') === 'censusUpdated'

  useEffect(() => {
    if (params.by && params.id) {
      setLevel({
        geo: params.by as GeoLegalEntitiesPossibilities,
        id: params.id,
        childrenGeoLevel: params.childrenGeoLevel as
          | GeoChildrenLegalEntitiesPossibilities
          | undefined,
      })
      return
    }

    setLevel({
      geo: 'country',
      id: temporaryCountryId,
      childrenGeoLevel: params.childrenGeoLevel as
        | GeoChildrenLegalEntitiesPossibilities
        | undefined,
    })
  }, [params])

  const filter = useMemo(() => {
    const filterType = searchParams.get('filterType')
    const filterName = searchParams.get('filterName')
    const filterValue = searchParams.get('filterValue')
    const filterAttendanceSource = searchParams.get('filterAttendanceSource')
    return filterType
      ? ({
          type: filterType,
          name: filterName,
          value: filterValue,
          attendanceSource: filterAttendanceSource,
        } as FilterHighlight)
      : null
  }, [searchParams])

  const highlight = useMemo(() => {
    const highlightType = searchParams.get('highlightType')
    const highlightName = searchParams.get('highlightName')
    const highlightValue = searchParams.get('highlightValue')
    const highlightAttendanceSource = searchParams.get('highlightAttendanceSource')
    return highlightType
      ? ({
          type: highlightType,
          name: highlightName,
          value: highlightValue,
          attendanceSource: highlightAttendanceSource,
        } as FilterHighlight)
      : null
  }, [searchParams])

  const additionalInformationsResult = useItems(
    () =>
      level && isEnabled
        ? (additionalInformationQuery(level?.geo, level?.id!, filter) as QueryBase)
        : (null as never),
    [level, level?.id!, filter, isEnabled],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: isEnabled,
    },
  )

  const resultPopulation = useItems(
    () =>
      level && isEnabled
        ? (countCityCensusByPopulation({
            groupColumn: 'cityId',
            level: level?.geo,
            id: level?.id!,
          }) as QueryBase)
        : (null as never),
    [level, level?.id!, filter, isEnabled],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: isEnabled,
    },
  )

  const resultArea = useItems(
    () =>
      level && isEnabled
        ? (countCityCensusEntitiesByArea({
            groupColumn: 'cityId',
            level: level?.geo,
            id: level?.id!,
          }) as QueryBase)
        : (null as never),
    [level, level?.id!, filter, isEnabled],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: isEnabled,
    },
  )

  const mergePopulation =
    resultPopulation.items && additionalInformationsResult.items && isPopulationCensus
      ? resultPopulation.items.map(values => ({
          ...values,
          ...additionalInformationsResult.items?.find(items => values.geoId === items.cityId),
        }))
      : null

  const mergeArea =
    resultArea.items && additionalInformationsResult.items && isAreaCensus
      ? resultArea.items.map(values => ({
          ...values,
          ...additionalInformationsResult.items?.find(items => values.geoId === items.cityId),
        }))
      : null

  const resultMergePopulation = mergePopulation
    ? Number(mergePopulation[0]?.count) / Number(mergePopulation[0]?.highlight)
    : null

  const resultMergeArea = mergeArea
    ? Number(mergeArea[0]?.count) / Number(mergeArea[0]?.highlight)
    : null

  useEffect(() => {
    if (
      level &&
      (level.geo === 'country' ||
        level.geo === 'macroRegion' ||
        level.geo === 'state' ||
        level.geo === 'city')
    ) {
      setIsEnabled(true)
    } else {
      setIsEnabled(false)
    }
  }, [level])

  const additionalInfo = additionalInformationsResult.items
    ? formatAsBrNumber(
        additionalInformationsResult.items.reduce((acc, item) => acc + Number(item.count ?? 0), 0),
      )
    : null

  useEffect(() => {
    if (
      (additionalInformationsResult.isLoading &&
        ((filter && filter?.value) || (highlight && highlight?.value))) ||
      isLoadingMainTotal
    ) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [isLoadingMainTotal, additionalInformationsResult, filter, highlight])

  useEffect(() => {
    if (highlight || !isEnabled) {
      handleAdditionalInfo(null)
    }
  }, [highlight, isEnabled])

  if (isEnabled && additionalInfo !== '0') {
    if (isLoading) {
      handleAdditionalInfo('Carregando...')
    }

    if (additionalInfo && additionalInfo !== '0') {
      if (isPopulationCensus) {
        handleAdditionalInfo(
          `${formatAsBrNumber(Number(resultMergePopulation?.toFixed(2)))} empresas per capita`,
        )
      } else if (isAreaCensus) {
        handleAdditionalInfo(
          `${formatAsBrNumber(Number(resultMergeArea?.toFixed(2)))} empresas por km²`,
        )
      } else {
        handleAdditionalInfo(`${additionalInfo} ${additionalInfo !== '1' ? 'empresas' : 'empresa'}`)
      }
    }
  } else if (additionalInfo === '0') {
    handleAdditionalInfo(null)
  }
  return isMobile && isEnabled && additionalInfo !== '0' ? (
    <Wrapper>
      <div className='item-container results-container'>
        <label>Total de empresas fora dos limites da seleção</label>
        {isLoading ? (
          <small>Carregando...</small>
        ) : additionalInfo && additionalInfo !== '0' ? (
          <>
            {isPopulationCensus ? (
              <output>
                {formatAsBrNumber(Number(resultMergePopulation?.toFixed(2)))} empresas per capita{' '}
              </output>
            ) : isAreaCensus ? (
              <output>
                {formatAsBrNumber(Number(resultMergeArea?.toFixed(2)))} empresas por km²
              </output>
            ) : additionalInfo !== '1' ? (
              <output>{additionalInfo} empresas </output>
            ) : (
              <output>{additionalInfo} empresa </output>
            )}
          </>
        ) : null}
      </div>
    </Wrapper>
  ) : null
}
