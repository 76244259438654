import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import * as turf from '@turf/helpers'
import buffer from '@turf/buffer'
import bbox from '@turf/bbox'
import ngeohash from 'ngeohash'
import LegalEntityGeoprocessingMapDemarcationReport from './report/legalEntityGeoprocessingMapDemarcationReport.component'
import { PointFeatureWithRadius } from '~/prix/react/components/map/radiusEditor'

export default function LegalEntityGeoprocessingMapDemarcationRadiusScreen() {
  const params = useParams()
  const geohash = params.geohash as string
  const radius = params.radius as string
  const polygonFeature: PointFeatureWithRadius = useMemo(() => {
    const {
      latitude,
      longitude,
    } = ngeohash.decode(geohash)
    const coordinates = [longitude, latitude]
    const point = turf.point(coordinates, {
      radius: Number(radius),
    })
    const buffered = buffer(point, Number(radius) / 1000, {
      steps: 10,
      units: 'kilometers',
    })
    const currentBbox = bbox(buffered)
    point.bbox = currentBbox
    return point as PointFeatureWithRadius
  }, [geohash, radius])

  return (
    <LegalEntityGeoprocessingMapDemarcationReport feature={polygonFeature} />
  )
}