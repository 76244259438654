import { defineEntity, entity, foreign, object, string, timestamps } from '~/prix'

const attendantServiceUnit = defineEntity({
  key: 'attendantServiceUnit',
  title: 'Tabela com ids de agentes e unidades de atendimento',
  sources: ['olap', 'oltp'],
  schema: object({
    id: string().label('ID'),
    attendantId: foreign(entity('attendant').property('id')),
    serviceUnitId: foreign(entity('serviceUnit').property('id')),
    ...timestamps,
  }),
})

export default attendantServiceUnit
