import React, { useMemo } from 'react'
import { Context } from '~/prix'
import { APIProvider, API } from '~/prix/react/hooks/api'
import tokenParsedToUser from '../utils/shared/tokenParsedToUser'
import { useKeycloak } from './keycloak'

const date = new Date()

export default function ApiWithContext({
  children,
  entities,
  sources,
}: Omit<API, 'isLoading' | 'context'> & { children: React.ReactNode }) {
  const { token, tokenParsed, isLoading } = useKeycloak()

  const context: Context = useMemo(
    () => ({
      ip: '127.0.0.1',
      userAgent: navigator.userAgent,
      timezoneOffset: date.getTimezoneOffset(),
      token: token ?? null,
      user: tokenParsed && tokenParsed.sub ? tokenParsedToUser(tokenParsed) : null,
      bypassAuthorization: false,
    }),
    [isLoading, token],
  )

  return (
    <APIProvider
      value={{
        entities,
        sources,
        context,
        isLoading,
      }}
    >
      {children}
    </APIProvider>
  )
}
