const rolesAndNames = {
  administrator: 'Administrador',
  systemAdministrator: 'Administrador do Sistema',
  appSebrae: 'App Sebrae',
  uge: 'UGE',
  manager: 'Gestor Nacional',
  stateManager: 'Gestor Estadual',
  agent: 'Agente',
}

export type Role = keyof typeof rolesAndNames
export const roles = Object.keys(rolesAndNames) as Role[]
export default rolesAndNames
