import { boolean, defineAction, object } from '~/prix'
import legalEntityFetchAction from './legalEntityFetch.action.json' assert { type: 'json' }

const legalEntityFetchDefinition = defineAction({
  ...legalEntityFetchAction,
  input: object({
    force: boolean().isOptional(),
  }),
  output: object({}),
})

export default legalEntityFetchDefinition
