import { defineEntity, entity, foreign, number, object, string } from '~/prix'

const cityCensusByAreaEntity = defineEntity({
  key: 'cityCensusByArea',
  title: 'Quantidade de empresas por km²',
  alternativeTitles: ['Número de empresas dividido pelo valor da área de cidades'],
  sources: ['oltp'],
  schema: object({
    cityId: foreign(entity('city').property('id')).label('Cidade com informações do censo'),
    viewArea: number().label('Area total da cidade'),
    lastUpdated: number().label('Ano de Coleta do Dado'),
    entitiesByArea: number().label('Quantidade de empresas por área'),
    entitiesByCity: number().label('Quantidade de empresas por cidade'),
    microRegionId: foreign(entity('microRegion').property('id')),
    mesoRegionId: foreign(entity('mesoRegion').property('id')),
    stateId: foreign(entity('state').property('id')),
    macroRegionId: foreign(entity('macroRegion').property('id')),
    countryId: foreign(entity('country').property('id')),
  }),
})

export default cityCensusByAreaEntity
