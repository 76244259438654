import { parseDateTime } from './types/dateTime'
import { ObjectType } from './types/object'
import { AbstractType, GenericObject, InferNative, NativeBaseOptional } from './types/type'

export default function cast<Schema extends AbstractType>(
  item: NativeBaseOptional,
  schema: Schema,
): InferNative<Schema> {
  if (item === null) {
    return null as InferNative<Schema>
  }

  if (schema.type === 'object') {
    const objectSchema = (schema as unknown) as ObjectType
    if (typeof item !== 'object') {
      return null as InferNative<Schema>
    }

    const result: GenericObject = {}
    const genericObjectItem = item as GenericObject
    for (let key in genericObjectItem) {
      if (!objectSchema.shape[key]) {
        continue
      }

      result[key] = cast(genericObjectItem[key], objectSchema.shape[key])
    }
    return result as InferNative<Schema>
  }

  if (schema.type === 'dateTime') {
    return (typeof item === 'string'
      ? parseDateTime(item)
      : item instanceof Date
      ? item
      : null) as InferNative<Schema>
  }

  if (schema.type === 'date') {
    return (item instanceof Date ? item.toISOString().slice(0, 10) : String(item)).slice(
      0,
      10,
    ) as InferNative<Schema>
  }

  if (schema.type === 'time') {
    return (item instanceof Date
      ? `${item.getHours()}:${item.getMinutes()}:${item.getSeconds()}`
      : String(item)) as InferNative<Schema>
  }

  if (schema.type === 'number') {
    return (typeof item === 'string'
      ? parseFloat(item)
      : typeof item === 'number' || typeof item === 'bigint'
      ? item
      : null) as InferNative<Schema>
  }

  if (schema.type === 'boolean') {
    return (item === '0' || item === 'false' ? false : Boolean(item)) as InferNative<Schema>
  }

  if (schema.type === 'string') {
    return String(item) as InferNative<Schema>
  }

  if (schema.type === 'foreign') {
    return (typeof item === 'number' ? item : String(item)) as InferNative<Schema>
  }

  return item as InferNative<Schema>
}
