import React, { useEffect, useMemo, useRef, useState } from 'react'
import useMedia from 'react-use/lib/useMedia'
import styled from 'styled-components'
import List, { ListFooter } from '~/components/list'
import { institutionalColors } from '~/design'
import LeftArrowIcon from '~/meta/angle-left-arrow.svg'
import Link from '~/prix/react/components/link'
import RedirectIcon from '~/components/icons/arrows/16px_curved-next.svg'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  margin-top: 64px;
  margin-bottom: 32px;
  
  article {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-wrap: wrap;
    margin: 0 32px 15px 32px;
    padding: 64px 24px 24px 24px;
    color: ${({ theme }) => theme.colors.darkGrey};
    justify-items: center;
    position: relative;
    box-shadow: 0px 15px 13px rgba(20, 46, 82, 0.20);
    border-radius: 12px;
    
    @media (max-width: 980px) {
      padding: 64px 10px 24px 0px;
    }

    @media (max-width: 550px) {
      margin: 0 32px;
    }
  }
`

const CardReadOne = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 35px;
  margin: 0px 32px;

 @media (max-width: 550px) {
    margin: 10px 32px;
 }
`

const CardReadOneTitle = styled.div`
  display: flex;
  flex: 1;
  margin: -40px 16px;
  border-radius: 8px 8px 0px 0px;
  background: var(--p-action, #0F438A);
  padding: 15px 25px;
  width: 100%;
  height: 150px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: 10px;

  button {
    height: 3rem;
    width: 3rem;
    padding: 8px;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    border: none;
    border-radius: 100px;
    background: #005EB8;
    cursor: pointer;
    
    svg {
      margin-top: 2px;
      fill: #fff;
    }
  }
  
  h3 {
    color: #FFF;
    font-weight: 400;
    line-height: normal;
    font-size: 2rem;
  }
  
  div {
    min-width: 3rem;
  }
`

const ListRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 46px;
  align-items: center;
  border-bottom: 1px solid #E6E6E6;

  @media (max-width: 880px) {
    width: -webkit-fill-available;
  }
`

const ListHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 46px;
  align-items: center;
  border-bottom: 1px solid #E6E6E6;
  margin-top: 4px;
  padding-right: 12px;
`

const ListHeaderColumn = styled.div`
  display: block;
  gap: 4px;
  padding-left: 24px;  

  color: ${institutionalColors.graySebrae60};
  font-size: 0.89rem;
  font-weight: 700;
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const ListData = styled.div`
  display: block;
  gap: 4px;
  padding: 0 24px;

  overflow: hidden;
  color: ${institutionalColors.graySebrae30};
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.89655rem;
  font-weight: 400;
  text-transform: capitalize;
`

export default function AgentIntersectionModal({
  onClose,
  agents,
}: {
  onClose?: any
  agents?: any
}) {
  const isMobile = useMedia('(max-width: 1270px) and (min-width: 768px)')
  const [currentPage, setCurrentPage] = useState(1)
  const refWrapper = useRef<HTMLDivElement>(null)

  const itemsPerPage = 8
  const hideColumn = 'cpf'
  const fractions = undefined

  const agentListFormatted = useMemo(() => {
    return agents?.map((agent: any) => {
      const agentObject = JSON.parse(agent)
      return {
        name: agentObject.name.toLowerCase(),
        cpf: agentObject.cpf,
        hash: agentObject.hash,
      }
    }).sort((a: { name: string }, b: { name: any }) => a.name.localeCompare(b.name))
  }, [agents])

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = currentPage * itemsPerPage
  const currentData = agentListFormatted?.slice(startIndex, endIndex)

  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1)
  }

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1)
  }

  const goToPage = (page: number) => {
    setCurrentPage(page)
  }

  const labels = {
    name: 'Nome',
    cpf: 'CPF',
  } as any

  const renderList = (item: any, wrapperWidth: any) => {
    const columns = Object.keys(labels)

    return columns.map((key, index) => (
      <>
        {isMobile && hideColumn === key ? null : (
          <React.Fragment key={key}>
            <ListData
              style={fractions && fractions[key] ? { width: wrapperWidth * fractions[key] } : { flex: 1 }}
            >
              {item[key]}
            </ListData>
          </React.Fragment>
        )}

        {index === columns.length - 1 && (
          <a
            href={`/app/agent-report/${item.hash}/0`}
            className='link'
            title='Acessar'
          >
            <RedirectIcon
              fill={'#0F438A'}
              width={24}
              height={24}
            />
          </a>
        )}
      </>
    ))
  }

  return (
    <Wrapper>
      <CardReadOne>
        <CardReadOneTitle>
          <button onClick={event => {
            event.preventDefault()
            onClose()
          }}>
            <LeftArrowIcon fill='#fff' />
          </button>
          <h3>
            Lista de agentes vizinhos
          </h3>
          <div />
        </CardReadOneTitle>
      </CardReadOne>
      <article>
        <ListHeaderRow>
          {Object.keys(labels).map((item: string, index: number) => {
            return (isMobile && hideColumn == item ? null :
              <ListHeaderColumn
                key={index}
                style={fractions && fractions[item] && refWrapper ? { width: refWrapper.current?.clientWidth! * fractions[item] } : { flex: 1 }}
              >
                {labels[item]}
              </ListHeaderColumn>
            )
          })}
        </ListHeaderRow>

        {currentData.map((item: any, index: number) => (
          <ListRow key={index}>
            {isMobile && hideColumn == item ? null : renderList(item, refWrapper.current?.clientWidth!)}
          </ListRow>
        ))}

      </article>
      <ListFooter
        currentPage={currentPage}
        totalPages={agents ? Math.ceil(agents.length / itemsPerPage) : 0}
        itemsPerPage={itemsPerPage}
        totalItems={agents ? agents.length : 0}
        onNextPage={nextPage}
        onPrevPage={prevPage}
        onGoToPage={goToPage}
      />
    </Wrapper>
  )
}