import { defineEntity, entity, foreign, number, object } from '~/prix'

const cityIbgeCensus = defineEntity({
  key: 'cityIbgeCensus',
  title: 'Dados censitários da cidade.',
  sources: ['olap', 'oltp'],
  schema: object({
    id: number().label('Identificador do registro'),
    area: number().label('Area da cidade em km²'),
    grossDomesticProduct: number().label('Produto Interno Bruto (PIB) em Mil'),
    population: number().label('População Residente da'),
    populationEstimative: number().label('Estimativa de População'),
    populationDensity: number().label('Densidade da População (Habitante por km²)'),
    year: number().label('Ano de Coleta do Dado'),
    cityId: foreign(entity('city').property('id')),
  }),
})

export default cityIbgeCensus
