import { defineAction } from '~/prix'
import any from '~/prix/types/any'
import { BaseActionDefinition } from '../actionDefinition'
import { EntityKey } from '../entity'

export default function defineReadAutoAction(
  legalEntityGeoprocessingReadActionJson: BaseActionDefinition,
  targetEntity: EntityKey,
) {
  return defineAction({
    ...legalEntityGeoprocessingReadActionJson,
    operation: 'read',
    targetEntity,
    input: any(),
    output: any(),
  })
}
