import React, { memo } from 'react'
import { Text, View, StyleSheet, Font } from '@react-pdf/renderer'
import { formatAsBrNumber } from '~/prix'

Font.register({
  family: 'Lato-700',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVew-FGC_p9dw.ttf',
    },
  ],
})

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    padding: 10,
  },
  table: {
    flexGrow: 1,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '11px',
  },

  cell: {
    flex: 1,
  },

  firstColumnCell: {
    display: 'flex',
    flex: 1,
    paddingTop: '4px',
    minHeight: '28px',
    maxWidth: '125px',
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: 'rgba(0, 94, 184, 0.05)',
    border: '1px solid #c4c4c4',
  },

  columnCell: {
    display: 'flex',
    minHeight: '28px',
    maxHeight: '28px',
    maxWidth: '100px',
    border: '1px solid #c4c4c4',
  },

  columnHeaderView: {
    backgroundColor: 'rgba(0, 94, 184, 0.05)',
  },

  columnHeaderText: {
    fontSize: '11px',
    fontFamily: 'Lato-700',
  },

  columnText: {
    fontSize: '10px',
    paddingTop: '8px',
  },

  paddingTotalTextHeader: {
    paddingTop: '4px',
  },

  textBold: {
    fontFamily: 'Lato-700',
  }

})

function legalEntityGeoprocessingMapDemarcationReportTablePdf(values?: any) {
  const { values: data } = values

  const tableRow = (label: string, data) => ({
    col1: label,
    col2: formatAsBrNumber(data?.count),
    col3: formatAsBrNumber(data?.percentage.toFixed(2)),
    col4: formatAsBrNumber(data?.total),
    col5: formatAsBrNumber(data?.average),
  })

  const table = [
    tableRow('Auxílio Brasil', data.auxilioBrasil),
    tableRow('Auxílio Emergencial', data.auxilioEmergencial),
    tableRow('BPC', data.bpc),
    tableRow('Bolsa Família', data.bolsaFamilia),
    tableRow('Novo Bolsa Família', data.novoBolsaFamilia),
    tableRow('PETI', data.peti),
    tableRow('Garantia-Safra', data.safra),
    tableRow('Seguro Defeso', data.seguroDefeso),
    tableRow('Total de benefícios', data.totalBenefits),
  ]

  return (
    <View style={styles.table}>
      <View style={styles.row}>
        <Text style={[styles.cell, styles.firstColumnCell, styles.textBold]}>Programa</Text>
        <View style={[styles.cell, styles.columnCell, styles.columnHeaderView]}>
          <Text style={styles.columnHeaderText}>
            Quantidade de
          </Text>
          <Text style={styles.columnHeaderText}>
            beneficiarios
          </Text>
        </View>
        <View style={[styles.cell, styles.columnCell, styles.columnHeaderView]}>
          <Text style={styles.columnHeaderText}>
            Percentual de
          </Text>
          <Text style={styles.columnHeaderText}>
            beneficiarios
          </Text>
        </View>
        <View style={[styles.cell, styles.columnCell, styles.columnHeaderView]}>
          <Text style={[styles.columnHeaderText, styles.paddingTotalTextHeader]}>Totalizadores</Text>
        </View>
        <View style={[styles.cell, styles.columnCell, styles.columnHeaderView]}>
          <Text style={styles.columnHeaderText}>
            Média de
          </Text>
          <Text style={styles.columnHeaderText}>
            transferências
          </Text>
        </View>
      </View>
      {table.map((item, index) => (
        <View key={index} style={styles.row}>
          <Text style={[styles.cell, styles.firstColumnCell, styles.textBold, styles.columnText]}>{item.col1}</Text>
          <Text style={[styles.cell, styles.columnCell, styles.columnText]}>{item.col2}</Text>
          <Text style={[styles.cell, styles.columnCell, styles.columnText]}>{item.col3}%</Text>
          <Text style={[styles.cell, styles.columnCell, styles.columnText]}>R$ {item.col4}</Text>
          <Text style={[styles.cell, styles.columnCell, styles.columnText]}>R$ {item.col5}</Text>
        </View>
      ))}
    </View>
  )
}

export default memo(legalEntityGeoprocessingMapDemarcationReportTablePdf)