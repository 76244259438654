import { count, entity, query, notNull, equals, string, truthy } from '~/prix'

export default function partialQuery(stateAbbreviation?: string) {
  return query('legalEntityGeoprocessing')
    .select({
      total: count(entity('legalEntityGeoprocessing').property('id')),
      count: count(notNull(entity('legalEntityGeoprocessing').property('cityId'))),
    })
  [stateAbbreviation !== undefined ? 'join' : 'dummy']({
    current: entity('legalEntityGeoprocessing').property('stateId'),
    target: entity('state').property('id'),
    join: 'inner',
  })
    .where(
      ...[
        stateAbbreviation && stateAbbreviation !== undefined
          ? equals(entity('state').property('abbreviation'), string().value(stateAbbreviation))
          : null,
      ].filter(truthy),
    )
    .limit(1)
}