import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import * as htmlToImage from 'html-to-image'

export interface ChartDataProps {
  year: string
  month: string
  balance: number
  admissions: number
  demissions: number
}

interface LegalEntityGeoprocessingMapDemarcationReportChartProps {
  values: ChartDataProps[]
  setImageUrl: React.Dispatch<React.SetStateAction<Array<string>>>
}

function legalEntityGeoprocessingMapDemarcationReportChartRender({
  values,
  setImageUrl,
}: LegalEntityGeoprocessingMapDemarcationReportChartProps) {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [isVisible, setIsVisible] = useState(true)
  const lastYear = values?.slice(Math.max(values?.length - 12, 0))

  const chartData = useMemo(() => {
    return lastYear.map((item: ChartDataProps) => {
      return {
        date: `${item.month}/${item.year.slice(-2)}`,
        admissões: item.admissions,
        demissões: item.demissions,
      }
    })
  }, [values])

  useEffect(() => {
    if (chartRef.current && chartData) {
      htmlToImage
        .toPng(chartRef.current, { skipFonts: true })
        .then(dataUrl => {
          setImageUrl((prevValues: string[]) => [...prevValues, dataUrl])
          setTimeout(() => {
            setIsVisible(false)
          }, 500)
        })
        .catch(error => {
          console.error('Error generating chart image:', error)
        })
    }

    return () => {
      setImageUrl([])
    }
  }, [chartData])

  return chartData ? (
    <div ref={chartRef} style={{ display: isVisible ? 'block' : 'none' }}>
      <ResponsiveContainer width={'99%'} height={350}>
        <LineChart
          width={500}
          height={400}
          data={chartData}
          margin={{
            top: 5,
            right: 5,
            left: 5,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray='5 5' opacity={0.8} />
          <XAxis
            dataKey='date'
            interval={0}
            angle={-45}
            textAnchor='end'
            height={60}
            tick={{ fontSize: 13 }}
          />
          <YAxis allowDataOverflow={true} tick={{ fontSize: 13 }} />
          {/* <Tooltip /> */}
          <Legend />
          <Line
            type='linear'
            dataKey='admissões'
            isAnimationActive={false}
            stroke='#005EB8'
            strokeWidth={2.5}
            activeDot={{ r: 8 }}
          />
          <Line
            type='linear'
            dataKey='demissões'
            isAnimationActive={false}
            stroke='#d30d0d'
            strokeWidth={2.5}
            activeDot={{ r: 6 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  ) : null
}

export default memo(legalEntityGeoprocessingMapDemarcationReportChartRender)
