import {
  entity,
  equals,
  query,
  string,
  count,
  isNull,
  truthy,
  likeAny,
  notNull,
  boolean,
  number,
  between,
} from '~/prix'
import {
  GeoLegalEntitiesPossibilities,
  optionsBooleanValue,
} from '../legalEntityGeoprocessingMapLevels.data'
import { FilterHighlight } from '../legalEntityGeoprocessingMapLevels.data'
import { extractDates } from '../definedQueries/utils'
import { formatISO, subYears } from 'date-fns'

export default function additionalInformationQuery(
  by: GeoLegalEntitiesPossibilities,
  id: string,
  filter: FilterHighlight | null,
) {
  if (
    by === 'neighborhood' ||
    by === 'street' ||
    by === 'clusteredLegalEntity' ||
    by === 'legalEntity'
  ) {
    return {}
  }

  const now = new Date()
  const oneYearAgo = subYears(now, 1)

  const formattedNow = formatISO(now, { representation: 'date' })
  const formattedOneYearAgo = formatISO(oneYearAgo, { representation: 'date' })

  const [filterStartDate, filterFinalDate] =
    filter && filter.value && typeof filter.value === 'string' && filter.type === 'attendance'
      ? extractDates(filter.value)
      : [formattedOneYearAgo, formattedNow]

  switch (by) {
    case 'country':
      return query('legalEntityGeoprocessing')
        .select({
          total: count(entity('legalEntityGeoprocessing').property('id')),
          count: count(isNull(entity('legalEntityGeoprocessing').property(`countryId`))),
        })
        .join({
          current: entity('legalEntityGeoprocessing').property('legalEntityId'),
          target: entity('legalEntity').property('id'),
          join: 'inner',
        })
        [filter && filter.type === 'attendance' && filter.value ? 'join' : 'dummy']({
          current: entity('legalEntityGeoprocessing').property('legalEntityId'),
          target: entity('legalEntityAttendance').property('legalEntityId'),
          join: 'left',
        })
        [
          filter && filter.type === 'attendance' && filter.value && filter.attendanceSource
            ? 'join'
            : 'dummy'
        ]({
          current: entity('legalEntityAttendance').property('id'),
          target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
          join: 'left',
        })
        .where(
          ...[
            equals(entity('legalEntity').property('registrationStatus'), string().value('active')),
            filter?.type === 'size' && filter?.value
              ? equals(
                  entity('legalEntity').property(`${filter?.type}`),
                  string().value(`${filter?.value!}`),
                )
              : null,

            filter?.type === 'attendance' && filter?.value
              ? notNull(entity('legalEntityAttendance').property('legalEntityId'))
              : null,

            filter?.type === 'attendance' && filter?.value && filter?.attendanceSource
              ? equals(
                  entity('legalEntityAttendanceDataSource').property(`dataSourceId`),
                  number().value(Number(filter?.attendanceSource)),
                )
              : null,

            filter?.type === 'attendance' &&
            filter?.value &&
            filter?.attendanceSource &&
            filterStartDate &&
            filterFinalDate
              ? between(
                  entity('legalEntityAttendance').property('startDate'),
                  string().value(String(filterStartDate)),
                  string().value(String(filterFinalDate)),
                )
              : null,

            filter?.type === 'cnae' && filter?.value
              ? likeAny(
                  entity('legalEntity').property('cnaes'),
                  filter?.value
                    .toString()
                    .split(',')
                    .map(cnae_id => string().value(`%,${cnae_id!}%`)),
                  { sensitive: true },
                )
              : null,
            filter?.type === 'legalNature' && filter?.value
              ? equals(
                  entity('legalEntity').property('legalNatureId'),
                  string().value(`${filter?.value!}`),
                )
              : null,
            optionsBooleanValue.includes(`${filter?.type}`)
              ? equals(entity('legalEntity').property(`${filter?.type}`), boolean().value(true))
              : null,
          ].filter(truthy),
        )
        .limit(1)
    // Macro
    case 'macroRegion':
      return query('legalEntityGeoprocessing')
        .select({
          total: count(entity('legalEntityGeoprocessing').property('id')),
          count: count(isNull(entity('legalEntityGeoprocessing').property(`stateId`))),
        })
        .join({
          current: entity('legalEntityGeoprocessing').property('legalEntityId'),
          target: entity('legalEntity').property('id'),
        })
        [filter && filter.type === 'attendance' && filter.value ? 'join' : 'dummy']({
          current: entity('legalEntityGeoprocessing').property('legalEntityId'),
          target: entity('legalEntityAttendance').property('legalEntityId'),
          join: 'left',
        })
        [
          filter && filter.type === 'attendance' && filter.value && filter.attendanceSource
            ? 'join'
            : 'dummy'
        ]({
          current: entity('legalEntityAttendance').property('id'),
          target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
          join: 'left',
        })
        .where(
          ...[
            equals(entity('legalEntity').property('registrationStatus'), string().value('active')),
            equals(
              entity('legalEntityGeoprocessing').property('macroRegionId'),
              string().value(id),
            ),
            filter?.type === 'size' && filter?.value
              ? equals(
                  entity('legalEntity').property(`${filter?.type}`),
                  string().value(`${filter?.value!}`),
                )
              : null,

            filter?.type === 'attendance' && filter?.value
              ? notNull(entity('legalEntityAttendance').property('legalEntityId'))
              : null,

            filter?.type === 'attendance' && filter?.value && filter?.attendanceSource
              ? equals(
                  entity('legalEntityAttendanceDataSource').property(`dataSourceId`),
                  number().value(Number(filter?.attendanceSource)),
                )
              : null,

            filter?.type === 'attendance' &&
            filter?.value &&
            filter?.attendanceSource &&
            filterStartDate &&
            filterFinalDate
              ? between(
                  entity('legalEntityAttendance').property('startDate'),
                  string().value(String(filterStartDate)),
                  string().value(String(filterFinalDate)),
                )
              : null,

            filter?.type === 'cnaes' && filter?.value
              ? likeAny(
                  entity('legalEntity').property('cnaes'),
                  filter?.value
                    .toString()
                    .split(',')
                    .map(cnae_id => string().value(`%,${cnae_id!}%`)),
                  { sensitive: true },
                )
              : null,
            filter?.type === 'legalNature' && filter?.value
              ? equals(
                  entity('legalEntity').property('legalNatureId'),
                  string().value(`${filter?.value!}`),
                )
              : null,
            optionsBooleanValue.includes(`${filter?.type}`)
              ? equals(entity('legalEntity').property(`${filter?.type}`), boolean().value(true))
              : null,
          ].filter(truthy),
        )
        .limit(1)
    // Nível estado
    case 'state':
      return query('legalEntityGeoprocessing')
        .select({
          total: count(entity('legalEntityGeoprocessing').property('id')),
          count: count(isNull(entity('legalEntityGeoprocessing').property(`cityId`))),
        })
        .join({
          current: entity('legalEntityGeoprocessing').property('legalEntityId'),
          target: entity('legalEntity').property('id'),
          join: 'inner',
        })
        [filter && filter.type === 'attendance' && filter.value ? 'join' : 'dummy']({
          current: entity('legalEntityGeoprocessing').property('legalEntityId'),
          target: entity('legalEntityAttendance').property('legalEntityId'),
          join: 'left',
        })
        [
          filter && filter.type === 'attendance' && filter.value && filter.attendanceSource
            ? 'join'
            : 'dummy'
        ]({
          current: entity('legalEntityAttendance').property('id'),
          target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
          join: 'left',
        })
        .where(
          ...[
            equals(entity('legalEntity').property('registrationStatus'), string().value('active')),
            equals(entity('legalEntityGeoprocessing').property('stateId'), string().value(id)),
            filter?.type === 'size' && filter?.value
              ? equals(
                  entity('legalEntity').property(`${filter?.type}`),
                  string().value(`${filter?.value!}`),
                )
              : null,

            filter?.type === 'attendance' && filter?.value
              ? notNull(entity('legalEntityAttendance').property('legalEntityId'))
              : null,

            filter?.type === 'attendance' && filter?.value && filter?.attendanceSource
              ? equals(
                  entity('legalEntityAttendanceDataSource').property(`dataSourceId`),
                  number().value(Number(filter?.attendanceSource)),
                )
              : null,

            filter?.type === 'attendance' &&
            filter?.value &&
            filter?.attendanceSource &&
            filterStartDate &&
            filterFinalDate
              ? between(
                  entity('legalEntityAttendance').property('startDate'),
                  string().value(String(filterStartDate)),
                  string().value(String(filterFinalDate)),
                )
              : null,

            filter?.type === 'cnaes' && filter?.value
              ? likeAny(
                  entity('legalEntity').property('cnaes'),
                  filter?.value
                    .toString()
                    .split(',')
                    .map(cnae_id => string().value(`%,${cnae_id!}%`)),
                  { sensitive: true },
                )
              : null,
            filter?.type === 'legalNature' && filter?.value
              ? equals(
                  entity('legalEntity').property('legalNatureId'),
                  string().value(`${filter?.value!}`),
                )
              : null,
            optionsBooleanValue.includes(`${filter?.type}`)
              ? equals(entity('legalEntity').property(`${filter?.type}`), boolean().value(true))
              : null,
          ].filter(truthy),
        )
        .limit(1)
    // Nível meso-região
    case 'mesoRegion':
      return query('legalEntityGeoprocessing')
        .select({
          total: count(entity('legalEntityGeoprocessing').property('id')),
          count: count(isNull(entity('legalEntityGeoprocessing').property(`microRegionId`))),
        })
        .join({
          current: entity('legalEntityGeoprocessing').property('legalEntityId'),
          target: entity('legalEntity').property('id'),
          join: 'inner',
        })
        [filter && filter.type === 'attendance' && filter.value ? 'join' : 'dummy']({
          current: entity('legalEntityGeoprocessing').property('legalEntityId'),
          target: entity('legalEntityAttendance').property('legalEntityId'),
          join: 'left',
        })
        [
          filter && filter.type === 'attendance' && filter.value && filter.attendanceSource
            ? 'join'
            : 'dummy'
        ]({
          current: entity('legalEntityAttendance').property('id'),
          target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
          join: 'left',
        })
        .where(
          ...[
            equals(entity('legalEntity').property('registrationStatus'), string().value('active')),
            equals(entity('legalEntityGeoprocessing').property('mesoRegionId'), string().value(id)),
            filter?.type === 'size' && filter?.value
              ? equals(
                  entity('legalEntity').property(`${filter?.type}`),
                  string().value(`${filter?.value!}`),
                )
              : null,

            filter?.type === 'attendance' && filter?.value
              ? notNull(entity('legalEntityAttendance').property('legalEntityId'))
              : null,

            filter?.type === 'attendance' && filter?.value && filter?.attendanceSource
              ? equals(
                  entity('legalEntityAttendanceDataSource').property(`dataSourceId`),
                  number().value(Number(filter?.attendanceSource)),
                )
              : null,

            filter?.type === 'attendance' &&
            filter?.value &&
            filter?.attendanceSource &&
            filterStartDate &&
            filterFinalDate
              ? between(
                  entity('legalEntityAttendance').property('startDate'),
                  string().value(String(filterStartDate)),
                  string().value(String(filterFinalDate)),
                )
              : null,

            filter?.type === 'cnaes' && filter?.value
              ? likeAny(
                  entity('legalEntity').property('cnaes'),
                  filter?.value
                    .toString()
                    .split(',')
                    .map(cnae_id => string().value(`%,${cnae_id!}%`)),
                  { sensitive: true },
                )
              : null,
            filter?.type === 'legalNature' && filter?.value
              ? equals(
                  entity('legalEntity').property('legalNatureId'),
                  string().value(`${filter?.value!}`),
                )
              : null,
            optionsBooleanValue.includes(`${filter?.type}`)
              ? equals(entity('legalEntity').property(`${filter?.type}`), boolean().value(true))
              : null,
          ].filter(truthy),
        )
        .limit(1)
    // Micro
    case 'microRegion':
      return query('legalEntityGeoprocessing')
        .select({
          total: count(entity('legalEntityGeoprocessing').property('id')),
          count: count(isNull(entity('legalEntityGeoprocessing').property(`cityId`))),
        })
        .join({
          current: entity('legalEntityGeoprocessing').property('legalEntityId'),
          target: entity('legalEntity').property('id'),
          join: 'inner',
        })
        [filter && filter.type === 'attendance' && filter.value ? 'join' : 'dummy']({
          current: entity('legalEntityGeoprocessing').property('legalEntityId'),
          target: entity('legalEntityAttendance').property('legalEntityId'),
          join: 'left',
        })
        [
          filter && filter.type === 'attendance' && filter.value && filter.attendanceSource
            ? 'join'
            : 'dummy'
        ]({
          current: entity('legalEntityAttendance').property('id'),
          target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
          join: 'left',
        })
        .where(
          ...[
            equals(entity('legalEntity').property('registrationStatus'), string().value('active')),
            equals(
              entity('legalEntityGeoprocessing').property('microRegionId'),
              string().value(id),
            ),
            equals(
              entity('legalEntityGeoprocessing').property('microRegionId'),
              string().value(id),
            ),
            filter?.type === 'size' && filter?.value
              ? equals(
                  entity('legalEntity').property(`${filter?.type}`),
                  string().value(`${filter?.value!}`),
                )
              : null,

            filter?.type === 'attendance' && filter?.value
              ? notNull(entity('legalEntityAttendance').property('legalEntityId'))
              : null,

            filter?.type === 'attendance' && filter?.value && filter?.attendanceSource
              ? equals(
                  entity('legalEntityAttendanceDataSource').property(`dataSourceId`),
                  number().value(Number(filter?.attendanceSource)),
                )
              : null,

            filter?.type === 'attendance' &&
            filter?.value &&
            filter?.attendanceSource &&
            filterStartDate &&
            filterFinalDate
              ? between(
                  entity('legalEntityAttendance').property('startDate'),
                  string().value(String(filterStartDate)),
                  string().value(String(filterFinalDate)),
                )
              : null,

            filter?.type === 'cnaes' && filter?.value
              ? likeAny(
                  entity('legalEntity').property('cnaes'),
                  filter?.value
                    .toString()
                    .split(',')
                    .map(cnae_id => string().value(`%,${cnae_id!}%`)),
                  { sensitive: true },
                )
              : null,
            filter?.type === 'legalNature' && filter?.value
              ? equals(
                  entity('legalEntity').property('legalNatureId'),
                  string().value(`${filter?.value!}`),
                )
              : null,
            optionsBooleanValue.includes(`${filter?.type}`)
              ? equals(entity('legalEntity').property(`${filter?.type}`), boolean().value(true))
              : null,
          ].filter(truthy),
        )
        .limit(1)
    // Cidade
    case 'city':
      return query('legalEntityGeoprocessing')
        .select({
          total: count(entity('legalEntityGeoprocessing').property('id')),
          count: count(isNull(entity('legalEntityGeoprocessing').property(`neighborhoodId`))),
          cityId: entity('city').property('id'),
        })
        .join({
          current: entity('legalEntityGeoprocessing').property('cityId'),
          target: entity('city').property('id'),
        })
        .join({
          current: entity('legalEntityGeoprocessing').property('legalEntityId'),
          target: entity('legalEntity').property('id'),
        })
        [filter && filter.type === 'attendance' && filter.value ? 'join' : 'dummy']({
          current: entity('legalEntityGeoprocessing').property('legalEntityId'),
          target: entity('legalEntityAttendance').property('legalEntityId'),
          join: 'left',
        })
        [
          filter && filter.type === 'attendance' && filter.value && filter.attendanceSource
            ? 'join'
            : 'dummy'
        ]({
          current: entity('legalEntityAttendance').property('id'),
          target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
          join: 'left',
        })
        .where(
          ...[
            equals(entity('legalEntity').property('registrationStatus'), string().value('active')),
            equals(entity('legalEntityGeoprocessing').property('cityId'), string().value(id)),
            filter?.type === 'size' && filter?.value
              ? equals(
                  entity('legalEntity').property(`${filter?.type}`),
                  string().value(`${filter?.value!}`),
                )
              : null,

            filter?.type === 'attendance' && filter?.value
              ? notNull(entity('legalEntityAttendance').property('legalEntityId'))
              : null,

            filter?.type === 'attendance' && filter?.value && filter?.attendanceSource
              ? equals(
                  entity('legalEntityAttendanceDataSource').property(`dataSourceId`),
                  number().value(Number(filter?.attendanceSource)),
                )
              : null,

            filter?.type === 'attendance' &&
            filter?.value &&
            filter?.attendanceSource &&
            filterStartDate &&
            filterFinalDate
              ? between(
                  entity('legalEntityAttendance').property('startDate'),
                  string().value(String(filterStartDate)),
                  string().value(String(filterFinalDate)),
                )
              : null,

            filter?.type === 'cnaes' && filter?.value
              ? likeAny(
                  entity('legalEntity').property('cnaes'),
                  filter?.value
                    .toString()
                    .split(',')
                    .map(cnae_id => string().value(`%,${cnae_id!}%`)),
                  { sensitive: true },
                )
              : null,
            filter?.type === 'legalNature' && filter?.value
              ? equals(
                  entity('legalEntity').property('legalNatureId'),
                  string().value(`${filter?.value!}`),
                )
              : null,
            optionsBooleanValue.includes(`${filter?.type}`)
              ? equals(entity('legalEntity').property(`${filter?.type}`), boolean().value(true))
              : null,
          ].filter(truthy),
        )
        .limit(1)
  }
}
