import filesize from 'filesize'
import React from 'react'
import useMemory from '~/prix/react/hooks/memory'

export default function MemoryInformation() {
  const { memory, memoryError } = useMemory()
  return (
    <>
      <thead>
        <tr>
          <th colSpan={2}>Memória</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Tamanho limite (JS heap)</td>
          {memoryError ? (
            <td>{!memory ? 'Não suportado' : filesize(memory.jsHeapSizeLimit)}</td>
          ) : (
            <td>{!memory ? 'Carregando...' : filesize(memory.jsHeapSizeLimit)}</td>
          )}
        </tr>
        <tr>
          <td>Tamanho total (JS heap)</td>
          {memoryError ? (
            <td>{!memory ? 'Não suportado' : filesize(memory.totalJSHeapSize)}</td>
          ) : (
            <td>{!memory ? 'Carregando...' : filesize(memory.totalJSHeapSize)}</td>
          )}
        </tr>
        <tr>
          <td>Memória usada (JS heap)</td>
          {memoryError ? (
            <td>{!memory ? 'Não suportado' : filesize(memory.usedJSHeapSize)}</td>
          ) : (
            <td>{!memory ? 'Carregando...' : filesize(memory.usedJSHeapSize)}</td>
          )}
        </tr>
      </tbody>
    </>
  )
}
