import { array, defineAction, number, object, string } from '~/prix'
import fetchLatestCityCensus from './fetchLatestCityCensus.action.json' assert { type: 'json' }

const fetchLatestCityCensusDefinition = defineAction({
  ...fetchLatestCityCensus,
  input: object({
    cityId: number().label('ID da cidade.'),
  }),
  output: object({
    mostRecentCensus: array(
      object({
        key: string().label('Coluna descritiva do dado'),
        value: string().label('Valor do dado.'),
        year: string().label('Ano em que o dado foi coletado'),
        description: string().label('Descrição do Dado'),
      }),
    ),
  }),
})

export default fetchLatestCityCensusDefinition
