import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: 'flex';
  flex: 1;

  img {
    width: 100%;
    height: 165px;
    object-fit: cover;
  }

  .text {
    padding: 14px 10px 10px 10px;
  }

  .bottom {
    display: flex;
    justify-content: center;
    padding: 5px 0px 10px 0px;

    a {
      color: #005EB8;

      &:hover {
        color: #0F438A;
      }
    }
  }
`

export default function DashboardGridItemSubnormalClusters() {
  return (
    <Wrapper>
      <img
        src="https://images.unsplash.com/photo-1551717256-ad2ac9ab0261?blend=000000&blend-alpha=10&blend-mode=normal&blend-w=1&crop=faces%2Cedges&h=630&mark=https%3A%2F%2Fimages.unsplash.com%2Fopengraph%2Flogo.png&mark-align=top%2Cleft&mark-pad=50&mark-w=64&w=1200&auto=format&fit=crop&q=60&ixid=M3wxMjA3fDB8MXxhbGx8fHx8fHx8fHwxNzEwNTM2Mzk0fA&ixlib=rb-4.0.3"
        alt="Imagem Exemplo"
      />
      <div className='text'>
        <span>
          Um estudo sobre alguns aglomerados subnormais do Brasil, seus estabelecimentos e atendimentos pelo SEBRAE.
        </span>
      </div>
      <div className='bottom'>
        <a href={'app/report/subnormal-clusters'}>Ver mais</a>
      </div>
    </Wrapper>
  )
}