import { createContext, useContext, useMemo } from 'react'
import { Sources } from '../../sources/source'
import { Entities } from '../../entity'
import { Context } from '../../context'
import errors from '../../error'

export interface API {
  sources: Sources
  entities: Entities
  context: Context
  isLoading?: boolean
}

const apiContext = createContext<API | null>(null)

export const APIProvider = apiContext.Provider

export default function useAPI(): API {
  const currentApiContext = useContext(apiContext)
  if (!currentApiContext) {
    throw errors.incompatible('API não pode ser usada sem que esteja em contexto')
  }
  return currentApiContext
}
