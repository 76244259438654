import { defineAction, object } from '~/prix'
import fetchCourseProductAction from './fetchCourseProduct.action.json'

const fetchCourseProductDefinition = defineAction({
  ...fetchCourseProductAction,
  input: object({}),
  output: object({}),
})

export default fetchCourseProductDefinition
