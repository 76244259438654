import React, { memo } from 'react'
import useMedia from 'react-use/lib/useMedia'
import styled from 'styled-components'
import { formatAsBrNumber } from '~/prix'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 15px;

  .header-container {
    display: flex;
    height: 50px;
    flex-direction: column;
    margin-bottom: 10px;

    @media (max-width: 1550px) {
      margin-bottom: 15px;
    }

    @media (max-width: 530px) {
      height: 65px;
      margin-bottom: 10px;
    }

    .title {
      color: #0f438a;
      font-size: 18px;
      font-weight: 600;
      padding-bottom: 5px;
    }

    .subtitle {
      font-size: 14px;
      color: #0f438a;
      font-weight: 500;
      line-height: 1.35;
    }
  }

  .table-container {
    overflow-x: auto;
    border-radius: 4px;
    margin-top: 4px;

    table {
      width: 100%;
      border-collapse: collapse;
    }

    th,
    td {
      border: 1px solid #c4c4c4;
      padding: 8px;
    }

    th {
      background-color: rgba(0, 94, 184, 0.05);
    }

    th:nth-child(1) {
      border: 1px solid #c4c4c4;
      text-align: left;
      background-color: rgba(0, 94, 184, 0.05);
    }

    td {
      text-align: center;
      background-color: #fff;
    }

    td:nth-child(1) {
      text-align: left;
      font-weight: bold;
      background-color: rgba(0, 94, 184, 0.05);
      max-width: 175px;
      min-width: 125px;
    }

    th:nth-child(2) {
      text-align: center;
      max-width: 100px;
    }

    th:nth-child(3) {
      text-align: center;
      max-width: 150px;
    }

    th:nth-child(4) {
      text-align: center;
      max-width: 150px;
    }

    td:nth-child(4) {
      text-align: left;
      max-width: 150px;
      min-width: 125px;
    }

    th:nth-child(5) {
      text-align: center;
      max-width: 150px;
    }
  }

  .table-mobile-container {
    width: 100%;
    overflow-x: auto;
    margin-top: 10px;

    table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 20px;
    }

    th,
    td {
      padding: 8px;
      text-align: center;
      border: 1px solid #c4c4c4;

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }

    tr:nth-child(1) {
      background-color: rgba(0, 94, 184, 0.05);
    }

    td:nth-child(1) {
      background-color: rgba(0, 94, 184, 0.05);
      font-weight: bold;
      max-width: 75px;
    }

    td:nth-child(2) {
      max-width: 50px;
    }
  }
`

interface LegalEntityGeoprocessingMapDemarcationReportChartProps {
  subtitle?: string
  values?: any
}

function legalEntityGeoprocessingMapDemarcationReportTable({
  values,
}: LegalEntityGeoprocessingMapDemarcationReportChartProps) {
  const population = values[0].populationCount
  const transferredResources = values[0].transferredResources
  const yearReference = values[0].year

  const auxilioBrasil = values[0].auxilioBrasil
  const auxilioEmergencial = values[0].auxilioEmergencial
  const bolsaFamilia = values[0].bolsaFamilia
  const novoBolsaFamilia = values[0].novoBolsaFamilia
  const bpc = values[0].bpc
  const peti = values[0].peti
  const safra = values[0].safra
  const seguroDefeso = values[0].seguroDefeso
  const totalBenefits = values[0].totalBenefits

  const isMobile = useMedia('(max-width: 670px)')

  const createTableRow = (
    label: string,
    item: {
      count: number
      percentage: { toFixed: (arg0: number) => number }
      total: number
      average: number
    },
  ) => (
    <tr key={label}>
      <td>{label}</td>
      <td>{formatAsBrNumber(item.count)}</td>
      <td>{formatAsBrNumber(item.percentage.toFixed(2))}%</td>
      <td>R$ {formatAsBrNumber(item.total)}</td>
      <td>R$ {formatAsBrNumber(item.average)}</td>
    </tr>
  )

  const MobileTable = ({
    title,
    data,
  }: {
    title: string
    data: {
      count: number
      percentage: { toFixed: (arg0: number) => number }
      total: number
      average: number
    }
  }) => {
    return (
      <table>
        <tr>
          <th colSpan={2}>{title}</th>
        </tr>
        <tr>
          <td>Quantidade de beneficiários</td>
          <td>{formatAsBrNumber(data.count)}</td>
        </tr>
        <tr>
          <td>Percentual de beneficiários</td>
          <td>{formatAsBrNumber(data.percentage.toFixed(2))}%</td>
        </tr>
        <tr>
          <td>Totalizadores</td>
          <td>R$ {formatAsBrNumber(data.total)}</td>
        </tr>
        <tr>
          <td>Média de transferências por beneficiários</td>
          <td>R$ {formatAsBrNumber(data.average)}</td>
        </tr>
      </table>
    )
  }

  return (
    <Wrapper>
      <div className='header-container'>
        <span className='title'>Portal da transparência</span>
        <span className='subtitle'>
          A partir do ano base {yearReference}, a população reportada foi{' '}
          {formatAsBrNumber(population)}
          {transferredResources &&
            ` e os recursos transferidos foram R$ ${formatAsBrNumber(transferredResources)}`}
          .
        </span>
      </div>

      {!isMobile ? (
        <div className='table-container'>
          <table>
            <thead>
              <tr>
                <th>Programa</th>
                <th>Quantidade de beneficiários</th>
                <th>Percentual de beneficiários</th>
                <th>Totalizadores</th>
                <th>Média de transferências por beneficiários</th>
              </tr>
            </thead>
            <tbody>
              {createTableRow('Auxílio Brasil', auxilioBrasil)}
              {createTableRow('Auxílio Emergencial', auxilioEmergencial)}
              {createTableRow('Benefício de Prestação Continuada (BPC)', bpc)}
              {createTableRow('Bolsa Família', bolsaFamilia)}
              {createTableRow('Novo Bolsa Família', novoBolsaFamilia)}
              {createTableRow('Programa de Erradicação do Trabalho Infantil', peti)}
              {createTableRow('Benefício Garantia-Safra', safra)}
              {createTableRow('Seguro Defeso', seguroDefeso)}
              {createTableRow('Total de benefícios', totalBenefits)}
            </tbody>
          </table>
        </div>
      ) : (
        <div className='table-mobile-container'>
          <MobileTable title='Auxílio Brasil' data={auxilioBrasil} />
          <MobileTable title='Auxílio Emergencial' data={auxilioEmergencial} />
          <MobileTable title='Benefício de Prestação Continuada (BPC)' data={bpc} />
          <MobileTable title='Bolsa Família' data={bolsaFamilia} />
          <MobileTable title='Novo Bolsa Família' data={novoBolsaFamilia} />
          <MobileTable title='Programa de Erradicação do Trabalho Infantil' data={peti} />
          <MobileTable title='Benefício Garantia-Safra' data={safra} />
          <MobileTable title='Seguro Defeso' data={seguroDefeso} />
          <MobileTable title='Total de benefícios' data={totalBenefits} />
        </div>
      )}
    </Wrapper>
  )
}

export default memo(legalEntityGeoprocessingMapDemarcationReportTable)
