import theme from '~/design'

export type AlertLevel =
  | 'notification'
  | 'outbreak'
  | 'urgent'
  | 'emergent'
  | 'above'
  | 'below'
  | 'informative'
  | null

export const alertLabels = {
  notification: 'Notificação',
  outbreak: 'Surto',
  urgent: 'Urgente',
  emergent: 'Emergente',
  above: 'Acima',
  below: 'Abaixo',
  informative: 'Informativo',
}

export const alertColors = {
  notification: '#000',
  outbreak: '#DB340B',
  urgent: '#e67e22',
  emergent: '#f1c40f',
  above: '#FFB31A',
  below: '#1abc9c',
  informative: theme.colors.primary,
}

export type TimeSeriesDataProps = {
  key: string
  title: string
  label: string
  alertLevel: AlertLevel
}

export const timeSeriesInfo = {
  legalEntityAttendanceAppSebrae: {
    label: 'Atendimentos',
    title: 'SEBRAE Na Sua Empresa (App)',
    order: 1,
  },
  legalEntityAttendanceEad: {
    label: 'Atendimentos',
    title: 'Todos os cursos - EaD',
    order: 2,
  },
  legalEntityEadPortfolio: {
    label: 'Atendimentos',
    title: 'Cursos do portfólio - EaD',
    order: 3,
  },
  legalEntityAttendanceRadarAli: {
    label: 'Atendimentos',
    title: 'Radar ALI',
    order: 4,
  },
  legalEntityOpeningDate: {
    label: 'Novos CNPJs',
    title: 'Abertura de empresas',
    order: 5,
  },
  legalEntityClosingDate: {
    label: 'CNPJs fechados',
    title: 'Fechamento de empresas',
    order: 6,
  },
} as const
