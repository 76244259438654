import { defineAction, object } from '~/prix'
import seedCityCensus from './seedCityCensus.action.json' assert { type: 'json' }

const seedCityCensusDefinition = defineAction({
  ...seedCityCensus,
  input: object({}),
  output: object({}),
})

export default seedCityCensusDefinition
