import React from 'react'
import styled from 'styled-components'
import AppLayout from '~/components/appLayout'
import VirtualList from '~/prix/react/components/list/virtualList'
import useCreateDataFetcherFromAction from '~/prix/react/hooks/createDataFetcherFromAction'
import legalEntityReadDefinition from './legalEntityRead.definition'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export default function LegalEntityReadScreen() {
  // const dataFetcher = useCreateDataFetcherFromAction(legalEntityReadDefinition)

  return (
    <AppLayout title='Lista de empresas' dockActive='person'>
      Lista de empresas
      <Wrapper>
        {/* <h2>Topo</h2>
        <VirtualList
          renderRow={(item, index) => item?.corporateName}
          dataFetcher={dataFetcher}
        />
        <h2>Rodapé</h2> */}
      </Wrapper>
    </AppLayout>
  )
}
