import { defineEntity, object, string, dateTime, number, boolean } from '~/prix'

const individualEntity = defineEntity({
  key: 'individual',
  title: 'Pessoa física',
  sources: ['olap', 'oltp'],
  schema: object({
    id: number().label('ID', 'Identificador', 'Código'),
    cpf: string().label('CPF'),
    name: string().label('Nome'),
    phoneNumber: string().label('Telefone'),
    isValidCpf: boolean().label('Indica se o CPF é válido'),
    gender: string().label('Genero'),
    birthDate: dateTime().label('Data de nascimento'),
    schooling: string().label('Escolaridade'),
    createdAt: dateTime().label('Data de inclusão'),
    updatedAt: dateTime().label('Data de atualização'),
  }),
})

export default individualEntity
