import { count, entity, equals, query, descending, string, truthy, between } from '~/prix'

export default function estabelecimentoQuery({
  stateAbbreviation,
  startString,
  endString,
}: {
  stateAbbreviation?: string
  startString?: string
  endString?: string
}) {
  return query('estabelecimento', { schema: 'rfb' })
    .select({
      mainCnae: entity('estabelecimento').property('cnaePrincipal'),
      description: entity('cnae').property('description'),
      count: count(entity('estabelecimento').property('cnaePrincipal')),
    })
    .join({
      current: entity('estabelecimento').property('cnaePrincipal'),
      target: entity('cnae').property('id'),
      join: 'inner',
    })
    .where(
      ...[
        equals(entity('estabelecimento').property('situacaoCadastral'), string().value('02')),
        stateAbbreviation && stateAbbreviation !== undefined
          ? equals(entity('estabelecimento').property('uf'), string().value(stateAbbreviation))
          : null,
        startString && endString
          ? between(
              entity('estabelecimento').property('dataInicioAtividade'),
              string().value(startString),
              string().value(endString),
            )
          : null,
      ].filter(truthy),
    )
    .order(descending('count'))
    .limit(10)
}
