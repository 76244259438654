/**
 * Checks if a value is null or undefined
 *
 * @param value The value to check
 * @returns True if the value is null or undefined, false otherwise
 * @example
 * isNullOrUndefined(null) // true
 * isNullOrUndefined(undefined) // true
 * isNullOrUndefined('') // false
 * isNullOrUndefined([]) // false
 */
export function isNullOrUndefined(value: any) {
  return value === null || typeof value === 'undefined'
}

/**
 * Checks if a value is empty, i.e. null, undefined, an empty string, a empty string with trailing, an empty array or an empty object
 *
 * @param value The value to check
 * @returns True if the value is empty, false otherwise
 *
 * @example
 * isEmpty(null) // true
 * isEmpty(undefined) // true
 * isEmpty('') // true
 * isEmpty([]) // true
 * isEmpty({}) // true
 * isEmpty(' ') // true
 * isEmpty(0) // false
 */
export function isEmpty(value: any) {
  if (isNullOrUndefined(value)) {
    return true
  }
  if (typeof value === 'string' && value.trim() === '') {
    return true
  }
  if (Array.isArray(value) && value.length === 0) {
    return true
  }
  if (typeof value === 'object' && Object.keys(value).length === 0) {
    return true
  }
  return false
}

/**
 * Retorna o valor inserido quando houver ou nulo quando estiver vazio.
 * @param values O valor que se deseja fazer o coalesce.
 * @returns O valor quando houver, ou null quando estiver vazio.
 *
 * @example
 * coalesceWhenEmpty("Something") //Something
 * coalesceWhenEmpty('') //null
 * coalesceWhenEmpty([]) //null
 * coalesceWhenEmpty(' ') //null
 * coalesceWhenEmpty(null) //null
 * coalesceWhenEmpty(undefined) //null
 * coalesceWhenEmpty(0) //0
 */
export function coalesceWhenEmpty<T>(...values: T[]): T | null {
  const findResult = values.find(value => !isEmpty(value))
  if (typeof findResult !== 'undefined') {
    return findResult
  }
  return null
}
