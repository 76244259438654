import { boolean, defineAction, number, object, string } from '~/prix'
import fetchAttendanceProximityAction from './fetchAttendanceProximity.action.json'

const fetchAttendanceProximityDefinition = defineAction({
  ...fetchAttendanceProximityAction,
  input: object({
    legalEntityCnpj: string(),
    agentGeolocation: object({
      latitude: number(),
      longitude: number(),
      accuracy: number().isOptional(),
    }),
    legalEntityAddress: object({
      publicPlace: string().isOptional(),
      complement: string().isOptional(),
      neighborhood: string().isOptional(),
      number: string().isOptional(),
      municipalityIbgeCode: string().isOptional(),
      stateAbbreviation: string().isOptional(),
      postalCode: string().isOptional(),
    }),
  }),
  output: object({
    isInsideMunicipality: boolean(),
    nearToStreet: boolean(),
    streetDistance: number(),
    nearToLegalEntity: boolean(),
    legalEntityDistance: number(),
    inLocoProbability: string(),
    inLoco: boolean(),
  }),
})

export default fetchAttendanceProximityDefinition
