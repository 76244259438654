import * as turf from '@turf/helpers'
import { useMemo } from 'react'
import { PointFeatureWithRadius } from '~/prix/react/components/map/radiusEditor'
import useAction from '~/prix/react/hooks/action'
import mapDemarcationReportDefinition from './mapDemarcationReport.definition'
import { MultiRegionsFeatureCollection } from '../legalEntityGeoprocessingMapDemarcationRegion.screen'
import { DataTransparencyProps } from '../report/pdf/legalEntityGeoprocessingMapDemarcationReportListPdf.component'
import { ChartDataProps } from '../report/legalEntityGeoprocessingMapDemarcationReportChart.component'

interface CensusProps {
  key: string
  value: number
  year: number
  description: string
}

interface ResultProps {
  areaInfo: {
    areaMeters: number
    warnings: Array<{ message: string }>
  }
  attendances: Array<{ count: number }>
  averageCost: Array<{
    legalEntityCount: number
    value: number
  }>
  citiesCensus: Array<{
    name: string
    intersectionArea: number | null
    data: CensusProps[]
  }>
  citiesGovernmentTransparency: Array<{
    name: string
    intersectionArea: number | null
    data: DataTransparencyProps
  }>
  cityCagedData: Array<{
    name: string
    intersectionArea: number | null
    data: ChartDataProps[]
  }>
  cnae: Array<{
    description: string
    count: number
  }>
  legalEntityCount: Array<{ count: number }>
  legalNature: Array<{
    description: string
    count: number
  }>
  mei: Array<{ count: string }>
  revenue: Array<{
    legalEntityCount: number
    value: number
  }>
  scores: Array<{
    legalEntityCount: number
    scoreIndicatorsManagement: string | null
    scoreInnovation: string | null
    scoreMarketing: string | null
    scoreOperationsManagement: string | null
    scoreSustainablePractices: string | null
    scoreTransformation: string | null
  }>
  sector: Array<{
    type: string
    count: number
  }>
  segment: Array<{
    description: string
    count: number
  }>
  size: Array<{
    size: string
    count: number
  }>
}

export type DemarcationFeature =
  | turf.Feature<turf.Polygon>
  | PointFeatureWithRadius
  | MultiRegionsFeatureCollection

export interface FeatureProps {
  type?: string
  geometry?: {
    type: string
    coordinates: Array<number>
  }
  properties?: {
    level?: string
    id?: number
    name?: string
  }
}

export interface MapDemarcationReportProps {
  feature: {
    bbox: number[]
    features: FeatureProps[]
    geometry?: {
      type?: string
    }
    properties?: {
      radius?: DemarcationFeature
    }
    isEnabled?: boolean
  }
  isLoading?: boolean
  isEnabled?: boolean
}

export default function useMapDemarcationReport({ feature, isEnabled }: MapDemarcationReportProps) {
  const input = useMemo(() => {
    if (!feature) {
      return {
        point: null,
        radius: null,
        boundary: null,
        geoItems: null,
      }
    }
    return {
      point:
        feature.geometry?.type === 'Point' ? (feature.geometry as PointFeatureWithRadius) : null,
      radius:
        feature.geometry?.type === 'Point'
          ? (feature.properties?.radius as PointFeatureWithRadius)
          : null,
      boundary:
        feature.geometry?.type === 'Polygon'
          ? (feature.geometry as turf.Feature<turf.Polygon>)
          : null,
      geoItems: feature.features
        ? (feature as turf.FeatureCollection<turf.Point>).features.map(
            feature => feature.properties,
          )
        : null,
    }
  }, [feature])
  const result = useAction(mapDemarcationReportDefinition, input, [input, isEnabled], {
    autoLoad: isEnabled,
  })

  return {
    values: result.result as unknown as ResultProps,
    isLoading: result.isLoading,
  }
}
