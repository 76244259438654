import { entity, equals, number, query, string } from '~/prix'
import asGeoJson from '~/prix/functions/asGeoJson'
import { multiPolygon } from '~/prix/types/geoJson'

export default function streetsQuery(by: 'neighborhoodId' | 'streetId', id: string) {
  switch (by) {
    case 'neighborhoodId':
      return query('street')
        .select({
          id: entity('street').property('id'),
          name: entity('street').property('name'),
          center: asGeoJson(entity('street').property('center')),
          collection: asGeoJson(entity('street').property('collection')),
        })
        .join({
          current: entity('street').property('id'),
          target: entity('neighborhoodStreetIntersection').property('streetId'),
        })
        .where(
          equals(
            entity('neighborhoodStreetIntersection').property('neighborhoodId'),
            number().value(Number(id!)),
          ),
        )
        .limit(10000)

    case 'streetId':
      return query('street')
        .select({
          id: entity('street').property('id'),
          name: entity('street').property('name'),
          wayId: entity('way').property('id'),
          path: asGeoJson(entity('way').property('path')),
          center: asGeoJson(entity('street').property('center')),
          boundary: multiPolygon().value(null as any),
          collection: asGeoJson(entity('street').property('collection')),
          stateId: entity('state').property('id'),
        })
        .join({
          current: entity('street').property('id'),
          target: entity('way').property('streetId'),
        })
        .join({
          current: entity('street').property('cityId'),
          target: entity('city').property('id'),
        })
        .join({
          current: entity('city').property('microRegionId'),
          target: entity('microRegion').property('id'),
        })
        .join({
          current: entity('microRegion').property('mesoRegionId'),
          target: entity('mesoRegion').property('id'),
        })
        .join({
          current: entity('mesoRegion').property('stateId'),
          target: entity('state').property('id'),
        })
        .where(equals(entity('street').property('id'), string().value(id)))
        .limit(10000)
    default:
      throw new Error(`Unknown by: ${by} on streets query`)
  }
}
