import * as d3Shape from 'd3-shape'
import { debounce } from 'debounce'
import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'
import useMedia from 'react-use/lib/useMedia'
import styled, { useTheme } from 'styled-components'
import mapRange from '~/prix/utils/math/mapRange'
import segmentArrayBySeparator from '~/prix/utils/segmentArray'
import { notNullNaNOrUndefined, truthy } from '~/prix/utils/types/boolean'
import { formatAsBrNumber } from '~/prix/utils/types/number'
import Tooltip from '../tooltip'
import DefaultBottomAxis, { BottomAxisProps } from './bottomAxis'
import { endOfMonth, endOfWeek, format, parse, startOfMonth, startOfWeek } from 'date-fns'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  position: relative;

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }

  .tooltip {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    padding: 14px;
    border-radius: 14px;
  }
`

export interface LineChartItem {
  date?: string
  values: (number | null)[]
}

export interface Series {
  label?: string | Element | undefined
  marker?: {
    color?: string
    radius?: number
  } | null
  line?: {
    color?: string
    width?: number
    curve?:
      | 'curveLinear'
      | 'curveNatural'
      | 'curveMonotoneX'
      | 'curveCatmullRom'
      | 'curveMonotoneY'
      | 'curveStep'
      | 'curveStepAfter'
      | 'curveStepBefore'
    dashed?: boolean
  } | null
  max?: number
  min?: number
  format?: (value: number) => string
}

export interface LineChartProps {
  items: LineChartItem[]
  height?: number
  // Fixed width
  itemWidth?: number
  // Min width to be flexible
  minItemWidth?: number
  series?: Series[]
  bottomSpacingForFixedAxis?: number
  topSpacingForFixedAxis?: number
  BottomAxis?: React.FC<BottomAxisProps>
  periodicity?: string | undefined | null
  lastDayOfData?: Date | undefined
}

function estimateMinItemWidth(characters: number, fontSize: number = 12, margin: number = 10) {
  const estimatedCharacterWidth = fontSize * 0.59
  return estimatedCharacterWidth * characters + margin
}

export default function LineChart({
  items,
  height = 170,
  BottomAxis = DefaultBottomAxis,
  minItemWidth = estimateMinItemWidth(items.length.toString().length),
  periodicity,
  lastDayOfData,
  ...props
}: LineChartProps) {
  const theme = useTheme()
  const ref = useRef<HTMLDivElement>(null)
  const isTouchable = useMedia('(any-pointer: coarse)')
  const [tooltip, setTooltip] = useState<number | null>(null)
  const [itemWidth, setItemWidth] = useState(props.itemWidth || 0)
  const [sequence, setSequence] = useState<number[]>(() =>
    props.itemWidth ? items.map((_, index) => index) : [],
  )

  useMemo(() => {
    let hasMismatch = false
    for (const index of sequence) {
      if (!items[index]) {
        hasMismatch = true
      }
    }
    if (hasMismatch) {
      console.warn('Items and sequence mismatch, errors might occur.')
    }
  }, [sequence, items])

  const series = useMemo(
    () =>
      (props.series || (items[0].values.map(() => ({})) as Series[])).map((current, index) => {
        const max = Math.max(...items.map(item => item.values[index]).filter(notNullNaNOrUndefined))
        const min = Math.min(
          0,
          ...items.map(item => item.values[index]).filter(notNullNaNOrUndefined),
        )

        return {
          ...current,
          marker:
            current.marker !== null
              ? {
                  color: current.marker?.color || theme.colors.primary,
                  radius: current.marker?.radius || 4,
                }
              : null,
          line:
            current.line !== null
              ? {
                  color: current.line?.color || theme.colors.primary,
                  width: current.line?.width || 2,
                  curve: current.line?.curve || 'curveCatmullRom',
                  dashed: current.line?.dashed || false,
                }
              : null,
          max: current.max ?? max,
          min: current.min ?? min,
          format: current.format ?? ((value: number) => formatAsBrNumber(Math.round(value), 0)),
        }
      }),
    [items, props.series, theme.colors.primary],
  )
  const max = useMemo(() => Math.max(...series.map(s => s.max)), [series])
  const min = useMemo(() => Math.min(...series.map(s => s.min)), [series])
  const spacings = {
    bottom: 5,
    top: 10,
    bottomForFixedAxis: props.bottomSpacingForFixedAxis ?? 30,
    topForFixedAxis: props.topSpacingForFixedAxis ?? 0,
  }
  const heightWithoutSpacings =
    height - spacings.top - spacings.bottom - spacings.topForFixedAxis - spacings.bottomForFixedAxis

  const sampleItems = useMemo(() => sequence.map(index => items[index]), [items, sequence])
  const paths = useMemo(
    () =>
      series.map((current, index) => {
        const line = current.line
        if (!line) {
          return null
        }
        const generateCurve = d3Shape.line().curve(d3Shape[line.curve])
        const values = items.map(item => item.values[index])
        const segments = segmentArrayBySeparator(values, value => !notNullNaNOrUndefined(value))
        return segments
          .map(segment => {
            const coordinates = segment
              .map((value, index) => {
                if (!notNullNaNOrUndefined(value)) {
                  return null
                }

                const ratio =
                  sampleItems.length !== items.length ? (sampleItems.length - 1) / items.length : 1
                const heightPercentage = 1 - (value - min) / (max - min)
                const x = index * ratio * itemWidth + itemWidth / 2
                const y =
                  heightPercentage * heightWithoutSpacings + spacings.top + spacings.topForFixedAxis
                return [x, y] as [number, number]
              })
              .filter(truthy)
            return generateCurve(coordinates)
          })
          .filter(truthy)
      }),
    [
      series,
      items,
      sampleItems,
      itemWidth,
      max,
      min,
      heightWithoutSpacings,
      spacings.top,
      spacings.topForFixedAxis,
    ],
  )

  useLayoutEffect(() => {
    const wrapperRef = ref.current as HTMLDivElement | undefined
    if (props.itemWidth) {
      setItemWidth(props.itemWidth)
      setSequence(items.map((_, index) => index))
      return
    }
    if (!wrapperRef) {
      return
    }

    const handleUpdate = () => {
      const currentWidth = wrapperRef.offsetWidth
      const maxItems = Math.min(Math.floor(currentWidth / minItemWidth), items.length)
      const itemWidth = currentWidth / maxItems
      const newSequence = Array.from({ length: maxItems }, (_, index) =>
        Math.floor(mapRange(index, 0, maxItems - 1, 0, items.length - 1)),
      )
      setSequence(newSequence)
      setItemWidth(itemWidth)
    }
    const resizeObserver = new ResizeObserver(debounce(handleUpdate, 200))
    resizeObserver.observe(wrapperRef)
    return () => {
      resizeObserver.disconnect()
    }
  }, [items.length, ref.current, props.itemWidth, minItemWidth])

  const width = sampleItems.length * itemWidth
  const lineOn0 = useMemo(() => {
    if (min === 0) {
      return null
    }
    const ratio = (0 - min) / (max - min)
    const heightPercentage = 1 - ratio
    return (
      <g>
        <line
          x1={0}
          y1={heightPercentage * heightWithoutSpacings + spacings.top + spacings.topForFixedAxis}
          x2={width}
          y2={heightPercentage * heightWithoutSpacings + spacings.top + spacings.topForFixedAxis}
          stroke={theme.colors.mediumGrey}
          strokeWidth={1}
          strokeDasharray='2 2'
        />
      </g>
    )
  }, [
    max,
    min,
    heightWithoutSpacings,
    spacings.top,
    spacings.topForFixedAxis,
    theme.colors.mediumGrey,
    width,
  ])

  const handleMouseMove = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (isTouchable) {
        return
      }

      const elementBounding = event.currentTarget.getBoundingClientRect()
      const x = event.clientX - elementBounding.left
      const index = Math.floor(x / itemWidth)
      setTooltip(index)
    },
    [itemWidth],
  )
  const handleMouseLeave = useCallback(() => {
    if (isTouchable) {
      return
    }
    setTooltip(null)
  }, [])

  const tooltipValue =
    tooltip !== null && items[sequence[tooltip]]
      ? items[sequence[tooltip]].values.filter(notNullNaNOrUndefined)[0] ?? null
      : null

  const dateRange = useMemo(() => {
    if (periodicity === undefined || periodicity === null)
      return items.map(() => ({ startDate: null, endDate: null }))

    return items.map(item => {
      const dateFormat = periodicity === 'monthly' ? 'MM/yyyy' : 'dd/MM/yyyy'
      const referenceDate = item.date ? parse(item.date, dateFormat, new Date()) : null

      if (!referenceDate) return { startDate: null, endDate: null }

      if (periodicity === 'daily') {
        return {
          startDate: item.date,
          endDate: null,
        }
      }
      if (periodicity === 'weekly') {
        return {
          startDate: format(startOfWeek(referenceDate), 'dd/MM/yy'),
          endDate: format(endOfWeek(referenceDate), 'dd/MM/yy'),
        }
      }
      if (periodicity === 'monthly') {
        return {
          startDate: format(startOfMonth(referenceDate), 'dd/MM/yy'),
          endDate: format(endOfMonth(referenceDate), 'dd/MM/yy'),
        }
      }
      if (periodicity === 'yearly') {
        return {
          startDate: `01/01/${item.date}`,
          endDate:
            lastDayOfData && item.date == format(lastDayOfData, 'yyyy')
              ? format(lastDayOfData, 'dd/MM/yy')
              : `31/12/${item.date}`,
        }
      }
    })
  }, [items, periodicity])

  return (
    <Wrapper ref={ref} style={{ height, width: props.itemWidth ? width : undefined }}>
      <div
        style={{ height, width, position: 'absolute' }}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <svg width={width} height={height}>
          <g>
            <BottomAxis
              sequence={sequence}
              height={spacings.bottomForFixedAxis}
              top={height - spacings.bottomForFixedAxis - spacings.bottom}
              itemWidth={itemWidth}
            />
          </g>

          {lineOn0}

          {paths.map((seriesPaths, index) => {
            const line = series[index].line
            if (!line || !seriesPaths) {
              return null
            }

            return (
              <g key={index}>
                {seriesPaths.map((path, index) => (
                  <path
                    key={index}
                    d={path}
                    stroke={line.color}
                    strokeWidth={line.width}
                    fill='none'
                    strokeDasharray={line.dashed ? '4 4' : undefined}
                  />
                ))}
              </g>
            )
          })}

          <g className='items'>
            {sampleItems.map((item, index) => {
              const xLeft = index * itemWidth
              const xCenter = xLeft + itemWidth / 2
              const yOnValue = (item?.values || []).map(value =>
                notNullNaNOrUndefined(value)
                  ? heightWithoutSpacings -
                    ((value - min) / (max - min)) * heightWithoutSpacings +
                    spacings.top +
                    spacings.topForFixedAxis
                  : null,
              )
              return (
                <g key={index}>
                  {yOnValue.map((y, index) =>
                    y !== null && series[index].marker ? (
                      <circle
                        key={index}
                        cx={xCenter}
                        cy={y}
                        r={series[index].marker!.radius}
                        fill={series[index].marker!.color}
                      />
                    ) : null,
                  )}
                  <rect x={xLeft} y={0} width={itemWidth} height={height} fill='transparent' />
                </g>
              )
            })}
          </g>
        </svg>

        {tooltip !== null && tooltipValue !== null ? (
          <Tooltip
            x={tooltip * itemWidth + itemWidth / 2}
            y={
              heightWithoutSpacings -
              ((tooltipValue - min) / (max - min)) * heightWithoutSpacings +
              spacings.top +
              spacings.topForFixedAxis
            }
            position='absolute'
            wrapperWidth={width}
            wrapperHeight={height}
          >
            {items[tooltip].values.map((value, index) =>
              notNullNaNOrUndefined(value) ? (
                <p key={index} style={{ display: 'flex' }}>
                  {series[index].label ? <>{series[index].label}: </> : ''}
                  {series[index].format(value)}
                </p>
              ) : null,
            )}
            {dateRange[tooltip]?.startDate ? (
              <p>
                Data de referência:{' '}
                {periodicity === 'daily'
                  ? dateRange[tooltip].startDate
                  : `${dateRange[tooltip].startDate} - ${dateRange[tooltip].endDate}`}
              </p>
            ) : null}
          </Tooltip>
        ) : null}
      </div>
    </Wrapper>
  )
}
