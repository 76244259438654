import { defineEntity, entity, foreign, object, string } from '~/prix'

const cnaeClass = defineEntity({
  key: 'cnaeClass',
  title: 'Classe de cnae',
  sources: ['olap', 'oltp'],
  schema: object({
    id: string().label('ID'),
    ibgeCode: string().label('Código IBGE'),
    description: string().label('Descrição'),
    observations: string().label('Observações'),
    cnaeGroupId: foreign(entity('cnaeGroup').property('id')),
  }),
})

export default cnaeClass
