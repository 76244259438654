import { useJsApiLoader } from '@react-google-maps/api'
import useConfig from './config'

const googleLibraries = ['places' as 'places', 'visualization' as 'visualization']

export default function useGoogleMaps() {
  const config = useConfig()
  const { loadError, isLoaded } = useJsApiLoader({
    googleMapsApiKey: config.services.googleConfig.apiKey,
    libraries: googleLibraries,
  })

  return !isLoaded && !loadError
    ? {
        isLoading: true,
        error: loadError || null,
        google: null,
      }
    : {
        isLoading: false,
        error: loadError || null,
        google: window.google,
      }
}
