import { defineEntity, number, object, string } from '~/prix'
import { multiPolygon, point } from '~/prix/types/geoJson'

const countryEntity = defineEntity({
  key: 'country',
  title: 'País',
  sources: ['olap', 'oltp'],
  schema: object({
    id: string().label('ID', 'Identificador', 'Código'),
    englishName: string().label('Nome', 'País'),
    name: string().label('Nome', 'País'),
    iso: number().label('Código ISO'),
    callingCode: number().label('Código de chamada'),
    abbreviation: number().label('Sigla', 'Abreviação', 'Código'),
    center: point().label('Centro'),
    boundary: multiPolygon().label('Polígono'),
  }),
})

export default countryEntity
