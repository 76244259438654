import { array, defineAction, number, object, string } from '~/prix'
import legalEntitySearchWithGeoAction from './legalEntitySearchWithGeo.action.json'

const legalEntitySearchWithGeoDefinition = defineAction({
  ...legalEntitySearchWithGeoAction,
  input: object({
    query: string(),
    limit: number(),
  }),
  output: object({
    warnings: array(
      object({
        message: string(),
      }),
    ).isOptional(),
    elementsFound: array(
      object({
        url: string(),
        id: number(),
        geo: string(),
        tradeName: string(),
        corporateName: string(),
        subtitle: string(),
        streetId: string(),
        searchRank: string(),
      }),
    ),
  }),
})

export default legalEntitySearchWithGeoDefinition
