export default function segmentArrayBySeparator<T>(
  array: T[],
  separatorCheck: (item: T) => boolean,
): T[][] {
  const result: T[][] = []
  let current: T[] = Array(array.length)
  for (const [index, item] of array.entries()) {
    if (separatorCheck(item)) {
      if (current.length > 0) {
        result.push(current)
      }
      current = []
    } else {
      current[index] = item
    }
  }
  if (current.length) {
    result.push(current)
  }
  return result
}
