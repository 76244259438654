import React from 'react'
import styled from 'styled-components'
import Handle from '~/prix/react/components/handle'
import useItem from '~/prix/react/hooks/item'
import { formatAsBrIntegerNumber, formatAsPercentage } from '~/prix/utils/types/number'

import partialQuery from '~/packages/dashboard/queries/partial.query'
import withCoordinatesQuery from '~/packages/dashboard/queries/withCoordinates.query'

const Items = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--p-default, #005EB8);
  box-shadow : 0px 32px 30px rgba(20, 46, 82, 0.20);
  padding: 12px;
  border-radius: 0 0 12px 12px;
  height: 100%;
  min-height: 119px;
  justify-content: center;
`

const Item = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 1025px) {
    align-items: center;
    gap: 20px;
    padding: 0 12px;
  }

  > div {
    width: 50%;
  }

  .first {
    color: #FFF;
    font-weight: 700;
    font-size: 33px;
  }

  .second {
    color: #FFF;
    font-size: 13px;
    font-weight: 400;

    @media (max-width: 1160px) {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .third {
    color: #FFF;
    font-size: 13px;
    font-weight: 400;
    margin: 0;
  }
`

export default function DashboardGridItemSystem({
  userGeoStateAbbreviation,
  dataSourceId,
}: {
  userGeoStateAbbreviation?: string | null
  dataSourceId?: number
}) {
  const partial = useItem(
    () => partialQuery(userGeoStateAbbreviation ? userGeoStateAbbreviation : undefined),
    [userGeoStateAbbreviation],
    { cache: 60 * 60 * 24 },
  )

  const withCoordinates = useItem(
    () => withCoordinatesQuery(userGeoStateAbbreviation ? userGeoStateAbbreviation : undefined),
    [userGeoStateAbbreviation],
    { cache: 60 * 60 * 24 },
  )

  return (
    <Handle
      isLoading={partial.isLoading}
      error={partial.error}
      style={{ minHeight: '100px' }}
    >
      <Items>
        <Item>
          <div>
            <span className='first'>
              {formatAsPercentage(partial.item?.count! / partial.item?.total!)}%
            </span>
          </div>
          <div>
            <span className='second'>
              Endereços parciais
            </span>
            <p className="third"> {formatAsBrIntegerNumber(partial.item?.count!)}
            </p>
          </div>
        </Item>
        <Item>
          <div>
            <span className='first'>
              {formatAsPercentage(withCoordinates.item?.count! / withCoordinates.item?.total!)}%
            </span>
          </div>
          <div>
            <span className='second'>
              Endereços com coordenadas
            </span>
            <p className="third">
              {formatAsBrIntegerNumber(withCoordinates.item?.count!)}
            </p>
          </div>
        </Item>
      </Items>
    </Handle>
  )
}
