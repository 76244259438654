import type { DefaultTheme } from 'styled-components'
import chroma from 'chroma-js'

export const menuWidth = 220
export const mobileMaxWidth = 800

export const getContentWidth = ({ windowWidth }: { windowWidth: number }) =>
  windowWidth >= mobileMaxWidth ? windowWidth - menuWidth : windowWidth

export const gradients = {
  nausPurple: ['#9e7ae6', '#5f27cd'],
}

export const colors = {
  // Palette
  gray: '#ECF0F1',
  darkGray: '#777',
  wetAsphalt: '#486280',
  nausPurple: '#5F27CD',
  bluebell: '#341F97',
  oceanBlue: '#0F438A',
  sebraeBlue: '#005EB8',
  lightBlue: '#ABD6FF',
  midNightBlue: '#341F97',
  headerBlue: '#0f438a',
}

export const complementarColors = {
  // Other colors
  amethyst: '#9b59b6',
  emerald: '#2ecc71',
  turquoise: '#1abc9c',
  alizarin: '#e74c3c',
  orange: '#f39c12',
  nephritis: '#27ae60',
  pomegranate: '#c0392b',
  yellow: '#FFD400',
  deviantPurple: '#730CE5',
  sonderPurple: '#BD8DF3',
  backgroundWhite: '#F3F3F3',
  rosePearl: '#F14668',
}

export const institutionalColors = {
  blueSebrae10: '#0A1729',
  blueSebrae20: '#142E52',
  blueSebrae30: '#0F438A',
  blueSebrae36: '#005EB8',
  blueSebrae60: '#5C8FD6',
  blueSebrae75: '#8FB8EF',
  blueSebrae90: '#394D60',
  blueSebrae97: '#F0F6F3',
  graySebrae10: '#121A21',
  graySebrae20: '#243342',
  graySebrae30: '#394D60',
  graySebrae45: '#617385',
  graySebrae60: '#8B99A7',
  graySebrae75: '#B6BFC8',
  graySebrae90: '#E0E5EB',
  graySebrae97: '#F5F7FA',
}

export const organizationalColors = {
  aquaBlue10: '#05282E',
  aquaBlue20: '#094F5D',
  aquaBlue30: '#0E778D',
  aquaBlue44: '#14ADCC',
  aquaBlue60: '#58C4DA',
  aquaBlue75: '#96DBEE9',
  aquaBlue90: '#D5F1F6',
}

export const primaryColors = {
  darkest: institutionalColors.blueSebrae10,
  action: institutionalColors.blueSebrae30,
  default: institutionalColors.blueSebrae36,
  active: institutionalColors.blueSebrae60,
  lightest: institutionalColors.blueSebrae97,
}

export const contrastGradient = ['#AEE8FF', '#3988C7', '#0F438A']
export const contrastGradientColor = chroma.scale(contrastGradient).mode('lrgb')

const theme: DefaultTheme = {
  colors: {
    primary: colors.sebraeBlue,
    secondary: colors.oceanBlue,
    inputBorder: '#2684ff',
    lightGrey: '#ecf0f1',
    mediumGrey: '#ccc',
    darkGrey: '#414141',
    success: '#2ecc71',
    danger: '#e74c3c',
    darkText: '#0e1c1a',
    lightText: '#fff',
  },
  borderRadius: '4px',
  shadow: level => `0 1px ${level * 4}px rgba(35, 35, 35, ${0.1 + level * 0.05})`,
  padding: level => `${level}rem`,
}
export default theme
