import type { InferNative, NativeBaseOptional, Type, AbstractType } from '../types/type'
import type { AbstractValueFunction } from './function'
import { PropertyStringBase } from '../property'
import { StringType } from '~/prix'

export interface UnaccentFunction<NativeGeneric extends NativeBaseOptional>
  extends AbstractValueFunction<NativeGeneric> {
  name: 'unaccent'
}
/**
 * Remove acentos de uma propriedade.
 * @param content Propriedade cujo as acentuações devem ser removidas.
 * @returns Propriedade compatível com a prixAPI
 */
export default function Unaccent<
  TypeGeneric extends Type,
  NativeGeneric extends NativeBaseOptional = InferNative<TypeGeneric>,
>(
  content: NativeGeneric | ((StringType | AbstractType<string>) & { value: string | null }),
): PropertyStringBase {
  return {
    kind: 'function',
    name: 'unaccent',
    content,
  }
}
