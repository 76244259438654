import { defineAction, object } from '~/prix'
import individualCpfValidatorAction from './individualCpfValidator.action.json'

const individualCpfValidatorDefinition = defineAction({
  ...individualCpfValidatorAction,
  input: object({}),
  output: object({}),
})

export default individualCpfValidatorDefinition
