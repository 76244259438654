import Fingerprint2 from 'fingerprintjs2'
import { v4 as uuid } from 'uuid'
import { UAParser } from 'ua-parser-js'
// import clientKeyValueDatabase from '~/utils/client/keyValue'
// import handleError from '~/utils/shared/handleError'
import errors from '~/prix/error'
import { AppError } from '~/prix'

export interface IDevice {
  identifier: string
  brand: string | null
  // buildNumber: number | null
  // buildVersion: string | null
  // pushToken: string | null
  deviceId: string | null
  model: string | null
  name: string | null
  phoneNumber: string | null
  serialNumber: string | null
  userAgent: string | null
  isEmulator: boolean
  isApp: boolean
  deviceType: string | null
  oneSignalUserId: string | null
  os: string | null
  osVersion: string | null
  platformName: string | null
  deviceName?: string | null
  publicIP?: string | null
  locationProviders?: object | null
  browserName: string | null
  browserVersion: string | null
}

export default async function compileDeviceInfo(): Promise<IDevice> {
  let identifier = window.localStorage.getItem('identifier')
  if (!identifier) {
    try {
      identifier = await new Promise(resolve => Fingerprint2.getV18(resolve))
    } catch (error) {
      throw errors.unknown(error as AppError)
    }

    if (!identifier) {
      identifier = uuid() as string
    }
    window.localStorage.setItem('identifier', identifier)
  }

  const parser = new UAParser()
  const result = parser.getResult()

  const predictedName =
    result.device.model && result.device.vendor
      ? `${result.device.model} ${result.device.vendor}`
      : `${result.os.name || ' '} ${result.browser.name || ' '}`.trim()
  const name = predictedName
  const deviceId = predictedName
    .replace(/([^A-Za-zÀ-Úà-ú\s])/gi, '')
    .trim()
    .replace(/\s+/g, '_')
    .toLowerCase()

  // const pushToken = await clientKeyValueDatabase.get('oneSignalToken')

  // const currentMeta = meta()

  return {
    identifier,
    brand: result.device.vendor || null,
    // buildNumber: currentMeta.versionNumber,
    // buildVersion: currentMeta.version,
    deviceId,
    model: result.device.model || null,
    name: name || identifier,
    deviceName: result.device.vendor,
    phoneNumber: null,
    serialNumber: null,
    userAgent: window.navigator.userAgent,
    isEmulator: false,
    isApp: false,
    deviceType: 'browser',
    // pushToken,
    oneSignalUserId: null,
    os: result.os.name ? result.os.name.replace(' ', '').toLowerCase() : null,
    osVersion: result.os.version ? result.os.version.replace(' ', '').toLowerCase() : null,
    platformName: result.os.name || null,
    browserName: result.browser.name || null,
    browserVersion: result.browser.version || null,
  }
}
