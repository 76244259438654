import {
  array,
  defineAction,
  object,
  string,
} from '~/prix'
import updateTimeSeriesNotificationSettings from './updateTimeSeriesNotificationSettings.action.json'

const updateTimeSeriesNotificationSettingsDefinition = defineAction({
  ...updateTimeSeriesNotificationSettings,
  input: object({
    notificationLevels: object({
      sebraeApp: string(),
      allCourses: string(),
      portfolioCourses: string(),
      sse: string(),
      ali: string(),
      businessOpening: string(),
      businessClosing: string(),
    }),
    notificationAlerts: object({
      sebraeApp: array(string()),
      allCourses: array(string()),
      portfolioCourses: array(string()),
      sse: array(string()),
      ali: array(string()),
      businessOpening: array(string()),
      businessClosing: array(string()),
    }),
  }),
  output: object({}),
})

export default updateTimeSeriesNotificationSettingsDefinition
