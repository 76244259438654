import { defineEntity, object, number, foreign, entity, enumerated, dateTime } from '~/prix'

const legalEntityIndividualEntity = defineEntity({
  key: 'legalEntityIndividual',
  title: 'Pessoa física em pessoa jurídica',
  sources: ['olap', 'oltp'],
  schema: object({
    id: number().label('ID', 'Identificador', 'Código'),
    legalEntityId: foreign(entity('legalEntity').property('id')).label(
      'Identificador pessoa jurídica',
    ),
    individualId: foreign(entity('individual').property('id')).label('Identificador pessoa física'),
    relationship: enumerated({
      selfReported: 'Autorreferido',
      partner: 'Sócio',
      collaborator: 'Colaborador',
      unknown: 'Desconhecido',
    }),
    createdAt: dateTime().label('Data de inclusão'),
  }),
})

export default legalEntityIndividualEntity
