import React, { useRef, useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import { colors } from '~/design'
import GoogleMaps from '~/prix/react/components/map/googleMaps'
import { Circle, Marker } from '@react-google-maps/api'
import useGeolocation from '~/prix/react/hooks/geolocation'
import canvasDrawToURL from '~/prix/utils/canvasDrawToURL'
import useAsync from 'react-use/lib/useAsync'
import Supercluster from 'supercluster'
import { GeoJsonPoint } from '~/prix/types/geoJson'
import OriginalLink from '~/prix/react/components/link'
import useMedia from 'react-use/lib/useMedia'
import useGoogleMaps from '~/prix/react/hooks/googleMaps'
import PulseLoader from 'react-spinners/PulseLoader'
import Handle from '~/prix/react/components/handle'
import useAttendanceRadar, { LegalEntityData } from '~/packages/legalEntityGeoprocessing/radar/attendanceRadar.hook'
import { OverItems } from '~/packages/legalEntityGeoprocessing/radar/attendanceRadarMap.screen'
import { formatAsBrDecimalNumber } from '~/prix/utils/types/number'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  gap: 10px;

  .bottom {
    display: absolute;
    bottom: 0;
    margin: 0 auto;
    padding-top: 10px;

    a {
      color: #005EB8;

      &:hover {
        color: #0F438A;
      }
    }
  }
`

const MapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 300px;
  width: 100%;

  .overlay-isLoading {
    position: absolute;
    background-color: rgba(0, 94, 184, 0.4);
    backdrop-filter: blur(5px);
    z-index: 1;
    text-align: center;
    height: 300px;
    border-radius: 12px;

    .center {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .message {
        margin-top: 10px;
        color: #fff;
        font-weight: bold;
        text-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
      }
    }
  }
`

const Container = styled.div`
  padding-bottom: 10px;
  width: 100%;

  .enum {
    padding-bottom: 5px;
  }

  .item {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 1px 0px 1px 0px;
    
    .corporate-name {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow-y: hidden;
      width: 75%;
      padding-right: 5px;

      
      color: var(--institucional-cinza-sebrae-cinza-sebrae-30, #394D60);
      font-size: 16px;
      font-weight: 400;
      line-height: 23px;
    }

    .distance {
      display: flex;
      align-items: center;
      justify-content: right;
      width: 25%;
      font-size: 14px;
    }
  }
`

const ListTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 1px solid #8B99A7;

  span {
    color: var(--institucional-cinza-sebrae-cinza-sebrae-60, #8B99A7);
    font-size: 13px;
    font-weight: 700;
  }
`

const MessageContainer = styled.div`
  display: flex;
  padding: 0px 0px 7.5px 0px;
  justify-content: left;
`

const Link = styled(OriginalLink)`
  display: flex;
  flex-shrink: 1;
  width: 100%;

  &:hover {
    color: rgb(0, 0, 0);
    background-color: rgb(235, 235, 235);
  }

  span {
    color: #363636;
    font-size: 14px;
    line-height: 1.3;
    display: block;
  }
`

export default function DashboardGridItemRadar() {
  const isMobile = useMedia('(max-width: 768px)')
  const map = useRef<google.maps.Map | null>(null)
  const [isRadarEnabled, setIsRadarEnabled] = useState<boolean>(false)
  const [isLoadingMap, setIsLoadingMap] = useState(true)
  const geolocation = useGeolocation()
  const { google } = useGoogleMaps()
  const [error, setError] = useState<Error | null>(null)
  const wrapperWidth = useRef<HTMLDivElement>(null)

  const cellphoneSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 48 48"><g class="nc-icon-wrapper"><path fill="#444" d="M35 47H13a4 4 0 0 1-4-4V5a4 4 0 0 1 4-4h22a4 4 0 0 1 4 4v38a4 4 0 0 1-4 4z"></path><circle fill="#595959" cx="24" cy="42" r="2"></circle><path fill="#43A6DD" d="M35 37H13a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h22a1 1 0 0 1 1 1v28a1 1 0 0 1-1 1z"></path></g></svg>`
  const computerSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 48 48"><g class="nc-icon-wrapper"><path fill="#444" d="M41 30V7a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v23h34z"></path><path fill="#E6E6E6" d="M41 30H7L1.447 41.106A2 2 0 0 0 3.237 44h41.527a2 2 0 0 0 1.789-2.894L41 30z"></path><path fill="#43A6DD" d="M10 8h28v19H10z"></path><path fill="#B3B3B3" d="M40 37H8l2-5h28z"></path><path fill="#B3B3B3" d="M29 41H19l.542-2h8.916z"></path></g></svg>`

  const cellphoneIcon = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(cellphoneSvg)
  const computerIcon = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(computerSvg)

  useEffect(() => {
    if (geolocation.isLoading === false) {
      setIsRadarEnabled(true)
    }
  }, [geolocation, isLoadingMap])

  const { withinRadius, isLoading: isLoadingAttendanceHook } = useAttendanceRadar({
    longitude: Number(geolocation.longitude),
    latitude: Number(geolocation.latitude),
    isEnabled: !!isRadarEnabled,
    errorPermission: setError,
  })

  const withinRadiusValues = withinRadius
    .map((item: LegalEntityData) => ({
      legalEntityId: item.legalEntityId,
      corporateName: item.corporateName,
      distance: Number(item.distanceMeters.toFixed(2)),
      pointOnStreet: item.pointOnStreet,
    }))
    .slice(0, 3)

  function mapLoadAction() {
    setIsLoadingMap(false)
  }

  const bounds = useMemo(() => {
    const items = withinRadius

    if (!items) {
      return null
    }
    let south = Infinity
    let west = Infinity
    let north = -Infinity
    let east = -Infinity

    for (const item of items) {
      const point = item.pointOnStreet as GeoJsonPoint

      if (point && point.type === 'Point') {
        const [longitude, latitude] = point.coordinates
        if (south > latitude) {
          south = latitude
        }
        if (north < latitude) {
          north = latitude
        }
        if (west > longitude) {
          west = longitude
        }
        if (east < longitude) {
          east = longitude
        }
      }
    }

    if (!isFinite(south) || !isFinite(north) || !isFinite(east) || !isFinite(west)) {
      return null
    }
    return {
      south: south - 0.0001,
      west: west - 0.0001,
      north: north + 0.0001,
      east: east + 0.0001,
    }
  }, [withinRadius, isRadarEnabled])

  const overItems = useMemo(() => {
    if (!bounds) {
      return null
    }

    const withinRadiusValuesItems = withinRadiusValues.map(item => {
      const centerGeoJson = item.pointOnStreet as {
        type: 'Point'
        coordinates: number[]
      }
      return {
        type: 'Feature' as 'Feature',
        properties: {
          id: item.legalEntityId,
          name: `${item.corporateName}`,
          key: item.legalEntityId,
          multiPolygon: null,
          path: null,
          count: 1,
        },
        geometry: centerGeoJson,
      }
    })

    const index = new Supercluster({
      radius: 40,
      maxZoom: 17,
    })

    index.load(withinRadiusValuesItems)

    const clusters = index.getClusters([bounds.west, bounds.south, bounds.east, bounds.north], 16)

    return clusters.map(cluster => {
      if (cluster.properties.cluster) {
        const innerItems = index
          .getLeaves(cluster.properties.cluster_id, 30000)
          .map(({ properties }) => properties)

        const count = innerItems ? innerItems.length : 1

        return {
          id: cluster.properties.cluster_id,
          key: cluster.properties.cluster_id,
          name: String(cluster.properties.point_count),
          count,
          cluster: {
            type: 'Point',
            coordinates: [cluster.geometry.coordinates[1], cluster.geometry.coordinates[0]],
          },
          clusterItems: innerItems,
        }
      }

      return {
        id: cluster.properties.id,
        key: cluster.properties.id,
        name: String(cluster.properties.name),
        count: 1,
        cluster: {
          type: 'Point',
          coordinates: [cluster.geometry.coordinates[1], cluster.geometry.coordinates[0]],
        },
        clusterItems: [cluster.properties],
      }
    }) as any
  }, [withinRadiusValues, bounds?.east, bounds?.west, bounds?.north, bounds?.south])

  const clusterMarker = useAsync(() =>
    canvasDrawToURL(
      context => {
        context.fillStyle = colors.sebraeBlue
        context.arc(30, 30, 22, 0, 2 * Math.PI)
        context.globalAlpha = 0.9
        context.fill()
        context.strokeStyle = '#fff'
        context.lineWidth = 2
        context.stroke()
      },
      { width: 60, height: 60 },
    ),
  )

  return (
    <Wrapper>
      <Handle error={error}>
        <MapWrapper
          ref={wrapperWidth}
          style={
            isLoadingMap
              ? { height: '300px', pointerEvents: 'none' }
              : { height: 'none', pointerEvents: 'none' }
          }
        >
          {isLoadingAttendanceHook !== false && isLoadingMap === false ? (
            <div className='overlay-isLoading' style={{ width: wrapperWidth.current?.clientWidth }}>
              <div className='center'>
                <PulseLoader color={'#fff'} />
                <span className='message'>Carregando localizações...</span>
              </div>
            </div>
          ) : null}
          <GoogleMaps
            ref={map}
            onLoad={mapLoadAction}
            mapContainerStyle={
              isRadarEnabled
                ? {
                  width: '100%',
                  height: '100%',
                  borderRadius: '12px',
                  maxHeight: '300px',
                }
                : { display: 'none' }
            }
            mapTypeId={'satellite'}
            zoom={17}
            center={{ lng: Number(geolocation.longitude), lat: Number(geolocation.latitude) }}
            options={{
              disableDefaultUI: true,
            }}
          >
            <>
              {isRadarEnabled && google && isLoadingAttendanceHook === false ? (
                <Marker
                  icon={{
                    anchor: new google.maps.Point(15, 15),
                    url: isMobile ? cellphoneIcon : computerIcon,
                  }}
                  position={{
                    lng: Number(geolocation.longitude),
                    lat: Number(geolocation.latitude),
                  }}
                  zIndex={1}
                >
                  <Circle
                    center={{
                      lng: Number(geolocation.longitude),
                      lat: Number(geolocation.latitude),
                    }}
                    radius={100}
                    options={{
                      strokeColor: colors.sebraeBlue,
                      strokeOpacity: 1,
                      strokeWeight: 2,
                      fillColor: colors.sebraeBlue,
                      fillOpacity: 0.3,
                      clickable: false,
                      draggable: false,
                      editable: false,
                      visible: true,
                      zIndex: 10,
                    }}
                  />
                </Marker>
              ) : null}

              {overItems && google
                ? overItems.map((item: OverItems, index: number) => (
                  <Marker
                    icon={{
                      url: `${clusterMarker.value}`,
                      anchor: new google.maps.Point(15, 15),
                    }}
                    label={{
                      text: `${item.count}`,
                      color: '#fff',
                      fontWeight: 'bold',
                      fontSize: '16px',
                    }}
                    position={{
                      lng: item.cluster.coordinates[1],
                      lat: item.cluster.coordinates[0],
                    }}
                    zIndex={2}
                    key={index}
                  />
                ))
                : null}
            </>
          </GoogleMaps>
        </MapWrapper>

        {isLoadingAttendanceHook === false ? (
          [...withinRadiusValues].length > 0 && isRadarEnabled ? (
            <Container>
              <ListTitle>
                <span>Nome da empresa</span>
                <span>Distância</span>
              </ListTitle>
              {withinRadiusValues.map(item => (
                <div className='item' key={item.legalEntityId}>
                  <Link href={`/app/map/legalEntity/${item.legalEntityId}`}>
                    <div className='item'>
                      <span className='corporate-name'>{item.corporateName}</span>
                      <span className='distance'>
                        (à {formatAsBrDecimalNumber(Math.round(item.distance))}m)
                      </span>
                    </div>
                  </Link>
                </div>
              ))}
            </Container>
          ) : (
            <MessageContainer>
              <span>Nenhuma empresa encontrada.</span>
            </MessageContainer>
          )
        ) : (
          <MessageContainer>
            <span>Carregando lista prévia...</span>
          </MessageContainer>
        )}

        {[...withinRadiusValues].length > 0 ? (
          <div className='bottom'>
            <a href={'app/attendance-radar'}>Ver mais</a>
          </div>
        ) : null}
      </Handle>
    </Wrapper>
  )
}
