import React, { useEffect } from 'react'
import Handle from '~/prix/react/components/handle'
import useItem from '~/prix/react/hooks/item'
import { formatAsBrIntegerNumber } from '~/prix/utils/types/number'
import { Item, Items } from './dashboardGridItemAttendance.component'
import courseRegisterIndividualQuery from '../queries/courseRegisterIndividual.query'


export default function DashboardGridItemCourseRegisterIndividual({
  userGeoStateAbbreviation,
  dataSourceId,
  isLastYearQuery,
}: {
  userGeoStateAbbreviation?: string | null
  dataSourceId?: number
  isLastYearQuery?: boolean
}) {
  const totalAttendance = useItem(
    () => courseRegisterIndividualQuery(
      {
        stateAbbreviation: userGeoStateAbbreviation ? userGeoStateAbbreviation : undefined,
        dataSourceId,
        isLastYearQuery
      }
    ),
    [userGeoStateAbbreviation, dataSourceId],
    { cache: 60 * 60 * 24 },
  )

  return (
    <Handle isLoading={totalAttendance.isLoading} error={totalAttendance.error} style={{ minHeight: '100px' }}>
      <Items>
        <Item>
          <span className='first'>
            {formatAsBrIntegerNumber(Number(totalAttendance.item?.total))}
          </span>
          <span className='second'>Matrículas vinculadas a empresas realizadas</span>
        </Item>

        <Item>
          <span className='first'>
            {formatAsBrIntegerNumber(Number(totalAttendance.item?.totalLegalEntity))}
          </span>
          <span className='second'>Empresas geocodificadas matriculadas</span>
        </Item>
      </Items>
    </Handle>
  )
}
