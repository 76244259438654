import { defineEntity, entity, foreign, number, object } from '~/prix'

const cityCensusByPopulationEntity = defineEntity({
  key: 'cityCensusByPopulation',
  title: 'Quantidade de empresas per capita',
  alternativeTitles: ['Número de empresas dividido pelo valor da população da cidade'],
  sources: ['oltp'],
  schema: object({
    cityId: foreign(entity('city').property('id')).label('Cidade com informações do censo'),
    viewPopulation: number().label('População'),
    lastUpdated: number().label('Ano de Coleta do Dado'),
    entitiesByPopulation: number().label('Quantidade de empresas per capita'),
    entitiesByCity: number().label('Quantidade de empresas por cidade'),
    microRegionId: foreign(entity('microRegion').property('id')),
    mesoRegionId: foreign(entity('mesoRegion').property('id')),
    stateId: foreign(entity('state').property('id')),
    macroRegionId: foreign(entity('macroRegion').property('id')),
    countryId: foreign(entity('country').property('id')),
  }),
})

export default cityCensusByPopulationEntity
