import React, { memo, useState } from 'react'
import styled from 'styled-components'
import { formatAsBrNumber } from '~/prix'
import InfoIcon from '~/components/icons/ui/16px_round-e-info.svg'
import LegalEntityGeoprocessingMapDemarcationReportChartComponent from './legalEntityGeoprocessingMapDemarcationReportChart.component'
import LegalEntityGeoprocessingMapDemarcationReportTableComponent from './legalEntityGeoprocessingMapDemarcationReportTable.component'
import { organizationalColors } from '~/design'
import LegalEntityGeoprocessingMapDemarcationReportChartRenderComponent from './legalEntityGeoprocessingMapDemarcationReportChartRender.component'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 20px;

  .list {
    display: flex;
    flex: 1;
    border: 1px solid #b6bfc8;
    border-radius: 20px;
    padding: 20px;

    .item-wrapper-full {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: 0px 20px 0px 20px;
    }
  }
`

const HeaderCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  margin-bottom: 3px;
  padding: 12px 10px 24px 10px;

  .info {
    display: flex;
    align-items: center;
    column-gap: 6px;
    color: #8b99a7;
    font-size: 16px;
    font-weight: 500;
    padding: 0px 0px 4px 0px;

    @media (max-width: 670px) {
      margin-top: 15px;
    }
  }

  .index-wrapper {
    display: flex;
    min-height: 75px;
    width: 100%;
    margin: 12px 0px 5px 0px;

    .button-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      flex-wrap: wrap;
      column-gap: 30px;
      row-gap: 8px;

      @media (max-width: 480px) {
        height: fit-content;
      }

      button {
        height: 50%;
        background: none;
        border: none;
        cursor: pointer;
        outline: inherit;
        border-bottom: 1px solid #8b99a7;
        font-size: 17px;
        font-weight: 600;
        color: #8b99a7;

        &.is-active {
          color: #0f438a;
          border-bottom: 2px solid #0f438a;
        }

        &:hover {
          color: ${organizationalColors.aquaBlue60};
          border-bottom: 2px solid ${organizationalColors.aquaBlue60};
          transition: 0.1s;
        }
      }
    }
  }

  .subtitle {
    color: #0f438a;
    font-size: 14px;
    font-weight: 500;
  }
`

const Item = styled.div`
  display: flex;
  height: 40px;
  padding: 10px 0px 10px 0px;
  align-items: center;

  .description {
    width: 75%;
    align-items: center;

    span {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;

      color: #121921;
      font-size: 16px;

      @media (max-width: 670px) {
        font-size: 14px;
      }
    }
  }

  .value {
    width: 20%;
    text-align: center;

    @media (max-width: 670px) {
      width: 25%;
    }

    span {
      color: #121921;
      font-size: 16px;
      padding-left: 10px;

      @media (max-width: 670px) {
        font-size: 14px;
      }
    }
  }
`

const Line = styled.hr`
  display: block;
  height: 1px;
  background: transparent;
  width: 100%;
  border: none;
  border-top: solid 1px #e0e5eb;
`

const Circle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
`

const HeaderContainer = styled.div`
  display: flex;
  height: 50px;
  flex-direction: column;
  padding: 0px 0px 5px 0px;

  .title {
    color: #0f438a;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 5px;
  }

  .subtitle {
    font-size: 14px;
    color: #0f438a;
    font-weight: 500;
    line-height: 1.35;
  }
`

interface LegalEntityGeoprocessingMapDemarcationReportCityListProps {
  cityData: any
  setImageUrl: React.Dispatch<React.SetStateAction<Array<string>>>
  hasSubtitle?: boolean
  hasIdhm?: any
  onChangeSelectedYearChart: React.Dispatch<React.SetStateAction<string>>
}

function legalEntityGeoprocessingMapDemarcationReportCityList({
  cityData,
  setImageUrl,
  hasSubtitle,
  hasIdhm,
  onChangeSelectedYearChart,
}: LegalEntityGeoprocessingMapDemarcationReportCityListProps) {
  const [isActive, setIsActive] = useState<number>(0)

  return (
    <Wrapper>
      <HeaderCard>
        <div className='index-wrapper'>
          <div className='button-wrapper'>
            {cityData.map((item: any, index: number) => (
              <button
                key={index}
                className={isActive == index ? 'is-active' : ''}
                onClick={() => setIsActive(index)}
              >
                {item.name}
              </button>
            ))}
          </div>
        </div>
        <span className='info'>
          <InfoIcon height={14} width={14} fill={'#8B99A7'} />
          Selecione a cidade acima para visualizar os dados.
        </span>
        {hasSubtitle ? (
          <span className='subtitle'>
            Intersecção de{' '}
            {cityData[isActive].intersectionArea !== null
              ? formatAsBrNumber(Number((cityData[isActive].intersectionArea / 1000000).toFixed(2)))
              : cityData[isActive].citiesCensusData?.length > 0
              ? formatAsBrNumber(cityData[isActive].citiesCensusData[0].value)
              : ''}{' '}
            km²
          </span>
        ) : null}
      </HeaderCard>
      <div className='list'>
        <div className='item-wrapper-full'>
          <HeaderContainer>
            <span className='title'>Censo</span>
            <span className='subtitle'>Dados extraídos do último Censo encontrado.</span>
          </HeaderContainer>
          {cityData[isActive].citiesCensusData.map(
            (
              item: {
                description: string
                value: number
                key: string
                year: string
              },
              index: React.Key,
            ) => (
              <React.Fragment key={index}>
                <Item>
                  <div className='description'>
                    <span>
                      {item.description} <small>(Censo: {item.year})</small>
                    </span>
                  </div>
                  <div className='value'>
                    {item.value < 1 ? (
                      <Circle
                        style={
                          hasIdhm && hasIdhm[0] && item.value < 0.55
                            ? {
                                backgroundColor: '#8ccdff',
                              }
                            : hasIdhm && item.value >= 0.55 && item.value <= 0.699
                            ? {
                                backgroundColor: '#42abff',
                              }
                            : hasIdhm && item.value >= 0.7 && item.value <= 0.799
                            ? {
                                backgroundColor: '#1975bf',
                              }
                            : hasIdhm && item.value >= 0.8
                            ? {
                                backgroundColor: '#0F438A',
                              }
                            : {}
                        }
                      ></Circle>
                    ) : null}
                    <span>
                      {item.key === 'grossDomesticProduct' || item.key === 'incomePerCapita'
                        ? 'R$ '
                        : null}
                      {formatAsBrNumber(item.value)}
                      {item.key === 'area' ? ' km²' : null}
                      {item.key === 'populationDensity' ? ' habitantes/km²' : null}
                    </span>
                  </div>
                </Item>
                <Line />
              </React.Fragment>
            ),
          )}

          <LegalEntityGeoprocessingMapDemarcationReportTableComponent
            values={
              cityData[isActive].citiesGovernmentTransparencyData.length > 0
                ? cityData[isActive].citiesGovernmentTransparencyData
                : null
            }
          />

          {cityData[isActive].citiesCagedData?.length > 0 ? (
            <LegalEntityGeoprocessingMapDemarcationReportChartComponent
              values={cityData[isActive].citiesCagedData}
              onChangeSelectedYearChart={onChangeSelectedYearChart}
            />
          ) : null}

          {cityData.map((item: any, index: number) => (
            <LegalEntityGeoprocessingMapDemarcationReportChartRenderComponent
              key={index}
              values={item.citiesCagedData}
              setImageUrl={setImageUrl}
            />
          ))}
        </div>
      </div>
    </Wrapper>
  )
}

export default memo(legalEntityGeoprocessingMapDemarcationReportCityList)
