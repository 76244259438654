import { useMemo } from 'react'
import { truthy } from '~/prix'
import { QueryBase } from '~/prix/query'
import useItems from '~/prix/react/hooks/items'
import { Level, Breadcrumb } from './disproportionateAttentionMapLevels.data'
import breadcrumbQuery from '../../../legalEntityGeoprocessing/map/levels/breadcrumb.query'

export default function useDisproportionateAttentionBreadcrumbs({ geo, id, roles }: Level) {
  const isStartLevel = geo === 'country' && !id
  const temporaryCountryId = '30'

  const breadcrumbResult = useItems(() => breadcrumbQuery(geo, id!) as QueryBase, [geo, id], {
    cache: 60 * 60 * 24 * 7,
    autoLoad: !isStartLevel,
  })
  const breadcrumbs = useMemo(() => {
    if (isStartLevel) {
      return [
        {
          geo: 'country',
          id: temporaryCountryId,
          title: 'Brasil',
          subTitle: 'País',
        } as Breadcrumb,
      ]
    }
    if (breadcrumbResult.error) {
      console.error(breadcrumbResult.error)
    }

    return breadcrumbResult && breadcrumbResult.items
      ? ([
          roles === false
            ? {
                geo: 'country',
                id: temporaryCountryId,
                title: 'Brasil',
                subTitle: 'País',
              }
            : null,
          breadcrumbResult.items[0].stateId
            ? {
                geo: 'state',
                id: breadcrumbResult.items[0].stateId,
                title: breadcrumbResult.items[0].stateName,
                subTitle: 'Estado',
              }
            : null,
          breadcrumbResult.items[0].cityId
            ? {
                geo: 'city',
                id: breadcrumbResult.items[0].cityId,
                title: breadcrumbResult.items[0].cityName,
                subTitle: 'Cidade',
              }
            : null,
        ].filter(truthy) as Breadcrumb[])
      : null
  }, [breadcrumbResult, isStartLevel])

  return {
    items: breadcrumbs,
    isLoading: breadcrumbResult.isLoading,
    error: breadcrumbResult.error,
  }
}
