import { defineAction, object, boolean } from '~/prix'
import fetchAllSuburbs from './fetchAllSuburbs.action.json'

const fetchAllSuburbsDefinition = defineAction({
  ...fetchAllSuburbs,
  input: object({
    update: boolean()
      .default(() => false)
      .label('Atualizar registros'),
  }),
  output: object({}),
})

export default fetchAllSuburbsDefinition
