import React from 'react'
import styled from 'styled-components'
import { formatAsBrNumber } from '~/prix'
import { HarmonicComponent } from './timeSeries.entity'

const Wrapper = styled.div`
  flex: 1;
  padding: 0 10px;
`

export interface TimeSeriesHarmonicInfoLineProps {
  component: HarmonicComponent
}

export default function TimeSeriesHarmonicInfoLine({ component }: TimeSeriesHarmonicInfoLineProps) {
  if (!component) {
    return null
  }
  return (
    <Wrapper>
      À cada {formatAsBrNumber(component.frequency, 0)} dias, total de {component.peaks} picos
    </Wrapper>
  )
}
