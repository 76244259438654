import React from 'react'

export default function ResolutionInformation() {
  return (
    <>
      <thead>
        <tr>
          <th colSpan={2}>Resolução</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Resolução de tela</td>
          <td>
            {window.screen.width} x {window.screen.height}
          </td>
        </tr>
        <tr>
          <td>Viewport</td>
          <td>
            {window.innerWidth} x {window.innerHeight}
          </td>
        </tr>
      </tbody>
    </>
  )
}
