import { defineEntity, object, string } from '~/prix'

const legalEntityAttendanceEntity = defineEntity({
  key: 'legalEntityAttendanceAttendant',
  title: 'Atendentes vinculados a atendimentos',
  sources: ['olap','oltp'],
  schema: object({
    agentCpf: string().label('CPF', 'CPF do agente'),
    name: string().label('Nome', 'Nome do agente'),
    email: string().label('Email', 'Email do agente'),
  }),
})

export default legalEntityAttendanceEntity
