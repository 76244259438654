import { array, defineAction, number, object, string } from '~/prix'
import fetchLegalEntitiesCoordinatesAction from './fetchLegalEntitiesCoordinates.action.json'

const fetchLegalEntitiesCoordinatesDefinition = defineAction({
  ...fetchLegalEntitiesCoordinatesAction,
  input: object({
    cnpjList: array(string()).isOptional(),
  }),
  output: object({
    cnpj: string(),
    latitude: number(),
    longitude: number(),
    quality: string(),
  }),
})

export default fetchLegalEntitiesCoordinatesDefinition
