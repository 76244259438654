import { useState } from 'react'
import useInterval from 'react-use/lib/useInterval'

export default function useMemory() {
  const performanceMemory: PerformanceMemory = performance
  const [memory, setMemory] = useState<Memory | undefined>()
  const [memoryError, setMemoryError] = useState<Error | undefined>()

  useInterval(() => {
    if (performanceMemory.memory) {
      setMemory(performanceMemory.memory)
    } else {
      setMemoryError(new Error('performance.memory not suported'))
    }
  }, 5000)

  return { memory, memoryError }
}
