import { defineEntity, entity, foreign, object, string, number, boolean } from '~/prix'
import { multiPolygon, point } from '~/prix/types/geoJson'

const cityGovernmentTransparencyEntity = defineEntity({
  key: 'cityGovernmentTransparency',
  title: 'Transparência do Governo Federal',
  alternativeTitles: ['Dados de transparência do governo federal'],
  sources: ['olap', 'oltp'],
  schema: object({
    id: string().label('ID', 'Identificador', 'Código'),
    cityId: foreign(entity('city').property('id')),
    ibgeCode: number().label('Código do IBGE'),
    transferredResources: number().label('Recursos transferidos do governo para os municípios'),
    populationCount: number().label('Contador de população'),
    beneficiaryCount: number().label('Contador de favorecidos dos auxílios do governo'),
    benefitsTotal: number().label('Valor total dos benefícios pagos aos cidadãos'),

    bolsaFamiliaBeneficiaryCount: number().label(
      'Quantidade de beneficiários do programa bolsa familia',
    ),
    petiBeneficiaryCount: number().label('Quantidade de beneficiários do programa PETI'),
    safraBeneficiaryCount: number().label('Quantidade de beneficiários do programa SAFRA'),
    seguroDefesoBeneficiaryCount: number().label(
      'Quantidade de beneficiários do programa seguro defeso',
    ),
    bpcBeneficiaryCount: number().label('Quantidade de beneficiários do programa BPC'),
    auxilioEmergencialBeneficiaryCount: number().label(
      'Quantidade de beneficiários do programa Auxílio Emergencial',
    ),
    auxilioBrasilBeneficiaryCount: number().label(
      'Quantidade de beneficiários do programa Auxílio Brasil',
    ),
    novoBolsaFamiliaBeneficiaryCount: number().label(
      'Quantidade de beneficiários do programa Novo Bolsa Familia',
    ),

    bolsaFamiliaBeneficiaryTotal: number().label(
      'Valor total pago aos cidadãos do programa bolsa familia',
    ),
    petiBeneficiaryTotal: number().label('Valor total pago aos cidadãos do programa PETI'),
    safraBeneficiaryTotal: number().label('Valor total pago aos cidadãos do programa SAFRA'),
    seguroDefesoBeneficiaryTotal: number().label(
      'Valor total pago aos cidadãos do programa seguro defeso',
    ),
    bpcBeneficiaryTotal: number().label('Valor total pago aos cidadãos do programa BPC'),
    auxilioEmergencialBeneficiaryTotal: number().label(
      'Valor total pago aos cidadãos do programa Auxílio Emergencial',
    ),
    auxilioBrasilBeneficiaryTotal: number().label(
      'Valor total pago aos cidadãos do programa Auxílio Brasil',
    ),
    novoBolsaFamiliaBeneficiaryTotal: number().label(
      'Valor total pago aos cidadãos do programa Novo Bolsa Familia',
    ),

    populationPercentage: number().label(
      'Porcentagem de cidadãos cadastrados nos programas de benefícios do governo',
    ),
    bolsaFamiliaBeneficiaryPercentage: number().label(
      'Porcentagem de cidadãos cadastrados no programa programa bolsa familia',
    ),
    petiBeneficiaryPercentage: number().label(
      'Porcentagem de cidadãos cadastrados no programa programa PETI',
    ),
    safraBeneficiaryPercentage: number().label(
      'Porcentagem de cidadãos cadastrados no programa programa SAFRA',
    ),
    seguroDefesoBeneficiaryPercentage: number().label(
      'Porcentagem de cidadãos cadastrados no programa programa seguro defeso',
    ),
    bpcBeneficiaryPercentage: number().label(
      'Porcentagem de cidadãos cadastrados no programa programa BPC',
    ),
    auxilioEmergencialBeneficiaryPercentage: number().label(
      'Porcentagem de cidadãos cadastrados no programa programa Auxílio Emergencial',
    ),
    auxilioBrasilBeneficiaryPercentage: number().label(
      'Porcentagem de cidadãos cadastrados no programa programa Auxílio Brasil',
    ),
    novoBolsaFamiliaBeneficiaryPercentage: number().label(
      'Porcentagem de cidadãos cadastrados no programa programa Novo Bolsa Familia',
    ),

    bolsaFamiliaMeanPerBeneficiary: number().label(
      'Média de valor pago em beneficio aos cidadãos do programa bolsa familia',
    ),
    petiMeanPerBeneficiary: number().label(
      'Média de valor pago em beneficio aos cidadãos do programa PETI',
    ),
    safraMeanPerBeneficiary: number().label(
      'Média de valor pago em beneficio aos cidadãos do programa SAFRA',
    ),
    seguroDefesoMeanPerBeneficiary: number().label(
      'Média de valor pago em beneficio aos cidadãos do programa seguro defeso',
    ),
    bpcMeanPerBeneficiary: number().label(
      'Média de valor pago em beneficio aos cidadãos do programa BPC',
    ),
    auxilioEmergencialMeanPerBeneficiary: number().label(
      'Média de valor pago em beneficio aos cidadãos do programa Auxílio Emergencial',
    ),
    auxilioBrasilMeanPerBeneficiary: number().label(
      'Média de valor pago em beneficio aos cidadãos do programa Auxílio Brasil',
    ),
    novoBolsaFamiliaMeanPerBeneficiary: number().label(
      'Média de valor pago em beneficio aos cidadãos do programa Novo Bolsa Familia',
    ),
  }),
})

export default cityGovernmentTransparencyEntity
