import {
  count,
  entity,
  equals,
  isNull,
  query,
  string,
  truthy,
  inOperator,
  object,
  array,
  boolean,
} from '~/prix'

export default function attendantVisitListQuery(agentCpf: string[]) {
  const uniqueAgentCpf = [...new Set(agentCpf)] // Removendo valores duplicados

  return query('legalEntityAttendance')
    .select({
      startDate: entity('legalEntityAttendance').property('startDate'),
      count: count(entity('legalEntityAttendance').property('startDate')),
      agentCpf: entity('legalEntityAttendance').property('agentCpf'),
    })
    .join({
      current: entity('legalEntityAttendance').property('legalEntityId'),
      target: entity('legalEntity').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityAttendance').property('id'),
      target: entity('legalEntityAttendanceDataSource').property(
        'legalEntityAttendanceDataSourceId',
      ),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityAttendanceDataSource').property('dataSourceId'),
      target: entity('dataSource').property('id'),
      join: 'inner',
    })
    .where(
      ...[
        isNull(entity('legalEntity').property('deletedAt')),
        inOperator(
          entity('legalEntityAttendance').property('agentCpf'),
          uniqueAgentCpf.map(agentCpf => string().value(agentCpf)),
        ),
        equals(entity('dataSource').property('isActive'), boolean().value(true)),
      ].filter(truthy),
    )
}
