import { defineEntity, entity, foreign, object, string, number, boolean, dateTime } from '~/prix'

const reportOutputEntity = defineEntity({
  key: 'reportOutput',
  title: 'Saída do Relatório',
  alternativeTitles: [],
  sources: ['olap', 'oltp'],
  schema: object({
    id: string().label('ID', 'Identificador', 'Código'),
    reportId: foreign(entity('report').property('id')).label('ID do Relatório'),
    hash: string().label('Hash').isOptional(),
    html: string().label('HTML').isOptional(),
    variables: string().label('Variáveis'),
    createdAt: dateTime().label('Data de Criação'),
    updatedAt: dateTime().label('Data de Atualização'),
  }),
})

export default reportOutputEntity
