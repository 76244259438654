import { initializeApp } from 'firebase/app'
import { getToken, isSupported } from 'firebase/messaging'
import { getMessaging } from 'firebase/messaging/sw'
import { Dispatch, SetStateAction } from 'react'
import env from '~/config.json'

export const app = initializeApp(env.services.firebaseCredentials)

export const getTokens = async (setTokenFound: Dispatch<SetStateAction<string | null>>) => {
  try {
    if (!(await isSupported())) {
      console.info('Notifications not supported')
      setTokenFound(null)
      return
    }

    const messaging = getMessaging(app)
    const currentToken = await getToken(messaging, { vapidKey: env.services.VAPID_KEY })
    if (currentToken) {
      setTokenFound(currentToken)
      console.info('Notifications supported and token found')
    } else {
      setTokenFound(null)
      console.info('No registration token available. Request permission to generate one.')
    }
  } catch (error) {
    console.warn('An error occurred while retrieving token. ', error)
  }
}
