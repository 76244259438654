import { PropertyStringBase } from '../property'
import type { InferNative, NativeBaseOptional, Type } from '../types/type'
import type { AbstractValueFunction } from './function'

export interface ToTsVectorFunction<NativeGeneric extends NativeBaseOptional>
  extends AbstractValueFunction<NativeGeneric> {
  name: 'toTsVector'
  dictionary: String
}

/**
 * Conversão de função TO_TSVECTOR do postgres, converte a propriedade escolhida em um ts_vector.
 * @remarks [Documentação PostgreSLQ](https://www.postgresql.org/docs/14/textsearch-controls.html#TEXTSEARCH-PARSING-DOCUMENTS)
 * @param content Argumentos da função
 * @param dictionary Dicionário usado para a conversão.
 * @returns Propriedade nativa compatível com a prixApi
 */
export default function toTsVector<
  TypeGeneric extends Type,
  NativeGeneric extends NativeBaseOptional = InferNative<TypeGeneric>,
>(dictionary: String, content: NativeGeneric): PropertyStringBase {
  return {
    kind: 'function',
    name: 'toTsVector',
    dictionary,
    content,
  }
}
