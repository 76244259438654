import { useCallback, useState } from 'react'
import { errors, InferNative, ObjectType, Sources, Type } from '~/prix'
import { BaseActionDefinitionWithInputOutput } from '~/prix/actionDefinition'
import { AppError } from '../../error'
import useAPI from './api'

export default function useActionFunction<
  InputType extends Type,
  OutputType extends ObjectType,
  ActionDefinition extends BaseActionDefinitionWithInputOutput<InputType, OutputType>,
  Input = InferNative<ActionDefinition['input']>,
  Output = InferNative<ActionDefinition['output']>,
>(
  actionDefinition: ActionDefinition,
  sourceKey: keyof Sources = 'oltp',
  { initialLoadingState = true } = {},
) {
  const [result, setResult] = useState<Output>()
  const [error, setError] = useState<Error | AppError | null>(null)
  const [isLoading, setIsLoading] = useState(initialLoadingState)

  const api = useAPI()
  const source = api.sources[sourceKey]

  const callAction = useCallback(
    (input: Input) => {
      if (!source) {
        throw errors.incompatible('Fonte de dados indisponível')
      }

      setError(null)
      setIsLoading(true)

      const call = source.call(actionDefinition, input, api.context)
      const resultPromise = new Promise<Output>((resolve, reject) => {
        call.run({ onResponse: resolve as any }).catch(reject)
      })

      return {
        abort: () => call.abort(),
        resultPromise: resultPromise
          .then(result => {
            setResult(result)
            return result
          })
          .catch(error => {
            setError(error)
            throw error
          })
          .finally(() => setIsLoading(false)),
      }
    },
    [source, api.context, actionDefinition],
  )

  return {
    result,
    error,
    isLoading,
    callAction,
  }
}
