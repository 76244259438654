import * as turf from '@turf/helpers'
import React, { AnchorHTMLAttributes, useCallback, useMemo } from 'react'
import { PointFeatureWithRadius } from '~/prix/react/components/map/radiusEditor'
import useActionFunction from '~/prix/react/hooks/actionFunction'
import mapDemarcationExportEntitiesDefinition from '../exportEntities/mapDemarcationExportEntities.definition'
import { MapDemarcationReportProps } from '../fetchFromDatabase/mapDemarcationReport.hook'

export type LegalEntityGeoprocessingMapDemarcationReportProps = MapDemarcationReportProps & AnchorHTMLAttributes<HTMLAnchorElement> & {
  format: 'xlsx' | 'csv'
  children: (payload: { loading: boolean, error: Error | null }) => React.ReactNode
}

export default function LegalEntityGeoprocessingMapDemarcationExportEntitiesButton({
  feature,
  format,
  isLoading: isLoadingFeature = false,
  children,
  ...props
}: LegalEntityGeoprocessingMapDemarcationReportProps) {
  const input = useMemo(() => {
    if (!feature) {
      return {
        point: null,
        radius: null,
        boundary: null,
        geoItems: null,
      }
    }
    return {
      point: feature.geometry?.type === 'Point' ? feature.geometry as PointFeatureWithRadius : null,
      radius: feature.geometry?.type === 'Point' ? feature.properties?.radius as PointFeatureWithRadius : null,
      boundary: feature.geometry?.type === 'Polygon' ? feature.geometry as turf.Feature<turf.Polygon> : null,
      geoItems: feature.features ? (feature as turf.FeatureCollection<turf.Point>).features.map(feature => feature.properties) : null,
    }
  }, [feature])
  const {
    isLoading,
    callAction,
    error,
  } = useActionFunction(mapDemarcationExportEntitiesDefinition, undefined, {
    initialLoadingState: false,
  })

  const handleClick = useCallback(async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (isLoading || isLoadingFeature) {
      return
    }

    event.preventDefault()
    const { resultPromise } = callAction({
      ...input,
      format,
    } as any)
    const result = (await resultPromise) as {
      downloadUrl: string
    }
    if (!result?.downloadUrl) {
      return
    }
    window.location.href = result.downloadUrl
  }, [input, format, isLoading, isLoadingFeature])

  return (
    <a {...props} onClick={handleClick}>{children({
      error,
      loading: isLoading || isLoadingFeature,
    })}</a>
  )
}
