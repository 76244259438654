import { defineAction, object } from '~/prix'
import fetchSummaryAction from './fetchSummary.action.json'

const fetchSummaryDefinition = defineAction({
  ...fetchSummaryAction,
  input: object({}),
  output: object({}),
})

export default fetchSummaryDefinition
