import { defineAction, object, number } from '~/prix'
import fetchSuburbs from './fetchSuburbs.action.json'

const fetchSuburbsDefinition = defineAction({
  ...fetchSuburbs,
  input: object({
    osmNumber: number().label('Código OSM (Open Street Map'),
  }),
  output: object({}),
})

export default fetchSuburbsDefinition
