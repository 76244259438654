import React, { Fragment, memo } from 'react'
import { InfoWindow, Marker } from '@react-google-maps/api'
import Separator from '~/components/separator'
import { Button } from '~/prix/react/components/button'
import GeoJsonMultiPolygon from '~/prix/react/components/map/geoJsonMultiPolygon'
import { levelKeyAux } from './disproportionateAttentionMap.screen'
import { GeoItem } from './disproportionateAttentionMapLevels.data'
import {
  InfoWindowWrapper,
  MobileButtonWrapper,
} from '~/packages/legalEntityGeoprocessing/map/legalEntityGeoItem.component'

interface DisproportionateAttentionGeoItemProps extends GeoItem {
  isTouchable: boolean
  isSelected: boolean
  onClick: (item: GeoItem) => void
  handleClickMobile: (item: any) => void
  onMouseOver: (item: GeoItem) => void
  onMouseOut: (item: GeoItem) => void
  onMouseCenter: (value: boolean) => void
  isMouseOnCenter: boolean
  onDispose: (item: GeoItem) => void
  markerVariationForValue: (value: string) => void
  markerVariationLoading: boolean
  subtitle: string
  mapVisualization: {
    type: 'satellite' | 'map'
    visualizationType: 'heatmap' | 'chloroplethic'
  }
}

function DisproportionateAttentionGeoItem({
  isTouchable,
  isSelected,
  onClick,
  handleClickMobile,
  onMouseOut,
  onMouseOver,
  onMouseCenter,
  isMouseOnCenter,
  onDispose,
  markerVariationForValue,
  markerVariationLoading,
  subtitle,
  ...item
}: DisproportionateAttentionGeoItemProps): JSX.Element {
  const { multiPolygon, levelKey, name, center } = item
  const borderColor = item.borderColor
  const color = item.color

  const infoWindow = () => {
    return center ? (
      <InfoWindow
        position={center}
        onCloseClick={() => onDispose(item)}
        options={{ disableAutoPan: true, pixelOffset: new google.maps.Size(0, -5) }}
      >
        <InfoWindowWrapper>
          {!name ? (
            <span>Sem nome ({levelKeyAux[levelKey as unknown as keyof typeof levelKeyAux]})</span>
          ) : (
            <span>
              {name} ({levelKeyAux[levelKey as unknown as keyof typeof levelKeyAux]})
            </span>
          )}
          <Separator />
          <h1>{subtitle}</h1>
          {isTouchable ? (
            <MobileButtonWrapper>
              <Button onClick={() => handleClickMobile(item)}>Detalhar</Button>
            </MobileButtonWrapper>
          ) : null}
        </InfoWindowWrapper>
      </InfoWindow>
    ) : null
  }

  return (
    <Fragment>
      {multiPolygon ? (
        <GeoJsonMultiPolygon
          geoJson={multiPolygon}
          onClick={() => onClick(item)}
          onMouseOver={() => onMouseOver(item)}
          onMouseOut={() => onMouseOut(item)}
          options={{
            fillColor: color,
            fillOpacity: item.topTenCategories.length > 0 ? 1 : 0,
            strokeColor: borderColor,
            strokeWeight: item.topTenCategories.length > 0 ? 1.5 : 1.75,
            zIndex: 1,
          }}
        />
      ) : null}

      {isSelected && center && levelKey !== 'neighborhoods' && levelKey !== 'streets' ? (
        !isTouchable ? (
          <Marker
            icon={{
              url: `${markerVariationForValue(item.percentColor)}`,
              anchor: new google.maps.Point(15, 15),
            }}
            position={center}
            onClick={() => onClick(item)}
            onMouseOver={() => onMouseCenter(true)}
            onMouseOut={() => onMouseCenter(false)}
          >
            {isMouseOnCenter === true ? infoWindow() : null}
          </Marker>
        ) : isSelected ? (
          infoWindow()
        ) : null
      ) : null}
    </Fragment>
  )
}

export default memo(DisproportionateAttentionGeoItem)
