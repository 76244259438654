import { descending, entity, equals, query, string } from '~/prix'

export default function legalEntityIndividualListQuery(id: string) {
  return (
    query('legalEntityIndividual')
      .select({
        legalEntityIndividualId: entity('legalEntityIndividual').property('id'),
        legalEntityId: entity('legalEntityIndividual').property('legalEntityId'),
        name: entity('individual').property('name'),
        cpf: entity('individual').property('cpf'),
        isValidCpf: entity('individual').property('isValidCpf'),
        phoneNumber: entity('individual').property('phoneNumber'),
        gender: entity('individual').property('gender'),
        relationship: entity('legalEntityIndividual').property('relationship'),
        dataSourceId: entity('dataSource').property('id'),
        dataSourceName: entity('dataSource').property('name'),
      })
      .join({
        current: entity('legalEntityIndividual').property('individualId'),
        target: entity('individual').property('id'),
        join: 'inner',
      })
      .join({
        current: entity('legalEntityIndividual').property('id'),
        target: entity('legalEntityIndividualDataSource').property('legalEntityIndividualId'),
        join: 'inner',
      })
      .join({
        current: entity('legalEntityIndividualDataSource').property('dataSourceId'),
        target: entity('dataSource').property('id'),
        join: 'inner',
      })
      .where(equals(entity('legalEntityIndividual').property('legalEntityId'), string().value(id)))
      // .order(descending(''))
      .limit(5000)
  )
}
