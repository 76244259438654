import React, { useState } from 'react'
import DatePicker, { CalendarContainer } from 'react-datepicker'
import styled from 'styled-components'
import 'react-datepicker/dist/react-datepicker.css'
import Wrapper from '../field/wrapper'
import { ptBR } from 'date-fns/locale'
import { registerLocale } from 'react-datepicker'
registerLocale('ptBR', ptBR)

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;

  .clear {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    box-shadow: none;
    cursor: pointer;

    svg {
      path {
        fill: hsl(0, 0%, 80%);
      }
    }
  }
`
const svgIcon = (
  <svg
    version='1.1'
    viewBox='0 0 32 32'
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    xmlSpace='preserve'
  >
    <title>Calendário</title>
    <g fill={'hsl(0, 0%, 60%'}>
      <path d='M10 27H6a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1z' />
      <path d='M10 20H6a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1z' />
      <path d='M18 27h-4a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1z' />
      <path d='M18 20h-4a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1z' />
      <path d='M26 20h-4a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1z' />
      <path d='M31 3h-5V1a1 1 0 0 0-1-1h-3a1 1 0 0 0-1 1v2H11V1a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2H1a1 1 0 0 0-1 1v27a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM21 5h5v2h-5V5zM6 5h5v2H6V5zm24 25H2V12h28v18z' />
    </g>
  </svg>
)

const MyContainer = ({ className, children }: { className: string; children: React.ReactNode }) => {
  return (
    <div style={{ borderRadius: '8px' }}>
      <CalendarContainer className={className}>
        <div
          style={{
            position: 'relative',
            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
          }}
        >
          {children}
        </div>
      </CalendarContainer>
    </div>
  )
}

export default function DateField({
  meta: {
    isDisabled = false,
    withTime = false,
    minDate = null,
    maxDate = null,
    readOnly = false,
  } = {},
  id,
  value,
  label,
  placeholder = 'Selecione...',
  onDirectChange,
  isRequired = false,
}: {
  meta?: {
    isDisabled?: boolean
    withTime?: boolean
    minDate?: Date | null
    maxDate?: Date | null
    readOnly?: boolean
  }
  id: string
  value: string | null
  placeholder?: string
  label: string
  onDirectChange: (date: any) => void
  isRequired?: boolean
}) {
  const [exhibitionValue, setExhibitionValue] = useState<Date | null>(
    value ? new Date(value + 'T00:00:00') : null,
  )

  const handleChange = (date: Date | null) => {
    setExhibitionValue(date)
    onDirectChange(date)
  }

  const handleChangeRaw = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === undefined) return

    let regex
    let newValue = e.target.value.replace(/[^0-9]/g, '')

    if (withTime) {
      // esse regex com tempo não foi testado
      newValue = newValue.replace(/(\d{2})(\d{2})(\d{4})(\d{2})(\d{2})/, '$1/$2/$3 $4:$5')
      regex =
        /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4} (([0-1][0-9])|(2[0-3])):([0-5][0-9])$/
    } else {
      newValue = newValue.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3')
      regex = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/
    }

    if (regex.test(newValue)) {
      const [day, month, year] = newValue.split('/')
      const newRaw = new Date(`${month}/${day}/${year}`)
      if (newRaw instanceof Date && !isNaN(newRaw.getTime())) {
        setExhibitionValue(newRaw)
      }
    } else {
      setExhibitionValue(new Date())
    }
  }

  return (
    <Wrapper isRequired={isRequired} label={label} id={id}>
      <DatePicker
        selected={exhibitionValue}
        onChange={date => setExhibitionValue(date)}
        onBlur={() => handleChange(exhibitionValue)}
        onChangeRaw={event => handleChangeRaw(event)}
        showTimeSelect={withTime}
        locale='ptBR'
        dateFormat='dd/MM/yyyy'
        placeholderText={placeholder}
        disabled={isDisabled}
        calendarContainer={MyContainer}
        clearButtonTitle='Limpar'
        toggleCalendarOnIconClick
        readOnly={readOnly}
        showIcon
        icon={svgIcon}
        maxDate={maxDate}
        minDate={minDate}
        onCalendarClose={() => handleChange(exhibitionValue)}
      />
    </Wrapper>
  )
}
