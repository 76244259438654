import { count, entity, isNull, query, string, truthy, like, equals, number, between } from '~/prix'

export default function agentVisitList({
  agentCpf,
  dataSourceId,
  startDate,
  endDate,
}: {
  agentCpf: string
  dataSourceId?: string
  startDate: string
  endDate: string
}) {
  return query('legalEntityAttendance')
    .select({
      startDate: entity('legalEntityAttendance').property('startDate'),
      count: count(entity('legalEntityAttendance').property('startDate')),
      agentCpf: entity('legalEntityAttendance').property('agentCpf'),
    })
    .join({
      current: entity('legalEntityAttendance').property('legalEntityId'),
      target: entity('legalEntity').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityAttendance').property('id'),
      target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
      join: 'inner',
    })
    .where(
      ...[
        isNull(entity('legalEntity').property('deletedAt')),
        like(entity('legalEntityAttendance').property('agentCpf'), string().value(agentCpf)),
        dataSourceId !== undefined
          ? equals(
              entity(`legalEntityAttendanceDataSource`).property(`dataSourceId`),
              number().value(Number(dataSourceId)),
            )
          : null,
        between(
          entity('legalEntityAttendance').property('startDate'),
          string().value(startDate),
          string().value(endDate),
        ),
      ].filter(truthy),
    )
}
