import createType, { AbstractType } from './type'

export interface BooleanType<NativeGeneric extends boolean | null = boolean>
  extends AbstractType<NativeGeneric> {
  readonly type: 'boolean'
  readonly currentValue?: NativeGeneric
  isRequired(): BooleanType<Exclude<NativeGeneric, null>>
  isOptional(): BooleanType<NativeGeneric | null>
}

export default function boolean(): BooleanType<boolean> {
  return {
    ...createType<BooleanType<boolean>>(),
    type: 'boolean',
  }
}
