import { array, boolean, defineAction, number, object, string } from '~/prix'
import appSebraeNewAttendanceAction from './appSebraeNewAttendance.action.json'

const appSebraeNewAttendanceDefinition = defineAction({
  ...appSebraeNewAttendanceAction,
  input: object({
    id: string(),
    tipoAtendimento: string(),
    tipoInteracao: string(),
    dataAtendimento: string(),
    justificativaRemota: string(),
    atendente: object({
      cpf: string(),
      nome: string(),
    }),
    unidade: object({
      codigo: number(),
      nome: string(),
      endereco: object({
        logradouro: string(),
        numero: string(),
        complemento: string(),
        bairro: string(),
        cidade: string(),
        uf: string(),
        cep: string(),
      }),
    }),
    respondente: object({
      cpf: string(),
      nome: string(),
      dataNascimento: string(),
      email: string(),
      celular: string(),
      celularDdd: string(),
      sexo: string(),
      endereco: object({
        logradouro: string(),
        numero: string(),
        complemento: string(),
        bairro: string(),
        cidade: string(),
        uf: string(),
        cep: string(),
      }),
    }),
    empresa: object({
      cnpj: string(),
      nomeFantasia: string(),
      razaoSocial: string(),
      cnaeFiscalPrimario: string(),
      cnaesFiscaisSecundarios: array(string()),
      matriz: boolean(),
      dataAberturaEmpresa: string(),
      opcaoMei: boolean(),
      opcaoSimples: boolean(),
      porte: number(),
      quantidadeFuncionarios: number(),
      quantidadeSocios: number(),
      situacaoCadastral: string(),
      naturezaJuridicaConcla: number(),
      capitalSocial: number(),
      endereco: object({
        logradouro: string(),
        numero: string(),
        complemento: string(),
        bairro: string(),
        cidade: string(),
        uf: string(),
        cep: string(),
      }),
      contato: object({
        telefone1: string(),
        telefone1Ddd: string(),
        telefone2: string(),
        telefone2Ddd: string(),
        email: string(),
      }),
      geolocalizacao: object({
        placeId: string(),
        latitude: number(),
        longitude: number(),
        raioMetros: number(),
      }),
      dataAtualizacao: string(),
    }),
    dataCadastro: string(),
    dataAtualizacao: string(),
  }),
  output: object({}),
})

export default appSebraeNewAttendanceDefinition
