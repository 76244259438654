import React, { memo } from 'react'
import { Text, View, StyleSheet, Svg, Path, Font } from '@react-pdf/renderer'

Font.register({
  family: 'Lato-700',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVew-FGC_p9dw.ttf',
    },
  ],
})

const StarBlueIcon = () => (
  <Svg width='17' height='17' viewBox='0 0 32 31'>
    <Path
      d='M15.1029 1.82122C15.4695 1.07705 16.5305 1.07705 16.8971 1.82122L20.3708 8.87351C20.5191 9.1745 20.8084 9.38108 21.1412 9.42358L29.0838 10.4378C29.9274 10.5456 30.26 11.5902 29.634 12.1659L23.8661 17.4701C23.6113 17.7044 23.4958 18.0539 23.5608 18.3939L25.0148 26.0009C25.1721 26.8236 24.3087 27.4636 23.5672 27.0737L16.4654 23.3397C16.174 23.1865 15.826 23.1865 15.5346 23.3397L8.43275 27.0737C7.69133 27.4636 6.82789 26.8236 6.98516 26.0009L8.43918 18.3939C8.50417 18.0539 8.38868 17.7044 8.13386 17.4701L2.36596 12.1659C1.73999 11.5902 2.07263 10.5456 2.9162 10.4378L10.8588 9.42358C11.1916 9.38108 11.4809 9.1745 11.6292 8.87351L15.1029 1.82122Z'
      fill='#005EB8'
    />
  </Svg>
)

const StarIcon = () => (
  <Svg width='17' height='17' viewBox='0 0 32 31'>
    <Path
      d='M15.1029 1.82122C15.4695 1.07705 16.5305 1.07705 16.8971 1.82122L20.3708 8.87351C20.5191 9.1745 20.8084 9.38108 21.1412 9.42358L29.0838 10.4378C29.9274 10.5456 30.26 11.5902 29.634 12.1659L23.8661 17.4701C23.6113 17.7044 23.4958 18.0539 23.5608 18.3939L25.0148 26.0009C25.1721 26.8236 24.3087 27.4636 23.5672 27.0737L16.4654 23.3397C16.174 23.1865 15.826 23.1865 15.5346 23.3397L8.43275 27.0737C7.69133 27.4636 6.82789 26.8236 6.98516 26.0009L8.43918 18.3939C8.50417 18.0539 8.38868 17.7044 8.13386 17.4701L2.36596 12.1659C1.73999 11.5902 2.07263 10.5456 2.9162 10.4378L10.8588 9.42358C11.1916 9.38108 11.4809 9.1745 11.6292 8.87351L15.1029 1.82122Z'
      stroke='#B6BFC8'
      strokeWidth='2'
    />
  </Svg>
)

const AlertIcon = () => (
  <Svg width='11' height='11' viewBox='0 0 16 16'>
    <Path
      d='M15.216 13.529L8.882 1.654a1 1 0 0 0-1.765 0L.784 13.529A1 1 0 0 0 1.667 15h12.667a1 1 0 0 0 .882-1.471zM8 13a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm1-3H7V6h2v4z'
      fill='#f1c40f'
    />
  </Svg>
)

const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    height: '47%',
    paddingBottom: 17.5,
  },

  titleContainer: {
    display: 'flex',
    height: 75,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingLeft: 20,
  },

  list: {
    display: 'flex',
    border: '1px solid #B6BFC8',
    borderRadius: '20px',
  },

  listContainer: {
    display: 'flex',
    flexDirection: 'row',
  },

  title: {
    fontSize: '15px',
    color: '#0F438A',
    fontFamily: 'Lato-700',
  },

  subtitleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  icon: {
    marginRight: '3px',
  },

  subtitleText: {
    fontSize: '10px',
    color: '#0F438A',
    alignItems: 'center',
  },

  descriptionColumnContainer: {
    display: 'flex',
    width: '40%',
  },

  starColumnContainer: {
    display: 'flex',
    width: '30%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },

  scoreColumnContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30%',
  },

  listItem: {
    display: 'flex',
    height: 75,
    flexDirection: 'row',
  },

  listItemDescription: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 10,
  },

  listItemValue: {
    display: 'flex',
    width: '55%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  listText: {
    fontSize: '10px',
  },

  stars: {
    paddingTop: '5px',
    marginLeft: '5px',
    marginRight: '5px',
  },
})

function legalEntityGeoprocessingMapDemarcationReportIndicatorPdf(values: any) {
  const { values: data } = values

  return (
    <View style={styles.wrapper}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>Média dos demais indicadores</Text>
        <View style={styles.subtitleWrapper}>
          {data.legalEntityCount <= 3 ? (
            <View style={styles.icon}>
              <AlertIcon />
            </View>
          ) : null}
          <Text style={styles.subtitleText}>
            Média dos demais indicadores baseados em {data.legalEntityCount} empresas:
          </Text>
        </View>
      </View>
      <View style={styles.list}>
        {data.values?.map(
          (
            item: {
              name: string
              count: number
            },
            index: number,
          ) => (
            <View style={styles.listContainer} key={index}>
              <View style={styles.descriptionColumnContainer}>
                <View style={styles.listItem}>
                  <View style={styles.listItemDescription}>
                    <Text style={styles.listText}>{item.name}:</Text>
                  </View>
                </View>
              </View>

              <View style={styles.starColumnContainer}>
                <View style={styles.stars}>
                  {item.count >= 1 ? <StarBlueIcon /> : <StarIcon />}
                </View>
                <View style={styles.stars}>
                  {item.count >= 2 ? <StarBlueIcon /> : <StarIcon />}
                </View>
                <View style={styles.stars}>
                  {item.count >= 3 ? <StarBlueIcon /> : <StarIcon />}
                </View>
                <View style={styles.stars}>
                  {item.count >= 4 ? <StarBlueIcon /> : <StarIcon />}
                </View>
                <View style={styles.stars}>
                  {item.count === 5 ? <StarBlueIcon /> : <StarIcon />}
                </View>
              </View>

              <View style={styles.scoreColumnContainer}>
                <Text style={styles.listText}>
                  Pontuação: {Number(item?.count).toFixed(2).replace('.', ',')} de 5
                </Text>
              </View>
            </View>
          ),
        )}
      </View>
    </View>
  )
}

export default memo(legalEntityGeoprocessingMapDemarcationReportIndicatorPdf)
