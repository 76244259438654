import { defineAction, object, string } from '~/prix'
import legalEntityNewlyOpenedAction from './legalEntityNewlyOpened.action.json'

const legalEntityNewlyOpenedActionDefinition = defineAction({
  ...legalEntityNewlyOpenedAction,
  input: object({
    cpf: string(),
  }),
  output: object({}),
})

export default legalEntityNewlyOpenedActionDefinition
