import { defineAction, object } from '~/prix'
import refreshPgRoutingNetworkAction from './refreshPgRoutingNetwork.action.json'

const refreshPgRoutingNetworkDefinition = defineAction({
  ...refreshPgRoutingNetworkAction,
  input: object({}),
  output: object({}),
})

export default refreshPgRoutingNetworkDefinition
