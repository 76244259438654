import { defineAction, object, string } from '~/prix'
import geocodeAddressAction from './geocodeAddress.action.json'

// city, country, neighborhood, number, state, street

const geocodeAddressDefinition = defineAction({
  ...geocodeAddressAction,
  input: object({
    city: string().label('Nome da cidade'),
    country: string().label('Nome do país'),
    neighborhood: string().label('Nome do bairro'),
    number: string().label('Número da casa'),
    state: string().label('Nome ou abreviatura de estado'),
    street: string().label('Nome da rua'),
  }),
  output: object({}),
})

export default geocodeAddressDefinition
