import { defineEntity, entity, foreign, object, string } from '~/prix'
import { multiPolygon, point } from '~/prix/types/geoJson'

const macroRegionEntity = defineEntity({
  key: 'macroRegion',
  title: 'Macrorregião',
  sources: ['olap', 'oltp'],
  schema: object({
    id: string().label('ID', 'Identificador', 'Código'),
    ibgeCode: string().label('Código ibge da macrorregião'),
    name: string().label('Nome', 'Macrorregião'),
    center: point().label('Centro'),
    boundary: multiPolygon().label('Polígono'),
    lowerQualityBoundary: multiPolygon().label('Polígono de baixa qualidade'),
    countryId: foreign(entity('country').property('id')),
  }),
})

export default macroRegionEntity
