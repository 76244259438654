import React, { useImperativeHandle, useMemo, useRef } from 'react'
import { GoogleMap, GoogleMapProps } from '@react-google-maps/api'
import useGoogleMaps from '../../hooks/googleMaps'
import useAPI from '../../hooks/api'
import useItems from '../../hooks/items'
import userAuthStateQuery from '~/packages/user/userAuthState.query'
import { useParams } from 'react-router'

export interface MapsProps extends GoogleMapProps {}

const containerStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
}

const GoogleMaps: React.ForwardRefRenderFunction<google.maps.Map | null, MapsProps> = (
  { mapContainerStyle = containerStyle, mapTypeId, center, zoom = 4, children, ...props },
  forwardedRef,
) => {
  const googleMapRef = useRef<GoogleMap>(null)
  const { google } = useGoogleMaps()
  const params = useParams()

  const { context, isLoading } = useAPI()
  const hasUserRoles = context.user
    ? !context.user?.roles.some(
        item => item === 'systemAdministrator' || item === 'administrator' || item === 'manager',
      )
    : false

  const userGeoStateResult = useItems(
    () =>
      context.user?.stateAbbreviation
        ? userAuthStateQuery(context.user?.stateAbbreviation)
        : (null as never),
    [params.id, context.user, isLoading],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: isLoading === false,
    },
  )

  const brazilCenter = useMemo(
    () => (google ? new google.maps.LatLng(-10.78085882890515, -53.092482362387045) : undefined),
    [google],
  )

  const stateCenter = useMemo(() => {
    if (hasUserRoles && userGeoStateResult.items?.length) {
      return {
        lat: userGeoStateResult.items[0]?.center.coordinates[1],
        lng: userGeoStateResult.items[0]?.center.coordinates[0],
      }
    } else {
      return undefined
    }
  }, [userGeoStateResult.isLoading])

  const options = useMemo(
    () =>
      google && mapTypeId === 'satellite'
        ? {
            mapTypeId: google.maps.MapTypeId.SATELLITE,
            mapTypeControl: false,
            ...props.options,
          }
        : google && mapTypeId === 'map'
        ? {
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            mapTypeControl: false,
            ...props.options,
          }
        : props.options,
    [google, mapTypeId, props],
  )
  useImperativeHandle<google.maps.Map | null, google.maps.Map | null>(
    forwardedRef,
    () => googleMapRef.current?.state.map ?? null,
    [googleMapRef.current?.state.map],
  )

  if (!google) {
    return null
  }

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={center ? center : stateCenter ? stateCenter : brazilCenter ? brazilCenter : undefined}
      zoom={zoom}
      {...props}
      ref={googleMapRef}
      options={options}
    >
      {children}
    </GoogleMap>
  )
}

export default React.forwardRef(GoogleMaps)
