import { entity, equals, number, query } from '~/prix'
import asGeoJson from '~/prix/functions/asGeoJson'

export default function countriesQuery() {
  return query('country')
    .select({
      id: entity('country').property('id'),
      name: entity('country').property('name'),
      boundary: asGeoJson(entity('country').property('boundary')),
      center: asGeoJson(entity('country').property('center')),
    })
    .where(equals(entity('country').property('iso'), number().value(76)))
    .limit(10000)
}
