import { InfoWindow, Marker } from '@react-google-maps/api'
import React, { Fragment, memo } from 'react'
import styled from 'styled-components'
import { colors } from '~/design'
import GeoJsonMultiPolygonComponent from '~/prix/react/components/map/geoJsonMultiPolygon'
import { InfoWindowWrapper } from '../map/legalEntityGeoItem.component'
import { GeoJsonMultiPolygon } from '~/prix/types/geoJson'

export interface Item {
  id: number
  geo: string
  geoName: string
  center: google.maps.LatLng | null
  multiPolygon?: GeoJsonMultiPolygon | null
  searchRank: number
  subtitle: string
}

interface LegalEntityGeoprocessingMapDemarcationGeoItemProps {
  items: Item[]
  overItem: any
  onMouseOver: (item: any) => void
  onMouseOut: (item: any) => void
}

export const MobileButtonWrapper = styled.div`
  display: flex;
  margin-top: 10px;

  button {
    width: 100%;
    height: 100%;
    font-size: 14px;
  }
`

function LegalEntityGeoprocessingMapDemarcationGeoItem({
  items,
  onMouseOver,
  onMouseOut,
  overItem,
}: LegalEntityGeoprocessingMapDemarcationGeoItemProps) {

  const infoWindow = React.useCallback(() => {
    if (overItem?.center) {
      return (
        <InfoWindow
          position={new google.maps.LatLng(overItem.center.coordinates[1], overItem.center.coordinates[0])}
          options={{ disableAutoPan: true, pixelOffset: new google.maps.Size(0, -5) }}        >
          <InfoWindowWrapper>
            <span>
              {overItem.geoName}
            </span>
          </InfoWindowWrapper>
        </InfoWindow>
      )
    }
    return
  }, [overItem])

  return (
    <Fragment>
      {items?.map((item: any) => (
        <div key={item.id}>
          {
            item?.multiPolygon ? (
              <GeoJsonMultiPolygonComponent
                geoJson={item.multiPolygon}
                onMouseOver={() => onMouseOver(item)}
                onMouseOut={() => onMouseOut(item)}
                options={{
                  fillColor: colors.sebraeBlue,
                  fillOpacity: 0.2,
                  strokeColor: colors.sebraeBlue,
                  strokeWeight: 1.75,
                  zIndex: 1,
                }}
              />
            ) : null}

          {item?.center && item?.geo !== 'neighborhood' ? infoWindow() : null}
        </div>
      ))}

      {items?.map((item: any) => (
        item.center && item.geo === 'neighborhood' && item.multiPolygon === null ? (
          <div key={item.id}>
            <Marker
              position={new google.maps.LatLng(item.center.coordinates[1], item.center.coordinates[0])}
              onMouseOver={() => onMouseOver(item)}
              onMouseOut={() => onMouseOut(item)}
              icon={{
                path: google.maps.SymbolPath.CIRCLE,
                scale: 12,
                fillColor: colors.sebraeBlue,
                fillOpacity: 1,
                strokeWeight: 3,
                strokeColor: '#fff',
              }}
            />
            {item?.center && item?.geo === 'neighborhood' ? infoWindow() : null}
          </div>
        )
          : null
      ))}
    </Fragment >
  )
}

export default memo(LegalEntityGeoprocessingMapDemarcationGeoItem)
