import { defineEntity, entity, foreign, object, string, number, boolean, dateTime } from '~/prix'

const reportEntity = defineEntity({
  key: 'report',
  title: 'Relatório',
  alternativeTitles: [],
  sources: ['olap', 'oltp'],
  schema: object({
    id: string().label('ID', 'Identificador', 'Código'),
    key: string().label('Chave única'),
    title: string().label('Título'),
    description: string().label('Descrição'),
    imageUrl: string().label('URL da Imagem'),
    combinations: string().label('Combinações'),
    isPublic: boolean().label('Publica'),
    createdAt: dateTime().label('Data de Criação'),
    updatedAt: dateTime().label('Data de Atualização'),
  }),
})

export default reportEntity
