import { defineEntity, object, string } from '~/prix'

const studentEad = defineEntity({
  key: 'aluno',
  title: 'EaD aluno',
  sources: ['oltp'],
  entitySchema: 'ead',
  schema: object({
    cpf: string(),
    nome: string(),
    telefone: string(),
    sexo: string(),
    dataNascimento: string(),
    escolaridade: string(),
    dataCriacao: string(),
    dataAtualizacao: string(),
    uf: string(),
  }),
})

export default studentEad
