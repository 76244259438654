import React, { useState } from 'react'
import useAPI from '~/prix/react/hooks/api'
import rolesAndNames from '~/utils/shared/roles'

export default function UserInformation() {
  const { context, isLoading } = useAPI()

  const header = (
    <thead>
      <tr>
        <th colSpan={2}>Usuário</th>
      </tr>
    </thead>
  )

  if (isLoading) {
    return (
      <>
        {header}
        <tbody>
          <tr>
            <td colSpan={2}>Carregando...</td>
          </tr>
        </tbody>
      </>
    )
  }

  return (
    <>
      {header}
      <tbody>
        <tr>
          <td>Nome</td>
          <td>{context.user?.name}</td>
        </tr>
        <tr>
          <td>E-mail</td>
          <td>{context.user?.email}</td>
        </tr>
        <tr>
          <td>CPF</td>
          <td>{context.user?.cpf}</td>
        </tr>
        <tr>
          <td>UF</td>
          <td>{context.user?.stateAbbreviation}</td>
        </tr>
        <tr>
          <td>E-mail verificado</td>
          <td>{context.user?.isEmailVerified ? 'Sim' : 'Não'}</td>
        </tr>
        <tr>
          <td>Papéis</td>
          <td>
            {context.user?.roles.map(role => rolesAndNames[role] ?? 'Desconhecido').join(', ')}
          </td>
        </tr>
      </tbody>
    </>
  )
}
