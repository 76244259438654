import React, { useMemo } from 'react'
import Select from 'react-select'
import Wrapper from './wrapper'
import { colors } from '~/design'
import { EnumeratedType } from '~/prix/types/enumerated'
import { FieldProps } from '~/prix/react/hooks/form'

interface OptionGroupBaseSelect {
  isDisabled: boolean
  isFocused: boolean
  isSelected: boolean
}

export default function EnumeratedField<Schema extends EnumeratedType>({
  meta: {
    isDisabled = false,
    placeholder = 'Selecione...',
    label,
    isRequired = false,
  } = {},
  id,
  value,
  onDirectChange,
  isLoading,
  fieldSchema: { keysAndLabels },
  onBlur,
  isMultiple = false,
}: FieldProps<Schema> & { children?: React.ReactNode, isMultiple?: boolean }) {
  const inputOptions = useMemo(
    () =>
      Object.keys(keysAndLabels).map(key => ({
        value: key,
        label: keysAndLabels[key],
      })),
    [keysAndLabels],
  )

  const finalValue = isMultiple
    ? value
      ? value.map((key: string) => ({ value: key, label: keysAndLabels[key as keyof typeof keysAndLabels] }))
      : []
    : value
      ? { value, label: keysAndLabels[value as keyof typeof keysAndLabels] }
      : null

  return (
    <Wrapper
      isRequired={isRequired}
      label={label}
      id={id}
    >
      <Select
        isClearable={!isRequired}
        isMulti={isMultiple}
        value={finalValue}
        onBlur={onBlur}
        styles={{
          container: base => ({
            ...base,
            flex: 1,
            minWidth: '100%',
            maxWidth: '200px',
            pointerEvents: 'auto',

            '>div': {
              cursor: isLoading === true ? 'wait' : isDisabled ? 'not-allowed' : 'pointer',
              backgroundColor: isDisabled ? '#f1f1f1' : '#fff',

              ':hover': {
                borderColor: isDisabled ? 'transparent' : 'hsl(0, 0%, 70%)',
              },

              'svg': {
                display: isDisabled ? 'none' : 'inline-block',
              }
            },
          }),

          option: (styles: any, { isDisabled, isFocused, isSelected }: OptionGroupBaseSelect) => {
            return {
              ...styles,
              backgroundColor: isDisabled
                ? null
                : isSelected
                  ? colors.sebraeBlue
                  : isFocused
                    ? colors.lightBlue
                    : null,
              color: isDisabled
                ? '#ccc'
                : isSelected
                  ? '#fff'
                  : isFocused
                    ? 'rgba(0, 0, 0, 0.75)'
                    : styles.color,

              ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled && isSelected ? colors.sebraeBlue : colors.sebraeBlue,
                color: isDisabled && isSelected ? '#fff' : '#fff',
              },
            }
          },

          placeholder: base => ({
            ...base,
            display: '-webkit-box',
            'WebkitLineClamp': '2',
            'WebkitBoxOrient': 'vertical',
            textOverflow: 'ellipsis',
            overflowY: 'hidden',
          }),
        }}
        onChange={result => {
          if (!onDirectChange) return
          if (isMultiple) {
            onDirectChange(result && result.length ? result.map((item: any) => item.value) : null)
          } else {
            onDirectChange(result ? result.value : null)
          }
        }}
        options={inputOptions}
        placeholder={placeholder}
        noOptionsMessage={() => 'Sem opções.'}
        loadingMessage={() => 'Carregando...'}
        inputId={id}
        isDisabled={isDisabled}
      />
    </Wrapper>
  )
}
