import { defineAction, object, boolean } from '~/prix'
import fetchAllRoadsAction from './fetchAllRoads.action.json'

const fetchAllRoadsDefinition = defineAction({
  ...fetchAllRoadsAction,
  input: object({
    update: boolean()
      .default(() => false)
      .label('Atualizar registros'),
  }),
  output: object({}),
})

export default fetchAllRoadsDefinition
