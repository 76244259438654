import array, { ArrayType } from './array'
import number, { NumberType } from './number'
import object, { ObjectType, ShapeBase } from './object'
import string, { StringType } from './string'
import createType, { InferNative } from './type'

// Point
const defaultGeoJsonPoint = object({
  type: string().value('Point'),
  coordinates: array(number()),
})

export type GeoJsonPoint = InferNative<typeof defaultGeoJsonPoint>

export interface GeoJsonPointShape extends ShapeBase {
  type: StringType<'Point'>
  coordinates: ArrayType<number[]>
}

// Polygon
const defaultGeoJsonPolygon = object({
  type: string().value('Polygon'),
  coordinates: array(array(array(number()))),
})

export type GeoJsonPolygon = InferNative<typeof defaultGeoJsonPolygon>

export interface GeoJsonPolygonShape extends ShapeBase {
  type: StringType<'Polygon'>
  coordinates: ArrayType<number[][][]>
}

// MultiPolygon
const defaultGeoJsonMultiPolygon = object({
  type: string().value('MultiPolygon'),
  coordinates: array(array(array(array(number())))),
})

export type GeoJsonMultiPolygon = InferNative<typeof defaultGeoJsonMultiPolygon>

export interface GeoJsonMultiPolygonShape extends ShapeBase {
  type: StringType<'MultiPolygon'>
  coordinates: ArrayType<number[][][][]>
}

// LineString
const defaultGeoJsonLineString = object({
  type: string().value('LineString'),
  coordinates: array(array(number())),
})

export type GeoJsonLineString = InferNative<typeof defaultGeoJsonLineString>

export interface GeoJsonLineStringShape extends ShapeBase {
  type: StringType<'LineString'>
  coordinates: ArrayType<number[][]>
}

// MultiLineString
const defaultGeoJsonMultiLineString = object({
  type: string().value('MultiLineString'),
  coordinates: array(array(array(number()))),
})

export type GeoJsonMultiLineString = InferNative<typeof defaultGeoJsonMultiLineString>

export interface GeoJsonMultiLineStringShape extends ShapeBase {
  type: StringType<'MultiLineString'>
  coordinates: ArrayType<number[][][]>
}

export interface GeoJsonType<
  NativeShapeGeneric extends
  | GeoJsonPointShape
  | GeoJsonPolygonShape
  | GeoJsonMultiPolygonShape
  | GeoJsonLineStringShape
  | GeoJsonMultiLineStringShape
  | null =
  | GeoJsonPointShape
  | GeoJsonPolygonShape
  | GeoJsonMultiPolygonShape
  | GeoJsonLineStringShape
  | GeoJsonMultiLineStringShape,
> extends ObjectType<NativeShapeGeneric> {
  readonly type: 'object'
  readonly subType: 'geoJson'
  readonly shape: NativeShapeGeneric
  readonly currentValue?: NativeShapeGeneric
  isRequired(): GeoJsonType<Exclude<NativeShapeGeneric, null>>
  isOptional(): GeoJsonType<NativeShapeGeneric | null>
}

export function point<
  NativeShapeGeneric extends GeoJsonPointShape,
>(): GeoJsonType<NativeShapeGeneric> {
  return {
    ...createType<GeoJsonType<NativeShapeGeneric>>(),
    type: 'object',
    subType: 'geoJson',
    shape: defaultGeoJsonPoint as unknown as NativeShapeGeneric,
  }
}

export function polygon<
  NativeShapeGeneric extends GeoJsonPolygonShape,
>(): GeoJsonType<NativeShapeGeneric> {
  return {
    ...createType<GeoJsonType<NativeShapeGeneric>>(),
    type: 'object',
    subType: 'geoJson',
    shape: defaultGeoJsonPolygon as unknown as NativeShapeGeneric,
  }
}

export function multiPolygon<
  NativeShapeGeneric extends GeoJsonMultiPolygonShape,
>(): GeoJsonType<NativeShapeGeneric> {
  return {
    ...createType<GeoJsonType<NativeShapeGeneric>>(),
    type: 'object',
    subType: 'geoJson',
    shape: defaultGeoJsonMultiPolygon as unknown as NativeShapeGeneric,
  }
}

export function lineString<
  NativeShapeGeneric extends GeoJsonLineStringShape,
>(): GeoJsonType<NativeShapeGeneric> {
  return {
    ...createType<GeoJsonType<NativeShapeGeneric>>(),
    type: 'object',
    subType: 'geoJson',
    shape: defaultGeoJsonLineString as unknown as NativeShapeGeneric,
  }
}

export function multiLineString<
  NativeShapeGeneric extends GeoJsonMultiLineStringShape,
>(): GeoJsonType<NativeShapeGeneric> {
  return {
    ...createType<GeoJsonType<NativeShapeGeneric>>(),
    type: 'object',
    subType: 'geoJson',
    shape: defaultGeoJsonMultiLineString as unknown as NativeShapeGeneric,
  }
}
