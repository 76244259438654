import { defineAction, object } from '~/prix'
import seedLegalEntityCnaesAction from './seedLegalEntityCnaes.action.json' assert { type: 'json' }

const seedLegalEntityCnaesDefinition = defineAction({
  ...seedLegalEntityCnaesAction,
  input: object({}),
  output: object({}),
})

export default seedLegalEntityCnaesDefinition
