import { useMemo, useState } from 'react'
import useActionFunction from '~/prix/react/hooks/actionFunction'
import radarDefinition from './fetchFromDatabase/radar.definition'
import { AppError, errors } from '~/prix'
import { point } from '@turf/helpers'
import circle from '@turf/circle'
import bearing from '@turf/bearing'
import destination from '@turf/destination'
import distance from '@turf/distance'
import booleanWithin from '@turf/boolean-within'
import useAPI from '~/prix/react/hooks/api'
import { GeoJsonPoint } from '~/prix/types/geoJson'

interface RadarOptions {
  latitude: number
  longitude: number
  isEnabled: boolean
  errorPermission: any
}

export type LegalEntityData = {
  legalEntityId: number
  cnpj: string
  corporateName: string
  tradeName: string
  country: string
  state: string
  city: string
  neighborhood: string
  street: string
  complement: string
  postalCode: string
  filter: 'radius' | 'sameStreet' | 'sameNeighborhood'
  economicActivity: string
  pointOnStreet: GeoJsonPoint
  distanceMeters: number
  withinProximities: boolean
}

interface ChunkData {
  entities: LegalEntityData[]
  center: [number, number]
}

export default function useAttendanceRadar({
  latitude,
  longitude,
  isEnabled,
  errorPermission,
}: RadarOptions) {
  const radius = 100
  const cacheRadius = 350

  const { callAction: callActionAttendanceRadar } = useActionFunction(radarDefinition)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [currentStreetIds, setCurrentStreetIds] = useState<string[]>([])
  const [currentNeighborhoodId, setCurrentNeighborhoodId] = useState<string>('')

  const [cachedChunks, setCachedChunks] = useState<ChunkData[]>([])

  const { context } = useAPI()

  const [visibleEntities, setVisibleEntities] = useState<{
    sameRoad: LegalEntityData[]
    sameNeighborhood: LegalEntityData[]
    withinRadius: LegalEntityData[]
  }>({
    sameRoad: [],
    sameNeighborhood: [],
    withinRadius: [],
  })

  /**
   * Resolve os perímetros de seleção de entidades levando uma localização como parâmetro para o centro desse perímetro.
   * @param chunkCenter Localização do centro da área que deve ser recuperada.
   * @returns Dados do chunk, contendo as entidades encontradas na área e o polígono da área considerada.
   */
  function chunkCache(chunkCenter: [number, number]) {
    const chunkDetectionArea = circle(chunkCenter, 10, { steps: 10, units: 'meters' })

    const cachedChunkIndex = cachedChunks.findIndex(cachedChunk =>
      booleanWithin(point(cachedChunk.center), chunkDetectionArea),
    )

    if (cachedChunkIndex !== -1) {
      return new Promise(resolve => {
        const getCachedChunk = cachedChunks[cachedChunkIndex]

        cachedChunks.splice(cachedChunkIndex, 1)
        cachedChunks.push(getCachedChunk)

        resolve(getCachedChunk)
      })
    } else {
      const databaseResult = callActionAttendanceRadar({
        latitude: chunkCenter[1] as number,
        longitude: chunkCenter[0],
      }).resultPromise

      return databaseResult.then(item => {
        const { entities, bounds, getStateAbbreviationByCoordinates } = item
        const chunkBoundary = getChunkBoundary(bounds)

        const result = {
          chunk: chunkBoundary,
          center: chunkCenter,
          neighborhoodId: currentNeighborhoodId,
          streetIds: currentStreetIds,
          entities: entities as LegalEntityData[],
          getStateAbbreviationByCoordinates,
        }

        if (
          getStateAbbreviationByCoordinates.stateAbbreviationByCoordinates !==
          context.user?.stateAbbreviation
        ) {
          errorPermission(errors.permission('Coordenadas fora dos limites do estado'))
        }
        cachedChunks.push(result)
        setCachedChunks(cachedChunks.slice(-4))
        return result
      })
    }
  }

  /**
   * Resolve os IDs de bairro e ruas de acordo com a localização do usuário es entidades próximas.
   * @param entitiesWithinProximities Entidades visíveis ao usuário ou que devem ser levadas em consideração para determinar bairro ou rua.
   * @returns Bairro e Ruas atuais de usuário.
   */
  function streetNeighborhoodCache(entitiesWithinProximities: LegalEntityData[]) {
    const streetIds = streetCache(entitiesWithinProximities)
    const neighborhoodId = neighborhoodCache(entitiesWithinProximities)
    return { streetIds, neighborhoodId }
  }

  /**
   * Resolve os IDs das ruas mais próximas ao usuário.
   * @param nearestEntity Lista de entidades próximas ao usuário que devem ser levadas em consideração para determinar a rua.
   * @returns Lista de IDs de ruas de onde o usuário se encontra.
   */
  function streetCache(entitiesWithinProximities: LegalEntityData[]) {
    const userPosition = [longitude, latitude]

    const roadDetectionArea = circle(userPosition, 30, { steps: 10, units: 'meters' })
    const nearestEntities = entitiesWithinProximities.filter(item =>
      booleanWithin(point((item.pointOnStreet as GeoJsonPoint).coordinates), roadDetectionArea),
    )

    const streetIds: string[] = [...new Set(nearestEntities.map(item => item.street))]
    if (
      streetIds.length === 0 ||
      JSON.stringify(currentStreetIds.sort()) === JSON.stringify(streetIds.sort())
    ) {
      return currentStreetIds
    } else {
      setCurrentStreetIds(streetIds)
      return streetIds
    }
  }

  /**
   * Resolve o ID de bairro atual de onde o usuário se encontra.
   * @param entitiesWithinProximities Lista de entidades visíveis pelo usuário.
   * @returns Retorna o ID do bairro da empresa mais próxima ao usuário
   */
  function neighborhoodCache(entitiesWithinProximities: LegalEntityData[]): string {
    const neighborhoodId: string = entitiesWithinProximities
      .filter(item => item.neighborhood)
      .sort((a, b) => a.distanceMeters - b.distanceMeters)[0]?.neighborhood

    if (neighborhoodId === undefined || neighborhoodId === currentNeighborhoodId) {
      return currentNeighborhoodId
    } else {
      setCurrentNeighborhoodId(neighborhoodId)
      return neighborhoodId
    }
  }

  useMemo(() => {
    if (isEnabled) {
      setIsLoading(true)

      let chunkCenter = cachedChunks[cachedChunks.length - 1]?.center || [longitude, latitude]
      const originDistance = distance([longitude, latitude], chunkCenter, { units: 'meters' })

      if (originDistance >= cacheRadius - radius / 2) {
        const userDirectionAngle = roundBearing(bearing(chunkCenter, [longitude, latitude]))

        const next_point = destination(chunkCenter, cacheRadius * 2, userDirectionAngle, {
          units: 'meters',
        })

        chunkCenter = next_point.geometry.coordinates as [number, number]
      }

      if (originDistance >= cacheRadius * 3 - radius / 2) {
        cachedChunks.splice(0, 4)
        chunkCenter = [longitude, latitude]
      }

      chunkCache(chunkCenter).then(() => {
        const entityDetectionArea = circle([longitude, latitude], radius, {
          steps: 10,
          units: 'meters',
        })

        const cachedEntities = cachedChunks.map(item => item.entities).flat()

        cachedEntities.forEach(item => {
          const entityLocation = point((item.pointOnStreet as GeoJsonPoint).coordinates)

          item.withinProximities = booleanWithin(entityLocation, entityDetectionArea)
          item.distanceMeters = distance([longitude, latitude], entityLocation, {
            units: 'meters',
          })
        })

        const visibleEntities = cachedEntities.filter(item => item.withinProximities)

        const { neighborhoodId, streetIds } = streetNeighborhoodCache(visibleEntities)

        setVisibleEntities(filterEntities(cachedEntities, neighborhoodId, streetIds))

        setIsLoading(false)
      })
    }
  }, [latitude, longitude, isEnabled, cachedChunks, currentNeighborhoodId, currentStreetIds])

  return {
    isLoading,
    sameRoad: visibleEntities.sameRoad,
    sameNeighborhood: visibleEntities.sameNeighborhood,
    withinRadius: visibleEntities.withinRadius,
  }
}

/**
 * Arredonda o ângulo de usuário em um correspondente de 90 graus de acordo com a direção do bearing.
 * @param bearing Bearing ou ângulo original entre o ponto inicial do usuário ou centro da área cacheada e a localização do usuário.
 * @returns Retorna o ângulo correspondente em uma curva de 90 graus.
 */
function roundBearing(bearing: number) {
  //East
  if (bearing > 45 && bearing < 135) {
    return 90
  }
  //West
  if (bearing > -135 && bearing < -45) {
    return -90
  }
  //South
  if (bearing < -135 || bearing > 135) {
    return -180
  }
  //North
  if (bearing > -45 || bearing < 45) {
    return 0
  }
  throw new Error('Incapaz de definir direção.')
}

/**
 * Converte o objeto ST_ASGEOJSON em paths compatíveis com o turf e google maps.
 * @param rawStAsGeoJson Raw de retorno do banco de dados do objeto ST_ASGEOJSON
 * @returns objeto compatível com o turf e google maps.
 */
function getChunkBoundary(rawStAsGeoJson: { coordinates: [[]] }) {
  const geoJson = rawStAsGeoJson
  const paths = geoJson.coordinates[0].map(item => {
    return {
      lng: item[0],
      lat: item[1],
    }
  })
  return paths
}

/**
 * Filtra as entidades as categorizando entre as que estão no mesmo bairro, mesma rua ou no raio de visão de usuário.
 * @param entities Lista de entidades que devem ser filtradas.
 * @param neighborhoodId Id do bairro atual do usuário.
 * @param streetIds Lista de id de ruas onde o usuário está localizado, em array para os casos de esquinas ou encruzilhadas.
 * @returns Um objeto contendo listas para cada categoria. {sameNeighborhood, sameRoad, withinRadius}
 */
function filterEntities(entities: LegalEntityData[], neighborhoodId: string, streetIds: string[]) {
  if (entities) {
    entities.sort((a, b) => a.distanceMeters - b.distanceMeters)
    const nearEntities = entities.filter(item => item.withinProximities)

    const sameNeighborhood = entities.filter(item => item.neighborhood === neighborhoodId)

    const sameRoad = entities.filter(item => streetIds.includes(item.street))

    return { sameNeighborhood, sameRoad, withinRadius: nearEntities }
  }
  throw new Error('Objeto de entities vazio.')
}
