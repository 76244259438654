import { defineAction, object, number } from '~/prix'
import reverseGeocodingAction from './reverseGeocoding.action.json'

const reverseGeocodingDefinition = defineAction({
  ...reverseGeocodingAction,
  input: object({
    latitude: number(),
    longitude: number(),
  }),
  output: object({}),
})

export default reverseGeocodingDefinition
