import { defineAction, object, boolean } from '~/prix'
import fetchStreetsAndWays from './fetchAllStreetsAndWays.action.json' assert { type: 'json' }

const fetchStreetsAndWaysDefinition = defineAction({
  ...fetchStreetsAndWays,
  input: object({
    update: boolean()
      .default(() => false)
      .label('Atualizar registros'),
  }),
  output: object({}),
})

export default fetchStreetsAndWaysDefinition
