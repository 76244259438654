import {
  defineAction,
  object,
  string,
} from '~/prix'
import searchAgentIntersections from './searchAgentIntersections.action.json'

const searchAgentIntersectionsDefinition = defineAction({
  ...searchAgentIntersections,
  input: object({
    agentCpf: string(),
  }),
  output: object({}),
})

export default searchAgentIntersectionsDefinition
