import { entity, equals, query, string } from '~/prix'
import asGeoJson from '~/prix/functions/asGeoJson'

export default function userAuthStateQuery(stateAbbreviation: string) {
  return query('state')
    .select({
      stateId: entity('state').property('id'),
      stateName: entity('state').property('name'),
      stateAbbreviation: entity('state').property('abbreviation'),
      center: asGeoJson(entity('state').property('center')),
    })
    .where(equals(entity('state').property('abbreviation'), string().value(stateAbbreviation)))
    .limit(1)
}
