import React, { useCallback, useMemo } from 'react'
import { colors } from '~/design'
import { capitalizeWords } from '~/prix'
import { BottomAxis, BottomAxisProps } from '~/prix/react/components/lineChart/bottomAxis'
import { Series } from '~/prix/react/components/lineChart/lineChart'
import LineChartWithScroll from '~/prix/react/components/lineChart/lineChartWithScroll'
import { Component, ConditionalPolynomialComponent, TimeSeriesItem } from './timeSeries.entity'

type Representation = 'year' | 'month' | 'weekday' | 'holiday' | 'dayOfMonth' | 'dayOfYear'
export interface TimeSeriesCalendarEffectBottomChartProps {
  items: TimeSeriesItem[]
  representation: Representation
  height: number
  maxSeries?: number
  aggregation?: 'sum' | 'mean' | 'median' | 'min' | 'max'
  components: Component[]
}

const series: Series[] = [
  {
    label: 'Ocorrências estimadas',
    marker: {
      color: colors.nausPurple,
      radius: 5,
    },
    line: {
      color: colors.nausPurple,
      width: 3,
      curve: 'curveMonotoneX',
    },
  },
]
const months = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
]

const weekdays = [
  'Domingo',
  'Segunda-feira',
  'Terça-feira',
  'Quarta-feira',
  'Quinta-feira',
  'Sexta-feira',
  'Sábado',
]

function TimeSeriesCalendarEffectBottomChart({
  representation,
  // items,
  height,
  // maxSeries = 10,
  // aggregation = 'sum',
  components,
}: TimeSeriesCalendarEffectBottomChartProps) {
  const [labels, chartItems] = useMemo(() => {
    const valuesForComponents = (components as ConditionalPolynomialComponent[]).map(component => {
      const values = Object.entries(component.coefficients).map(([name, coefficients]) => {
        const x = component.counts[name]
        const y = coefficients.reduce(
          (acc, coefficient, index) => acc + coefficient * x ** index,
          0,
        )
        return [name, y] as const
      })
      return values
    })

    const results = valuesForComponents.reduce((acc, entries) => {
      entries.forEach(([name, value]) => {
        if (!acc[name]) {
          acc[name] = 0
        }
        acc[name] += value
      })

      return acc
    }, {} as Record<string, number>)

    const sortedResults = Object.entries(results).sort(([aName], [bName]) => {
      if (representation !== 'holiday') {
        return Number(aName) - Number(bName)
      }

      const aDate =
        (components as ConditionalPolynomialComponent[]).find(component => component.start[aName])
          ?.start[aName] ?? null
      const bDate =
        (components as ConditionalPolynomialComponent[]).find(component => component.start[bName])
          ?.start[bName] ?? null
      if (!aDate || !bDate) {
        return 0
      }

      const aDateWithoutYear = aDate.replace(/^\d{4}-/, '')
      const bDateWithoutYear = bDate.replace(/^\d{4}-/, '')

      return aDateWithoutYear.localeCompare(bDateWithoutYear)
    })

    return [
      sortedResults.map(([name]) => {
        if (representation === 'month') {
          return months[Number(name) - 1]
        }
        if (representation === 'weekday') {
          return weekdays[Number(name)]
        }
        return name
      }),
      sortedResults.map(([_, value]) => ({ values: [value] })),
    ]
  }, [components])

  const renderBottomAxis = useCallback(
    (props: BottomAxisProps) => {
      return (
        <BottomAxis
          {...props}
          getLabel={(index, itemWidth) => {
            const capitalized = capitalizeWords(labels[index])
            if (itemWidth >= 70) {
              if (capitalized.length > 10) {
                return capitalized.substring(0, 10) + '...'
              }
              return capitalized
            }
            const result = capitalized.replace('D. ', '').substring(0, 3)
            return result
          }}
        />
      )
    },
    [labels],
  )

  return (
    <LineChartWithScroll
      height={height}
      items={chartItems}
      itemWidth={representation === 'holiday' ? 100 : undefined}
      BottomAxis={renderBottomAxis}
      series={series}
      topSpacingForFixedAxis={30}
    />
  )
}

export default React.memo(TimeSeriesCalendarEffectBottomChart)
