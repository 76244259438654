import { entity, equals, query, string } from '~/prix'
import { GeoLegalEntitiesPossibilities } from '../legalEntityGeoprocessingMapLevels.data'

export default function breadcrumbQuery(by: GeoLegalEntitiesPossibilities, id: string) {
  switch (by) {
    case 'country':
      return query('country')
        .select({
          level: string().value('country'),
          countryId: entity('country').property('id'),
          countryName: entity('country').property('name'),
        })
        .where(equals(entity('country').property('id'), string().value(id)))
        .limit(1)

    case 'macroRegion':
      return query('macroRegion')
        .select({
          level: string().value('macroRegion'),
          macroRegionId: entity('macroRegion').property('id'),
          macroRegionName: entity('macroRegion').property('name'),
        })
        .where(equals(entity('macroRegion').property('id'), string().value(id)))
        .limit(1)

    case 'state':
      return query('state')
        .select({
          level: string().value('state'),
          macroRegionId: entity('macroRegion').property('id'),
          macroRegionName: entity('macroRegion').property('name'),
          stateId: entity('state').property('id'),
          stateName: entity('state').property('name'),
        })
        .join({
          current: entity('state').property('macroRegionId'),
          target: entity('macroRegion').property('id'),
        })
        .where(equals(entity('state').property('id'), string().value(id)))
        .limit(1)

    case 'mesoRegion':
      return query('mesoRegion')
        .select({
          level: string().value('mesoRegion'),
          macroRegionId: entity('macroRegion').property('id'),
          macroRegionName: entity('macroRegion').property('name'),
          stateId: entity('state').property('id'),
          stateName: entity('state').property('name'),
          mesoRegionId: entity('mesoRegion').property('id'),
          mesoRegionName: entity('mesoRegion').property('name'),
        })
        .join({
          current: entity('mesoRegion').property('stateId'),
          target: entity('state').property('id'),
        })
        .join({
          current: entity('state').property('macroRegionId'),
          target: entity('macroRegion').property('id'),
        })
        .join({
          current: entity('macroRegion').property('countryId'),
          target: entity('country').property('id'),
        })
        .where(equals(entity('mesoRegion').property('id'), string().value(id)))
        .limit(1)

    case 'microRegion':
      return query('microRegion')
        .select({
          level: string().value('microRegion'),
          macroRegionId: entity('macroRegion').property('id'),
          macroRegionName: entity('macroRegion').property('name'),
          stateId: entity('state').property('id'),
          stateName: entity('state').property('name'),
          mesoRegionId: entity('mesoRegion').property('id'),
          mesoRegionName: entity('mesoRegion').property('name'),
          microRegionId: entity('microRegion').property('id'),
          microRegionName: entity('microRegion').property('name'),
        })
        .join({
          current: entity('microRegion').property('mesoRegionId'),
          target: entity('mesoRegion').property('id'),
        })
        .join({
          current: entity('mesoRegion').property('stateId'),
          target: entity('state').property('id'),
        })
        .join({
          current: entity('state').property('macroRegionId'),
          target: entity('macroRegion').property('id'),
        })
        .join({
          current: entity('macroRegion').property('countryId'),
          target: entity('country').property('id'),
        })
        .where(equals(entity('microRegion').property('id'), string().value(id)))
        .limit(1)

    case 'city':
      return query('city')
        .select({
          level: string().value('city'),
          macroRegionId: entity('macroRegion').property('id'),
          macroRegionName: entity('macroRegion').property('name'),
          stateId: entity('state').property('id'),
          stateName: entity('state').property('name'),
          mesoRegionId: entity('mesoRegion').property('id'),
          mesoRegionName: entity('mesoRegion').property('name'),
          microRegionId: entity('microRegion').property('id'),
          microRegionName: entity('microRegion').property('name'),
          cityId: entity('city').property('id'),
          cityName: entity('city').property('name'),
        })
        .join({
          current: entity('city').property('microRegionId'),
          target: entity('microRegion').property('id'),
        })
        .join({
          current: entity('microRegion').property('mesoRegionId'),
          target: entity('mesoRegion').property('id'),
        })
        .join({
          current: entity('mesoRegion').property('stateId'),
          target: entity('state').property('id'),
        })
        .join({
          current: entity('state').property('macroRegionId'),
          target: entity('macroRegion').property('id'),
        })
        .join({
          current: entity('macroRegion').property('countryId'),
          target: entity('country').property('id'),
        })
        .where(equals(entity('city').property('id'), string().value(id)))
        .limit(1)

    case 'neighborhood':
      return query('neighborhood')
        .select({
          level: string().value('neighborhood'),
          macroRegionId: entity('macroRegion').property('id'),
          macroRegionName: entity('macroRegion').property('name'),
          stateId: entity('state').property('id'),
          stateName: entity('state').property('name'),
          mesoRegionId: entity('mesoRegion').property('id'),
          mesoRegionName: entity('mesoRegion').property('name'),
          microRegionId: entity('microRegion').property('id'),
          microRegionName: entity('microRegion').property('name'),
          cityId: entity('city').property('id'),
          cityName: entity('city').property('name'),
          neighborhoodId: entity('neighborhood').property('id'),
          neighborhoodName: entity('neighborhood').property('name'),
        })
        .join({
          current: entity('neighborhood').property('cityId'),
          target: entity('city').property('id'),
        })
        .join({
          current: entity('city').property('microRegionId'),
          target: entity('microRegion').property('id'),
        })
        .join({
          current: entity('microRegion').property('mesoRegionId'),
          target: entity('mesoRegion').property('id'),
        })
        .join({
          current: entity('mesoRegion').property('stateId'),
          target: entity('state').property('id'),
        })
        .join({
          current: entity('state').property('macroRegionId'),
          target: entity('macroRegion').property('id'),
        })
        .join({
          current: entity('macroRegion').property('countryId'),
          target: entity('country').property('id'),
        })
        .where(equals(entity('neighborhood').property('id'), string().value(id)))
        .limit(1)

    case 'street':
      return query('street')
        .select({
          level: string().value('street'),
          macroRegionId: entity('macroRegion').property('id'),
          macroRegionName: entity('macroRegion').property('name'),
          stateId: entity('state').property('id'),
          stateName: entity('state').property('name'),
          mesoRegionId: entity('mesoRegion').property('id'),
          mesoRegionName: entity('mesoRegion').property('name'),
          microRegionId: entity('microRegion').property('id'),
          microRegionName: entity('microRegion').property('name'),
          cityId: entity('city').property('id'),
          cityName: entity('city').property('name'),
          streetId: entity('street').property('id'),
          streetName: entity('street').property('name'),
        })
        .join({
          current: entity('street').property('cityId'),
          target: entity('city').property('id'),
        })
        .join({
          current: entity('city').property('microRegionId'),
          target: entity('microRegion').property('id'),
        })
        .join({
          current: entity('microRegion').property('mesoRegionId'),
          target: entity('mesoRegion').property('id'),
        })
        .join({
          current: entity('mesoRegion').property('stateId'),
          target: entity('state').property('id'),
        })
        .join({
          current: entity('state').property('macroRegionId'),
          target: entity('macroRegion').property('id'),
        })
        .join({
          current: entity('macroRegion').property('countryId'),
          target: entity('country').property('id'),
        })
        .where(equals(entity('street').property('id'), string().value(id)))
        .limit(1)

    case 'legalEntity':
      return query('legalEntityGeoprocessing')
        .select({
          macroRegionId: entity('legalEntityGeoprocessing').property('macroRegionId'),
          macroRegionName: entity('macroRegion').property('name'),
          stateId: entity('legalEntityGeoprocessing').property('stateId'),
          stateName: entity('state').property('name'),
          mesoRegionId: entity('legalEntityGeoprocessing').property('mesoRegionId'),
          mesoRegionName: entity('mesoRegion').property('name'),
          microRegionId: entity('legalEntityGeoprocessing').property('microRegionId'),
          microRegionName: entity('microRegion').property('name'),
          cityId: entity('legalEntityGeoprocessing').property('cityId'),
          cityName: entity('city').property('name'),
          neighborhoodId: entity('legalEntityGeoprocessing').property('neighborhoodId'),
          neighborhoodName: entity('neighborhood').property('name'),
          streetId: entity('legalEntityGeoprocessing').property('streetId'),
          streetName: entity('street').property('name'),
        })
        .join({
          current: entity('legalEntityGeoprocessing').property('macroRegionId'),
          target: entity('macroRegion').property('id'),
        })
        .join({
          current: entity('legalEntityGeoprocessing').property('stateId'),
          target: entity('state').property('id'),
        })
        .join({
          current: entity('legalEntityGeoprocessing').property('mesoRegionId'),
          target: entity('mesoRegion').property('id'),
        })
        .join({
          current: entity('legalEntityGeoprocessing').property('microRegionId'),
          target: entity('microRegion').property('id'),
        })
        .join({
          current: entity('legalEntityGeoprocessing').property('cityId'),
          target: entity('city').property('id'),
        })
        .join({
          current: entity('legalEntityGeoprocessing').property('neighborhoodId'),
          target: entity('neighborhood').property('id'),
        })
        .join({
          current: entity('legalEntityGeoprocessing').property('streetId'),
          target: entity('street').property('id'),
        })
        .where(
          equals(entity('legalEntityGeoprocessing').property('legalEntityId'), string().value(id)),
        )
        .limit(1)
  }
}
