import React from 'react'
import styled from 'styled-components'
import { NotificationProps } from './header'
import { useNavigate } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import { alertColors, alertLabels } from '~/packages/timeSeries/timeSeries.data'
import Badge from '~/prix/react/components/badge'

const NotificationContent = styled.a<{ isModal: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: ${props => props.isModal ? 'flex-start' : 'space-between'};
  ${props => props.isModal ? 'width: 100%;' : 'max-width: 100%;'}
  padding: 12px;
  gap: 2px;
  border: 0px;
  border-style: solid;
  border-bottom-width: 1px;
  border-image: linear-gradient(to right, white 0%, #cedae0 40%, #cedae0 60%, white 100%) 1 20%;
  ${props => props.isModal ? '' : 'overflow: hidden;'}
`

const InnerContentWrapper = styled.div<{ isModal: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  width: 100%;
  height: ${props => props.isModal ? '100%' : 'auto'};

  .bottom-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`

const NotificationTitle = styled.p<{ isModal: boolean }>`
  font-size: 15px;
  font-weight: 700;
  margin: 0;
  width: 100%;
  text-align: left;
  white-space: ${props => props.isModal ? 'normal' : 'nowrap'};
  overflow: ${props => props.isModal ? 'visible' : 'hidden'};
  text-overflow: ${props => props.isModal ? 'none' : 'ellipsis'};
`

const NotificationBody = styled.p<{ isModal: boolean }>`
  font-size: 13px;
  font-weight: 400;
  margin: 0;
  width: 100%;
  text-align: left;
  white-space: ${props => props.isModal ? 'normal' : 'nowrap'};
  overflow: ${props => props.isModal ? 'visible' : 'hidden'};
  text-overflow: ${props => props.isModal ? 'none' : 'ellipsis'};
`

const NotificationDate = styled.p`
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  width: 100%;
  text-align: end;
`

const formatDate = (dateString: string): string => {
  const date = parseISO(dateString)
  return format(date, "dd/MM/yyyy 'às' HH:mm")
}

export default function NotificationsComponent({
  isModal = false,
  notification,
  setRead
}: {
  isModal: boolean
  notification: NotificationProps
  setRead: any
}) {
  const navigate = useNavigate()

  return (
    <NotificationContent
      isModal={isModal}
      onClick={() => {
        if (notification.url) {
          const userConfirmed = window.confirm('Você será redirecionado para outra página. Deseja continuar?')
          if (userConfirmed) {
            setRead()
            navigate(notification.url)
          }
        }
      }}
      title={notification.url ? 'Clique para abrir a notificação.' : ''}
      style={{
        backgroundColor: notification.readAt ? '#eeeeee' : '#F0F6FE',
        cursor: notification.url ? 'pointer' : 'default',
        opacity: notification.readAt ? 0.5 : 1,
      }}
    >
      <InnerContentWrapper isModal={isModal}>
        <NotificationTitle isModal={isModal}>{notification.payload.title}</NotificationTitle>
        <NotificationBody isModal={isModal}>{notification.payload.body}</NotificationBody>
        <div className="bottom-line">
          {notification.label &&
            <Badge
              background={alertColors[notification.label as keyof typeof alertColors]}>
              {alertLabels[notification.label as keyof typeof alertLabels]}
            </Badge>}
          <NotificationDate>{formatDate(String(notification.createdAt))}</NotificationDate>
        </div>
      </InnerContentWrapper>
    </NotificationContent>
  )
}