import { array, defineAction, enumerated, object, string } from '~/prix'
import geoSearchAction from './geoSearch.action.json'

const geoSearchDefinition = defineAction({
  ...geoSearchAction,
  input: object({
    query: string().isOptional(),
    level: enumerated({
      country: 'country',
      macroRegion: 'macroRegion',
      mesoRegion: 'mesoRegion',
      microRegion: 'microRegion',
      state: 'state',
      city: 'city',
      street: 'street',
      neighborhood: 'neighborhood',
      suburb: 'suburb',
    }).isOptional(),

    stateId: string().isOptional(),
    stateAbbreviation: string().isOptional(),
    cityId: string().isOptional(),
    cityIbgeCode: string().isOptional(),
    neighborhoodId: string().isOptional(),
    streetId: string().isOptional(),
  }),
  output: object({
    warnings: array(
      object({
        message: string(),
      }),
    ).isOptional(),
    elementsFound: array(object({})),
  }),
})

export default geoSearchDefinition
