import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PulseLoader from 'react-spinners/PulseLoader'
import useMedia from 'react-use/lib/useMedia'
import styled from 'styled-components'
import AppLayout from '~/components/appLayout'
import GeoprocessingMapError from '~/components/geoprocessingMapError'
import { colors } from '~/design'
import Choropleth from '~/meta/choropleth.svg'
import HeatmapIcon from '~/meta/heatmap.svg'
import MapIcon from '~/meta/map.svg'
import SatelliteIcon from '~/meta/satelite.svg'
import useGeoLegalEntities from '~/packages/legalEntity/geoLegalEntities.hook'
import LegalEntityReadOne from '~/packages/legalEntity/read/legalEntityReadOne.component'
import legalEntitySearchWithGeo from '~/packages/legalEntity/search/legalEntitySearchWithGeo.query'
import { AppError, enumerated } from '~/prix'
import EnumeratedField from '~/prix/react/components/form/field/enumeratedField'
import SelectFromQuery from '~/prix/react/components/form/inputs/selectFromQuery'
import SearchField from '~/prix/react/components/form/inputs/searchField'
import LoadingQueriesMessage from '~/prix/react/components/loadingQueriesMessage'
import GoogleMaps from '~/prix/react/components/map/googleMaps'
import Modal from '~/prix/react/components/modal'
import useHeatmap from '../heatmap/mapHeatmap.hook'
import MapHeatmapLayer from '../heatmap/mapHeatmapLayer.component'
import LegalEntityGeoItem from './legalEntityGeoItem.component'
import {
  Breadcrumb,
  FilterHighlight,
  GeoChildrenLegalEntitiesPossibilities,
  GeoItem,
  GeoLegalEntitiesPossibilities,
  geoLevelToGeoItem,
  Level,
} from './legalEntityGeoprocessingMapLevels.data'
import MapMenu from './menu/legalEntityGeoprocessingMapMenu.component'
import Handle from '~/prix/react/components/handle'
import { searchQueryToRegularExpression } from '~/prix/utils/types/regularExpression'
import { unaccent } from '~/prix/naturalLanguage/unaccent'
import { maskNumbers, masks } from '~/prix/utils/types/mask'
import { NativeBase } from '~/prix/types/type'
import Logo from '~/meta/logo.svg'
import { DefinedOptionMerged } from './menu/legalEntityGeoprocessingMapMenu.data'
import { useSearchParams } from 'react-router-dom'
import { Button } from '~/prix/react/components/button'
import OriginalLink from '~/prix/react/components/link'
import LeftArrowIcon from '~/meta/angle-left-arrow.svg'
import { ListFooter } from '~/components/list'
import { toastWarning } from '~/components/toast'

export interface GeoLegalEntitiesOptions {
  id: string | null
  geo: GeoLegalEntitiesPossibilities
  filter: FilterHighlight | null
  highlight: FilterHighlight | null
  childrenGeoLevel?: GeoChildrenLegalEntitiesPossibilities
  isEnabled?: boolean
  definedOption: DefinedOptionMerged
}

export const levelKeyAux = {
  macroRegions: 'Macrorregião',
  states: 'Estado',
  mesoRegions: 'Mesorregião',
  microRegions: 'Microrregião',
  cities: 'Cidade',
  neighborhoods: 'Bairro',
  streets: 'Rua',
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;

  .gm-style iframe + div {
    border: none !important;
  }
`

const MapLevelFieldOverlay = styled.div`
  position: absolute;
  top: 10px;
  margin-left: 10px;
  width: 300px;
  height: 55px;

  input {
    height: 27.5px;
  }

  @media (max-width: 1100px) {
    width: 40%;
  }

  @media (max-width: 768px) {
    width: 40%;
  }

  @media (max-width: 360px) {
    width: 175px;
  }
`

export const Link = styled(OriginalLink)`
  text-decoration: none;
  color: #fff;
  padding: 10px 10px 10px 10px;
  transition-duration: 0.5s;
  text-align: center;
  border-radius: 5px;
  background-color: ${colors.sebraeBlue};
  margin-bottom: 10px;
  width: 200px;

  font-weight: 600;
  font-size: 16px;
  cursor: pointer;

  span {
    margin-left: 5px;
  }

  &:hover {
    background-color: ${colors.oceanBlue};
  }
`

const MapSearchFieldOverlay = styled.div`
  position: absolute;
  top: 10px;
  margin-left: 315px;
  width: 300px;
  min-width: 150px;
  height: 55px;

  input {
    height: 27.5px;
  }

  @media (max-width: 1100px) {
    margin-left: 43%;
    width: 40%;
  }

  @media (max-width: 360px) {
    top: 57.5px;
    margin-left: 10px;
    width: 175px;
  }
`

const ChildrenGeoLevelOverlay = styled.div`
  position: absolute;
  bottom: 40px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;

  button {
    height: 40px;
    padding: 0px 16px;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Roboto, Arial, sans-serif;
    border: none;
    background-color: transparent;
    color: rgb(86, 86, 86);
    cursor: pointer;

    &:hover {
      color: rgb(0, 0, 0);
      background-color: rgb(235, 235, 235);
    }
    &.active {
      font-weight: bold;
      color: rgb(0, 0, 0);
      box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
      &:hover {
        color: rgb(0, 0, 0);
      }
    }

    @media (max-width: 512px) {
      max-width: 90px;
    }
  }
`

const MapTypeOverlayFirstGroup = styled.div`
  position: absolute;
  display: flex;
  margin-left: 10px;
  flex-direction: row;
  bottom: 85px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: transparent;
    cursor: pointer;
    padding: 4px 4px 0px 4px;
  }

  .svg-opacity {
    opacity: 0.5;
  }

  .border-left {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .border-right {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`

const MapTypeOverlaySecondGroup = styled(MapTypeOverlayFirstGroup)`
  margin-left: 100px;
  bottom: 85px;
`

export const TitleWrapper = styled.div`
  display: inline-flex;
  flex-flow: row nowrap;
  max-width: 100%;
  span {
    font-size: 14px;
    flex: 1;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const SubtitleWrapper = styled.div`
  span {
    font-size: 12px;
  }
`
export const LegalEntityListTitle = styled.span`
  padding: 0.75em 0.75em;
  text-align: center;
  color: #414141;
`

const EntitiesSection = styled.div`
  display: flex;
  margin: 25px 32px;
  padding: 0px 0px;
  flex-direction: column;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
`

const BodyWrapper = styled.div`
  background-color: #fff;
  border-radius: 0 0 12px 12px;
  display: flex;
  flex-direction: column;
`

const CardEntitiesList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const CardEntitiesListTitle = styled.div`
  display: flex;
  border-radius: 12px 12px 0px 0px;
  background: #0f438a;
  padding: 0 25px;
  width: 100%;
  gap: 12px;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  button {
    height: 3rem;
    width: 3rem;
    padding: 8px;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    cursor: pointer;

    border: none;
    border-radius: 100px;
    background: #005eb8;

    svg {
      margin-top: 2px;
      fill: #fff;
    }
  }

  h3 {
    color: #fff;
    font-weight: 400;
    line-height: 23.3px;
    font-size: 2rem;
  }

  div {
    min-width: 3rem;
  }
`

const ListHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 46px;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  flex-shrink: 0;
  background-color: #fff;
`

const ListHeaderColumn = styled.div`
  display: block;
  flex: 1;
  padding-left: 24px;

  color: #8b99a7;
  font-size: 0.89rem;
  font-weight: 700;
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const ListRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 46px;
  align-items: center;
`

const ListData = styled.div`
  display: block;
  flex: 1;
  overflow: hidden;
  color: #394d60;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.89655rem;
  font-weight: 400;
  padding-left: 23px;
  padding-top: 5px;
`

const HighlightWrapper = styled.div`
  max-width: 75px;
  background-color: ${colors.sebraeBlue};
  color: #fff;
  border-radius: 6px;
  font-weight: bold;
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: center;
`

const LegalEntityListButton = styled.button`
  text-align: left;
  border: none;
  background: transparent;
  border-radius: 2px;
  color: rgb(119, 119, 119);
  cursor: pointer;
  border-bottom: 1px solid #e6e6e6;

  &:hover {
    background-color: rgb(245, 245, 245);
    color: rgb(54, 54, 54);
    border-bottom: 1px solid transparent;
  }

  small {
    background-color: ${colors.sebraeBlue};
    color: #fff;
    border-radius: 4px;
    font-weight: bold;
    padding: 0px 3px 1px 2px;
    white-space: nowrap;
  }
`

export const OverlayLoading = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 94, 184, 0.6);
  backdrop-filter: blur(5px);
  z-index: 1;
  align-items: center;
  text-align: center;

  .center {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .message {
      margin-top: 10px;
      color: #fff;
      font-weight: bold;
      text-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
    }
  }

  .center-error {
    border-radius: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 30px 30px 20px 30px;
    background-color: #fff;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    cursor: default;

    .row {
      display: flex;
      flex-direction: row;
    }

    .column {
      display: flex;
      flex-direction: column;
      margin-right: 5px;
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 15px;
    }
  }
`

const OverlayMap = styled.div`
  /* display: none; */
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  z-index: 1;
  align-items: center;
  text-align: center;

  .center {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .message {
      margin-top: 10px;
      color: #fff;
      font-weight: bold;
      text-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
    }
  }

  .center-error {
    border-radius: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 30px 30px 20px 30px;
    background-color: #fff;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    cursor: default;

    .row {
      display: flex;
      flex-direction: row;
    }

    .column {
      display: flex;
      flex-direction: column;
      margin-right: 5px;
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 15px;
    }
  }
`

export const OverlaySearchableMap = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 94, 184, 0.6);
  z-index: 1;
  align-items: center;
  text-align: center;

  span {
    font-size: 16px;
    padding: 20px 40px 20px 40px;
  }

  .center {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fafafa;
    align-items: center;
    text-align: center;
    border-radius: 4px;
    padding: 15px 0px 15px 0px;

    .message {
      margin-top: 10px;
      color: #fff;
      font-weight: bold;
      text-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
    }
  }

  .center-error {
    border-radius: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 30px 30px 20px 30px;
    background-color: #fff;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    cursor: default;

    .row {
      display: flex;
      flex-direction: row;
    }

    .column {
      display: flex;
      flex-direction: column;
      margin-right: 5px;
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 15px;
    }
  }
`

const secondaryValueAux = {
  size: 'porte',
  cnaes: 'CNAE',
  segment: 'segmento',
  legalNatureId: 'natureza jurídica',
  attendance: 'fonte de dados',
  area: 'fonte de dados',
  perCapta: 'fonte de dados',
  profits: 'fonte de dados',
  cost: 'fonte de dados',
  scoreOperationsManagement: 'fonte de dados',
  scoreTransformation: 'fonte de dados',
  scoreIndicatorsManagement: 'fonte de dados',
  scoreInnovation: 'fonte de dados',
  scoreMarketing: 'fonte de dados',
  scoreSustainablePractices: 'fonte de dados',
}

export interface LegalEntityGeoprocessingMapProps {
  mapTypeId: 'satellite' | 'map'
  onMapTypeIdChange: (mapTypeId: 'satellite' | 'map') => void
  onVisualizationTypeIdChange: (mapTypeId: 'chloroplethic' | 'heatmap') => void
  selectedLegalEntityId: string | null
  filter: FilterHighlight | null
  onFilterChange: (filter: FilterHighlight | null) => void
  highlight: FilterHighlight | null
  onHighlightChange: (highlight: FilterHighlight | null) => void
  level: Level | null
  onLevelChange: (level: Level) => void
  onChildrenGeoLevelChange: (childrenGeoLevel: GeoChildrenLegalEntitiesPossibilities) => void
  breadcrumbs: Breadcrumb[]
  title?: string
  markerVariationForValue: (value: string) => void
  markerVariationLoading: boolean
  setHeatmapEnabledUrl: React.Dispatch<React.SetStateAction<boolean | undefined>>
  userGeoState: any
  hasUserRoles: boolean
  error?: AppError
  handleOptionMapMenu: any
  definedOption: DefinedOptionMerged | null
  visualizationTypeIdUrl: 'chloroplethic' | 'heatmap'
}

export default function LegalEntityGeoprocessingMap({
  handleOptionMapMenu,
  definedOption,
  mapTypeId,
  onMapTypeIdChange,
  onVisualizationTypeIdChange,
  visualizationTypeIdUrl,
  selectedLegalEntityId,
  breadcrumbs,
  level,
  onLevelChange,
  onChildrenGeoLevelChange,
  filter,
  onFilterChange,
  highlight,
  onHighlightChange,
  title,
  markerVariationForValue,
  markerVariationLoading,
  setHeatmapEnabledUrl,
  userGeoState,
  hasUserRoles,
  error,
}: LegalEntityGeoprocessingMapProps) {
  const anyPointerCoarse = useMedia('(any-pointer: coarse)')
  const hoverNone = useMedia('(hover: none)')
  const isTouchable = anyPointerCoarse || hoverNone
  const isMobile = useMedia('(max-width: 768px)')
  const map = useRef<google.maps.Map | null>(null)
  const [enableBackButton, setEnableBackButton] = useState<boolean>(false)

  const [isSearchDone, setIsSearchDone] = useState(false)
  const [selectedGeoId, setSelectedGeoId] = useState<string | null>(null)
  const [selectedLegalEntities, setSelectedLegalEntities] = useState<Array<{
    id: string
    name: string
    highlight: boolean | null
  }> | null>(null)
  const [mouseOnCenter, setMouseOnCenter] = useState<boolean>(false)
  const [searchParams] = useSearchParams()
  const [legalEntityModalSearch, setLegalEntityModalSearch] = useState<string | null>(null)
  const [isHighlightCensusEnabled, setIsHighlightCensusEnabled] = useState(false)
  const [isHighlightProfitsEnabled, setIsHighlightProfitsEnabled] = useState(false)
  const [zoomLevel, setZoomLevel] = useState(4)
  const [menuVisibility, setMenuVisibility] = useState(true)

  const [visualizationTypeId, setVisualizationTypeId] = useState<'chloroplethic' | 'heatmap'>(
    'chloroplethic',
  )
  const [outOfRangeEntities, setOutOfRangeEntities] = useState<number | string | undefined>(
    undefined,
  )
  const [totalEntities, setTotalEntities] = useState<number | string | undefined>(undefined)
  const [isLegalEntityClusterSelected, setIsLegalEntityClusterSelected] = useState<boolean>(false)
  const [legalEntityName, setLegalEntityName] = useState('')

  const toggleMenuVisibility = () => setMenuVisibility(!menuVisibility)
  const closeMenu = () => onLevelChange(level ?? { geo: 'country', id: null })

  const [currentPage, setCurrentPage] = useState(1)

  const itemsPerPage = isMobile ? 7 : 8

  const mapLoadAction = (evt: any) => {
    evt.addListener('zoom_changed', () => {
      setZoomLevel(evt.zoom)
    })
  }

  const isHighlightCensus = useMemo(() => {
    const isAreaSearchParams =
      searchParams.get('highlightType') === 'area' &&
      searchParams.get('highlightValue') === 'censusUpdated'
    const isPerCaptaSearchParams =
      searchParams.get('highlightType') === 'perCapta' &&
      searchParams.get('highlightValue') === 'censusUpdated'

    return isAreaSearchParams || isPerCaptaSearchParams
  }, [searchParams])

  const isHighlightProfits = useMemo(() => {
    const isRevenueSearchParams =
      searchParams.get('highlightType') === 'profits' &&
      searchParams.get('highlightValue') === 'profitsData'
    const isCostSearchParams =
      searchParams.get('highlightType') === 'cost' &&
      searchParams.get('highlightValue') === 'profitsData'
    const isScoreOperationsManagementSearchParams =
      searchParams.get('highlightType') === 'scoreOperationsManagement'
    const isScoreTransformationSearchParams =
      searchParams.get('highlightType') === 'scoreTransformation'
    const isScoreIndicatorsManagementSearchParams =
      searchParams.get('highlightType') === 'scoreIndicatorsManagement'
    const isScoreInnovationSearchParams = searchParams.get('highlightType') === 'scoreInnovation'
    const isScoreMarketingSearchParams = searchParams.get('highlightType') === 'scoreMarketing'
    const isScoreSustainablePracticesSearchParams =
      searchParams.get('highlightType') === 'scoreSustainablePractices'

    return (
      isRevenueSearchParams ||
      isCostSearchParams ||
      isScoreOperationsManagementSearchParams ||
      isScoreTransformationSearchParams ||
      isScoreTransformationSearchParams ||
      isScoreIndicatorsManagementSearchParams ||
      isScoreInnovationSearchParams ||
      isScoreMarketingSearchParams ||
      isScoreSustainablePracticesSearchParams
    )
  }, [searchParams])

  const messageProfits = useMemo(() => {
    const isScoreOperationsManagementSearchParams =
      searchParams.get('highlightType') === 'scoreOperationsManagement'
    const isScoreTransformationSearchParams =
      searchParams.get('highlightType') === 'scoreTransformation'
    const isScoreIndicatorsManagementSearchParams =
      searchParams.get('highlightType') === 'scoreIndicatorsManagement'
    const isScoreInnovationSearchParams = searchParams.get('highlightType') === 'scoreInnovation'
    const isScoreMarketingSearchParams = searchParams.get('highlightType') === 'scoreMarketing'
    const isScoreSustainablePracticesSearchParams =
      searchParams.get('highlightType') === 'scoreSustainablePractices'
    const isHighlightScores =
      isScoreOperationsManagementSearchParams ||
      isScoreTransformationSearchParams ||
      isScoreIndicatorsManagementSearchParams ||
      isScoreInnovationSearchParams ||
      isScoreMarketingSearchParams ||
      isScoreSustainablePracticesSearchParams

    const message = isHighlightScores
      ? 'indicadores'
      : searchParams.get('highlightType') === 'profits'
      ? 'faturamento'
      : searchParams.get('highlightType') === 'cost'
      ? 'custo médio'
      : null

    return message
  }, [searchParams])

  const accessableLevel = useMemo(() => {
    return (
      level &&
      (level.geo === 'city' ||
        level.geo === 'neighborhood' ||
        level.geo === 'street' ||
        level.geo === 'clusteredLegalEntity' ||
        level.geo === 'legalEntity')
    )
  }, [level])

  useEffect(() => {
    if (isHighlightCensus && accessableLevel) {
      setIsHighlightCensusEnabled(false)
    } else {
      setIsHighlightCensusEnabled(true)
    }
  }, [accessableLevel, isHighlightCensus])

  useEffect(() => {
    if (isHighlightProfits && accessableLevel) {
      setIsHighlightProfitsEnabled(false)
    } else {
      setIsHighlightProfitsEnabled(true)
    }
  }, [accessableLevel, isHighlightProfits])

  const overlaySearchableMapCondition = useMemo(() => {
    const filterCondition =
      filter && filter?.type !== null && (filter?.value === null || filter?.value === undefined)
    const highlightCondition =
      (highlight &&
        highlight?.type !== null &&
        (highlight?.value === null || highlight?.value === undefined)) ||
      (highlight?.type === 'courseDropout' &&
        highlight?.eadOption === 'individual' &&
        !highlight?.courseProduct)

    return filterCondition || highlightCondition
  }, [filter, highlight])

  const result = useGeoLegalEntities({
    geo: level?.geo ?? 'country',
    id: level?.id ?? null,
    childrenGeoLevel: level?.childrenGeoLevel,
    filter,
    highlight,
    isEnabled: !!level,
    definedOption,
    level,
  })

  const childrenGeoLevelOptionsEntries = useMemo(
    () => Object.entries(result.childrenGeoLevelOptions),
    [result.childrenGeoLevelOptions],
  )
  useEffect(() => {
    if (!result.bounds || !map.current) {
      return
    }
    map.current.fitBounds(result.bounds)
  }, [map.current, result.bounds])

  useEffect(() => {
    if (isTouchable) {
      handleDispose()
    }
  }, [isTouchable, level?.geo, level?.childrenGeoLevel])

  const handleClickNextLevelButton = useCallback(
    (
      item: Partial<GeoItem> & {
        id: string
        name: string
        levelKey: string
      },
    ) => {
      const nextGeo = geoLevelToGeoItem[item.levelKey] as GeoLegalEntitiesPossibilities | null

      if (!nextGeo) {
        if (item.clusterItems && item.clusterItems.length > 1) {
          item.clusterItems.sort((a, b) => ('' + a.name).localeCompare(b.name))
          setSelectedLegalEntities(item.clusterItems)
        } else if (item.clusterItems) {
          onLevelChange({
            geo: 'legalEntity',
            id: item.clusterItems[0].id,
          })
        } else {
          onLevelChange({
            geo: 'legalEntity',
            id: item.id,
          })
        }
        return
      }

      const nextLevel = {
        geo: nextGeo,
        id: String(item.id),
      }
      onLevelChange(nextLevel)
    },
    [onLevelChange],
  )

  const handleClick = useCallback(
    (item: GeoItem) => {
      if (isTouchable) {
        setSelectedGeoId(old => (old !== item.id ? item.id : null))
        return
      }

      if (highlight?.type === 'courseDropout') {
        toastWarning(
          'Os destaques de evasão de cursos só possuem dados disponíveis a nível de país.',
        )
        return
      }

      handleClickNextLevelButton(item)
    },
    [handleClickNextLevelButton, isTouchable],
  )

  const handleMouseOver = useCallback(
    (item: GeoItem) => {
      if (!isTouchable) {
        setSelectedGeoId(item.id)
      }
    },
    [isTouchable],
  )

  const handleMouseCenter = useCallback(
    (value: boolean) => {
      if (!isTouchable) {
        setMouseOnCenter(value)
      }
    },
    [isTouchable],
  )

  const handleMouseOut = useCallback(
    (item: GeoItem) => {
      if (!isTouchable) {
        setSelectedGeoId(null)
      }
    },
    [isTouchable],
  )

  const handleDispose = useCallback(() => {
    setSelectedGeoId(null)
  }, [])

  const handleBreadcrumbChangeLevel = useCallback(
    (newLevelKey: GeoLegalEntitiesPossibilities) => {
      const breadcrumb = breadcrumbs.find(
        breadcrumb => `${breadcrumb.geo}.${breadcrumb.id}` === newLevelKey,
      )
      if (!breadcrumb) {
        return
      }
      onLevelChange(breadcrumb)
    },
    [onLevelChange, breadcrumbs],
  )

  useEffect(() => {
    if (visualizationTypeIdUrl) {
      setVisualizationTypeId(visualizationTypeIdUrl)
    }
  }, [visualizationTypeIdUrl])

  useEffect(() => {
    setHeatmapEnabledUrl(visualizationTypeId === 'heatmap')
  }, [visualizationTypeId])

  const {
    heatmapPoints,
    isLoading: heatmapIsLoading,
    resolutionThreshold,
  } = useHeatmap({
    geo: level?.geo ?? 'country',
    id: level?.id ?? null,
    filter,
    isEnabled: visualizationTypeId === 'heatmap',
    definedOption,
  })

  const handleHeatmap = useCallback(
    (isHeatmap: boolean) => {
      if (isHeatmap) {
        onVisualizationTypeIdChange('heatmap')
        setVisualizationTypeId('heatmap')
      }
      if (!isHeatmap) {
        onVisualizationTypeIdChange('chloroplethic')
        setVisualizationTypeId('chloroplethic')
      }
    },
    [onVisualizationTypeIdChange],
  )

  const legalEntitySearchWithGeoQueryFactory = useCallback(
    (input: string) =>
      legalEntitySearchWithGeo(input, hasUserRoles ? userGeoState.items[0] : undefined),
    [userGeoState.items, hasUserRoles],
  )

  const handleLastState = useCallback(() => {
    const lastStateBreadcrumb = breadcrumbs.find(item => item.geo === 'state')
    onLevelChange({
      geo: 'state',
      id: String(lastStateBreadcrumb?.id),
    })
  }, [breadcrumbs])

  const minPercentColor = useMemo(() => {
    if (result.items) {
      return Math.min(
        Infinity,
        ...result.items.map(item => {
          if (typeof item.percentColor === 'number') {
            return Number(item.percentColor)
          }
          return Infinity
        }),
      )
    }
  }, [result.items])

  const maxPercentColor = useMemo(() => {
    if (result.items) {
      return Math.max(
        -Infinity,
        ...result.items.map(item => {
          if (typeof item.percentColor === 'number') {
            return Number(item.percentColor)
          }
          return -Infinity
        }),
      )
    }
  }, [result.items])

  /**
   * Formata os resultados de legal entities da query da busca global.
   * Adicionando máscaras ao CNPJ e trazendo o tradeName quando existir e for diferente do corporateName
   * @param legalEntitySearchItem Item único de busca.
   * @returns Item da pesquisa formatado.
   */
  const formatLegalEntitySearchItem = useCallback(
    (legalEntitySearchItem: { [x: string]: NativeBase | null }) => {
      const { tradeName, corporateName, subtitle } = legalEntitySearchItem

      const cnpj = subtitle?.toString().substring(0, 14)
      const location = subtitle?.toString().substring(17)
      const formatedSubtitle = `${cnpj ? `${maskNumbers(cnpj, masks.cnpj)}` : ''}, ${location}`

      //Verificar se a empresa possui um tradeName esquisito para ignora-las. Ex. 'XXXX', '@@@'...
      const uniqueTradeNameChars = [...new Set(tradeName?.toString().split(''))]
      const charBlackList = [`'`, '#', '*', 'x', 'X', '-', '.', '?', '@', '/', ':']
      const hasTrashTradeName =
        uniqueTradeNameChars.length === 1 &&
        charBlackList.some(wildChar => wildChar === uniqueTradeNameChars[0].trim())

      const formatedTradeCorporateName = `${
        tradeName && !hasTrashTradeName && tradeName !== corporateName ? `${tradeName} | ` : ''
      }${corporateName || ''}`

      return {
        ...legalEntitySearchItem,
        tradeName: formatedTradeCorporateName || 'Desconhecido',
        subtitle: formatedSubtitle,
      }
    },
    [],
  )

  const writeEntitiesList = (data: any) => {
    const maskCNPJ = (cnpj: string) => {
      const maskedCNPJ = cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
      return maskedCNPJ
    }

    const separatedName = data.name.split(' (')
    const name = separatedName[0]
    const cnpj = separatedName[1]?.replace(')', '')

    const maskedCNPJ = cnpj ? maskCNPJ(cnpj) : ''

    return (
      <div style={{ display: 'flex', flex: 1, flexDirection: 'row', maxWidth: '100%' }}>
        <ListData style={{ maxWidth: '50%' }}>{name}</ListData>
        <ListData style={{ maxWidth: '30%' }}>{maskedCNPJ}</ListData>
        <ListData style={{ maxWidth: '20%' }}>
          {data.highlight ? <HighlightWrapper>Destacado</HighlightWrapper> : null}
        </ListData>
      </div>
    )
  }

  const listData = selectedLegalEntities?.filter(item =>
    searchQueryToRegularExpression(unaccent(legalEntityModalSearch || '')).test(
      unaccent(item.name),
    ),
  )

  const sort = listData?.sort((a, b) => {
    if (a.highlight && !b.highlight) return -1
    if (!a.highlight && b.highlight) return 1
    return 0
  })

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = currentPage * itemsPerPage
  const currentData = sort?.slice(startIndex, endIndex)

  const nextPage = () => {
    setCurrentPage(prevPage => prevPage + 1)
  }

  const prevPage = () => {
    setCurrentPage(prevPage => prevPage - 1)
  }

  const goToPage = (page: number) => {
    setCurrentPage(page)
  }

  useMemo(() => {
    if (selectedLegalEntities) {
      setIsLegalEntityClusterSelected(true)
    } else {
      setIsLegalEntityClusterSelected(false)
    }
  }, [selectedLegalEntities])

  const heatmapButtonCondition = useMemo(() => {
    const definedOptionHighlightType =
      definedOption?.highlightType === 'isMei' ||
      definedOption?.highlightValue === 'censusUpdated' ||
      definedOption?.highlightType === 'perCapita' ||
      definedOption?.type === 'legalEntityAttendanceIndex'

    const highlightType = highlight && highlight.type ? true : false

    const result = definedOptionHighlightType || highlightType
    return result
  }, [definedOption, highlight])

  useMemo(() => {
    if (definedOption === null) {
      setSelectedLegalEntities(null)
      setLegalEntityModalSearch(null)
    }
  }, [definedOption])

  return (
    <AppLayout
      title={'Mapa'}
      initialMenuVisibility={menuVisibility}
      dockActive='map'
      menu={() => (
        <MapMenu
          handleOptionMapMenu={handleOptionMapMenu}
          definedOption={definedOption}
          onChangeFilter={onFilterChange}
          filter={filter}
          onChangeHighlight={onHighlightChange}
          maxValue={result.items ? result.items[0]?.legendValues[0]?.maxValue : null}
          minValue={result.items ? result.items[0]?.legendValues[0]?.minValue : null}
          minPercentColor={minPercentColor ? minPercentColor : null}
          maxPercentColor={maxPercentColor ? maxPercentColor : null}
          highlight={!(visualizationTypeId === 'heatmap') ? highlight : undefined}
          countSum={result.countSum}
          highlightSum={!(visualizationTypeId === 'heatmap') ? result.highlightSum : null}
          isLoading={result.isLoading}
          isHeatmapEnabled={visualizationTypeId === 'heatmap'}
          loadingMessage={result.queries}
          toggleMenuVisibility={toggleMenuVisibility}
          error={error}
          isHighlightCensusEnabled={isHighlightCensusEnabled}
          isHighlightProfitsEnabled={isHighlightProfitsEnabled}
          handleOutOfRangeEntities={setOutOfRangeEntities}
          handleTotalEntities={setTotalEntities}
        />
      )}
      card={
        !isMobile
          ? {
              title: selectedLegalEntityId ? `${legalEntityName}` : definedOption?.title ?? '',
              selectedLegalEntity: selectedLegalEntityId
                ? { selectedLegalEntityId, selectedLegalEntityName: String(legalEntityName) }
                : undefined,
              outOfRangeEntities:
                definedOption === null ||
                (highlight && highlight.type && (!highlight.eadOption || !filter?.eadOption)) ||
                (level?.geo !== 'neighborhood' &&
                  level?.geo !== 'street' &&
                  level?.geo !== 'city' &&
                  ((filter && filter.eadOption && filter.value) ||
                    (highlight && highlight.eadOption && highlight.value)))
                  ? undefined
                  : outOfRangeEntities,
              totalEntities:
                isLegalEntityClusterSelected === false && definedOption !== null
                  ? totalEntities
                  : undefined,
              onToggleMenu: closeMenu,
            }
          : {}
      }
    >
      <Wrapper>
        <Handle error={error}>
          {result.isLoading || heatmapIsLoading || (hasUserRoles && userGeoState.isLoading) ? (
            <OverlayLoading>
              <div className='center'>
                <PulseLoader color={'#fff'} />
                <span className='message'>
                  <LoadingQueriesMessage queries={result.queries} />
                </span>
              </div>
            </OverlayLoading>
          ) : null}

          {result.error ? <GeoprocessingMapError error={result.error} tryAgain={true} /> : null}

          {definedOption === null ? (
            <OverlayMap>
              <div className='center'>
                <Logo width={250} height={250} fill={colors.sebraeBlue} />
              </div>
            </OverlayMap>
          ) : null}

          {overlaySearchableMapCondition ? (
            <OverlaySearchableMap>
              <div className='center'>
                {filter && filter?.attendanceSource === null && !highlight ? (
                  <React.Fragment>
                    <span>
                      Selecione{' '}
                      {filter.type === 'legalNatureId' || filter.type === 'attendance'
                        ? 'uma '
                        : 'um '}
                      {secondaryValueAux[filter.type as unknown as keyof typeof secondaryValueAux]}{' '}
                    </span>
                    {isMobile ? <Button onClick={toggleMenuVisibility}>Selecionar</Button> : null}
                  </React.Fragment>
                ) : filter &&
                  filter?.attendanceSource === '5' &&
                  filter.eadOption === null &&
                  highlight?.eadOption !== null ? (
                  <>
                    <span>Selecione um nível</span>
                    {isMobile ? <Button onClick={toggleMenuVisibility}>Selecionar</Button> : null}
                  </>
                ) : filter &&
                  filter?.attendanceSource === '5' &&
                  filter.eadOption === 'individual' &&
                  !filter.courseProduct &&
                  !highlight ? (
                  <>
                    <span>Selecione um curso</span>
                    {isMobile ? <Button onClick={toggleMenuVisibility}>Selecionar</Button> : null}
                  </>
                ) : null}

                {highlight && highlight?.attendanceSource === null ? (
                  <>
                    <span>
                      Selecione{' '}
                      {highlight.type === 'legalNatureId' ||
                      highlight.type === 'attendance' ||
                      highlight.type === 'area' ||
                      highlight.type === 'perCapta' ||
                      isHighlightProfits
                        ? 'uma '
                        : 'um '}
                      {
                        secondaryValueAux[
                          highlight.type as unknown as keyof typeof secondaryValueAux
                        ]
                      }{' '}
                    </span>
                    {isMobile ? <Button onClick={toggleMenuVisibility}>Selecionar</Button> : null}
                  </>
                ) : highlight &&
                  highlight?.attendanceSource === '5' &&
                  highlight.eadOption === null ? (
                  <>
                    <span>Selecione um nível</span>
                    {isMobile ? <Button onClick={toggleMenuVisibility}>Selecionar</Button> : null}
                  </>
                ) : (highlight && highlight?.attendanceSource !== '5') ||
                  (highlight &&
                    highlight?.attendanceSource === '5' &&
                    (highlight.courseProduct ||
                      (highlight.eadOption && highlight.eadOption !== 'individual')) &&
                    highlight?.type !== 'courseDropout') ? (
                  <>
                    <span>Selecione um período</span>
                    {isMobile ? <Button onClick={toggleMenuVisibility}>Selecionar</Button> : null}
                  </>
                ) : highlight &&
                  highlight?.attendanceSource === '5' &&
                  highlight.eadOption === 'individual' &&
                  !highlight.courseProduct ? (
                  <>
                    <span>Selecione um curso</span>
                    {isMobile ? <Button onClick={toggleMenuVisibility}>Selecionar</Button> : null}
                  </>
                ) : null}
              </div>
            </OverlaySearchableMap>
          ) : null}

          {level && highlight && accessableLevel && isHighlightCensus ? (
            <OverlaySearchableMap>
              <div className='center'>
                <span>Sem dados do censo nessa visualização</span>
                <Button onClick={handleLastState}>Retornar para o nível estado</Button>
              </div>
            </OverlaySearchableMap>
          ) : null}

          {level && highlight && accessableLevel && isHighlightProfits ? (
            <OverlaySearchableMap>
              <div className='center'>
                <span>Sem dados de {messageProfits} nessa visualização</span>
                <Button onClick={handleLastState}>Retornar para o nível estado</Button>
              </div>
            </OverlaySearchableMap>
          ) : null}

          <GoogleMaps
            ref={map}
            mapTypeId={mapTypeId}
            onLoad={mapLoadAction}
            zoom={hasUserRoles && userGeoState.items?.length ? 7 : undefined}
            // tilt={visualizationTypeId === 'heatmap' ? 0 : undefined} // 0 remove o 3d
          >
            {userGeoState.isLoading === false
              ? result.items?.map((item, index) => (
                  <LegalEntityGeoItem
                    {...item}
                    key={String(index)}
                    isSelected={selectedGeoId === item.id}
                    isTouchable={isTouchable}
                    onClick={handleClick}
                    onClickNextLevelButton={handleClickNextLevelButton}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                    onMouseCenter={handleMouseCenter}
                    isMouseOnCenter={mouseOnCenter}
                    onDispose={handleDispose}
                    markerVariationForValue={markerVariationForValue}
                    markerVariationLoading={markerVariationLoading}
                    mapVisualization={{
                      type: mapTypeId,
                      visualizationType: visualizationTypeId,
                    }}
                  />
                ))
              : null}
            {visualizationTypeId === 'heatmap' && !selectedLegalEntityId && (
              <MapHeatmapLayer
                heatmapPoints={heatmapPoints}
                zoomLevel={zoomLevel}
                resolutionThreshold={resolutionThreshold}
              />
            )}
          </GoogleMaps>

          <MapLevelFieldOverlay>
            <EnumeratedField
              fieldSchema={enumerated(
                breadcrumbs.reduce(
                  (acc, breadcrumb) => ({
                    ...acc,
                    [`${breadcrumb.geo}.${breadcrumb.id}`]: breadcrumb.subTitle
                      ? `${breadcrumb.title} (${breadcrumb.subTitle})`
                      : breadcrumb.title,
                  }),
                  {} as { [key: string]: string },
                ),
              ).isRequired()}
              value={level ? `${level.geo}.${level.id}` : ''}
              onDirectChange={handleBreadcrumbChangeLevel}
            />
          </MapLevelFieldOverlay>

          <MapSearchFieldOverlay>
            <SelectFromQuery
              idProperty='id'
              labelProperty='corporateName'
              placeholder='Busque por empresas ou locais...'
              isSearchInput={true}
              isSearchComplete={isSearchDone}
              getLabel={item => (
                <div>
                  <TitleWrapper>
                    <span>
                      {item.tradeName !== null &&
                      String(item.tradeName)?.substring(0, 3) !== 'XXX' &&
                      String(item.tradeName)?.substring(0, 3) !== 'xxx'
                        ? item.tradeName
                        : item.corporateName}
                    </span>
                  </TitleWrapper>

                  <SubtitleWrapper>
                    <span>{item.subtitle}</span>
                  </SubtitleWrapper>
                </div>
              )}
              preLoad={false}
              queryFactory={legalEntitySearchWithGeoQueryFactory}
              onQueryComplete={queryResult =>
                queryResult
                  .sort((a, b) => (a.searchRank as number) - (b.searchRank as number))
                  .reverse()
                  .slice(0, 25)
                  .map(item => {
                    if (item.geo === 'legalEntity') {
                      return formatLegalEntitySearchItem(item)
                    }
                    return item
                  })
              }
              onDirectChange={item => {
                if (!item) {
                  return
                }
                onLevelChange(item as unknown as Level)
                setIsSearchDone(true)
              }}
            />
          </MapSearchFieldOverlay>

          <>
            <MapTypeOverlayFirstGroup>
              <button
                title='Mapa'
                className='button border-left'
                onClick={() => onMapTypeIdChange('map')}
              >
                <div className={mapTypeId !== 'map' ? 'svg-opacity' : ''}>
                  <MapIcon width={35} height={35} />
                </div>
              </button>

              <button
                title='Satélite'
                className='button border-right'
                onClick={() => onMapTypeIdChange('satellite')}
              >
                <div className={mapTypeId !== 'satellite' ? 'svg-opacity' : ''}>
                  <SatelliteIcon width={35} height={35} />
                </div>
              </button>
            </MapTypeOverlayFirstGroup>
            <MapTypeOverlaySecondGroup>
              <button
                title='Cloroplético'
                className='button border-left'
                onClick={() => handleHeatmap(false)}
                style={
                  heatmapButtonCondition === true
                    ? { borderTopRightRadius: '4px', borderBottomRightRadius: '4px' }
                    : {}
                }
              >
                <div className={visualizationTypeId === 'heatmap' ? 'svg-opacity' : ''}>
                  <Choropleth width={35} height={35} />
                </div>
              </button>

              {heatmapButtonCondition !== true ? (
                <button
                  title='Mapa de calor'
                  className='button border-right'
                  onClick={() => handleHeatmap(true)}
                >
                  <div className={!(visualizationTypeId === 'heatmap') ? 'svg-opacity' : ''}>
                    <HeatmapIcon width={35} height={35} />
                  </div>
                </button>
              ) : null}
            </MapTypeOverlaySecondGroup>
            <ChildrenGeoLevelOverlay>
              {childrenGeoLevelOptionsEntries.map(([key, value]) => (
                <button
                  key={key}
                  className={key === result.childrenGeoLevel ? 'active' : ''}
                  onClick={() =>
                    onChildrenGeoLevelChange(key as GeoChildrenLegalEntitiesPossibilities)
                  }
                >
                  {value}
                </button>
              ))}
            </ChildrenGeoLevelOverlay>
          </>

          <Modal
            isOpen={!!selectedLegalEntities}
            onClose={() => {
              setSelectedLegalEntities(null)
              setLegalEntityModalSearch(null)
            }}
            modalStyle='halfScreen'
            hideCloseButton={true}
            customStyles={{
              content: {
                maxWidth: '100%',
                background: 'transparent',
              },
            }}
            scrollY={isMobile ? true : false}
          >
            <EntitiesSection>
              <CardEntitiesList>
                <CardEntitiesListTitle>
                  <button
                    onClick={() => {
                      setSelectedLegalEntities(null)
                      setLegalEntityModalSearch(null)
                    }}
                  >
                    <LeftArrowIcon fill='#FFF' />
                  </button>
                  <h3>Lista de empresas</h3>
                  <div />
                </CardEntitiesListTitle>
              </CardEntitiesList>
              <BodyWrapper>
                {selectedLegalEntities && selectedLegalEntities.length > 10 && (
                  <SearchField
                    value={legalEntityModalSearch || ''}
                    onChange={setLegalEntityModalSearch}
                  />
                )}
                <ListHeaderRow>
                  <ListHeaderColumn>Nome da Empresa</ListHeaderColumn>
                  <ListHeaderColumn>CNPJ</ListHeaderColumn>
                </ListHeaderRow>
                {currentData && (
                  <>
                    {currentData.map(currentLegalEntity => (
                      <LegalEntityListButton
                        key={currentLegalEntity.id}
                        onClick={event => {
                          event.preventDefault()
                          onLevelChange({
                            geo: 'legalEntity',
                            id: currentLegalEntity.id,
                          })
                        }}
                      >
                        <ListRow>{writeEntitiesList(currentLegalEntity)}</ListRow>
                      </LegalEntityListButton>
                    ))}
                    <ListFooter
                      currentPage={currentPage}
                      totalPages={listData ? Math.ceil(listData.length / itemsPerPage) : 0}
                      itemsPerPage={itemsPerPage}
                      totalItems={listData ? listData.length : 0}
                      onNextPage={nextPage}
                      onPrevPage={prevPage}
                      onGoToPage={goToPage}
                    />
                  </>
                )}
              </BodyWrapper>
            </EntitiesSection>
          </Modal>
          <Modal
            isOpen={!!selectedLegalEntityId}
            onClose={() => onLevelChange(level ?? { geo: 'country', id: null })}
            modalStyle='fullScreen'
            scrollY={true}
            hideCloseButton={true}
          >
            {selectedLegalEntityId ? (
              <LegalEntityReadOne
                id={selectedLegalEntityId}
                onClose={() => onLevelChange(level ?? { geo: 'country', id: null })}
                enableBackButton={setEnableBackButton}
                setLegalEntityName={setLegalEntityName}
              />
            ) : null}
          </Modal>
        </Handle>
      </Wrapper>
    </AppLayout>
  )
}
