import type { InferNative, NativeBaseOptional, Type } from '../types/type'
import type { AbstractValueFunction, ValueFunctionContent } from './function'

export interface ConvertFunction<TypeGeneric extends Type, NativeGeneric extends NativeBaseOptional>
  extends AbstractValueFunction<NativeGeneric> {
  name: 'convert'
  target: TypeGeneric
}

export default function convert<
  TypeGeneric extends Type,
  NativeGeneric extends NativeBaseOptional = InferNative<TypeGeneric>,
>(content: ValueFunctionContent, target: TypeGeneric): ConvertFunction<TypeGeneric, NativeGeneric> {
  return {
    kind: 'function',
    name: 'convert',
    target,
    content,
  }
}
