import { defineAction, object } from '~/prix'
import seedStreetRoadReferenceAction from './seedStreetRoadReference.action.json'

const seedStreetRoadReferenceDefinition = defineAction({
  ...seedStreetRoadReferenceAction,
  input: object({}),
  output: object({}),
})

export default seedStreetRoadReferenceDefinition
