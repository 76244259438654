import { array, boolean, date, defineAction, enumerated, number, object, string } from '~/prix'
import attendanceGeoReportAction from './attendanceGeoReport.action.json'
import { multiPolygon, point } from '~/prix/types/geoJson'

const attendanceGeoReportDefinition = defineAction({
  ...attendanceGeoReportAction,
  input: object({
    searchQuery: string().isOptional(),
    agentCpf: string().isOptional(),
    startDate: date().isOptional(),
    endDate: date().isOptional(),
    legalEntityCnpj: string().isOptional(),
    ufAbbreviation: string().isOptional(),
    municipalityIbgeCode: string().isOptional(),
    legalEntitySize: array(string()).isOptional(),
    visit: number().isOptional(),
    legalEntityDistanceThreshold: number().isOptional(),
    streetDistanceThreshold: number().isOptional(),
    limit: number().isOptional(),
    pageSize: number().isOptional(),
    page: number().isOptional(),
  }),
  output: object({
    totalRows: number(),
    page: number(),
    pageSize: number(),
    warnings: array(
      object({
        message: string(),
      }),
    ).isOptional(),
    attendances: array(
      object({
        attendance: array(
          object({
            id: string(),
            startDate: date(),
            endDate: date(),
            instrument: enumerated({
              remoteConsultancy: 'Consultoria a distância',
              presentialConsultancy: 'Consultoria presencial',
              remoteReport: 'Informação a distância',
              presentialReport: 'Informação presencial',
              remoteTechnicalGuidance: 'Orientação técnica a distância',
              presentialTechnicalGuidance: 'Orientação técnica presencial',
            }),
            stateAbbreviation: string(),
            coordinates: point(),
            accuracy: number(),
            remoteJustification: string(),
          }),
        ),
        attendanceGeoValidation: array(
          object({
            isInsideMunicipality: boolean(),
            isInsideNeighborhood: boolean(),
            nearToStreet: boolean(),
            streetDistance: number(),
            nearToLegalEntity: boolean(),
            legalEntityDistance: number(),
            inLocoProbability: number(),
            inLoco: boolean(),
          }),
        ),
        attendant: array(
          object({
            id: string(),
            cpf: string(),
            name: string(),
          }),
        ),
        legalEntity: array(
          object({
            id: string(),
            cnpj: string(),
            corporateName: string(),
            tradeName: string(),
            coordinates: point(),
            sizeAbbreviature: enumerated({
              unknown: 'Desconhecido',
              MEI: 'Micro Empreendedor Individual',
              ME: 'Micro Empresa',
              EPP: 'Empresa de Pequeno Porte',
            }).isOptional(),
          }),
        ),
      }),
    ),
  }),
})

export default attendanceGeoReportDefinition
