import { descending, entity, equals, query, string } from '~/prix'

export default function courseRegisterIndividualListQuery(id: string) {
  return (
    query('courseRegisterIndividual')
      .select({
        courseProductId: entity('courseRegisterIndividual').property('courseProductId'),
        courseProductCode: entity('courseProduct').property('productCode'),
        courseProductName: entity('courseProduct').property('productName'),
        courseProductType: entity('courseProduct').property('productType'),
        courseProductPortfolioSasCode: entity('courseProduct').property('portfolioSasCode'),
        isActivePortfolio: entity('courseProduct').property('isActivePortfolio'),
        registerDate: entity('courseRegisterIndividual').property('registerDate'),
        courseCompletionPercentage: entity('courseRegisterIndividual').property('courseCompletionPercentage'),
      })
      .join({
        current: entity('courseRegisterIndividual').property('courseProductId'),
        target: entity('courseProduct').property('id'),
        join: 'inner',
      })
      .join({
        current: entity('courseRegisterIndividual').property('legalEntityId'),
        target: entity('legalEntity').property('id'),
        join: 'inner',
      })

      .where(equals(entity('legalEntity').property('id'), string().value(id)))
      .order(descending('registerDate'))
      .limit(5000)
  )
}