import { defineAction, object } from '~/prix'
import neighborhoodLowerQualityBoundariesAction from './neighborhoodLowerQualityBoundaries.action.json'

const neighborhoodLowerQualityBoundariesDefinition = defineAction({
  ...neighborhoodLowerQualityBoundariesAction,
  input: object({}),
  output: object({}),
})

export default neighborhoodLowerQualityBoundariesDefinition
