import { defineEntity, entity, foreign, object, string, number } from '~/prix'
import { multiPolygon, point } from '~/prix/types/geoJson'

const mesoRegionEntity = defineEntity({
  key: 'mesoRegion',
  title: 'Mesorregião',
  sources: ['olap', 'oltp'],
  schema: object({
    id: string().label('ID', 'Identificador', 'Código'),
    name: string().label('Nome', 'Mesorregião'),
    ibgeCode: number().label('Código do IBGE'),
    center: point().label('Centro'),
    boundary: multiPolygon().label('Polígono'),
    lowerQualityBoundary: multiPolygon().label('Polígono de baixa qualidade'),
    stateId: foreign(entity('state').property('id')),
  }),
})

export default mesoRegionEntity
