import createType, { AbstractType } from './type'
import { InferNative } from './type'

export type ArrayBase = Array<any>

export interface ArrayType<
  NativeShapeGeneric extends ArrayBase | null = ArrayBase
> extends AbstractType<NativeShapeGeneric> {
  readonly type: 'array'
  readonly content: AbstractType<any>
  readonly currentValue?: NativeShapeGeneric
  isRequired(): ArrayType<Exclude<NativeShapeGeneric, null>>
  isOptional(): ArrayType<NativeShapeGeneric | null>
}

export default function array<
  NativeGeneric extends Array<NativeGeneric>,
  ContentTypeGeneric extends AbstractType,
  InferedContent = InferNative<ContentTypeGeneric>
>(contentType: ContentTypeGeneric): ArrayType<Array<InferedContent>> {
  return {
    ...createType<ArrayType<Array<InferedContent>>>(),
    type: 'array',
    content: contentType,
  }
}
