import { defineAction, object } from '~/prix'
import fetchAttendantFromRadarAliAction from './fetchAttendantFromRadarAli.action.json'

const fetchAttendantFromRadarAliDefinition = defineAction({
  ...fetchAttendantFromRadarAliAction,
  input: object({}),
  output: object({}),
})

export default fetchAttendantFromRadarAliDefinition
