import react, { useEffect, useState } from 'react'
import useAsync from 'react-use/lib/useAsync'
import useAsyncState from './asyncState'
// import handleError from '~/utils/shared/handleError'
import { isTouchDevice } from '~/utils/shared/platform'
import compileDeviceInfo from '~/prix/utils/device'

export default function useDevice() {
  return useAsync(compileDeviceInfo, [])
}

export const widthToSizeName = (width: number): string => (width <= 768 ? 'mobile' : 'desktop')
export function useDeviceSize(callback: () => void): string {
  const [sizeName, setSizeName] = useAsyncState<String | null>(null)

  useEffect((): (() => void) => {
    const update = async () => {
      try {
        const newSizeName = widthToSizeName(window.innerWidth)
        if (sizeName !== newSizeName) {
          await setSizeName(newSizeName)
          if (callback) {
            callback()
          }
        }
      } catch (error) {
        // handleError(error)
      }
    }
    update()

    window.addEventListener('resize', update)
    return () => {
      window.removeEventListener('resize', update)
    }
  }, [])


  return sizeName
}

export function useIsTouchDevice() {
  const [isTouch, setIsTouch] = useState<boolean | null>(null)

  useEffect(() => setIsTouch(isTouchDevice), [])

  return isTouch
}
