import {
  GoogleMap,
  StreetViewPanorama,
  Marker,
  InfoWindow,
  StreetViewService,
} from '@react-google-maps/api'
import React, { useState, useCallback, useMemo, useEffect } from 'react'
import useMedia from 'react-use/lib/useMedia'
import styled from 'styled-components'
import { format, parseISO } from 'date-fns'
import { entity, equals, query, string } from '~/prix'
import asGeoJson from '~/prix/functions/asGeoJson'
import useItem from '~/prix/react/hooks/item'
import Handle from '~/prix/react/components/handle'
import useItems from '~/prix/react/hooks/items'
import List from '~/components/list'
import TopCard from '~/components/topCard'
import AlertIcon from '~/components/icons/ui/16px_alert-2.svg'
import attendanceListQuery from '~/packages/legalEntityAttendance/list/legalEntityAttendanceList.query'
import legalEntityIndividualListQuery from '~/packages/legalEntityIndividual/list/legalEntityIndividualList.query'
import cnaeListQuery from '~/packages/cnae/list/legalEntityCnaeList.query'
import DataForm from './legalEntityReadOneForm'
import LeftArrowIcon from '~/meta/angle-left-arrow.svg'
import { colors } from '~/design'
import PulseLoader from 'react-spinners/PulseLoader'
import LoadingMessage from '~/prix/react/components/loadingMessage'
import courseRegisterIndividualListQuery from '~/packages/courseRegisterIndividual/list/courseRegisterIndividualList.query'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 1;

  article {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 6.5rem;
    row-gap: 4.1rem;
    flex: 1;
    margin: 1rem 64px;
    color: ${({ theme }) => theme.colors.darkGrey};
    padding-bottom: 75px;
    justify-items: center;
    z-index: 5;
    position: relative;

    h1 {
      font-size: 1.2rem;
      margin-top: 0;
      margin-bottom: 3px;
      font-weight: bold;
      color: ${({ theme }) => theme.colors.primary};
    }

    span b {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.primary};
    }

    .info {
      display: flex;
      flex-direction: column;

      .span-info {
        padding: 2px 0px 2px 0px;
      }
    }

    .map {
      margin-top: 20px;
      height: 100%;
      justify-self: normal;
    }

    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
      box-shadow: 0px 32px 30px rgba(20, 46, 82, 0.2);
      border-radius: 12px;
      margin: 0 64px 64px 64px;
      padding: 0 24px 64px 24px;

      .map {
        padding: 0 16px;
      }
    }

    @media (max-width: 550px) {
      margin: 0 32px;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`

const StreetViewInfoContainer = styled.div`
  margin-top: 5px;
  display: flex;
  font-weight: bold;
`

const StreetViewInfoContent = styled.span`
  padding-left: 5px;
`

const CardReadOne = styled.div`
  display: none;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex: 1;
    gap: 35px;
    margin: 64px 64px 0 64px;
  }

  @media (max-width: 768px) {
    margin-top: 12px;
  }

  @media (max-width: 550px) {
    margin: 10px 32px;
  }
`

const CardReadOneTitle = styled.div`
  @media (max-width: 1024px) {
    display: flex;
    flex: 1;
    margin: -40px 16px;
    border-radius: 8px 8px 0px 0px;
    background: #0f438a;
    padding: 25px;
    width: 100%;
    box-shadow: 0px 32px 30px rgba(20, 46, 82, 0.2);
    height: 150px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    gap: 10px;

    button {
      height: 3rem;
      width: 3rem;
      padding: 8px;
      align-items: center;
      gap: 4px;
      flex-shrink: 0;

      border: none;
      border-radius: 100px;
      background: #005eb8;

      svg {
        margin-top: 2px;
        fill: #fff;
      }
    }

    h3 {
      color: #fff;
      font-weight: 400;
      line-height: 23.3px;
      font-size: 2rem;

      @media (max-width: 768px) {
        line-height: normal;
      }
    }

    div {
      min-width: 3rem;
    }
  }

  @media (max-width: 768px) {
    padding: 15px 25px;
  }
`

const LoadingWrapper = styled.div`
  display: flex;
  flex: 1;
  align-self: center;
  justify-content: center;

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .loader {
      display: flex;
      height: 50px;
      flex-direction: row;
      align-items: center;
    }

    .message {
      display: flex;
      height: 50px;
      flex-direction: row;
      font-size: 16px;
      text-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
    }
  }
`

const AttendanceStatus = styled.div`
  display: flex;
  width: 118px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;

  border-radius: 4px;
  background: #ffc1b2;

  color: #b22400;
  text-align: center;
  font-weight: 400;
`

const ListWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  box-shadow: 0px 10px 20px 0px rgba(20, 46, 82, 0.2);
  border-radius: 12px;
  min-width: 0;
`

export interface LegalEntityReadOne {
  id: string
  onClose?: any
  enableBackButton?: any
  setLegalEntityName?: any
}

const relationshipAux = {
  selfReported: 'Autorreferido',
  partner: 'Sócio',
  collaborator: 'Colaborador',
  unknown: 'Desconhecido',
}

export default function LegalEntityReadOne({
  id,
  onClose,
  enableBackButton,
  setLegalEntityName,
}: LegalEntityReadOne) {
  const [validPano, setValidPano] = useState<google.maps.StreetViewPanoramaData | null>(null)
  const [status, setStatus] = useState<string | undefined>(undefined)
  const isMobileLarge = useMedia('(max-width: 1024px)')

  const { error, isLoading, item } = useItem(
    () =>
      query('legalEntity')
        .select({
          tradeName: entity('legalEntity').property('tradeName'),
          corporateName: entity('legalEntity').property('corporateName'),
          cnpj: entity('legalEntity').property('cnpj'),
          publicPlace: entity('legalEntity').property('publicPlace'),
          number: entity('legalEntity').property('number'),
          complement: entity('legalEntity').property('complement'),
          neighborhood: entity('legalEntity').property('neighborhood'),
          city: entity('legalEntity').property('city'),
          state: entity('legalEntity').property('state'),
          postalCode: entity('legalEntity').property('postalCode'),
          pointOnStreet: asGeoJson(entity('legalEntityGeoprocessing').property('pointOnStreet')),
          pointOnStreetDirectionAngle: entity('legalEntityGeoprocessing').property(
            'pointOnStreetDirectionAngle',
          ),
          sector: entity('legalEntity').property('sector'),
          size: entity('legalEntity').property('size'),
          openingDate: entity('legalEntity').property('openingDate'),
          email: entity('legalEntity').property('email'),
          phoneNumberOneAreaCode: entity('legalEntity').property('phoneNumber_1AreaCode'),
          phoneNumberOne: entity('legalEntity').property('phoneNumber_1'),
          phoneNumberTwoAreaCode: entity('legalEntity').property('phoneNumber_2AreaCode'),
          phoneNumberTwo: entity('legalEntity').property('phoneNumber_2'),
        })
        .join({
          current: entity('legalEntity').property('id'),
          target: entity('legalEntityGeoprocessing').property('legalEntityId'),
        })
        .where(equals(entity('legalEntity').property('id'), string().value(id)))
        .limit(1),
    [id],
  )

  const attendanceList = useItems(() => attendanceListQuery(id), [id], {
    cache: 60 * 60 * 8,
    autoLoad: isLoading === false,
  })

  const courseRegisterIndividualList = useItems(() => courseRegisterIndividualListQuery(id), [id], {
    cache: 60 * 60 * 8,
    autoLoad: isLoading === false,
  })

  const formattedCourseRegisterIndividualList = useMemo(() => {
    const attendance = courseRegisterIndividualList.items?.map(item => ({
      courseProductCode: item.courseProductCode,
      courseProductName: item.courseProductName,
      courseProductType: item.courseProductType,
      courseProductPortfolioSasCode: item.courseProductPortfolioSasCode,
      isPortfolio: item.isActivePortfolio ? 'Ativo' : 'Inativo',
      registerDate:
        item?.registerDate === null
          ? null
          : format(parseISO(item.registerDate as string), 'dd/MM/yyyy'),
      courseCompletionPercentage: item.courseCompletionPercentage
        ? `${item.courseCompletionPercentage.toString().replace('.', ',')}%`
        : 'Sem dados',
    }))

    return attendance
  }, [attendanceList])

  useEffect(() => {
    if (setLegalEntityName) {
      setLegalEntityName(
        item?.tradeName ? item?.tradeName : item?.corporateName ? item?.corporateName : '',
      )
    }
  }, [item])

  const formattedAttendanceList = useMemo(() => {
    const attendance = attendanceList.items?.map(item => ({
      dataSourceName: item.dataSourceName,
      endDate:
        item?.endDate === null ? null : format(parseISO(item.endDate as string), 'dd/MM/yyyy'),
    }))

    return attendance
  }, [attendanceList])

  const legalEntityIndividualList = useItems(() => legalEntityIndividualListQuery(id), [id], {
    cache: 60 * 60 * 8,
    autoLoad: isLoading === false,
  })

  const formattedLegalEntityIndividualList = useMemo(() => {
    const individual = legalEntityIndividualList.items?.map(item => ({
      name: item.name,
      relationship: relationshipAux[item.relationship as unknown as keyof typeof relationshipAux],
    }))
    return individual
  }, [legalEntityIndividualList.items])

  const cnaeList = useItems(() => cnaeListQuery(id), [id], {
    cache: 60 * 60 * 8,
    autoLoad: isLoading === false,
  })

  const mainCnae = useMemo(() => {
    const cnaes = cnaeList.items
      ?.map(item => ({
        cnae_id: item.cnaeId,
        description: item.description,
        is_main: item.isMain,
        segmentDescription: item.segmentDescription,
      }))
      .filter(item => item.is_main === true)

    return cnaes
  }, [cnaeList.items])

  const pointOnStreetDirectionAngle = item?.pointOnStreetDirectionAngle as number | null

  const streetViewCoordinates = useMemo(() => {
    const pointOnStreet =
      item?.pointOnStreet ??
      (null as {
        coordinates: number[]
      } | null)

    return pointOnStreet
      ? {
          lng: pointOnStreet.coordinates[0],
          lat: pointOnStreet.coordinates[1],
        }
      : null
  }, [item?.pointOnStreet])

  const onLoad = useCallback(
    (streetViewService: google.maps.StreetViewService) => {
      streetViewService.getPanorama(
        {
          location: streetViewCoordinates,
          radius: 50,
          source: google.maps.StreetViewSource.OUTDOOR,
        },
        (data, status) => {
          setStatus(status)
          status === google.maps.StreetViewStatus.OK ? setValidPano(data) : null
        },
      )
    },
    [streetViewCoordinates],
  )

  const handleBackButton = (value: boolean) => {
    enableBackButton(value)
  }

  return (
    <Handle error={error}>
      {isLoading ? (
        <LoadingWrapper>
          <div className='center'>
            <div className='loader'>
              <PulseLoader color={colors.sebraeBlue} />
            </div>
            <span className='message'>
              <LoadingMessage
                messages={[
                  'Pesquisando por informações da empresa selecionada',
                  'Resgatando dados referentes a empresa selecionada',
                  'Carregando quantitativos da empresa selecionada',
                ]}
              />
            </span>
          </div>
        </LoadingWrapper>
      ) : (
        <Wrapper>
          {isMobileLarge && (
            <>
              <TopCard title={'Dados da Empresa'} />
              <CardReadOne>
                <CardReadOneTitle>
                  <button
                    onClick={event => {
                      event.preventDefault()
                      onClose()
                    }}
                  >
                    <LeftArrowIcon fill='#fff' />
                  </button>
                  <h3>{item?.tradeName ?? item?.corporateName}</h3>
                  {/* <EmptyHeartIcon /> */}
                  <div />
                </CardReadOneTitle>
                {/* <AttendanceStatus>
            Não atendido
          </AttendanceStatus> */}
              </CardReadOne>
            </>
          )}
          {!isMobileLarge ? handleBackButton(true) : handleBackButton(false)}
          <article>
            {streetViewCoordinates ? (
              <div className='map'>
                <GoogleMap
                  mapContainerStyle={
                    status !== undefined
                      ? {
                          width: '100%',
                          height: isMobileLarge ? '300px' : '93%',
                        }
                      : { display: 'none' }
                  }
                  center={streetViewCoordinates}
                  zoom={15}
                >
                  <Marker visible={true} position={streetViewCoordinates}>
                    {status === 'ZERO_RESULTS' ? (
                      <InfoWindow>
                        <StreetViewNotAvailableInfo />
                      </InfoWindow>
                    ) : null}
                  </Marker>
                  <StreetViewService onLoad={onLoad} />
                  <StreetViewPanorama
                    options={{
                      zoom: 1,
                      pano: validPano && validPano.location ? validPano.location.pano : undefined,
                      visible: status === 'ZERO_RESULTS' ? false : true,
                      pov: pointOnStreetDirectionAngle
                        ? { heading: pointOnStreetDirectionAngle, pitch: 0 }
                        : undefined,
                    }}
                  />
                </GoogleMap>
                {status === 'ZERO_RESULTS' && <StreetViewNotAvailableInfo />}
              </div>
            ) : null}

            <DataForm item={item} cnae={mainCnae} readOnly={true} />

            <ListWrapper>
              <List
                title={'Histórico de Atendimento'}
                items={formattedAttendanceList}
                isLoading={attendanceList.isLoading}
                labels={{
                  dataSourceName: 'Fonte de dados',
                  endDate: 'Data',
                }}
              />
            </ListWrapper>

            <ListWrapper>
              <List
                title={'Histórico de Atendimento - Pessoa Física'}
                items={formattedLegalEntityIndividualList}
                isLoading={legalEntityIndividualList.isLoading}
                labels={{
                  name: 'Nome',
                  relationship: 'Relação',
                }}
              />
            </ListWrapper>

            <ListWrapper style={{ gridColumn: !isMobileLarge ? 'span 2' : 'span 1' }}>
              <List
                title={'Cursos EaD'}
                items={formattedCourseRegisterIndividualList}
                isLoading={legalEntityIndividualList.isLoading}
                labels={{
                  courseProductCode: isMobileLarge ? 'Dados Gerais' : 'AVA',
                  courseProductName: 'Nome',
                  courseProductType: 'Tipo',
                  courseCompletionPercentage: isMobileLarge ? '' : 'Porcentagem de Conclusão',
                  isPortfolio: 'Portfólio Atual',
                  registerDate: 'Matrícula',
                }}
                fractions={
                  !isMobileLarge
                    ? {
                        courseProductCode: 0.15,
                        courseProductName: 0.35,
                        courseProductType: 0.15,
                        courseCompletionPercentage: 0.15,
                        isPortfolio: 0.15,
                        registerDate: 0.15,
                      }
                    : {
                        courseProductCode: 1 / 2,
                        courseCompletionPercentage: 1 / 2,
                      }
                }
                hideColumn={
                  isMobileLarge
                    ? ['courseProductName', 'courseProductType', 'isPortfolio', 'registerDate']
                    : undefined
                }
                customCells={
                  isMobileLarge
                    ? {
                        courseProductCode: (item: any) => [
                          <>
                            <strong>AVA:</strong> {item.courseProductCode}
                          </>,
                          <>
                            <strong>Nome:</strong> {item.courseProductName}
                          </>,
                          <>
                            <strong>Tipo:</strong> {item.courseProductType}
                          </>,
                        ],
                        courseCompletionPercentage: (item: any) => [
                          <>
                            <strong>Conclusão:</strong> {item.courseCompletionPercentage}
                          </>,
                          <>
                            <strong>Portfólio Atual:</strong> {item.isPortfolio}
                          </>,
                          <>
                            <strong>Matrícula:</strong> {item.registerDate}
                          </>,
                        ],
                      }
                    : null
                }
              />
            </ListWrapper>
          </article>
        </Wrapper>
      )}
    </Handle>
  )
}

const StreetViewNotAvailableInfo = () => {
  return (
    <StreetViewInfoContainer>
      <AlertIcon width={18} height={16} fill={'#f1c40f'} />
      <StreetViewInfoContent>
        Dados de StreetView indisponíveis para este endereço.
      </StreetViewInfoContent>
    </StreetViewInfoContainer>
  )
}
