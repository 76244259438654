import React, { memo } from 'react'
import useMedia from 'react-use/lib/useMedia'
import styled from 'styled-components'
import StarIcon from '~/meta/report/star.svg'
import StarBlueIcon from '~/meta/report/starBlue.svg'
import AlertIcon from '~/components/icons/ui/16px_alert.svg'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .header-card {
    display: flex;
    height: 75px;
    background-color: transparent;
    padding: 10px;

    .title-wrapper {
      display: flex;
      flex-direction: column;

      .title {
        color: #0F438A;
        font-size: 22px;
        font-weight: 600;
        padding-bottom: 5px;
      }

      .subtitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .icon {
          margin-top: 3px; 
          margin-right: 3px;
        }

        span {
          color: #0F438A;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  .list {
    display: flex;
    border: 1px solid #B6BFC8;
    border-radius: 20px;
    padding: 20px;

    .item-wrapper {
      display: flex; 
      flex: 1;
      flex-direction: column;
      padding: 0px 20px 0px 20px;
    }
  }
`

const Item = styled.div`
  display: flex;
  height: 60px;
  padding: 10px 0px 10px 0px;
  align-items: center;
        
  .description {
    width: 40%;
    align-items: center;
    line-height: 18.5px;

    @media (max-width: 768px) {
      width: 32.5%;
    }
    
    span {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #121921;
      font-size: 16px;

      @media (max-width: 670px) {
        font-size: 14px;
        padding-right: 5px;
      }
    }
  }

  .stars {
    width: 35%;
    align-items: center;
    padding-top: 3px;

    @media (max-width: 768px) {
      line-height: 12px;
      width: 42.5%;
    }

    span {
      padding-top: 5px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .score {
    width: 25%;
    text-align: center;
    line-height: 18.5px;

    span {
      color: #121921;
      font-size: 16px;

      @media (max-width: 670px) {
        font-size: 14px;
      }
    }
  }
`

interface LegalEntityGeoprocessingMapDemarcationReportIndicatorProps {
  title: string
  subtitle: string
  values?: any
}

function legalEntityGeoprocessingMapDemarcationReportIndicator({
  title,
  subtitle,
  values,
}: LegalEntityGeoprocessingMapDemarcationReportIndicatorProps) {
  const indicators = values.result?.indicators
  const legalEntityCount = values.result?.legalEntityCount
  const firstSizeCondition = useMedia('(max-width: 1400px)')
  const secondSizeCondition = useMedia('(max-width: 500px)')
  const starSize = firstSizeCondition && !secondSizeCondition ? 20 : secondSizeCondition ? 15 : 28

  return (
    <Wrapper>
      <div className='header-card'>
        <div className='title-wrapper'>
          <span className='title'>{title}</span>

          <div className='subtitle'>
            {legalEntityCount <= 3 ?
              <div className='icon'>
                <AlertIcon fill={'#f1c40f'} width={18} height={18} />
              </div>
              : null}
            <span>
              {subtitle}
            </span>
          </div>
        </div>
      </div>
      <div className='list'>
        <div className='item-wrapper'>

          {indicators?.map((item, index: number) =>
            <Item key={index}>
              <div className='description'>
                <span>
                  {item.name}:
                </span>
              </div>
              <div className='stars'>
                <span>
                  {item.count >= 1 ? <StarBlueIcon width={starSize} height={starSize} /> : <StarIcon width={starSize} height={starSize} />}
                </span>
                <span>
                  {item.count >= 2 ? <StarBlueIcon width={starSize} height={starSize} /> : <StarIcon width={starSize} height={starSize} />}
                </span>
                <span>
                  {item.count >= 3 ? <StarBlueIcon width={starSize} height={starSize} /> : <StarIcon width={starSize} height={starSize} />}
                </span>
                <span>
                  {item.count >= 4 ? <StarBlueIcon width={starSize} height={starSize} /> : <StarIcon width={starSize} height={starSize} />}
                </span>
                <span>
                  {item.count === 5 ? <StarBlueIcon width={starSize} height={starSize} /> : <StarIcon width={starSize} height={starSize} />}
                </span>
              </div>
              <div className='score'>
                <span>
                  Pontuação: {Number(item?.count).toFixed(2).replace('.', ',')} {firstSizeCondition === false ? ' de 5' : ''}
                </span>
              </div>
            </Item>
          )}

        </div>
      </div>
    </Wrapper>
  )
}

export default memo(legalEntityGeoprocessingMapDemarcationReportIndicator)