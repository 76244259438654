import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import HomeScreen from '~/packages/home/home.screen'
import DashboardScreen from './dashboard/dashboard.screen'
import OptionsScreen from './option/option.screen'
import LegalEntityReadScreen from './legalEntity/read/legalEntityRead.screen'
import MapScreen from './legalEntityGeoprocessing/map/legalEntityGeoprocessingMapFromURL.screen'
import TimeSeriesScreen from './timeSeries/timeSeries.screen'
import NotFound from './notFound/notFound.screen'
import SystemAndInformation from './systemAndInformation/systemAndInformation'
import AttendanceRadarMap from './legalEntityGeoprocessing/radar/attendanceRadarMap.screen'
import DisproportionateAttentionMap from './legalEntityAttendance/disproportionateAttention/map/disproportionateAttentionMapFromURL.screen'
import Loader from '~/prix/react/components/loader'
import AttendantProductionSelectableChart from './attendant/productionChart/attendantProductionSelectableChart.screen'
import AttendantProductionIndividualChart from './attendant/productionChart/attendantProductionIndividualChart.screen'
import AttendantSelectableMap from './attendant/map/attendantSelectableMap.screen'
import AttendantIndividualMap from './attendant/map/attendantIndividualMap.screen'
import MapDemarcation from './legalEntityGeoprocessing/mapDemarcation/legalEntityGeoprocessingMapDemarcation.screen'
import MapDemarcationPolygon from './legalEntityGeoprocessing/mapDemarcation/legalEntityGeoprocessingMapDemarcationPolygon.screen'
import MapDemarcationRadius from './legalEntityGeoprocessing/mapDemarcation/legalEntityGeoprocessingMapDemarcationRadius.screen'
import MapDemarcationRegion from './legalEntityGeoprocessing/mapDemarcation/legalEntityGeoprocessingMapDemarcationRegion.screen'
import ReportReadScreen from './report/read/reportRead.screen'
import AgentReportScreen from './agentReport/agentReport.screen'
import TermsScreen from './terms/terms.screen'
import NotificationSettingsScreen from './notifications/settings/notificationSettings.screen'

const ExplorerScreen = React.lazy(() => import('./explorer/explorer.screen'))

export default function Router() {
  return (
    <BrowserRouter>
      <React.Suspense fallback={<Loader />}>
        <Routes>
          <Route path='/' element={<HomeScreen />} />
          <Route path='/index.html' element={<HomeScreen />} />
          <Route path='/terms' element={<TermsScreen />} />
          <Route path='/app'>
            <Route index element={<DashboardScreen />} />
            <Route path='/app/person' element={<LegalEntityReadScreen />} />
            <Route path='/app/map' element={<MapScreen />} />
            <Route path='/app/map/demarcation' element={<MapDemarcation />} />
            <Route
              path='/app/map/demarcation/polygon/:geohashes'
              element={<MapDemarcationPolygon />}
            />
            <Route
              path='/app/map/demarcation/radius/:geohash/:radius'
              element={<MapDemarcationRadius />}
            />
            <Route
              path='/app/map/demarcation/region/:geo/:ids'
              element={<MapDemarcationRegion />}
            />
            <Route path='/app/map/:by/:id' element={<MapScreen />} />
            <Route path='/app/map/:by/:id/:childrenGeoLevel' element={<MapScreen />} />
            <Route path='/app/time-series' element={<TimeSeriesScreen />} />
            <Route path='/app/time-series/:timeSeriesKey' element={<TimeSeriesScreen />} />
            <Route path='/app/time-series/:timeSeriesKey/:state' element={<TimeSeriesScreen />} />
            <Route path='/app/time-series/:timeSeriesKey/:state/:eventDate' element={<TimeSeriesScreen />} />
            <Route path='/app/options' element={<OptionsScreen />} />
            <Route path='/app/explorer' element={<ExplorerScreen />} />
            <Route path='/app/explorer/:actionKey/:triggerIndex' element={<ExplorerScreen />} />
            <Route path='/app/options/system-and-information' element={<SystemAndInformation />} />
            <Route path='/app/options/notifications' element={<NotificationSettingsScreen />} />
            <Route path='/app/attendance-radar' element={<AttendanceRadarMap />} />
            <Route path='/app/report/:key' element={<ReportReadScreen />} />
            <Route path='/app/report/:key/:hash' element={<ReportReadScreen />} />
            <Route
              path='/app/disproportionate-attention'
              element={<DisproportionateAttentionMap />}
            />
            <Route
              path='/app/disproportionate-attention/:by/:id'
              element={<DisproportionateAttentionMap />}
            />
            <Route
              path='/app/disproportionate-attention/:by/:id/:childrenGeoLevel'
              element={<DisproportionateAttentionMap />}
            />
            <Route
              path='/app/attendant-production-selectable-chart'
              element={<AttendantProductionSelectableChart />}
            />
            <Route
              path='/app/attendant-production-individual-chart'
              element={<AttendantProductionIndividualChart />}
            />
            <Route path='/app/attendant-selectable-map' element={<AttendantSelectableMap />} />
            <Route path='/app/attendant-individual-map' element={<AttendantIndividualMap />} />
            <Route path='/app/agent-report' element={<AgentReportScreen />} />
            <Route path='/app/agent-report/:agentId' element={<AgentReportScreen />} />
            <Route
              path='/app/agent-report/:agentHash/:dataSourceId'
              element={<AgentReportScreen />}
            />
          </Route>
          <Route path='*' element={<NotFound />} />
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  )
}
