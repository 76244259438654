import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { institutionalColors, primaryColors } from '~/design'
import FirstPageIcon from '~/meta/firstPageArrow.svg'
import LastPageIcon from '~/meta/lastPageArrow.svg'

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  background: #fff;
  flex-shrink: 1;
`

const ListTitle = styled.div`
  border-radius: 12px 12px 0px 0px;
  background: ${primaryColors.action};
  min-height: 43px;
  display: block;
  padding: 7px;

  p {
    margin-left: 1.7rem;
    color: #fff;
    font-size: 1.31034rem;
    font-weight: 700;
  }
`

const ListRow = styled.div<{ customHeight?: number; customCell?: boolean }>`
  display: flex;
  flex-direction: row;
  height: ${({ customHeight, customCell }) =>
    customHeight || customCell ? (customHeight ? `${customHeight}px` : 'auto') : '46px'};
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  flex-shrink: 1;
  padding: 0 24px;
`

const ListHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 46px;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  margin-top: 14px;
  padding: 0 24px;
`

const ListHeaderColumn = styled.div`
  display: block;
  color: ${institutionalColors.graySebrae60};
  font-size: 0.89rem;
  font-weight: 700;
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 8px;
`

const ListData = styled.div`
  display: block;
  padding-right: 8px;

  overflow: hidden;
  color: ${institutionalColors.graySebrae30};
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.89655rem;
  font-weight: 400;
`

const ListNoDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100px;
  align-items: center;
  justify-content: center;
  border-radius: 0rem 0rem 0.82759rem 0.82759rem;

  span {
    color: #8b99a7;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.3px;
  }
`

const ListFooterComponent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 46px;
  align-items: center;
  align-content: baseline;
  border-radius: 0rem 0rem 0.82759rem 0.82759rem;
  justify-content: flex-end;
  row-gap: 5px;
  padding: 15px 0;

  div {
    > button {
      border: none;
      background: none;
      font-size: 13px;
      border-radius: 50%;
      color: ${institutionalColors.graySebrae60};
      font-weight: 400;
      padding: 2px 6px;
      cursor: pointer;

      svg {
        margin-bottom: -3.9px;
        fill: #8b99a7;
      }
    }

    .active {
      border-radius: 50%;
      border: 1px solid #8fb8ef;
      background: #5c8fd6;

      color: #fff;
      text-align: center;
      font-weight: 400;
    }
  }

  span {
    color: ${institutionalColors.graySebrae60};
    font-size: 13px;
    font-weight: 400;
  }
`

const CustomCellStyle = styled.div`
  display: block;
  padding: 14px 6px 14px 6px;
  min-width: 0;

  p {
    margin: 3px 0;
    padding: 0;
    color: ${institutionalColors.graySebrae30};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.89655rem;
    font-weight: 400;
    width: 100%;
  }
`

type ListFooterProps = {
  currentPage: number
  totalPages: number
  itemsPerPage: number
  totalItems: number
  onNextPage: () => void
  onPrevPage: () => void
  onGoToPage: (page: number) => void
}

export const ListFooter: React.FC<ListFooterProps> = ({
  currentPage,
  totalPages,
  itemsPerPage,
  totalItems,
  onNextPage,
  onPrevPage,
  onGoToPage,
}) => {
  const startItem = (currentPage - 1) * itemsPerPage + 1
  const endItem = Math.min(currentPage * itemsPerPage, totalItems)

  return (
    <ListFooterComponent>
      {totalItems > itemsPerPage ? (
        <div className='buttons'>
          <button onClick={() => onGoToPage(1)} disabled={currentPage === 1}>
            <FirstPageIcon width={16} height={16} />
          </button>
          <button onClick={onPrevPage} disabled={currentPage === 1}>
            {'<'}
          </button>
          {currentPage > 1 && (
            <button onClick={() => onGoToPage(currentPage - 1)}>{currentPage - 1}</button>
          )}
          <button disabled className='active'>
            {currentPage}
          </button>
          {currentPage < totalPages && (
            <button onClick={() => onGoToPage(currentPage + 1)}>{currentPage + 1}</button>
          )}
          <button onClick={onNextPage} disabled={currentPage === totalPages}>
            {'>'}
          </button>
          <button onClick={() => onGoToPage(totalPages)} disabled={currentPage === totalPages}>
            <LastPageIcon width={16} height={16} />
          </button>
        </div>
      ) : null}
      <span>
        Mostrando {startItem} - {endItem} de {totalItems} itens
      </span>
    </ListFooterComponent>
  )
}

type CustomCellProps = {
  wrapperWidth: any
  item: any
  label: string
  customRender?: (item: any) => React.ReactNode
}

export default function List({
  title,
  isLoading,
  labels,
  fractions,
  hideColumn,
  itemsPerPage = 10,
  customStyles,
  customHeight,
  customCells,
  ...items
}: {
  title: string
  isLoading: boolean
  labels: Record<string, string>
  fractions?: Record<string, number> | null
  hideColumn?: Array<string>
  itemsPerPage?: number
  customStyles?: {
    width?: string
    boxShadow?: string
  }
  customHeight?: number
  customCells?: any
  items: any
}) {
  const queryItems = items?.items
  const [currentPage, setCurrentPage] = useState(1)
  const refWrapper = useRef<HTMLDivElement>(null)
  const [widthState, setWidthState] = useState(window.innerWidth)

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = currentPage * itemsPerPage
  const currentData = queryItems?.slice(startIndex, endIndex)

  const nextPage = () => {
    setCurrentPage(prevPage => prevPage + 1)
  }

  const prevPage = () => {
    setCurrentPage(prevPage => prevPage - 1)
  }

  const goToPage = (page: number) => {
    setCurrentPage(page)
  }

  useEffect(() => {
    const handleResize = () => {
      setWidthState(refWrapper.current?.clientWidth!)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [refWrapper])

  const CustomCell: React.FC<CustomCellProps> = ({ wrapperWidth, item, label, customRender }) => {
    if (customRender) {
      const renderedItems = customRender(item)
      return (
        <CustomCellStyle style={wrapperWidth}>
          {Array.isArray(renderedItems) ? (
            renderedItems.map((item, index) => <p key={index}>{item}</p>)
          ) : (
            <div>{renderedItems}</div>
          )}
        </CustomCellStyle>
      )
    }

    const values = item[label]

    if (Array.isArray(values)) {
      return (
        <>
          {values.map((value: string, index: number) => (
            <ListData key={index}>{value}</ListData>
          ))}
        </>
      )
    }

    return <ListData>{values}</ListData>
  }

  const renderList = (item: any, wrapperWidth: number) => {
    return Object.keys(labels).map(key => {
      if (customCells && customCells[key]) {
        return (
          <CustomCell
            key={key}
            wrapperWidth={
              fractions && fractions[key] ? { width: wrapperWidth * fractions[key] } : { flex: 1 }
            }
            item={item}
            label={key}
            customRender={customCells[key]}
          />
        )
      }

      return hideColumn && hideColumn.includes(key) ? null : (
        <ListData
          key={key}
          style={
            fractions && fractions[key] ? { width: wrapperWidth * fractions[key] } : { flex: 1 }
          }
        >
          {item[key]}
        </ListData>
      )
    })
  }

  return (
    <ListContainer style={customStyles ? customStyles : undefined} ref={refWrapper}>
      <ListTitle>
        <p>{title}</p>
      </ListTitle>
      {!isLoading && queryItems ? (
        <>
          <ListHeaderRow>
            {Object.keys(labels).map((item: string, index: number) => {
              return hideColumn && hideColumn.includes(item) ? null : (
                <ListHeaderColumn
                  key={index}
                  style={
                    fractions && fractions[item] && refWrapper
                      ? { width: widthState * fractions[item] }
                      : { flex: 1 }
                  }
                >
                  {labels[item]}
                </ListHeaderColumn>
              )
            })}
          </ListHeaderRow>

          {currentData.map((item: any, index: number) => (
            <ListRow key={index} customHeight={customHeight} customCell={!!customCells}>
              {hideColumn && hideColumn.includes(item) ? null : renderList(item, widthState)}
            </ListRow>
          ))}
        </>
      ) : (
        <ListRow>
          <ListData>
            <span>Carregando...</span>
          </ListData>
        </ListRow>
      )}

      {!isLoading && queryItems && queryItems.length === 0 ? (
        <ListNoDataContainer>
          <p>Sem dados até o momento</p>
        </ListNoDataContainer>
      ) : (
        <ListFooter
          currentPage={currentPage}
          totalPages={queryItems ? Math.ceil(queryItems.length / itemsPerPage) : 0}
          itemsPerPage={itemsPerPage}
          totalItems={queryItems ? queryItems.length : 0}
          onNextPage={nextPage}
          onPrevPage={prevPage}
          onGoToPage={goToPage}
        />
      )}
    </ListContainer>
  )
}
