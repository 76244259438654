import React, { useMemo } from 'react'
import styled from 'styled-components'
import Link from '~/prix/react/components/link'
import useAPI from '~/prix/react/hooks/api'
import LinkArrowIcon from '~/meta/linkArrow.svg'

const Items = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow : 0px 32px 30px rgba(20, 46, 82, 0.20);
  border-radius: 0 0 12px 12px;
`

const Item = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #E0E5EB;
    padding: 20px 0;
    width: 100%;
    flex: 1;

    svg {
      margin-right: 10px;
      min-width: 24px;
      min-height: 24px;
    }

    color: var(--institucional-cinza-sebrae-cinza-sebrae-45, #617385);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
    
    &:hover {
      color: #0F438A;
    }
  }
`

export default function DashboardGridItemShortcuts() {
  const { context } = useAPI()

  const agentPermission = useMemo(() => {
    return context.user?.roles.includes('agent')
  }, [context.user])

  return (
    <Items>
      <Item>
        <Link href='/app/disproportionate-attention'>
          <LinkArrowIcon />
          ATENDIMENTO DESPROPORCIONAL
        </Link>
      </Item>
      <Item>
        <Link href='/app/map/demarcation'>
          <LinkArrowIcon />
          MAPA DE DEMARCAÇÃO
        </Link>
      </Item>
      <Item>
        <Link href='/app/agent-report'>
          <LinkArrowIcon />
          RELATÓRIO DO AGENTE
        </Link>
      </Item>
      <Item>
        <Link href='/app/time-series/'>
          <LinkArrowIcon />
          SÉRIES TEMPORAIS
        </Link>
      </Item>
    </Items>
  )
}