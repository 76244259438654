import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import LegalEntityGeoprocessingMapDemarcationReport from './report/legalEntityGeoprocessingMapDemarcationReport.component'
import * as turf from '@turf/helpers'
import bbox from '@turf/bbox'
import type { GeoLevel } from '../map/legalEntityGeoprocessingMapLevels.data'
import useItems from '~/prix/react/hooks/items'
import anyLevelQuery from '../map/levels/anyLevel.query'

export type MultiRegionsGeometry =
  | turf.MultiPolygon
  | turf.Polygon
  | turf.MultiLineString
  | turf.Point
export type MultiRegionsFeatureCollection = turf.FeatureCollection<
  MultiRegionsGeometry,
  {
    level: GeoLevel
    id: string
    name: string | null
  }
> & {
  bbox: turf.BBox
}

export default function LegalEntityGeoprocessingMapDemarcationRegionScreen() {
  const params = useParams()

  const result = useItems(
    () => anyLevelQuery(params.geo as GeoLevel, params.ids!.split(',')) as any,
    [params.geo, params.ids],
    {
      cache: 60 * 60 * 24 * 7,
    },
  )

  const featureCollection = useMemo(() => {
    if (!result.items) {
      return null
    }
    const features = result.items.map(item => {
      const geometry =
        item.lowerQualityBoundary ??
        item.boundary ??
        item.lineStrings ??
        (item.center as turf.Polygon | turf.MultiLineString | turf.Point)
      return turf.feature(geometry, {
        level: params.geo as GeoLevel,
        id: item.id as string,
        name: item.name as string | null,
      })
    })
    const currentFeatureCollection = turf.featureCollection(features)
    const currentBbox = bbox(currentFeatureCollection)
    currentFeatureCollection.bbox = currentBbox
    return currentFeatureCollection as MultiRegionsFeatureCollection
  }, [result.items, params.geo])

  return (
    <LegalEntityGeoprocessingMapDemarcationReport
      feature={featureCollection}
      isLoading={result.isLoading}
    />
  )
}
