import React, { useMemo } from 'react'
import styled from 'styled-components'
import { QueryBase } from '~/prix/query'
import useItems from '~/prix/react/hooks/items'
import establishmentQuery from '../establishment/establishment.query'
import Handle from '~/prix/react/components/handle'
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { colors } from '~/design'
import { formatAsBrIntegerNumber } from '~/prix'

const Wrapper = styled.div<{ isLoading: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 18px 16px 0px 16px;
  cursor: ${({ isLoading }) => (isLoading ? 'wait' : 'default')};

  @media (max-width: 1024px) {
    padding: 54px 16px 0 16px;
  }
`

const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
  max-width: 450px;

  span {
    padding-bottom: 5px;
  }

  b {
    color: ${colors.sebraeBlue};
  }

  @media (max-width: 768px) {
    max-width: 300px;
  }
`

type PayloadProps = {
  payload: {
    name: string
    cnaeId: string
    value: number
  }
}

export default function TimeSeriesCnae({
  isEnabled = true,
  stateAbbreviation,
  stateName,
  startString,
  endString,
}: {
  isEnabled?: boolean
  stateAbbreviation?: string
  stateName?: string
  startString?: string | undefined
  endString?: string | undefined
}) {
  const result = useItems(
    () =>
      isEnabled
        ? (establishmentQuery({ stateAbbreviation, startString, endString }) as QueryBase)
        : (null as never),
    [isEnabled, stateAbbreviation, startString, endString],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: isEnabled,
    },
  )

  const cnaes = useMemo(() => {
    const items = result.items

    if (!items) return null

    const currentItems = items.map((item: any) => {
      return {
        name: item.description,
        titleName:
          item.description.length > 14
            ? item.description.substring(0, 14) + '...'
            : item.description,
        value: item.count,
        cnaeId: item.mainCnae,
      }
    })

    return currentItems
  }, [result])

  const CustomTooltip = (props: any) => {
    const { active, payload }: { active: boolean; payload: PayloadProps[] } = props
    if (active && payload && payload.length) {
      return (
        <TooltipWrapper>
          <span>
            <b>Descrição: </b>
            {payload[0].payload.name}
          </span>
          <span>
            <b>Cnae: </b>
            {payload[0].payload.cnaeId}
          </span>
          <span>
            <b>Total: </b>
            {formatAsBrIntegerNumber(Number(payload[0].payload.value))}
          </span>
        </TooltipWrapper>
      )
    }
    return null
  }

  return (
    <Wrapper isLoading={result.isLoading}>
      <Handle error={result.error} isLoading={result.isLoading}>
        <h3>
          Principais atividades econômicas {stateName && '-'} {stateName}
        </h3>
        {cnaes && cnaes.length ? (
          <ResponsiveContainer width='98%' height='85%'>
            <BarChart
              layout='vertical'
              data={cnaes}
              margin={{
                top: 0,
                right: 30,
                left: 45,
                bottom: 15,
              }}
              barSize={100}
            >
              <Tooltip content={<CustomTooltip />} />
              <XAxis type='number' stroke={colors.darkGray} />
              <YAxis
                dataKey='titleName'
                type='category'
                stroke={colors.darkGray}
                interval={0}
                tick={<CustomizedAxisTick />}
              />
              <Bar dataKey='value' fill={colors.sebraeBlue} />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          'Sem informações'
        )}
      </Handle>
    </Wrapper>
  )
}

function CustomizedAxisTick(props: any) {
  const { x, y, payload } = props

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={0} textAnchor='end' transform='rotate(-35)' fontSize='1rem'>
        {payload.value}
      </text>
    </g>
  )
}
