import type { InferNative, NativeBaseOptional, Type } from '../types/type'
import type { AbstractValueFunction } from './function'

export interface ConcatFunction<NativeGeneric extends NativeBaseOptional>
  extends AbstractValueFunction<NativeGeneric> {
  name: 'concat'
}

/**
 * Conversão de função CONCAT do postgres, concatena um array de proprieddes em uma string.
 * @remarks [Documentação PostgreSLQ](https://www.postgresql.org/docs/current/functions-string.html)
 * @param content Propriedades que serão concatenadas, em formato de array.
 * @returns Propriedade nativa compatível com a prixApi
 */
export default function concat<
  TypeGeneric extends Type,
  NativeGeneric extends NativeBaseOptional = InferNative<TypeGeneric>,
>(content: NativeGeneric[]): ConcatFunction<NativeGeneric> {
  return {
    kind: 'function',
    name: 'concat',
    content,
  }
}
