import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import AppLayout from '~/components/appLayout'
import ngeohash from 'ngeohash'
import { entity, equals, formatDistance, query, string, truthy } from '~/prix'
import { Button, ButtonLink, ButtonLinkAsSimpleText } from '~/prix/react/components/button'
import GoogleMaps from '~/prix/react/components/map/googleMaps'
import PolygonEditor, { usePolygonEditor } from '~/prix/react/components/map/polygonEditor'
import RadiusEditor, { useRadiusEditor } from '~/prix/react/components/map/radiusEditor'
import useGoogleMaps from '~/prix/react/hooks/googleMaps'
import { useNavigate } from 'react-router'
import SelectFromQuery from '~/prix/react/components/form/inputs/selectFromQuery'
import { SubtitleWrapper, TitleWrapper } from '../map/legalEntityGeoprocessingMap.screen'
import legalEntityGeoprocessingMapDemarcationSearchGeo from './search/legalEntityGeoprocessingMapDemarcationSearchGeo.query'
import { Level } from '../map/legalEntityGeoprocessingMapLevels.data'
import LegalEntityGeoprocessingMapDemarcationGeoItemComponent from './legalEntityGeoprocessingMapDemarcationGeoItem.component'
import AlertIcon from '~/components/icons/ui/16px_alert.svg'
import useAPI from '~/prix/react/hooks/api'
import useItems from '~/prix/react/hooks/items'
import asGeoJson from '~/prix/functions/asGeoJson'
import * as turf from '@turf/helpers'
import booleanWithin from '@turf/boolean-within'
import buffer from '@turf/buffer'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`
const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  gap: 5px;

  .search-wrapper {
    display: flex;
    flex: 1;
    max-width: 450px;

    .react-select__value-container {
      flex-wrap: nowrap !important;
      overflow-x: scroll;
      height: 40px;
      overflow: auto;
      overflow-y: hidden;

      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none;  

      ::-webkit-scrollbar{
        height: 4px;
        background: lightgray;
      }
    }

    .react-select__multi-value {
      min-width: 20% !important;

      @media (max-width: 500px) {
        min-width: 40% !important;
      }
    }
  }
`
const BottomBar = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  justify-content: space-between;
  align-items: center;

  .left {
    .alert-info {
      display: flex;  
      flex: 1;
      background-color: #f8fbff;
      border: 1px solid #e7f2ff;
      border-radius: 8px;
      padding: 5px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      span {
        font-size: 14.5px;
        padding-left: 5px;
      }
    }  
  }  
`

const ErrorOverlay = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  pointer-events: auto;
  padding: 50px;
`

const ErroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #FFF;
  box-shadow: 0px 32px 30px 0px rgba(20, 46, 82, 0.20);
  border-radius: 6px;
  text-align: center;
`

const BackButton = styled.button`
  background-color: #f1c40f;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  margin-top: 20px;
  background-color: ${({ theme }) => theme.colors.primary};
`

export type DemarcationType = 'region' | 'radius' | 'polygon'
export type DemarcationPoint = google.maps.LatLngLiteral & {
  id: string
}

const geohashPrecision = 8
const polygonDetailsRoute = '/app/map/demarcation/polygon/'
const radiusDetailsRoute = '/app/map/demarcation/radius/'
const regionDetailsRoute = '/app/map/demarcation/region/'

export default function LegalEntityGeoprocessingMapDemarcationScreen() {
  const navigate = useNavigate()
  const map = useRef<google.maps.Map | null>(null)
  const { google } = useGoogleMaps()

  const [center, setCenter] = useState<google.maps.LatLng | undefined>(undefined)
  const [zoom, setZoom] = useState<number>(4)
  const [regions, setRegions] = useState([])
  const [isRequestClearableGeometries, setIsRequestClearableGeometries] = useState<boolean>(false)
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false)
  const [demarcationType, setDemarcationType] = useState<DemarcationType>('polygon')
  const { context } = useAPI()
  const [isOverlayVisible, setIsOverlayVisible] = useState<boolean>(false)

  const hasUserStatePermission = context.user
    ? context.user.roles.some(
      item => item === 'agent' || item === 'stateManager'
    )
    : false

  const geoResult = hasUserStatePermission && context.user?.stateAbbreviation ? useItems(
    () =>
      query('state')
        .select({
          id: entity('state').property('id'),
          name: entity('state').property('name'),
          abbreviation: entity('state').property('abbreviation'),
          boundary: asGeoJson(entity('state').property('lowerQualityBoundary')),
          center: asGeoJson(entity('state').property('center')),
        })
        .where(
          ...[
            equals(entity('state').property('abbreviation'),
              string().value(context.user?.stateAbbreviation!))
          ].filter(truthy),
        )
        .limit(30),
    [context.user?.stateAbbreviation, hasUserStatePermission],
    {
      cache: 8 * 60 * 60 * 1000,
    },
  ) : null

  const handleClearGeometries = useCallback((value: boolean) => {
    setIsRequestClearableGeometries(value)
  }, [])

  const onSearchInputChange = useCallback((item) => {
    const getLastRegion = item.slice(-1)
    const lastRegion = getLastRegion[0]

    if (item && regions.length > 0 && item[0]?.geo !== lastRegion?.geo) {
      setRegions([])
    }

    if (lastRegion && !regions.includes(lastRegion)) {
      setRegions(current => [...current, lastRegion])
    }

    if (lastRegion && lastRegion.center.coordinates) {
      setCenter(google ? new google.maps.LatLng(lastRegion.center.coordinates[1], lastRegion.center.coordinates[0]) : undefined)
    }

    if (item.length < regions.length) {
      setRegions(item)
    }

    const zoomDefinitions = {
      'macroRegion': 6,
      'state': 7,
      'mesoRegion': 9,
      'microRegion': 10,
      'city': 11,
      'neighborhood': 15,
    }

    if (lastRegion && lastRegion.geo) {
      setZoom(zoomDefinitions[lastRegion?.geo as unknown as keyof typeof zoomDefinitions])
    }

  }, [google, regions])

  useEffect(() => {
    if (isRequestClearableGeometries === true) {
      setRegions([])
      setIsRequestClearableGeometries(false)
    }
  }, [isRequestClearableGeometries])

  useEffect(() => {
    if (isInputFocused === true) {
      setDemarcationType('region')
      setIsInputFocused(false)
    }
  }, [isInputFocused])

  useEffect(() => {
    if (demarcationType === 'polygon' || demarcationType === 'radius') {
      setRegions([])
    }

  }, [demarcationType])

  useMemo(() => {
    if (!google || !geoResult || !geoResult.items) return null

    setZoom(6)
    setCenter(new google.maps.LatLng(geoResult?.items[0]?.center.coordinates[1], geoResult?.items[0]?.center.coordinates[0]))
  }, [geoResult?.items])


  const brazilCenter = useMemo(
    () => (google ? new google.maps.LatLng(-10.78085882890515, -53.092482362387045) : undefined),
    [google],
  )

  const [overItem, setOverItem] = useState(null)

  const handleMouseOver = useCallback(
    (item: any) => {
      setOverItem(item)
    },
    [],
  )

  const handleMouseOut = useCallback(() => {
    setOverItem(null)
  }, [])

  const mapOptions = useMemo(() => {
    if (!google) {
      return undefined
    }

    const currentMapOptions: google.maps.MapOptions = {
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.DEFAULT,
        position: google.maps.ControlPosition.LEFT_BOTTOM,
      },
      fullscreenControl: false,
      streetViewControl: false,
    }

    return currentMapOptions
  }, [google])

  const radius = useRadiusEditor(null)
  const polygon = usePolygonEditor(null)

  const maxPolygonPoints = useMemo(() => {
    const partialURL = window.location.origin + polygonDetailsRoute
    const availableURLLength = 2048 - partialURL.length
    const maxPoints = Math.floor((availableURLLength + 1) / (geohashPrecision + 1))
    return maxPoints
  }, [geohashPrecision])

  const handleGetDetails = useCallback(() => {
    if (hasUserStatePermission) {
      if (!isWithinState()) {
        setIsOverlayVisible(true)
        return
      }
    }

    if (demarcationType === 'polygon') {
      if (polygon.points.length < 3) {
        return
      }
      const geohashes = polygon.points.map(point => ngeohash.encode(point.lat, point.lng, geohashPrecision))
      const geohashesString = geohashes.join(',')
      const fullPath = polygonDetailsRoute + geohashesString
      navigate(fullPath)
    }

    if (demarcationType === 'radius') {
      if (!radius.pointWithRadius) {
        return
      }
      const geohash = ngeohash.encode(radius.pointWithRadius.geometry.coordinates[1], radius.pointWithRadius.geometry.coordinates[0], geohashPrecision)
      const fullPath = radiusDetailsRoute + geohash + '/' + radius.pointWithRadius.properties.radius
      navigate(fullPath)
    }

    if (demarcationType === 'region') {
      const path = regions.map((region: { geo: string, id: string }) => ({
        geo: region.geo,
        ids: [region.id]
      }))

      const arrayHashmap = path.reduce((acc: { [key: string]: { geo: string, ids: string[] } }, item) => {
        acc[item.geo] ? acc[item.geo].ids.push(...item.ids) : (acc[item.geo] = { ...item })
        return acc
      }, {})

      const mergedArray = Object.values(arrayHashmap as Array<{ geo: string, ids: string }>)
      const hashParams = `${mergedArray[0].geo}/${mergedArray[0].ids}`

      const fullPath = regionDetailsRoute + hashParams
      navigate(fullPath)
    }
  }, [demarcationType, radius.pointWithRadius, polygon.points, regions])


  const mapDemarcationSearchGeoQueryFactory = useCallback(
    (input: string) =>
      legalEntityGeoprocessingMapDemarcationSearchGeo(input, undefined),
    [],
  )

  const onMapClick = {
    polygon: polygon.onMapClick,
    radius: radius.onMapClick,
    region: undefined,
  }[demarcationType]

  const onMapMouseMove = {
    polygon: undefined,
    radius: radius.onMapMouseMove,
    region: undefined,
  }[demarcationType]

  const onReset = {
    polygon: polygon.points.length ? polygon.onReset : undefined,
    radius: radius.pointWithRadius ? radius.onReset : undefined,
    region: regions.length ? () => setRegions([]) : undefined,
  }[demarcationType]

  useEffect(() => {
    if (polygon.points.length > 221 && onReset) {
      onReset()
    }
  }, [polygon.points])

  const polygonMaxPointsWarningMessage = useMemo(() => {
    const lastValue = maxPolygonPoints - 1
    const remainingValues = [
      {
        value: '5',
        condition: polygon.points.length === maxPolygonPoints - 5,
      },
      {
        value: '4',
        condition: polygon.points.length === maxPolygonPoints - 4,
      },
      {
        value: '3',
        condition: polygon.points.length === maxPolygonPoints - 3,
      },
      {
        value: '2',
        condition: polygon.points.length === maxPolygonPoints - 2,
      },
      {
        value: '1',
        condition: polygon.points.length === maxPolygonPoints - 1,
      },
    ]

    const remainingValue = remainingValues.filter(item => item.condition === true)

    const halfMessage = 'Utilizados metade dos pontos disponíveis para composição do polígono.'
    const remainingMessage = `${polygon.points.length < lastValue ? 'Restam' : 'Resta'} ${remainingValue[0]?.value} ${polygon.points.length < lastValue ? 'pontos disponíveis' : 'ponto disponível'}, caso esse limite seja ultrapassado o polígono será removido.`

    if (polygon.points?.length === (maxPolygonPoints / 2)) {
      return {
        type: 'half',
        message: halfMessage,
      }
    }

    if (polygon.points?.length > (maxPolygonPoints - 5)) {
      return {
        type: 'remaining',
        message: remainingMessage,
      }
    }
  }, [polygon.points, maxPolygonPoints])

  const isValid = {
    polygon: polygon.points.length > 2,
    radius: radius.pointWithRadius !== null,
    region: regions.length > 0,
  }[demarcationType]

  const isWithinState = () => {
    const geoItem = geoResult?.items || null

    if (!geoItem) {
      return
    }

    const boundary = geoItem[0].boundary
    const boundaryGeoJSON = turf.multiPolygon(boundary.coordinates)

    const polygonDemarcation = () => {
      const userPolygon = polygon.polygon?.geometry || null
      const userPolygonGeoJSON = userPolygon ? turf.polygon(userPolygon.coordinates) : null
      const isInside = userPolygonGeoJSON ? booleanWithin(userPolygonGeoJSON, boundaryGeoJSON) : false
      return isInside
    }

    const radiusDemarcation = () => {
      const center = radius.point && turf.point([radius.point?.lng, radius.point?.lat])

      if (center && radius) {
        const buffered = buffer(center, Number(radius.radius), { units: 'meters' })
        const isInside = booleanWithin(buffered, boundaryGeoJSON)
        return isInside
      }

      return null
    }

    const regionDemarcation = () => {
      if (regions.length === 0) {
        return null
      }

      const subtitles = regions.map((region: any) => region.subtitle.split('-'))
      const isInside = subtitles.every((subtitleArray: any, index: number) =>
        subtitleArray.some((subtitle: any) => {
          if (subtitle.trim() === 'Estado') {
            return geoItem[0].name === regions[index].geoName
          }
          return subtitle.trim() === geoItem[0].abbreviation
        })
      )

      return isInside
    }

    if (demarcationType === 'polygon') return polygonDemarcation()

    if (demarcationType === 'radius') return radiusDemarcation()

    if (demarcationType === 'region') return regionDemarcation()
  }

  if (!mapOptions) {
    return null
  }

  return (
    <AppLayout
      title='Mapa de demarcação'
      dockActive='map'
    >
      <Wrapper>
        <TopBar>
          <div className='search-wrapper'>
            <SelectFromQuery
              idProperty='id'
              labelProperty='geoName'
              placeholder='Busque por endereços geográficos...'
              isSearchInput={true}
              isMultiple
              isDemarcation
              isClearInput={regions.length === 0 ? true : false}
              getLabel={item => (
                <div>
                  <TitleWrapper>
                    <span>
                      {item.geoName}
                    </span>
                  </TitleWrapper>

                  <SubtitleWrapper>
                    <span>{item.subtitle}</span>
                  </SubtitleWrapper>
                </div>
              )}
              preLoad={false}
              queryFactory={mapDemarcationSearchGeoQueryFactory}
              onClearGeometriesRequest={value => handleClearGeometries(value)}
              onFocused={value => setIsInputFocused(value)}
              onQueryComplete={queryResult =>
                queryResult
                  .sort((a, b) => (a.searchRank as number) - (b.searchRank as number))
                  .reverse()
                  .slice(0, 25)
                  .map(item => {
                    return item
                  })
              }
              onDirectChange={item => {
                if (!item) {
                  return
                }

                onSearchInputChange(item as unknown as Level)

              }}
            />
          </div>
          <Button
            styleType={
              demarcationType === 'polygon' ? 'primary' : 'lightGrey'
            }
            onClick={() => setDemarcationType('polygon')}
            withoutMargin
          >Polígono</Button>
          <Button
            styleType={
              demarcationType === 'radius' ? 'primary' : 'lightGrey'
            }
            onClick={() => setDemarcationType('radius')}
            withoutMargin
          >Raio</Button>
        </TopBar>

        {isOverlayVisible &&
          <ErrorOverlay>
            <ErroWrapper>
              <AlertIcon fill={'#f1c40f'} width={24} height={24} />
              <h3>Você não possui permissão para requisitar dados de fora do estado de origem.</h3>
              <BackButton onClick={() => {
                onReset && onReset()
                setIsOverlayVisible(false)
              }}>Voltar</BackButton>
            </ErroWrapper>
          </ErrorOverlay>
        }

        <GoogleMaps
          ref={map}
          center={center ?? brazilCenter}
          zoom={zoom}
          onClick={onMapClick}
          onMouseMove={onMapMouseMove}
          options={mapOptions}
        >
          {regions.length > 0 ?
            <LegalEntityGeoprocessingMapDemarcationGeoItemComponent
              items={regions}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              overItem={overItem}
            />
            : null}
          {demarcationType === 'polygon' ? (
            <PolygonEditor
              {...polygon}
            />
          ) : null}
          {demarcationType === 'radius' ? (
            <RadiusEditor
              {...radius}
            />
          ) : null}
        </GoogleMaps>
        <BottomBar>
          <div className='left'>
            {demarcationType === 'radius' ? <output>
              Raio: {formatDistance(radius.radius ?? 0)}
            </output> : null}
            {polygonMaxPointsWarningMessage !== undefined ?
              <div className='alert-info'>
                <AlertIcon width={15} height={15} fill={polygonMaxPointsWarningMessage.type === 'half' ? '#f1c40f' : 'red'} />
                <span>{polygonMaxPointsWarningMessage.message}</span>
              </div>
              : null}
          </div>
          <div className='right'>
            <ButtonLinkAsSimpleText onGo={onReset} isDisabled={!onReset} withoutMargin>Limpar</ButtonLinkAsSimpleText>
            <ButtonLink withoutMargin isDisabled={!isValid} onGo={handleGetDetails}>Detalhar</ButtonLink>
          </div>
        </BottomBar>
      </Wrapper>
    </AppLayout>
  )
}