import { useState, useEffect } from 'react'

export type SetAsyncState<GenericValue> = (
  newState: GenericValue | ((oldValues: GenericValue) => GenericValue),
) => Promise<unknown>

export default function useAsyncState<GenericValue>(initialValue: GenericValue) {
  const [state, setState] = useState(initialValue)
  const [started, setStarted] = useState(false)
  const [finishPromise, setFinishPromise] = useState<null | ((state: GenericValue) => void)>(null)

  useEffect(() => {
    const fn = finishPromise
    if (started && fn) {
      setFinishPromise(null)
      fn(state)
    }
    if (!started) {
      setStarted(true)
    }
  }, [state, finishPromise])

  const setStateWithPromise = (
    newState: GenericValue | ((oldValues: GenericValue) => GenericValue),
  ) => {
    const promise = new Promise(resolve => {
      setFinishPromise(() => resolve)
    })
    setState(newState)
    return promise
  }
  return [state, setStateWithPromise] as [
    GenericValue,
    (newState: GenericValue | ((oldValues: GenericValue) => GenericValue)) => Promise<unknown>,
  ]
}
