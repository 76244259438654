import { defineEntity, entity, foreign, object, string } from '~/prix'

const cnaeSubclass = defineEntity({
  key: 'cnaeSubclass',
  title: 'Subclasse de cnae',
  sources: ['olap', 'oltp'],
  schema: object({
    id: string().label('ID'),
    ibgeCode: string().label('Código IBGE'),
    description: string().label('Descrição'),
    activities: string().label('Atividades'),
    observations: string().label('Observações'),
    cnaeClassId: foreign(entity('cnaeClass').property('id')),
  }),
})

export default cnaeSubclass
