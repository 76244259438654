import { Find, Optionals } from './utils/typing'
import { ObjectType, ShapeBase } from './types/object'
import { Defaults } from './index'
import { SourceKey } from './sources/source'
import { InferNative, NativeBaseOptional } from './types/type'
import { Property } from './property'

export interface Entity<
  Key extends string = string,
  SchemaShape extends ShapeBase = ShapeBase,
  SourceKeys extends SourceKey[] = SourceKey[],
> {
  kind: 'entity'
  key: Key
  title: string
  alternativeTitles?: string[]
  sources: SourceKeys
  entitySchema?: string
  schema: ObjectType<SchemaShape>
  identifier: string
}

export default function defineEntity<
  Key extends string = string,
  SchemaShape extends ShapeBase = ShapeBase,
  SourceKeys extends SourceKey[] = SourceKey[],
>({
  identifier = 'id',
  ...newEntity
}: Optionals<Entity<Key, SchemaShape, SourceKeys>, 'identifier' | 'kind'>): Entity<
  Key,
  SchemaShape,
  SourceKeys
> {
  return { kind: 'entity', identifier, ...newEntity }
}

export function filterEntitiesBySource(
  entities: Array<Entity<string, ShapeBase>>,
  source: SourceKey,
): Array<Entity<string, ShapeBase>> {
  return entities.filter(current => current.sources.includes(source))
}

export type Entities = Defaults extends { entities: Array<Entity<string, any>> }
  ? Defaults['entities']
  : Array<Entity<string, any>>

export type EntityKey = Defaults extends { entityKey: string } ? Defaults['entityKey'] : string

export type FindEntity<CurrentEntityKey extends EntityKey> = Find<
  Entities,
  {
    key: CurrentEntityKey
  }
>

export interface EntityReference<Key extends string, Alias extends string, CurrentEntity> {
  key: Key
  alias: Alias
  entitySchema?: string
  property: <
    Name extends Extract<keyof InferNative<FindEntity<Key>['schema']>, string>,
    NativeGeneric extends NativeBaseOptional = NativeBaseOptional, // InferNative<FindEntity<Key>['schema']>[Name],
  >(
    name: Name,
  ) => Property<Key, Name, Alias, NativeGeneric>
}

export type EntitySchema = string
interface EntityOptions<EntitySchema extends string, Alias extends string> {
  alias?: Alias
  schema?: EntitySchema
}

/**
 * Método para definição da entidade.
 * @param key Identificação da tabela, ou o nome da tabela em camelCase.
 * @param options Opções adicionais do método, podendo dar um alias customizado a entidade, ou informar seu schema.
 * @returns Objeto de entidade compatível com a PrixApi, para realizar queries diretas em banco.
 */
export function entity<
  Key extends string,
  Alias extends string = Key,
  CurrentEntity = FindEntity<Key>,
>(
  key: Key,
  options?: EntityOptions<EntitySchema, Alias>,
): EntityReference<Key, Alias, CurrentEntity> {
  let alias = key as unknown as Alias
  if (options && options.alias) {
    alias = alias
  }
  let entitySchema: string | undefined = undefined
  if (options && options.schema) {
    entitySchema = options.schema
  }
  return {
    key,
    alias,
    entitySchema,
    property: name => ({
      entityAlias: alias,
      entityKey: key,
      entitySchema,
      kind: 'property',
      name,
    }),
  }
}
