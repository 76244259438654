import { defineEntity, object, number, foreign, entity, enumerated, dateTime } from '~/prix'

const legalEntityIndividualDataSourceEntity = defineEntity({
  key: 'legalEntityIndividualDataSource',
  title: 'Pessoa física fonte de dados em pessoa jurídica',
  sources: ['olap', 'oltp'],
  schema: object({
    id: number().label('ID', 'Identificador', 'Código'),
    legalEntityIndividualId: foreign(entity('legalEntity').property('id')).label(
      'Identificador pessoa física em pessoa jurída',
    ),
    dataSourceId: foreign(entity('legalEntity').property('id')).label(
      'Identificador fonte de dados',
    ),
    dataSourceOriginId: number().label('Identificador origem fonte de dados'),
    createdAt: dateTime().label('Data de inclusão'),
  }),
})

export default legalEntityIndividualDataSourceEntity
