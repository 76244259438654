import format from 'date-fns/format'
import parse from 'date-fns/parse'
import { baseDate } from './dateTime'
import createType, { AbstractType } from './type'

export interface YearType<NativeGeneric extends string | null = string>
  extends AbstractType<NativeGeneric> {
  readonly type: 'year'
  readonly currentValue?: NativeGeneric
  isRequired(): YearType<Exclude<NativeGeneric, null>>
  isOptional(): YearType<NativeGeneric | null>
}

export default function year(): YearType<string> {
  return {
    ...createType<YearType<string>>(),
    type: 'year',
  }
}

export function parseYear(date: string): Date {
  return parse(date, 'yyyy', baseDate)
}

export function stringifyYear(jsDateTime: Date): string {
  return format(jsDateTime, 'yyyy')
}

export const formatYear = stringifyYear
