import { entity, equals, query, string } from '~/prix'
import asGeoJson from '~/prix/functions/asGeoJson'

export default function legalEntityQuery(by: 'legalEntityId', id: string) {
  switch (by) {
    case 'legalEntityId':
      return query('legalEntity')
        .select({
          tradeName: entity('legalEntity').property('tradeName'),
          corporateName: entity('legalEntity').property('corporateName'),
          cnpj: entity('legalEntity').property('cnpj'),
          publicPlace: entity('legalEntity').property('publicPlace'),
          number: entity('legalEntity').property('number'),
          complement: entity('legalEntity').property('complement'),
          neighborhood: entity('legalEntity').property('neighborhood'),
          city: entity('legalEntity').property('city'),
          state: entity('legalEntity').property('state'),
          postalCode: entity('legalEntity').property('postalCode'),
          pointOnStreet: asGeoJson(entity('legalEntityGeoprocessing').property('pointOnStreet')),
          pointOnStreetDirectionAngle: entity('legalEntityGeoprocessing').property(
            'pointOnStreetDirectionAngle',
          ),
          stateId: entity('state').property('id'),
        })
        .join({
          current: entity('legalEntity').property('id'),
          target: entity('legalEntityGeoprocessing').property('legalEntityId'),
        })
        .join({
          current: entity('legalEntityGeoprocessing').property('stateId'),
          target: entity('state').property('id'),
        })
        .where(equals(entity('legalEntity').property('id'), string().value(id)))
        .limit(10000)
    default:
      throw new Error(`Unknown by: ${by} on legalEntity query`)
  }
}
