export function searchQueryToRegularExpression(input: string | null): RegExp {
  if (!input) {
    return /.*/
  }

  return new RegExp(
    `^${input
      .split(' ')
      .map(word => (word ? `(?=.*${String(word).replace(/[^0-9a-zA-Zà-úÀ-Ú]/g, '')})` : ''))
      .join('')}.*$`,
    'i',
  )
}
