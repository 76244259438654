import React, { useEffect, useMemo, useState } from 'react'
import AppLayout from '~/components/appLayout'
import useMedia from 'react-use/lib/useMedia'
import useAPI from '~/prix/react/hooks/api'
import DashboardComponent from './dashboard.component'
import DashboardSources from './dashboardSources.component'
import latestComparisonWeeksQuery from './queries/latestComparisonWeeks.query'
import useItems from '~/prix/react/hooks/items'
import { isNullOrUndefined } from '~/prix/utils/empty'
import eachSourceAttendanceDatesQuery from '~/packages/legalEntityAttendance/attendanceDates/eachSourceAttendanceDates.query'

const dataSourceOptions = [
  'Geral',
  'Portfólio Ead', // Utilizando o id da RFB para o portfólio
  '',
  'Radar ALI',
  'Brasil Mais',
  'EaD',
  'SEBRAE Na Sua Empresa (App)',
]

export default function DashboardScreen() {
  const isDesktop = useMedia('(min-width: 769px)')
  const { context } = useAPI()
  const [dataSource, setDataSource] = useState<string>('Geral')
  const [menuVisibility, setMenuVisibility] = useState(false)
  const toggleMenuVisibility = () => setMenuVisibility(!menuVisibility)
  const dataSourceIndex = dataSourceOptions.indexOf(dataSource)

  const [isUserLoading, setIsUserLoading] = useState(true)

  useEffect(() => {
    setIsUserLoading(context.user === null)
  }, [context.user])

  const hasUserRoles = context.user
    ? !context.user?.roles.some(
        item => item === 'systemAdministrator' || item === 'administrator' || item === 'manager',
      )
    : false

  const userGeoStateAbbreviation = useMemo(() => {
    const userAbbreviation =
      hasUserRoles && context.user ? context.user.stateAbbreviation : undefined
    return userAbbreviation
  }, [hasUserRoles])

  const eachSourceAttendanceDates = useItems(
    () => eachSourceAttendanceDatesQuery(),
    [isUserLoading],
    {
      cache: 60 * 60 * 8,
      autoLoad: isUserLoading === false,
    },
  )

  const lastDates = useMemo(() => {
    if (eachSourceAttendanceDates.items) {
      const lastAttendanceDateEaD = eachSourceAttendanceDates.items.find(
        item => item.dataSourceId === 5,
      )
      const lastAttendanceDateRadarALI = eachSourceAttendanceDates.items.find(
        item => item.dataSourceId === 3,
      )
      const lastAttendanceDateAppSebrae = eachSourceAttendanceDates.items.find(
        item => item.dataSourceId === 6,
      )

      // 1 = portfolio
      return {
        1: lastAttendanceDateEaD?.maxAttendanceDate,
        3: lastAttendanceDateRadarALI?.maxAttendanceDate,
        5: lastAttendanceDateEaD?.maxAttendanceDate,
        6: lastAttendanceDateAppSebrae?.maxAttendanceDate,
      }
    }
  }, [eachSourceAttendanceDates.isLoading])

  const fetchDataLastWeek = (sourceId: number, startDate: string | null, isRegister: boolean) => {
    return useItems(
      () =>
        latestComparisonWeeksQuery({
          stateAbbreviation: userGeoStateAbbreviation || undefined,
          dataSourceId: sourceId,
          isPortfolioEad: isRegister ? true : false,
          lastRegisterDate: startDate ?? null,
        }).latestWeek,
      [lastDates === undefined, startDate],
      {
        cache: 60 * 60 * 24,
        autoLoad: startDate !== null,
      },
    )
  }
  const fetchDataSecondLastWeek = (
    sourceId: number,
    startDate: string | null,
    isRegister: boolean,
  ) => {
    return useItems(
      () =>
        latestComparisonWeeksQuery({
          stateAbbreviation: userGeoStateAbbreviation || undefined,
          dataSourceId: sourceId,
          isPortfolioEad: isRegister === true ? true : false,
          lastRegisterDate: startDate ?? null,
        }).secondLastWeek,
      [lastDates === undefined, startDate],
      {
        cache: 60 * 60 * 24,
        autoLoad: startDate !== null,
      },
    )
  }

  //#region Captando dados da ultima e penultima semana
  const lastWeekPortfolio = fetchDataLastWeek(
    1,
    lastDates ? String(Object.values(lastDates)[0]) : null,
    true,
  )
  const lastWeekRadarALI = fetchDataLastWeek(
    3,
    lastDates ? String(Object.values(lastDates)[1]) : null,
    false,
  )
  const lastWeekEaD = fetchDataLastWeek(
    5,
    lastDates ? String(Object.values(lastDates)[2]) : null,
    false,
  )
  const lastWeekAppSebrae = fetchDataLastWeek(
    6,
    lastDates ? String(Object.values(lastDates)[3]) : null,
    false,
  )

  const secondLastWeekPortfolio = fetchDataSecondLastWeek(
    1,
    lastDates ? String(Object.values(lastDates)[0]) : null,
    true,
  )
  const secondLastWeekRadarALI = fetchDataSecondLastWeek(
    3,
    lastDates ? String(Object.values(lastDates)[1]) : null,
    false,
  )
  const secondLastWeekEaD = fetchDataSecondLastWeek(
    5,
    lastDates ? String(Object.values(lastDates)[2]) : null,
    false,
  )
  const secondLastWeekAppSebrae = fetchDataSecondLastWeek(
    6,
    lastDates ? String(Object.values(lastDates)[3]) : null,
    false,
  )
  //#endregion Captando dados da ultima e penultima semana

  const isLoadingLastTwoWeeks = useMemo(() => {
    if (
      lastDates === undefined ||
      secondLastWeekPortfolio?.isLoading !== false ||
      secondLastWeekRadarALI?.isLoading !== false ||
      secondLastWeekEaD?.isLoading !== false ||
      secondLastWeekAppSebrae?.isLoading !== false ||
      lastWeekPortfolio?.isLoading !== false ||
      lastWeekRadarALI?.isLoading !== false ||
      lastWeekEaD?.isLoading !== false ||
      lastWeekAppSebrae?.isLoading !== false ||
      isUserLoading !== false
    ) {
      return true
    } else if (
      secondLastWeekPortfolio?.isLoading === false &&
      secondLastWeekRadarALI?.isLoading === false &&
      secondLastWeekEaD?.isLoading === false &&
      secondLastWeekAppSebrae?.isLoading === false &&
      lastWeekPortfolio?.isLoading === false &&
      lastWeekRadarALI?.isLoading === false &&
      lastWeekEaD?.isLoading === false &&
      lastWeekAppSebrae?.isLoading === false &&
      isUserLoading === false &&
      lastDates !== undefined
    ) {
      return false
    }
  }, [
    secondLastWeekPortfolio?.isLoading,
    secondLastWeekRadarALI?.isLoading,
    secondLastWeekEaD?.isLoading,
    secondLastWeekAppSebrae?.isLoading,
    lastWeekPortfolio?.isLoading,
    lastWeekRadarALI?.isLoading,
    lastWeekEaD?.isLoading,
    lastWeekAppSebrae?.isLoading,
    isUserLoading,
    lastDates,
  ])

  const sumItems = (items: any[] | undefined) =>
    items?.reduce(
      (accumulator, currentValue) => (accumulator || 0) + (currentValue.total || 0),
      0,
    ) || 0

  const calculateIndicator = (latestWeekSum: number, secondLastWeekSum: number) => {
    const difference =
      !isNullOrUndefined(latestWeekSum) && !isNullOrUndefined(secondLastWeekSum)
        ? latestWeekSum - secondLastWeekSum
        : null

    const condition = difference ? difference >= 0 : undefined

    const percentage = difference && latestWeekSum ? (difference / secondLastWeekSum) * 100 : null

    const finalPercentage = percentage && isFinite(percentage) ? percentage : 100

    return { latestWeekSum, secondLastWeekSum, percentage: finalPercentage, condition }
  }

  const tabIndicators = useMemo(() => {
    const latestTwoWeeksSum = {
      portfolio: {
        lastWeek: sumItems(lastWeekPortfolio?.items),
        secondLastWeek: sumItems(secondLastWeekPortfolio?.items),
        indicator: function () {
          return calculateIndicator(this.lastWeek, this.secondLastWeek)
        },
      },
      radarAli: {
        lastWeek: sumItems(lastWeekRadarALI?.items),
        secondLastWeek: sumItems(secondLastWeekRadarALI?.items),
        indicator: function () {
          return calculateIndicator(this.lastWeek, this.secondLastWeek)
        },
      },
      ead: {
        lastWeek: sumItems(lastWeekEaD?.items),
        secondLastWeek: sumItems(secondLastWeekEaD?.items),
        indicator: function () {
          return calculateIndicator(this.lastWeek, this.secondLastWeek)
        },
      },
      appSebrae: {
        lastWeek: sumItems(lastWeekAppSebrae?.items),
        secondLastWeek: sumItems(secondLastWeekAppSebrae?.items),
        indicator: function () {
          return calculateIndicator(this.lastWeek, this.secondLastWeek)
        },
      },
    }
    const data = {
      portfolio: latestTwoWeeksSum['portfolio'].indicator(),
      radarAli: latestTwoWeeksSum['radarAli'].indicator(),
      ead: latestTwoWeeksSum['ead'].indicator(),
      appSebrae: latestTwoWeeksSum['appSebrae'].indicator(),
    }

    return data
  }, [isLoadingLastTwoWeeks, isUserLoading])

  return (
    <AppLayout
      title={isDesktop ? 'Início' : `Início - ${dataSource}`}
      dockActive='start'
      isDashboard={true}
      initialMenuVisibility={menuVisibility}
      isLoadingIndicatorsData={isLoadingLastTwoWeeks}
      tabIndicators={tabIndicators}
      menu={
        !isDesktop
          ? () => (
              <DashboardSources
                dataSource={dataSource}
                setDataSource={setDataSource}
                title={context.user?.name ? `Olá ${context.user?.name}!` : ''}
                toggleMenuVisibility={toggleMenuVisibility}
                tabIndicators={tabIndicators}
              />
            )
          : null
      }
      card={
        isDesktop
          ? {
              title: context.user?.name ? `Olá ${context.user?.name}!` : '',
              dataSource: dataSource,
              setDataSource: setDataSource,
              customStyles: {
                content: {
                  height: '100px',
                },
              },
            }
          : undefined
      }
    >
      <DashboardComponent
        dataSourceId={dataSourceIndex}
        isLoadingIndicatorsData={isLoadingLastTwoWeeks !== false ? true : false}
        tabIndicators={tabIndicators}
        lastDates={lastDates}
      />
    </AppLayout>
  )
}
