import React from 'react'
import Helmet from 'react-helmet'

export function SeoPage({ siteName, url, description, keywords, title, type, image = '' }: any) {
  return (
    <Helmet>
      <title>{title}</title>
      <link rel='canonical' href={url} />
      <meta name='title' content={title} />
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
      <meta name='image' content={image} />
      <meta itemProp='name' content={title} />
      <meta itemProp='description' content={description} />
      <meta itemProp='image' content={image} />
      <meta name='og:title' content={title} />
      <meta name='og:description' content={description} /> image ?{' '}
      <meta name='og:image' content={image} /> : null,
      <meta name='og:url' content={url} />
      <meta name='og:site_name' content={siteName} />
      <meta name='og:locale' content='pt_BR' />
      <meta name='og:type' content={type} />
      <link href='https://www.google-analytics.com' rel='preconnect' crossOrigin />
      <script src='https://www.google.com/recaptcha/api.js' async defer />
      <script async src='https://www.googletagmanager.com/gtag/js?id=UA-96032259-2' />
      <script
        dangerouslySetInnerHTML={` window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'UA-96032259-2');`}
      />
    </Helmet>
  )
}

// export function SeoContent({
//   url,
//   title,
//   author,
//   image,
//   keywords,
//   siteName,
//   description,
//   section,
//   type,
//   publishedTime,
//   modifiedTime,
// }: any) {
//   return (
//     <Helmet>
//       <link rel='canonical' href={url} />
//       <meta name='title' property='title' content={title} />
//       <meta name='author' property='author' content={author} />
//       <meta name='description' property='description' content={description} />
//       <meta name='keywords' property='keywords' content={keywords} />
//       <meta name='image' property='image' content={image} />
//       <meta itemProp='name' content={title} />
//       <meta itemProp='description' content={description} />
//       <meta itemProp='image' content={image} />
//       <meta name='twitter:card' content='summary' />
//       <meta name='twitter:title' content={title} />
//       <meta name='twitter:description' content={description} />
//       {/* <meta name='twitter:site' content={twitterConfig.account} />
//       <meta name='twitter:creator' content={twitterConfig.account} /> */}
//       <meta name='twitter:image:src' content={image} />
//       <meta property='og:title' content={title} />
//       <meta property='og:description' content={description} />
//       <meta property='og:image' content={image} />
//       <meta property='og:url' content={url} />
//       <meta property='og:site_name' content={siteName} />
//       <meta property='og:locale' content='pt_BR' />
//       <meta property='og:updated_time' content={publishedTime} />
//       {/* <meta property='fb:app_id' content={facebookConfig.appId} />
//       <meta property='fb:admins' content={facebookConfig.adminsIds} /> */}
//       <meta property='og:type' content={type} />
//       {/* <meta property='article:publisher' content={facebookConfig.publisher} />
//       <meta property='article:author' content={facebookConfig.author} /> */}
//       <meta property='article:section' content={section} />
//       <meta property='article:tag' content={keywords} />
//       <meta property='article:published_time' content={publishedTime} />
//       <meta property='article:modified_time' content={modifiedTime} />
//       <link href='https://www.google-analytics.com' rel='preconnect' crossOrigin />
//       <script src='https://www.google.com/recaptcha/api.js' async defer />
//       <async src='https://www.googletagmanager.com/gtag/js?id=UA-96032259-2' />
//       <script
//         dangerouslySetInnerHTML={` window.dataLayer = window.dataLayer || [];
//       function gtag(){dataLayer.push(arguments);}
//       gtag('js', new Date());
//       gtag('config', 'UA-96032259-2');`}
//       />
//     </Helmet>
//   )
// }
