import createType, { AbstractType } from './type'

export interface ShapeBase {
  [key: string]: AbstractType<any>
}

export interface ObjectType<NativeShapeGeneric extends ShapeBase | null = ShapeBase>
  extends AbstractType<NativeShapeGeneric> {
  readonly type: 'object'
  readonly shape: NativeShapeGeneric
  readonly currentValue?: NativeShapeGeneric
  isRequired(): ObjectType<Exclude<NativeShapeGeneric, null>>
  isOptional(): ObjectType<NativeShapeGeneric | null>
}

export default function object<NativeGeneric extends ShapeBase>(
  shape: NativeGeneric,
): ObjectType<NativeGeneric> {
  return {
    ...createType<ObjectType<NativeGeneric>>(),
    type: 'object',
    shape,
  }
}
