import { defineEntity, number, object, string } from '~/prix'

const segmentEntity = defineEntity({
  key: 'segment',
  title: 'Segmentos de empresas',
  sources: ['olap', 'oltp'],
  schema: object({
    id: string().label('ID', 'Identificador', 'Código'),
    description: string().label('Descrição'),
  }),
})

export default segmentEntity
