import { boolean, defineAction, object } from '~/prix'
import systemPrepareCacheAction from './systemPrepareCache.action.json' assert { type: 'json' }

const systemPrepareCacheDefinition = defineAction({
  ...systemPrepareCacheAction,
  input: object({
    minimum: boolean().isOptional(),
    expireTime: boolean().isOptional(),
  }),
  output: object({
    ok: boolean(),
  }),
})

export default systemPrepareCacheDefinition
