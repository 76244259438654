import type { NativeBaseOptional } from '../types/type'
import type { AbstractValueFunction, ValueFunctionContent } from './function'

export interface StAreaFunction<NativeGeneric extends NativeBaseOptional>
  extends AbstractValueFunction<NativeGeneric> {
  name: 'stArea'
}
/**
 * Wrapper da função ST_Area do postgis. Calcula a área do dado geométrico em metros.
 * @link https://postgis.net/docs/ST_Area.html
 * @param content Objeto geométrico sendo Polygon ou Multipolygon do qual se deseja calcular a área..
 * @returns Propriedade compatível com a prixAPI
 */
export default function stArea<NativeGeneric extends NativeBaseOptional = NativeBaseOptional>(
  content: ValueFunctionContent,
): StAreaFunction<NativeGeneric> {
  return {
    kind: 'function',
    name: 'stArea',
    content,
  }
}
