import { defineEntity, object, string } from '~/prix'

const enrollmentEad = defineEntity({
  key: 'matricula',
  title: 'EaD Matricula',
  sources: ['oltp'],
  entitySchema: 'ead',
  schema: object({
    cpf: string(),
    dataInicio: string(),
    dataFim: string(),
    dataMatricula: string(),
    codigoProduto: string(),
    codigoSasDisponibilizacao: string(),
    codigoSasPortfolio: string(),
    nomeProduto: string(),
    tipoProduto: string(),
    percentualConclusaoProduto: string(),
  }),
})

export default enrollmentEad
