import { defineEntity, entity, foreign, object, string } from '~/prix'

const cnaeDivision = defineEntity({
  key: 'cnaeDivision',
  title: 'Divisão de cnae',
  sources: ['olap', 'oltp'],
  schema: object({
    id: string().label('ID'),
    ibgeCode: string().label('Código IBGE'),
    description: string().label('Descrição'),
    observations: string().label('Observações'),
    cnaeSectionId: foreign(entity('cnaeSection').property('id')),
  }),
})

export default cnaeDivision
