import React, { useCallback } from 'react'
import { renderToString } from 'react-dom/server'
import styled from 'styled-components'
import { colors } from '~/design'
import NavBarComponent from './nav/navBar.component'
import LoginComponent from './login/login.component'
import BrasilSquares from '~/meta/brasil-squares.svg'
import SquaresImg from '~/meta/squares.svg'
import { SeoPage } from '~/components/seo'
import useActionFunction from '~/prix/react/hooks/actionFunction'

const SquaresImgString = renderToString(<SquaresImg width={800} height={800} />)

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  background: linear-gradient(60deg, ${colors.sebraeBlue}, ${colors.oceanBlue});
  text-align: center;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

const Content = styled.div`
  display: flex;
  height: 100%;
  width: 55%;
  align-items: center;
  justify-content: center;
  padding: 65px 65px 10px 65px;

  @media (max-width: 1450px) {
    margin-right: 10px;
  }

  @media (max-width: 1050px) {
    display: none;
  }
`

const LoginContent = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  width: 45%;
  background-image: url('data:image/svg+xml;utf8, ${SquaresImgString}');
  background-size: initial;
  background-repeat: repeat-y;
  background-position-x: -200px;

  @media (max-width: 1050px) {
    display: block;
    position: absolute;
    height: 100vw;
    width: 100vh;
    background-position-x: -245px;
    background-size: auto;
    transform: rotate(90deg);
  }
`

export default function HomeScreen() {
  return (
    <Wrapper>
      <SeoPage
        title={'prixSEBRAE'}
        url={'https://prixsebrae.com'}
        siteName={'prixsebrae'}
        keywords='prix tech, prixsebrae, sebrae, prixneuron, geo'
        type='website'
      />

      <NavBarComponent />
      <LoginContent>
        <LoginComponent />
      </LoginContent>
      <Content>
        <BrasilSquares width={700} height={700} />
      </Content>
    </Wrapper>
  )
}
