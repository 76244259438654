import { defineAction, object } from '~/prix'
import neighborhoodStreetIntersectionProcessAction from './neighborhoodStreetIntersectionProcess.action.json' assert { type: 'json' }

const neighborhoodStreetIntersectionProcessDefinition = defineAction({
  ...neighborhoodStreetIntersectionProcessAction,
  input: object({}),
  output: object({}),
})

export default neighborhoodStreetIntersectionProcessDefinition
