import { boolean, defineAction, object } from '~/prix'
import legalEntityAttendanceFetchAction from './legalEntityAttendanceFetch.action.json' assert { type: 'json' }

const legalEntityAttendanceFetchActionDefinition = defineAction({
  ...legalEntityAttendanceFetchAction,
  input: object({}),
  output: object({
    ok: boolean(),
  }),
})

export default legalEntityAttendanceFetchActionDefinition
