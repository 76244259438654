import { defineAction, object } from '~/prix'
import fetchOpenStreetMapStateIdAction from './fetchOpenStreetMapStateId.action.json'

const fetchOpenStreetMapIdDefinition = defineAction({
  ...fetchOpenStreetMapStateIdAction,
  input: object({}),
  output: object({}),
})

export default fetchOpenStreetMapIdDefinition
