import createType, { AbstractType } from './type'

export interface AnyType<NativeShapeGeneric extends any | null = any>
  extends AbstractType<NativeShapeGeneric> {
  readonly type: 'any'
  readonly currentValue?: NativeShapeGeneric
  isRequired(): AnyType<Exclude<NativeShapeGeneric, null>>
  isOptional(): AnyType<NativeShapeGeneric | null>
}

export default function any<NativeGeneric extends any>(): AnyType<NativeGeneric> {
  return {
    ...createType<AnyType<NativeGeneric>>(),
    type: 'any',
  }
}
