import {
  entity,
  isNull,
  query,
  string,
  truthy,
  like,
  equals,
  number,
  count,
  descending,
  between,
} from '~/prix'

export default function AgentSegmentListQuery({
  agentCpf,
  dataSourceId,
  startDate,
  endDate,
}: {
  agentCpf: string
  dataSourceId?: string
  startDate: string
  endDate: string
}) {
  return query('legalEntityAttendance')
    .select({
      count: count(entity('legalEntity').property('mainCnaeId')),
      segmentDescription: entity('segment').property('description'),
    })
    .join({
      current: entity('legalEntityAttendance').property('legalEntityId'),
      target: entity('legalEntity').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityAttendance').property('id'),
      target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntity').property('mainCnaeId'),
      target: entity('cnaeSegment').property('cnaeId'),
      join: 'inner',
    })
    .join({
      current: entity('cnaeSegment').property('segmentId'),
      target: entity('segment').property('id'),
      join: 'inner',
    })
    .where(
      ...[
        isNull(entity('legalEntity').property('deletedAt')),
        like(entity('legalEntityAttendance').property('agentCpf'), string().value(agentCpf)),
        dataSourceId !== undefined
          ? equals(
              entity(`legalEntityAttendanceDataSource`).property(`dataSourceId`),
              number().value(Number(dataSourceId)),
            )
          : null,
        between(
          entity('legalEntityAttendance').property('startDate'),
          string().value(startDate),
          string().value(endDate),
        ),
      ].filter(truthy),
    )
    .order(descending('count'))
}
