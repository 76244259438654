import React from 'react'
import styled from 'styled-components'
import Link from '~/prix/react/components/link'
import { colors } from '~/design'
import NotFoundIcon from '~/meta/notFound.svg'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    .svg {
      width: 70%;
      display: flex;
    }
  }
`
const Message = styled.div`
  display: center;
  margin-bottom: 5px;
  margin-top: 20px;
  align-items: center;

  small {
    font-size: 20px;
    color: ${colors.sebraeBlue};
  }

  span {
    font-weight: bold;
    color: ${colors.darkGray};
    font-size: 28px;
    color: ${colors.sebraeBlue};
  }
`

const Button = styled(Link)`
  text-decoration: none;
  font-size: 1rem;
  font-weight: 700;
  background-color: ${colors.sebraeBlue};
  color: #fff;
  border-radius: 4px;
  align-self: center;
  padding: 0.5rem 2rem;
  margin: 0.625rem;
  align-items: center;
  transition-duration: 0.4s;
  border: 1px solid transparent;

  span {
    font-size: 16px;
    color: #fff;
  }

  &:hover {
    color: #fff;
    background-color: ${colors.oceanBlue};
    border: 1px solid #fff;
  }
`

export default function NotFound() {
  return (
    <Wrapper>
      <div className='svg'>
        <NotFoundIcon width={400} height={400} />
      </div>
      <Message>
        <small>Ocorreu um erro</small>
        <br />
        <span>Página não encontrada</span>
        <br />
      </Message>
      <Button href='/app'>
        <span>Página inicial</span>
      </Button>
    </Wrapper>
  )
}
