import { defineAction, object } from '~/prix'
import neighborhoodProcessAction from './neighborhoodProcess.action.json' assert { type: 'json' }

const neighborhoodProcessDefinition = defineAction({
  ...neighborhoodProcessAction,
  input: object({}),
  output: object({}),
})

export default neighborhoodProcessDefinition
