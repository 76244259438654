import React from 'react'
import { useViewMode } from '~/prix/react/hooks/viewMode'

export default function LayoutInformation() {
  const viewMode = useViewMode()

  return (
    <>
      <thead>
        <tr>
          <th colSpan={2}>Layout</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Modo de visualização</td>
          <td>{viewMode}</td>
        </tr>
      </tbody>
    </>
  )
}
