import React, { useRef } from 'react'
import OriginalModal from 'react-modal'
import styled from 'styled-components'
import RemoveIcon from '~/components/icons/ui/24px_bold-remove.svg'
import { scrollContext } from './scrollContext'
import useMedia from 'react-use/lib/useMedia'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  @media (max-width: 1024px) {
    padding-bottom: 50px;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  display: block;
  top: 0;
  right: 12px;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 8px;
  z-index: 999;
`
export type ModalStyle = 'fullScreen' | 'halfScreen' | 'homeScreen' | 'floatingScreen' | 'biggerTopCardScreen' | 'default'

export default function Modal({
  isOpen,
  onClose,
  scrollY = false,
  children,
  customStyles,
  modalStyle,
  hideCloseButton = false,
  isNotification = false,
}: {
  isOpen: boolean
  onClose: () => void
  scrollY?: boolean
  children: React.ReactNode
  modalStyle?: ModalStyle
  customStyles?: {
    content: {
      borderRadius?: string
      flex?: string
      height?: string
      margin?: string
      maxWidth?: string
      left?: string
      top?: string
      padding?: string
      background?: string
    }
  }
  isNotification?: boolean
  hideCloseButton?: boolean
}) {
  const wrapperReference = useRef(null)
  const isMobileLarge = useMedia('(max-width: 1024px)')

  var overlayClass = 'prix-modal-overlay'
  switch (modalStyle) {
    case 'fullScreen':
      overlayClass = 'prix-fullscreen-modal-overlay'
      break
    case 'halfScreen':
      overlayClass = 'prix-half-screen-modal-overlay'
      break
    case 'homeScreen':
      overlayClass = 'prix-home-screen-modal-overlay'
      break
    case 'floatingScreen':
      overlayClass = 'prix-floating-screen-modal-overlay'
      break
    case 'biggerTopCardScreen':
      overlayClass = 'prix-screen-modal-overlay-bigger-card'
      break
    case 'default':
      overlayClass = 'prix-modal-overlay'
      break
    default:
      overlayClass = 'prix-modal-overlay'
      break
  }

  if (modalStyle === 'halfScreen' && !customStyles) {
    customStyles = {
      content: { maxWidth: '100%', background: '#F8F8F8' }
    }
  }

  if (modalStyle === 'fullScreen') {
    return (
      <OriginalModal
        isOpen={isOpen}
        className='prix-modal'
        overlayClassName={overlayClass}
        style={customStyles = {
          content: {
            borderRadius: '0', flex: '1', height: '100%', margin: '0', maxWidth: '100%', left: '0', top: '0', background: '#F8F8F8',
          }
        }}
        portalClassName={isNotification ? 'prix-notification-modal' : undefined}
      >
        <Wrapper ref={wrapperReference} style={scrollY ? { overflowY: 'scroll' } : { overflowY: 'hidden' }}>
          <scrollContext.Provider value={wrapperReference}>{children}</scrollContext.Provider>
        </Wrapper>

        <CloseButton
          onClick={event => {
            event.preventDefault()
            onClose()
          }}
          style={{ display: hideCloseButton || isMobileLarge ? 'none' : 'block' }}
        >
          <RemoveIcon width={20} height={20} fill='#ccc' />
        </CloseButton>
      </OriginalModal>
    )
  }

  return (
    <OriginalModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className='prix-modal'
      overlayClassName={overlayClass}
      style={customStyles ? customStyles : undefined}
      portalClassName={isNotification ? 'prix-notification-modal' : undefined}
    >
      <Wrapper ref={wrapperReference} style={scrollY ? { overflowY: 'scroll' } : { overflowY: 'hidden' }}>
        <scrollContext.Provider value={wrapperReference}>{children}</scrollContext.Provider>
      </Wrapper>

      <CloseButton
        onClick={event => {
          event.preventDefault()
          onClose()
        }}
        style={{ display: hideCloseButton ? 'none' : 'block' }}
      >
        <RemoveIcon width={20} height={20} fill='#ccc' />
      </CloseButton>
    </OriginalModal>
  )
}
