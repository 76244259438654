import React, { memo, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import EnumeratedField from '~/prix/react/components/form/field/enumeratedField'
import { enumerated } from '~/prix'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 20px;
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px 0px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .container-text {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 24px 0px 0px;
    width: 75%;
    flex-direction: column;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .container-input {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    width: 25%;

    .enum-wrapper {
      width: 88%;

      @media (max-width: 768px) {
        width: 50%;
      }
    }

    @media (max-width: 768px) {
      margin: 10px 0px 5px 0px;
      width: 100%;
    }
  }

  .title {
    color: #0f438a;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 5px;
  }

  .subtitle {
    font-size: 14px;
    color: #0f438a;
    font-weight: 500;
    line-height: 1.35;
  }
`

export interface ChartDataProps {
  year: string
  month: string
  balance: number
  admissions: number
  demissions: number
}

interface LegalEntityGeoprocessingMapDemarcationReportChartProps {
  values: ChartDataProps[]
  onChangeSelectedYearChart: React.Dispatch<React.SetStateAction<string>>
}

function legalEntityGeoprocessingMapDemarcationReportChart({
  values,
  onChangeSelectedYearChart,
}: LegalEntityGeoprocessingMapDemarcationReportChartProps) {
  const years = Array.from(new Set(values.map(item => item.year))).sort()
  const [selectedYear, setSelectedYear] = React.useState<string>(years[years.length - 1])
  const enumeratedYears = enumerated(years.reduce((acc, year) => ({ ...acc, [year]: year }), {}))

  const chartData = useMemo(() => {
    onChangeSelectedYearChart(selectedYear)
    return values
      .filter(item => item.year === selectedYear)
      .map(item => {
        return {
          date: `${item.month}/${item.year}`,
          admissões: item.admissions,
          demissões: item.demissions,
        }
      })
  }, [selectedYear])

  return (
    <Wrapper>
      <HeaderContainer>
        <div className='container-text'>
          <span className='title'>CAGED</span>
          <span className='subtitle'>
            Gráfico de admissões e demissões do ano de {selectedYear}.
          </span>
        </div>
        <div className='container-input'>
          <div className='enum-wrapper'>
            <EnumeratedField
              meta={{ label: 'Ano' }}
              fieldSchema={enumeratedYears}
              value={selectedYear}
              onDirectChange={value => setSelectedYear(value)}
            />
          </div>
        </div>
      </HeaderContainer>

      {chartData ? (
        <div>
          <ResponsiveContainer width={'99%'} height={350}>
            <LineChart
              width={500}
              height={400}
              data={chartData}
              margin={{
                top: 5,
                right: 5,
                left: 5,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray='5 5' opacity={0.8} />
              <XAxis
                dataKey='date'
                interval={0}
                angle={-45}
                textAnchor='end'
                height={60}
                tick={{ fontSize: 13 }}
              />
              <YAxis allowDataOverflow={true} tick={{ fontSize: 13 }} />
              <Tooltip />
              <Legend />
              <Line
                type='linear'
                dataKey='admissões'
                isAnimationActive={false}
                stroke='#005EB8'
                strokeWidth={2.5}
                activeDot={{ r: 8 }}
              />
              <Line
                type='linear'
                dataKey='demissões'
                isAnimationActive={false}
                stroke='#d30d0d'
                strokeWidth={2.5}
                activeDot={{ r: 6 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      ) : null}
    </Wrapper>
  )
}

export default memo(legalEntityGeoprocessingMapDemarcationReportChart)
