import { array, defineAction, object, string } from '~/prix'
import { itineraryObject } from '../fetchItinerary/fetchItinerary.definition'
import fetchLegalEntityItineraryStatusAction from './fetchLegalEntityItineraryStatus.action.json'

const fetchLegalEntityItineraryStatusDefinition = defineAction({
  ...fetchLegalEntityItineraryStatusAction,
  input: object({
    cnpj: string(),
    id: string(),
    cpf: string().isOptional(),
  }),
  output: object({
    activeItinerary: itineraryObject.isOptional(),
    warnings: array(
      object({
        message: string(),
      }),
    ).isOptional(),
  }),
})

export default fetchLegalEntityItineraryStatusDefinition
