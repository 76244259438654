import { defineAction, object, string } from '~/prix'
import generateAgentReportHeatmapDataAction from './generateAgentReportHeatmapData.action.json' assert { type: 'json' }

const seedAgentReportHeatmapDefinition = defineAction({
  ...generateAgentReportHeatmapDataAction,
  input: object({
    agentCpf: string(),
    startDate: string(),
    endDate: string(),
  }),
  output: object({}),
})

export default seedAgentReportHeatmapDefinition
