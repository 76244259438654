import { descending, entity, equals, query, string, sum } from '~/prix'

interface CountCityCensusEntitiesByArea {
  groupColumn: string
  id: string
  level: string
}

export default function countCityCensusEntitiesByArea({
  groupColumn,
  id,
  level,
}: CountCityCensusEntitiesByArea) {
  switch (groupColumn) {
    case 'countryId':
      return query('cityCensusByArea')
        .select({
          geoId: entity('cityCensusByArea').property(groupColumn),
          highlight: sum(entity('cityCensusByArea').property('viewArea')),
          lastUpdated: entity('cityCensusByArea').property('lastUpdated'),
        })
        .where(equals(entity('cityCensusByArea').property(`${level}Id`), string().value('30')))
        .order(descending('lastUpdated'))

    case 'macroRegionId':
      return query('cityCensusByArea')
        .select({
          geoId: entity('cityCensusByArea').property(groupColumn),
          highlight: sum(entity('cityCensusByArea').property('viewArea')),
          lastUpdated: entity('cityCensusByArea').property('lastUpdated'),
        })
        .where(equals(entity('cityCensusByArea').property(`${level}Id`), string().value(id)))
        .order(descending('lastUpdated'))

    case 'stateId':
      return query('cityCensusByArea')
        .select({
          geoId: entity('cityCensusByArea').property(groupColumn),
          highlight: sum(entity('cityCensusByArea').property('viewArea')),
          lastUpdated: entity('cityCensusByArea').property('lastUpdated'),
        })
        .where(equals(entity('cityCensusByArea').property(`${level}Id`), string().value(id)))
        .order(descending('lastUpdated'))

    case 'mesoRegionId':
      return query('cityCensusByArea')
        .select({
          geoId: entity('cityCensusByArea').property(groupColumn),
          highlight: sum(entity('cityCensusByArea').property('viewArea')),
          lastUpdated: entity('cityCensusByArea').property('lastUpdated'),
        })
        .where(equals(entity('cityCensusByArea').property(`${level}Id`), string().value(id)))
        .order(descending('lastUpdated'))

    case 'microRegionId':
      return query('cityCensusByArea')
        .select({
          geoId: entity('cityCensusByArea').property(groupColumn),
          highlight: sum(entity('cityCensusByArea').property('viewArea')),
          lastUpdated: entity('cityCensusByArea').property('lastUpdated'),
        })
        .where(equals(entity('cityCensusByArea').property(`${level}Id`), string().value(id)))
        .order(descending('lastUpdated'))

    case 'cityId':
      return query('cityCensusByArea')
        .select({
          geoId: entity('cityCensusByArea').property(groupColumn),
          highlight: entity('cityCensusByArea').property('viewArea'),
          lastUpdated: entity('cityCensusByArea').property('lastUpdated'),
        })
        .where(equals(entity('cityCensusByArea').property(`${level}Id`), string().value(id)))
        .order(descending('lastUpdated'))

    case 'neighborhoodId':
      return query('cityCensusByArea')
        .select({
          geoId: entity('cityCensusByArea').property('cityId'),
          highlight: entity('cityCensusByArea').property('viewArea'),
          lastUpdated: entity('cityCensusByArea').property('lastUpdated'),
        })
        .where(equals(entity('cityCensusByArea').property('cityId'), string().value(id)))
        .order(descending('lastUpdated'))
        .limit(1)
    default:
      throw new Error(`Error`)
  }
}
