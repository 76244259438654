import { defineAction, object } from '~/prix'
import fetchOpenStreetMapId from './fetchOpenStreetMapId.action.json' assert { type: 'json' }

const fetchOpenStreetMapIdDefinition = defineAction({
  ...fetchOpenStreetMapId,
  input: object({}),
  output: object({}),
})

export default fetchOpenStreetMapIdDefinition
