import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import objectHash from 'object-hash'
import browserCache from '~/prix/utils/browserCache'
import { EntityKey } from '../../entity'
import { AppError } from '../../error'
import { SerializableQuery, Selectables, SelectablesToItem } from '../../query'
import resolve from '../../resolve'
import useAPI from './api'
import { v4 as generateUUID } from 'uuid'

export default function useItems<
  MainEntityKey extends EntityKey,
  MainEntityKeyAlias extends string,
  Selection extends Selectables,
>(
  queryFactory: () => SerializableQuery<MainEntityKey, MainEntityKeyAlias, Selection>,
  deps: React.DependencyList,
  {
    autoLoad = true,
    cache,
  }: {
    autoLoad?: boolean
    /**
     * Cache in seconds
     */
    cache?: number
  } = {},
) {
  const api = useAPI()
  const query = useMemo(queryFactory, deps)
  const [items, setItems] = useState<SelectablesToItem<Selection>[]>()
  const [error, setError] = useState<Error | AppError>()
  const [isLoading, setIsLoading] = useState(autoLoad)
  const requestId = useMemo(() => {
    const generatedId = generateUUID()
    return generatedId
  }, [api, query, autoLoad])

  // //Cancelar a requisição do componente se estiver desmontando e ainda estiver carregando...
  // useEffect(() => {
  //   return () => {
  //     if (isLoading) {
  //       const data = new Blob(
  //         [
  //           JSON.stringify({
  //             userId: api.context.user?.id,
  //             requestedTimestamp: new Date().getTime(),
  //             requestId: requestId,
  //           }),
  //         ],
  //         {
  //           type: 'application/json',
  //         },
  //       )
  //       window.navigator.sendBeacon('/api/v1/abort-pending-user-queries/', data)
  //     }
  //   }
  // }, [isLoading, api, query])

  const load = () => {
    setError(undefined)

    const hash = cache
      ? objectHash(query, {
          respectType: false,
          respectFunctionProperties: false,
        })
      : null
    const cacheName = `${query.entityKey}.${hash}`

    setIsLoading(true)

    let abort: () => Promise<void> = async () => undefined
    const resultPromise = browserCache(
      {
        bypass: !cache,
        name: cacheName,
        expirationSeconds: cache || 0,
        args: [],
      },
      async () => {
        api.context.requestId = requestId
        const { abort: newAbort, run } = resolve({
          ...api,
          query,
          onAbort: () => {
            setIsLoading(false)
            setItems(undefined)
            setError(undefined)
          },
        })
        abort = newAbort

        const { firstResponse } = await run({})
        return firstResponse
      },
    )
      .then(result => {
        const { items } = result
        if (items === undefined || (result.status && result.status !== 200)) {
          return result
        }
        setItems(items)
        setError(undefined)
        setIsLoading(false)
        return result
      })
      .catch(error => setError(error))
      .finally(() => setIsLoading(false))

    return {
      abort,
      resultPromise,
    }
  }

  useEffect(() => {
    if (!autoLoad) {
      return
    }

    const loadResult = load()
    return () => {
      loadResult.abort()
    }
  }, [api, query, autoLoad])

  return {
    items,
    error,
    isLoading,
    query,
    load,
  }
}
