import { defineEntity, object, string } from '~/prix'

const cnaeSection = defineEntity({
  key: 'cnaeSection',
  title: 'Seção de CNAE',
  sources: ['olap', 'oltp'],
  schema: object({
    id: string().label('ID'),
    ibgeCode: string().label('Código IBGE'),
    description: string().label('Descrição'),
    observations: string().label('Observações'),
    cnaes: string().label('Divisões de cnae pertencentes à seção separados por vírgula'),
  }),
})

export default cnaeSection
