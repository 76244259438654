import { Polyline, PolylineProps } from '@react-google-maps/api'
import React, { useMemo } from 'react'
import { colors } from '~/design/index'
import { GeoJsonLineString } from '~/prix/types/geoJson'

export interface GeoJsonLineStringProps extends PolylineProps {
  geoJson: GeoJsonLineString
}

export default function GeoJsonLineString({ geoJson, ...props }: GeoJsonLineStringProps) {
  const polylinePaths = useMemo(() => {
    return geoJson.coordinates.map(([lng, lat]) => ({ lat, lng }))
  }, [geoJson])

  const options = {
    fillColor: colors.oceanBlue,
    fillOpacity: 0.4,
    strokeColor: colors.oceanBlue,
    strokeWeight: 1,
    zIndex: 1,
  }

  return <Polyline path={polylinePaths} options={options} {...props} />
}
