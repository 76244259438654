import { defineAction, object } from '~/prix'
import fetchCourseDataSourceHelpersAction from './fetchDataSourceHelpers.action.json'

const fetchDataSourceHelpersDefinition = defineAction({
  ...fetchCourseDataSourceHelpersAction,
  input: object({}),
  output: object({}),
})

export default fetchDataSourceHelpersDefinition
