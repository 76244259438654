import {
  FilterHighlight,
  GeoChildrenLegalEntitiesPossibilities,
  GeoLegalEntitiesPossibilities,
} from '../legalEntityGeoprocessing/map/legalEntityGeoprocessingMapLevels.data'
import { DefinedOptionMerged } from '../legalEntityGeoprocessing/map/menu/legalEntityGeoprocessingMapMenu.data'
import useCityLegalEntities from './cityLegalEntities.hook'
import useCountryLegalEntities from './countryLegalEntities.hook'
import useMacroRegionLegalEntities from './macroRegionLegalEntities.hook'
import useMesoRegionLegalEntities from './mesoRegionLegalEntities.hook'
import useMicroRegionLegalEntities from './microRegionLegalEntities.hook'
import useNeighborhoodLegalEntities from './neighborhoodLegalEntities.hook'
import useStateLegalEntities from './stateLegalEntities.hook'
import useStreetLegalEntities from './streetLegalEntities.hook'

export interface GeoLegalEntitiesOptions {
  id: string | null
  geo: GeoLegalEntitiesPossibilities
  filter: FilterHighlight | null
  highlight: FilterHighlight | null
  childrenGeoLevel?: GeoChildrenLegalEntitiesPossibilities
  isEnabled?: boolean
  definedOption: DefinedOptionMerged | null
  level?: any
}

export default function useGeoLegalEntities({
  id,
  geo,
  filter,
  highlight,
  definedOption,
  childrenGeoLevel,
  isEnabled = true,
}: GeoLegalEntitiesOptions) {
  const results = {
    country: useCountryLegalEntities({
      filter: filter && filter.value ? filter : null,
      highlight: highlight && highlight.value ? highlight : null,
      childrenGeoLevel: childrenGeoLevel as 'macroRegions' | 'states',
      isEnabled: geo === 'country' && isEnabled,
      definedOption: definedOption ?? null,
    }),
    macroRegion: useMacroRegionLegalEntities({
      id: id!,
      filter: filter && filter.value ? filter : null,
      highlight: (highlight && highlight.value) || (highlight && highlight.courseProduct) ? highlight : null,
      childrenGeoLevel: childrenGeoLevel as 'states',
      isEnabled: geo === 'macroRegion' && isEnabled,
      definedOption: definedOption ?? null,
    }),
    state: useStateLegalEntities({
      id: id!,
      filter: filter && filter.value ? filter : null,
      highlight: (highlight && highlight.value) || (highlight && highlight.courseProduct) ? highlight : null,
      childrenGeoLevel: childrenGeoLevel as 'microRegions' | 'mesoRegions' | 'cities',
      isEnabled: geo === 'state' && isEnabled,
      definedOption: definedOption ?? null,
    }),
    mesoRegion: useMesoRegionLegalEntities({
      id: id!,
      filter: filter && filter.value ? filter : null,
      highlight: (highlight && highlight.value) || (highlight && highlight.courseProduct) ? highlight : null,
      childrenGeoLevel: childrenGeoLevel as 'microRegions' | 'cities',
      isEnabled: geo === 'mesoRegion' && isEnabled,
      definedOption: definedOption ?? null,
    }),
    microRegion: useMicroRegionLegalEntities({
      id: id!,
      filter: filter && filter.value ? filter : null,
      highlight: (highlight && highlight.value) || (highlight && highlight.courseProduct) ? highlight : null,
      childrenGeoLevel: childrenGeoLevel as 'cities',
      isEnabled: geo === 'microRegion' && isEnabled,
      definedOption: definedOption ?? null,
    }),
    city: useCityLegalEntities({
      id: id!,
      filter: filter && filter.value ? filter : null,
      highlight: (highlight && highlight.value) || (highlight && highlight.courseProduct) ? highlight : null,
      childrenGeoLevel: childrenGeoLevel as 'neighborhoods',
      isEnabled: geo === 'city' && isEnabled,
      definedOption: definedOption ?? null,
    }),
    neighborhood: useNeighborhoodLegalEntities({
      id: id!,
      filter: filter && filter.value ? filter : null,
      highlight: (highlight && highlight.value) || (highlight && highlight.courseProduct) ? highlight : null,
      childrenGeoLevel: childrenGeoLevel as 'streets',
      isEnabled: geo === 'neighborhood' && isEnabled,
      definedOption: definedOption ?? null,
    }),
    street: useStreetLegalEntities({
      id: id!,
      filter: filter && filter.value ? filter : null,
      highlight: (highlight && highlight.value) || (highlight && highlight.courseProduct) ? highlight : null,
      childrenGeoLevel: childrenGeoLevel as 'streetWithClusters',
      isEnabled: geo === 'street' && isEnabled,
      definedOption: definedOption ?? null,
    }),
  }

  const result = results[geo as keyof typeof results]
  return result
}
