import errors from '../error'

export default async function browserCache<Input extends any[], Output>(
  {
    name,
    bypass = false,
    args,
    expirationSeconds,
  }: {
    name: string
    bypass?: boolean
    args: Input
    expirationSeconds: number
  },
  run: (...args: Input) => Promise<Output>,
) {
  if (bypass) {
    return run(...args)
  }

  const [firstName] = name.split('.')
  const cache = await caches.open(firstName)

  const now = +new Date()
  const url = `/__prix-data-cache/${name}/data`
  const matching = await cache.match(url, {
    ignoreMethod: true,
    ignoreSearch: true,
    ignoreVary: true,
  })
  if (matching) {
    const { result, timestamp } = await matching.json()
    const differenceSeconds = (now - timestamp) / 1000
    if (differenceSeconds <= expirationSeconds) {
      return result
    }
  }

  try {
    const result = await run(...args)
    const headers = new Headers()
    headers.set('Cache-Control', `max-age=${expirationSeconds}`)
    const response = new Response(
      JSON.stringify({
        timestamp: now,
        result,
      }),
      { status: 200, headers },
    )
    cache.put(url, response)
    return result
  } catch (err) {
    const headers = new Headers()
    return new Response(
      JSON.stringify({
        timestamp: now,
      }),
      { status: 500, headers },
    )
  }
}
