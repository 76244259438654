import { defineEntity, entity, foreign, object, string, number, boolean } from '~/prix'
import { lineString, point } from '~/prix/types/geoJson'

const wayEntity = defineEntity({
  key: 'way',
  title: 'Trecho de rua',
  sources: ['olap', 'oltp'],
  schema: object({
    id: string().label('ID', 'Identificador', 'Código'),
    openStreetMapCode: number().label('Código do Open Street Map'),
    highway: string().label('Tipo'),
    surface: string().label('Superfície', 'Revestimento'),
    lanes: number().label('Faixas'),
    oneWay: boolean().label('Mão única'),
    center: point().label('ponto central da way'),
    path: lineString().label('Linha', 'Trecho'),
    streetId: foreign(entity('street').property('id')),
  }),
})

export default wayEntity
