import React, { memo, useCallback, useState } from 'react'
import styled from 'styled-components'
import { colors, organizationalColors } from '~/design'
import { formatAsBrNumber } from '~/prix'
import LegalEntityGeoprocessingMapDemarcationReportMap from './legalEntityGeoprocessingMapDemarcationReportMap.component'
import useMedia from 'react-use/lib/useMedia'
import { DemarcationFeature, FeatureProps } from '../fetchFromDatabase/mapDemarcationReport.hook'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .header-card {
    display: flex;
    height: 130px;
    background-color: #0f438a;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;

    box-shadow: 0px 32px 30px rgba(20, 46, 82, 0.2);

    border-top-right-radius: 20px;
    border-top-left-radius: 20px;

    .title-wrapper {
      display: flex;
      flex-direction: column;

      .title {
        color: #fff;
        font-size: 22px;
        font-weight: 600;
        text-align: center;
        padding-bottom: 5px;

        @media (max-width: 768px) {
          padding: 0px 20px 5px 20px;
        }
      }

      .subtitle {
        color: #58c4da;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
      }
    }
  }

  .body {
    display: flex;
    min-height: 320px;
    background-color: #ffff;
    flex-direction: column;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: 0px 32px 30px rgba(20, 46, 82, 0.2);

    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;

    .index-wrapper {
      display: flex;
      height: 75px;
      width: 100%;

      .button-wrapper {
        display: flex;
        align-items: center;
        column-gap: 30px;

        button {
          height: 50%;
          background: none;
          border: none;
          cursor: pointer;
          outline: inherit;
          border-bottom: 1px solid #8b99a7;
          font-size: 16px;
          color: #8b99a7;

          &:hover {
            color: ${organizationalColors.aquaBlue60};
            border-bottom: 2px solid ${organizationalColors.aquaBlue60};
            transition: 0.1s;
          }
        }
      }
    }

    .values-wrapper {
      display: flex;
      flex: 1;
      align-items: center;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      .container {
        width: 45%;
        height: 100%;

        @media (max-width: 768px) {
          width: 100%;
        }

        .title {
          display: flex;
          height: 70px;
          align-items: flex-end;
          padding-left: 10px;

          @media (max-width: 768px) {
            height: 25px;
          }

          span {
            font-size: 19px;
            color: ${colors.sebraeBlue};
            font-weight: 600;
            font-style: italic;
          }
        }

        .cards {
          display: flex;
          flex-direction: row;
          height: 155px;
          padding: 0px 10px 0px 10px;
          align-items: center;

          @media (max-width: 768px) {
            height: 125px;
          }
        }
      }

      .legal-entity-sizes-wrapper {
        width: 55%;
        height: 100%;

        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }

  .header-map {
    display: flex;
    height: 130px;
    background-color: #0f438a;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;

    box-shadow: 0px 32px 30px rgba(20, 46, 82, 0.2);

    border-top-right-radius: 20px;
    border-top-left-radius: 20px;

    .title-wrapper {
      display: flex;
      flex-direction: column;

      .title {
        color: #fff;
        font-size: 22px;
        font-weight: 600;
        text-align: center;
        padding-bottom: 5px;
      }

      .subtitle {
        color: #58c4da;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
      }
    }
  }

  .map {
    display: flex;
    height: 450px;
    margin-bottom: -5px;
  }

  .bottom {
    display: flex;
    flex: 1;
    flex-direction: column;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 0px 32px 30px rgba(20, 46, 82, 0.2);

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      flex-direction: column;

      span {
        padding: 0px 15px 15px 15px;
        color: #0f438a;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
      }

      .text-wrapper {
        padding: 0px 20px 20px 20px;
        p {
          text-indent: 30px;
          text-align: justify;
          margin: 0;
          padding-left: 15px;
          padding-right: 15px;
          padding-bottom: 10px;
          line-height: 1.5;
          font-size: 16px;

          @media (max-width: 768px) {
            font-size: 15px;
          }
        }
      }
    }
  }
`

const Card = styled.div`
  background: #f0f6fe;
  height: 100px;
  padding: 0px 10px 0px 10px;
  max-width: 165px;

  .value-wrapper {
    display: flex;
    height: 40px;
    align-items: flex-end;
    justify-content: center;

    span {
      color: #121921;
      font-size: 23px;
      font-weight: 700;
    }
  }

  .description-wrapper {
    display: flex;
    height: 60px;
    padding-top: 10px;
    justify-content: center;
    text-align: center;

    span {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #636f7a; /* 8B99A7 */
      font-size: 14px;
    }
  }
`

interface ValuesProps {
  result: {
    averageCost: {
      value: string
      legalEntityCount: number
    }
    currentYear: number
    legalEntityAttendanceCount: string | number
    legalEntityCount: number
    legalEntityMeiCount: number
    legalEntitySizeCount: {
      micro: number | null
      small: number | null
      other: number | null
    }
    revenue: {
      value: string
      legalEntityCount: number
    }
  }
}

interface LegalEntityGeoprocessingMapDemarcationReportCardProps {
  title: string
  subtitle: string
  values: ValuesProps
  isMap?: boolean
  feature?: {
    bbox: number[]
    features: FeatureProps[]
    geometry?: {
      type?: string
    }
    properties?: {
      radius?: DemarcationFeature
    }
    isEnabled?: boolean
  }
  text?: {
    firstParagraph: string
    secondParagraph: string
    thirdParagraph: string
    fourthParagraph: string
  }
}

function legalEntityGeoprocessingMapDemarcationReportCard({
  title,
  subtitle,
  values,
  feature,
  isMap,
  text,
}: LegalEntityGeoprocessingMapDemarcationReportCardProps) {
  const [demographicMode, setDemographicMode] = useState<boolean>(true)
  const [economicMode, setEconomicMode] = useState<boolean>(false)

  const economicCondition = useMedia('(max-width: 768px)')

  const currentYear = values?.result.currentYear

  const legalEntityCount = values?.result.legalEntityCount
  const legalEntityAttendanceCount = values?.result.legalEntityAttendanceCount
  const legalEntityMeiCount = values?.result.legalEntityMeiCount
  const legalEntityMicroCount = values?.result.legalEntitySizeCount.micro
  const legalEntitySmallCount = values?.result.legalEntitySizeCount.small

  const averageRevenue = {
    value: values?.result.revenue.value,
    legalEntityCount: values?.result.revenue.legalEntityCount,
  }

  const averageCost = {
    value: values?.result.averageCost.value,
    legalEntityCount: values?.result.averageCost.legalEntityCount,
  }

  const handleDemographicButton = useCallback(() => {
    setEconomicMode(false)
    setDemographicMode(true)
  }, [])

  const handleEconomicButton = useCallback(() => {
    setDemographicMode(false)
    setEconomicMode(true)
  }, [])

  return (
    <Wrapper>
      {isMap !== true ? (
        <>
          <div className='header-card'>
            <div className='title-wrapper'>
              <span className='title'>{title}</span>
              <span className='subtitle'>{subtitle}</span>
            </div>
          </div>
          <div className='body'>
            <div className='index-wrapper'>
              <div className='button-wrapper'>
                <button
                  autoFocus
                  onClick={handleDemographicButton}
                  style={
                    demographicMode === true
                      ? { color: '#005EB8', borderBottom: '2px solid #005EB8' }
                      : undefined
                  }
                >
                  Demográfico
                </button>
                {averageCost.value && averageRevenue.value ? (
                  <button
                    onClick={handleEconomicButton}
                    style={
                      economicMode === true
                        ? { color: '#005EB8', borderBottom: '2px solid #005EB8' }
                        : undefined
                    }
                  >
                    Econômico
                  </button>
                ) : null}
              </div>
            </div>
            {demographicMode ? (
              <div className='values-wrapper'>
                <div className='container'>
                  <div className='title'>
                    <span>Quantidade de empresas</span>
                  </div>
                  <div className='cards'>
                    <Card>
                      <div className='value-wrapper'>
                        <span>{formatAsBrNumber(legalEntityCount)}</span>
                      </div>
                      <div className='description-wrapper'>
                        <span>Total de empresas na área</span>
                      </div>
                    </Card>
                    <Card>
                      <div className='value-wrapper'>
                        <span>{formatAsBrNumber(Number(legalEntityAttendanceCount))}</span>
                      </div>
                      <div className='description-wrapper'>
                        <span>Empresas atendidas em {currentYear}</span>
                      </div>
                    </Card>
                  </div>
                </div>
                <div className='container legal-entity-sizes-wrapper'>
                  <div className='title'>
                    <span>Empresas por porte</span>
                  </div>
                  <div className='cards'>
                    <Card>
                      <div className='value-wrapper'>
                        <span>{formatAsBrNumber(legalEntityMeiCount)}</span>
                      </div>
                      <div className='description-wrapper'>
                        <span>Total de empresas MEI</span>
                      </div>
                    </Card>
                    <Card>
                      <div className='value-wrapper'>
                        <span>{formatAsBrNumber(Number(legalEntityMicroCount))}</span>
                      </div>
                      <div className='description-wrapper'>
                        <span>Total de microempresas</span>
                      </div>
                    </Card>
                    <Card>
                      <div className='value-wrapper'>
                        <span>{formatAsBrNumber(Number(legalEntitySmallCount))}</span>
                      </div>
                      <div className='description-wrapper'>
                        <span>Total de empresas de pequeno porte</span>
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
            ) : (
              <div className='values-wrapper'>
                <div
                  className='container'
                  style={economicCondition ? undefined : { width: '215px' }}
                >
                  <div className='title'>
                    <span>Faturamento médio</span>
                  </div>
                  <div className='cards'>
                    <Card>
                      <div className='value-wrapper'>
                        <span>R$ {averageRevenue.value}</span>
                      </div>
                      <div className='description-wrapper'>
                        <span>Baseado em {averageRevenue.legalEntityCount} empresas</span>
                      </div>
                    </Card>
                  </div>
                </div>
                <div className='container'>
                  <div className='title'>
                    <span>Custo médio</span>
                  </div>
                  <div className='cards'>
                    <Card>
                      <div className='value-wrapper'>
                        <span>R$ {averageCost.value}</span>
                      </div>
                      <div className='description-wrapper'>
                        <span>Baseado em {averageCost.legalEntityCount} empresas</span>
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      ) : null}

      {isMap ? (
        <>
          <div className='header-map'>
            <div className='title-wrapper'>
              <span className='title'>{title}</span>
              <span className='subtitle'>{subtitle}</span>
            </div>
          </div>
          <div className='map'>
            {feature ? (
              <LegalEntityGeoprocessingMapDemarcationReportMap
                feature={feature}
                isLoading={false}
              />
            ) : null}
          </div>

          <div className='bottom'>
            {text ? (
              <div className='wrapper'>
                <span>Relatório dos dados exibidos</span>
                <div className='text-wrapper'>
                  <p>{text.firstParagraph}</p>
                  {averageCost.value && averageRevenue.value ? <p>{text.secondParagraph}</p> : null}
                  <p>{text.thirdParagraph}</p>
                  <p>{text.fourthParagraph}</p>
                </div>
              </div>
            ) : (
              <div className='wrapper'>
                <div className='text-wrapper' />
              </div>
            )}
          </div>
        </>
      ) : null}
    </Wrapper>
  )
}

export default memo(legalEntityGeoprocessingMapDemarcationReportCard)
