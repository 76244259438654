import React, { memo, MouseEvent, ReactNode, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export interface LinkProps {
  href?: string
  children?: ReactNode
  className?: string
  remote?: boolean
  newTab?: boolean
  isDisabled?: boolean
  onGo?: (event: MouseEvent) => void
  onClick?: (event: MouseEvent) => void
  isActive?: boolean
  style?: React.CSSProperties
  title?: string
  state?: object
  replace?: boolean
  withoutMargin?: boolean
}

function isLeftClickEvent(event: MouseEvent) {
  return event.button === 0
}

function isModifiedEvent(event: MouseEvent) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
}

function Link({
  children,
  className = '',
  remote = false,
  newTab = false,
  isActive,
  state,
  onClick,
  onGo,
  isDisabled,
  replace = false,
  href = '?',
  withoutMargin,
  ...otherProps
}: LinkProps) {
  const navigate = useNavigate()
  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (remote || newTab || isDisabled) {
        return
      }

      if (onClick) {
        onClick(event)
        if (event.isPropagationStopped()) {
          return
        }
      }

      if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
        return
      }

      event.preventDefault()
      if (onGo) {
        onGo(event)
        return
      }

      navigate(href, {
        replace,
        state,
      })
    },
    [href, state, remote, newTab, isDisabled, onClick, onGo, replace],
  )

  return (
    <a
      className={`${isDisabled ? 'is-disabled' : ''} ${className}`}
      onClick={handleClick}
      target={newTab ? '_blank' : undefined}
      rel={newTab ? 'noopener' : undefined}
      href={href}
      {...otherProps}
    >
      {children}
    </a>
  )
}
export default memo(Link)
