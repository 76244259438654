import { useMemo } from 'react'
import useItems from '~/prix/react/hooks/items'
import { GeoJsonPoint } from '~/prix/types/geoJson'
import { FilterHighlight } from '../map/legalEntityGeoprocessingMapLevels.data'
import mapHeatmapQuery from './mapHeatmap.query'
import { GeoLegalEntitiesPossibilities } from './../map/legalEntityGeoprocessingMapLevels.data'
import { DefinedOption } from '../map/menu/legalEntityGeoprocessingMapMenu.data'
import useGoogleMaps from '~/prix/react/hooks/googleMaps'

interface HeatmapOptions {
  id: string | null
  geo: GeoLegalEntitiesPossibilities
  filter?: FilterHighlight | null
  isEnabled?: boolean
  definedOption?: DefinedOption | null
}

/**
 * Retornar um nível cujo tenha o heatmap disponível, Atualmente disponível os níveis Nacional, Estadual e Municipal.
 * @param geoLevel Identificador do nível do mapa.
 * @returns Nível equivalente de acordo com os heatmap disponíveis
 */
function getAvailableHeatmapResolution(
  geoLevel: GeoLegalEntitiesPossibilities,
): 'country' | 'state' | 'city' {
  const geoLevelCorrespondingHeatmap: {
    [geoLevel: string]: 'country' | 'state' | 'city'
  } = {
    country: 'country',
    macroRegion: 'country',
    state: 'state',
    mesoRegion: 'state',
    microRegion: 'state',
    city: 'city',
    neighborhood: 'city',
    street: 'city',
    clusteredLegalEntity: 'city',
    legalEntity: 'city',
  }
  return geoLevelCorrespondingHeatmap[geoLevel]
}

export default function useHeatmap({ id, filter, geo, isEnabled, definedOption }: HeatmapOptions) {
  const simplifiedMapLevel = useMemo(() => getAvailableHeatmapResolution(geo), [geo])
  const { google } = useGoogleMaps()

  // Evitar renderizar heatmap em níveis mais fundos que neighborhood.
  const avoidTriggerDeeperLevels = () => {
    if (simplifiedMapLevel === 'city' && geo !== 'city') {
      return 'deeper_city'
    } else {
      return geo
    }
  }

  const result = useItems(
    () =>
      mapHeatmapQuery({
        filter: filter as FilterHighlight | null,
        availableGeoLevel: simplifiedMapLevel,
        mapLevel: geo,
        parentId: id,
        definedOption,
      }),
    [filter, simplifiedMapLevel, geo, id, avoidTriggerDeeperLevels(), definedOption],
    {
      cache: 60 * 60 * 24,
      autoLoad: isEnabled,
    },
  )

  const data = useMemo(() => {
    if (result.items && google) {
      const heatmapPoints: { weight: number; location: google.maps.LatLng }[] = []
      result.items.forEach(item => {
        if (item.location) {
          const locationAsGeoJson = item.location as GeoJsonPoint
          const location = new google.maps.LatLng(
            locationAsGeoJson.coordinates[1],
            locationAsGeoJson.coordinates[0],
          )
          heatmapPoints.push({
            weight: item.weight,
            location,
          })
        }
      })
      return heatmapPoints
    } else {
      return []
    }
  }, [result.isLoading, definedOption, google])

  // Nível máximo de zoom que a resolução atual suporta sem perder detalhes.
  // Vide https://user-images.githubusercontent.com/38020033/169533788-9234ebf0-9e43-47ca-9cbd-4cdd5ecaa483.png
  let resolutionThreshold: number
  switch (simplifiedMapLevel) {
    case 'country':
      resolutionThreshold = 6
      break
    case 'state':
      resolutionThreshold = 10
      break
    case 'city':
      resolutionThreshold = 14
      break
  }

  return { heatmapPoints: data, isLoading: result.isLoading, resolutionThreshold }
}
