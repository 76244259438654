import React, { useMemo, useState } from 'react'
import AppLayout from '~/components/appLayout'
import useMapDemarcationReport, {
  FeatureProps,
  MapDemarcationReportProps,
} from '../fetchFromDatabase/mapDemarcationReport.hook'
import styled from 'styled-components'
import { colors } from '~/design'
import LegalEntityGeoprocessingMapDemarcationReportCardComponent from './legalEntityGeoprocessingMapDemarcationReportCard.component'
import { formatAsBrDecimalNumber, formatAsBrNumber } from '~/prix'
import { useNavigate } from 'react-router-dom'
import PulseLoader from 'react-spinners/PulseLoader'
import LoadingMessage from '~/prix/react/components/loadingMessage'
import LegalEntityGeoprocessingMapDemarcationReportListComponent from './legalEntityGeoprocessingMapDemarcationReportList.component'
import LegalEntityGeoprocessingMapDemarcationReportIndicatorComponent from './legalEntityGeoprocessingMapDemarcationReportIndicator.component'
import ArrowLeftBottomIcon from '~/meta/report/arrow-left-bottom.svg'
import ArrowLeftHeaderIcon from '~/meta/report/arrow-left-header.svg'
import useMedia from 'react-use/lib/useMedia'
import { PDFDownloadLink as OriginalPDFDownloadLink, PDFViewer } from '@react-pdf/renderer'
import LegalEntityGeoprocessingMapDemarcationReportPdfComponent from './pdf/legalEntityGeoprocessingMapDemarcationReportPdf.component'
import OriginalLegalEntityGeoprocessingMapDemarcationExportEntitiesButton from './legalEntityGeoprocessingMapDemarcationExportEntitiesButton.component'
import LegalEntityGeoprocessingMapDemarcationReportCityListComponent from './legalEntityGeoprocessingMapDemarcationReportCityList.component'

const PDFDownloadLink = styled(OriginalPDFDownloadLink)`
  background-color: ${colors.sebraeBlue};
  color: #fff;
  padding: 10px 17.5px 10px 17.5px;
  border-radius: 4px;
  transition: 0.3s;
  white-space: nowrap;

  &:hover {
    background-color: ${colors.oceanBlue};
  }
`

const DisabledButton = styled.a`
  background-color: ${colors.oceanBlue};
  color: #fff;
  padding: 10px 17.5px 10px 17.5px;
  border-radius: 4px;
  margin-left: 10px;
  white-space: nowrap;
  cursor: not-allowed;
  opacity: 0.6;
`

const LegalEntityGeoprocessingMapDemarcationExportEntitiesButton = styled(
  OriginalLegalEntityGeoprocessingMapDemarcationExportEntitiesButton,
)`
  background-color: ${colors.sebraeBlue};
  color: #fff;
  padding: 10px 17.5px 10px 17.5px;
  border-radius: 4px;
  transition: 0.3s;
  margin-left: 10px;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background-color: ${colors.oceanBlue};
  }
`

const Wrapper = styled.div`
  display: flex;
  flex: 1;

  .report {
    display: flex;
    flex: 1;
    flex-direction: column;

    .header {
      display: flex;
      flex: 1;
      max-width: 100vw;
      min-height: 250px;
      background-color: ${colors.sebraeBlue};
      max-height: 250px;

      .first-header-area-wrapper {
        display: flex;
        width: 60%;
        flex-direction: column;

        .title {
          display: flex;
          align-items: center;
          margin-left: 80px;
          color: #ffff;
          font-size: 26px;
          font-weight: 500;
          flex-direction: row;
          margin-top: 60px;

          @media (max-width: 1150px) {
            margin-left: 40px;
          }

          @media (max-width: 600px) {
            font-size: 20px;
          }
        }

        .subtitle {
          display: flex;
          align-items: center;
          margin-left: 80px;
          color: #ffff;
          font-size: 16px;
          font-weight: 500;
          text-align: center;
          flex-direction: row;

          @media (max-width: 1150px) {
            margin-left: 40px;
          }
        }
      }

      .back-button-wrapper {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        padding-top: 60px;
        padding-right: 80px;

        @media (max-width: 965px) {
          width: unset;
        }

        @media (max-width: 1150px) {
          padding-right: 40px;
        }

        button {
          height: 50px;
          background: none;
          border: none;
          cursor: pointer;
          outline: inherit;

          .container {
            display: flex;
            flex-direction: row;

            .icon {
              margin-right: 5px;
            }

            span {
              color: #58c4da;
              font-size: 18px;

              @media (max-width: 600px) {
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    .grid {
      display: flex;
      flex: 1;
      margin-top: -125px;
      max-width: 100vw;
      gap: 25px;
      padding: 20px 50px 50px 50px;

      @media (max-width: 1150px) {
        padding: 20px 25px 50px 25px;
      }

      flex-direction: row;

      .column-data {
        width: 60%;

        .one {
          display: flex;
          padding-bottom: 25px;
        }

        .map {
          padding-bottom: 25px;
          display: flex;
        }

        .list-wrapper {
          display: flex;
        }

        .indicators {
          display: flex;
        }

        .middle-wrapper {
          display: flex;
          flex-direction: row;
          gap: 10px;

          @media (max-width: 768px) {
            flex-direction: column;
          }

          .segment {
            display: flex;
            width: 50%;

            @media (max-width: 768px) {
              width: 100%;
            }
          }

          .legal-nature {
            display: flex;
            width: 50%;

            @media (max-width: 768px) {
              width: 100%;
            }
          }
        }

        @media (max-width: 1150px) {
          width: 100%;
        }
      }

      .column-map {
        width: 40%;

        .map {
          padding-bottom: 25px;
          display: flex;
        }

        @media (max-width: 1150px) {
          display: none;
        }
      }

      .bottom-print {
        display: flex;
        flex-direction: column;
        height: 100px;

        .line-wrapper {
          display: flex;
          width: 100%;
        }

        .buttons-wrapper {
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: flex-end;
          width: 100%;
          gap: 50px;

          @media (max-width: 798px) {
            flex-direction: column-reverse;
            gap: 15px;
          }

          .back-button {
            button {
              height: 50px;
              background: none;
              border: none;
              padding: 0;
              font: inherit;
              cursor: pointer;
              outline: inherit;

              .container {
                display: flex;
                flex-direction: row;

                .icon {
                  margin-right: 5px;
                }

                span {
                  color: #005eb8;
                  font-size: 16px;
                  font-weight: 600;

                  &:hover {
                    color: #0f438a;
                  }
                }
              }
            }
          }

          .print-button {
            @media (max-width: 798px) {
              text-align: center;
              display: flex;
              flex-direction: column;
            }
            a {
              @media (max-width: 798px) {
                margin-top: 15px;
                margin-left: 0;
              }
            }
            button {
              height: 50px;
              background: #005eb8;
              border: none;
              border-radius: 4px;
              cursor: pointer;
              padding-left: 30px;
              padding-right: 30px;

              .container {
                display: flex;
                flex-direction: row;

                .icon {
                  margin-right: 8px;
                }

                span {
                  color: #fff;
                  font-size: 16px;
                  font-weight: 600;
                }
              }

              &:hover {
                transition-duration: 0.4s;
                background: #0f438a;
              }
            }
          }
        }
      }
    }

    .separator {
      height: 25px;
    }

    .article {
      display: flex;
      flex: 1;
      max-width: 100vw;

      .info {
        display: flex;
        flex: 1;
        flex-direction: column;

        p {
          text-indent: 30px;
          margin: 0;
          padding-left: 15px;
          padding-right: 15px;
          padding-bottom: 10px;
          line-height: 1.5;
          font-size: 16px;
        }

        .title {
          padding-left: 15px;
          font-size: 18px;
        }

        .content {
          font-size: 16px;

          ul li::marker {
            color: ${colors.sebraeBlue};
            font-size: 16px;
          }

          span {
            display: flex;
            margin-top: 5px;
            padding-left: 15px;
            font-size: 16px;
          }
        }
      }
    }

    .grid-only-map {
      display: flex;
      flex: 1;
      max-width: 100vw;
      gap: 25px;
      padding: 20px 50px 50px 50px;
      flex-direction: row;

      @media (max-width: 1150px) {
        padding: 20px 25px 50px 25px;
      }

      .column-data {
        width: 60%;

        .map {
          padding-bottom: 25px;
          display: flex;
        }

        @media (max-width: 1150px) {
          width: 100%;
        }
      }

      .column-map {
        width: 40%;
        margin-top: -125px;
        .map {
          padding-bottom: 25px;
        }

        @media (max-width: 1150px) {
          display: none;
        }
      }
    }
  }

  .loading-wrapper {
    display: flex;
    flex: 1;
    align-self: center;
    justify-content: center;

    .center {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .loader {
        display: flex;
        height: 50px;
        flex-direction: row;
        align-items: center;
      }

      .message {
        display: flex;
        height: 50px;
        flex-direction: row;
        font-size: 16px;
        text-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
      }
    }
  }
`

const LineHr = styled.hr`
  margin-top: 20px;
  display: block;
  height: 50px;
  height: 1px;
  background: transparent;
  width: 100%;
  border: none;
  border-top: solid 1px #005eb8;
`

export default function LegalEntityGeoprocessingMapDemarcationReport({
  feature,
  isLoading: isLoadingFeature = false,
}: MapDemarcationReportProps) {
  const navigate = useNavigate()
  const currentYear = new Date().getFullYear()
  const idhm = [
    'hdi',
    'hdiIncome',
    'hdiEducation',
    'hdiLifeExpectancy',
    'hdiEducationAttendance',
    'hdiEducationLevel',
  ]
  const mapCondition = useMedia('(max-width: 1150px)')
  const [imageUrl, setImageUrl] = useState<Array<string>>([])
  const [selectedYearChart, setSelectedYearChart] = useState<string>('')

  const { values, isLoading } = useMapDemarcationReport({
    feature,
    isEnabled: !isLoadingFeature,
  })

  const area = useMemo(() => {
    const value = Number(values?.areaInfo?.areaMeters) / 1000000
    return value ? formatAsBrDecimalNumber(Number(value.toFixed(2))) : 0
  }, [values?.areaInfo])

  const revenueIndicator = useMemo(() => {
    const legalEntityCount = formatAsBrNumber(values?.revenue[0]?.legalEntityCount)
    const value = Number(values?.revenue[0]?.value?.toFixed(2))
    const revenue = formatAsBrDecimalNumber(value)

    return {
      legalEntityCount: Number(legalEntityCount),
      revenue,
    }
  }, [values?.revenue])

  const averageCostIndicator = useMemo(() => {
    const legalEntityCount = formatAsBrNumber(values?.averageCost[0]?.legalEntityCount)
    const value = Number(values?.averageCost[0]?.value?.toFixed(2))
    const cost = formatAsBrDecimalNumber(value)

    return {
      legalEntityCount: Number(legalEntityCount),
      cost,
    }
  }, [values?.averageCost])

  const anotherIndicators = useMemo(() => {
    const result = values?.scores?.map(item => {
      return [
        item.scoreIndicatorsManagement
          ? {
              name: 'Índice de gestão de indicadores',
              count: Number(item.scoreIndicatorsManagement) || null,
            }
          : null,
        item.scoreInnovation
          ? {
              name: 'Índice de inovação',
              count: Number(item.scoreInnovation) || null,
            }
          : null,
        item.scoreMarketing
          ? {
              name: 'Índice de marketing',
              count: Number(item.scoreMarketing) || null,
            }
          : null,
        item.scoreOperationsManagement
          ? {
              name: 'Índice de gestão de operações',
              count: Number(item.scoreOperationsManagement) || null,
            }
          : null,
        item.scoreSustainablePractices
          ? {
              name: 'Índice de práticas sustentáveis',
              count: Number(item.scoreSustainablePractices) || null,
            }
          : null,
        item.scoreTransformation
          ? {
              name: 'Índice de transformação digital',
              count: Number(item.scoreTransformation) || null,
            }
          : null,
      ]
    })

    const validation = result && result[0] ? result[0]?.every(element => element === null) : false

    return validation === false ? result : []
  }, [values?.scores])

  const firstHeaderAreaContent = useMemo(() => {
    const hasProperties = feature?.features?.length && feature.features[0].properties ? true : false

    if (hasProperties) {
      const level = feature.features[0].properties?.level
      const pluralCondition = feature?.features.length > 1
      const levelAux = {
        neighborhood: pluralCondition ? 'Bairros' : 'Bairro',
        city: pluralCondition ? 'Cidades' : 'Cidade',
        microRegion: pluralCondition ? 'Microrregiões' : 'Microrregião',
        mesoRegion: pluralCondition ? 'Mesorregiões' : 'Mesorregião ',
        state: pluralCondition ? 'Estados' : 'Estado',
        macroRegion: pluralCondition ? 'Macrorregiões' : 'Macrorregião',
      }

      const names = feature?.features.map((item: FeatureProps) => item.properties?.name)
      const subtitle = names?.join(', ')

      return {
        title: levelAux[level as unknown as keyof typeof levelAux],
        subtitle,
      }
    }

    const subtitle = feature?.geometry
      ? feature.geometry?.type === 'Polygon'
        ? 'Polígono'
        : 'Raio'
      : ''

    return {
      title: 'Área customizada',
      subtitle,
    }
  }, [area, feature])

  const sizes = useMemo(() => {
    const micro = values?.size.filter(item => item.size === 'micro')
    const small = values?.size.filter(item => item.size === 'small')
    const other = values?.size.filter(item => item.size === 'other')
    const unknown = values?.size.filter(item => item.size === 'unknown')

    return {
      micro: micro ? micro[0]?.count : null,
      small: small ? small[0]?.count : null,
      other: other ? other[0]?.count : null,
      unknown: unknown ? unknown[0]?.count : null,
    }
  }, [values?.size])

  const text = useMemo(() => {
    const neighborhoodCondition =
      area === 0 &&
      feature?.features?.length &&
      feature.features[0].properties?.level === 'neighborhood'
    const pluralNeighborhoodCondition = feature?.features?.length > 1
    const pluralRevenueLegalEntityCondition = revenueIndicator.legalEntityCount > 1
    const pluralAverageCostLegalEntityCondition = averageCostIndicator.legalEntityCount > 1

    const firstParagraph = `A partir ${
      neighborhoodCondition && pluralNeighborhoodCondition
        ? 'dos bairros selecionados'
        : neighborhoodCondition && !pluralNeighborhoodCondition
        ? 'do bairro selecionado'
        : 'da área demarcada'
    } foram encontradas ${formatAsBrDecimalNumber(
      values?.legalEntityCount[0].count,
    )} empresas provenientes de dados da Receita Federal juntamente aos bancos de dados disponibilizados pelo SEBRAE, junção que permite uma maior abrangência. Os valores referentes aos atendimentos no ano corrente ${currentYear}, totalizando ${
      values?.attendances[0].count
    }, possuem como referência os dados disponibilizados pelas fontes do SEBRAE, sendo elas SEBRAE Na Sua Empresa (App), Brasil Mais, Radar ALI e EaD. ${
      area && !neighborhoodCondition ? `A área demarcada engloba ${area} km².` : ''
    }`
    const secondParagraph = `Também são requisitados os valores de faturamento e custo médio, sendo eles R$ ${
      revenueIndicator.revenue
    } e R$ ${averageCostIndicator.cost} respectivamente. O faturamento médio baseia-se em ${
      revenueIndicator.legalEntityCount
    } ${pluralRevenueLegalEntityCondition ? 'empresas' : 'empresa'}, enquanto o custo médio em ${
      averageCostIndicator.legalEntityCount
    } ${
      pluralAverageCostLegalEntityCondition ? 'empresas' : 'empresa'
    }. Ambos valores são extraídos das fontes SEBRAE Brasil Mais e Radar ALI, além disso esses resultados são tratados para evitar outliers.`
    const thirdParagraph = `São requeridos também dados referentes ao porte das empresas, sendo eles ${formatAsBrNumber(
      Number(sizes.micro),
    )} microempresas (ME), ${formatAsBrNumber(
      Number(sizes.small),
    )} empresas de pequeno porte (EPP) e ${formatAsBrNumber(
      Number(sizes.other) + Number(sizes.unknown),
    )} empresas com porte demais. Dentre as ${formatAsBrNumber(
      Number(sizes.micro),
    )} microempresas, ${formatAsBrNumber(
      Number(values?.mei[0].count),
    )} são identificadas como MEIs (Microempreendedor Individual). Esses dados são providos pela Receita Federal junto às fontes Radar ALI, Brasil Mais, EaD disponibilizadas pelo SEBRAE.`
    const fourthParagraph = `Em sequência, são exibidos dados quantitativos relacionados às principais ocorrências de atividade econômica principal, segmentos, natureza jurídica e demais indicadores. Esses dados são oriundos da Receita Federal junto às fontes de dados Radar ALI, Brasil Mais e EaD do SEBRAE.`

    const textResult = {
      firstParagraph,
      secondParagraph,
      thirdParagraph,
      fourthParagraph,
    }

    return textResult
  }, [feature, area, values, revenueIndicator, averageCostIndicator, sizes])

  const reportName = `Relatório ${firstHeaderAreaContent?.subtitle}`

  const citiesHasIdhm = values?.citiesCensus.map(item => {
    const values = item.data.map(item => item.key).filter(item => idhm.includes(item))
    return values.length > 0 ? true : false
  })
  const isNeighborhood =
    feature?.features?.length > 0
      ? feature.features[0]?.properties?.level === 'neighborhood'
      : undefined

  const citiesMerged = values?.citiesCensus
    .map(census => {
      const caged = values?.cityCagedData.find(caged => caged.name === census.name)
      const governmentTransparency = values?.citiesGovernmentTransparency.find(
        transparencyData => transparencyData.name === census.name,
      )

      return {
        name: census.name,
        intersectionArea: census.intersectionArea,
        citiesCensusData: census.data,
        citiesCagedData: caged?.data,
        citiesGovernmentTransparencyData: [governmentTransparency?.data],
      }
    })
    .sort((a, b) => a.name.localeCompare(b.name))

  return (
    <AppLayout title='Relatório da demarcação' dockActive='map'>
      <Wrapper>
        {isLoading || values === undefined ? (
          <div className='loading-wrapper'>
            <div className='center'>
              <div className='loader'>
                <PulseLoader color={colors.sebraeBlue} />
              </div>
              <span className='message'>
                <LoadingMessage
                  messages={[
                    'Pesquisando por informações sobre a área demarcada',
                    'Carregando quantitativos de porte sobre a área demarcada',
                    'Resgatando dados de indicadores sobre a área demarcada',
                    'Carregando principais atividades econômicas sobre a área demarcada',
                    'Pesquisando por quantitativos de atendimento sobre a área demarcada',
                    'Resgatando principais segmentos sobre a área demarcada',
                  ]}
                />
              </span>
            </div>
          </div>
        ) : (
          <div className='report'>
            <div className='header'>
              <div className='first-header-area-wrapper'>
                <span className='title'>
                  Localização por {firstHeaderAreaContent?.title.toLowerCase()}
                </span>
                <span className='subtitle'>{firstHeaderAreaContent?.subtitle}</span>
              </div>
              <div className='back-button-wrapper'>
                <button onClick={() => navigate('../map/demarcation', { replace: true })}>
                  <div className='container'>
                    <div className='icon'>
                      <ArrowLeftHeaderIcon width={22} height={22} />
                    </div>
                    <div>
                      <span>Voltar a página anterior</span>
                    </div>
                  </div>
                </button>
              </div>
            </div>
            {values?.legalEntityCount[0].count > 0 ? (
              <div className='grid'>
                <div className='column-data'>
                  <div className='one'>
                    <LegalEntityGeoprocessingMapDemarcationReportCardComponent
                      title={'Dados demográficos e socieconômicos das empresas'}
                      subtitle={
                        'Dados demográficos e socioeconômicos de todas as empresas que abrangem a área selecionada'
                      }
                      values={{
                        result: {
                          legalEntityCount: values?.legalEntityCount[0].count,
                          legalEntityAttendanceCount: values?.attendances[0].count,
                          legalEntityMeiCount: Number(values?.mei[0].count),
                          legalEntitySizeCount: sizes,
                          averageCost: {
                            value: averageCostIndicator.cost,
                            legalEntityCount: averageCostIndicator.legalEntityCount,
                          },
                          revenue: {
                            value: revenueIndicator.revenue,
                            legalEntityCount: revenueIndicator.legalEntityCount,
                          },
                          currentYear,
                        },
                      }}
                    />
                  </div>
                  {mapCondition ? (
                    <div className='map'>
                      <LegalEntityGeoprocessingMapDemarcationReportCardComponent
                        title={area ? `Área de análise: ${area} km²` : 'Localização'}
                        subtitle={`Visualização prévia da ${
                          area ? 'área de análise' : 'localização'
                        }`}
                        feature={feature}
                        isMap={true}
                        text={text}
                        values={{
                          result: {
                            legalEntityCount: values?.legalEntityCount[0].count,
                            legalEntityAttendanceCount: values?.attendances[0].count,
                            legalEntityMeiCount: Number(values?.mei[0].count),
                            legalEntitySizeCount: sizes,
                            averageCost: {
                              value: averageCostIndicator.cost,
                              legalEntityCount: averageCostIndicator.legalEntityCount,
                            },
                            revenue: {
                              value: revenueIndicator.revenue,
                              legalEntityCount: revenueIndicator.legalEntityCount,
                            },
                            currentYear,
                          },
                        }}
                      />
                    </div>
                  ) : null}
                  <div className='list-wrapper' style={{ paddingBottom: '20px' }}>
                    <LegalEntityGeoprocessingMapDemarcationReportListComponent
                      title={'Atividade econômica principal'}
                      subtitle={
                        'Quantidade de empresas por atividade econômica principal presentes na área demarcada.'
                      }
                      isCnae={true}
                      values={{
                        result: {
                          cnae: values?.cnae,
                        },
                      }}
                    />
                  </div>
                  {anotherIndicators.length > 0 ? (
                    <div className='indicators'>
                      <LegalEntityGeoprocessingMapDemarcationReportIndicatorComponent
                        title={'Média dos demais indicadores'}
                        subtitle={`Média dos demais indicadores baseados em ${values?.scores[0]?.legalEntityCount} empresas:`}
                        values={{
                          result: {
                            indicators: anotherIndicators[0]?.sort(
                              (a, b) => (b?.count || 0) - (a?.count || 0),
                            ),
                            legalEntityCount: values?.scores[0]?.legalEntityCount,
                          },
                        }}
                      />
                    </div>
                  ) : null}
                  <div className='middle-wrapper'>
                    <div className='segment'>
                      <LegalEntityGeoprocessingMapDemarcationReportListComponent
                        title={'Empresas por segmento'}
                        subtitle={
                          'Quantidade de empresas por segmento presentes na área demarcada.'
                        }
                        values={{
                          result: {
                            segment: values?.segment,
                          },
                        }}
                      />
                    </div>
                    <div className='legal-nature '>
                      <LegalEntityGeoprocessingMapDemarcationReportListComponent
                        title={'Empresas por natureza jurídica'}
                        subtitle={
                          'Quantidade de empresas por natureza jurídica presentes na área demarcada.'
                        }
                        values={{
                          result: {
                            segment: values?.legalNature,
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className='list-wrapper'>
                    <LegalEntityGeoprocessingMapDemarcationReportCityListComponent
                      cityData={citiesMerged}
                      setImageUrl={setImageUrl}
                      onChangeSelectedYearChart={setSelectedYearChart}
                      hasSubtitle={
                        area && (isNeighborhood === false || isNeighborhood === undefined)
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className='bottom-print'>
                    <div className='line-wrapper'>
                      <LineHr />
                    </div>
                    <div className='buttons-wrapper'>
                      <div className='back-button'>
                        <button onClick={() => navigate('../map/demarcation', { replace: true })}>
                          <div className='container'>
                            <div className='icon'>
                              <ArrowLeftBottomIcon width={20} height={20} />
                            </div>
                            <div>
                              <span>Voltar a página anterior</span>
                            </div>
                          </div>
                        </button>
                      </div>
                      <div className='print-button'>
                        <PDFDownloadLink
                          document={
                            <LegalEntityGeoprocessingMapDemarcationReportPdfComponent
                              values={{
                                result: {
                                  legalEntityCount: values?.legalEntityCount[0].count,
                                  legalEntityAttendanceCount: values?.attendances[0].count,
                                  legalEntityMeiCount: Number(values?.mei[0].count),
                                  legalEntitySizeCount: sizes,
                                  averageCost: {
                                    value: averageCostIndicator.cost,
                                    legalEntityCount: averageCostIndicator.legalEntityCount,
                                  },
                                  revenue: {
                                    value: revenueIndicator.revenue,
                                    legalEntityCount: revenueIndicator.legalEntityCount,
                                  },
                                  currentYear,
                                  area,
                                  text,
                                  firstHeaderAreaContent,
                                  cnae: values?.cnae,
                                  segment: values?.segment,
                                  legalNature: values?.legalNature,
                                  indicators: {
                                    values: anotherIndicators
                                      ? anotherIndicators[0]?.sort(
                                          (a, b) => (b?.count || 0) - (a?.count || 0),
                                        )
                                      : undefined,
                                    legalEntityCount: values?.scores[0]?.legalEntityCount,
                                  },
                                  feature,
                                  citiesCensus: values?.citiesCensus,
                                  citiesGovernmentTransparency:
                                    values?.citiesGovernmentTransparency,
                                  imageUrl,
                                  selectedYearChart,
                                },
                              }}
                            />
                          }
                          fileName={reportName}
                        >
                          {({ blob, url, loading, error }) =>
                            error
                              ? error.message
                              : loading
                              ? 'Carregando documento...'
                              : 'Gerar PDF'
                          }
                        </PDFDownloadLink>
                        {values?.legalEntityCount[0].count > 5000 ? (
                          //Limite de 5k de empresas
                          <DisabledButton title='A seleção ultrapassa o limite de 5 mil empresas para a exportação.'>
                            {'Download indisponível'}
                          </DisabledButton>
                        ) : (
                          <>
                            <LegalEntityGeoprocessingMapDemarcationExportEntitiesButton
                              format='csv'
                              feature={feature}
                            >
                              {({ loading, error }) =>
                                error
                                  ? error.message
                                  : loading
                                  ? 'Preparando arquivo...'
                                  : 'Exportar empresas (CSV)'
                              }
                            </LegalEntityGeoprocessingMapDemarcationExportEntitiesButton>

                            <LegalEntityGeoprocessingMapDemarcationExportEntitiesButton
                              format='xlsx'
                              feature={feature}
                            >
                              {({ loading, error }) =>
                                error
                                  ? error.message
                                  : loading
                                  ? 'Preparando arquivo...'
                                  : 'Exportar empresas (Excel)'
                              }
                            </LegalEntityGeoprocessingMapDemarcationExportEntitiesButton>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='column-map'>
                  <div className='map'>
                    <LegalEntityGeoprocessingMapDemarcationReportCardComponent
                      title={area ? `Área de análise: ${area} km²` : 'Localização'}
                      subtitle={`Visualização prévia da ${
                        area ? 'área de análise' : 'localização'
                      }`}
                      feature={feature}
                      isMap={true}
                      text={text}
                      values={{
                        result: {
                          legalEntityCount: values?.legalEntityCount[0].count,
                          legalEntityAttendanceCount: values?.attendances[0].count,
                          legalEntityMeiCount: Number(values?.mei[0].count),
                          legalEntitySizeCount: sizes,
                          averageCost: {
                            value: averageCostIndicator.cost,
                            legalEntityCount: averageCostIndicator.legalEntityCount,
                          },
                          revenue: {
                            value: revenueIndicator.revenue,
                            legalEntityCount: revenueIndicator.legalEntityCount,
                          },
                          currentYear,
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className='grid-only-map'>
                <div className='column-data'>
                  {mapCondition ? (
                    <div className='map'>
                      <LegalEntityGeoprocessingMapDemarcationReportCardComponent
                        title={area ? `Área de análise: ${area} km²` : 'Localização'}
                        subtitle={`Visualização prévia da ${
                          area ? 'área de análise' : 'localização'
                        }`}
                        feature={feature}
                        isMap={true}
                        values={{
                          result: {
                            legalEntityCount: values?.legalEntityCount[0].count,
                            legalEntityAttendanceCount: values?.attendances[0].count,
                            legalEntityMeiCount: Number(values?.mei[0].count),
                            legalEntitySizeCount: sizes,
                            averageCost: {
                              value: averageCostIndicator.cost,
                              legalEntityCount: averageCostIndicator.legalEntityCount,
                            },
                            revenue: {
                              value: revenueIndicator.revenue,
                              legalEntityCount: revenueIndicator.legalEntityCount,
                            },
                            currentYear,
                          },
                        }}
                      />
                    </div>
                  ) : null}
                  {citiesMerged?.map((item, index) => {
                    return (
                      <div className='list-wrapper' key={index}>
                        <LegalEntityGeoprocessingMapDemarcationReportListComponent
                          title={item.name}
                          subtitle={
                            area && (isNeighborhood === false || isNeighborhood === undefined)
                              ? `Intersecção de ${
                                  item?.intersectionArea !== null
                                    ? formatAsBrNumber(
                                        parseFloat((item?.intersectionArea / 1000000).toFixed(2)),
                                      )
                                    : item?.citiesCensusData?.length > 0
                                    ? formatAsBrNumber(item?.citiesCensusData[0].value)
                                    : ''
                                } km²`
                              : undefined
                          }
                          isCity={true}
                          hasIdhm={citiesHasIdhm[index]}
                          setImageUrl={setImageUrl}
                          values={{
                            result: {
                              city: item?.citiesCensusData,
                              cagedData:
                                item?.citiesCagedData && item?.citiesCagedData?.length > 0
                                  ? item?.citiesCagedData
                                  : null,
                              governmentTransparencyData:
                                item?.citiesGovernmentTransparencyData?.length > 0
                                  ? item.citiesGovernmentTransparencyData
                                  : null,
                            },
                          }}
                        />
                      </div>
                    )
                  })}
                </div>
                <div className='column-map'>
                  <div className='map'>
                    <LegalEntityGeoprocessingMapDemarcationReportCardComponent
                      title={area ? `Área de análise: ${area} km²` : 'Localização'}
                      subtitle={`Visualização prévia da ${
                        area ? 'área de análise' : 'localização'
                      }`}
                      feature={feature}
                      isMap={true}
                      values={{
                        result: {
                          legalEntityCount: values?.legalEntityCount[0].count,
                          legalEntityAttendanceCount: values?.attendances[0].count,
                          legalEntityMeiCount: Number(values?.mei[0].count),
                          legalEntitySizeCount: sizes,
                          averageCost: {
                            value: averageCostIndicator.cost,
                            legalEntityCount: averageCostIndicator.legalEntityCount,
                          },
                          revenue: {
                            value: revenueIndicator.revenue,
                            legalEntityCount: revenueIndicator.legalEntityCount,
                          },
                          currentYear,
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {/* For tests */}
        {/* <PDFViewer width={'100%'}>
          <LegalEntityGeoprocessingMapDemarcationReportPdfComponent
            values={{
              result: {
                legalEntityCount: values?.legalEntityCount[0].count,
                legalEntityAttendanceCount: values?.attendances[0].count,
                legalEntityMeiCount: Number(values?.mei[0].count),
                legalEntitySizeCount: sizes,
                averageCost: {
                  value: averageCostIndicator.cost,
                  legalEntityCount: averageCostIndicator.legalEntityCount,
                },
                revenue: {
                  value: revenueIndicator.revenue,
                  legalEntityCount: revenueIndicator.legalEntityCount,
                },
                currentYear,
                area,
                text,
                firstHeaderAreaContent,
                cnae: values?.cnae,
                segment: values?.segment,
                legalNature: values?.legalNature,
                indicators: {
                  values: anotherIndicators
                    ? anotherIndicators[0]?.sort((a, b) => (b?.count || 0) - (a?.count || 0))
                    : undefined,
                  legalEntityCount: values?.scores[0]?.legalEntityCount,
                },
                feature,
                citiesCensus: values?.citiesCensus,
                citiesGovernmentTransparency: values?.citiesGovernmentTransparency,
                imageUrl,
                selectedYearChart,
              },
            }}
          />
        </PDFViewer>  */}
      </Wrapper>
    </AppLayout>
  )
}
