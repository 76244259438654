import React from 'react'
import styled from 'styled-components'
import Link from '~/prix/react/components/link'

const NavBar = styled.nav`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: wrap;
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 85px;
  background-color: transparent;
  z-index: 3;

  min-height: 65px;

  @media (max-height: 485px) {
    display: none;
  }
`

const Menu = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding-right: 30px;

  @media (max-height: 768px) {
    backdrop-filter: blur(3px);
  }

  @media (max-width: 1100px) {
    backdrop-filter: blur(3px);
    padding-right: 10px;
  }
`

const MenuItem = styled.li`
  align-self: center;
`

const MenuLink = styled(Link)`
  text-decoration: none;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 500;
  display: inline;
  text-align: center;
  padding: 0.4rem 1rem;

  &:hover {
    text-decoration: none;
    color: #fff;
    border-bottom: 1px solid;
    border-bottom-color: #fff;
    cursor: pointer;
  }
`

export default function NavBarComponent() {
  return (
    <>
      <NavBar>
        <Menu>
          <MenuItem>
            <MenuLink href='https://prix.tech/#Contact' newTab>
              Contato
            </MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink href='https://prix.tech' newTab>
              Desenvolvedora
            </MenuLink>
          </MenuItem>
        </Menu>
      </NavBar>
    </>
  )
}
