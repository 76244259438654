import { entity, isNull, query, string, truthy, like, equals, number, between } from '~/prix'

export default function EntitiesRelationList({
  agentCpf,
  dataSourceId,
  startDate,
  endDate,
}: {
  agentCpf: string
  dataSourceId?: string
  startDate: string
  endDate: string
}) {
  return query('legalEntityAttendance')
    .select({
      cnpj: entity('legalEntity').property('cnpj'),
      tradeName: entity('legalEntity').property('tradeName'),
      corporateName: entity('legalEntity').property('corporateName'),
      openingDate: entity('legalEntity').property('openingDate'),
    })
    .join({
      current: entity('legalEntityAttendance').property('legalEntityId'),
      target: entity('legalEntity').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityAttendance').property('id'),
      target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
      join: 'inner',
    })
    .where(
      ...[
        isNull(entity('legalEntity').property('deletedAt')),
        like(entity('legalEntityAttendance').property('agentCpf'), string().value(agentCpf)),
        dataSourceId !== undefined
          ? equals(
              entity(`legalEntityAttendanceDataSource`).property(`dataSourceId`),
              number().value(Number(dataSourceId)),
            )
          : null,
        between(
          entity('legalEntityAttendance').property('createdAt'),
          string().value(startDate),
          string().value(endDate),
        ),
      ].filter(truthy),
    )
}
