import styled from 'styled-components'
import React, { useEffect } from 'react'
import useItems from '~/prix/react/hooks/items'
import EntitiesRelationList from './queries/entitiesRelationListQuery.query'
import { QueryBase } from '~/prix/query'
import { format } from 'date-fns'
import { Chart } from 'react-google-charts'
import { AttendantAndDataSourceProps } from './agentReportProductionChart.component'

const Wrapper = styled.div`
  padding: 8px 0;
  width: 100%;
  height: 100%;
`

const MessageWrapper = styled.div`
  display: flex;
  flex: 1;
  align-self: center;
  justify-content: center;
  height: 300px;

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .loader {
      display: flex;
      height: 50px;
      flex-direction: row;
      align-items: center;
    }

    .message {
      display: flex;
      height: 50px;
      flex-direction: row;
      font-size: 16px;
      text-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
    }

    span {
      font-size: 17px;
    }
  }
`

function isDuplicateCnpj(list: any[], cnpj: any) {
  return list.some(item => item.cnpj === cnpj)
}

function removeDuplicateCnpj(originalList: any[]) {
  const uniqueList: any[] = []

  originalList.forEach(item => {
    if (item && !isDuplicateCnpj(uniqueList, item.cnpj)) {
      uniqueList.push(item)
    }
  })

  return uniqueList
}

export default function AgentReportHistogram({
  agent,
  dataSource,
  setHistogramCount,
  startDate,
  endDate,
}: {
  agent: AttendantAndDataSourceProps
  dataSource?: AttendantAndDataSourceProps
  setHistogramCount: (value: number) => void
  startDate: string
  endDate: string
}) {
  const maskCnpj = (cnpj: string) => {
    const maskedCNPJ = cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
    return maskedCNPJ
  }

  const listEntities = useItems(
    () =>
      agent !== undefined || dataSource !== undefined
        ? (EntitiesRelationList({
            agentCpf: agent.id,
            dataSourceId: dataSource?.id,
            startDate: startDate,
            endDate: endDate,
          }) as QueryBase)
        : (null as never),
    [agent, dataSource, startDate, endDate],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: agent !== undefined || dataSource !== undefined,
    },
  )

  const listEntitiesFormatted = listEntities.items
    ? listEntities.items.map((item: any) => {
        if (item.openingDate === null) {
          return null
        }

        return {
          cnpj: item.cnpj,
          tradeName: item.tradeName,
          corporateName: item.corporateName,
          openingDate: format(new Date(item.openingDate), 'yyyy-MM-dd'),
        }
      })
    : []

  const listEntitiesFiltered = removeDuplicateCnpj(listEntitiesFormatted)

  const calculateAgeInYears = (openingDate: string | number | Date) => {
    const currentDate = new Date()
    const opening = new Date(openingDate)
    let years = currentDate.getFullYear() - opening.getFullYear()

    if (
      currentDate.getMonth() < opening.getMonth() ||
      (currentDate.getMonth() === opening.getMonth() && currentDate.getDate() < opening.getDate())
    ) {
      years -= 1
    }

    return years
  }

  const listEntitiesWithAge = listEntitiesFiltered!.map(item => {
    const ageInYears = calculateAgeInYears(item.openingDate)
    return {
      cnpj: item.cnpj,
      corporateName: item.corporateName,
      tradeName: item.tradeName,
      ageInYears: ageInYears,
    }
  })

  useEffect(() => {
    if (listEntitiesWithAge !== undefined) {
      const length = listEntitiesWithAge.length
      setHistogramCount(length)
    }
  }, [listEntitiesWithAge])

  const getMaxAgeInYears = (list: any[]) => {
    const maxAge = Math.max(...list.map(item => item.ageInYears))
    return Math.ceil(maxAge)
  }

  const generateDynamicBuckets = (maxAge: number) => {
    const buckets = []

    if (maxAge < 10) {
      maxAge = 10
    } else maxAge = maxAge + 2

    for (let i = 0; i <= maxAge; i++) {
      buckets.push(i)
    }
    return buckets
  }

  const HistogramChart = () => {
    const data = [['Empresa', 'Idade em Anos']]
    const maxAgeInYears = getMaxAgeInYears(listEntitiesWithAge)

    listEntitiesWithAge.forEach(item => {
      data.push([
        `
      ${item.tradeName ?? ''} 
      ${item.tradeName && item.corporateName ? ' - ' : ''} 
      ${item.corporateName ?? ''} 
      ${item.corporateName || (item.tradeName && item.cnpj) ? ' - ' : ''}
      ${item.cnpj ? `CNPJ: ${maskCnpj(item.cnpj)}` : ''}`,
        String(item.ageInYears),
      ])
    })

    const buckets = generateDynamicBuckets(maxAgeInYears)

    return (
      <>
        {data && data.length > 1 ? (
          <Chart
            chartType='Histogram'
            width='100%'
            height='400px'
            data={data}
            options={{
              title: 'Histograma de Idade das Empresas',
              hAxis: { title: 'Idade em Anos', minValue: 0, maxValue: maxAgeInYears },
              vAxis: { title: 'Número de Empresas' },
              histogram: { buckets: buckets },
              backgroundColor: '#fafafa',
            }}
          />
        ) : (
          <MessageWrapper>
            <div className='center'>
              <span>Sem dados para o agente selecionado</span>
            </div>
          </MessageWrapper>
        )}
      </>
    )
  }

  return <Wrapper>{HistogramChart()}</Wrapper>
}
