import {
  defineAction,
  object,
  string,
} from '~/prix'
import getNotifications from './getNotifications.action.json'

const getNotificationsDefinition = defineAction({
  ...getNotifications,
  input: object({
    userEmail: string(),
  }),
  output: object({
    id: string(),
    payload: object({
      title: string(),
      body: string(),
    }),
    url: string(),
    label: string(),
    createdAt: string(),
    readAt: string(),
  }),
})

export default getNotificationsDefinition

