import { defineEntity, entity, foreign, object, string, number } from '~/prix'
import { multiPolygon, point } from '~/prix/types/geoJson'

const microRegionEntity = defineEntity({
  key: 'microRegion',
  title: 'Microrregião',
  sources: ['olap', 'oltp'],
  schema: object({
    id: string().label('ID', 'Identificador', 'Código'),
    name: string().label('Nome', 'Microrregião'),
    ibgeCode: number().label('Código do IBGE'),
    center: point().label('Centro'),
    boundary: multiPolygon().label('Polígono'),
    lowerQualityBoundary: multiPolygon().label('Polígono de baixa qualidade'),
    mesoRegionId: foreign(entity('mesoRegion').property('id')),
  }),
})

export default microRegionEntity
