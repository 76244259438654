import React, { useLayoutEffect, useRef } from 'react'
import useScrollbarSize from 'react-scrollbar-size'
import styled from 'styled-components'
import { scrollContext } from '../scrollContext'
import LineChart, { LineChartProps } from './lineChart'
import { colors, institutionalColors } from '~/design'
import RightArrowIcon from '~/components/icons/arrows/16px_minimal-right.svg'
import LeftArrowIcon from '~/components/icons/arrows/16px_minimal-left.svg'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  position: relative;
  overflow-y: hidden;
  overflow-x: visible;
  
  .inner-arrow {
    svg {
      margin-top: 1px;
    }
  }
`

const PrevWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: sticky;
  left: 0;
  z-index: 1;
  padding-left: 8px;

  > button {
    border: 1px solid transparent;
    background: none;
    font-size: 13px;
    color: ${institutionalColors.graySebrae60};
    font-weight: 400;
    padding: 2px 6px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 0;

    svg {
     fill: #8B99A7;
    }

    :hover {
      svg {
        fill: ${colors.sebraeBlue};
      }
    }
  }
`

const ForwardWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: sticky;
  left: 95%;
  z-index: 1;

  > button {
    border: 1px solid transparent;
    background: none;
    font-size: 13px;
    color: ${institutionalColors.graySebrae60};
    font-weight: 700;
    padding: 2px 6px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 0;

    svg {
     fill: #8B99A7;
     margin: 0;
    }

    :hover {
      svg {
        fill: ${colors.sebraeBlue};
      }
    }
  }
`

interface IndexesWidthProps {
  startWidth: number
  endWidth: number
}

export interface LineChartWithScrollProps extends LineChartProps {
  initialScroll?: 'start' | 'end' | number
  initialScrollEndBased?: boolean
  eventIndexes?: IndexesWidthProps[] | undefined | null
  periodicity?: string | undefined | null
  lastDayOfData?: Date
}

function LineChartWithScroll({
  height = 190,
  initialScroll = 'end',
  initialScrollEndBased = false,
  eventIndexes,
  periodicity,
  lastDayOfData,
  ...props
}: LineChartWithScrollProps) {
  const ref = useRef<HTMLDivElement>(null)
  const { height: scrollbarHeight } = useScrollbarSize()

  useLayoutEffect(() => {
    if (!ref.current) {
      return
    }
    if (initialScroll === 'end') {
      ref.current.scrollLeft = ref.current.scrollWidth
    }
    if (initialScroll === 'start') {
      ref.current.scrollLeft = 0
    }
    if (typeof initialScroll === 'number') {
      ref.current.scrollLeft = initialScroll - (initialScrollEndBased ? ref.current.clientWidth : 0)
    }
  }, [ref, initialScroll])

  const handleNext = () => {
    const scrollValue = ref.current?.scrollLeft || 0
    if (eventIndexes && eventIndexes.length > 0) {
      let nearestIndex = 0
      let smallestDifference = Infinity
      if (eventIndexes[0].startWidth > scrollValue + 1.5) {
        nearestIndex = 0
      } else {
        for (let i = 0; i < eventIndexes.length; i++) {
          const { startWidth, endWidth } = eventIndexes[i]
          const difference = Math.min(Math.abs(scrollValue - startWidth), Math.abs(scrollValue - endWidth))
          if (difference === 0.5 || difference < smallestDifference) {
            smallestDifference = difference
            nearestIndex = i + 1
          }
        }
      }

      if (nearestIndex > eventIndexes.length - 1) {
        nearestIndex = eventIndexes.length - 1
      }

      ref.current?.scrollTo({
        left: eventIndexes[nearestIndex].startWidth,
        behavior: 'smooth',
      })
    } else {
      ref.current?.scrollTo({
        left: ref.current!.scrollWidth,
        behavior: 'smooth',
      })
    }
  }

  const handlePrev = () => {
    const scrollValue = ref.current?.scrollLeft || 0
    if (eventIndexes && eventIndexes.length > 0) {
      let nearestIndex = 0
      let smallestDifference = Infinity

      if (eventIndexes[eventIndexes.length - 1].endWidth < scrollValue) {
        nearestIndex = eventIndexes.length - 1
      } else {
        for (let i = 0; i < eventIndexes.length; i++) {
          const { startWidth, endWidth } = eventIndexes[i]
          const difference = Math.min(Math.abs(scrollValue - startWidth), Math.abs(scrollValue - endWidth))

          if (difference < smallestDifference) {
            smallestDifference = difference
            nearestIndex = i - 1
          }
        }
      }

      ref.current?.scrollTo({
        left: eventIndexes[nearestIndex]?.startWidth || 0,
        behavior: 'smooth',
      })
    } else {
      ref.current?.scrollTo({
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  return (
    <Wrapper
      ref={ref}
      style={{
        height,
      }}
    >
      {eventIndexes &&
        <PrevWrapper>
          <button
            onClick={() => handlePrev()}
            className='inner-arrow'>
            <LeftArrowIcon width={16} height={16} />
          </button>
        </PrevWrapper>
      }

      <scrollContext.Provider value={ref}>
        <LineChart {...props} periodicity={periodicity} height={height - scrollbarHeight} lastDayOfData={lastDayOfData} />
      </scrollContext.Provider>

      {eventIndexes &&
        <ForwardWrapper>
          <button
            onClick={() => handleNext()}
            className='inner-arrow'>
            <RightArrowIcon width={16} height={16} />
          </button>
        </ForwardWrapper>
      }
    </Wrapper>
  )
}

export default React.memo(LineChartWithScroll)
