import { useMemo } from 'react'
import { truthy } from '~/prix'
import { QueryBase } from '~/prix/query'
import useItems from '~/prix/react/hooks/items'
import { Level, Breadcrumb } from './legalEntityGeoprocessingMapLevels.data'
import breadcrumbQuery from './levels/breadcrumb.query'

export default function useBreadcrumbs({ geo, id, roles }: Level) {
  const isStartLevel = geo === 'country' && !id
  const temporaryCountryId = '30'

  const breadcrumbResult = useItems(() => breadcrumbQuery(geo, id!) as QueryBase, [geo, id], {
    cache: 60 * 60 * 24 * 7,
    autoLoad: !isStartLevel,
  })
  const breadcrumbs = useMemo(() => {
    if (isStartLevel) {
      return [
        {
          geo: 'country',
          id: temporaryCountryId,
          title: 'Brasil',
          subTitle: 'País',
        } as Breadcrumb,
      ]
    }
    if (breadcrumbResult.error) {
      console.error(breadcrumbResult.error)
    }

    return breadcrumbResult && breadcrumbResult.items
      ? ([
          roles === false
            ? {
                geo: 'country',
                id: temporaryCountryId,
                title: 'Brasil',
                subTitle: 'País',
              }
            : null,
          breadcrumbResult.items[0].macroRegionId && roles === false
            ? {
                geo: 'macroRegion',
                id: breadcrumbResult.items[0].macroRegionId,
                title: breadcrumbResult.items[0].macroRegionName,
                subTitle: 'Macrorregião',
              }
            : null,
          breadcrumbResult.items[0].stateId
            ? {
                geo: 'state',
                id: breadcrumbResult.items[0].stateId,
                title: breadcrumbResult.items[0].stateName,
                subTitle: 'Estado',
              }
            : null,
          breadcrumbResult.items[0].mesoRegionId
            ? {
                geo: 'mesoRegion',
                id: breadcrumbResult.items[0].mesoRegionId,
                title: breadcrumbResult.items[0].mesoRegionName,
                subTitle: 'Mesorregião',
              }
            : null,
          breadcrumbResult.items[0].microRegionId
            ? {
                geo: 'microRegion',
                id: breadcrumbResult.items[0].microRegionId,
                title: breadcrumbResult.items[0].microRegionName,
                subTitle: 'Microrregião',
              }
            : null,
          breadcrumbResult.items[0].cityId
            ? {
                geo: 'city',
                id: breadcrumbResult.items[0].cityId,
                title: breadcrumbResult.items[0].cityName,
                subTitle: 'Cidade',
              }
            : null,
          breadcrumbResult.items[0].neighborhoodId
            ? {
                geo: 'neighborhood',
                id: breadcrumbResult.items[0].neighborhoodId,
                title: breadcrumbResult.items[0].neighborhoodName,
                subTitle: 'Bairro',
              }
            : null,
          breadcrumbResult.items[0].streetId
            ? {
                geo: 'street',
                id: breadcrumbResult.items[0].streetId,
                title:
                  breadcrumbResult.items[0].streetName === null
                    ? 'Sem nome'
                    : breadcrumbResult.items[0].streetName,
                subTitle: 'Rua',
              }
            : null,
        ].filter(truthy) as Breadcrumb[])
      : null
  }, [breadcrumbResult, isStartLevel])

  return {
    items: breadcrumbs,
    isLoading: breadcrumbResult.isLoading,
    error: breadcrumbResult.error,
  }
}
