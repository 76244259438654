import createType, { AbstractType, TypeMeta } from './type'

export interface StringMeta {
  mask?: (string | RegExp)[]
  maxLength?: number
  inputType?: 'text' | 'password'
  [key: string]: unknown
}

export interface StringType<NativeGeneric extends string | null = string>
  extends AbstractType<NativeGeneric> {
  readonly type: 'string'
  readonly currentValue?: NativeGeneric
  readonly currentMeta: TypeMeta<NativeGeneric> & StringMeta
  meta(metaPatch: TypeMeta<NativeGeneric> & StringMeta): AbstractType<NativeGeneric>
  isRequired(): StringType<Exclude<NativeGeneric, null>>
  isOptional(): StringType<NativeGeneric | null>
}

export default function string(): StringType<string> {
  return {
    ...createType<StringType<string>>(),
    type: 'string',
  }
}
