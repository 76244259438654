import React from 'react'
import { AttendantAndDataSourceProps } from './agentReportProductionChart.component'
import useItems from '~/prix/react/hooks/items'
import AgentSegmentListQuery from './queries/agentSegmentList.query'
import List from '~/components/list'
import styled from 'styled-components'

const MessageWrapper = styled.div`
  display: flex;
  flex: 1;
  align-self: center;
  justify-content: center;
  height: 300px;

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .loader {
      display: flex;
      height: 50px;
      flex-direction: row;
      align-items: center;
    }

    .message {
      display: flex;
      height: 50px;
      flex-direction: row;
      font-size: 16px;
      text-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
    }

    span {
      font-size: 17px;
    }
  }
`

export default function AgentReportSegmentList({
  agent,
  dataSource,
  startDate,
  endDate,
}: {
  agent: AttendantAndDataSourceProps
  dataSource?: AttendantAndDataSourceProps
  startDate: string
  endDate: string
}) {
  const listSegment = useItems(
    () =>
      agent !== undefined || dataSource !== undefined
        ? (AgentSegmentListQuery({
            agentCpf: agent.id,
            dataSourceId: dataSource?.id,
            startDate: startDate,
            endDate: endDate,
          }) as any)
        : (null as never),
    [agent, dataSource, startDate, endDate],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: agent !== undefined || dataSource !== undefined,
    },
  )

  return listSegment ? (
    <List
      title='Classificação de Segmentos'
      items={listSegment.items}
      isLoading={listSegment.isLoading}
      labels={{
        count: 'Quantidade',
        segmentDescription: 'Segmento',
      }}
      customStyles={{
        width: '100%',
        boxShadow: '0px 32px 30px 0px rgba(20, 46, 82, 0.20)',
      }}
      fractions={{
        count: 1 / 2,
        segmentDescription: 2 / 3,
      }}
    />
  ) : (
    <MessageWrapper>
      <div className='center'>
        <span>Carregando dados...</span>
      </div>
    </MessageWrapper>
  )
}
