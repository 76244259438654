export interface Order<SelectedKey extends string> {
  readonly kind: 'order'
  readonly mode: 'ascending' | 'descending'
  readonly selectedKey: SelectedKey
}

export type OrderBase = Order<string>

export function ascending<SelectedKey extends string>(selectedKey: SelectedKey) {
  return {
    kind: 'order',
    mode: 'ascending',
    selectedKey,
  } as Order<SelectedKey>
}

export function descending<SelectedKey extends string>(selectedKey: SelectedKey) {
  return {
    kind: 'order',
    mode: 'descending',
    selectedKey,
  } as Order<SelectedKey>
}
