import { defineEntity, object, string } from '~/prix'

const cnaeEntity = defineEntity({
  key: 'cnae',
  title: 'CNAE',
  sources: ['olap', 'oltp'],
  entitySchema: 'public',
  schema: object({
    id: string().label('ID'),
    description: string().label('Descrição'),
  }),
})

export default cnaeEntity
