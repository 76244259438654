import {
  defineAction,
  object,
} from '~/prix'
import sendTimeSeriesNotification from './sendTimeSeriesNotification.action.json'

const sendTimeSeriesNotificationDefinition = defineAction({
  ...sendTimeSeriesNotification,
  input: object({}),
  output: object({}),
})

export default sendTimeSeriesNotificationDefinition

