import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Marker, Polygon } from '@react-google-maps/api'
import * as turf from '@turf/helpers'
import memoize from "fast-memoize"
import { colors } from '~/design'
import useGoogleMaps from '~/prix/react/hooks/googleMaps'

export type DemarcationPoint = google.maps.LatLngLiteral & {
  id: string
}
export type GhostDemarcationPoint = DemarcationPoint & {
  previousId: string
}

let currentMarkerId = 0

export function usePolygonEditor(initialPolygon: turf.Feature<turf.Polygon> | null = null) {
  const [points, setPoints] = useState<Array<DemarcationPoint>>(() => {
    if (!initialPolygon) {
      return []
    }
    return initialPolygon.geometry.coordinates[0]
      .filter((_, index, list) => index < list.length - 1)
      .map((point, index) => ({
        id: String(index),
        lat: point[1],
        lng: point[0],
      }))
  })
  const [isDirty, setIsDirty] = useState(false)
  const [activeGhostPoint, setActiveGhostPoint] = useState<GhostDemarcationPoint | null>(null)

  const polygonPoints = useMemo(() => {
    // Points with active ghost point
    if (activeGhostPoint) {
      const index = points.findIndex((p) => p.id === activeGhostPoint.previousId)
      const newPoints = Array.from(points)
      newPoints.splice(index + 1, 0, activeGhostPoint)
      return newPoints
    }
    return points
  }, [points, activeGhostPoint])

  const ghostPolygonPoints = useMemo(() => {
    if (points.length < 2) {
      return []
    }
    return points.map((point, index, list) => {
      const nextIndex = index < list.length - 1 ? index + 1 : 0
      const nextPoint = list[nextIndex]
      const id = `${point.id}-${nextPoint.id}`
      const lng = (point.lng + nextPoint.lng) / 2
      const lat = (point.lat + nextPoint.lat) / 2

      return {
        id,
        lat,
        lng,
        previousId: point.id,
      } as GhostDemarcationPoint
    })
  }, [points])

  const handleReset = useCallback(() => {
    setIsDirty(false)
    if (!initialPolygon) {
      setPoints([])
      return
    }
    setPoints(initialPolygon.geometry.coordinates[0]
      .filter((_, index, list) => index < list.length - 1)
      .map((point, index) => ({
        id: String(index),
        lat: point[1],
        lng: point[0],
      })))
  }, [initialPolygon])

  useEffect(() => {
    handleReset()
  }, [handleReset])

  const polygon = useMemo(() => {
    if (!isDirty) {
      return initialPolygon
    }
    if (polygonPoints.length < 3) {
      return null
    }
    const coordinates = [
      ...polygonPoints.map((point) => [point.lng, point.lat]),
      [polygonPoints[0].lng, polygonPoints[0].lat],
    ]
    return turf.polygon([coordinates])
  }, [polygonPoints, isDirty, initialPolygon])

  const handleMapClick = useCallback((event: google.maps.MapMouseEvent) => {
    const { latLng } = event
    if (!latLng) {
      return
    }

    setPoints((points) => [...points, {
      id: String(currentMarkerId++),
      ...latLng.toJSON()
    }])
    setIsDirty(true)
  }, [])

  const handleMarkerDrag = useCallback(memoize((point: DemarcationPoint, index: number) => (event: google.maps.MapMouseEvent) => {
    const { latLng } = event
    if (!latLng) {
      return
    }
    setPoints((points) => {
      const newPoints = Array.from(points)
      newPoints[index] = {
        ...point,
        ...latLng.toJSON()
      }
      return newPoints
    })
    setIsDirty(true)
  }), [])

  const handleGhostMarkerDrag = useCallback(memoize((point: GhostDemarcationPoint) => (event: google.maps.MapMouseEvent) => {
    const { latLng } = event
    if (!latLng) {
      return
    }
    setActiveGhostPoint({
      ...point,
      ...latLng.toJSON()
    })
  }), [])

  const handleGhostMarkerDragEnd = useCallback(memoize((point: GhostDemarcationPoint) => (event: google.maps.MapMouseEvent) => {
    const { latLng } = event

    // Include ghost point in polygon
    setActiveGhostPoint(null)
    setPoints((points) => {
      const newPoints = Array.from(points)
      const index = points.findIndex((p) => p.id === point.previousId)
      if (index === -1) {
        return points
      }
      newPoints.splice(index + 1, 0, {
        ...point,
        ...(latLng ? latLng.toJSON() : {})
      })
      return newPoints
    })
    setIsDirty(true)
  }), [])


  return {
    points,
    polygon,
    polygonPoints,
    ghostPolygonPoints,
    onMapClick: handleMapClick,
    onReset: handleReset,
    onMarkerDrag: handleMarkerDrag,
    onGhostMarkerDrag: handleGhostMarkerDrag,
    onGhostMarkerDragEnd: handleGhostMarkerDragEnd,
  }
}

export default function PolygonEditor({
  points,
  polygonPoints,
  ghostPolygonPoints,
  onMarkerDrag,
  onGhostMarkerDrag,
  onGhostMarkerDragEnd,
  onMapClick,
  markerStyle,
  ghostMarkerStyle,
  polygonOptions,
}: {
  points: Array<DemarcationPoint>
  polygonPoints: Array<DemarcationPoint>
  ghostPolygonPoints: Array<GhostDemarcationPoint>
  onMarkerDrag: (point: DemarcationPoint, index: number) => (event: google.maps.MapMouseEvent) => void
  onGhostMarkerDrag: (point: GhostDemarcationPoint) => (event: google.maps.MapMouseEvent) => void
  onGhostMarkerDragEnd: (point: GhostDemarcationPoint) => (event: google.maps.MapMouseEvent) => void
  onMapClick: (event: google.maps.MapMouseEvent) => void
  markerStyle?: google.maps.Symbol | google.maps.Icon
  ghostMarkerStyle?: google.maps.Symbol | google.maps.Icon
  polygonOptions?: google.maps.PolygonOptions
}) {
  const { google } = useGoogleMaps()
  const { markerStyle: finalMarkerStyle, ghostMarkerStyle: finalGhostMarkerStyle, polygonOptions: finalPolygonOptions
  } = useMemo(() => {
    if (!google) {
      return {
        markerStyle: undefined,
        ghostMarkerStyle: undefined,
      }
    }

    const suggestionMarkerStyle = {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 6,
      fillColor: colors.sebraeBlue,
      fillOpacity: 1,
      strokeColor: colors.gray,
      strokeWeight: 2,
    }
    const suggestionGhostMarkerStyle = {
      ...suggestionMarkerStyle,
      scale: 4,
      strokeColor: colors.sebraeBlue,
    }
    const suggestionPolygonOptions = {
      fillColor: colors.sebraeBlue,
      fillOpacity: 0.2,
      strokeColor: colors.sebraeBlue,
      strokeOpacity: 1,
      strokeWeight: 2,
    }
    return {
      markerStyle: markerStyle || suggestionMarkerStyle,
      ghostMarkerStyle: ghostMarkerStyle || suggestionGhostMarkerStyle,
      polygonOptions: polygonOptions || suggestionPolygonOptions,
    }
  }, [google, markerStyle, ghostMarkerStyle, polygonOptions])

  if (!google) {
    return null
  }

  return (
    <>
      {polygonPoints.length >= 1 ? (
        <Polygon
          paths={polygonPoints}
          options={finalPolygonOptions}
          onClick={onMapClick}
        />
      ) : null}
      {points.map((point, index) => (
        <Marker
          key={index}
          position={point}
          icon={finalMarkerStyle}
          draggable
          onDrag={onMarkerDrag(point, index)}
        />
      ))}
      {ghostPolygonPoints.map((point, index) => (
        <Marker
          key={index}
          position={point}
          icon={finalGhostMarkerStyle}
          draggable
          onDrag={onGhostMarkerDrag(point)}
          onDragEnd={onGhostMarkerDragEnd(point)}
        />
      ))}
    </>
  )
}