import React, { useCallback } from 'react'
import styled from 'styled-components'
import { colors } from '~/design'
import Link from '~/prix/react/components/link'
import UserIcon from '~/components/icons/users/48px_circle-08.svg'
import { version } from '~/utils/client/publicConfig'
import keycloakConfig from '~/utils/shared/keycloakConfig'
import { useKeycloak } from '~/components/keycloak'
import useAPI from '~/prix/react/hooks/api'
import Menu from '~/prix/react/components/menu/menu'
import { useNavigate } from 'react-router'
import useActionFunction from '~/prix/react/hooks/actionFunction'
import deregisterDeviceDefinition from '../notifications/deregisterDevice/deregisterDevice.definition'
import useNotificationContext from '../notifications/notificationContext'

interface IOptionsMenuProps {
  menuActive?: string
}

export const Version = styled.div`
  padding-top: 25px;
  padding-bottom: 10px;
  text-align: center;
  justify-self: flex-end;
`

export const Aside = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 32px 30px 0px rgba(20, 46, 82, 0.20);
  border: solid 1px rgba(221, 221, 221, 1);
  border-radius: 12px;
  margin: 0 16px;

  .header-card {
    font-size: 16px;
    border-radius: 8px 8px 0px 0px;
    background: var(--p-action, #0F438A);
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 15px 5px 15px 15px;

    svg {
      min-height: 48px;
      min-width: 48px;
      max-height: 48px;
      max-width: 48px;
    }
    
    .header-user-info {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      line-height: 22px;
      color: #fff;
      font-size: 19px;
      padding-top: 3px;

      overflow: hidden;

      strong,
      small {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  ul {
    margin-top: 10px;
    padding: 0;
    flex-direction: column;
    width: 100%;
    list-style-type: none;

    li {
      text-decoration: none;
      line-height: 1.25;

      a {
        display: block;
        border-radius: 2px;
        padding: 0.5rem 0.75rem;
        color: #394D60;
        font-size: 16px;
        

        &:hover {
          background-color: rgb(245, 245, 245);
        }

        &.is-active {
          display: flex;
          align-self: center;
          color: #fff;
          background-color: ${colors.sebraeBlue};
        }
      }
    }
  }
`

export default function OptionsMenu({ menuActive }: IOptionsMenuProps) {
  const { context } = useAPI()
  const { logout } = useKeycloak()
  const navigate = useNavigate()
  const userEmail = context.user?.email
  const { callAction: callActionDeregisterToken } = useActionFunction(deregisterDeviceDefinition)
  const { userToken } = useNotificationContext()

  const handleReload = useCallback(async (event: React.MouseEvent) => {
    event.preventDefault()
    if (window.caches) {
      try {
        const keyList = await window.caches.keys()
        await Promise.all(keyList.map(key => window.caches.delete(key)))
      } catch (error) {
        console.error(error)
      }
    }

    try {
      if ('serviceWorker' in navigator) {
        const registrations = await navigator.serviceWorker.getRegistrations()
        for (const registration of registrations) {
          await registration.unregister()
        }
      }
    } catch (error) {
      console.error(error)
    }
    window.document.location.href = `/index.html?refresh=${Math.random()}`
  }, [])

  const handleLogout = useCallback(
    async (event: React.MouseEvent) => {
      if (userEmail && userToken) {
        callActionDeregisterToken({ userEmail, userToken })
      }
      event.preventDefault()
      navigate('/')
      await logout().catch((error: Error) => console.error(error))
    },
    [logout, userEmail, userToken],
  )

  return (
    <Menu style={{ justifyContent: 'center' }}>
      <Aside>
        <div className='header-card'>
          <UserIcon height={48} width={48} fill='#fff' />
          <div className='header-user-info'>
            <strong>{context.user?.name}</strong>
            <small>{context.user?.email}</small>
          </div>
        </div>
        <ul>
          <li>
            <Link
              href='/app/options/notifications'
              className={menuActive === 'notifications' ? 'is-active' : undefined}
            >
              Notificações
            </Link>
          </li>
          <li>
            <Link
              href='/app/options/system-and-information'
              className={menuActive === 'system-and-information' ? 'is-active' : undefined}
            >
              Sistema e informações
            </Link>
          </li>
          <li>
            <a href={`/index.html?refresh=${Math.random()}`} onClick={handleReload}>
              Atualizar
            </a>
          </li>
          <li>
            <Link
              href={`${keycloakConfig.url}/realms/${keycloakConfig.realm}/account`}
              remote
              newTab
            >
              Conta de usuário
            </Link>
          </li>
          <li>
            <Link
              href="/terms"
              remote
              newTab
            >
              Termos de uso
            </Link>
          </li>
          <li>
            <Link onClick={handleLogout}>Sair</Link>
          </li>
        </ul>
      </Aside>
      <div style={{ flex: 1 }} />
      <Version>
        <span>Versão {version}</span>
      </Version>
    </Menu>
  )
}
