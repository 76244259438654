import React, { useMemo } from 'react'
import Handle from '~/prix/react/components/handle'
import { formatAsBrIntegerNumber, formatAsPercentage } from '~/prix/utils/types/number'
import useItems from '~/prix/react/hooks/items'
import mostUsedCitiesQuery from '../queries/mostUsedCities.query'
import { ColumnData, Header, Items, ListRow } from './dashboardGridItemMostUsedStateList.component'

interface DashboardGridItemMostUsedCitiesListProps {
  id: number,
  name: string,
  total_legal_entity?: number,
  total_attendance?: number,
  percentage?: number,
}

export default function DashboardGridItemMostUsedCityList({
  userGeoStateAbbreviation,
  dataSourceId,
  stateId,
}: {
  userGeoStateAbbreviation?: string | null
  dataSourceId?: number
  stateId?: string
}) {

  const listQuery = useItems(
    () => mostUsedCitiesQuery(
      {
        stateAbbreviation: userGeoStateAbbreviation ? userGeoStateAbbreviation : undefined,
        dataSourceId: Number(dataSourceId),
      }
    ),
    [userGeoStateAbbreviation, dataSourceId],
    { cache: 60 * 60 * 24 },
  )

  const allCitiesData = listQuery.items?.map((city) => ({
    ...city,
    difference: city.totalAttendances / city.totalLegalEntity,
  }))

  const cityDataOrdered = allCitiesData?.sort(
    (a, b) => b.difference - a.difference
  )

  const cityData = cityDataOrdered?.slice(0, 5)

  const definedOption = useMemo(() => {
    if (dataSourceId === 2) {
      return 'legalEntityAttendanceInYourCompanyIndex'
    }

    if (dataSourceId === 3) {
      return 'legalEntityAttendanceRadarAliIndex'
    }

    if (dataSourceId === 5) {
      return 'legalEntityAttendanceAllCoursesEadIndexQuery'
    }

    if (dataSourceId === 6) {
      return 'legalEntityAttendanceAppSebraeIndex'
    }
  }, [dataSourceId])

  const ListItem = ({ name, total_attendance, total_legal_entity, percentage }: DashboardGridItemMostUsedCitiesListProps) => (
    <ListRow>
      <ColumnData style={{ width: '27.5%' }}>{name}</ColumnData>
      <ColumnData style={{ width: '25%', textAlign: 'center' }}>{formatAsBrIntegerNumber(Number(total_attendance))}</ColumnData>
      <ColumnData style={{ width: '25%', textAlign: 'center' }}>{formatAsBrIntegerNumber(Number(total_legal_entity))}</ColumnData>
      <ColumnData style={{ width: '22.5%', textAlign: 'center' }}>{formatAsPercentage(Number(percentage))}%</ColumnData>
    </ListRow>
  )

  const currentData = cityData?.map((item: any) => ({
    name: item.geoName,
    total_attendance: item.totalAttendances,
    total_legal_entity: item.totalLegalEntity,
    percentage: item.difference,
  }))

  return (
    <Handle isLoading={listQuery.isLoading} error={listQuery.error} style={{ minHeight: '100px' }}>
      <Items>
        <ListRow>
          <Header style={{ width: '27.5%' }}><span>Estado</span></Header>
          <Header style={{ width: '25%', justifyContent: 'center', textAlign: 'center' }}><span>Empresas atendidas</span></Header>
          <Header style={{ width: '25%', justifyContent: 'center', textAlign: 'center' }}><span>Empresas geocodificadas</span></Header>
          <Header style={{ width: '22.5%', justifyContent: 'center', textAlign: 'center' }}><span>Porcentagem</span></Header>
        </ListRow>
        {currentData?.map((item, index) => (
          <ListItem key={index} {...item} />
        ))}
        <div className='bottom'>
          <a href={`/app/map/state/${stateId}?definedOption=${definedOption}`}>Ver mais</a>
        </div>
      </Items>
    </Handle>
  )
}