import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { DefinedOption, sourceAux } from './legalEntityGeoprocessingMapMenu.data'
import { institutionalColors } from '~/design'
import UpIcon from '~/components/icons/arrows/16px_minimal-up.svg'
import DownIcon from '~/components/icons/arrows/16px_minimal-down.svg'

export const PresetWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 20px;

  .containers {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 16px 8px;
  }
`

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  color: ${institutionalColors.graySebrae30};
  background: #fff;

  padding: 16px 12px;
  margin: 0 2px;
  border: solid 1px rgba(221, 221, 221, 1);
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0px 32px 30px 0px rgba(20, 46, 82, 0.2);
  overflow: hidden;

  .submenu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;

    svg {
      margin-left: 12px;
      fill: ${institutionalColors.graySebrae30};
    }

    h3 {
      margin-left: 8px;
    }

    .border {
      border-left: 2px solid transparent;
      border-radius: 12px;
      width: 4px;
      height: 40px;
    }

    &:hover {
      color: #0f438a;

      .border {
        border: 2px solid #0f438a;
      }

      svg {
        fill: #0f438a;
      }
    }
  }

  .active {
    color: #0f438a;

    .border {
      border: 2px solid #0f438a;
    }

    svg {
      fill: #0f438a;
    }
  }
`

export const PresetButton = styled.button`
  display: flex;
  text-decoration: none;
  text-align: left;
  width: 95%;
  padding: 12px 18px 12px 24px;
  border-radius: 4px;
  border: none;

  background-color: #fff;
  color: #617385;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    color: #005eb8;
  }
`

export const SectionTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
  background: #0f438a;
  height: 52px;
  padding: 0 12px;
  margin: 0 2px;

  span {
    flex: 1;
    color: #fff;
    text-align: center;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    font-size: 19px;
  }

  svg {
    fill: #fff;
  }
`

const DropdownContent = ({ body, handleStaticOption }: any) => {
  return body.map((item: DefinedOption) => {
    return (
      <PresetButton key={`${item.title}`} onClick={() => handleStaticOption(item.default)}>
        <div>
          <span>{item.positionSubmenu ? sourceAux[item.positionSubmenu] : item.title}</span>
        </div>
      </PresetButton>
    )
  })
}

function MapMenuItem({ body, onChangeFilter, handleOptionMapMenu, cardTitle }: any) {
  const [selectedSubmenus, setSelectedSubmenus] = useState<string[]>([])
  const sectionContainerRef = useRef<HTMLDivElement>(null)

  const handleStaticOption = useCallback(
    (type: string) => {
      handleOptionMapMenu(type)
    },
    [onChangeFilter],
  )

  const toggleSubmenu = (menu: string) => {
    if (selectedSubmenus.includes(menu)) {
      setSelectedSubmenus(selectedSubmenus.filter(item => item !== menu))
      sectionContainerRef!.current!.style.transitionDuration = '0s'
    } else {
      setSelectedSubmenus([...selectedSubmenus, menu])
      sectionContainerRef!.current!.style.transitionDuration = '0.3s'
    }
  }

  useEffect(() => {
    if (sectionContainerRef.current) {
      sectionContainerRef.current.style.maxHeight = selectedSubmenus
        ? `${sectionContainerRef.current.scrollHeight}px`
        : '0'
    }
  }, [selectedSubmenus])

  const groupedItems = body.reduce((acc: any, item: DefinedOption) => {
    if (item.menu === undefined) {
      acc['Geral'] = acc['Geral'] || []
      acc['Geral'].push(item)
    } else {
      acc[item.menu] = acc[item.menu] || []
      acc[item.menu].push(item)
    }
    return acc
  }, {})

  return (
    <PresetWrapper>
      <div className='containers'>
        <SectionTitle>
          <span>{cardTitle}</span>
        </SectionTitle>
        <SectionContainer ref={sectionContainerRef}>
          {Object.entries(groupedItems).map(([menu, items]) => (
            <div style={{ width: '100%' }} key={menu}>
              <div
                className={selectedSubmenus.includes(menu) ? 'submenu active' : 'submenu'}
                onClick={() => toggleSubmenu(menu)}
              >
                <div className='border' />
                <h3>{menu}</h3>
                {selectedSubmenus.includes(menu) ? (
                  <UpIcon width={10} height={10} />
                ) : (
                  <DownIcon width={10} height={10} />
                )}
              </div>
              {selectedSubmenus.includes(menu) && (
                <DropdownContent body={items} handleStaticOption={handleStaticOption} />
              )}
            </div>
          ))}
        </SectionContainer>
      </div>
    </PresetWrapper>
  )
}

export default MapMenuItem
