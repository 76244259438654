import { defineAction, object } from '~/prix'
import fetchLegalEntityIndicatorsAction from './fetchLegalEntityIndicators.action.json'

const fetchLegalEntityIndicatorsDefinition = defineAction({
  ...fetchLegalEntityIndicatorsAction,
  input: object({}),
  output: object({}),
})

export default fetchLegalEntityIndicatorsDefinition
