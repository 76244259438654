import { defineEntity, entity, foreign, number, object, string } from '~/prix'

const cnaeSegmentEntity = defineEntity({
  key: 'cnaeSegment',
  title: 'Tabela com id de CNAES e Segmentos',
  sources: ['olap', 'oltp'],
  schema: object({
    id: string().label('ID', 'Identificador', 'Código'),
    cnaeId: foreign(entity('cnae').property('id')),
    segmentId: foreign(entity('segment').property('id')),
  }),
})

export default cnaeSegmentEntity
