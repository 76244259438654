import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export type ViewMode = 'normal' | 'embed'

export function useViewMode() {
  const [viewMode, setViewMode] = useState<ViewMode>(() => {
    const viewMode = sessionStorage.getItem('viewMode') || 'normal'
    return viewMode as ViewMode
  })

  useEffect(() => {
    const listener = (event: StorageEvent) => {
      if (event.key === 'viewMode') {
        setViewMode(event.newValue as ViewMode)
      }
    }
    window.addEventListener('storage', listener)
    return () => window.removeEventListener('storage', listener)
  }, [])

  return viewMode
}

export function useAutoSetViewMode() {
  const [searchParams] = useSearchParams()
  const currentViewMode = useViewMode()
  const set = (newViewMode: ViewMode) => {
    sessionStorage.setItem('viewMode', newViewMode)
  }

  const viewMode = searchParams.get('viewMode') || searchParams.get('viewmode')
  useEffect(() => {
    if (viewMode === 'embed') {
      set('embed')
    } else if (viewMode === 'normal') {
      set('normal')
    }
  }, [viewMode])

  return (viewMode || currentViewMode) as ViewMode
}
