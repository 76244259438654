import React from 'react'
import { colors } from '~/design'
import styled from 'styled-components'
import OriginalLink from '~/prix/react/components/link'
import { formatAsBrDecimalNumber, AppError } from '~/prix'
import Handle from '../../../prix/react/components/handle'

interface Item {
  legalEntityId: number
  corporateName: string
  distance: number
  pointOnStreet: { type: string; coordinates: Array<number> }
}

interface AttendanceRadar {
  withinRadius: Array<Item>
  sameRoad: Array<Item>
  sameNeighborhood: Array<Item>
  error?: AppError | undefined | null
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
`

const EntitiesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 32px 30px 0px rgba(20, 46, 82, 0.20);
  border: solid 1px rgba(221, 221, 221, 1);
  
  padding: 15px;
  border-radius: 0 0 4px 4px;
  margin: 0 1px; 
`

const TitleContent = styled.div`
  border-radius: 8px 8px 0px 0px;
  background: var(--p-action, #0F438A);
  display: flex;
  height: 42px;
  flex-direction: column;
  justify-content: center;
  margin: 0 2px;

  span {
    color: #FFF;
    text-align: center;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    font-size: 19px;
  }
`

const Link = styled(OriginalLink)`
  display: flex;
  flex-shrink: 1;
  width: 100%;

  &:hover {
    color: rgb(0, 0, 0);
    background-color: rgb(235, 235, 235);
  }

  span {
    color: #363636;
    font-size: 14px;
    line-height: 1.3;
    display: block;
  }
`

const Items = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .item {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 2px 10px 2px 10px;

    .corporate-name {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow-y: hidden;
      width: 78%;
    }

    .distance {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22%;
    }
  }
`

export default function AttendanceRadarMapMenu({
  withinRadius,
  sameRoad,
  sameNeighborhood,
  error,
}: AttendanceRadar) {
  return [...sameRoad, ...sameNeighborhood, ...withinRadius].length > 0 ? (
    <>
      <Handle error={error}>
        {withinRadius.length > 0 ? (
          <Container>
            <TitleContent>
              <span>Mais próximas:</span>
            </TitleContent>
            <EntitiesWrapper>
              <Items>
                {withinRadius.map((item: Item) => (
                  <Link key={item.legalEntityId} href={`/app/map/legalEntity/${item.legalEntityId}`}>
                    <div className='item'>
                      <span className='corporate-name'>{item.corporateName}</span>
                      <span className='distance'>
                        (à {formatAsBrDecimalNumber(Math.round(item.distance))}m)
                      </span>
                    </div>
                  </Link>
                ))}
              </Items>
            </EntitiesWrapper>
          </Container>
        ) : null}

        <div />

        {sameRoad.length > 0 ? (
          <Container>
            <TitleContent>
              <span>Na mesma rua:</span>
            </TitleContent>
            <EntitiesWrapper>
              <Items>
                {sameRoad.map((item: Item) => (
                  <Link key={item.legalEntityId} href={`/app/map/legalEntity/${item.legalEntityId}`}>
                    <div className='item'>
                      <span className='corporate-name'>{item.corporateName}</span>
                      <span className='distance'>
                        (à {formatAsBrDecimalNumber(Math.round(item.distance))}m)
                      </span>
                    </div>
                  </Link>
                ))}
              </Items>
            </EntitiesWrapper>
          </Container>
        ) : null}
      </Handle>

      <div />

      {sameNeighborhood.length > 0 ? (
        <Container>
          <TitleContent>
            <span>No mesmo bairro:</span>
          </TitleContent>
          <EntitiesWrapper>
            <Items>
              {sameNeighborhood.map((item: Item) => (
                <Link key={item.legalEntityId} href={`/app/map/legalEntity/${item.legalEntityId}`}>
                  <div className='item'>
                    <span className='corporate-name'>{item.corporateName}</span>
                    <span className='distance'>
                      (à {formatAsBrDecimalNumber(Math.round(item.distance))}m)
                    </span>
                  </div>
                </Link>
              ))}
            </Items>
          </EntitiesWrapper>
        </Container>
      ) : null}
    </>
  ) : (
    <span>Carregando...</span>
  )
}
