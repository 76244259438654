import { defineAction, object, array, string } from '~/prix'
import legalEntityGeoprocessingProcessAction from './legalEntityGeoprocessingProcess.action.json' assert { type: 'json' }

const legalEntityGeoprocessingProcessDefinition = defineAction({
  ...legalEntityGeoprocessingProcessAction,
  input: object({
    states: array(string()).isOptional(),
  }),
  output: object({}),
})

export default legalEntityGeoprocessingProcessDefinition
