import { entity, isNull, query, string, truthy, like, round } from '~/prix'
import asGeoJson from '~/prix/functions/asGeoJson'
import stArea from '~/prix/functions/stArea'

export default function AgentAreaDemarcationQuery(agentCpf: string) {
  return query('attendant')
    .select({
      cpf: entity('attendant').property('cpf'),
      name: entity('attendant').property('name'),
      coverageArea: asGeoJson(entity('attendant').property('coverageArea')),
      focusArea: asGeoJson(entity('attendant').property('focusArea')),
      area: round(stArea(entity('attendant').property('focusArea'))),
    })
    .where(
      ...[
        isNull(entity('attendant').property('deletedAt')),
        like(entity('attendant').property('cpf'), string().value(agentCpf)),
      ].filter(truthy),
    )
}
