import { entity, query, notEquals, number, equals, boolean } from '~/prix'

export default function eachSourceAttendanceDates() {
  return query('dataSource')
    .select({
      dataSourceId: entity('dataSource').property('id'),
      dataSourceName: entity('dataSource').property('name'),
      maxAttendanceDate: entity('dataSource').property('endDate'),
      minAttendanceDate: entity('dataSource').property('startDate'),
    })
    .where(
      ...[
        notEquals(entity('dataSource').property('id'), number().value(1)),
        equals(entity('dataSource').property('isActive'), boolean().value(true)),
      ],
    )
}
