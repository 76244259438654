import differenceInDays from 'date-fns/differenceInDays'
import differenceInMonths from 'date-fns/differenceInMonths'
import differenceInWeeks from 'date-fns/differenceInWeeks'
import differenceInYears from 'date-fns/differenceInYears'
import { EnumeratedType } from './enumerated'
import { NumberType } from './number'
import { ObjectType, ShapeBase } from './object'
import createType from './type'
import number from './number'
import enumerated from './enumerated'
import date, { formatDate, parseDate, stringifyDate } from './date'
import week, { formatFirstDayOfWeek, formatLastDayOfWeek, parseWeek, stringifyWeek } from './week'
import month, { formatMonth, parseMonth, stringifyMonth } from './month'
import year, { formatYear, parseYear, stringifyYear } from './year'
import errors from '../error'

export type IntervalUnit =
  | 'milliseconds'
  | 'seconds'
  | 'minutes'
  | 'hours'
  | 'days'
  | 'weeks'
  | 'months'
  | 'years'

export interface IntervalShape extends ShapeBase {
  valueInSeconds: NumberType<number>
  unit: EnumeratedType<IntervalUnit>
}

export interface IntervalType<NativeShapeGeneric extends IntervalShape | null = IntervalShape>
  extends ObjectType<NativeShapeGeneric> {
  readonly type: 'object'
  readonly subType: 'interval'
  readonly shape: NativeShapeGeneric
  readonly currentValue?: NativeShapeGeneric
  isRequired(): IntervalType<Exclude<NativeShapeGeneric, null>>
  isOptional(): IntervalType<NativeShapeGeneric | null>
}

const defaultIntervalShape = {
  valueInSeconds: number(),
  unit: enumerated({
    milliseconds: 'Milissegundos',
  }).isOptional(),
}

export default function interval<NativeShapeGeneric extends IntervalShape = IntervalShape>(
  shape: NativeShapeGeneric = defaultIntervalShape as NativeShapeGeneric,
): IntervalType<NativeShapeGeneric> {
  return {
    ...createType<IntervalType<NativeShapeGeneric>>(),
    type: 'object',
    shape,
  }
}

export const intervalsAndDifferenceFunctions = {
  milliseconds: undefined,
  seconds: undefined,
  minutes: undefined,
  hours: undefined,
  days: differenceInDays,
  weeks: differenceInWeeks,
  months: differenceInMonths,
  years: differenceInYears,
}

export const intervalsAndTypes = {
  milliseconds: undefined,
  seconds: undefined,
  minutes: undefined,
  hours: undefined,
  days: date,
  weeks: week,
  months: month,
  years: year,
}

export const intervalsAndParsers = {
  milliseconds: undefined,
  seconds: undefined,
  minutes: undefined,
  hours: undefined,
  days: parseDate,
  weeks: parseWeek,
  months: parseMonth,
  years: parseYear,
}

export const intervalsAndStringifyFunctions = {
  milliseconds: undefined,
  seconds: undefined,
  minutes: undefined,
  hours: undefined,
  days: stringifyDate,
  weeks: stringifyWeek,
  months: stringifyMonth,
  years: stringifyYear,
}

export const intervalsAndShortFormatFunctions = {
  milliseconds: undefined,
  seconds: undefined,
  minutes: undefined,
  hours: undefined,
  days: formatDate,
  weeks: (date: Date) => `${formatFirstDayOfWeek(date)} à ${formatLastDayOfWeek(date)}`,
  months: formatMonth,
  years: formatYear,
}

const intervalsArray: IntervalUnit[] = [
  'milliseconds',
  'seconds',
  'minutes',
  'hours',
  'days',
  'weeks',
  'months',
  'years',
]

export function isIntervalGreaterOrEqualTo(left: IntervalUnit, right: IntervalUnit) {
  const indexOfLeft = intervalsArray.indexOf(left)
  const indexOfRight = intervalsArray.indexOf(right)

  if (indexOfLeft === -1 || indexOfRight === -1) {
    throw errors.validation('Unidade de intervalo desconhecida')
  }

  return indexOfLeft >= indexOfRight
}

export function isIntervalLessOrEqualTo(left: IntervalUnit, right: IntervalUnit) {
  const indexOfLeft = intervalsArray.indexOf(left)
  const indexOfRight = intervalsArray.indexOf(right)

  if (indexOfLeft === -1 || indexOfRight === -1) {
    throw errors.validation('Unidade de intervalo desconhecida')
  }

  return indexOfLeft <= indexOfRight
}
