import React, { Fragment } from 'react'
import { useTheme } from 'styled-components'

export interface BottomAxisProps {
  sequence: number[]
  height: number
  top: number
  itemWidth: number
  getLabel?: (index: number, itemWidth: number) => string
  before?: React.ReactNode
  after?: React.ReactNode
}

export function BottomAxis({
  sequence,
  height,
  top,
  itemWidth,
  getLabel = index => String(index + 1),
  before,
  after,
}: BottomAxisProps) {
  const fontSize = 12

  const theme = useTheme()
  const lineMargin = 5
  return (
    <g style={{ userSelect: 'none' }}>
      {before}
      <line
        x1={0}
        y1={top}
        x2={sequence.length * itemWidth}
        y2={top}
        stroke={theme.colors.mediumGrey}
      />
      {sequence.map((value, index) => (
        <Fragment key={index}>
          <text
            x={index * itemWidth + itemWidth / 2}
            y={top + lineMargin + height / 2}
            textAnchor='middle'
            alignmentBaseline='middle'
            fill={theme.colors.darkText}
            fontWeight={500}
            fontSize={fontSize}
          >
            {getLabel(value, itemWidth)}
          </text>
          <line
            x1={index * itemWidth + itemWidth / 2}
            y1={top}
            x2={index * itemWidth + itemWidth / 2}
            y2={top + lineMargin}
            stroke={theme.colors.mediumGrey}
          />
        </Fragment>
      ))}
      {after}
    </g>
  )
}
const MemoDefaultBottomAxis = React.memo(BottomAxis)
export default MemoDefaultBottomAxis
