import { boolean, defineAction, object } from '~/prix'
import individualAnonymizationAction from './individualAnonymization.action.json'

const individualAnonymizationActionDefinition = defineAction({
  ...individualAnonymizationAction,
  input: object({}),
  output: object({
    ok: boolean(),
  }),
})

export default individualAnonymizationActionDefinition
