import {
  defineEntity,
  entity,
  foreign,
  object,
  string,
  timestamps,
  enumerated,
  dateTime,
} from '~/prix'

const legalEntityAttendanceEntity = defineEntity({
  key: 'legalEntityAttendance',
  title: 'Atendimentos',
  alternativeTitles: ['Atendimentos dos Agentes'],
  sources: ['olap', 'oltp'],
  schema: object({
    id: string().label('ID', 'Identificador', 'Código'),
    agentCpf: string().label('CPF', 'CPF do agente que realizou o atendimento'),
    startDate: dateTime().label('Data de início do atendimento'),
    endDate: dateTime().label('Data de fim do atendimento'),
    stateId: foreign(entity('state').property('id')).label('Estado de Atendimento'),
    instrument: enumerated({
      remoteConsultancy: 'Consultoria a distância',
      presentialConsultancy: 'Consultoria presencial',
      remoteReport: 'Informação a distância',
      presentialReport: 'Informação presencial',
      remoteTechnicalGuidance: 'Orientação técnica a distância',
      presentialTechnicalGuidance: 'Orientação técnica presencial',
    }),
    legalEntityId: foreign(entity('legalEntity').property('id')),
    ...timestamps,
  }),
})

export default legalEntityAttendanceEntity
