import styled, { css } from 'styled-components'
import Link from './link'

export interface ButtonProperties {
  styleType?: 'primary' | 'secondary' | 'danger' | 'success' | 'lightGrey'
  withoutMargin?: boolean
}

export const buttonCSS = css<ButtonProperties>`
  background-color: ${({ theme, styleType: type }) => theme.colors[type || 'primary']};
  border-style: none;
  color: ${({ theme, styleType: type }) => type === 'lightGrey' ? theme.colors.darkText : theme.colors.lightText};
  cursor: pointer;
  justify-content: center;
  padding: ${({ theme }) => theme.padding(0.5)} ${({ theme }) => theme.padding(0.7)};
  text-align: center;
  white-space: nowrap;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: none;
  display: inline-flex;
  margin-bottom: ${({ withoutMargin }) => (withoutMargin ? '0' : '0.5rem')};
  user-select: none;

  &[disabled], &.is-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

export const buttonAsSimpleTextCSS = css<ButtonProperties>`
  ${buttonCSS}
  background-color: transparent;
  color: ${({ theme, styleType: type }) => theme.colors[type || 'primary']};
`

export const ButtonLink = styled(Link)`
  ${buttonCSS}
`

export const ButtonLinkAsSimpleText = styled(Link)`
  ${buttonAsSimpleTextCSS}
`

export const Button = styled.button<ButtonProperties>`
  ${buttonCSS}
`
export const InputSubmitButton = styled.input.attrs<ButtonProperties>({
  type: 'submit',
})`
  ${buttonCSS}
`
export const InputResetButton = styled.input.attrs<ButtonProperties>({
  type: 'reset',
})`
  ${buttonCSS}
`
