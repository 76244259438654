import { dateTime, defineEntity, entity, foreign, object, string, number } from '~/prix'
import { point } from '~/prix/types/geoJson'

const serviceUnitGeoprocessing = defineEntity({
  key: 'serviceUnitGeoprocessing',
  title: 'Unidades de atendimento geocodificadas',
  sources: ['olap', 'oltp'],
  schema: object({
    id: string().label('ID', 'Identificador', 'Código'),
    serviceUnitId: foreign(entity('serviceUnit').property('id')),
    coordinates: point().label('Coordenadas da geocodificação com o google maps'),
    geocodingCoordinates: point().label('Coordenadas da geocodificação com o google maps'),
    pointOnStreet: point().label('Coordenadas de rua'),
    pointOnStreetDistance: number().label('Distancia do pointOnStreet com o googleCoordinates'),
    pointOnStreetDirectionAngle: number().label('Ângulo de direcionamento das coordenadas de rua'),
    streetId: foreign(entity('street').property('id')),
    neighborhoodId: foreign(entity('neighborhood').property('id')),
    neighborhoodUpdatedAt: dateTime().label('Data de atualização do bairro'),
    cityId: foreign(entity('city').property('id')),
    cityUpdatedAt: dateTime().label('Data de atualização da cidade'),
    wayId: foreign(entity('way').property('id')),
    microRegionId: foreign(entity('microRegion').property('id')),
    mesoRegionId: foreign(entity('mesoRegion').property('id')),
    stateId: foreign(entity('state').property('id')),
    macroRegionId: foreign(entity('macroRegion').property('id')),
    countryId: foreign(entity('country').property('id')),
  }),
})

export default serviceUnitGeoprocessing
