import { defineAction, object, string } from '~/prix'
import { itineraryObject } from '../fetchItinerary/fetchItinerary.definition'
import selectItineraryAction from './selectItinerary.action.json'

const selectItineraryDefinition = defineAction({
  ...selectItineraryAction,
  input: object({
    temporaryHash: string().isOptional(),
    cpf: string(),
  }),
  output: object({
    itinerary: itineraryObject,
  }),
})

export default selectItineraryDefinition
