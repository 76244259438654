import { useMemo } from 'react'
import { FilterHighlight } from '~/packages/legalEntityGeoprocessing/map/legalEntityGeoprocessingMapLevels.data'
import useLegalEntityGeoprocessingStreetWithClustersMap from '~/packages/legalEntityGeoprocessing/map/legalEntityGeoprocessingStreetWithClustersMap.hook'
import { isNullOrUndefined } from '~/prix/utils/empty'
import { DefinedOption } from '../legalEntityGeoprocessing/map/menu/legalEntityGeoprocessingMapMenu.data'

interface StreetLegalEntitiesOptions {
  id: string
  filter: FilterHighlight | null
  highlight: FilterHighlight | null
  childrenGeoLevel?: 'streetWithClusters'
  isEnabled?: boolean
  definedOption: DefinedOption | null
}

const childrenGeoLevelOptions = {
  streetWithClusters: 'Empresas',
}

export default function useStreetLegalEntities({
  id,
  filter,
  highlight,
  childrenGeoLevel = 'streetWithClusters',
  isEnabled = true,
  definedOption,
}: StreetLegalEntitiesOptions) {
  const polygonsResult = useLegalEntityGeoprocessingStreetWithClustersMap({
    filter,
    highlight,
    isEnabled,
    childrenGeoLevel,
    id,
    by: 'streetId',
    definedOption,
  })

  const { countSum, highlightSum } = useMemo(() => {
    if (
      definedOption &&
      definedOption.type === 'legalEntityAttendanceIndex' &&
      polygonsResult.isLoading === false
    ) {
      const legalEntityCount = polygonsResult.items
        ? polygonsResult.items
          .filter(item => !item.path)
          .reduce((acc, item) => acc + (Number(item.count) ?? 0), 0)
        : null

      const attendanceCount = polygonsResult.items
        ? polygonsResult.items
          .filter(item => !item.path)
          .reduce((acc, item) => acc + (Number(item.highlight) ?? 0), 0)
        : null

      return {
        countSum: legalEntityCount,
        highlightSum: attendanceCount,
      }
    }

    const countSum = polygonsResult.items
      ? polygonsResult.items
        .filter(item => !item.path)
        .reduce((acc, item) => acc + (item.count ?? 0), 0)
      : null

    const highlightSum =
      polygonsResult.items && highlight && !isNullOrUndefined(highlight.value)
        ? polygonsResult.items
          .filter(item => !item.path)
          .reduce((acc, item) => acc + (item.highlight ?? 0), 0)
        : null

    return {
      countSum,
      highlightSum,
    }
  }, [highlight, polygonsResult?.items, definedOption])

  return {
    ...polygonsResult,
    childrenGeoLevelOptions,
    childrenGeoLevel,
    countSum,
    highlightSum,
  }
}