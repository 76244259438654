import { defineAction, object } from '~/prix'
import prependCommaAtCnaesAction from './prependCommaAtCnaes.action.json' assert { type: 'json' }

const prependCommaAtCnaesDefinition = defineAction({
  ...prependCommaAtCnaesAction,
  input: object({}),
  output: object({}),
})

export default prependCommaAtCnaesDefinition
