import { date, defineEntity, number, object, string, timestamps, enumerated, boolean } from '~/prix'

const legalEntityEntity = defineEntity({
  key: 'legalEntity',
  title: 'Pessoa jurídica',
  alternativeTitles: ['Empresa', 'Entidade legal', 'Negócio', 'Revendedora'],
  sources: ['olap', 'oltp'],
  schema: object({
    id: string().label('ID', 'Identificador', 'Código'),
    tradeName: string().label('Nome fantasia', 'Nome público', 'Nome'),
    corporateName: string().isOptional().label('Razão social', 'Nome de registro', 'Nome'),
    cnpj: string().label('CNPJ'),
    openingDate: date().label('Data de início da atividade', 'Data de criação', 'Data de abertura'),
    email: string().label('E-mail'),
    neighborhood: string().label('Bairro'),
    postalCode: string().label('CEP'),
    publicPlace: string().label('Logradouro'),
    number: string().label('Número'),
    complement: string().label('Complemento'),
    city: string().label('Cidade'),
    state: string().label('Estado'),
    mainCnaeId: string().label('CNAE primário'),
    cnaes: string().label('CNAEs secundários'),
    size: enumerated({
      unknown: 'Não informado',
      micro: 'Micro empresa',
      small: 'Empresa de pequeno porte',
      other: 'Demais',
    }),
    isSimples: boolean().label('É SIMPLES'),
    isMei: boolean().label('É MEI'),
    capital: number().label('Capital social'),
    legalNatureId: string().label('Natureza legal'),
    isBranch: boolean().label('É filial'),
    registrationStatus: enumerated({
      null: 'null',
      active: 'active',
      suspended: 'suspended',
      inapt: 'inapt',
      extinct: 'extinct',
    }),
    registrationStatusDate: date().label('Data de atualização do status de registro'),
    registrationStatusReason: string().label('Motivo do status de registro'),
    fullAddressHash: string().label('Hash do Endereço da entidade.'),
    sector: enumerated({
      agribusiness: 'Agronegócios',
      commerce: 'Comércio',
      industry: 'Indústria',
      service: 'Serviços',
    }),
    phoneNumberOneAreaCode: string().label('Código de área de telefone principal'),
    phoneNumberOne: string().label('Telefone principal'),
    phoneNumberTwoAreaCode: string().label('Código de área de telefone secundário'),
    phoneNumberTwo: string().label('Telefone secundário'),
    ...timestamps,
  }),
})

export default legalEntityEntity
