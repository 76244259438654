import { defineAction, object } from '~/prix'
import streetSearchRefreshViewsAction from './streetSearchRefreshViews.action.json'

const streetSearchRefreshViewsDefinition = defineAction({
  ...streetSearchRefreshViewsAction,
  input: object({}),
  output: object({}),
})

export default streetSearchRefreshViewsDefinition
