import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import UserIcon from '~/components/icons/users/48px_circle-08.svg'
import { useKeycloak } from '~/components/keycloak'
import { colors } from '~/design'
import Logo from '~/meta/logo.svg'
import { AppError } from '~/prix'
import Handle from '~/prix/react/components/handle'
import Link from '~/prix/react/components/link'
import keycloakConfig from '~/utils/shared/keycloakConfig'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  @media (max-width: 1050px) {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
  }
  form {
    margin-top: -15px;
  }
  .label {
    flex: 1;
    text-align: left;
    font-size: 14px;
  }
  .label :last-child {
    display: none;
  }
  .input[id='email'] {
    margin-bottom: 5px;
  }
  .input[id='password'] {
    margin-bottom: 5px;
  }
  input[type='submit'] {
    width: 100%;
    height: 35px;
    font-weight: bold;
    &:hover {
      text-decoration: none;
      background-color: ${colors.oceanBlue};
      cursor: pointer;
      transition-duration: 0.3s;
    }
  }
  #device {
    display: none;
  }
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 4px #fafafa;
  padding: 30px;
  min-width: 275px;
  max-width: 450px;

  .logo {
    flex-direction: row;
  }

  small {
    margin-top: 10px;
    color: #00000089;
  }
`

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const IconContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  align-self: center;
`

const EmailText = styled.span`
  padding: 15px 0px 15px 0px;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150ch;
  text-align: center;
  margin-top: 5px;
  font-size: 16px;
  color: ${colors.darkGray};
`

const Button = styled(Link)`
  display: block;
  width: 100%;
  text-decoration: none;
  background-color: ${colors.sebraeBlue};
  border: 1px solid;
  color: #fff;
  padding: 10px;
  transition-duration: 0.4s;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  max-width: 225px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.oceanBlue};
    color: #fff;
  }
`

const SubLink = styled(Link)`
  display: block;
  padding: 5px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: ${colors.sebraeBlue};

  &:hover {
    color: ${colors.oceanBlue};
  }
`

const LinkTerms = styled(Link)`
  font-weight: 500;
  color: ${colors.sebraeBlue};
  &:hover {
    color: ${colors.oceanBlue};
  }
`

export default function LoginComponent() {
  const navigate = useNavigate()
  const {
    isLoading,
    isAuthenticated,
    login,
    logout,
    tokenParsed,
    bypassAuth,
    error: generalAuthError,
  } = useKeycloak()
  const [loginOrLogoutError, setLoginOrLogoutError] = useState<Error | AppError | null>(null)

  useEffect(() => {
    if (isLoading) {
      return
    }
    if (isAuthenticated) {
      const redirectOnAuthSuccess = sessionStorage.getItem('redirectOnAuthSuccess')
      if (redirectOnAuthSuccess) {
        sessionStorage.removeItem('redirectOnAuthSuccess')
        navigate(redirectOnAuthSuccess)
      }
    }
  }, [isLoading, isAuthenticated])

  const handleLogin = useCallback(
    async (event: React.MouseEvent) => {
      event.preventDefault()
      setLoginOrLogoutError(null)
      try {
        sessionStorage.setItem('redirectOnAuthSuccess', '/app')
        await login()
      } catch (error) {
        setLoginOrLogoutError(error as Error)
      }
    },
    [isLoading, login],
  )

  const handleLogout = useCallback(
    async (event: React.MouseEvent) => {
      event.preventDefault()
      setLoginOrLogoutError(null)
      try {
        await logout()
      } catch (error) {
        setLoginOrLogoutError(error as Error)
      }
    },
    [logout],
  )

  const handleReload = useCallback(async () => {
    window.location.reload()
  }, [])

  const error = generalAuthError ?? loginOrLogoutError

  if (isAuthenticated && tokenParsed) {
    return (
      <Wrapper>
        <Box>
          <Handle error={error} isLoading={isLoading} onTryAgain={handleReload}>
            <Container>
              <IconContainer>
                <UserIcon height={65} width={65} fill={colors.sebraeBlue} />
              </IconContainer>
              <EmailText> {tokenParsed.email} </EmailText>

              <Button href='/app'>Continuar como {tokenParsed.name}</Button>
              <br />
              <SubLink remote newTab href='/terms'>
                Termos de uso
              </SubLink>
              {!bypassAuth ? (
                <SubLink
                  remote
                  newTab
                  href={`${keycloakConfig.url}/realms/${keycloakConfig.realm}/account`}
                >
                  Conta
                </SubLink>
              ) : (
                <span>⚠️ Autenticado para desenvolvimento</span>
              )}
              <SubLink onClick={handleLogout}>Sair</SubLink>
            </Container>
          </Handle>
        </Box>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Box>
        <Handle error={error} isLoading={isLoading} onTryAgain={handleReload}>
          <div className='logo'>
            <Logo height={170} width={170} fill={colors.sebraeBlue} />
          </div>
          <Button onClick={handleLogin}>ACESSAR</Button>
          {bypassAuth ? <span>⚠️ Autenticação para desenvolvimento</span> : null}
          <small>
            Prosseguindo você concorda com os{' '}
            <LinkTerms newTab href='/terms'>
              {' '}
              termos de uso{' '}
            </LinkTerms>
            .
          </small>
        </Handle>
      </Box>
    </Wrapper>
  )
}
