import {
  defineAction,
  object,
  string,
} from '~/prix'
import deregisterDevice from './deregisterDevice.action.json'

const deregisterDeviceDefinition = defineAction({
  ...deregisterDevice,
  input: object({
    userEmail: string(),
    userToken: string(),
  }),
  output: object({}),
})

export default deregisterDeviceDefinition

