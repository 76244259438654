import { defineEntity, object, number, foreign, entity, dateTime } from '~/prix'

const legalEntityDataSourceEntity = defineEntity({
  key: 'legalEntityDataSource',
  title: 'Fonte de dados da empresa',
  sources: ['default'],
  schema: object({
    id: number().label('ID', 'Identificador', 'Código'),
    legalEntityId: foreign(entity('legalEntity').property('id')).label(
      'Identificador pessoa jurídica',
    ),
    dataSourceId: foreign(entity('dataSource').property('id')).label(
      'Identificador da fonte de dados',
    ),
    createdAt: dateTime().label('Data de inclusão'),
    dataSourceOriginId: number().label('ID original da empresa (cnpj)'),
  }),
})

export default legalEntityDataSourceEntity