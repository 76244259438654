import { format, subMonths, subYears } from 'date-fns'
import { FilterHighlight } from '../legalEntityGeoprocessingMapLevels.data'

interface DateFilter {
  startDate: string
  endDate: string
}

export function createDefaultDateFilter() {
  return {
    startDate: format(subYears(new Date(), 1), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
  }
}

export function applyFilter(dateFilter: DateFilter, filter: FilterHighlight) {
  if (filter && filter.value && typeof filter.value === 'string') {
    const [start, end] = filter.value.split(',')

    return {
      ...dateFilter,
      startDate: start,
      endDate: end,
    }
  }
  return dateFilter
}

export function getDateRangeFilter(filterIncoming: FilterHighlight) {
  let filter = createDefaultDateFilter()
  const dateRangeFilter = applyFilter(filter, filterIncoming)

  return dateRangeFilter
}

export function extractDates(dateRange: string): [string | null, string | null] {
  let startDate: string | null = null
  let endDate: string | null = null

  if (dateRange && typeof dateRange === 'string') {
    const parts = dateRange.split(',')

    if (parts.length === 2) {
      startDate = parts[0].trim()
      endDate = parts[1].trim()
    }
  }

  return [startDate, endDate]
}

export const attendancePeriodLastYear = `${format(subYears(new Date(), 1), 'yyyy-MM-dd')},${format(
  new Date(),
  'yyyy-MM-dd',
)}`

export const attendancePeriodLastMonth = `${format(
  subMonths(new Date(), 1),
  'yyyy-MM-dd',
)},${format(new Date(), 'yyyy-MM-dd')}`

export const attendancePeriodLastYearFormatted = {
  startDate: format(subYears(new Date(), 1), 'yyyy-MM-dd'),
  endDate: format(new Date(), 'yyyy-MM-dd'),
}

export const attendancePeriodLastMonthFormatted = {
  startDate: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
  endDate: format(new Date(), 'yyyy-MM-dd'),
}
