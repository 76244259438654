import { defineEntity, object, string } from '~/prix'

const studentCompanyEad = defineEntity({
  key: 'alunoEmpresa',
  title: 'EaD de aluno empresa',
  sources: ['oltp'],
  entitySchema: 'ead',
  schema: object({
    cpf: string(),
    cnpj: string(),
    dataInclusao: string(),
    dataModificacao: string(),
  }),
})

export default studentCompanyEad
