import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 8px 0 0px 0;

  hr {
    display: block;
    height: 1px;
    background: transparent;
    width: 100%;
    border: none;
    margin-top: 5px;
    margin-bottom: 10px;
    border-top: solid 1px rgba(221, 221, 221, 0.5);
  }
`

export default function Separator() {
  return (
    <Wrapper>
      <hr />
    </Wrapper>
  )
}
