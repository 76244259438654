import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { colors } from '~/design'
import SelectFromQuery from '~/prix/react/components/form/inputs/selectFromQuery'
import { ascending, entity, like, query, string } from '~/prix'
import AppLayout from '~/components/appLayout'
import AttendantProductionChart from './attendantProductionChart.component'
import unaccent from '~/prix/functions/unaccent'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .header {
    height: 150px;
    background-color: ${colors.sebraeBlue}; 
    z-index: 999;
    .title {
      padding-left: 30px;
      font-weight: 600;
      font-size: 24px;
      color: #fff;
    }

    .select-wrapper {
      padding-left: 30px;
      width: 400px;
      display: flex;
      flex: 1;
      z-index: 999;

      .react-select__value-container {
        flex-wrap: nowrap !important;
        overflow-x: auto;
        overflow-y: hidden;

        ::-webkit-scrollbar{
            height: 4px;
            background: lightgray;
          }
      
        .react-select__multi-value {
          height: 20px;
          font-size: 16px;
          min-width: 25% !important;
          padding-right: 0px;
        }
      }
    }
  }

  .graph {
    display: flex;
    padding-top: 30px;
  }
`

export default function AttendantProductionSelectableChart() {
  const [attendantSelected, setAttendantSelected] = useState<{ id: string, name: string }[]>([])

  const attendantListQueryFactory = useCallback(
    (input: string) => (
      query('legalEntityAttendanceAttendant')
        .select({
          agentCpf: entity('legalEntityAttendanceAttendant').property('cpf'),
          name: entity('legalEntityAttendanceAttendant').property('coalesce'),
          order: entity('legalEntityAttendanceAttendant').property('name'),
        })
        .where(
          like(
            entity('legalEntityAttendanceAttendant').property('coalesce'),
            unaccent(string().value(`%${input.replace(/\s/g, '%')}%`)),
          ),
        )
        .order(ascending('order'))
        .limit(20)
    ), [],
  )

  const handleAttendant = useCallback((values) => {
    // Filtrando valores duplicados do input
    const uniqueValues = values.filter((value: { agentCpf: string }, index: number, self: { agentCpf: string }[]) =>
      index === self.findIndex((currentValue: { agentCpf: string }) => currentValue.agentCpf === value.agentCpf)
    )
    setAttendantSelected(uniqueValues.map((value: { agentCpf: string, name: string }) => ({ id: value.agentCpf, name: value.name.split(" ")[0] })))
  }, [])

  return (
    <AppLayout
      title='Gráfico de produtividade'
      dockActive='start'
    >
      <Wrapper>
        <div className='header'>
          <p className='title'>
            Gráfico de produtividade
          </p>
          <div className='select-wrapper'>
            <SelectFromQuery
              placeholder='Busque por CPF...'
              idProperty='id'
              isSearchInput={true}
              isMultiple
              labelProperty='name'
              queryFactory={attendantListQueryFactory}
              value={attendantSelected.map(attendant => ({ id: attendant.id, name: attendant.name }))}
              onDirectChange={handleAttendant}
            />
          </div>
        </div>
        <div className='graph'>
          <AttendantProductionChart attendant={attendantSelected} />
        </div>
      </Wrapper>
    </AppLayout>
  )
}
