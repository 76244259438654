import React from 'react'
import { useMemo } from 'react'
import styled from 'styled-components'
import useWindowSize from 'react-use/lib/useWindowSize'

const borderRadius = 14
const Wrapper = styled.div`
  position: fixed;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  z-index: 999;
  padding: 14px;
  overflow: auto;

  p {
    margin: 0;
  }
`

export interface TooltipProps extends React.ComponentPropsWithoutRef<'div'> {
  children: React.ReactNode
  x: number
  y: number
  position?: 'fixed' | 'absolute'
  wrapperWidth?: number
  wrapperHeight?: number
}

export default function Tooltip({
  children,
  x,
  y,
  position = 'fixed',
  wrapperWidth: fixedWrapperWidth,
  wrapperHeight: fixedWrapperHeight,
  ...props
}: TooltipProps) {
  const { width: windowWidth, height: windowHeight } = useWindowSize()
  const wrapperWidth = fixedWrapperWidth || windowWidth
  const wrapperHeight = fixedWrapperHeight || windowHeight

  const style = useMemo(() => {
    const maxWidth = Math.max(x, wrapperWidth - x) - 20
    const maxHeight = Math.max(y, wrapperHeight - y) - 20

    const xBasis = wrapperWidth / 2 >= x ? 'left' : 'right'
    const yBasis = wrapperHeight / 2 >= y ? 'top' : 'bottom'

    const newStyle = {
      position,
      left: 'initial',
      right: 'initial',
      top: 'initial',
      bottom: 'initial',
      [xBasis]: xBasis === 'left' ? x : wrapperWidth - x,
      [yBasis]: yBasis === 'top' ? y : wrapperHeight - y,
      maxWidth,
      maxHeight,
      borderTopLeftRadius: yBasis === 'top' && xBasis === 'left' ? 0 : borderRadius,
      borderTopRightRadius: yBasis === 'top' && xBasis === 'right' ? 0 : borderRadius,
      borderBottomLeftRadius: yBasis === 'bottom' && xBasis === 'left' ? 0 : borderRadius,
      borderBottomRightRadius: yBasis === 'bottom' && xBasis === 'right' ? 0 : borderRadius,
    } as const
    return newStyle
  }, [x, y, wrapperWidth, wrapperHeight, position])

  return (
    <Wrapper
      {...props}
      style={{
        ...style,
        ...(props.style || {}),
      }}
    >
      {children}
    </Wrapper>
  )
}
