import React from 'react'
import styled from 'styled-components'
import { colors, complementarColors } from '~/design'
import OriginalLink from '~/prix/react/components/link'
import MapIcon from './icons/maps/48px_world-pin.svg'
import DashboardIcon from './icons/ui/48px_dashboard-30.svg'
import { menuBoxShadow } from './menu'
import MapsIcon from '~/meta/mapsIcon.svg'
import HomeIcon from '~/meta/homeIcon.svg'
import OptionsIcon from '~/meta/optionsIcon.svg'
import BrandsIcon from './icons/maps/32px_pin-user.svg'
import useMedia from 'react-use/lib/useMedia'

const Wrapper = styled.div<{
  shadow?: boolean
}>`
  position: relative;
  display: flex;
  width: 83px;
  flex-direction: column;
  order: 1;
  border-right: ${({ shadow }) => (shadow ? 'none' : `1px solid ${colors.gray}`)};
  box-shadow: ${({ shadow }) => (shadow ? menuBoxShadow : 'none')};
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 3;

  @media (max-width: 768px) {
    bottom: 0;
    width: 100%;
    order: 4;
    overflow: auto;
    overflow-x: auto;
    overflow-y: hidden;
    background-color: #005EB8;
  }

  nav {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    flex: 1 0;

    span.anonymization {
      vertical-align: bottom;
    }

    footer {
      position: absolute;
      bottom: 10px;
      text-align: center;
    }

    a {
      padding: 12px 0px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 768px) {
        padding: 5px 0px;
      }
    }

    @media (max-width: 768px) {
      flex-direction: row;
      padding-bottom: 0;

      span,
      span.anonymization {
        display: none;
      }

      footer {
        position: relative;
        bottom: 0px;
      }
    }
  }
`
const Link = styled(OriginalLink)`
  display: flex;
  flex-shrink: 1;
  width: 100%;
  text-align: center;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  position: relative;
  justify-content: center;

  &:hover {
    background-color: rgb(240, 246, 254, 1);
  }

  span {
    font-weight: bold;
    text-align: center;
    font-size: 12px;
    margin-top: 8px;
    line-height: 1.3;
    display: block;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &.anonymization {
    padding: 10px 5px 5px;

    .badge {
      display: flex;
      background-color: #eee;
      border-radius: 5px;
      padding: 5px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      @media (max-width: 768px) {
        background-color: #fff;
      }
    }
  }

  svg {
    display: flex;
    min-width: 48px;
    min-height: 48px;
  }

  @media (max-width: 768px) {
    &.anonymization {
      padding: 5px 5px 5px;
    }
  }
`

const Ball = styled.div`
  background-color: ${complementarColors.alizarin};
  border-radius: 100%;
  height: 1.75em;
  width: 1.75em;
  text-align: center;
  position: absolute;
  top: -0.3em;
  left: 4.2em;
  opacity: 1;
  animation-direction: alternate;
  animation-name: ball-pulse;
  animation-duration: 400ms;
  animation-iteration-count: infinite;

  @media (max-width: 768px) {
    height: 1em;
    width: 1em;
    top: 0.4em;
    left: 2.9em;
  }

  p {
    margin-top: 0.1em;
    font-size: 0.78em;
    font-weight: bold;
    font-weight: bolder;
    font-family: sans-serif;
    color: white !important;
    position: relative;
    left: 0.05em;

    @media (max-width: 768px) {
      display: none;
    }
  }

  @keyframes ball-pulse {
    0% {
      background-color: ${complementarColors.alizarin};
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    }
    100% {
      background-color: #822c23;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
    }
  }
`
const SmallBall = styled(Ball)`
  height: 1em;
  width: 1em;
  top: 0.3em;
  left: 4em;

  @media (max-width: 768px) {
    height: 1em;
    width: 1em;
    top: 0.4em;
    left: 3.1em;
  }
`

export default function Dock({ shadow, active }: { shadow?: boolean; active?: string }) {
  const isMobile = useMedia('(max-width: 768px)')

  return (
    <Wrapper shadow={shadow}>
      <nav>
        <Link
          href='/app'
          className='link'
          style={
            isMobile ? { background: '#FFF' } : { background: active === 'start' ? 'var(--p-light, #F0F6FE)' : '' }
          }
        >
          <HomeIcon
            fill={active === 'start' ? '#0F438A' : '#777'}
            width={48}
            height={48}
          />
          <span style={{ color: active === 'start' ? '#0F438A' : '#777' }}>
            Início
          </span>
          {/* {notificationsState.data?.totalItems ? (
            <Ball>
              <p>
                {notificationsState.data?.totalItems > 99
                  ? '99+'
                  : notificationsState.data?.totalItems}
              </p>
            </Ball>
          ) : null} */}
        </Link>
        <Link
          href='/app/map'
          className='link'
          style={
            isMobile ? { background: '#FFF' } : { background: active === 'map' ? 'var(--p-light, #F0F6FE)' : '' }
          }
        >
          <MapsIcon
            fill={active === 'map' ? '#0F438A' : '#777'}
            width={48}
            height={48}
          />
          <span style={{ color: active === 'map' ? '#0F438A' : '#777' }}>
            Mapa
          </span>
        </Link>
        {/* <Link
          href='/app/person'
          className='link'
          style={{
            color: active === 'person' ? colors.sebraeBlue : colors.darkGray,
          }}
        >
          <CitizenIcon
            fill={active === 'person' ? colors.sebraeBlue : colors.darkGray}
            width={48}
            height={48}
          />
          <span>EMPRESAS</span>
        </Link> */}
        <Link
          href='/app/options'
          className='link'
          style={
            isMobile ? { background: '#FFF' } : { background: active === 'options' ? 'var(--p-light, #F0F6FE)' : '' }
          }
        >
          <OptionsIcon
            fill={active === 'options' ? '#0F438A' : '#777'}
            width={48}
            height={48}
          />
          <span style={{ color: active === 'options' ? '#0F438A' : '#777' }}>
            Opções
          </span>
          {/* {applicationAboutState.data?.isOutdated ? <SmallBall /> : null} */}
        </Link>
      </nav>
    </Wrapper>
  )
}
