import { defineAction, object, number } from '~/prix'
import fetchStreetsAndWays from './fetchStreetsAndWays.action.json' assert { type: 'json' }

const fetchStreetsAndWaysDefinition = defineAction({
  ...fetchStreetsAndWays,
  input: object({
    osmNumber: number().label('Código OSM (Open Street Map'),
  }),
  output: object({}),
})

export default fetchStreetsAndWaysDefinition
