import { format } from 'date-fns'
import {
  entity,
  query,
  count,
  distinct,
  equals,
  truthy,
  string,
  number,
  boolean,
  isNull,
  notNull,
  descending,
  greaterOrEqual,
  date,
} from '~/prix'

export default function courseProductMostUsedQuery({
  stateAbbreviation,
  stateId,
}: {
  stateAbbreviation?: string
  stateId?: string
}) {
  const currentDate = new Date()
  const firstDayOfCurrentYear = new Date(currentDate.getFullYear(), 0, 1)
  const firstDayOfCurrentYearFormatted = format(firstDayOfCurrentYear, 'yyyy-MM-dd')

  return query('legalEntity')
    .select({
      course_product: entity('courseProduct').property('productCode'),
      course_product_name: entity('courseProduct').property('productName'),
      total: count(distinct(entity('courseRegisterIndividual').property('id'))),
      totalLegalEntity: count(
        distinct(entity('legalEntityGeoprocessing').property('legalEntityId')),
      ),
    })
    .join({
      current: entity('legalEntity').property('id'),
      target: entity('legalEntityGeoprocessing').property('legalEntityId'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntity').property('id'),
      target: entity('courseRegisterIndividual').property('legalEntityId'),
      join: 'inner',
    })
    .join({
      current: entity('courseRegisterIndividual').property('courseProductId'),
      target: entity('courseProduct').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntity').property('id'),
      target: entity('legalEntityAttendance').property('legalEntityId'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityAttendance').property('id'),
      target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityAttendanceDataSource').property('dataSourceId'),
      target: entity('dataSource').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('stateId'),
      target: entity('state').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('cityId'),
      target: entity('city').property('id'),
      join: 'inner',
    })
    .where(
      ...[
        isNull(entity('legalEntity').property('deletedAt')),
        notNull(entity('legalEntityGeoprocessing').property('stateId')),
        stateAbbreviation && stateAbbreviation !== undefined
          ? equals(entity('state').property('abbreviation'), string().value(stateAbbreviation))
          : null,

        stateId && stateId !== undefined
          ? equals(entity('legalEntityAttendance').property('stateId'), string().value(stateId))
          : null,

        equals(
          entity('legalEntityAttendanceDataSource').property('dataSourceId'),
          number().value(5),
        ),

        equals(entity('courseProduct').property('isActivePortfolio'), boolean().value(true)),

        greaterOrEqual(
          entity('courseRegisterIndividual').property('registerDate'),
          date().value(`${firstDayOfCurrentYearFormatted}`),
        ),
      ].filter(truthy),
    )
    .order(descending('total'))
    .limit(5)
}
