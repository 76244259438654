import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

interface LoadingMessageProps {
  messages: string[]
}

export default function LoadingMessage({ messages }: LoadingMessageProps) {
  const messageIndexRef = useRef(0)
  const [message, setMessage] = useState(messages[0])

  useEffect(() => {
    setMessage(messages[messageIndexRef.current])

    let timeout: NodeJS.Timeout

    const createTimeout = () => {
      const delay =
        Math.random() < 0.01 ? 500 : Math.random() < 0.4 ? 2000 : Math.random() < 0.6 ? 4000 : 6000
      timeout = setTimeout(() => {
        messageIndexRef.current = (messageIndexRef.current + 1) % messages.length
        setMessage(messages[messageIndexRef.current])
        createTimeout()
      }, delay)
    }
    createTimeout()

    return () => {
      clearTimeout(timeout)
      messageIndexRef.current = 0
    }
  }, [messages])

  return <Wrapper>{message}...</Wrapper>
}
