import format from 'date-fns/format'
import getWeekOfMonth from 'date-fns/getWeekOfMonth'
import setISOWeek from 'date-fns/setISOWeek'
import setISOWeekYear from 'date-fns/setISOWeekYear'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import { baseDate } from './dateTime'
import { getMonthAbbreviation, getMonthName } from './month'
import createType, { AbstractType } from './type'
import errors from '../error'
import { formatDate } from './date'

export interface WeekType<NativeGeneric extends string | null = string>
  extends AbstractType<NativeGeneric> {
  readonly type: 'week'
  readonly currentValue?: NativeGeneric
  isRequired(): WeekType<Exclude<NativeGeneric, null>>
  isOptional(): WeekType<NativeGeneric | null>
}

export default function week(): WeekType<string> {
  return {
    ...createType<WeekType<string>>(),
    type: 'week',
  }
}

export function parseWeek(date: string): Date {
  const [full, year, week] = date.match(/(\d{4,4})-W(\d{2,2})/) || []
  if (!full) {
    throw errors.incompatible(`Semana em formato errado "${date}".`)
  }

  const jsYearDate = setISOWeekYear(baseDate, parseInt(year, 10))
  const jsDate = setISOWeek(jsYearDate, parseInt(week, 10))
  return jsDate
}

export function stringifyWeek(jsDateTime: Date): string {
  return format(jsDateTime, "RRRR-'W'II")
}

if (typeof window !== 'undefined') {
  ;(window as any).stringifyWeek = stringifyWeek
  ;(window as any).parseWeek = parseWeek
}

export function formatWeekOfMonth(
  jsDateTime: Date,
  { isAbbreviated }: { isAbbreviated?: boolean } = {},
): string {
  const weekOfMonth = getWeekOfMonth(jsDateTime)
  return `${weekOfMonth}ª de ${
    isAbbreviated ? getMonthAbbreviation(jsDateTime) : getMonthName(jsDateTime)
  }`
}

export function formatFirstDayOfWeek(jsDateTime: Date): string {
  const jsFirstDay = startOfWeek(jsDateTime)
  return formatDate(jsFirstDay)
}

export function formatLastDayOfWeek(jsDateTime: Date): string {
  const jsLastDay = endOfWeek(jsDateTime)
  return formatDate(jsLastDay)
}
