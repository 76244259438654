import { date, defineEntity, object, string } from '~/prix'

const establishmentEntity = defineEntity({
  key: 'estabelecimento',
  title: 'Estabelecimentos',
  sources: ['oltp'],
  entitySchema: 'rfb',
  schema: object({
    cnpjBasico: string().label('CNPJ'),
    cnaePrincipal: string().label('CNAE principal'),
    situacaoCadastral: string().label('Situação cadastral'),
    dataInicioAtividade: date().label('Data início de atividade'),
    uf: string().label('Abreviatura do Estado'),
  }),
})

export default establishmentEntity
