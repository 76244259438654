import React, { useCallback } from 'react'
import AppLayout from '~/components/appLayout'
import OptionsMenu from '../option/menu.component'
import UserInformation from './userInformation'
import DeviceInformation from './deviceInformation'
import styled from 'styled-components'
import ResolutionInformation from './screenInformation'
import ServiceWorkerActive from './serviceWorker'
import StorageInformation from './storage'
import MemoryInformation from './memory'
import LayoutInformation from './layoutInformation'
import useActionFunction from '~/prix/react/hooks/actionFunction'
import sendNotificationDefinition from '../notifications/sendNotification/sendNotification.definition'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  text-align: center;
  flex-direction: column;
  padding: 20px;
  background-color: #fff;
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  tr:nth-child(even) {
    background-color: #ffff;
  }
`

export default function SystemAndInformation() {
  return (
    <AppLayout
      title='Informações do Sistema'
      dockActive='options'
      menu={<OptionsMenu menuActive='system-and-information' />}
      card={{
        title: 'Informações do Sistema',
      }}
    >
      <Wrapper>
        <table className='tb'>
          <UserInformation />
          <DeviceInformation />
          <ResolutionInformation />
          <LayoutInformation />
          <ServiceWorkerActive />
          <StorageInformation />
          <MemoryInformation />
        </table>
      </Wrapper>
    </AppLayout>
  )
}
