import { defineEntity, entity, foreign, object, string } from '~/prix'

const legalEntityUnnestCnaeEntity = defineEntity({
  key: 'legalEntityUnnestCnae',
  title: 'Cnaes desaninhados',
  alternativeTitles: ['Número de empresas dividido pelo valor da área de cidades'],
  sources: ['olap', 'oltp'],
  schema: object({
    legalEntityId: foreign(entity('legalEntity').property('id')).label('ID de empresa'),
    cnaeId: string().label('IDs de CNAEs secundários'),
  }),
})

export default legalEntityUnnestCnaeEntity
