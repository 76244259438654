import React from 'react'
import styled from 'styled-components'
import { colors } from '~/design'
import AppLayout from '~/components/appLayout'
import AttendantProductionChart from './attendantProductionChart.component'
import useAPI from '~/prix/react/hooks/api'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .header {
    display: flex;
    height: 150px;
    background-color: ${colors.sebraeBlue}; 
    align-items: center;
    
    .title {
      padding-left: 30px;
      font-weight: 600;
      font-size: 24px;
      color: #fff;
    }
  }

  .graph {
    display: flex;
    padding-top: 30px;
  }
`

export default function AttendantProductionIndividualChart() {
  const { context } = useAPI()

  const attendant = {
    id: String(context.user?.cpf),
    name: String(context.user?.name)
  }

  return (
    <AppLayout
      title='Gráfico de produtividade'
      dockActive='start'
    >
      <Wrapper>
        <div className='header'>
          <p className='title'>
            Gráfico de produtividade
          </p>
        </div>
        <div className='graph'>
          {attendant ?
            <AttendantProductionChart attendant={[attendant]} />
            : null}
        </div>
      </Wrapper>
    </AppLayout >
  )
}