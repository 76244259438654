import React from 'react'
import styled from 'styled-components'
import theme, { colors } from '~/design'
import { formatAsPercentage } from '~/prix'
import { Button } from '~/prix/react/components/button'

import SearchIcon from '~/components/icons/ui/16px_zoom-2.svg'

const BarChartTitle = styled.h1`
  margin-top: 0;
  font-size: 1.2rem;
  font-weight: bolder;
`
const BarChartContainer = styled.div`
  width: 100%;

  p {
    margin-bottom: 5px;
    font-size: 1rem;
    text-overflow: ellipsis;
  }
`
const BarChartBar = styled.div`
  width: 100%;
  padding-top: 15px;
  position: relative;
  background: lightgray;
  border-radius: 15px;
  overflow: hidden;

  div {
    top: 0;
    left: 0;
    position: absolute;
    height: 15px;
    width: ${props => props.theme.custom?.percentage}%;
    background: ${colors.sebraeBlue};
    span {
      top: 25%;
      left: 0;
      padding-left: 5px;
      color: white;
      font-weight: bold;
      position: absolute;
      text-shadow: 0 0 2px black;
    }
  }
`

const ChartTitleContainer = styled.div`
  padding-top: 45px;
  display: flex;
  justify-content: space-between;

  button {
    &:hover {
      box-shadow: 0px 1px 4px 0px rgba(20, 46, 82, 0.2);
      background: #0f438a;
      transition: 0.1s;
    }
  }
`

const PercentageLabelContainer = styled.div`
  margin-top: 5px;
`

interface BarChartProps {
  title: string
  buttonProps: {
    title: string
    onClick: (event: any) => void
  }
  items: {
    title: string
    percentage: number
  }[]
}

export default function BarChart({ title, buttonProps, items }: BarChartProps) {
  return (
    <>
      <ChartTitleContainer>
        <BarChartTitle>{title}</BarChartTitle>
        <div style={{ width: '10px' }}></div>
        <Button
          title={buttonProps.title}
          style={{ marginBottom: 0, height: '35px' }}
          onClick={buttonProps.onClick}
        >
          <div style={{ paddingRight: '15px', scale: '60%', fill: 'white' }}>
            <SearchIcon />
          </div>
          <div>{buttonProps.title}</div>
        </Button>
      </ChartTitleContainer>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <BarChartContainer>
            <p>{item.title}</p>
            <BarChartBar theme={{ ...theme, custom: { percentage: item.percentage * 100 } }}>
              <div></div>
            </BarChartBar>
          </BarChartContainer>
          <PercentageLabelContainer style={{ marginTop: '5px' }}>
            <span>{formatAsPercentage(item.percentage)}%</span>
          </PercentageLabelContainer>
        </React.Fragment>
      ))}
    </>
  )
}
