import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
  createSearchParams,
} from 'react-router-dom'
import DisproportionateAttentionMap from './disproportionateAttentionMap.screen'
import useAsync from 'react-use/lib/useAsync'
import pMap from 'p-map'
import canvasDrawToURL from '~/prix/utils/canvasDrawToURL'
import {
  Filter,
  GeoChildrenLegalEntitiesPossibilities,
  GeoLegalEntitiesPossibilities,
  geoQueries,
  Level,
} from './disproportionateAttentionMapLevels.data'
import useAPI from '~/prix/react/hooks/api'
import { AppError, errors } from '~/prix'
import useItems from '~/prix/react/hooks/items'
import userAuthStateQuery from '~/packages/user/userAuthState.query'
import { useKeycloak } from '~/components/keycloak'
import useDisproportionateAttentionBreadcrumbs from './disproportionateAttentionBreadcrumbs.hook'

interface SearchParams {
  filterCnaeId?: string
  filterCnaeName?: string
  filterSize?: string
  filterAttendancePeriod?: string
  filterAttendanceSource?: string
  filterEadOption?: string
  filterCourseProductCode?: string
  filterCourseProductName?: string
  mapType?: string
  visualizationType?: string
}

export const geoQueriesAux = {
  country: 'countries',
  macroRegion: 'macroRegions',
  state: 'states',
  mesoRegion: 'mesoRegions',
  microRegion: 'microRegions',
  city: 'cities',
  neighborhood: 'neighborhoods',
  street: 'streets',
  legalEntity: 'legalEntities',
}
const geoQueriesIdAux = {
  country: 'countryId',
  macroRegion: 'macroRegionId',
  state: 'stateId',
  mesoRegion: 'mesoRegionId',
  microRegion: 'microRegionId',
  city: 'cityId',
  neighborhood: 'cityId',
  street: 'streetId',
  legalEntity: 'legalEntityId',
}

export default function LegalEntityGeoprocessingMapFromURLScreen() {
  const { pathname: currentPathname } = useLocation()
  const navigate = useNavigate()
  const params = useParams()
  const [searchParams] = useSearchParams()
  const [level, setLevel] = useState<Level | null>(null)
  const [error, setError] = useState<AppError>()

  const auth = useKeycloak()

  const temporaryCountryId = '30'

  const { context, isLoading } = useAPI()
  const hasUserRoles = context.user
    ? !context.user?.roles.some(
        item => item === 'systemAdministrator' || item === 'administrator' || item === 'manager',
      )
    : false

  useEffect(() => {
    const error = errors.auth()
    if (auth.token === null && error) {
      setError(error)
    } else {
      setError(undefined)
    }
  }, [auth])

  const userGeoStateResult = useItems(
    () =>
      context.user?.stateAbbreviation
        ? userAuthStateQuery(context.user?.stateAbbreviation)
        : (null as never),
    [params.id, context.user, isLoading],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: isLoading === false && error === undefined,
    },
  )

  const searchGeoStateOption = useItems(
    () =>
      params.by && hasUserRoles
        ? geoQueries[geoQueriesAux[params.by as keyof typeof geoQueriesAux]](
            geoQueriesIdAux[params.by as unknown as keyof typeof geoQueriesIdAux],
            params.id,
          )
        : (null as never),
    [params.id, params.by, hasUserRoles],
    {
      cache: 60 * 60 * 12,
      autoLoad: !!params.id && hasUserRoles === true && error === undefined,
    },
  )

  useEffect(() => {
    const stateId =
      level && level.geo !== 'state' && searchGeoStateOption.items
        ? searchGeoStateOption.items[0]?.stateId
        : level && level.geo === 'state' && searchGeoStateOption.items
        ? searchGeoStateOption.items[0]?.id
        : undefined

    if (
      error === undefined &&
      hasUserRoles === true &&
      stateId &&
      userGeoStateResult.items &&
      userGeoStateResult.items[0] &&
      Number(stateId.toString().length) === 2 &&
      userGeoStateResult.items[0]?.stateId !== stateId
    ) {
      const error = errors.permission()
      setError(error)
    }
  }, [level, userGeoStateResult, searchGeoStateOption, hasUserRoles, error])

  useEffect(() => {
    if (hasUserRoles === true && (params.by === 'country' || params.by === 'macroRegion')) {
      const error = errors.permission()
      setError(error)
    }

    if (params.by && params.id) {
      setLevel({
        geo: params.by as GeoLegalEntitiesPossibilities,
        id: params.id,
        childrenGeoLevel: params.childrenGeoLevel as
          | GeoChildrenLegalEntitiesPossibilities
          | undefined,
      })
      return
    }

    if (
      hasUserRoles === true &&
      userGeoStateResult &&
      userGeoStateResult.items &&
      !params.by &&
      !params.id
    ) {
      setLevel({
        geo: 'state',
        id: String(userGeoStateResult.items[0]?.stateId),
        childrenGeoLevel: undefined,
      })
      return
    }

    setLevel({
      geo: 'country',
      id: temporaryCountryId,
      childrenGeoLevel: params.childrenGeoLevel as
        | GeoChildrenLegalEntitiesPossibilities
        | undefined,
    })
  }, [params, hasUserRoles, userGeoStateResult.items])

  const breadcrumbsResult = useDisproportionateAttentionBreadcrumbs({
    geo: level?.geo ?? 'country',
    id: level?.id ?? null,
    roles: hasUserRoles,
  })

  const [validLevel, selectedLegalEntityId, title] = useMemo(() => {
    const lastGeoLevel = breadcrumbsResult.items
      ? breadcrumbsResult.items[breadcrumbsResult.items.length - 1] ?? {
          geo: 'country',
          id: null,
        }
      : null
    if (level?.geo === 'legalEntity' && !breadcrumbsResult.isLoading && breadcrumbsResult.items) {
      return [lastGeoLevel, level.id, undefined]
    }
    if (level?.geo === 'legalEntity') {
      return [null, level.id, undefined]
    }
    return [level, null, lastGeoLevel?.title]
  }, [level, breadcrumbsResult])

  const breadcrumbs = useMemo(
    () =>
      breadcrumbsResult.items && breadcrumbsResult.items.length
        ? breadcrumbsResult.items
        : [
            {
              ...(validLevel ?? { geo: 'country', id: null }),
              title: '',
              subTitle: '',
            },
          ],
    [breadcrumbsResult, validLevel],
  )

  const handleNavigate = useCallback(
    (newLevel: Level | null = null, newSearchParams: SearchParams = {}) => {
      const newLevelOrPrevious = newLevel ?? level

      let pathname = currentPathname
      if (newLevelOrPrevious && newLevelOrPrevious.geo === 'country' && !newLevelOrPrevious.id) {
        pathname = `/app/disproportionate-attention${
          newLevelOrPrevious.childrenGeoLevel
            ? `/${newLevelOrPrevious.geo}/${temporaryCountryId}/${newLevelOrPrevious.childrenGeoLevel}`
            : ''
        }`
      } else if (newLevelOrPrevious) {
        pathname = `/app/disproportionate-attention/${newLevelOrPrevious.geo}/${
          newLevelOrPrevious.id
        }${newLevelOrPrevious.childrenGeoLevel ? `/${newLevelOrPrevious.childrenGeoLevel}` : ''}`
      }

      const paramsObject = {
        filterCnaeId: searchParams.get('filterCnaeId') || '',
        filterCnaeName: searchParams.get('filterCnaeName') || '',
        filterSize: searchParams.get('filterSize') || '',
        filterAttendancePeriod: searchParams.get('filterAttendancePeriod') || '',
        filterAttendanceSource: searchParams.get('filterAttendanceSource') || '',
        filterEadOption: searchParams.get('filterEadOption') || '',
        filterCourseProductCode: searchParams.get('filterCourseProductCode') || '',
        filterCourseProductName: searchParams.get('filterCourseProductName') || '',
        ...newSearchParams,
      }

      Object.keys(paramsObject).forEach(key => {
        if (!paramsObject[key as keyof typeof paramsObject]) {
          delete paramsObject[key as keyof typeof paramsObject]
        }
      })

      const search = createSearchParams(paramsObject)
      navigate({
        pathname,
        search: search.toString(),
      })
    },
    [level, searchParams, currentPathname, hasUserRoles],
  )

  const handleChangeLevel = useCallback(
    ({ geo, id, childrenGeoLevel }: Level) => {
      handleNavigate({ geo, id, childrenGeoLevel })
    },
    [handleNavigate],
  )
  const handleChangeChildrenGeoLevel = useCallback(
    (childrenGeoLevel: GeoChildrenLegalEntitiesPossibilities | undefined) => {
      handleNavigate({ geo: level?.geo ?? 'country', id: level?.id ?? null, childrenGeoLevel })
    },
    [handleNavigate, level],
  )
  // Filter
  const filter = useMemo(() => {
    const filterCnaeId = searchParams.get('filterCnaeId')
    const filterCnaeName = searchParams.get('filterCnaeName')
    const filterSize = searchParams.get('filterSize')
    const filterAttendancePeriod = searchParams.get('filterAttendancePeriod')
    const filterAttendanceSource = searchParams.get('filterAttendanceSource')
    const filterEadOption = searchParams.get('filterEadOption')
    const filterCourseProductCode = searchParams.get('filterCourseProductCode')
    const filterCourseProductName = searchParams.get('filterCourseProductName')

    return filterCnaeId ||
      filterCnaeName ||
      filterSize ||
      filterAttendancePeriod ||
      filterAttendanceSource
      ? ({
          cnaeId: filterCnaeId,
          cnaeName: filterCnaeName,
          size: filterSize,
          attendancePeriod: filterAttendancePeriod,
          attendanceSource: filterAttendanceSource,
          eadOption: filterEadOption,
          courseProductCode: filterCourseProductCode,
          courseProductName: filterCourseProductName,
        } as Filter)
      : null
  }, [searchParams])

  const handleFilterChange = useCallback(
    (filter: Filter | null) => {
      handleNavigate(undefined, {
        filterCnaeId: filter?.cnaeId,
        filterCnaeName: filter?.cnaeName,
        filterSize: filter?.size,
        filterAttendancePeriod: filter?.attendancePeriod,
        filterAttendanceSource: filter?.attendanceSource,
        filterEadOption: filter?.eadOption,
        filterCourseProductCode: filter?.courseProductCode,
        filterCourseProductName: filter?.courseProductName,
      })
    },
    [searchParams, handleNavigate],
  )

  // Map type
  const mapTypeId = (searchParams.get('mapType') as 'satellite' | 'map') || 'satellite'
  const handleMapTypeIdChange = useCallback(
    (mapTypeId: 'satellite' | 'map') => {
      handleNavigate(undefined, {
        mapType: mapTypeId,
      })
    },
    [searchParams, handleNavigate],
  )

  //Canvas
  const markerVariationsCount = 11
  const markerVariations = useAsync(
    () =>
      pMap(Array.from(Array(markerVariationsCount)), () =>
        canvasDrawToURL(
          context => {
            context.fillStyle = '#FFF'
            context.arc(15, 15, 12, 0, 2 * Math.PI)
            context.globalAlpha = 0.65
            context.fill()
            context.strokeStyle = '#fff'
            context.lineWidth = 3
            context.stroke()
          },
          { width: 30, height: 30 },
        ),
      ),
    [markerVariationsCount, level],
  )

  const getMarkerVariationForValue = useCallback(
    value =>
      markerVariations.value && markerVariations.loading === false
        ? markerVariations.value[
            Math.min(
              Math.max(0, Math.floor(value * (markerVariationsCount - 1))),
              markerVariationsCount - 1,
            )
          ]
        : null,
    [markerVariations.value, markerVariations.loading, markerVariationsCount],
  )

  return (
    <DisproportionateAttentionMap
      title={title}
      breadcrumbs={breadcrumbs}
      selectedLegalEntityId={selectedLegalEntityId}
      mapTypeId={mapTypeId}
      onMapTypeIdChange={handleMapTypeIdChange}
      filter={filter}
      onFilterChange={handleFilterChange}
      level={validLevel}
      onLevelChange={handleChangeLevel}
      onChildrenGeoLevelChange={handleChangeChildrenGeoLevel}
      markerVariationForValue={getMarkerVariationForValue}
      markerVariationLoading={markerVariations.loading}
      userGeoState={userGeoStateResult}
      hasUserRoles={hasUserRoles}
      error={error}
    />
  )
}
