import {
  defineAction,
  object,
  string,
} from '~/prix'
import getNotificationSettings from './getNotificationSettings.action.json'

const getNotificationSettingsDefinition = defineAction({
  ...getNotificationSettings,
  input: object({}),
  output: object({}),
})

export default getNotificationSettingsDefinition