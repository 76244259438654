import React from 'react'
import styled from 'styled-components'
import Handle from '~/prix/react/components/handle'
import useItem from '~/prix/react/hooks/item'
import { formatAsBrIntegerNumber } from '~/prix/utils/types/number'
import individualQuery from '../queries/individual.query'

const Items = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--p-default, #005EB8);
  box-shadow : 0px 32px 30px rgba(20, 46, 82, 0.20);
  padding: 22px 12px;
  border-radius: 0 0 12px 12px;
  height: 100%;
  justify-content: center;
  min-height: 119px;
`

const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;

  margin-bottom: 10px;

  > span {
    width: 50%;
  }

  @media (min-width: 1025px) {
    align-items: center;
    gap: 20px;
    padding: 0 12px;
  }

  .first {
    color: #FFF;
    font-weight: 700;
    font-size: 33px;
  }

  .second {
    color: #FFF;
    font-size: 13px;
    font-weight: 400;

    @media (max-width: 1160px) {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`

export default function DashboardGridItemIndividual({
  userGeoStateAbbreviation,
  dataSourceId,
}: {
  userGeoStateAbbreviation?: string | null
  dataSourceId?: number
}) {
  const totalIndividual = useItem(
    () => individualQuery(userGeoStateAbbreviation ? userGeoStateAbbreviation : undefined),
    [userGeoStateAbbreviation],
    { cache: 60 * 60 * 24 },
  )

  return (
    <Handle isLoading={totalIndividual.isLoading} error={totalIndividual.error} style={{ minHeight: '100px' }}>
      <Items>
        {!userGeoStateAbbreviation ? (
          <Item>
            <span className='first'>
              {formatAsBrIntegerNumber(Number(totalIndividual.item?.total))}
            </span>
            <span className='second'>Pessoas físicas cadastradas</span>
          </Item>
        ) : null}
        <Item>
          <span className='first'>
            {formatAsBrIntegerNumber(Number(totalIndividual.item?.totalLegalEntity))}
          </span>
          <span className='second'>Pessoas físicas vinculadas à empresas</span>
        </Item>
      </Items>
    </Handle>
  )
}
