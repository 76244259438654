import { dateTime, defineEntity, enumerated, object, string } from '~/prix'

const userLog = defineEntity({
  key: 'userLog',
  title: 'Logs de Usuário',
  sources: ['olap', 'oltp'],
  schema: object({
    id: string().label('ID'),
    userId: string().label('ID de usuário'),
    actionKey: string().label('Identificador da ação requisitada.'),
    payload: object({}).label('Dados da requisição'),
    status: enumerated({ error: 'error', success: 'success' }),
    messageError: string().label('Mensagem de erro'),
    stackTrace: string().label('Trace do erro'),
    responseSource: enumerated({ database: 'database', cache: 'cache' }),
    responseTimeMs: string().label('Tempo de resposta em millisegundos'),
    timestamp: dateTime().label('Momento da requisição'),
  }),
})

export default userLog
