import chroma from 'chroma-js'
import format from 'date-fns/format'
import * as math from 'mathjs'
import React, { useCallback, useMemo } from 'react'
import Holidays from 'date-holidays'
import { colors } from '~/design'
import { capitalizeWords, mapRange, notNullNaNOrUndefined } from '~/prix'
import { BottomAxis, BottomAxisProps } from '~/prix/react/components/lineChart/bottomAxis'
import { Series } from '~/prix/react/components/lineChart/lineChart'
import LineChartWithScroll from '~/prix/react/components/lineChart/lineChartWithScroll'
import { parseDateTime } from '~/prix/types/dateTime'
import { TimeSeriesItem } from './timeSeries.entity'
import styled from 'styled-components'

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const Ball = styled.div<{ color?: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`

type Representation = 'year' | 'month' | 'weekday' | 'holiday' | 'dayOfMonth' | 'dayOfYear'
export interface TimeSeriesCalendarEffectBottomChartProps {
  items: TimeSeriesItem[]
  representation: Representation
  height: number
  maxSeries?: number
  aggregation?: 'sum' | 'mean' | 'median' | 'min' | 'max',
  isCompressed?: boolean | undefined | null
}

type Item = {
  date: Date
  granularRepresentation: string | null
  groupRepresentation: string | null
  sortIndex: number | null
  index: number
  values: (number | null)[]
}

const holidays = new Holidays('BR')
const currentHolidays = holidays.getHolidays(new Date().getFullYear())

function getGranularRepresentationForDate(date: Date, representation: Representation) {
  switch (representation) {
    case 'year':
      return format(date, 'yyyy')
    case 'month':
      return format(date, 'MM')
    case 'weekday':
      return format(date, 'iiii')
    case 'holiday':
      const holidayResults = holidays.isHoliday(date)
      if (!holidayResults) {
        return null
      }

      const holidayOnCurrentYear = currentHolidays.find(h =>
        holidayResults.some(holiday => holiday.name === h.name),
      )
      return holidayOnCurrentYear ? holidayOnCurrentYear.name : null
    case 'dayOfMonth':
      return format(date, 'dd')
    case 'dayOfYear':
      return format(date, 'DD', { useAdditionalDayOfYearTokens: true })
    default:
      throw new Error('Invalid representation')
  }
}

function getSortRepresentationForDate(date: Date, representation: Representation): number | null {
  switch (representation) {
    case 'year':
      return Number(format(date, 'y'))
    case 'month':
      return Number(format(date, 'M'))
    case 'weekday':
      return Number(format(date, 'i'))
    case 'holiday':
      const holidayResults = holidays.isHoliday(date)
      if (!holidayResults) {
        return null
      }

      const holidayOnCurrentYear = currentHolidays.findIndex(h =>
        holidayResults.some(holiday => holiday.name === h.name),
      )
      return holidayOnCurrentYear >= 0 ? holidayOnCurrentYear : null
    case 'dayOfMonth':
      return Number(format(date, 'd'))
    case 'dayOfYear':
      return Number(format(date, 'D', { useAdditionalDayOfYearTokens: true }))
    default:
      throw new Error('Invalid representation')
  }
}

function getGroupRepresentationForDate(date: Date, representation: Representation) {
  switch (representation) {
    case 'year':
      return 'all'
    case 'month':
      return format(date, 'yyyy')
    case 'weekday':
      return format(date, 'II (yyyy)')
    case 'holiday':
      return format(date, 'yyyy')
    case 'dayOfMonth':
      return format(date, 'MM/yyyy')
    case 'dayOfYear':
      return format(date, 'yyyy')
    default:
      throw new Error('Invalid representation')
  }
}

function getXForRepresentation(representation: Representation) {
  switch (representation) {
    case 'month':
      return new Map([
        [1, 'Janeiro'],
        [2, 'Fevereiro'],
        [3, 'Março'],
        [4, 'Abril'],
        [5, 'Maio'],
        [6, 'Junho'],
        [7, 'Julho'],
        [8, 'Agosto'],
        [9, 'Setembro'],
        [10, 'Outubro'],
        [11, 'Novembro'],
        [12, 'Dezembro'],
      ])
    case 'weekday':
      return new Map([
        [1, 'Domingo'],
        [2, 'Segunda-feira'],
        [3, 'Terça-feira'],
        [4, 'Quarta-feira'],
        [5, 'Quinta-feira'],
        [6, 'Sexta-feira'],
        [7, 'Sábado'],
      ])
    case 'holiday':
      return new Map(
        currentHolidays.map((holiday, index) => [
          index,
          holiday.name.replace(/dia d[oea]s? /i, '').replace('V[eé]spera de ', 'V. '),
        ]),
      )
    default:
      return null
  }
}

function TimeSeriesLatestImpactEffectBottomChart({
  representation,
  items,
  height,
  maxSeries = 3,
  aggregation = 'sum',
  isCompressed,
}: TimeSeriesCalendarEffectBottomChartProps) {
  const originalItems = useMemo(() => {
    return items.filter(item => item.value !== null)
  }, [items])
  const seriesOfItems = useMemo(() => {
    const seriesOfItems: Item[][] = []
    let currentItems: Item[] = []
    let currentSeriesKey = null

    for (let index = originalItems.length - 1; index >= 0; index--) {
      const item = originalItems[index]
      const date = parseDateTime(item.date)
      const granularRepresentation = getGranularRepresentationForDate(date, representation)
      const seriesKey = getGroupRepresentationForDate(date, representation)
      const sortRepresentation = getSortRepresentationForDate(date, representation)

      if (granularRepresentation === null || seriesKey === null || sortRepresentation === null) {
        continue
      }

      const newItem = {
        date,
        granularRepresentation,
        groupRepresentation: seriesKey,
        sortIndex: sortRepresentation,
        index,
        values: [item.value !== null ? Number(item.value) : null],
      }

      if (currentSeriesKey === null || currentSeriesKey === seriesKey) {
        currentItems[sortRepresentation] = {
          ...newItem,
          values: currentItems[sortRepresentation]
            ? [...currentItems[sortRepresentation].values, ...newItem.values]
            : newItem.values,
        }
        currentSeriesKey = seriesKey
      } else {
        if (seriesOfItems.length === maxSeries) {
          break
        }
        seriesOfItems.push(currentItems)
        currentItems = []
        currentItems[sortRepresentation] = newItem
        currentSeriesKey = seriesKey
      }
    }

    return seriesOfItems
      .map(itemsOfSameRepresentation =>
        itemsOfSameRepresentation.map(({ values, ...item }) => ({
          ...item,
          value: Number(math[aggregation](values.filter(notNullNaNOrUndefined))),
        })),
      )
      .reverse()
  }, [originalItems, representation, aggregation, maxSeries])

  const { indexes, labels } = useMemo(() => {
    const candidateMap = getXForRepresentation(representation)
    if (candidateMap) {
      return {
        indexes: Array.from(candidateMap.keys()),
        labels: Array.from(candidateMap.values()),
      }
    }
    const xLengthWithoutFilter = Math.max(...seriesOfItems.map(current => current.length))
    const indexes = seriesOfItems
      .find(item => item.length === xLengthWithoutFilter)
      ?.map((value, index) => index)
      .filter(notNullNaNOrUndefined)
    if (!indexes) {
      return {
        indexes: [],
        labels: [],
      }
    }
    const labels = indexes.map(index => String(index))
    return {
      indexes,
      labels,
    }
  }, [seriesOfItems, representation])

  const series = useMemo(() => {
    return seriesOfItems.map((seriesOfItem, index) => {
      let labelDescription = ''
      const color = chroma
        .mix(colors.sebraeBlue, "#b6bfe7", mapRange(index, 0, maxSeries, 1, 0.01), 'lab')
        .rgba()
      const colorString = `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`
      switch (representation) {
        case 'weekday':
          labelDescription = `Dados de ${format(seriesOfItem[1].date, 'dd/MM/yy')} à ${format(seriesOfItem[seriesOfItem.length - 1].date, 'dd/MM/yy')}  com Ocorrências`
          break
        case 'dayOfYear':
          labelDescription = `Dados de ${format(seriesOfItem[1].date, 'yyyy')} com Ocorrências`
          break
        case 'holiday':
          labelDescription = `Dados de ${format(seriesOfItem[1].date, 'yyyy')} com Ocorrências`
          break
        default:
          labelDescription = `Dados de ${format(seriesOfItem[1].date, 'dd/MM/yy')} à ${format(seriesOfItem[seriesOfItem.length - 1].date, 'dd/MM/yy')}  com Ocorrências`
          break
      }
      const currentSeries: Series = {
        label: (
          <LabelContainer>
            <Ball color={colorString} />
            {labelDescription}
          </LabelContainer>
        ),
        marker: {
          color: colorString,
          radius: 5,
        },
        line:
          representation !== 'holiday'
            ? {
              color: colorString,
              width: 3,
              curve: 'curveMonotoneX',
            }
            : null,
      }
      return currentSeries
    })
  }, [seriesOfItems.length, maxSeries, representation])

  const chartItems: Array<{
    values: (number | null)[]
  }> = useMemo(() => {
    return indexes.map(index => {
      return {
        date: null,
        values: seriesOfItems.map(seriesOfItems => seriesOfItems[Number(index)]?.value ?? null),
      }
    })
  }, [seriesOfItems, indexes])

  const renderBottomAxis = useCallback(
    (props: BottomAxisProps) => {
      return (
        <BottomAxis
          {...props}
          getLabel={(index, itemWidth) => {
            const capitalized = capitalizeWords(labels[index])
            if (itemWidth >= 70) {
              if (capitalized.length > 10) {
                return capitalized.substring(0, 10) + '...'
              }
              return capitalized
            }
            const result = capitalized.replace('D. ', '').substring(0, 3)
            return result
          }}
        />
      )
    },
    [labels],
  )

  if (chartItems.length === 0) {
    return (<div>Dados insuficientes para gerar a série temporal.</div>)
  }

  return (
    <LineChartWithScroll
      itemWidth={representation === 'holiday' ? 100 : !isCompressed ? undefined : 35}
      height={height}
      items={chartItems}
      series={series}
      BottomAxis={renderBottomAxis}
    />
  )
}

export default React.memo(TimeSeriesLatestImpactEffectBottomChart)