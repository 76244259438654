import config from '../../../utils/client/publicConfig'

interface IPublicOneSignal {
  appId: string
  androidAppId: string
  iosAppId: string
}

interface IPublicService {
  oneSignal: IPublicOneSignal
  googleConfig: {
    apiKey: string
    googleSiteVerification: string
    recaptchaSiteKey: string
  }
  mapbox: {
    token: string
  }
}

interface IPublicConfig {
  services: IPublicService
}

export default function useConfig(): IPublicConfig {
  return config()
}
