import React, { memo, useMemo } from 'react'
import { View, StyleSheet, Image } from '@react-pdf/renderer'
import circle from '@turf/circle'
import useConfig from '~/prix/react/hooks/config'

const styles = StyleSheet.create({
  mapContainer: {
    display: 'flex',
    height: '25%',
    marginLeft: 20,
    marginRight: 20,
  },

  image: {
    width: '100%',
    height: '100%',
  },
})

function legalEntityGeoprocessingMapDemarcationReportMapPdf({
  feature,
  isPolygon,
  isMultiPolygon,
  isRadius,
  isPointWithoutRadius: isPointWithoutRadius,
}: {
  feature: any
  isPolygon: boolean
  isMultiPolygon: boolean
  isRadius: boolean
  isPointWithoutRadius: boolean
}) {
  const config = useConfig()
  const apiKey = config.services.googleConfig.apiKey

  const multiPolygonsCities = feature?.features?.map(
    (item: { geometry: { coordinates: any[][] } }) => {
      return item.geometry.coordinates[0].flat()
    },
  )

  const polygonCitiesArrayReverseCoordinates = multiPolygonsCities
    ? multiPolygonsCities?.map((polygon: any[]) => {
        const result = polygon.map(coordinates => [coordinates[1], coordinates[0]])
        return result
      })
    : null

  const convertToFormattedType = (coordinates: number[][][]): string[] => {
    const formattedCoordinates: string[] = []

    for (const group of coordinates) {
      const groupCoordinates: string[] = []

      for (const coordPair of group) {
        const [lat, lon] = coordPair
        groupCoordinates.push(`${lat},${lon}`)
      }

      formattedCoordinates.push(groupCoordinates.join('|'))
    }

    return formattedCoordinates
  }

  const formattedString: string[] | null =
    feature?.features?.length > 0
      ? convertToFormattedType(polygonCitiesArrayReverseCoordinates)
      : null

  const mapper = formattedString
    ? formattedString?.map(item => {
        return `${item}&path=color:0xFFFFFF|weight:3|fillcolor:0x96ceff`
      })
    : null

  const mapperJoin = mapper?.join('|')

  const values = useMemo(() => {
    if (isPolygon === true) {
      return feature?.geometry.coordinates
    }

    if (isMultiPolygon === true) {
      return feature?.features[0]?.geometry.coordinates
    }
  }, [isPolygon, isMultiPolygon])

  if (isPolygon || isMultiPolygon) {
    // Tratamento para multi-polígonos
    const longestPolygon = values.reduce((a: string | any[], b: string | any[]) => {
      return a.length > b.length ? a : b
    })

    const arrayValues = isMultiPolygon ? longestPolygon[0] : longestPolygon

    const polygonArrayReverse = longestPolygon
      ? arrayValues?.map((item: any[]) => {
          return [item[1], item[0]]
        })
      : null

    const polygonJoin = polygonArrayReverse?.join('|')
    const imageUrl = `https://maps.googleapis.com/maps/api/staticmap?size=600x300&maptype=satellite&path=color:0xFFFFFF|weight:3|fillcolor:0x96ceff|${
      isPolygon ? polygonJoin : mapperJoin
    }&key=${apiKey}`

    return (
      <View style={styles.mapContainer}>
        <Image style={styles.image} src={imageUrl} />
      </View>
    )
  }

  if (isRadius) {
    const center = feature.geometry.coordinates
    const radius = feature.properties.radius
    const circleCoordinates = circle(center, radius, { steps: 100, units: 'meters' })

    const circleArrayReverse = circleCoordinates.geometry.coordinates[0]?.map(item => {
      return [item[1], item[0]]
    })

    const circleJoin = circleArrayReverse.join('|')
    const imageUrl = `https://maps.googleapis.com/maps/api/staticmap?size=600x300&maptype=satellite&path=color:0xFFFFFF|weight:3|fillcolor:0x96ceff|${circleJoin}&key=${apiKey}`

    return (
      <View style={styles.mapContainer}>
        <Image style={styles.image} src={imageUrl} />
      </View>
    )
  }

  if (isPointWithoutRadius) {
    const latitude = feature.features[0].geometry.coordinates[1]
    const longitude = feature.features[0].geometry.coordinates[0]
    const zoom = 14

    const imageUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=${zoom}&size=600x300&maptype=satellite&markers=color:blue%7C${latitude},${longitude}&key=${apiKey}`

    return (
      <View style={styles.mapContainer}>
        <Image style={styles.image} src={imageUrl} />
      </View>
    )
  }
  return <View style={styles.mapContainer} />
}

export default memo(legalEntityGeoprocessingMapDemarcationReportMapPdf)
