import { defineEntity, object, string } from '~/prix'

const companyEad = defineEntity({
  key: 'empresa',
  title: 'EaD empresa',
  sources: ['oltp'],
  entitySchema: 'ead',
  schema: object({
    cnpj: string(),
    razaoSocial: string(),
    nomeFantasia: string(),
    porte: string(),
    dataInicioAtividade: string(),
    cnaePrincipal: string(),
    tipoLogradouro: string(),
    logradouro: string(),
    numero: string(),
    complemento: string(),
    bairro: string(),
    cep: string(),
    municipio: string(),
    uf: string(),
    pais: string(),
    telefone1: string(),
    telefone2: string(),
    dataCriacao: string(),
    dataAtualizacao: string(),
  }),
})

export default companyEad
