import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Keycloak, { KeycloakInitOptions, KeycloakTokenParsed } from 'keycloak-js'
import { AppError, errors } from '~/prix'

export interface KeycloakProviderProps {
  keycloak: Keycloak
  autoRefreshOnExpire?: boolean
  autoRefreshBeforeExpire?: boolean
  refreshSecondsBeforeExpire?: number
  checkTokenSeconds?: number
  initOptions?: KeycloakInitOptions
  children: React.ReactNode
}

export type KeycloakContext = {
  login: () => Promise<void>
  logout: () => Promise<void>
  keycloak: Keycloak
  isLoading: boolean
  bypassAuth: boolean
  isAuthenticated: boolean | null
  error: AppError | null
  token: string | null
  tokenParsed: KeycloakTokenParsed | null
  initError: AppError | null
  authError: AppError | null
  authRefreshError: AppError | null
}

const bypassAuth = process.env.BYPASS_AUTH as unknown as boolean
const keycloakContext = createContext<KeycloakContext>({
  login: async () => {},
  logout: async () => {},
  keycloak: null as any,
  isLoading: false,
  bypassAuth,
  isAuthenticated: null,
  error: null,
  token: null,
  tokenParsed: null,
  initError: null,
  authError: null,
  authRefreshError: null,
})

const bypassAuthPayload: KeycloakTokenParsed = {
  sub: 'bypass',
  email: 'bypass@prix.tech',
  name: 'Bypass',
  email_verified: true,
  resource_access: {
    'prix-tech': {
      roles: ['ADMINISTRADOR'],
    },
  },
  uf: 'SC',
  upn: '12345678901',
}

const defaultInitOptions: KeycloakInitOptions = { onLoad: 'check-sso' }
export function KeycloakProvider({
  children,
  keycloak,
  initOptions = defaultInitOptions,
  autoRefreshOnExpire = true,
  autoRefreshBeforeExpire = true,
  checkTokenSeconds = 30,
  refreshSecondsBeforeExpire = 60,
}: KeycloakProviderProps) {
  const [token, setToken] = useState<string | null>(null)
  const [tokenParsed, setTokenParsed] = useState<KeycloakTokenParsed | null>(null)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [initError, setInitError] = useState<AppError | null>(null)
  const [authError, setAuthError] = useState<AppError | null>(null)
  const [authRefreshError, setAuthRefreshError] = useState<AppError | null>(null)

  useEffect(() => {
    if (bypassAuth) {
      return
    }

    setAuthError(null)
    const handleUpdateAuth = () => {
      setAuthRefreshError(null)
      setToken(keycloak.token ?? null)
      setTokenParsed(keycloak.tokenParsed ?? null)
      setIsAuthenticated(keycloak.authenticated ?? null)
    }
    keycloak.onAuthRefreshSuccess = handleUpdateAuth
    keycloak.onAuthSuccess = handleUpdateAuth
    keycloak.onAuthLogout = handleUpdateAuth
    keycloak.onReady = handleUpdateAuth
    keycloak.onAuthError = error => {
      setAuthError(
        errors.auth('Erro de autenticação', undefined, {
          message: error.error_description,
          name: error.error,
        }),
      )
    }
    keycloak.onAuthRefreshError = () => {
      setAuthRefreshError(errors.auth('Erro atualizando a autenticação'))
    }
    keycloak.onTokenExpired = () => {
      if (autoRefreshOnExpire) {
        keycloak.updateToken(refreshSecondsBeforeExpire)
      }
    }

    return () => {
      keycloak.onAuthError = undefined
      keycloak.onAuthRefreshError = undefined
      keycloak.onTokenExpired = undefined
      keycloak.onAuthRefreshSuccess = undefined
      keycloak.onAuthSuccess = undefined
      keycloak.onAuthLogout = undefined
      keycloak.onReady = undefined
    }
  }, [keycloak, refreshSecondsBeforeExpire])

  useEffect(() => {
    setIsLoading(true)
    setInitError(null)
    setIsAuthenticated(null)

    if (bypassAuth) {
      const isBypassAuthenticated = !!localStorage.getItem('isBypassAuthenticated')

      if (isBypassAuthenticated) {
        setIsAuthenticated(isBypassAuthenticated)
        setToken('bypass')
        setTokenParsed(bypassAuthPayload)
        setIsLoading(false)
      } else {
        setIsAuthenticated(false)
        setToken(null)
        setTokenParsed(null)
        setIsLoading(false)
      }
      return
    }

    let isStopped = false
    keycloak
      .init(initOptions)
      .then(isAuthenticated => {
        if (isStopped) return
        setToken(keycloak.token ?? null)
        setTokenParsed(keycloak.tokenParsed ?? null)
        setIsAuthenticated(isAuthenticated)
      })
      .catch(error => {
        if (isStopped) return
        setInitError(
          errors.unknown('Erro inicializando o sistema de autenticação', undefined, error),
        )
      })
      .finally(() => {
        if (isStopped) return
        setIsLoading(false)
      })

    return () => {
      isStopped = true
    }
  }, [keycloak, initOptions])

  useEffect(() => {
    if (!tokenParsed || !autoRefreshBeforeExpire || bypassAuth) {
      return
    }

    const interval = setInterval(() => {
      keycloak.updateToken(refreshSecondsBeforeExpire)
    }, checkTokenSeconds * 1000)
    return () => clearInterval(interval)
  }, [tokenParsed, checkTokenSeconds, autoRefreshBeforeExpire, refreshSecondsBeforeExpire])

  const login = useCallback(() => {
    if (bypassAuth) {
      localStorage.setItem('isBypassAuthenticated', 'true')
      setIsAuthenticated(true)
      setToken('bypass')
      setTokenParsed(bypassAuthPayload)
      return Promise.resolve()
    }

    return keycloak.login()
  }, [keycloak])
  const logout = useCallback(() => {
    if (bypassAuth) {
      localStorage.removeItem('isBypassAuthenticated')
      setIsAuthenticated(false)
      setToken(null)
      setTokenParsed(null)
      return Promise.resolve()
    }

    return keycloak.logout()
  }, [keycloak])

  const error = initError ?? authError ?? authRefreshError ?? null

  const context: KeycloakContext = useMemo(() => {
    return {
      login,
      logout,
      keycloak,
      bypassAuth,
      isLoading,
      isAuthenticated,
      error,
      token,
      tokenParsed,
      initError,
      authError,
      authRefreshError,
    }
  }, [
    login,
    logout,
    keycloak,
    bypassAuth,
    isLoading,
    isAuthenticated,
    error,
    token,
    tokenParsed,
    initError,
    authError,
    authRefreshError,
  ])

  return <keycloakContext.Provider value={context}>{children}</keycloakContext.Provider>
}

export function useKeycloak() {
  return useContext(keycloakContext)
}
