import axios, { Canceler, Method, AxiosError } from 'axios'
import type { Entity } from '../entity'
import { EntityKey } from '../entity'
import errors, { AppError } from '../error'
import { SourceReadOptions, SourceReadResolution } from './source'
import { RunOptions } from './source'
import { Selectables, SelectablesToItem } from '../query'
import cast from '../cast'
import { Context, InferNative, Type } from '..'
import {
  BaseActionDefinitionWithInputOutput,
  BaseActionDefinition,
  Trigger,
  HttpTrigger,
} from '../actionDefinition'

export interface ServerlessSourceOptions {
  baseUrl: string
  entities: Array<Entity<string, any>>
  actionDefinitions: BaseActionDefinitionWithInputOutput<any, any>[]
  timeout?: number
  onError?: (error: Error) => void
  readEventName?: string
}

export default function serverlessSource({
  baseUrl,
  entities,
  actionDefinitions,
  timeout = 60000,
  onError = (error: Error) => console.error(error),
}: ServerlessSourceOptions) {
  const callAction = <
    InputType extends Type,
    OutputType extends Type,
    Input = InferNative<InputType>,
    Output = InferNative<OutputType>,
    CurrentActionDefinition extends BaseActionDefinition = BaseActionDefinition,
  >(
    definition: BaseActionDefinitionWithInputOutput<InputType, OutputType>,
    input: Input,
    context: Context,
    onAbort = () => undefined,
  ) => {
    let isAborted = false

    const firstTrigger = definition.triggers.find(
      trigger => trigger.trigger === 'http',
    ) as HttpTrigger
    if (!firstTrigger) {
      throw errors.incompatible(`Ação "${definition.key}" não pode ser chamada neste método`)
    }

    const headers = {
      Accept: 'application/json',
      requestId: context.requestId,
    }
    const url = `${baseUrl}${firstTrigger.route}`

    let abortRequest: Canceler
    const responsePromise = axios(url, {
      method: firstTrigger.method as Method,
      params: firstTrigger.method === 'GET' ? input : undefined,
      data: firstTrigger.method !== 'GET' ? input : undefined,
      headers: context.token ? { ...headers, Token: context.token } : headers,
      timeout: (definition.runtime.timeout || 15) * 1000,
      cancelToken: new axios.CancelToken(cancel => {
        abortRequest = cancel
      }),
    })

    return {
      run: async ({ onResponse }: RunOptions<Output>) => {
        const finishPromise = new Promise<void>(async (resolve, reject) => {
          try {
            const { data } = await responsePromise

            if (isAborted) {
              return
            }

            onResponse(data)
            resolve()
          } catch (axiosError) {
            const error =
              (axiosError as AxiosError).response?.data &&
              typeof (axiosError as AxiosError).response!.data === 'object'
                ? (axiosError as AxiosError<AppError>).response!.data
                : (axiosError as Error)
            onError(error)
            reject(error)
          }
        })

        return finishPromise
      },
      abort: async () => {
        isAborted = true
        abortRequest()
        onAbort()
      },
    }
  }

  return {
    kind: 'source' as 'source',
    checkAvailability: async () => {
      return navigator.onLine
    },
    call: callAction,
    read: async <
      MainEntityKey extends EntityKey,
      MainEntityKeyAlias extends string,
      Selection extends Selectables = {},
    >({
      query,
      onAbort = () => undefined,
      context,
    }: SourceReadOptions<MainEntityKey, MainEntityKeyAlias, Selection>): Promise<
      SourceReadResolution<MainEntityKey, MainEntityKeyAlias, Selection>
    > => {
      let isAborted = false
      // Entity
      const fromEntity = entities.find(current => current.key === query.entityKey)
      if (!fromEntity) {
        throw errors.incompatible(`Entidade "${query.entityKey}" não existe`)
      }
      const actionDefinition = actionDefinitions.find(
        actionDefinition =>
          actionDefinition.operation === 'read' &&
          actionDefinition.targetEntity === query.entityKey,
      )
      if (!actionDefinition) {
        throw errors.incompatible(`Ação de leitura para entidade "${query.entityKey}" não existe`)
      }
      const { run, abort } = callAction(actionDefinition, query, context)
      return { run, abort } as any
    },
  }
}
