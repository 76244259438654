import React, { memo } from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import { formatAsBrNumber } from '~/prix'

const styles = StyleSheet.create({
  wrapperDemographic: {
    display: 'flex',
    height: '35%',
    paddingLeft: 20,
    paddingRight: 20,
  },

  wrapperEconomic: {
    display: 'flex',
    height: '58.5%',
    paddingLeft: 20,
    paddingRight: 20,
  },

  demographicAndEconomicText: {
    paddingTop: 5,
    fontSize: '15px',
    color: '#0F438A',
    fontFamily: 'Lato-700',
  },

  demographicAndEconomicSubtitle: {
    fontSize: '10px',
    color: '#0F438A',
  },

  cardsDemographicContainer: {
    display: 'flex',
    marginBottom: '10px',
    flexDirection: 'row',
    height: '50%',
  },

  cardsDemographicFirstColumn: {
    display: 'flex',
    width: '45%',
    flexDirection: 'column',
  },

  cardsDemographicSecondColumn: {
    display: 'flex',
    width: '55%',
    flexDirection: 'column',
  },

  cardsEconomicColumn: {
    display: 'flex',
    width: '40%',
    flexDirection: 'column',

  },

  cardTitleContainer: {
    display: 'flex',
    height: 50,
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },

  cardTitle: {
    fontSize: '11px',
    color: '#005EB8',
    fontFamily: 'Lato-700',
  },

  cardContainer: {
    display: 'flex',
    height: '70%',
    flexDirection: 'row',
  },

  card: {
    backgroundColor: '#dfe8f5',
    height: '80px',
    padding: '0px 1px 0px 1px',
    width: '100px',
    marginTop: 10,
    paddingTop: '5px',
  },

  cardRevenueAndCost: {
    backgroundColor: '#dfe8f5',
    height: '80px',
    padding: '0px 1px 0px 1px',
    width: '150px',
    marginTop: 10,
    paddingTop: '5px',
  },

  cardValueContainer: {
    display: 'flex',
    height: '30px',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingBottom: '5px',
  },

  cardValueText: {
    color: '#121921',
    fontSize: '15px',
    textAlign: 'center',
    fontFamily: 'Lato-900',
  },

  cardDescriptionContainer: {
    display: 'flex',
    height: '40px',
    alignItems: 'center',
  },

  cardDescriptionText: {
    color: '#636f7a',
    fontSize: '11px',
    textAlign: 'center',
    lineHeight: 1.25,

  },

  cardDescriptionRevenueAndCostText: {
    color: '#636f7a',
    fontSize: '11px',
    textAlign: 'center',
    lineHeight: 1.25,
  },
})

function legalEntityGeoprocessingMapDemarcationReportDemographicAndEconomicPdf(values: any) {
  const { values: data } = values

  const legalEntityCount = data.result.legalEntityCount
  const legalEntityAttendanceCount = data.result.legalEntityAttendanceCount
  const legalEntityMeiCount = data.result.legalEntityMeiCount
  const legalEntityMicroCount = data.result.legalEntitySizeCount.micro
  const legalEntitySmallCount = data.result.legalEntitySizeCount.small

  const averageRevenue = {
    value: data.result.revenue.value,
    legalEntityCount: data.result.revenue.legalEntityCount
  }

  const averageCost = {
    value: data.result.averageCost.value,
    legalEntityCount: data.result.averageCost.legalEntityCount
  }

  return (
    <View style={averageRevenue?.value !== '' && averageCost.value !== '' ?
      { display: 'flex', height: '93.5%' } :
      { display: 'flex', height: '46.5%' }}>
      <View style={styles.wrapperDemographic}>
        <Text style={styles.demographicAndEconomicText}>
          Dados demográficos
        </Text>
        <Text style={styles.demographicAndEconomicSubtitle}>
          Dados demográficos de todas as empresas que abrangem a área selecionada
        </Text>
        <View style={styles.cardsDemographicContainer}>
          <View style={styles.cardsDemographicFirstColumn}>
            <View style={styles.cardTitleContainer}>
              <Text style={styles.cardTitle}>
                Quantidade de empresas
              </Text>
            </View>
            <View style={styles.cardContainer}>
              <View style={styles.card}>
                <View style={styles.cardValueContainer}>
                  <Text style={styles.cardValueText}>
                    {formatAsBrNumber(legalEntityCount)}
                  </Text>
                </View>
                <View style={styles.cardDescriptionContainer}>
                  <Text style={styles.cardDescriptionText}>
                    Total de empresas na área
                  </Text>
                </View>
              </View>
              <View style={styles.card}>
                <View style={styles.cardValueContainer}>
                  <Text style={styles.cardValueText}>
                    {formatAsBrNumber(legalEntityAttendanceCount)}
                  </Text>
                </View>
                <View style={styles.cardDescriptionContainer}>
                  <Text style={styles.cardDescriptionText}>
                    Empresas
                  </Text>
                  <Text style={styles.cardDescriptionText}>
                    atendidas
                  </Text>
                  <Text style={styles.cardDescriptionText}>
                    em {data.result.currentYear}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.cardsDemographicSecondColumn}>
            <View style={styles.cardTitleContainer}>
              <Text style={styles.cardTitle}>
                Quantidade de empresas por porte
              </Text>
            </View>
            <View style={styles.cardContainer}>
              <View style={styles.card}>
                <View style={styles.cardValueContainer}>
                  <Text style={styles.cardValueText}>
                    {formatAsBrNumber(legalEntityMeiCount)}
                  </Text>
                </View>
                <View style={styles.cardDescriptionContainer}>
                  <Text style={styles.cardDescriptionText}>
                    MEI
                  </Text>
                </View>
              </View>
              <View style={styles.card}>
                <View style={styles.cardValueContainer}>
                  <Text style={styles.cardValueText}>
                    {formatAsBrNumber(legalEntityMicroCount)}
                  </Text>
                </View>
                <View style={styles.cardDescriptionContainer}>
                  <Text style={styles.cardDescriptionText}>
                    Microempresas
                  </Text>
                </View>
              </View>
              <View style={styles.card}>
                <View style={styles.cardValueContainer}>
                  <Text style={styles.cardValueText}>
                    {formatAsBrNumber(legalEntitySmallCount)}
                  </Text>
                </View>
                <View style={styles.cardDescriptionContainer}>
                  <Text style={styles.cardDescriptionText}>
                    Pequeno porte
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>

      {averageRevenue?.value !== '' && averageCost.value !== '' ?
        <View style={styles.wrapperEconomic}>
          <Text style={styles.demographicAndEconomicText}>
            Dados econômicos
          </Text>
          <Text style={styles.demographicAndEconomicSubtitle}>
            Dados econômicos de todas as empresas que abrangem a área selecionada
          </Text>
          <View style={styles.cardsDemographicContainer}>
            <View style={styles.cardsEconomicColumn}>
              <View style={styles.cardTitleContainer}>
                <Text style={styles.cardTitle}>
                  Faturamento médio
                </Text>
              </View>
              <View style={styles.cardRevenueAndCost}>
                <View style={styles.cardValueContainer}>
                  <Text style={styles.cardValueText}>
                    R$ {averageRevenue.value}
                  </Text>
                </View>
                <View style={styles.cardDescriptionContainer}>
                  <Text style={styles.cardDescriptionRevenueAndCostText}>
                    Baseado em
                  </Text>
                  <Text style={styles.cardDescriptionRevenueAndCostText}>
                    {averageRevenue.legalEntityCount} empresas
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.cardsEconomicColumn}>
              <View style={styles.cardTitleContainer}>
                <Text style={styles.cardTitle}>
                  Custo médio
                </Text>
              </View>
              <View style={styles.cardRevenueAndCost}>
                <View style={styles.cardValueContainer}>
                  <Text style={styles.cardValueText}>
                    R$ {averageCost.value}
                  </Text>
                </View>
                <View style={styles.cardDescriptionContainer}>
                  <Text style={styles.cardDescriptionRevenueAndCostText}>
                    Baseado em
                  </Text>
                  <Text style={styles.cardDescriptionRevenueAndCostText}>
                    {averageCost.legalEntityCount} empresas
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        : null}
    </View>
  )
}

export default memo(legalEntityGeoprocessingMapDemarcationReportDemographicAndEconomicPdf)