import { descending, entity, equals, query, string, sum } from '~/prix'

interface CountCityCensusByPopulation {
  groupColumn: string
  id: string
  level: string
}

export default function countCityCensusByPopulation({
  groupColumn,
  id,
  level,
}: CountCityCensusByPopulation) {
  switch (groupColumn) {
    case 'countryId':
      return query('cityCensusByPopulation')
        .select({
          geoId: entity('cityCensusByPopulation').property(groupColumn),
          highlight: sum(entity('cityCensusByPopulation').property('viewPopulation')),
          lastUpdated: entity('cityCensusByPopulation').property('lastUpdated'),
        })
        .where(
          equals(entity('cityCensusByPopulation').property(`${level}Id`), string().value('30')),
        )
        .order(descending('lastUpdated'))

    case 'macroRegionId':
      return query('cityCensusByPopulation')
        .select({
          geoId: entity('cityCensusByPopulation').property(groupColumn),
          highlight: sum(entity('cityCensusByPopulation').property('viewPopulation')),
          lastUpdated: entity('cityCensusByPopulation').property('lastUpdated'),
        })
        .where(equals(entity('cityCensusByPopulation').property(`${level}Id`), string().value(id)))
        .order(descending('lastUpdated'))

    case 'stateId':
      return query('cityCensusByPopulation')
        .select({
          geoId: entity('cityCensusByPopulation').property(groupColumn),
          highlight: sum(entity('cityCensusByPopulation').property('viewPopulation')),
          lastUpdated: entity('cityCensusByPopulation').property('lastUpdated'),
        })
        .where(equals(entity('cityCensusByPopulation').property(`${level}Id`), string().value(id)))
        .order(descending('lastUpdated'))

    case 'mesoRegionId':
      return query('cityCensusByPopulation')
        .select({
          geoId: entity('cityCensusByPopulation').property(groupColumn),
          highlight: sum(entity('cityCensusByPopulation').property('viewPopulation')),
          lastUpdated: entity('cityCensusByPopulation').property('lastUpdated'),
        })
        .where(equals(entity('cityCensusByPopulation').property(`${level}Id`), string().value(id)))
        .order(descending('lastUpdated'))

    case 'microRegionId':
      return query('cityCensusByPopulation')
        .select({
          geoId: entity('cityCensusByPopulation').property(groupColumn),
          highlight: sum(entity('cityCensusByPopulation').property('viewPopulation')),
          lastUpdated: entity('cityCensusByPopulation').property('lastUpdated'),
        })
        .where(equals(entity('cityCensusByPopulation').property(`${level}Id`), string().value(id)))
        .order(descending('lastUpdated'))

    case 'cityId':
      return query('cityCensusByPopulation')
        .select({
          geoId: entity('cityCensusByPopulation').property(groupColumn),
          highlight: entity('cityCensusByPopulation').property('viewPopulation'),
          lastUpdated: entity('cityCensusByPopulation').property('lastUpdated'),
        })
        .where(equals(entity('cityCensusByPopulation').property(`${level}Id`), string().value(id)))
        .order(descending('lastUpdated'))

    case 'neighborhoodId':
      return query('cityCensusByPopulation')
        .select({
          geoId: entity('cityCensusByPopulation').property('cityId'),
          highlight: entity('cityCensusByPopulation').property('viewPopulation'),
          lastUpdated: entity('cityCensusByPopulation').property('lastUpdated'),
        })
        .where(equals(entity('cityCensusByPopulation').property('cityId'), string().value(id)))
        .order(descending('lastUpdated'))
        .limit(1)

    default:
      throw new Error(`Error`)
  }
}
