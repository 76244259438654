import { entity, equals, query, string } from '~/prix'
import asGeoJson from '~/prix/functions/asGeoJson'

export default function neighborhoodsQuery(by: 'cityId', id: string) {
  switch (by) {
    case 'cityId':
      return query('neighborhood')
        .select({
          id: entity('neighborhood').property('id'),
          name: entity('neighborhood').property('name'),
          boundary: asGeoJson(entity('neighborhood').property('lower_quality_boundary')),
          center: asGeoJson(entity('neighborhood').property('center')),
          stateId: entity('state').property('id'),
        })
        .join({
          current: entity('neighborhood').property('cityId'),
          target: entity('city').property('id'),
        })
        .join({
          current: entity('city').property('microRegionId'),
          target: entity('microRegion').property('id'),
        })
        .join({
          current: entity('microRegion').property('mesoRegionId'),
          target: entity('mesoRegion').property('id'),
        })
        .join({
          current: entity('mesoRegion').property('stateId'),
          target: entity('state').property('id'),
        })
        .where(equals(entity('neighborhood').property('cityId'), string().value(id)))
        .limit(10000)
    default:
      throw new Error(`Unknown by: ${by} on neighborhoods query`)
  }
}
