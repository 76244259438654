import { format } from 'date-fns'
import React, { useCallback, useMemo } from 'react'
import { colors } from '~/design'
import { BottomAxis, BottomAxisProps } from '~/prix/react/components/lineChart/bottomAxis'
import { Series } from '~/prix/react/components/lineChart/lineChart'
import LineChartWithScroll from '~/prix/react/components/lineChart/lineChartWithScroll'
import { parseDateTime } from '~/prix/types/dateTime'
import { PolynomialComponent, TimeSeriesItem } from './timeSeries.entity'

const series: Series[] = [
  {
    label: 'Ocorrências estimadas',
    marker: {
      color: colors.sebraeBlue,
      radius: 5,
    },
    line: {
      color: colors.sebraeBlue,
      width: 3,
      curve: 'curveMonotoneX',
    },
  },
]

export interface TimeSeriesTrendBottomChartProps {
  component: PolynomialComponent
  height: number
  items: TimeSeriesItem[]
}

function TimeSeriesTrendBottomChart({ component, height, items }: TimeSeriesTrendBottomChartProps) {
  const chartItems = useMemo(
    () =>
      Array.from(Array(Math.min(component.count, items.length))).map((item, index) => ({
        values: [
          component.coefficients.reduce((acc, coefficient, coefficientIndex) => {
            return acc + coefficient * Math.pow(index, coefficientIndex)
          }, 0),
        ] as number[],
      })) || null,
    [component],
  )

  const renderBottomAxis = useCallback(
    (props: BottomAxisProps) => (
      <BottomAxis
        {...props}
        getLabel={index => {
          if (items[index]) {
            return format(parseDateTime(items[index].date), 'yyyy')
          } else {
            return 'ERRO'
          }
        }}
      />
    ),
    [items],
  )

  return (
    <LineChartWithScroll
      height={height}
      items={chartItems}
      series={series}
      BottomAxis={renderBottomAxis}
      topSpacingForFixedAxis={30}
    />
  )
}

export default React.memo(TimeSeriesTrendBottomChart)
