import { InfoWindow, Marker } from '@react-google-maps/api'
import React, { Fragment, memo, useMemo } from 'react'
import styled from 'styled-components'
import Separator from '~/components/separator'
import { colors } from '~/design'
import { formatAsBrNumber, formatAsPercentage } from '~/prix'
import { Button } from '~/prix/react/components/button'
import GeoJsonMultiPolygon from '~/prix/react/components/map/geoJsonMultiPolygon'
import { levelKeyAux } from './legalEntityGeoprocessingMap.screen'
import { GeoItem } from './legalEntityGeoprocessingMapLevels.data'
import GeoJsonMultiLineString from '~/prix/react/components/map/geoJsonMultiLineString'
import chroma from 'chroma-js'
import { useSearchParams } from 'react-router-dom'
import { isNullOrUndefined } from '~/prix/utils/empty'

interface LegalEntityGeoItemProps extends GeoItem {
  isTouchable: boolean
  isSelected: boolean
  onClick: (item: GeoItem) => void
  onClickNextLevelButton: (item: GeoItem) => void
  onMouseOver: (item: GeoItem) => void
  onMouseOut: (item: GeoItem) => void
  onMouseCenter: (value: boolean) => void
  isMouseOnCenter: boolean
  onDispose: (item: GeoItem) => void
  markerVariationForValue: (value: string) => void
  markerVariationLoading: boolean
  mapVisualization: {
    type: 'satellite' | 'map'
    visualizationType: 'heatmap' | 'chloroplethic'
  }
}

export const InfoWindowWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  span {
    font-size: 1.2rem;
    line-height: 1.5;
    padding-right: 25px;
  }

  h1 {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.5;
    color: ${colors.sebraeBlue};
    margin: 0px;
  }

  small {
    font-size: 0.85rem;
  }
`

export const MobileButtonWrapper = styled.div`
  display: flex;
  margin-top: 10px;

  button {
    width: 100%;
    height: 100%;
    font-size: 14px;
  }
`

function LegalEntityGeoItem({
  isTouchable,
  isSelected,
  onClick,
  onClickNextLevelButton,
  onMouseOut,
  onMouseOver,
  onMouseCenter,
  isMouseOnCenter,
  onDispose,
  markerVariationForValue,
  markerVariationLoading,
  ...item
}: LegalEntityGeoItemProps): JSX.Element {
  const { count, highlight, multiPolygon, levelKey, name, collection, center, cluster } = item
  const [searchParams] = useSearchParams()

  const contrastLow = chroma.contrast('#0F438A', item.color)
  const contrastHigh = chroma.contrast('#d9f5ff', item.color)

  const noData = highlight === 0 || highlight === null || highlight === undefined
  const noDataProfits =
    (highlight === 0 || isNullOrUndefined(highlight)) &&
    (searchParams.get('highlightValue') === 'profitsData' ||
      searchParams.get('highlightValue') === 'profitsData')

  const isHighlightCensus =
    (searchParams.get('highlightType') === 'area' ||
      searchParams.get('highlightType') === 'perCapta') &&
    searchParams.get('highlightValue') === 'censusUpdated'

  const borderColor =
    item.mapVisualization.visualizationType === 'heatmap'
      ? item.mapVisualization.type === 'map'
        ? colors.oceanBlue
        : 'white'
      : item.borderColor

  const color =
    item.mapVisualization.visualizationType === 'heatmap' ? colors.oceanBlue : item.color

  const value = useMemo(() => {
    const result =
      highlight !== null
        ? `${count !== 0 ? formatAsPercentage(highlight / count) : 0}%`
        : count > 99
        ? '99+'
        : String(count)

    return result
  }, [highlight, count])

  const noCensusData = isHighlightCensus && highlight && formatAsBrNumber(highlight / count) === '0'
  const noDataPolygons = noDataProfits || noCensusData

  const isHighlightScores = useMemo(() => {
    const isScoreOperationsManagementSearchParams =
      searchParams.get('highlightType') === 'scoreOperationsManagement'
    const isScoreTransformationSearchParams =
      searchParams.get('highlightType') === 'scoreTransformation'
    const isScoreIndicatorsManagementSearchParams =
      searchParams.get('highlightType') === 'scoreIndicatorsManagement'
    const isScoreInnovationSearchParams = searchParams.get('highlightType') === 'scoreInnovation'
    const isScoreMarketingSearchParams = searchParams.get('highlightType') === 'scoreMarketing'
    const isScoreSustainablePracticesSearchParams =
      searchParams.get('highlightType') === 'scoreSustainablePractices'

    return (
      isScoreOperationsManagementSearchParams ||
      isScoreTransformationSearchParams ||
      isScoreIndicatorsManagementSearchParams ||
      isScoreInnovationSearchParams ||
      isScoreMarketingSearchParams ||
      isScoreSustainablePracticesSearchParams
    )
  }, [searchParams])

  const isHighlightProfits = useMemo(() => {
    return (
      searchParams.get('highlightType') === 'profits' ||
      searchParams.get('highlightType') === 'cost'
    )
  }, [searchParams])

  const messageValue = useMemo(() => {
    const isScoreOperationsManagementSearchParams =
      searchParams.get('highlightType') === 'scoreOperationsManagement'
    const isScoreTransformationSearchParams =
      searchParams.get('highlightType') === 'scoreTransformation'
    const isScoreIndicatorsManagementSearchParams =
      searchParams.get('highlightType') === 'scoreIndicatorsManagement'
    const isScoreInnovationSearchParams = searchParams.get('highlightType') === 'scoreInnovation'
    const isScoreMarketingSearchParams = searchParams.get('highlightType') === 'scoreMarketing'
    const isScoreSustainablePracticesSearchParams =
      searchParams.get('highlightType') === 'scoreSustainablePractices'
    const isHighlightScores =
      isScoreOperationsManagementSearchParams ||
      isScoreTransformationSearchParams ||
      isScoreIndicatorsManagementSearchParams ||
      isScoreInnovationSearchParams ||
      isScoreMarketingSearchParams ||
      isScoreSustainablePracticesSearchParams

    const isHighlightCensus =
      searchParams.get('highlightType') === 'area' ||
      searchParams.get('highlightType') === 'perCapta'
    const isHighlightProfits =
      searchParams.get('highlightType') === 'profits' ||
      searchParams.get('highlightType') === 'cost'

    const messageProfits = isHighlightScores
      ? 'indicadores'
      : searchParams.get('highlightType') === 'profits'
      ? 'faturamento'
      : searchParams.get('highlightType') === 'cost'
      ? 'custo médio'
      : null
    const profitsMessage =
      searchParams.get('highlightType') === 'profits'
        ? 'Média de faturamento'
        : searchParams.get('highlightType') === 'cost'
        ? 'Custo médio'
        : null
    const censusMessage =
      searchParams.get('highlightType') === 'area'
        ? 'por km²'
        : searchParams.get('highlightType') === 'perCapta'
        ? 'per capita'
        : null
    const isHighlightCourseDropout = searchParams.get('highlightType') === 'courseDropout'

    const isHeatmap =
      (item.mapVisualization.visualizationType === 'heatmap') === true
        ? `${formatAsBrNumber(count, 0)} empresas`
        : null
    const highlightIsNullOrUndefined =
      highlight === null || highlight === undefined
        ? `${formatAsBrNumber(count, 0)} empresas`
        : null
    const highlightCensus =
      isHighlightCensus && highlight && !noCensusData
        ? `${formatAsBrNumber(highlight / count)} empresa${count !== 1 ? 's' : ''} ${censusMessage}`
        : null
    const highlightIsNotNull =
      highlight !== null && !isHighlightCensus && !isHighlightScores && !isHighlightProfits
        ? `${formatAsBrNumber(highlight, 0)} de ${formatAsBrNumber(count, 0)} ${
            isHighlightCourseDropout ? 'alunos' : 'empresas'
          } (${value})`
        : null
    const highlightCensusWithoutData =
      isHighlightCensus && noCensusData ? 'Sem dados do censo' : null
    const highlightProfits =
      isHighlightProfits && highlight && !noData
        ? `${profitsMessage}: R$ ${formatAsBrNumber(highlight)}`
        : null
    const highlightProfitsWithoutData =
      (isHighlightProfits || isHighlightScores) && noData
        ? `Sem dados de ${messageProfits} para este nível`
        : null
    const highlightScores =
      isHighlightScores && highlight && !noData
        ? `Média: ${formatAsBrNumber(highlight)} de 5`
        : null

    return highlightIsNullOrUndefined
      ? highlightIsNullOrUndefined
      : isHeatmap
      ? isHeatmap
      : highlightCensus
      ? highlightCensus
      : highlightCensusWithoutData
      ? highlightCensusWithoutData
      : highlightProfits
      ? highlightProfits
      : highlightProfitsWithoutData
      ? highlightProfitsWithoutData
      : highlightScores
      ? highlightScores
      : highlightIsNotNull
      ? highlightIsNotNull
      : null
  }, [
    searchParams,
    highlight,
    item?.mapVisualization?.visualizationType,
    noData,
    noCensusData,
    value,
    count,
  ])

  const infoWindow = () => {
    return center ? (
      <InfoWindow
        position={center}
        onCloseClick={() => onDispose(item)}
        options={{ disableAutoPan: true, pixelOffset: new google.maps.Size(0, -5) }}
      >
        <InfoWindowWrapper>
          <span>
            {!name ? (
              <span>Sem nome ({levelKeyAux[levelKey as unknown as keyof typeof levelKeyAux]})</span>
            ) : (
              <span>
                {name} ({levelKeyAux[levelKey as unknown as keyof typeof levelKeyAux]})
              </span>
            )}
          </span>
          <Separator />
          <h1>{messageValue}</h1>
          {(isHighlightProfits || isHighlightScores) && !noData ? (
            <small>Média baseada em {formatAsBrNumber(count)} empresas</small>
          ) : null}
          {!noCensusData && levelKey === 'cities' && isHighlightCensus ? (
            <small>Valor baseado no ano de: {item.updateYearCensus}</small>
          ) : null}
          {isTouchable ? (
            <MobileButtonWrapper>
              <Button onClick={() => onClickNextLevelButton(item)}>Detalhar</Button>
            </MobileButtonWrapper>
          ) : null}
        </InfoWindowWrapper>
      </InfoWindow>
    ) : null
  }

  return (
    <Fragment>
      {multiPolygon ? (
        <GeoJsonMultiPolygon
          geoJson={multiPolygon}
          onClick={() => onClick(item)}
          onMouseOver={() => onMouseOver(item)}
          onMouseOut={() => onMouseOut(item)}
          options={{
            fillColor: color,
            fillOpacity:
              item.mapVisualization.visualizationType === 'heatmap' || noDataPolygons ? 0 : 1,
            strokeColor: borderColor,
            strokeWeight: item.mapVisualization.visualizationType === 'heatmap' ? 1.75 : 1.5,
            zIndex: 1,
          }}
        />
      ) : null}

      {collection ? (
        <GeoJsonMultiLineString
          geoJson={collection && collection?.geometries ? collection.geometries[0] : collection}
          onClick={() => onClick(item)}
          onMouseOver={() => onMouseOver(item)}
          onMouseOut={() => onMouseOut(item)}
          options={{
            strokeColor: color,
            strokeWeight: 3,
            zIndex: 1,
          }}
        />
      ) : null}

      {center &&
      markerVariationLoading === false &&
      (levelKey === 'neighborhoods' || levelKey === 'streets') ? (
        <Marker
          icon={{
            url: `${markerVariationForValue(item.percentColor)}`,
            anchor: new google.maps.Point(15, 15),
          }}
          position={center}
          onClick={() => onClick(item)}
          onMouseOver={() => onMouseOver(item)}
          onMouseOut={() => onMouseOut(item)}
        >
          {isSelected ? infoWindow() : null}
        </Marker>
      ) : null}

      {isSelected && center && levelKey !== 'neighborhoods' && levelKey !== 'streets' ? (
        !isTouchable ? (
          <Marker
            icon={{
              url: `${markerVariationForValue(item.percentColor)}`,
              anchor: new google.maps.Point(15, 15),
            }}
            position={center}
            onClick={() => onClick(item)}
            onMouseOver={() => onMouseCenter(true)}
            onMouseOut={() => onMouseCenter(false)}
          >
            {isMouseOnCenter === true ? infoWindow() : null}
          </Marker>
        ) : isSelected ? (
          infoWindow()
        ) : null
      ) : null}

      {cluster && markerVariationLoading === false ? (
        <Marker
          icon={{
            url: `${markerVariationForValue(item.percentColor)}`,
            anchor: new google.maps.Point(30, 30),
          }}
          label={{
            text: value,
            color:
              (item.mapVisualization.visualizationType === 'heatmap') === false
                ? contrastLow > contrastHigh
                  ? '#0F438A'
                  : '#d9f5ff'
                : '#363636',
            fontWeight: 'bold',
            fontSize: '16px',
          }}
          position={cluster}
          onClick={() => onClick(item)}
          onMouseOver={() => onMouseOver(item)}
          onMouseOut={() => onMouseOut(item)}
        >
          {isSelected ? (
            <InfoWindow
              position={cluster}
              onCloseClick={() => onDispose(item)}
              options={{ disableAutoPan: true, pixelOffset: new google.maps.Size(0, -5) }}
            >
              <InfoWindowWrapper>
                <span>{name}</span>
                <h1>
                  {highlight === null
                    ? `${formatAsBrNumber(count, 0)} empresa${count !== 1 ? 's' : ''}`
                    : `${formatAsBrNumber(highlight, 0)} de ${formatAsBrNumber(count, 0)} empresa${
                        count !== 1 ? 's' : ''
                      }`}
                </h1>
                {isTouchable ? (
                  <MobileButtonWrapper>
                    <Button onClick={() => onClickNextLevelButton(item)}>Detalhar</Button>
                  </MobileButtonWrapper>
                ) : null}
              </InfoWindowWrapper>
            </InfoWindow>
          ) : null}
        </Marker>
      ) : null}
    </Fragment>
  )
}

export default memo(LegalEntityGeoItem)
