import React, { useRef, useEffect, useState, useMemo } from 'react'
import PulseLoader from 'react-spinners/PulseLoader'
import useMedia from 'react-use/lib/useMedia'
import styled from 'styled-components'
import AppLayout from '~/components/appLayout'
import { colors } from '~/design'
import GoogleMaps from '~/prix/react/components/map/googleMaps'
import useAttendanceRadar, { LegalEntityData } from './attendanceRadar.hook'
import { Circle, Marker } from '@react-google-maps/api'
import useGeolocation from '~/prix/react/hooks/geolocation'
import canvasDrawToURL from '~/prix/utils/canvasDrawToURL'
import useAsync from 'react-use/lib/useAsync'
import AttendanceRadarMapMenu from './attendanceRadarMapMenu.component'
import MapIcon from '~/meta/map.svg'
import SatelliteIcon from '~/meta/satelite.svg'
import Supercluster from 'supercluster'
import { GeoJsonPoint } from '~/prix/types/geoJson'
import useGoogleMaps from '~/prix/react/hooks/googleMaps'
import Modal from '~/prix/react/components/modal'
import OriginalLink from '~/prix/react/components/link'
import { LegalEntityListTitle } from '../map/legalEntityGeoprocessingMap.screen'
import SearchField from '~/prix/react/components/form/inputs/searchField'
import { searchQueryToRegularExpression } from '~/prix/utils/types/regularExpression'
import { unaccent } from '~/prix/naturalLanguage/unaccent'
import Handle from '~/prix/react/components/handle'
import { ListFooter } from '~/components/list'
import LeftArrowIcon from '~/meta/angle-left-arrow.svg'

export interface ClusterItems {
  count: number
  id: number
  key: number
  name: string
}

export interface OverItems extends ClusterItems {
  cluster: { type: string; coordinates: Array<number> }
  clusterItems: ClusterItems[]
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;

  .gm-style iframe + div {
    border: none !important;
  }
`

export const LegalEntityListButton = styled(OriginalLink)`
  text-align: left;
  border: none;
  background: transparent;
  padding: 12px 5px 12px 12px;
  cursor: pointer;

  display: block;
  flex: 1;
  overflow: hidden;
  color: var(--institucional-cinza-sebrae-cinza-sebrae-30, #394D60);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.89655rem;
  font-weight: 400;

  &:hover {
    background-color: rgb(245, 245, 245);
    color: rgb(54, 54, 54);
  }
`

const MapTypeOverlayFirstGroup = styled.div`
  position: absolute;
  display: flex;
  margin-left: 10px;
  flex-direction: row;
  bottom: 40px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: transparent;
    cursor: pointer;
    padding: 4px 4px 0px 4px;
  }

  .svg-opacity {
    opacity: 0.5;
  }

  .border-left {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .border-right {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`

const OverlayLoading = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 94, 184, 0.6);
  backdrop-filter: blur(5px);
  z-index: 1;
  align-items: center;
  text-align: center;

  .center {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .message {
      margin-top: 10px;
      color: #fff;
      font-weight: bold;
      text-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
    }
  }

  .center-error {
    border-radius: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 30px 30px 20px 30px;
    background-color: #fff;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    cursor: default;

    .row {
      display: flex;
      flex-direction: row;
    }

    .column {
      display: flex;
      flex-direction: column;
      margin-right: 5px;
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 15px;
    }
  }
`
const EntitiesSection = styled.div`
  display: flex;
  margin: 25px 32px;
  padding: 0px 0px;
  flex-direction: column;
  flex-shrink: 0;

  border-radius: 0px 0px 8px 8px;
  background: #FFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`

const CardEntitiesList = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
`

const CardEntitiesListTitle = styled.div`
    display: flex;
    border-radius: 8px 8px 0px 0px;
    background: var(--p-action, #0F438A);
    padding: 0 25px;
    width: 100%;

    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    button {
      height: 3rem;
      width: 3rem;
      padding: 8px;
      align-items: center;
      flex-shrink: 0;

      border: none;
      border-radius: 100px;
      background: #005EB8;

      svg {
        margin-top: 2px;
        fill: #fff
      }
    }
    
    h3 {
      color: #FFF;
      font-weight: 400;
      line-height: 23.3px;
      font-size: 2rem;
    }
    
    div {
      min-width: 3rem;
    }
`

const ListRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 46px;
  align-items: center;
  flex-shrink: 0;
`

export default function AtendanceRadarMapScreen() {
  const isMobile = useMedia('(max-width: 768px)')
  const map = useRef<google.maps.Map | null>(null)
  const [zoomLevel, setZoomLevel] = useState(18)
  const [isRadarEnabled, setIsRadarEnabled] = useState<boolean>(false)
  const [menuVisibility, setMenuVisibility] = useState(false)
  const [isLoadingMap, setIsLoadingMap] = useState(true)
  const [mapTypeId, setMapTypeId] = useState('satellite')
  const [isModal, setIsModal] = useState(false)
  const [selectedLegalEntities, setSelectedLegalEntities] = useState<ClusterItems[] | undefined>(
    undefined,
  )
  const [legalEntityModalSearch, setLegalEntityModalSearch] = useState<string | null>(null)
  const [error, setError] = useState<Error>()

  const { google } = useGoogleMaps()
  const geolocation = useGeolocation()

  const customStyles = {
    content: {
      flex: 'block',
      marginLeft: '100px',
    },
  }

  const cellphoneSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 48 48"><g class="nc-icon-wrapper"><path fill="#444" d="M35 47H13a4 4 0 0 1-4-4V5a4 4 0 0 1 4-4h22a4 4 0 0 1 4 4v38a4 4 0 0 1-4 4z"></path><circle fill="#595959" cx="24" cy="42" r="2"></circle><path fill="#43A6DD" d="M35 37H13a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h22a1 1 0 0 1 1 1v28a1 1 0 0 1-1 1z"></path></g></svg>`
  const computerSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 48 48"><g class="nc-icon-wrapper"><path fill="#444" d="M41 30V7a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v23h34z"></path><path fill="#E6E6E6" d="M41 30H7L1.447 41.106A2 2 0 0 0 3.237 44h41.527a2 2 0 0 0 1.789-2.894L41 30z"></path><path fill="#43A6DD" d="M10 8h28v19H10z"></path><path fill="#B3B3B3" d="M40 37H8l2-5h28z"></path><path fill="#B3B3B3" d="M29 41H19l.542-2h8.916z"></path></g></svg>`

  const cellphoneIcon = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(cellphoneSvg)
  const computerIcon = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(computerSvg)

  useEffect(() => {
    if (geolocation.isLoading === false && isLoadingMap === false) {
      setIsRadarEnabled(true)
    }
  }, [geolocation, isLoadingMap])

  const {
    sameRoad,
    sameNeighborhood,
    withinRadius,
    isLoading: isLoadingAttendanceHook,
  } = useAttendanceRadar({
    longitude: Number(geolocation.longitude),
    latitude: Number(geolocation.latitude),
    isEnabled: !!isRadarEnabled,
    errorPermission: setError,
  })

  function mapLoadAction(evt: any) {
    evt.addListener('zoom_changed', () => {
      setZoomLevel(evt.zoom)
    })
    setIsLoadingMap(false)
  }

  const handleMapTypeId = (value: string) => {
    setMapTypeId(value)
  }

  const withinRadiusValues = withinRadius
    .map((item: LegalEntityData) => ({
      legalEntityId: item.legalEntityId,
      corporateName: item.corporateName,
      distance: Number(item.distanceMeters.toFixed(2)),
      pointOnStreet: item.pointOnStreet,
    }))
    .slice(0, 5)

  const sameRoadValues = sameRoad
    .map((item: LegalEntityData) => ({
      legalEntityId: item.legalEntityId,
      corporateName: item.corporateName,
      distance: Number(item.distanceMeters.toFixed(2)),
      pointOnStreet: item.pointOnStreet,
    }))
    .filter(road =>
      withinRadiusValues.every(within => {
        return within.legalEntityId !== road.legalEntityId
      }),
    )
    .slice(0, 5)

  const sameNeighborhoodValues = sameNeighborhood
    .map((item: LegalEntityData) => ({
      legalEntityId: item.legalEntityId,
      corporateName: item.corporateName,
      distance: Number(item.distanceMeters.toFixed(2)),
      pointOnStreet: item.pointOnStreet,
    }))
    .filter(neighborhood =>
      withinRadiusValues.every(within => {
        return within.legalEntityId !== neighborhood.legalEntityId
      }),
    )
    .filter(neighborhood =>
      sameRoadValues.every(road => {
        return road.legalEntityId !== neighborhood.legalEntityId
      }),
    )
    .slice(0, 5)

  const clusterMarker = useAsync(() =>
    canvasDrawToURL(
      context => {
        context.fillStyle = colors.sebraeBlue
        context.arc(30, 30, 22, 0, 2 * Math.PI)
        context.globalAlpha = 0.9
        context.fill()
        context.strokeStyle = '#fff'
        context.lineWidth = 2
        context.stroke()
      },
      { width: 60, height: 60 },
    ),
  )

  const bounds = useMemo(() => {
    const items = withinRadius

    if (!items) {
      return null
    }
    let south = Infinity
    let west = Infinity
    let north = -Infinity
    let east = -Infinity

    for (const item of items) {
      const point = item.pointOnStreet as GeoJsonPoint

      if (point && point.type === 'Point') {
        const [longitude, latitude] = point.coordinates
        if (south > latitude) {
          south = latitude
        }
        if (north < latitude) {
          north = latitude
        }
        if (west > longitude) {
          west = longitude
        }
        if (east < longitude) {
          east = longitude
        }
      }
    }

    if (!isFinite(south) || !isFinite(north) || !isFinite(east) || !isFinite(west)) {
      return null
    }
    return {
      south: south - 0.0001,
      west: west - 0.0001,
      north: north + 0.0001,
      east: east + 0.0001,
    }
  }, [withinRadius, isRadarEnabled])

  const overItems = useMemo(() => {
    if (!google || !bounds) {
      return null
    }

    const itemsByStreet = withinRadius.map(item => {
      const centerGeoJson = item.pointOnStreet as GeoJsonPoint
      return {
        type: 'Feature' as 'Feature',
        properties: {
          id: item.legalEntityId,
          name: `${item.corporateName}`,
          key: item.legalEntityId,
          count: 1,
        },
        geometry: centerGeoJson,
      }
    })

    const index = new Supercluster({
      radius: 40,
      maxZoom: 17,
    })

    index.load(itemsByStreet)

    const clusters = index.getClusters([bounds.west, bounds.south, bounds.east, bounds.north], 16)

    return clusters.map(cluster => {
      if (cluster.properties.cluster) {
        const innerItems = index
          .getLeaves(cluster.properties.cluster_id, 30000)
          .map(({ properties }) => properties)

        const count = innerItems ? innerItems.length : 1

        return {
          id: cluster.properties.cluster_id,
          key: cluster.properties.cluster_id,
          name: cluster.properties.point_count,
          count: count > 99 ? '99+' : count,
          cluster: {
            type: 'Point',
            coordinates: [cluster.geometry.coordinates[1], cluster.geometry.coordinates[0]],
          },
          clusterItems: innerItems,
        }
      }

      return {
        id: cluster.properties.id,
        key: cluster.properties.id,
        name: cluster.properties.name,
        count: 1,
        cluster: {
          type: 'Point',
          coordinates: [cluster.geometry.coordinates[1], cluster.geometry.coordinates[0]],
        },
        clusterItems: [cluster.properties],
      }
    }) as any
  }, [withinRadius, google, bounds?.east, bounds?.west, bounds?.north, bounds?.south])

  const handleClick = (item: OverItems) => {
    setIsModal(true)
    if (item.clusterItems && item.clusterItems.length > 1) {
      const orderedClusters = item.clusterItems.sort((first: ClusterItems, second: ClusterItems) =>
        ('' + first.name).localeCompare(second.name),
      )
      setSelectedLegalEntities(orderedClusters)
    } else {
      setSelectedLegalEntities(item.clusterItems)
    }
  }

  const listData = selectedLegalEntities
    ?.filter(item =>
      searchQueryToRegularExpression(unaccent(legalEntityModalSearch || '')).test(
        unaccent(item.name),
      ),
    )
  const itemsPerPage = 8

  const [currentPage, setCurrentPage] = useState(1)

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = currentPage * itemsPerPage
  const currentData = listData?.slice(startIndex, endIndex)

  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1)
  }

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1)
  }

  const goToPage = (page: number) => {
    setCurrentPage(page)
  }

  return (
    <AppLayout
      title={'Radar'}
      isRadar={true}
      initialMenuVisibility={menuVisibility}
      dockActive='start'
      menu={
        [...sameRoad, ...sameNeighborhood, ...withinRadius].length > 0 && !error
          ? () => (
            <AttendanceRadarMapMenu
              withinRadius={withinRadiusValues}
              sameRoad={sameRoadValues}
              sameNeighborhood={sameNeighborhoodValues}
            />
          )
          : null
      }
      card={{
        title: 'Lista de Empresas'
      }}
    >
      <Wrapper>
        <Handle error={error}>
          {isLoadingAttendanceHook !== false && !error ? (
            <OverlayLoading>
              <div className='center'>
                <PulseLoader color={'#fff'} />
                <span className='message'>Carregando...</span>
              </div>
            </OverlayLoading>
          ) : null}
          <GoogleMaps
            ref={map}
            onLoad={mapLoadAction}
            mapTypeId={mapTypeId}
            zoom={zoomLevel}
            center={{ lng: Number(geolocation.longitude), lat: Number(geolocation.latitude) }}
            options={{
              streetViewControl: false,
            }}
          >
            <>
              {isRadarEnabled && google && isLoadingAttendanceHook === false ? (
                <Marker
                  icon={{
                    anchor: new google.maps.Point(15, 15),
                    url: isMobile ? cellphoneIcon : computerIcon,
                  }}
                  position={{
                    lng: Number(geolocation.longitude),
                    lat: Number(geolocation.latitude),
                  }}
                >
                  <Circle
                    center={{
                      lng: Number(geolocation.longitude),
                      lat: Number(geolocation.latitude),
                    }}
                    radius={100}
                    options={{
                      strokeColor: colors.sebraeBlue,
                      strokeOpacity: 1,
                      strokeWeight: 2,
                      fillColor: colors.sebraeBlue,
                      fillOpacity: 0.3,
                      clickable: false,
                      draggable: false,
                      editable: false,
                      visible: true,
                      zIndex: 10,
                    }}
                  />
                </Marker>
              ) : null}

              {overItems && google
                ? overItems.map((item: OverItems) => (
                  <Marker
                    icon={{
                      url: `${clusterMarker.value}`,
                      anchor: new google.maps.Point(15, 15),
                    }}
                    key={item.id}
                    label={{
                      text: `${item.count}`,
                      color: '#fff',
                      fontWeight: 'bold',
                      fontSize: '16px',
                    }}
                    position={{
                      lng: item.cluster.coordinates[1],
                      lat: item.cluster.coordinates[0],
                    }}
                    onClick={() => handleClick(item)}
                  />
                ))
                : null}
              <Modal
                isOpen={isModal}
                onClose={() => {
                  setIsModal(false)
                  setLegalEntityModalSearch(null)
                }}
                customStyles={customStyles}
              // halfScreen={true} descomentar no merge
              // hideCloseButton={true}
              >
                <EntitiesSection>
                  <CardEntitiesList>
                    <CardEntitiesListTitle>
                      <button onClick={() => {
                        setIsModal(false)
                        setLegalEntityModalSearch(null)
                      }}>
                        <LeftArrowIcon />
                      </button>
                      <h3>Lista de Empresas</h3>
                      <div />
                    </CardEntitiesListTitle>
                  </CardEntitiesList>
                  {selectedLegalEntities && selectedLegalEntities.length > 10 && (
                    <SearchField
                      value={legalEntityModalSearch || ''}
                      onChange={setLegalEntityModalSearch}
                    />
                  )}
                  {currentData &&
                    <>
                      {currentData.map(item => (
                        <ListRow>
                          <LegalEntityListButton key={item.id} href={`/app/map/legalEntity/${item.id}`}>
                            {item.name}
                          </LegalEntityListButton>
                        </ListRow>
                      ))}
                      <ListFooter
                        currentPage={currentPage}
                        totalPages={listData ? Math.ceil(listData.length / itemsPerPage) : 0}
                        itemsPerPage={itemsPerPage}
                        totalItems={listData ? listData.length : 0}
                        onNextPage={nextPage}
                        onPrevPage={prevPage}
                        onGoToPage={goToPage} />
                    </>
                  }
                </EntitiesSection>
              </Modal>
            </>
          </GoogleMaps>
          <>
            <MapTypeOverlayFirstGroup>
              <button
                title='Satélite'
                className='button border-left'
                onClick={() => handleMapTypeId('satellite')}
              >
                <div className={mapTypeId !== 'satellite' ? 'svg-opacity' : ''}>
                  <SatelliteIcon width={35} height={35} />
                </div>
              </button>

              <button
                title='Mapa'
                className='button border-right'
                onClick={() => handleMapTypeId('map')}
              >
                <div className={mapTypeId !== 'map' ? 'svg-opacity' : ''}>
                  <MapIcon width={35} height={35} />
                </div>
              </button>
            </MapTypeOverlayFirstGroup>
          </>
        </Handle>
      </Wrapper>
    </AppLayout>
  )
}
