import { defineEntity, entity, foreign, number, object, string, boolean } from '~/prix'

const legalEntityIndicators = defineEntity({
  key: 'legalEntityIndicators',
  title: 'Dados de faturamento e Scores de empresas atendidas pelo Sebrae',
  sources: ['olap', 'oltp'],
  schema: object({
    id: string().label('ID', 'Identificador', 'Código'),
    cnpjLegalEntity: string().label('CNPJ'),
    legalEntityId: foreign(entity('legalEntity').property('id')).label('Identificador da empresa'),
    averageRevenue: number().label('Média de faturamento'),
    averageCost: number().label('Custo médio'),
    scoreOperationsManagement: number().label('Score de gestão de operações'),
    scoreTransformation: number().label('Score de transformação digital'),
    scoreIndicatorsManagement: number().label('Score de gestão de indicadores'),
    scoreInnovation: number().label('Score de inovação'),
    scoreMarketing: number().label('Score de marketing'),
    scoreSustainablePractices: number().label('Score de práticas sustentáveis'),
    isPossibleOutlierRevenue: boolean().label('Possível outlier da coluna de faturamento médio'),
    isPossibleOutlierCost: boolean().label('Possível outlier da coluna de custo médio'),
  }),
})

export default legalEntityIndicators
