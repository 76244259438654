import { entity, equals, query, string } from '~/prix'

export default function cnaeListQuery(id: string) {
  return query('legalEntityCnae')
    .select({
      cnaeId: entity('legalEntityCnae').property('cnaeSubclassId'),
      isMain: entity('legalEntityCnae').property('isMain'),
      description: entity('cnae').property('description'),
      segmentDescription: entity('segment').property('description'),
    })
    .join({
      current: entity('legalEntityCnae').property('cnaeSubclassId'),
      target: entity('cnae').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('cnae').property('id'),
      target: entity('cnaeSegment').property('cnaeId'),
      join: 'inner',
    })
    .join({
      current: entity('cnaeSegment').property('segmentId'),
      target: entity('segment').property('id'),
      join: 'inner',
    })
    .where(equals(entity('legalEntityCnae').property('legalEntityId'), string().value(id)))
    .limit(5000)
}
