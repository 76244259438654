import React, { useMemo, useState, useCallback, useRef, useEffect, ReactNode, SetStateAction, Dispatch } from 'react'
import { Helmet } from 'react-helmet'
import { Outlet, useLocation, useNavigate } from 'react-router'
import useMedia from 'react-use/lib/useMedia'
import useNetworkState from 'react-use/lib/useNetworkState'
import styled from 'styled-components'
import { AppError, errors } from '~/prix'
import Handle from '~/prix/react/components/handle'
import { scrollContext } from '~/prix/react/components/scrollContext'
import { useKeycloak } from '~/components/keycloak'
import Dock from './dock'
import Head from './head'
import Header from './header'
import TopCard, { ContentProps } from './topCard'
import { useAutoSetViewMode } from '~/prix/react/hooks/viewMode'

const Outer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 100%;
  
  .wrapper {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    order: 2;
    height: 100%;
    overflow: hidden;
    
    > main {
      display: ${({ mobileIsVisibleMenu }: { mobileIsVisibleMenu?: boolean }) =>
    mobileIsVisibleMenu ? 'none' : 'flex'};
      flex: 1;
      order: 3;
      position: relative;
      flex-direction: column;
      overflow: auto;
      background-color: #fafafa;
      z-index: 1;
    }

    div.menu-wrapper {
      display: flex;
      flex: 1;
      order: 2;
      z-index: 2;
      min-width: 275px;
      box-shadow: 1px 0 2px rgba(0, 0, 0, 0.1);
      position: relative;
      flex-direction: column;
      overflow-x: hidden;

      scrollbar-color: #ccc #fafafa;

      &::-webkit-scrollbar {
        width: 8px;
      }
    
      &::-webkit-scrollbar-track {
        background-color: #fafafa;
      }
    
      &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 4px;
        border: 2px solid #fafafa;
      }
      
      @media (max-width: 768px) {
        display: ${({ mobileIsVisibleMenu }: { mobileIsVisibleMenu?: boolean }) =>
    mobileIsVisibleMenu ? 'flex' : 'none'};
        background-color: #fff;
      }
    }

    div.menu-wrapper-radar {
      display: flex;
      flex: 1;
      order: 2;
      z-index: 2;
      min-width: 350px;
      box-shadow: 1px 0 2px rgba(0, 0, 0, 0.1);
      position: relative;
      flex-direction: column;
      overflow-x: hidden;

      @media (max-width: 768px) {
        display: ${({ mobileIsVisibleMenu }: { mobileIsVisibleMenu?: boolean }) =>
    mobileIsVisibleMenu ? 'flex' : 'none'};
        background-color: #fff;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
`

export interface ICard {
  title?: string
  outOfRangeEntities?: number | string
  totalEntities?: number | string
  onToggleMenu?: any
  selectedLegalEntity?: {
    selectedLegalEntityId: string
    selectedLegalEntityName: string
  }
  customStyles?: ContentProps
  dataSource?: string
  setDataSource?: Dispatch<SetStateAction<string>>
}

export interface AppLayoutProps {
  title?: string
  dockActive: string
  children?: React.ReactNode
  error?: Error | AppError | null
  mustBeAuthenticated?: boolean
  isLoading?: boolean
  menu?:
  | ReactNode
  | (({ onToggleMenu }: { onToggleMenu: () => void }) => ReactNode)
  | null
  initialMenuVisibility?: boolean
  isVisibleOffline?: boolean
  isRadar?: boolean
  isDashboard?: boolean
  card?: ICard | null
  tabIndicators?: any
  isLoadingIndicatorsData?: boolean
}

export default function AppLayout({
  children,
  title,
  dockActive,
  isLoading = false,
  error,
  initialMenuVisibility = false,
  isVisibleOffline = false,
  mustBeAuthenticated = true,
  menu = null,
  isRadar = false,
  isDashboard = false,
  card = null,
  tabIndicators,
  isLoadingIndicatorsData,
}: AppLayoutProps) {
  const currentViewMode = useAutoSetViewMode()
  const navigate = useNavigate()
  const mainReference = useRef<HTMLElement>(null)
  const isMobile = useMedia('(max-width: 768px)')
  const [mobileIsVisibleMenu, setMobileIsVisibleMenu] = useState(!initialMenuVisibility)
  const auth = useKeycloak()
  const { online } = useNetworkState()
  const { login } = useKeycloak()
  const viewModeStorage = sessionStorage.getItem('viewMode')

  //Abortar todas as requisições quando o usuário sair da página.
  useEffect(() => {
    function abortAllQueries(evt: Event) {
      evt.preventDefault()
      const data = new Blob(
        [
          JSON.stringify({
            userId: auth.tokenParsed?.sub,
            requestedTimestamp: new Date().getTime(),
          }),
        ],
        {
          type: 'application/json',
        },
      )
      window.navigator.sendBeacon('/api/v1/abort-pending-user-queries/', data)
    }
    window.addEventListener('unload', abortAllQueries, {
      once: true,
    })
    return () => {
      window.removeEventListener('unload', abortAllQueries)
    }
  }, [])

  const authError = useMemo(() => {
    if (!mustBeAuthenticated) {
      return null
    }
    if (auth.isLoading) {
      return null
    }
    if (!auth.tokenParsed) {
      setMobileIsVisibleMenu(false)
      return errors.auth()
    }
    return null
  }, [mustBeAuthenticated, auth.isLoading, auth.tokenParsed])

  const networkError = useMemo(() => {
    if (!isVisibleOffline && !online) {
      return errors.connectionFailed()
    }
    return null
  }, [isVisibleOffline, online])

  const handleToggleMenu = useCallback(() => setMobileIsVisibleMenu(old => !old), [])

  useEffect(() => {
    handleToggleMenu()
  }, [initialMenuVisibility])

  const handleTryAgain = useCallback(() => {
    if (authError) {
      sessionStorage.setItem('redirectOnAuthSuccess', '/app')
      return login()
    }
    navigate(0)
  }, [authError, navigate])

  return (
    <Outer mobileIsVisibleMenu={isMobile ? mobileIsVisibleMenu : false}>
      {isDashboard !== true || isMobile ?
        <Header />
        : null}

      <style>
        {`
          html {
            overflow-x: hidden;
            overflow-y: hidden;
          }
        `}
      </style>

      {!!card && isDashboard !== true && (
        <TopCard
          title={card?.title}
          outOfRangeEntities={card?.outOfRangeEntities}
          totalEntities={card?.totalEntities}
          onToggleMenu={card?.onToggleMenu}
          selectedLegalEntity={card?.selectedLegalEntity}
          customStyles={card?.customStyles}
          dataSource={card?.dataSource}
          setDataSource={card?.setDataSource}
        />
      )}

      <div className='wrapper'>
        <Helmet>{title ? <title>{title}</title> : null}</Helmet>

        <main ref={mainReference} style={{ flex: menu ? 3 : 5 }}>

          <scrollContext.Provider value={mainReference} />

          <Handle
            isLoading={auth.isLoading || isLoading}
            error={authError || networkError || error}
            tryAgainButtonLabel={authError ? 'Acessar' : 'Tentar novamente'}
            onTryAgain={handleTryAgain}
          >
            {isDashboard ? (
              <>
                {!isMobile ?
                  <Header />
                  : null}
                {!!card && (
                  <TopCard
                    title={card?.title}
                    outOfRangeEntities={card?.outOfRangeEntities}
                    totalEntities={card?.totalEntities}
                    onToggleMenu={card?.onToggleMenu}
                    selectedLegalEntity={card?.selectedLegalEntity}
                    customStyles={card?.customStyles}
                    dataSource={card?.dataSource}
                    setDataSource={card?.setDataSource}
                    tabIndicators={tabIndicators}
                    isLoadingIndicatorsData={isLoadingIndicatorsData}
                  />
                )}
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ flexDirection: 'column' }}>
                    {!isMobile && !authError && !auth.isLoading && currentViewMode !== 'embed' && viewModeStorage !== 'embed' ? (
                      <Dock active={dockActive} />
                    ) : null}
                  </div>
                  <div style={{ flexDirection: 'column' }}>
                    {children}
                    <Outlet />
                  </div>
                </div>
              </>
            ) :
              <>
                {children}
                <Outlet />
              </>
            }
          </Handle>
        </main>
        {!authError && !auth.isLoading ? (
          <>
            {menu ? (
              <div className={isRadar === false ? 'menu-wrapper' : 'menu-wrapper-radar'}>
                {typeof menu === 'function'
                  ? menu({
                    onToggleMenu: handleToggleMenu,
                  })
                  : menu}
              </div>
            ) : null}

            {title === 'Opções' ? null :
              <Head
                title={title}
                isVisibleMenu={isMobile ? mobileIsVisibleMenu : false}
                onToggleMenu={handleToggleMenu}
                menu={!!menu}
              />}
          </>
        ) : null}
        {(isMobile || isDashboard === false) && !authError && !auth.isLoading && currentViewMode !== 'embed' && viewModeStorage !== 'embed' ? (
          <Dock active={dockActive} />
        ) : null}
      </div>
    </Outer >
  )
}
