import React from 'react'
import useAsync from 'react-use/lib/useAsync'
import { isServiceWorkerSupported } from '~/utils/shared/platform'

export default function ServiceWorkerActive() {
  const header = (
    <thead>
      <tr>
        <th colSpan={2}>Service Worker</th>
      </tr>
    </thead>
  )
  if (!isServiceWorkerSupported) {
    return (
      <>
        {header}
        <tbody>
          <tr>
            <td colSpan={2}>Service worker não suportado</td>
          </tr>
        </tbody>
      </>
    )
  }

  const {
    loading: serviceWorkerRegistratedLoading,
    error: serviceWorkerRegistratedError,
    value: serviceWorkerRegistrated,
  } = useAsync(() => navigator.serviceWorker.getRegistrations())

  if (!serviceWorkerRegistrated || serviceWorkerRegistratedError) {
    return (
      <>
        {header}
        <tbody>
          <tr>
            <td colSpan={2}>
              Ocorreu um erro!
              <br />
              {serviceWorkerRegistrated ? 'Service worker ativo: indefinido' : null}
              {serviceWorkerRegistratedError ? serviceWorkerRegistratedError.message : null}
            </td>
          </tr>
        </tbody>
      </>
    )
  }

  if (serviceWorkerRegistrated.length === 0 || !serviceWorkerRegistrated[0]?.active) {
    return (
      <>
        {header}
        <tbody>
          <tr>
            <td colSpan={2}>
              {serviceWorkerRegistrated.length === 0 ? 'Nenhum service worker registrado' : null}
              {!serviceWorkerRegistrated[0]?.active ? ' / Nenhum service worker ativo' : null}
            </td>
          </tr>
        </tbody>
      </>
    )
  }

  if (serviceWorkerRegistratedLoading) {
    return (
      <>
        {header}
        <tbody>
          <tr>
            <td colSpan={2}>Carregando...</td>{' '}
          </tr>
        </tbody>
      </>
    )
  }

  return (
    <>
      {header}
      <tbody>
        <tr>
          <td>Service worker suportado</td> <td>{isServiceWorkerSupported ? 'sim' : 'não'}</td>{' '}
        </tr>
        <tr>
          <td>Service worker ativo</td> <td>{serviceWorkerRegistrated[0]?.active.state}</td>{' '}
        </tr>
        <tr>
          <td>Suporte a periodic sync</td>{' '}
          <td>
            {serviceWorkerRegistrated[0] && 'periodicSync' in serviceWorkerRegistrated[0]
              ? 'Sim'
              : 'Não'}
          </td>
        </tr>
      </tbody>
    </>
  )
}
