import { defineEntity, object, string } from '~/prix'

const legalNatureEntity = defineEntity({
  key: 'legalNature',
  title: 'Natureza Jurídica',
  sources: ['olap', 'oltp'],
  schema: object({
    id: string().label('ID'),
    description: string().label('Descrição'),
  }),
})

export default legalNatureEntity
