import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useItems from '~/prix/react/hooks/items'
import { add, format } from 'date-fns'
import { colors, complementarColors } from '~/design'
import styled from 'styled-components'
import { QueryBase } from '~/prix/query'
import Chart from 'react-google-charts'
import PulseLoader from 'react-spinners/PulseLoader'
import agentVisitList from './queries/agentVisitListQuery.query'

import ptBR from 'date-fns/locale/pt-BR'

import RightArrow from '~/components/icons/arrows/16px_minimal-right.svg'
import LeftArrow from '~/components/icons/arrows/16px_minimal-left.svg'

const Container = styled.div`
  display: flex;
  width: 100%;
  margin-left: 60px;

  .wrapper-calendar {
    display: flex;
    flex-direction: row;
    width: 100%;

    button {
      background: none;
      border: none;

      :hover {
        cursor: pointer;
      }
    }
  }

  .loading-wrapper {
    display: flex;
    flex: 1;
    align-self: center;
    justify-content: center;
    height: 300px;

    .center {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .loader {
        display: flex;
        height: 50px;
        flex-direction: row;
        align-items: center;
      }

      .message {
        display: flex;
        height: 50px;
        flex-direction: row;
        font-size: 16px;
        text-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
      }

      span {
        font-size: 17px;
      }
    }
  }

  @media (max-width: 1450px) {
    width: 90%;
    margin-left: 0;
  }

  @media (max-width: 768px) {
    padding: 0 5px;
    width: 95%;
  }

  @media (max-width: 550px) {
    width: 90%;
  }
`

const Calendar = styled.div`
  display: flex;
  flex: 1;
  max-height: 250px;
  width: 100%;

  @media (max-width: 1700px) {
    margin-right: -35px;
  }

  @media (max-width: 1450px) {
    margin-right: unset;
  }

  @media (max-width: 768px) {
    margin: 0px;
    padding-left: 5px;
    padding-right: 5px;
    width: 85%;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  min-width: 10px;
  align-items: center;
  z-index: 3;
`

const ButtonLeftWrapper = styled(ButtonWrapper)`
  justify-content: center;

  @media (max-width: 1500px) {
    margin-right: 40px;
    justify-content: unset;
  }

  @media (max-width: 768px) {
    padding-right: 0px;
    justify-content: unset;
  }
`

const Timetable = styled.div`
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;

  @media (max-width: 1500px) {
    overflow-x: scroll;
  }

  @media (max-width: 768px) {
    padding-top: 0;
  }
`

const options = {
  tooltip: { isHtml: true },
  noDataPattern: {
    backgroundColor: `${complementarColors.backgroundWhite}`,
    color: `${complementarColors.backgroundWhite}`,
  },

  colorAxis: {
    minValue: 0,
    maxValue: 15,
    colors: ['#AEE8FF', '#3988C7', '#0F438A'],
  },
  calendar: {
    cellSize: 19,
    cellColor: {
      stroke: `${colors.darkGray}`,
      strokeOpacity: 0.5,
      strokeWidth: 1,
    },
    daysOfWeek: 'DSTQQSS',
    dayOfWeekRightSpace: 10,
    dayOfWeekLabel: {
      bold: true,
    },
    monthLabel: {
      language: 'pt-BR',
    },
    monthOutlineColor: {
      stroke: `${colors.sebraeBlue}`,
      strokeOpacity: 0.8,
      strokeWidth: 2,
    },
    unusedMonthOutlineColor: {
      stroke: '#4c9ad7',
      strokeOpacity: 0.8,
      strokeWidth: 2,
    },
    focusedCellColor: {
      stroke: `${colors.sebraeBlue}`,
      strokeOpacity: 1,
      strokeWidth: 3,
    },
  },
}

const css = `div.google-visualization-tooltip { 
  white-space: nowrap;
  font-size: 16px;
  color: #000000;
}`

function tooltipHtml(date: string, value: number) {
  const day = format(new Date(date), 'dd', { locale: ptBR })
  const month = format(new Date(date), 'MMMM', { locale: ptBR })
  const monthCapitalize = month.charAt(0).toUpperCase() + month.slice(1)
  const year = format(new Date(date), 'yyyy', { locale: ptBR })
  const stringDate = `${day} de ${monthCapitalize} de ${year}`

  return (
    '<div style="padding:10px; font-size: 16px; color: #000000; opacity: 1; margin: 0;">' +
    '<table class="tooltip-layout">' +
    '<tr style="border-bottom: 1px solid #777;">' +
    '<td"><b>' +
    stringDate +
    '</b></td>' +
    '</tr>' +
    '<tr>' +
    '<td style="height: 25px">' +
    'Atendimentos: ' +
    value +
    '</td>' +
    '</tr>' +
    '</table>' +
    '</div>'
  )
}

interface ReducedListCountAttendances {
  [startDate: string]: {
    startDate: string
    count: number
  }
}

export interface AttendantAndDataSourceProps {
  id: string
  name: string
}

export default function AgentReportProductionChart({
  attendant,
  dataSource,
  setIsChartLoaded,
  startDate,
  endDate,
}: {
  attendant?: AttendantAndDataSourceProps
  dataSource?: AttendantAndDataSourceProps
  setIsChartLoaded: React.Dispatch<React.SetStateAction<boolean>>
  startDate: string
  endDate: string
}) {
  const [indexYear, setIndexYear] = useState<number>(0)

  const handleChangeYear = useCallback(
    type => {
      type === 'back' ? setIndexYear(indexYear + 1) : null
      type === 'next' ? setIndexYear(indexYear - 1) : null
    },
    [indexYear],
  )

  const listCountAttendances = useItems(
    () =>
      attendant !== undefined || dataSource !== undefined
        ? (agentVisitList({
            agentCpf: attendant!.id,
            dataSourceId: dataSource?.id,
            startDate: startDate,
            endDate: endDate,
          }) as QueryBase)
        : (null as never),
    [attendant, dataSource, startDate, endDate],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: attendant !== undefined || dataSource !== undefined,
    },
  )

  const result = useMemo(() => {
    const type = [
      [
        { type: 'date', id: 'date' },
        { type: 'number', id: 'attendances' },
        { type: 'string', role: 'tooltip' },
      ],
    ]

    const listCountAttendancesTimestampToDate = listCountAttendances.items?.map((item: any) => {
      return {
        startDate: format(new Date(item.startDate), 'yyyy-MM-dd'),
        count: item.count,
      }
    })

    const reducedListCountAttendancesTimestampToDate =
      listCountAttendancesTimestampToDate?.reduce<ReducedListCountAttendances>((acc, cur) => {
        if (acc[cur.startDate]) {
          acc[cur.startDate].count += cur.count
        } else {
          acc[cur.startDate] = cur
        }
        return acc
      }, {})

    const formatedValues = reducedListCountAttendancesTimestampToDate
      ? Object.values(reducedListCountAttendancesTimestampToDate)
      : null

    const listCountAttendancesYears = listCountAttendances.items?.map((item: any) => {
      const year = format(new Date(item.startDate), 'yyyy')
      return year
    })

    const uniqueYear = listCountAttendancesYears
      ?.filter(function (item, pos) {
        return listCountAttendancesYears.indexOf(item) == pos
      })
      .sort((a, b) => {
        return parseFloat(b) - parseFloat(a)
      })

    const listCountAttendancesFormatted = formatedValues?.map((item: any) => {
      const startDate = add(new Date(item.startDate), { hours: 3 })
      const array = [new Date(startDate), item.count, tooltipHtml(String(startDate), item.count)]
      return array
    })

    const filter = listCountAttendancesFormatted?.filter(item => {
      return uniqueYear && format(item[0], 'yyyy') === uniqueYear[indexYear] ? item : null
    })

    const values = listCountAttendancesFormatted && attendant!.id ? type.concat(filter || []) : type

    return {
      uniqueYear,
      listCountAttendancesFormatted,
      values,
    }
  }, [listCountAttendances, attendant])

  return (
    <>
      <Container>
        {listCountAttendances.isLoading ? (
          <div className='loading-wrapper'>
            <div className='center'>
              <div className='loader'>
                <PulseLoader color={colors.sebraeBlue} />
              </div>
            </div>
          </div>
        ) : result.values.length === 0 ? (
          <div className='loading-wrapper'>
            <div className='center'>
              <span>Selecione um agente</span>
            </div>
          </div>
        ) : result.values.length === 1 ? (
          <div className='loading-wrapper'>
            <div className='center'>
              <span>Sem dados para o agente selecionado</span>
            </div>
          </div>
        ) : (
          <div className='wrapper-calendar'>
            {attendant ? (
              <ButtonLeftWrapper>
                {result.uniqueYear &&
                result.uniqueYear?.length > 1 &&
                indexYear + 1 < result.uniqueYear?.length ? (
                  <button title='Ano anterior' onClick={() => handleChangeYear('back')}>
                    <div>
                      <LeftArrow fill={colors.sebraeBlue} width={48} height={48} />
                    </div>
                  </button>
                ) : null}
              </ButtonLeftWrapper>
            ) : null}

            <Calendar>
              {result.listCountAttendancesFormatted && attendant ? (
                <Timetable>
                  <Chart
                    chartType='Calendar'
                    width='1200px'
                    height='400px'
                    data={result.values}
                    options={options}
                    chartLanguage={'pt-BR'}
                    onLoad={() => setIsChartLoaded(true)}
                  />
                </Timetable>
              ) : null}
            </Calendar>

            {attendant?.id ? (
              <ButtonWrapper>
                {indexYear > 0 ? (
                  <button title='Ano posterior' onClick={() => handleChangeYear('next')}>
                    <div>
                      <RightArrow fill={colors.sebraeBlue} width={48} height={48} />
                    </div>
                  </button>
                ) : null}
              </ButtonWrapper>
            ) : null}
          </div>
        )}
      </Container>
      <style>{css}</style>
    </>
  )
}
