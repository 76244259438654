import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Circle, GoogleMap } from '@react-google-maps/api'
import { Marker, Polygon } from '@react-google-maps/api'
import useGoogleMaps from '~/prix/react/hooks/googleMaps'
import * as turf from '@turf/helpers'
import { colors } from '~/design'
import { PointFeatureWithRadius } from '~/prix/react/components/map/radiusEditor'
import GeoJsonMultiPolygon from '~/prix/react/components/map/geoJsonMultiPolygon'
import GeoJsonMultiLineString from '~/prix/react/components/map/geoJsonMultiLineString'
import GeoJsonLineString from '~/prix/react/components/map/geoJsonLineString'
import {
  GeoJsonMultiPolygon as GeoJsonMultiPolygonType,
  GeoJsonLineString as GeoJsonLineStringType,
  GeoJsonMultiLineString as GeoJsonMultiLineStringType,
} from '~/prix/types/geoJson'
import { MapDemarcationReportProps } from '../fetchFromDatabase/mapDemarcationReport.hook'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  overflow: hidden;
`

const containerStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
}

function PlotFeature({ feature }: { feature: PointFeatureWithRadius | turf.Feature }) {
  const isPolygon = feature.geometry?.type === 'Polygon'
  const polygonPoints = isPolygon
    ? (feature as turf.Feature<turf.Polygon>).geometry?.coordinates[0].map(coordinate => ({
      lat: coordinate[1],
      lng: coordinate[0],
    }))
    : []

  const isPoint = feature.geometry?.type === 'Point'
  const point = isPoint
    ? {
      lat: (feature as turf.Feature<turf.Point>).geometry?.coordinates[1],
      lng: (feature as turf.Feature<turf.Point>).geometry?.coordinates[0],
    }
    : null

  const isPointWithRadius = feature.geometry?.type === 'Point' && feature.properties?.radius
  const radius: number | null = isPointWithRadius
    ? (feature as turf.Feature<turf.Point>).properties?.radius
    : null

  const isMultiPolygon = feature.geometry?.type === 'MultiPolygon'

  const isLineString = feature.geometry?.type === 'LineString'

  const isMultiLineString = feature.geometry?.type === 'MultiLineString'

  return (
    <>
      {isPolygon ? (
        <Polygon
          paths={polygonPoints}
          options={{
            fillColor: colors.sebraeBlue,
            fillOpacity: 0.2,
            strokeColor: colors.sebraeBlue,
            strokeOpacity: 1,
            strokeWeight: 2,
          }}
        />
      ) : null}

      {isPointWithRadius ? (
        <>
          <Circle
            center={point as google.maps.LatLngLiteral}
            radius={(radius as number) || 0}
            options={{
              fillColor: colors.sebraeBlue,
              fillOpacity: 0.2,
              strokeColor: colors.sebraeBlue,
              strokeOpacity: 1,
              strokeWeight: 2,
            }}
          />
          <Marker
            position={point as google.maps.LatLngLiteral}
            icon={{
              path: google.maps.SymbolPath.CIRCLE,
              scale: 2,
              fillColor: colors.sebraeBlue,
              fillOpacity: 1,
              strokeColor: colors.sebraeBlue,
            }}
          />
        </>
      ) : null}

      {isPoint && !isPointWithRadius ? (
        <Marker
          position={point as google.maps.LatLngLiteral}
          icon={{
            path: google.maps.SymbolPath.CIRCLE,
            scale: 12,
            fillColor: colors.sebraeBlue,
            fillOpacity: 1,
            strokeWeight: 3,
            strokeColor: '#fff',
          }}
        />
      ) : null}

      {isMultiPolygon ? (
        <GeoJsonMultiPolygon geoJson={feature.geometry as GeoJsonMultiPolygonType} />
      ) : null}

      {isLineString ? (
        <GeoJsonLineString geoJson={feature.geometry as GeoJsonLineStringType} />
      ) : null}

      {isMultiLineString ? (
        <GeoJsonMultiLineString geoJson={feature.geometry as GeoJsonMultiLineStringType} />
      ) : null}
    </>
  )
}

export default function LegalEntityGeoprocessingMapDemarcationReportMap({
  feature,
  isLoading: isLoadingFeature = false,
}: MapDemarcationReportProps) {
  const [legacyGoogleMapRef, setLegacyGoogleMapRef] = useState<GoogleMap | null>(null)
  const { google } = useGoogleMaps()
  const brazilCenter = useMemo(
    () => (google ? new google.maps.LatLng(-10.78085882890515, -53.092482362387045) : undefined),
    [google],
  )

  useEffect(() => {
    const map = legacyGoogleMapRef?.state.map
    const bbox = feature?.bbox
    if (!map || !bbox) {
      return
    }

    // Fit map to feature
    const paddingInPixels = 10
    map.fitBounds(
      {
        east: bbox[2],
        north: bbox[3],
        south: bbox[1],
        west: bbox[0],
      },
      paddingInPixels,
    )
  }, [legacyGoogleMapRef])

  if (!google || !feature) {
    return null
  }

  const isFeatureCollection = (feature as turf.FeatureCollection).type === 'FeatureCollection'
  const features = isFeatureCollection ? (feature as turf.FeatureCollection).features : []

  return (
    <Wrapper>
      <GoogleMap
        ref={setLegacyGoogleMapRef}
        mapContainerStyle={containerStyle}
        center={brazilCenter}
        zoom={4}
      >
        {isFeatureCollection ? (
          features.map((feature, index) => <PlotFeature key={index} feature={feature} />)
        ) : (
          <PlotFeature feature={feature as PointFeatureWithRadius | turf.Feature} />
        )}
      </GoogleMap>
    </Wrapper>
  )
}
