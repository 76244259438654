import React, { useCallback } from 'react'
import styled from 'styled-components'
import Link from '~/prix/react/components/link'
import useAPI from '~/prix/react/hooks/api'
import Separator from '~/components/separator'
import keycloakConfig from '~/utils/shared/keycloakConfig'
import UserIcon from '~/components/icons/users/48px_circle-08.svg'
import { colors } from '~/design'
import { useKeycloak } from './keycloak'
import { useNavigate } from 'react-router'
import useActionFunction from '~/prix/react/hooks/actionFunction'
import deregisterDeviceDefinition from '~/packages/notifications/deregisterDevice/deregisterDevice.definition'
import useNotificationContext from '~/packages/notifications/notificationContext'

const PopupContainer = styled.div`
  position: absolute;

  top: 40px;
  right: 30px;
  width: 350px;
  padding: 30px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.15);
  flex-direction: column;

  .header-user-info {
    display: flex;
    flex-direction: column;
    line-height: 22px;
    font-size: 14px;
    text-align: left;
    margin-bottom: 10px;

    .user-info {
      display: flex;
      align-items: center;
      gap: 12px;
      padding-left: 8px;
    }
  }

  .small {
    padding: 10px;
    margin-bottom: 10px;
  }
`

const MenuItem = styled.div`
  color: ${colors.sebraeBlue};
  font-weight: normal;
  padding: 10px;
  text-align: left;
  cursor: pointer;

  &:hover {
    background-color: rgb(245, 245, 245);
  }
`
const CloseButton = styled.div`
  color: ${colors.sebraeBlue};
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`

export default function Dropdown({ onClose }: { onClose: () => void }) {
  const { context } = useAPI()
  const { keycloak } = useKeycloak()
  const userEmail = context.user?.email
  const navigate = useNavigate()
  const { callAction: callActionDeregisterToken } = useActionFunction(deregisterDeviceDefinition)
  const { userToken } = useNotificationContext()

  const handleLogout = useCallback(
    async (event: React.MouseEvent) => {
      if (userEmail && userToken) {
        callActionDeregisterToken({ userEmail, userToken })
      }
      event.preventDefault()
      navigate('/')
      await keycloak.logout().catch(error => console.error(error))
    },
    [keycloak, userEmail, userToken],
  )

  return (
    <PopupContainer>
      <CloseButton onClick={onClose}>x</CloseButton>
      <div className='header-user-info'>
        <div className='user-info'>
          <UserIcon height={48} width={48} fill={colors.sebraeBlue} />
          <div>
            <strong>{context.user?.name}</strong>
            <br />
            <small>{context.user?.email}</small>
          </div>
        </div>
      </div>
      <Separator />
      <Link href='/'>
        <MenuItem>Sobre a plataforma</MenuItem>
      </Link>
      <Link href='https://prix.tech/#Contact' remote newTab>
        <MenuItem>Fale conosco</MenuItem>
      </Link>
      <Separator />

      <small>Minha conta</small>
      <a href={`/index.html?refresh=${Math.random()}`}>
        <MenuItem>Atualizar</MenuItem>
      </a>
      <Link href={`${keycloakConfig.url}/realms/${keycloakConfig.realm}/account`} remote newTab>
        <MenuItem> Meu perfil</MenuItem>
      </Link>

      <Separator />

      <Link>
        <MenuItem onClick={handleLogout}>Sair</MenuItem>
      </Link>
    </PopupContainer>
  )
}
