import { HeatmapLayer } from '@react-google-maps/api'
import React, { useEffect, useState } from 'react'

interface MapHeatmapLayerOptions {
  heatmapPoints: {
    weight: number
    location: google.maps.LatLng
  }[]
  zoomLevel: number
  resolutionThreshold: number
  gradient?: Array<string>
}

/**
 * Descobrir e retornar o outlier superior em um array de números.
 * Qualquer valor acima desse outlier é fora da curva.
 * @source https://www.freecodecamp.org/news/what-is-an-outlier-definition-and-how-to-find-outliers-in-statistics/
 * @param dataPoints Array de números
 * @returns Outlier superior do array.
 */
function getOutlierFromArray(dataPoints: number[]) {
  dataPoints.sort((a, b) => a - b)

  const uniqueValues = [...new Set(dataPoints)]

  const lowerQuartileIndex = Math.floor(uniqueValues.length / 4)
  const upperQuartileIndex = Math.floor((uniqueValues.length / 4) * 3)

  const lowerQuartile = uniqueValues[lowerQuartileIndex] + uniqueValues[lowerQuartileIndex + 1] / 2
  const upperQuartile = uniqueValues[upperQuartileIndex] + uniqueValues[upperQuartileIndex + 1] / 2

  const interquartilRange = upperQuartile - lowerQuartile

  const upperOutlier = upperQuartile + 1.5 * interquartilRange

  return upperOutlier
}

/**
 * Calcula o radius e maxIntensity do heatmap, para garantir uniformidade e
 * tentar manter uma boa visualização do heatmap.
 * @param zoomLevel Nível de zoom do mapa.
 * @param outlier Outlier superior, qualquer valor acima é fora da curva.
 * @param resolutionThreshold Limite de resolução do heatmap, até qual nível de zoom é possível manter uma boa visualização.
 * @returns [radius, maxIntensity]
 */
function getHeatmapPointsRadiusIntensity(
  zoomLevel: number,
  dataPoints: number[],
  resolutionThreshold: number,
): number[] {
  const outlier = getOutlierFromArray(dataPoints)
  let coefficient = 1.5 - zoomLevel / 100

  if (zoomLevel > resolutionThreshold) {
    coefficient = 1.65 + 0.2 * (zoomLevel - (resolutionThreshold + 1)) - zoomLevel / 200
  }

  let radius = Math.pow(zoomLevel, coefficient) - zoomLevel / 8
  return [radius, outlier]
}

export default function MapHeatmapLayer({
  heatmapPoints,
  zoomLevel,
  resolutionThreshold,
  gradient,
}: MapHeatmapLayerOptions) {
  const [heatmapReady, setHeatmapReady] = useState(false)
  const [heatmapOptions, setHeatmapOptions] = useState<{}>({})

  useEffect(() => {
    setHeatmapReady(false)
    setTimeout(() => {
      if (heatmapPoints.length > 0) {
        const [radius, maxIntensity] = getHeatmapPointsRadiusIntensity(
          zoomLevel,
          heatmapPoints.map(item => item?.weight),
          resolutionThreshold,
        )
        setHeatmapOptions({ radius, maxIntensity })
        setHeatmapReady(true)

      } else {
        setHeatmapOptions({})
      }
    }, 120) // debounce?
  }, [heatmapPoints, zoomLevel])

  return (
    <HeatmapLayer
      data={heatmapReady ? heatmapPoints : []}
      options={{
        ...heatmapOptions,
        gradient,
      }}
    />
  )
}
