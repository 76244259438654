import { PropertyStringBase } from '../property'
import type { InferNative, NativeBaseOptional, Type } from '../types/type'
import type { AbstractValueFunction } from './function'

export interface CoalesceFunction<NativeGeneric extends NativeBaseOptional>
  extends AbstractValueFunction<NativeGeneric> {
  name: 'coalesce'
  defaultValue: String
}

/**
 * Conversão de função COALESCE do postgres, trás o primeiro elemento que não for nulo, ou o `defaultValue`.
 * @remarks [Documentação PostgreSLQ](https://www.postgresql.org/docs/current/functions-conditional.html#FUNCTIONS-COALESCE-NVL-IFNULL)
 * @param content Argumentos da função
 * @param defaultValue Valor padrão no caso de todos os argumentos serem nulos.
 * @returns Propriedade nativa compatível com a prixApi
 */
export default function coalesce<
  TypeGeneric extends Type,
  NativeGeneric extends NativeBaseOptional = InferNative<TypeGeneric>,
>(content: NativeGeneric, defaultValue: String): PropertyStringBase {
  return {
    kind: 'function',
    name: 'coalesce',
    defaultValue,
    content,
  }
}
