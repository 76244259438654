import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import useItems from '~/prix/react/hooks/items'
import { QueryBase } from '~/prix/query'
import { Wrapper } from '@googlemaps/react-wrapper'
import * as turf from '@turf/helpers'
import center from '@turf/center'
import AttendantMap, { Features } from '../attendant/map/attendantMap.component'
import attendantVisitLocationsListQuery from '../attendant/visitLocationsList/visitLocationsList.query'
import { AttendantAndDataSourceProps } from './agentReportProductionChart.component'
import styled from 'styled-components'

const OverlayLoading = styled.div`
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 94, 184, 0.6);
  backdrop-filter: blur(5px);
  z-index: 1;
  align-items: center;
  text-align: center;
  border-radius: 12px;

  .center {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .message {
      margin-top: 10px;
      color: #fff;
      font-weight: bold;
      text-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
    }
  }

  .center-error {
    border-radius: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 30px 30px 20px 30px;
    background-color: #fff;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    cursor: default;

    .row {
      display: flex;
      flex-direction: row;
    }

    .column {
      display: flex;
      flex-direction: column;
      margin-right: 5px;
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 15px;
    }
  }
`

export default function AgentReportAttendancesMap({
  agent,
  dataSource,
  setIsMapLoaded,
  apiKey,
  startDate,
  endDate,
}: {
  agent: AttendantAndDataSourceProps
  dataSource?: AttendantAndDataSourceProps
  setIsMapLoaded: Dispatch<SetStateAction<boolean>>
  apiKey: string
  startDate: string
  endDate: string
}) {
  const [values, setValues] = useState<any>()
  const [zoom, setZoom] = useState<number>(4)
  const [legalEntityName, setLegalEntityName] = useState<string | undefined>(undefined)
  const [selectedLegalEntityId, setSelectedLegalEntityId] = useState<string | undefined>(undefined)

  useEffect(() => {
    setValues({
      attendant: { id: agent.id, name: agent.name },
      dataSource: { id: dataSource?.id, name: dataSource?.name },
    })
  }, [agent, dataSource])

  const listLegalEntitiesAttendances = useItems(
    () =>
      values !== undefined
        ? (attendantVisitLocationsListQuery(values) as QueryBase)
        : (null as never),
    [values],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: values !== undefined,
    },
  )

  const listLegalEntitiesAttendancesFormatted = useMemo(() => {
    const list = listLegalEntitiesAttendances.items
      ?.map((item: any) => {
        const attendanceDate = new Date(item.attendanceStartDate)
        const startDateParsed = new Date(startDate)
        const endDateParsed = new Date(endDate)

        if (
          item.legalEntityCoordinates?.coordinates !== undefined &&
          attendanceDate >= startDateParsed &&
          attendanceDate <= endDateParsed
        ) {
          return {
            type: 'Feature',
            properties: {
              scalerank: 2,
              name: item.legalEntityName,
              id: item.legalEntityId,
            },
            geometry: {
              type: 'Point',
              coordinates: item.legalEntityCoordinates?.coordinates,
            },
          }
        }
      })
      .filter((item: any) => item !== undefined && item.legalEntityDataSource !== 5) as Features[]

    const removerDuplicates = list?.filter(
      (element, index, array) =>
        array.findIndex(find => find.properties.id === element.properties.id) === index,
    )
    return removerDuplicates
  }, [listLegalEntitiesAttendances, startDate, endDate])

  const attendancesCoordinates = useMemo(
    () =>
      listLegalEntitiesAttendancesFormatted
        ?.map(item => item?.geometry?.coordinates ?? item?.geometry?.coordinates)
        .filter(item => item !== undefined),
    [listLegalEntitiesAttendancesFormatted],
  )

  const hasServiceUnitCoordinates =
    listLegalEntitiesAttendances?.items?.[0]?.serviceUnitCoordinates !== null &&
    listLegalEntitiesAttendances?.items?.[0]?.serviceUnitCoordinates !== undefined

  const serviceUnitCoordinates = listLegalEntitiesAttendances?.items?.[0]
    ?.serviceUnitCoordinates as turf.AllGeoJSON

  const data = {
    type: 'FeatureCollection',
    features: listLegalEntitiesAttendancesFormatted,
  }

  const turfCenter = useMemo(() => {
    const getLegalEntityCoordinates = hasServiceUnitCoordinates
      ? serviceUnitCoordinates
      : attendancesCoordinates && attendancesCoordinates?.length > 0
      ? turf.points(attendancesCoordinates)
      : null

    const legalEntityCoordinates = getLegalEntityCoordinates
      ? center(getLegalEntityCoordinates)
      : null

    return legalEntityCoordinates
  }, [attendancesCoordinates])

  useEffect(() => {
    setZoom(12)
  }, [turfCenter])

  useEffect(() => {
    if (listLegalEntitiesAttendancesFormatted !== undefined) {
      setIsMapLoaded(true)
    }
  }, [listLegalEntitiesAttendancesFormatted])

  return (
    <Wrapper apiKey={apiKey}>
      {!listLegalEntitiesAttendances.isLoading &&
      listLegalEntitiesAttendancesFormatted?.length === 0 ? (
        <OverlayLoading>
          <div className='center'>
            <span className='message'>
              Não há informações geográficas dos atendimentos no período selecionado.
            </span>
          </div>
        </OverlayLoading>
      ) : (
        <AttendantMap
          center={turfCenter}
          zoom={zoom}
          data={data}
          attendant={values?.attendant}
          isLoading={listLegalEntitiesAttendances.isLoading}
          selectedLegalEntityId={selectedLegalEntityId}
          setLegalEntityName={setLegalEntityName}
          setSelectedLegalEntityId={setSelectedLegalEntityId}
          isLiteVersion={true}
          disableOverlay={true}
        />
      )}
    </Wrapper>
  )
}
