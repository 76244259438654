import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Marker, Polygon, Circle } from '@react-google-maps/api'
import * as turf from '@turf/helpers'
import calculateDistance from '@turf/distance'
import memoize from "fast-memoize"
import { colors } from '~/design'
import useGoogleMaps from '~/prix/react/hooks/googleMaps'

export type PointFeatureWithRadius = turf.Feature<turf.Point, {
  /**
   * Radius in meters
   */
  radius: number
}>

export type RadiusEditorProps = {
  point: google.maps.LatLngLiteral | null
  /**
   * Radius in meters
   */
  radius: number | null
  onMapClick: (event: google.maps.MapMouseEvent) => void
  onMapMouseMove: (event: google.maps.MapMouseEvent) => void
  onReset: () => void
  pointWithRadius: PointFeatureWithRadius | null
  circleOptions?: google.maps.CircleOptions
}

export function useRadiusEditor(initialPointWithRadius: PointFeatureWithRadius | null = null): RadiusEditorProps {
  const [isDrawing, setIsDrawing] = useState(false)
  const [pointWithRadius, setPointWithRadius] = useState<PointFeatureWithRadius | null>(initialPointWithRadius)

  const {
    point,
    radius,
  } = useMemo(() => ({
    point: pointWithRadius ? {
      lat: pointWithRadius.geometry.coordinates[1],
      lng: pointWithRadius.geometry.coordinates[0],
    } : null,
    radius: pointWithRadius ? pointWithRadius.properties.radius : null,
  }), [pointWithRadius])

  const handleReset = useCallback(() => {
    // console.log('initialPointWithRadius', initialPointWithRadius)
    setIsDrawing(false)
    setPointWithRadius(initialPointWithRadius)
  }, [initialPointWithRadius])
  useEffect(() => {
    handleReset()
  }, [handleReset])

  const handleMapClick = useCallback((event: google.maps.MapMouseEvent) => {
    const { latLng } = event
    if (!latLng) {
      return
    }
    if (isDrawing) {
      if (pointWithRadius) {
        const distance = calculateDistance(pointWithRadius, turf.point([latLng.lng(), latLng.lat()]), {
          units: 'kilometers',
        }) * 1000
        const newPointWithRadius = {
          ...pointWithRadius,
          properties: {
            ...pointWithRadius.properties,
            radius: distance,
          }
        }
        setPointWithRadius(newPointWithRadius)
      }
      setIsDrawing(false)
      return
    }
    setIsDrawing(true)
    setPointWithRadius(turf.point([latLng.lng(), latLng.lat()], {
      radius: 1,
    }))
  }, [isDrawing, pointWithRadius])
  const handleMapMouseMove = useCallback((event: google.maps.MapMouseEvent) => {
    const { latLng } = event
    if (!latLng) {
      return
    }
    if (!isDrawing || !pointWithRadius) {
      return
    }
    const distance = calculateDistance(pointWithRadius, turf.point([latLng.lng(), latLng.lat()]), {
      units: 'kilometers',
    }) * 1000
    const newPointWithRadius = {
      ...pointWithRadius,
      properties: {
        ...pointWithRadius.properties,
        radius: distance,
      }
    }
    setPointWithRadius(newPointWithRadius)
  }, [isDrawing, pointWithRadius])


  return {
    onMapClick: handleMapClick,
    onMapMouseMove: handleMapMouseMove,
    onReset: handleReset,
    pointWithRadius,
    point,
    radius,
  }
}

export default function RadiusEditor({
  circleOptions,
  point,
  radius,
  onMapClick,
  onMapMouseMove,
}: RadiusEditorProps) {
  const { google } = useGoogleMaps()
  const {
    circleOptions: finalCircleOptions,
    centralMarkerStyle: finalCentralMarkerStyle,
  } = useMemo(() => {
    if (!google) {
      return {
        circleOptions: undefined,
        ghostCircleOptions: undefined,
      }
    }

    const suggestionCircleOptions: google.maps.CircleOptions = {
      fillColor: colors.sebraeBlue,
      fillOpacity: 0.2,
      strokeColor: colors.sebraeBlue,
      strokeWeight: 2,
    }
    const currentCircleOptions = circleOptions || suggestionCircleOptions
    const centralMarkerStyle: google.maps.Symbol = {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 2,
      fillColor: currentCircleOptions.strokeColor,
      fillOpacity: 1,
      strokeColor: currentCircleOptions.strokeColor,
    }

    return {
      circleOptions: circleOptions || suggestionCircleOptions,
      centralMarkerStyle,
    }
  }, [google, circleOptions])

  if (!google) {
    return null
  }

  return (
    <>
      {point ? (
        <>
          <Circle
            center={point}
            radius={radius || 0}
            onClick={onMapClick}
            onMouseMove={onMapMouseMove}
            options={finalCircleOptions}
          />
          <Marker
            position={point}
            icon={finalCentralMarkerStyle}
            onClick={onMapClick}
          />
        </>
      ) : null}
    </>
  )
}