import format from 'date-fns/format'
import ptBR from 'date-fns/locale/pt-BR'
import parse from 'date-fns/parse'
import { baseDate } from './dateTime'
import createType, { AbstractType } from './type'

export interface MonthType<NativeGeneric extends string | null = string>
  extends AbstractType<NativeGeneric> {
  readonly type: 'month'
  readonly currentValue?: NativeGeneric
  isRequired(): MonthType<Exclude<NativeGeneric, null>>
  isOptional(): MonthType<NativeGeneric | null>
}

export default function month(): MonthType<string> {
  return {
    ...createType<MonthType<string>>(),
    type: 'month',
  }
}

export function parseMonth(date: string): Date {
  return parse(date, 'yyyy-MM', baseDate)
}

export function stringifyMonth(jsDateTime: Date): string {
  return format(jsDateTime, 'yyyy-MM')
}

export function getMonthName(jsDateTime: Date): string {
  return format(jsDateTime, 'MMMM')
}

export function getMonthAbbreviation(jsDateTime: Date): string {
  return format(jsDateTime, 'MMM', {
    locale: ptBR,
  }).toLowerCase()
}

export function formatMonth(
  jsDateTime: Date,
  { isAbbreviated }: { isAbbreviated?: boolean } = {},
): string {
  return format(jsDateTime, isAbbreviated ? 'MMM/yyyy' : "MMMM 'de' yyyy", {
    locale: ptBR,
  }).toLowerCase()
}
