import React, { useEffect, useMemo, useRef, useState } from 'react'
import { ArcLayer, GeoJsonLayer } from 'deck.gl'
import { GoogleMapsOverlay as DeckOverlay } from '@deck.gl/google-maps'
import { GeoJsonPoint } from '~/prix/types/geoJson'
import { OverlayLoading, OverlaySearchableMap } from '~/packages/legalEntityGeoprocessing/map/legalEntityGeoprocessingMap.screen'
import PulseLoader from 'react-spinners/PulseLoader'
import Modal from '~/prix/react/components/modal'
import LegalEntityReadOne from '~/packages/legalEntity/read/legalEntityReadOne.component'
import LegalEntityReadOneLite from '~/packages/legalEntity/read/legalEntityReadOneLite.component'

export interface Features {
  geometry: GeoJsonPoint
  properties: {
    id: number
    name: string
    scalerank: number
  }
  type: string
}

interface Data {
  type: string
  features: Features[]
}

interface AttendantMap {
  center: {
    type: string
    geometry: {
      type: string
      coordinates: Array<number>
    }
    properties: Object | null
  } | null
  zoom: number
  data: Data
  attendant: {
    id?: string
    name?: string
  }
  isLoading: boolean
  setLegalEntityName?: (value: string) => void
  selectedLegalEntityId?: string | undefined
  setSelectedLegalEntityId: (value: string | undefined) => void
  disableOverlay?: boolean
  isLiteVersion?: boolean
}

export default function AttendantMap({
  center,
  zoom,
  data,
  attendant,
  isLoading,
  setLegalEntityName,
  selectedLegalEntityId,
  setSelectedLegalEntityId,
  disableOverlay,
  isLiteVersion,
}:
  AttendantMap
) {
  const ref = useRef<HTMLDivElement>(null)
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [enableBackButton, setEnableBackButton] = useState<boolean>(false)
  const overlay = useMemo(
    () =>
      data?.features && data.features?.length > 0 ?
        new DeckOverlay({
          layers: [
            new GeoJsonLayer({
              id: 'legalEntities',
              data: data,
              filled: true,
              pointRadiusMinPixels: 2,
              pointRadiusScale: 1,
              getPointRadius: (data: Features) => 11 - data.properties.scalerank,
              getFillColor: [0, 94, 184],
              pickable: true,
              autoHighlight: true,
              onClick: (info: any) =>
                info.object &&
                setSelectedLegalEntityId(`${info.object.properties.id}`)
            }),
            new ArcLayer({
              id: 'arcs',
              data: data,
              dataTransform: (data: Data) => data.features.filter(filter => filter.properties.scalerank < 3),
              getSourcePosition: () => center?.geometry.coordinates,
              getTargetPosition: (data: Features) => data.geometry.coordinates,
              getSourceColor: [154, 205, 254],
              getTargetColor: [0, 94, 184],
              getWidth: 2.5,
              pickable: true,
            })
          ]
        }) : undefined,
    [data, center]
  )
  useEffect(() => {
    if (map && overlay) {
      map.setZoom(zoom)
      overlay.setMap(map)
    }
    if (map && center?.geometry?.coordinates) {
      map.setZoom(zoom)
      map.setCenter({ lng: center.geometry.coordinates[0], lat: center.geometry.coordinates[1] })
    } else if (map && attendant && !center?.geometry?.coordinates && attendant.id !== undefined) {
      const heading = map.getHeading()
      map.setHeading(heading ? heading + 0.1 : 0.1)
    } else if (map && attendant && attendant.id === undefined) {
      map.setZoom(4)
      map.setCenter({ lat: -10.78085882890515, lng: -53.092482362387045 })
    }
    return () => {
      if (overlay) {
        overlay.finalize()
        overlay.setMap(null)
      }
    }
  }, [map, center, zoom, overlay, attendant])

  useEffect(() => {
    const mapInstance = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        center: { lat: -10.78085882890515, lng: -53.092482362387045 },
        mapId: "90f87356969d889c",
        tilt: 30,
        zoom: 4,
        heading: 0,
        mapTypeId: 'hybrid'
      }
    )
    setMap(mapInstance)
  }, [])

  return (
    <>
      <div ref={ref} id="map" style={{ height: '100%', width: '100%' }} />
      {isLoading === true && !disableOverlay ?
        <OverlayLoading>
          <div className='center'>
            <PulseLoader color={'#fff'} />
          </div>
        </OverlayLoading>
        : null}
      {(data && data.features?.length === 0 || data === undefined) && isLoading === false && !disableOverlay ?
        <OverlaySearchableMap>
          <div className='center'>
            {attendant?.id ?
              <span>Sem dados para essa visualização.</span>
              : <span>Escolha um atendente para visualização dos dados.</span>
            }
          </div>
        </OverlaySearchableMap>
        : null}

      <Modal
        isOpen={!!selectedLegalEntityId}
        onClose={() => setSelectedLegalEntityId(undefined)}
        scrollY={true}
        modalStyle={isLiteVersion ? 'default' : 'fullScreen'}
        hideCloseButton={true}
      >
        {selectedLegalEntityId ?
          isLiteVersion ?
            <LegalEntityReadOneLite
              id={selectedLegalEntityId}
              onClose={() => setSelectedLegalEntityId(undefined)}
              enableBackButton={setEnableBackButton}
              setLegalEntityName={setLegalEntityName}
            />
            :
            <LegalEntityReadOne
              id={selectedLegalEntityId}
              onClose={() => setSelectedLegalEntityId(undefined)}
              enableBackButton={setEnableBackButton}
              setLegalEntityName={setLegalEntityName}
            />
          : null}
      </Modal>
    </>
  )
}