import type { InferNative, NativeBaseOptional, Type } from '../types/type'
import type { AbstractValueFunction, ValueFunctionContent } from './function'

export interface AsGeoJsonFunction<NativeGeneric extends NativeBaseOptional>
  extends AbstractValueFunction<NativeGeneric> {
  name: 'asGeoJson'
}

export default function asGeoJson<NativeGeneric extends NativeBaseOptional = NativeBaseOptional>(
  content: ValueFunctionContent,
): AsGeoJsonFunction<NativeGeneric> {
  return {
    kind: 'function',
    name: 'asGeoJson',
    content,
  }
}
