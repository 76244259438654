import React, { useEffect } from 'react'
import styled from 'styled-components'
import Handle from '~/prix/react/components/handle'
import useItem from '~/prix/react/hooks/item'
import { formatAsBrIntegerNumber } from '~/prix/utils/types/number'
import attendanceQuery from '../queries/attendance.query'

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--p-default, #005eb8);
  box-shadow: 0px 32px 30px rgba(20, 46, 82, 0.2);
  padding: 12px;
  border-radius: 0 0 12px 12px;
  height: 100%;
  justify-content: center;
  min-height: 119px;
`

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;

  > span {
    width: 50%;
  }

  @media (min-width: 1025px) {
    align-items: center;
    gap: 20px;
    padding: 0 12px;
  }

  .first {
    color: #fff;
    font-weight: 700;
    font-size: 33px;
  }

  .second {
    color: #fff;
    font-size: 13px;
    font-weight: 400;

    @media (max-width: 1160px) {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`

export default function DashboardGridItemAttendance({
  userGeoStateAbbreviation,
  dataSourceId,
  isLastYearQuery,
  handleCountAttendances,
  hasUserRoles,
}: {
  userGeoStateAbbreviation?: string | null
  dataSourceId?: number
  isLastYearQuery?: boolean
  handleCountAttendances?: any
  hasUserRoles?: boolean
}) {
  const totalAttendance = useItem(
    () =>
      attendanceQuery({
        stateAbbreviation: userGeoStateAbbreviation ? userGeoStateAbbreviation : undefined,
        dataSourceId,
        isLastYearQuery,
      }),
    [userGeoStateAbbreviation, dataSourceId],
    { cache: 60 * 60 * 24 },
  )

  // useEffect(() => {
  //   if (
  //     hasUserRoles &&
  //     totalAttendance.item &&
  //     totalAttendance.item.totalLegalEntity !== undefined &&
  //     totalAttendance.item?.totalLegalEntity !== 0
  //   ) {
  //     handleCountAttendances(totalAttendance.item?.totalLegalEntity)
  //   }

  //   if (
  //     (hasUserRoles &&
  //       totalAttendance.item &&
  //       totalAttendance.item.totalLegalEntity === undefined) ||
  //     totalAttendance.item?.totalLegalEntity === 0
  //   ) {
  //     handleCountAttendances(0)
  //   }
  // }, [totalAttendance.item, dataSourceId, hasUserRoles])

  return (
    <Handle
      isLoading={totalAttendance.isLoading}
      error={totalAttendance.error}
      style={{ minHeight: '100px' }}
    >
      <Items>
        <Item>
          <span className='first'>
            {formatAsBrIntegerNumber(Number(totalAttendance.item?.total))}
          </span>
          <span className='second'>Atendimentos realizados</span>
        </Item>

        <Item>
          <span className='first'>
            {formatAsBrIntegerNumber(Number(totalAttendance.item?.totalLegalEntity))}
          </span>
          <span className='second'>
            {dataSourceId === 0 ? 'Empresas atendidas' : 'Empresas geocodificadas atendidas'}
          </span>
        </Item>
      </Items>
    </Handle>
  )
}
