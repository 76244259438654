import { boolean, defineAction, object, string } from '~/prix'
import serviceUnitGeocodeAction from './serviceUnitGeocode.action.json'

const serviceUnitGeocodeDefinition = defineAction({
  ...serviceUnitGeocodeAction,
  input: object({
    cityName: string().isOptional().label('Nome da cidade que se deseja geocodificar.'),
    saveLogsToFolder: string()
      .isOptional()
      .label('Pasta onde se deve salvar os logs de progresso e erros.'),
    update: boolean()
      .isOptional()
      .label('Geocodificar todos os endereços na legal_entity e não somente os nulos.'),
  }),
  output: object({}),
})

export default serviceUnitGeocodeDefinition
