import { dateTime, defineEntity, entity, enumerated, foreign, number, object, string } from '~/prix'
import { point } from '~/prix/types/geoJson'

const legalEntityGeoprocessingEntity = defineEntity({
  key: 'legalEntityGeoprocessing',
  title: 'Geoprocessamento de pessoa jurídica',
  alternativeTitles: ['Geoprocessamento', 'Geolocalização', 'Localização', 'Geoposicionamento'],
  sources: ['olap', 'oltp'],
  schema: object({
    id: string().label('ID', 'Identificador', 'Código'),

    googlePlaceId: string().label('id do google place'),
    googleCoordinates: point().label('Coordenadas da geocodificação com o google maps'),
    googleQuality: enumerated({
      approximate: 'approximate',
      geometricCenter: 'geometricCenter',
      rangeInterpolated: 'rangeInterpolated',
      rooftop: 'rooftop',
    }),
    googleUpdatedAt: dateTime().label('Data de atualização das coordenadas do google'),

    legalEntityId: foreign(entity('legalEntity').property('id')),

    pointOnStreet: point().label('Coordenadas de rua'),
    pointOnStreetDistance: number().label('Distancia do pointOnStreet com o googleCoordinates'),
    pointOnStreetDirectionAngle: number().label('Ângulo de direcionamento das coordenadas de rua'),
    pointOnStreetUpdatedAt: dateTime().label('Data de atualização da cidade'),

    userDefinedGeolocation: point().label('Coordenadas definidas pelo usuário'),
    userDefinedGeolocationType: enumerated({
      manual: 'manual',
      device: 'device',
      inferenceByStreet: 'inferenceByStreet',
    }),
    userDefinedGeolocationAccuracy: number().label('Precisão da localização definida por usuário'),
    userDefinedUpdatedAt: dateTime().label(
      'Data de atualização das coordenadas definidas pelo usuário',
    ),

    streetId: foreign(entity('street').property('id')),

    neighborhoodId: foreign(entity('neighborhood').property('id')),
    neighborhoodUpdatedAt: dateTime().label('Data de atualização do bairro'),

    cityId: foreign(entity('city').property('id')),
    cityUpdatedAt: dateTime().label('Data de atualização da cidade'),

    wayId: foreign(entity('way').property('id')),
    microRegionId: foreign(entity('microRegion').property('id')),
    mesoRegionId: foreign(entity('mesoRegion').property('id')),
    stateId: foreign(entity('state').property('id')),
    macroRegionId: foreign(entity('macroRegion').property('id')),
    countryId: foreign(entity('country').property('id')),

    fullAddressHash: string().label('Hash do endereço da entidade legal'),
    countryLevelHeatmapCoordinates: point().label(
      'Coordenadas do hexágono do mapa de calor cujo a empresa pertence a nível país',
    ),
    stateLevelHeatmapCoordinates: point().label(
      'Coordenadas do hexágono do mapa de calor cujo a empresa pertence a nível de estado',
    ),
    cityLevelHeatmapCoordinates: point().label(
      'Coordenadas do hexágono do mapa de calor cujo a empresa pertence a nível de cidade',
    ),
  }),
})

export default legalEntityGeoprocessingEntity
