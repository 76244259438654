import {
  entity,
  query,
  string,
  truthy,
  equals,
  TSQuery,
  isNumeric,
  like,
  TSRank,
  number,
  some,
  isNull,
} from '~/prix'
import coalesce from '~/prix/functions/coalesce'
import concat from '~/prix/functions/concat'
import setWeight from '~/prix/functions/setWeight'
import toTsVector from '~/prix/functions/toTsVector'
import unaccent from '~/prix/functions/unaccent'

interface UserGeoState {
  stateId?: number
  stateName?: string
  stateAbbreviation?: string
}

export default function legalEntitySearchWithGeo(inputSearch: string, userGeoState: UserGeoState) {
  const inputWithWildcards = `%${inputSearch.replace(/\s/g, '%')}%`
  const inputWithTsWildcards = `${inputSearch.trim().replace(/\s/g, ':*&')}:*`

  const legalEntitySearchName = [
    toTsVector('unaccent', coalesce(entity('legalEntity').property('corporateName'), '')),
    toTsVector('unaccent', coalesce(entity('legalEntity').property('tradeName'), '')),
  ]
  const legalEntitySearchAddress = [
    toTsVector('unaccent', coalesce(entity('legalEntity').property('state'), '')),
    toTsVector('unaccent', coalesce(entity('legalEntity').property('city'), '')),
    toTsVector('unaccent', coalesce(entity('legalEntity').property('neighborhood'), '')),
    toTsVector('unaccent', coalesce(entity('legalEntity').property('publicPlace'), '')),
  ]

  return query('macroRegion')
    .select({
      id: entity('macroRegion').property('id'),
      geo: string().value('macroRegion'),
      tradeName: entity('macroRegion').property('name'),
      corporateName: string().value(''),
      subtitle: string().value('Macrorregião'),
      streetId: number().value(0),
      searchRank: TSRank(
        [
          setWeight(toTsVector('simple', unaccent(entity('macroRegion').property('name'))), 'A'),
          setWeight(toTsVector('simple', string().value('Macrorregiao')), 'C'),
        ],
        unaccent(string().value(inputWithTsWildcards)),
      ),
    })
    .join({
      current: entity('macroRegion').property('id'),
      target: entity('state').property('macroRegionId'),
      join: 'inner',
    })
    .where(
      ...[
        userGeoState && userGeoState.stateId !== undefined
          ? equals(entity('state').property('id'), number().value(userGeoState.stateId))
          : null,
        TSQuery(
          [unaccent(entity('macroRegion').property('name')), string().value('Macrorregiao')],
          unaccent(string().value(inputWithTsWildcards)),
        ),
      ].filter(truthy),
    )
    .limit(userGeoState && userGeoState.stateId ? 200 : 500)
    .union(
      query('state')
        .select({
          id: entity('state').property('id'),
          geo: string().value('state'),
          tradeName: entity('state').property('name'),
          corporateName: string().value(''),
          subtitle: string().value('Estado'),
          streetId: number().value(0),
          searchRank: TSRank(
            [
              setWeight(toTsVector('simple', unaccent(entity('state').property('name'))), 'A'),
              setWeight(
                toTsVector('simple', unaccent(entity('macroRegion').property('name'))),
                'C',
              ),
              setWeight(toTsVector('simple', string().value('Estado')), 'B'),
            ],
            unaccent(string().value(inputWithTsWildcards)),
          ),
        })
        .join({
          current: entity('state').property('macroRegionId'),
          target: entity('macroRegion').property('id'),
          join: 'inner',
        })
        .where(
          ...[
            userGeoState && userGeoState.stateId !== undefined
              ? equals(entity('state').property('id'), number().value(userGeoState.stateId))
              : null,
            TSQuery(
              [
                toTsVector('simple', unaccent(entity('state').property('name'))),
                toTsVector('simple', unaccent(entity('macroRegion').property('name'))),
                toTsVector('simple', string().value('Estado')),
              ],
              unaccent(string().value(inputWithTsWildcards)),
            ),
          ].filter(truthy),
        ),
      query('mesoRegion')
        .select({
          id: entity('mesoRegion').property('id'),
          geo: string().value('mesoRegion'),
          tradeName: entity('mesoRegion').property('name'),
          corporateName: string().value(''),
          subtitle: userGeoState
            ? string().value('Mesorregião')
            : concat([string().value('Mesorregião - '), entity('state').property('abbreviation')]),
          streetId: number().value(0),
          searchRank: TSRank(
            [
              setWeight(toTsVector('simple', unaccent(entity('mesoRegion').property('name'))), 'A'),
              setWeight(
                toTsVector('simple', unaccent(entity('macroRegion').property('name'))),
                'C',
              ),
              setWeight(toTsVector('simple', string().value('Mesorregiao')), 'B'),
            ],
            unaccent(string().value(inputWithTsWildcards)),
          ),
        })
        .join({
          current: entity('mesoRegion').property('stateId'),
          target: entity('state').property('id'),
          join: 'inner',
        })
        .join({
          current: entity('state').property('macroRegionId'),
          target: entity('macroRegion').property('id'),
          join: 'inner',
        })
        .where(
          ...[
            userGeoState && userGeoState.stateId !== undefined
              ? equals(entity('state').property('id'), number().value(userGeoState.stateId))
              : null,
            TSQuery(
              [
                toTsVector('simple', unaccent(entity('mesoRegion').property('name'))),
                toTsVector('simple', unaccent(entity('macroRegion').property('name'))),
                toTsVector('simple', string().value('Mesorregiao')),
              ],
              unaccent(string().value(inputWithTsWildcards)),
            ),
          ].filter(truthy),
        ),
      query('microRegion')
        .select({
          id: entity('microRegion').property('id'),
          geo: string().value('microRegion'),
          tradeName: entity('microRegion').property('name'),
          corporateName: string().value(''),
          subtitle: userGeoState
            ? string().value('Microrregião')
            : concat([string().value('Microrregião - '), entity('state').property('abbreviation')]),
          streetId: number().value(0),
          searchRank: TSRank(
            [
              setWeight(
                toTsVector('simple', unaccent(entity('microRegion').property('name'))),
                'A',
              ),
              setWeight(toTsVector('simple', string().value('Microrregiao')), 'C'),
            ],
            unaccent(string().value(inputWithTsWildcards)),
          ),
        })
        .join({
          current: entity('microRegion').property('mesoRegionId'),
          target: entity('mesoRegion').property('id'),
          join: 'inner',
        })
        .join({
          current: entity('mesoRegion').property('stateId'),
          target: entity('state').property('id'),
          join: 'inner',
        })
        .where(
          ...[
            userGeoState && userGeoState.stateId !== undefined
              ? equals(entity('state').property('id'), number().value(userGeoState.stateId))
              : null,
            TSQuery(
              [
                toTsVector('simple', unaccent(entity('microRegion').property('name'))),
                toTsVector('simple', string().value('Microrregiao')),
              ],
              unaccent(string().value(inputWithTsWildcards)),
            ),
          ].filter(truthy),
        ),
      query('city')
        .select({
          id: entity('city').property('id'),
          geo: string().value('city'),
          tradeName: entity('city').property('name'),
          corporateName: string().value(''),
          subtitle: userGeoState
            ? string().value('Cidade')
            : concat([string().value('Cidade - '), entity('state').property('abbreviation')]),
          streetId: number().value(0),
          searchRank: TSRank(
            [
              setWeight(toTsVector('simple', unaccent(entity('city').property('name'))), 'A'),
              setWeight(toTsVector('simple', string().value('Microrregiao')), 'B'),
            ],
            unaccent(string().value(inputWithTsWildcards)),
          ),
        })
        .join({
          current: entity('city').property('microRegionId'),
          target: entity('microRegion').property('id'),
          join: 'inner',
        })
        .join({
          current: entity('microRegion').property('mesoRegionId'),
          target: entity('mesoRegion').property('id'),
          join: 'inner',
        })
        .join({
          current: entity('mesoRegion').property('stateId'),
          target: entity('state').property('id'),
          join: 'inner',
        })
        .where(
          ...[
            userGeoState && userGeoState.stateId !== undefined
              ? equals(entity('state').property('id'), number().value(userGeoState.stateId))
              : null,
            TSQuery(
              [
                toTsVector('simple', unaccent(entity('city').property('name'))),
                toTsVector('simple', unaccent(entity('microRegion').property('name'))),
                toTsVector('simple', string().value('Cidade')),
              ],
              unaccent(string().value(inputWithTsWildcards)),
            ),
          ].filter(truthy),
        ),
      query('neighborhood')
        .select({
          id: entity('neighborhood').property('id'),
          geo: string().value('neighborhood'),
          tradeName: entity('neighborhood').property('name'),
          corporateName: string().value(''),
          subtitle: userGeoState
            ? concat([string().value('Bairro > '), entity('city').property('name')])
            : concat([
              string().value('Bairro > '),
              entity('city').property('name'),
              string().value(' - '),
              entity('state').property('abbreviation'),
            ]),
          streetId: number().value(0),
          searchRank: TSRank(
            [
              setWeight(
                toTsVector('simple', unaccent(entity('neighborhood').property('name'))),
                'B',
              ),
              setWeight(toTsVector('simple', unaccent(entity('city').property('name'))), 'C'),
              setWeight(toTsVector('simple', string().value('Bairro')), 'B'),
            ],
            unaccent(string().value(inputWithTsWildcards)),
          ),
        })
        .join({
          current: entity('neighborhood').property('cityId'),
          target: entity('city').property('id'),
          join: 'inner',
        })
        .join({
          current: entity('city').property('microRegionId'),
          target: entity('microRegion').property('id'),
          join: 'inner',
        })
        .join({
          current: entity('microRegion').property('mesoRegionId'),
          target: entity('mesoRegion').property('id'),
          join: 'inner',
        })
        .join({
          current: entity('mesoRegion').property('stateId'),
          target: entity('state').property('id'),
          join: 'inner',
        })
        .where(
          ...[
            userGeoState && userGeoState.stateId !== undefined
              ? equals(entity('state').property('id'), number().value(userGeoState.stateId))
              : null,
            TSQuery(
              [
                toTsVector('simple', unaccent(entity('neighborhood').property('name'))),
                toTsVector('simple', unaccent(entity('city').property('name'))),
                toTsVector('simple', string().value('Bairro')),
              ],
              unaccent(string().value(inputWithTsWildcards)),
            ),
          ].filter(truthy),
        )
        .union(
          query('legalEntity')
            .select({
              id: entity('legalEntity').property('id'),
              geo: string().value('legalEntity'),
              tradeName: entity('legalEntity').property('tradeName'),
              corporateName: entity('legalEntity').property('corporateName'),
              subtitle: concat([
                entity('legalEntity').property('cnpj'),
                string().value(' - '),
                entity('legalEntity').property('city'),
                string().value(' - '),
                entity('legalEntity').property('state'),
              ]),
              streetId: entity('legalEntityGeoprocessing').property('streetId'),
              searchRank: TSRank(
                [
                  ...legalEntitySearchName.map(field => setWeight(field, 'C')),
                  ...legalEntitySearchAddress.map(field => setWeight(field, 'D')),
                ],
                unaccent(string().value(inputWithTsWildcards)),
              ),
            })
            .join({
              current: entity('legalEntity').property('id'),
              target: entity('legalEntityGeoprocessing').property('legalEntityId'),
              join: 'inner',
            })
            .join({
              current: entity('legalEntityGeoprocessing').property('stateId'),
              target: entity('state').property('id'),
              join: 'left',
            })
            .join({
              current: entity('legalEntityGeoprocessing').property('cityId'),
              target: entity('city').property('id'),
              join: 'left',
            })
            .join({
              current: entity('legalEntityGeoprocessing').property('streetId'),
              target: entity('street').property('id'),
              join: 'left',
            })
            .where(
              ...[
                isNull(entity('legalEntity').property('deletedAt')),
                userGeoState && userGeoState.stateAbbreviation !== undefined
                  ? equals(
                    entity('legalEntity').property('state'),
                    string().value(String(userGeoState.stateAbbreviation)),
                  )
                  : null,
                isNumeric(inputSearch)
                  ? like(entity('legalEntity').property('cnpj'), string().value(inputWithWildcards))
                  : TSQuery(
                    [...legalEntitySearchName, ...legalEntitySearchAddress],
                    unaccent(string().value(inputWithTsWildcards)),
                  ),
                ,
              ].filter(truthy),
            ),
        ),
    )
}
