import { useEffect, useState } from 'react'
import useAsync from 'react-use/lib/useAsync'

export default function useStorageInformation() {
  const storageEstimate = useAsync(() => navigator.storage.estimate())
  const [storageEstimateState, setStorageEstimateState] = useState({ usage: 0, quota: 0 })
  

  useEffect(() => {
    if (storageEstimate.loading) {
      return
    }

    setStorageEstimateState({
      usage: storageEstimate.value?.usage ?? 0,
      quota: storageEstimate.value?.quota ?? 0,
    })
  }, [storageEstimate])

  return storageEstimateState
}
