import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import InfoIcon from '~/components/icons/ui/16px_round-e-info.svg'
import { format, sub, subYears } from 'date-fns'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  background: #fff;
  border-radius: 12px;

  box-shadow: 0px 32px 30px 0px rgba(20, 46, 82, 0.2);
  @media (max-width: 1024px) {
    margin: 0 16px;

    .first-card {
      margin-top: -80px;
    }
  }

  &.main {
    width: 49%;

    @media (max-width: 1180px) {
      width: 80%;
    }

    @media (max-width: 768px) {
      min-width: calc(100vw - 81px);
    }
  }

  &.secondary {
    width: 49%;

    @media (max-width: 1180px) {
      width: 80%;
    }

    @media (max-width: 768px) {
      min-width: calc(100vw - 81px);
      width: calc(100vw - 81px);
    }
  }

  &.lastYear {
    width: 49%;
    z-index: 998;

    @media (max-width: 1180px) {
      width: 80%;
    }

    @media (max-width: 768px) {
      min-width: calc(100vw - 81px);
      width: calc(100vw - 81px);
    }
  }

  &.lastYearMap {
    width: 49%;
    z-index: 999;

    @media (max-width: 1180px) {
      width: 80%;
    }

    @media (max-width: 768px) {
      min-width: calc(100vw - 81px);
      width: calc(100vw - 81px);
    }
  }

  &.attendant {
    width: 49%;
    z-index: 999;

    @media (max-width: 1180px) {
      width: 80%;
    }

    @media (max-width: 768px) {
      min-width: calc(100vw - 81px);
      width: calc(100vw - 81px);
    }
  }

  &.lineChart {
    width: 49%;
    z-index: 999;

    @media (max-width: 1180px) {
      width: 80%;
    }

    @media (max-width: 768px) {
      min-width: calc(100vw - 81px);
      width: calc(100vw - 81px);
    }
  }

  &.aside {
    width: 99%;

    @media (max-width: 768px) {
      min-width: calc(100vw - 81px);
      width: calc(100vw - 81px);
    }
  }
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--p-action, #0f438a);
  border-radius: 12px 12px 0px 0px;
  padding: 23px 42px;
  gap: 8px;

  h2 {
    color: var(--p-light, #f0f6fe);
    text-align: center;
    font-weight: 700;
    font-size: 21px;
    margin: 0;
  }

  h3 {
    color: var(--p-active, #58c4da);
    text-align: center;
    font-weight: 400;
    font-size: 13px;
    margin: 0;
  }

  .row {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* border: 2px solid yellow; */
    width: 100%;
  }

  .column-text {
    display: flex;
    flex: 1;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10%;
    /* border: 2px solid orange; */
  }

  .column-extra {
    display: flex;
    flex-direction: row;
    margin: auto;
    margin-left: 25px;
    /* border: 2px solid red; */

    .column-info {
      display: flex;
      padding-top: 5px;
      justify-content: center;
      align-items: center;
    }

    .column-icon {
      display: flex;
      margin-left: 10px;
      justify-content: center;
      align-items: center;
    }
  }

  .info-icon-container {
    position: relative;
    display: inline-block;
  }

  .tooltip {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    color: #000;
    padding: 5px;
    border-radius: 5px;
    display: none;
    width: 300px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 999;
    padding: 15px 5px 15px 5px;

    .ball {
      width: 10px;
      height: 10px;
      background-color: #4285f4;
      border-radius: 50%;
      display: inline-block;
      margin-right: 3px;
      margin-left: 10px;
    }
  }

  .info-icon-container:hover .tooltip {
    display: block;
  }
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  margin: 21px 21px 0 21px;
  padding-bottom: 16px;
  gap: 8px;
  border-bottom: 1px solid var(--i-cinza-75, #b6bfc8);

  h2 {
    color: var(--p-action, #0f438a);
    font-size: 23px;
    line-height: normal;
    margin: 0;
  }

  h3 {
    color: #000;
    font-size: 13px;
    font-weight: 400;
    margin: 0;
  }

  @media (max-width: 1024px) {
    h3 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`

export default function DashboardItem({
  title,
  subtitle,
  kind,
  component,
  activeDataSourceId,
  tabIndicators,
  lastDates,
  isMap = true,
}: {
  title: string
  subtitle?: string
  kind?: string
  component?: any
  activeDataSourceId?: number
  tabIndicators?: any // TODO type
  lastDates?: any // TODO type
  isMap?: boolean
}) {
  const [showTooltip, setShowTooltip] = useState(false)

  const dates = useMemo(() => {
    if (lastDates && activeDataSourceId) {
      const lastAttendance =
        activeDataSourceId && lastDates && lastDates[activeDataSourceId]
          ? format(new Date(lastDates[activeDataSourceId]), 'dd/MM/yyyy')
          : null

      const lastYearAttendance =
        activeDataSourceId && lastDates && lastDates[activeDataSourceId]
          ? format(subYears(new Date(lastDates[activeDataSourceId]), 1), 'dd/MM/yyyy')
          : null

      const sixDaysAgoDate =
        activeDataSourceId && lastDates[activeDataSourceId]
          ? sub(new Date(lastDates[activeDataSourceId]), { days: 6 })
          : null
      const sixDaysAgo = sixDaysAgoDate ? format(sixDaysAgoDate, 'dd/MM/yyyy') : null

      const sevenDaysAgoDate =
        activeDataSourceId && lastDates[activeDataSourceId]
          ? sub(new Date(lastDates[activeDataSourceId]), { days: 7 })
          : null
      const sevenDaysAgo = sevenDaysAgoDate ? format(sevenDaysAgoDate, 'dd/MM/yyyy') : null

      const fourteenDaysAgoDate =
        activeDataSourceId && lastDates[activeDataSourceId]
          ? sub(new Date(lastDates[activeDataSourceId]), { days: 13 })
          : null
      const fourteenDaysAgo = fourteenDaysAgoDate ? format(fourteenDaysAgoDate, 'dd/MM/yyyy') : null

      return {
        lastAttendance,
        lastYearAttendance,
        sixDaysAgo,
        sevenDaysAgo,
        fourteenDaysAgo,
      }
    }
  }, [activeDataSourceId, lastDates])

  return (
    <Container className={kind} style={isMap === false ? { display: 'none' } : {}}>
      {kind === 'main' ? (
        <TitleContainer>
          <h2>{title}</h2>
          {subtitle && <h3>{subtitle}</h3>}
        </TitleContainer>
      ) : kind === 'attendant' ? (
        <TitleWrapper style={{ padding: '23px 23px' }}>
          <div className='row'>
            <div className='column-text'>
              <h2>{title}</h2>
              {subtitle && <h3>{subtitle}</h3>}
            </div>
            <div className='column-extra'>
              <div className='column-info'>
                <div
                  className='info-icon-container'
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                >
                  <InfoIcon height={24} width={24} fill={'#FFF'} />
                  {showTooltip ? (
                    <div className='tooltip'>
                      <span className='ball'></span>{' '}
                      <span style={{ marginLeft: '8px', marginTop: '14px', fontSize: '13px' }}>
                        Data do último atendimento: {dates?.lastAttendance}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </TitleWrapper>
      ) : (
        <TitleWrapper>
          <h2>{title}</h2>
          {subtitle && <h3>{subtitle}</h3>}
        </TitleWrapper>
      )}
      {component && component}
    </Container>
  )
}
