import formatter from 'format-number'

export function near(a: number, b: number, threshold: number) {
  return Math.abs(a - b) <= threshold
}

export const formatAsBrIntegerNumber = formatter({
  truncate: 0,
  integerSeparator: '.',
  decimal: '',
})
export const formatAsBrDecimalNumber = formatter({
  truncate: 2,
  integerSeparator: '.',
  decimal: ',',
})
export function formatAsBrNumber(value: number, decimalPlaces?: number) {
  if (decimalPlaces == 0) {
    return formatAsBrIntegerNumber(value)
  }
  if (decimalPlaces == 2) {
    return formatAsBrDecimalNumber(value)
  }
  if (typeof decimalPlaces === 'number') {
    const formatAsBrCustomNumber = formatter({
      truncate: decimalPlaces,
      integerSeparator: '.',
      decimal: ',',
    })
    return formatAsBrCustomNumber(value)
  }

  if (Number.isInteger(value)) {
    return formatAsBrIntegerNumber(value)
  }
  return formatAsBrDecimalNumber(value)
}

export function isNumeric(input: string) {
  return !isNaN(parseFloat(input))
}

export function formatAsPercentage(numeric: number, decimalSeparator = ',') {
  const percentage = numeric * 100

  return String(
    percentage !== 0
      ? (percentage < 10 || (percentage >= 99.1 && percentage < 100)) &&
        !Number.isInteger(percentage)
        ? Number(percentage.toFixed(2)).toString().replace('.', decimalSeparator)
        : Math.floor(percentage)
      : 0,
  )
}

export function formatDistance(distanceMeters: number) {
  if (distanceMeters < 10) {
    return `${formatAsBrNumber(distanceMeters, 2)} m`
  }
  if (distanceMeters < 100) {
    return `${formatAsBrNumber(distanceMeters, 1)} m`
  }
  if (distanceMeters < 1000) {
    return `${formatAsBrNumber(distanceMeters, 0)} m`
  }
  if (distanceMeters / 1000 < 10) {
    return `${formatAsBrNumber(distanceMeters / 1000, 2)} km`
  }
  if (distanceMeters / 1000 < 100) {
    return `${formatAsBrNumber(distanceMeters / 1000, 1)} km`
  }
  return `${formatAsBrNumber(distanceMeters / 1000, 0)} km`
}
