import { defineAction, object } from '~/prix'
import seedStreetCollectionAction from './seedStreetCollection.action.json' assert { type: 'json' }

const seedStreetCollectionDefinition = defineAction({
  ...seedStreetCollectionAction,
  input: object({}),
  output: object({}),
})

export default seedStreetCollectionDefinition
