import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import * as turf from '@turf/helpers'
import bbox from '@turf/bbox'
import ngeohash from 'ngeohash'
import LegalEntityGeoprocessingMapDemarcationReport from './report/legalEntityGeoprocessingMapDemarcationReport.component'

export default function LegalEntityGeoprocessingMapDemarcationPolygonScreen() {
  const params = useParams()
  const geohashes = params.geohashes as string
  const polygonFeature = useMemo(() => {
    const coordinates = geohashes.split(',').map(geohash => {
      const {
        latitude,
        longitude,
      } = ngeohash.decode(geohash)
      return [longitude, latitude]
    })
    const polygon = turf.polygon([[
      ...coordinates,
      coordinates[0],
    ]])
    polygon.bbox = bbox(polygon)
    return polygon
  }, [geohashes])

  return (
    <LegalEntityGeoprocessingMapDemarcationReport feature={polygonFeature} />
  )
}