import { boolean, defineAction, object } from '~/prix'
import systemCheckAction from './legalEntityAttendanceLastYearRead.action.json' assert { type: 'json' }

const systemCheckDefinition = defineAction({
  ...systemCheckAction,

  input: object({}),
  output: object({
    ok: boolean(),
  }),
})

export default systemCheckDefinition
