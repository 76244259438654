import createType, { AbstractType, TypeMeta } from './type'

export interface NumberMeta {
  min?: number
  max?: number
  [key: string]: unknown
}

export interface NumberType<NativeGeneric extends number | null = number>
  extends AbstractType<NativeGeneric> {
  readonly type: 'number'
  readonly currentValue?: NativeGeneric
  readonly currentMeta: TypeMeta<NativeGeneric> & NumberMeta
  meta(
    metaPatch: TypeMeta<NativeGeneric> & NumberMeta
  ): AbstractType<NativeGeneric>
  isRequired(): NumberType<Exclude<NativeGeneric, null>>
  isOptional(): NumberType<NativeGeneric | null>
}

export default function number(): NumberType<number> {
  return {
    ...createType<NumberType<number>>(),
    type: 'number',
  }
}
