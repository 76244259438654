export interface PublicConfig {
  services: {
    oneSignal: {
      appId: string
    }
    googleConfig: {
      apiKey: string
      googleSiteVerification: string
      recaptchaSiteKey: string
    }
  }
}

export default function publicConfig() {
  return process.env.PUBLIC_CONFIG as unknown as PublicConfig
}

export const version = process.env.VERSION
export const versionNumber = process.env.VERSION_NUMBER
