import { boolean, defineAction, object } from '~/prix'
import systemImportSebraeNaSuaEmpresaAction from './systemImportSebraeNaSuaEmpresa.action.json' assert { type: 'json' }

const systemImportSebraeNaSuaEmpresaDefinition = defineAction({
  ...systemImportSebraeNaSuaEmpresaAction,
  input: object({
    forceRecreation: boolean(),
  }),
  output: object({}),
})

export default systemImportSebraeNaSuaEmpresaDefinition
